import _ from 'lodash';
import React from 'react';
import TextInput from './TextInput';

export interface TextInputBuilderState {
  formKey: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: (value: React.SetStateAction<string | number>) => void;
  value: string | number;
}

export interface UseTextInputProps {
  formKey?: string;
  name: string;
  state?: { formData: Record<string, any>; formStep: number; maxPages: number };
}

/* Custom hook to be used with TextInputBuilder
 * The returned memoized object is the "state" argument passed
 * to TextInputBuilder state=state
 */
export function useTextInput(props: UseTextInputProps): TextInputBuilderState {
  const formKeyName = props.formKey ?? props.name;
  const [formKey] = React.useState<string>(formKeyName);
  const [value, setValue] = React.useState<string | number>('');

  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(ev.target.value);
    },
    []
  );

  React.useEffect(() => {
    if (props.state) {
      const newValue = props?.state?.formData[formKey];
      if (newValue) {
        setValue(newValue);
      }
    }
  }, [props.state, formKey]);

  return {
    formKey,
    onChange,
    value,
    setValue
  };
}

export interface TextInputBuilderProps {
  label?: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
  state: TextInputBuilderState;
  type?: string;
}

export const TextInputBuilder = ({
  name,
  label,
  type,
  placeholder,
  maxLength,
  state
}: TextInputBuilderProps): JSX.Element => {
  return (
    <TextInput
      id={name}
      type={type ?? 'text'}
      name={name}
      placeholder={placeholder}
      label={label ? _.capitalize(label) : _.capitalize(name)}
      onChange={state.onChange}
      isError={false}
      value={state.value}
      maxLength={maxLength}
    />
  );
};

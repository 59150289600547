import { makeStyles, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useDashboardSlice } from 'app/layouts';
import { pageSizeOptions, StatusToColor } from 'constants/app';
import { ColumnProps, Dot, TablePaginated, Text } from 'design';
import React from 'react';
import { useDispatch } from 'react-redux';
import EquipmentTableAction from '../EquipmentTableActions';
import { MenuActionItemProps } from '../EquipmentTableActions/EquipmentTableAction';
import { Powersource } from 'types/PowerSource';

interface PowerSourceTableProps {
  powersources?: Powersource[];
  bundleId: string;
}

/**
 * Power source table props
 * @param {PowerSourceTableProps} props
 * @returns {JSX.Element}
 */
const PowerSourceTable = ({
  powersources,
  bundleId
}: PowerSourceTableProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const cc = useStyles();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const menuItems: MenuActionItemProps[] = [
    {
      label: 'Edit',
      isVisible: true,
      lazyComponent: 'BundlePowerSourceUpdate',
      size: 'large'
    }
  ];

  const battery = powersources && powersources[0];

  const Columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'operationalStatus.name',
        label: '',
        align: 'center',
        hidden: currentUser?.isOrgAdminOrUser,
        render: ({ operationalStatus }: Powersource) => (
          <Dot
            color={
              operationalStatus?.name
                ? StatusToColor[operationalStatus.name]
                : null
            }
          />
        ),
        cellWidth: 43,
        padding: 'none'
      },
      {
        accessor: 'expandIcon',
        label: '',
        align: 'center',
        padding: 'none',
        cellWidth: 43,
        resizable: true,
        render: ({ id }: Powersource) => (
          <IconButton
            data-testid="expandButton"
            className={cc.expandButton}
            onClick={() => {
              dispatch(
                actions.showDialog({
                  open: true,
                  title: '',
                  lazyComponent: 'BundlePowerSourceExpand',
                  size: 'large',
                  lazyComponentProps: {
                    id: id
                  }
                })
              );
            }}
          >
            <LaunchIcon fontSize="small" className={cc.linkIcon} />
          </IconButton>
        )
      },
      {
        accessor: 'powersourceCatalog.model',
        label: 'Name',
        verticalAlign: 'top',
        resizable: true,
        render: ({ powersourceCatalog, adminStatus }: Powersource) => (
          <>
            <Text>{powersourceCatalog?.model}</Text>
            <Text className={cc.subRow}>{adminStatus?.name}</Text>
          </>
        )
      },
      {
        accessor: 'serialNumber',
        label: 'Serial Number',
        render: () => (
          <>
            {powersources?.map(item => {
              return (
                <Text key={item.serialNumber}>
                  {item.serialNumber ? item.serialNumber : '-'}
                </Text>
              );
            })}
          </>
        ),
        resizable: true
      },
      {
        accessor: ``,
        label: 'Battery Count',
        render: () => (
          <>
            {powersources?.length && powersources[0]?.batteries?.length
              ? powersources[0].batteries.length
              : '-'}
          </>
        ),
        resizable: true
      },
      {
        accessor: '',
        label: '',
        hidden: currentUser?.isOrgAdminOrUser,
        render: (powerSource: Powersource) => (
          <EquipmentTableAction
            menuItems={menuItems}
            equipmentItem={powerSource}
            bundleId={bundleId}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, powersources]
  );
  return (
    <TablePaginated
      id="PowerSource"
      columns={Columns}
      handlePageSize={setPageSize}
      handlePageNav={setPageIndex}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageSizeOptions={pageSizeOptions}
      isLoading={false}
      rows={battery ? [battery] : []}
      total={0}
      hidePagination={true}
    />
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  expandButton: {
    padding: 0,
    marginTop: '2px'
  },
  linkIcon: {
    color: '#A9B3B7',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '-8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  subRow: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
export default PowerSourceTable;

/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';

export interface MoreMenuItemProps {
  label: string;
  action: () => void;
  isVisible?: boolean;
}

export interface MoreMenuProps {
  menuItems: MoreMenuItemProps[];
  anchorEl: null | Element;
  handleClose: () => void;
  classes?: any;
  className?: string;
}

/**
 * NavDropdown Menu
 * @param props
 */
const MoreMenu = ({
  menuItems,
  anchorEl,
  handleClose,
  classes,
  className
}: MoreMenuProps): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cc = classes ?? useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      /* internal styles: these settings are for the start location of the popup menu so it should be internal always */
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={cc}
      className={className}
    >
      {menuItems.map(
        ({ label, action, isVisible }: MoreMenuItemProps, i: number) => (
          <div key={i}>
            {isVisible && (
              <MenuItem
                button
                onClick={() => {
                  action();
                  if (handleClose) {
                    handleClose();
                  }
                }}
                key={label}
              >
                {label}
              </MenuItem>
            )}
          </div>
        )
      )}
    </Menu>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 300
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  }
}));

export default MoreMenu;

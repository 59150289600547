import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export interface CmdcIconProps extends SvgIconProps {
  classes?: any;
}

const CmdcIcon = ({
  classes,
  viewBox = '0 0 24 24',
  color = 'inherit',
  fill = '#8B1DE9'
}: CmdcIconProps): JSX.Element => {
  return (
    <SvgIcon color={color} classes={classes} viewBox={viewBox}>
      <path
        d="M98.926,175.536l-2.513,2.516-9.486,9.485-12-12,12-12Zm-4.3,2.891,2.891-2.891-2.827-2.827-7.761-7.761-7.761,7.761-2.831,2.827,2.891,2.891,2.583.813-2.1-5.987,4.467,4.835,2.749-7.6,2.746,7.6,4.471-4.835-2.1,5.987Z"
        transform="translate(-74.926 -163.538)"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CmdcIcon;

import * as React from 'react';
import MaskedInput from 'react-text-mask';

interface PhoneTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  value: string | undefined;
  onChange: any;
}

function PhoneTextMask({
  inputRef,
  ...props
}: PhoneTextMaskProps): JSX.Element {
  return (
    <MaskedInput
      data-testid="phoneInput"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      value={props.value}
      onChange={props.onChange}
      mask={[
        '1',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

export default PhoneTextMask;

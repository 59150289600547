import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Card, CardHeader, CardFooter } from 'design';
import { useLocationInfo } from 'api/useLocations';
import moment from 'moment';

export interface LocationSiteInformationProps {
  locationId: string;
}

const LocationSiteInformation = ({
  locationId
}: LocationSiteInformationProps): JSX.Element => {
  const cc = useStyles();
  const [emergencyAddress, setEmergencyAddress] = React.useState<string>('');
  const { data: locationData, isLoading } = useLocationInfo(locationId);

  React.useEffect(() => {
    const addressFields = [
      locationData?.location?.e911Address?.address1,
      locationData?.location?.e911Address?.address2,
      locationData?.location?.e911Address?.city,
      locationData?.location?.e911Address?.state,
      locationData?.location?.e911Address?.country,
      locationData?.location?.e911Address?.postalCode
    ];

    const data = addressFields.filter(Boolean).join(', ');
    setEmergencyAddress(data);
  }, [locationData]);
  const locationDetails = [
    {
      label: 'Emergency Address',
      value: emergencyAddress ? emergencyAddress : '-'
    },
    {
      label: 'Access Hours',
      value: locationData?.location?.accessHours ?? '-'
    },
    {
      label: 'Specific Security Measures',
      value: locationData?.location?.securityMeasures ?? '-'
    },
    {
      label: 'Telco Room(s) Location',
      value: locationData?.location?.telcoRoomsLocation ?? '-'
    },
    {
      label: 'Floor',
      value: locationData?.location?.floor ?? '-'
    },
    {
      label: 'Room #',
      value: locationData?.location?.room ?? '-'
    },
    {
      label: 'New Construction',
      value: locationData?.location?.isNewConstruction ?? '-'
    },
    {
      label: 'Number of Telco Rooms',
      value: locationData?.location?.telcoRooms ?? '-'
    }
  ];
  return (
    <Card>
      <CardHeader title="Site Information" />

      <div className={cc.cardContent}>
        <div className={cc.contentWrapper}>
          {isLoading ? (
            <div className={cc.loading}>Loading...</div>
          ) : (
            <>
              <Typography>
                {' '}
                Created {moment(locationData?.createdAt).format('MM/DD/YYYY')}
              </Typography>
              {locationDetails.map((detail, i) => {
                return (
                  <div key={i} className={cc.rowWrapper}>
                    <Typography color="primary">{detail.label}</Typography>
                    <Typography>{detail.value}</Typography>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      <CardFooter>&nbsp;</CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: theme.spacing(2)
  },
  contentWrapper: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowX: 'hidden',
    position: 'relative',
    height: 237.31,
    overflowY: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 50,
      backgroundColor: theme.palette.grey[50],
      minHeight: 70
    }
  },
  loading: {
    textAlign: 'center'
  },
  rowWrapper: {
    marginTop: theme.spacing(2)
  }
}));

export default LocationSiteInformation;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { selectCurrentUser } from 'store/selectors';
import { useOrgStatusCount } from 'api/useOrgStatus';
import { useOrgs } from 'api/useOrgs';
import OrgStatusBadgeBar from 'app/components/OrgStatusBadgeBar';
import OrganizationActions from 'app/components/OrganizationActions';
import PageHeader from 'app/components/PageHeader';
import { useDashboardSlice } from 'app/layouts';
import { MARKETSPARK_ORG_NAME, pageSizeOptions } from 'constants/app';
import { ColumnProps, Link, SearchInput, TablePaginated, Text } from 'design';
import useSort from 'hooks/useSort';
import { Organization, Sort } from 'types';

const OrganizationsPage = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const urlSearchParams = React.useMemo(() => new URLSearchParams(search), [
    search
  ]);
  const history = useHistory();

  const urlFilter = urlSearchParams.get('filter');
  const [filter, setFilter] = React.useState(urlFilter ? urlFilter : '');

  const urlPage = urlSearchParams.get('page');
  const [pageIndex, setPageIndex] = React.useState(
    urlPage ? parseInt(urlPage) : 1
  );

  const urlPageSize = urlSearchParams.get('limit');
  const [pageSize, setPageSize] = React.useState(
    urlPageSize ? parseInt(urlPageSize) : 10
  );

  const [sort, setSort] = useSort(
    urlSearchParams.get('sort')?.split('|') as Sort
  );

  const urlStatusFilter = urlSearchParams.get('orgStatus');

  const cc = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const prevOrganization = React.useRef(currentUser.organization);
  const { actions } = useDashboardSlice();
  const [searchInput, setSearchInput] = React.useState(filter);

  const getOrgStatusCount = useOrgStatusCount();

  const [statusBadgeFilter, setStatusBadgeFilter] = React.useState<string>(
    urlStatusFilter ? (urlStatusFilter as string) : ''
  );

  const { isLoading, data, error } = useOrgs(
    filter,
    statusBadgeFilter,
    sort,
    pageIndex,
    pageSize
  );

  const { orgs, total } = data || { orgs: [], total: 0 };

  if (error) {
    throw new Error(`${error.message} Failed to fetch organizations!`);
  }

  React.useEffect(() => {
    if (filter === '') {
      urlSearchParams.delete('filter');
    } else {
      urlSearchParams.set('filter', filter);
    }

    if (pageIndex <= 1) {
      urlSearchParams.delete('page');
    } else {
      urlSearchParams.set('page', pageIndex.toString());
    }

    if (pageSize === 10) {
      urlSearchParams.delete('limit');
    } else {
      urlSearchParams.set('limit', pageSize.toString());
    }

    // If sort is the default sort, don't include it in the URL
    if ((sort as Sort) === undefined || sort.join('|') === '|ASC') {
      urlSearchParams.delete('sort');
    } else {
      urlSearchParams.set('sort', sort.join('|'));
    }

    if (statusBadgeFilter === '') {
      setStatusBadgeFilter('');
      urlSearchParams.delete('orgStatus');
    } else {
      setStatusBadgeFilter(statusBadgeFilter);
      urlSearchParams.set('orgStatus', statusBadgeFilter);
    }

    history.push({
      pathname,
      search: urlSearchParams.toString()
    });
  }, [
    filter,
    pageIndex,
    pageSize,
    sort,
    statusBadgeFilter,
    history,
    pathname,
    urlSearchParams
  ]);

  React.useEffect(() => {
    // If the (super) user changes organizations, reset the page index and sort
    if (
      currentUser.organization !== undefined &&
      prevOrganization.current !== undefined &&
      currentUser.organization !== prevOrganization.current
    ) {
      setPageIndex(1);
      setSort('');
    }
    prevOrganization.current = currentUser.organization;
  }, [currentUser, setSort]);

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'name',
        label: 'Name',
        render: ({ id, name }: Organization) => (
          <Link to={`/organization/${id}`}>{name}</Link>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'status.name',
        label: 'Status',
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'primaryContact.firstName',
        label: 'Contact',
        render: ({ organizationUser }: Organization) => {
          const firstName = organizationUser?.user?.firstName ?? '';
          const lastName = organizationUser?.user?.lastName ?? '';
          const usersName = `${firstName} ${lastName}`;
          return (
            <Text>{usersName?.replace(' ', '').length ? usersName : '-'}</Text>
          );
        },
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'primaryContact.email',
        label: 'Email',
        render: ({ organizationUser }: Organization) => {
          return (
            <Text>
              {organizationUser ? (
                <a
                  href={`mailto:${organizationUser?.user.username}`}
                  className={cc.link}
                >
                  {`${organizationUser?.user.username}`}
                </a>
              ) : (
                '-'
              )}
            </Text>
          );
        },
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'locationStatusCount.total',
        label: 'Total',
        render: ({ locationStatusCount }: Organization) => (
          <Text>{locationStatusCount?.total}</Text>
        ),
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'locationStatusCount.online',
        label: 'Online',
        render: ({ locationStatusCount }: Organization) => (
          <Text>{locationStatusCount?.online}</Text>
        ),
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'locationStatusCount.offline',
        label: 'Offline',
        render: ({ locationStatusCount }: Organization) => (
          <Text>{locationStatusCount?.offline}</Text>
        ),
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'locationStatusCount.trouble',
        label: 'Trouble',
        render: ({ locationStatusCount }: Organization) => (
          <Text>{locationStatusCount?.trouble}</Text>
        ),
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: 'locationStatusCount.none',
        label: 'Disabled',
        render: ({ locationStatusCount }: Organization) => (
          <Text>{locationStatusCount?.none}</Text>
        ),
        sortable: true,
        hidden: !currentUser.isSuperAdmin,
        resizable: true
      },
      {
        accessor: '',
        label: '',
        padding: 'normal',
        render: ({
          id,
          name,
          enableNotificationOptIn,
          status
        }: Organization) => (
          <>
            {name && (
              <div>
                <OrganizationActions
                  id={id}
                  orgName={name}
                  enableNotificationOptIn={enableNotificationOptIn}
                  orgStatus={status?.name}
                />
              </div>
            )}
          </>
        ),
        sortable: false,
        hidden: !currentUser.isSuperAdmin
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.isSuperAdmin]
  );

  const handleAddOrg = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Create Organization',
        lazyComponent: 'OrganizationCreate'
      })
    );
  }, [actions, dispatch]);

  return (
    <>
      <Helmet>
        <title> Organizations </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={cc.headWrapper}>
        <PageHeader title="Organizations" />
        <div className={cc.optionsWrapper}>
          <SearchInput
            className={cc.searchInput}
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          {currentUser.isSuperAdmin &&
            currentUser.organization.name === MARKETSPARK_ORG_NAME && (
              <IconButton
                onClick={handleAddOrg}
                data-testid="addButton"
                classes={{ root: cc.addOrganizationButton }}
              >
                <AddCircleIcon
                  color="primary"
                  classes={{ root: cc.addOrganizationIcon }}
                />
              </IconButton>
            )}
        </div>
      </div>
      {currentUser.isSuperAdmin &&
        currentUser.organization.name === MARKETSPARK_ORG_NAME && (
          <OrgStatusBadgeBar
            getOrgStatusCount={getOrgStatusCount}
            statusFilter={statusBadgeFilter}
            setStatusFilter={setStatusBadgeFilter}
            setPageIndex={setPageIndex}
          />
        )}
      <div>
        <TablePaginated
          id="Organizations"
          rows={orgs}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          resizeBehavior="solid"
          pageSizeOptions={pageSizeOptions}
          isLoading={isLoading}
          handleSort={setSort}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    }
  },
  searchInput: {
    margin: theme.spacing(0, 2, 1, 0)
  },
  optionsWrapper: {
    display: 'flex'
  },
  moreVertIcon: {
    color: theme.palette.grey['500'],
    padding: '6px'
  },
  moreVertIconButton: {
    float: 'right',
    marginRight: '5px'
  },
  addOrganizationButton: {
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  addOrganizationIcon: {
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  popper: {
    backgroundColor: '#fff',
    padding: '5px',
    borderColor: '#D6E5EB',
    borderStyle: 'solid',
    border: '2px',
    width: 150,
    cursor: 'pointer',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#D6E5EB'
    }
  },
  link: { textDecoration: 'none', color: '#ad4eff' }
}));

export default OrganizationsPage;

const LS = window.localStorage;
const STORAGE_NAME = 'cmdc_ui';

export function getLS() {
  return JSON.parse(LS.getItem(STORAGE_NAME) || '{}');
}

export function setLS<T>(data: T) {
  const currentData = getLS();
  LS.setItem(STORAGE_NAME, JSON.stringify({ ...currentData, ...data }));
}

export function clearLS() {
  LS.removeItem(STORAGE_NAME);
}

export function clearLSKey(...key: string[]) {
  const currentData = getLS();
  key.forEach(k => delete currentData[k]);
  LS.setItem(STORAGE_NAME, JSON.stringify(currentData));
}

import * as React from 'react';
import {
  CircularProgress,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Button } from 'design';
import { useExportData } from 'api/useExportData';

export interface ExportDataFlowParams {
  cancelExportRequested: () => void;
  typeData: string;
  orgOrAccountId: string | undefined;
}

const ExportDataFlow = ({
  cancelExportRequested,
  typeData,
  orgOrAccountId
}: ExportDataFlowParams): JSX.Element => {
  const cc = useStyles();
  const [shouldPollForDownload, setShouldPollForDownload] = React.useState(
    true
  );

  const { data: progressData } = useExportData(
    orgOrAccountId,
    typeData,
    shouldPollForDownload
  );
  const { progress, url } = progressData ?? { progress: 0, url: '' };

  if (progress === 100 && shouldPollForDownload) {
    setShouldPollForDownload(false);
  }

  if (progress !== null && progress < 100) {
    return (
      <>
        <div
          className={cc.exportIconWrapper}
          data-testid="export-process-loading"
        >
          <CircularProgress size={32} thickness={6} />
        </div>
        <Typography variant="body2" align="center" className={cc.bodyText}>
          Don&apos;t worry, we&apos;re busy fetching your data. Process will
          take a few moments to complete.
        </Typography>
        <Button
          isLoading={false}
          onClick={() => cancelExportRequested()}
          variant="outlined"
        >
          CANCEL
        </Button>
      </>
    );
  }

  return (
    <>
      <div className={cc.exportIconWrapper}>
        <DownloadIcon color="action" />
      </div>
      <Typography variant="body2" align="center" className={cc.bodyText}>
        We’re all done!
        <br /> You can now download your data below.
      </Typography>
      <Button
        isLoading={false}
        onClick={() => cancelExportRequested()}
        href={url}
      >
        DOWNLOAD DATA
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  exportIconWrapper: {
    marginBottom: theme.spacing(1.5)
  },
  bodyText: {
    marginBottom: theme.spacing(3)
  }
}));

export default ExportDataFlow;

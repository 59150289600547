import _ from 'lodash';

export default function trimValuesDeep(input: any) {
  return _.cloneDeepWith(input, value => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString().trim();
    }
    return undefined; //Returning `undefined` will let lodash use its default cloning behavior for other types
  });
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import PageHeader from '../PageHeader';
import { SearchInput } from 'design';
import { selectAuth } from 'app/slice/selectors';
import styles from './HardwareTables.module.scss';

interface HardwareTablesHeaderProps {
  title: string;
  searchInput: string;
  setFilter: (value: string) => void;
  setSearchInput: (value: string) => void;
  setPageIndex: (value: number) => void;
  plusButtonOnClick: () => void;
}

const HardwareTablesHeader = ({
  title,
  searchInput,
  setFilter,
  setSearchInput,
  setPageIndex,
  plusButtonOnClick
}: HardwareTablesHeaderProps): JSX.Element => {
  const { permissions } = useSelector(selectAuth);
  const canCreateEquipment = permissions?.includes('create:equipment');

  const searchInputHandleClear = () => {
    setFilter('');
    setSearchInput('');
    setPageIndex(1);
  };

  const searchInputHandleSearch = () => {
    setFilter(searchInput);
    setPageIndex(1);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={styles.headerContainer}>
        <PageHeader title={title} />
        <div className={styles.headerSearchContainer}>
          <SearchInput
            value={searchInput}
            handleClear={searchInputHandleClear}
            onChange={setSearchInput}
            handleSearch={searchInputHandleSearch}
          />
          {canCreateEquipment && (
            <IconButton onClick={plusButtonOnClick}>
              <AddCircleIcon color="primary" />
            </IconButton>
          )}
        </div>
      </div>
    </>
  );
};

export default HardwareTablesHeader;

import React from 'react';

import { Card, CardContent, Text } from 'design';
import styles from './HelpCenter.module.scss';

export interface DefaultHelpCenterCardProps {
  title: string;
  subTitle: string;
  linkRoute: string;
  icon: JSX.Element;
  className?: string;
}

const DefaultHelpCenterCard = ({
  title,
  subTitle,
  linkRoute,
  icon
}: DefaultHelpCenterCardProps) => {
  return (
    <a href={linkRoute} target="_blank" rel="noreferrer">
      <Card className={styles.cardDialog}>
        <CardContent>
          <div className={styles.cardContent}>
            {icon}
            <Text variant="subtitle2" className={styles.titleText}>
              {title}
            </Text>
            <Text variant="body1">{subTitle}</Text>
          </div>
        </CardContent>
      </Card>
    </a>
  );
};

export default DefaultHelpCenterCard;

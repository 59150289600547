import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  hasPermission: boolean;
  redirectRoute?: string;
}

export default function ProtectedRoute({
  hasPermission,
  component,
  path,
  exact,
  redirectRoute
}: Props) {
  if (!hasPermission) {
    return <Redirect to={redirectRoute || '/dashboard'} />;
  }

  return (
    <Route
      key="EditProfilePage"
      exact={exact}
      path={path}
      component={component}
    />
  );
}

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { editHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';
import {
  powerSourceDropDownInputs,
  powerSourceRequiredInputs,
  powerSourceTextInputs
} from './EquipmentConstants';
import PowerSourceCatalogSchema from './PowerSourceCatalogSchema';
import {
  HardwareCatalog,
  PowersourceCatalog,
  PowerSourceCreateEditData,
  PowerSourceFormikValues
} from 'types';
import validateOnlyEditableFields from '../ValidationEditDialog';

interface PowerSourceEditDialogProps {
  actionedCatalog: HardwareCatalog;
  refetch: () => void;
  handleClose: () => void;
}

const PowerSourceEditDialog = ({
  actionedCatalog,
  refetch,
  handleClose
}: PowerSourceEditDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const actionedPowerSource = actionedCatalog as PowersourceCatalog;

  const powerSourceEditInitialValues: PowerSourceFormikValues = {
    brand: actionedPowerSource.brand,
    model: actionedPowerSource.model,
    power: actionedPowerSource.power,
    maxBatteryCount: actionedPowerSource.maxBatteryCount?.toString() ?? '',
    serialNumberRequired: actionedPowerSource.serialNumberRequired,
    quantityRequired: actionedPowerSource.quantityRequired
  };

  const formik = useFormik({
    initialValues: powerSourceEditInitialValues,
    validationSchema: PowerSourceCatalogSchema(actionedPowerSource.id),
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    const data: PowerSourceCreateEditData = {
      ...formik.values,
      maxBatteryCount: parseInt(formik.values.maxBatteryCount)
    };

    // Check only allowable values have been changed
    if (actionedCatalog.locationCount > 0) {
      const invalidField = validateOnlyEditableFields(
        actionedPowerSource,
        data
      );
      if (invalidField) {
        dispatch(
          actions.showNotify({
            message: `Cannot change ${invalidField} for this powersource as it has locations`,
            severity: 'error'
          })
        );
        return;
      }
    }

    try {
      // API call
      await editHardwareCatalog('powersource', actionedPowerSource.id, data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Power source updated successfully',
          severity: 'success'
        })
      );

      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: error.message,
            severity: 'error'
          })
        );
      } else {
        actions.showNotify({
          message: 'Failed to update power source',
          severity: 'error'
        });
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Power Source"
      type="Edit"
      formik={formik}
      textInputs={powerSourceTextInputs}
      dropDownInputs={powerSourceDropDownInputs}
      requiredInputs={powerSourceRequiredInputs}
      handleClose={handleClose}
    />
  );
};

export default PowerSourceEditDialog;

import React from 'react';
import { Grid } from '@material-ui/core';
import { useOrg } from 'api/useOrgs';
import DashboardOrgLinesCard from 'app/components/DashboardOrgLinesCard';
import DashboardOverviewCard from 'app/components/DashboardOverviewCard';
import OrgProviderCard from 'app/components/NetProviderCard/OrgProviderCard';
import PageHeader from 'app/components/PageHeader';
import { useDashboardSlice } from 'app/layouts';
import { MARKETSPARK } from 'constants/app';
import { CardGrid } from 'design';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/selectors';
import CDRCard from 'app/components/CDRCard';
import {
  E911Card,
  TopLocationsCard,
  TopLinesCard
} from '../../components/DashboardStatisticCards';

const DashboardPage = (): JSX.Element => {
  const user = useSelector(selectCurrentUser);
  const { actions } = useDashboardSlice();
  // NOTE: Feeling like we should just pass org id down to the cards here, versus each having to do that on it's own
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: orgData } = useOrg(user?.organization!.id);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!user?.firstName) {
      dispatch(
        actions.showDialog({
          open: true,
          size: 'xlarge',
          title: 'Complete Your Profile',
          lazyComponent: 'UpdateProfile',
          disableBackdropClick: true
        })
      );
    }
  }, [user, actions, dispatch]);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <PageHeader />
      <Grid container spacing={2} alignItems="stretch">
        <Grid item>
          <CardGrid>
            <DashboardOverviewCard />
            <DashboardOrgLinesCard />
            {user.isSuperAdmin && orgData?.isNewFlow && (
              <OrgProviderCard organizationId={orgData.id} />
            )}
            {orgData &&
              orgData?.name !== MARKETSPARK &&
              (user?.isOrgAdmin || user?.isSuperAdmin) && (
                <CDRCard id={orgData.id} />
              )}
            {orgData &&
              orgData?.name !== MARKETSPARK &&
              (user?.isOrgAdmin || user?.isSuperAdmin) && (
                <TopLocationsCard id={orgData.id} />
              )}
            {orgData &&
              orgData?.name !== MARKETSPARK &&
              (user?.isOrgAdmin || user?.isSuperAdmin) && (
                <TopLinesCard id={orgData.id} />
              )}
            {orgData &&
              orgData?.name !== MARKETSPARK &&
              (user?.isOrgAdmin || user?.isSuperAdmin) && (
                <E911Card id={orgData.id} />
              )}
          </CardGrid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;

import { query } from 'constants/app';
import moment from 'moment';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Sort } from 'types';
import { BundleTemplate, ManagedBy } from 'types';
import axios, { AxiosError } from 'utils/axiosProvider';
import qs from 'qs';

interface GetBundleTemplateParams {
  queryKey: [__0: string, id: string];
}
export interface GetBundleTemplatesParams {
  queryKey: [
    __0: string,
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}
export interface BundleCatalogDependency {
  locations: number;
}

const decanonizeBundleTemplate = (bundleTemplate: any): BundleTemplate => {
  const {
    id,
    name,
    createdBy,
    createdAt,
    routersCatalog,
    gatewaysCatalog,
    powersourcesCatalog,
    status
  } = bundleTemplate;

  const sortByRank = (a: Record<string, any>, b: Record<string, any>) => {
    return a.rank >= b.rank ? 1 : -1;
  };

  return {
    id,
    name,
    createdBy,
    createdAt: moment(createdAt).format('MM/DD/YYYY'),
    routersCatalog: routersCatalog?.sort(sortByRank),
    gatewaysCatalog: gatewaysCatalog?.sort(sortByRank),
    powersourcesCatalog: powersourcesCatalog?.sort(sortByRank),
    status
  };
};
export interface PagedBundleTemplates {
  bundleTemplates: BundleTemplate[];
  total: number;
}

interface BundleTemplatePayload {
  id?: string;
  name: string;
  powersourcesCatalog: {
    powersourceCatalogId: string;
    batteryCount: number;
  }[];

  routersCatalog: {
    routerCatalogId: string;
    isCellular: boolean;
    isWired: boolean;
  }[];

  gatewaysCatalog: {
    gatewayCatalogId: string;
  }[];
}
const getBundleTemplates = async ({ queryKey }: GetBundleTemplatesParams) => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [
          `name|cont|${filter}`,
          `routers_catalog.model|cont|${filter}`,
          `gateways_catalog.model|cont|${filter}`,
          `powersources_catalog.model|cont|${filter}`,
          `status.name|eq|${filter}`
        ]
      }
    : {};
  const { data } = await axios.get('/bundle-catalog', {
    params: {
      ...possibleFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  const bundleTemplates: BundleTemplate[] = data.data.map(
    decanonizeBundleTemplate
  );

  return {
    bundleTemplates,
    total: Number(data.total)
  };
};

const useBundleTemplates = (
  filter: string,
  sort: Sort,
  pageIndex: number,
  pageSize: number
) => {
  return useQuery<PagedBundleTemplates, AxiosError>(
    [query.bundleTemplates, filter, sort, pageIndex, pageSize],
    getBundleTemplates
  );
};

const getBundleTemplate = async ({
  queryKey
}: GetBundleTemplateParams): Promise<BundleTemplate> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/bundle-catalog/${id}`);
  return decanonizeBundleTemplate(data);
};

const useBundleTemplate = (
  id: string
): UseQueryResult<BundleTemplate, Error> => {
  return useQuery<BundleTemplate, AxiosError>(
    [query.bundleTemplate, id],
    getBundleTemplate,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

const postBundleTemplate = async (
  payload: BundleTemplatePayload
): Promise<string> => {
  const { data } = await axios.post(`/bundle-catalog`, payload);
  return data.id;
};

const useCreateBundleTemplate = (): UseMutationResult<
  string,
  AxiosError,
  BundleTemplatePayload
> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<string, AxiosError, BundleTemplatePayload>(
    postBundleTemplate,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.bundleTemplates]);
      }
    }
  );
  return mutatePostOrg;
};

const updateBundleTemplate = async (
  payload: BundleTemplatePayload
): Promise<string> => {
  const { data } = await axios.patch(`/bundle-catalog/${payload.id}`, payload);
  return data.id;
};

const useUpdateBundleTemplate = (): UseMutationResult<
  string,
  AxiosError,
  BundleTemplatePayload
> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<string, AxiosError, BundleTemplatePayload>(
    updateBundleTemplate,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.bundleTemplates]);
      }
    }
  );
  return mutatePostOrg;
};

const deleteBundle = async (id: string): Promise<void> => {
  await axios.delete(`/bundle-catalog/${id}`);
};

const useDeleteBundleTemplate = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteOrg = useMutation<void, AxiosError, any>(deleteBundle, {
    onSettled: () => {
      queryClient.invalidateQueries([query.bundleTemplates]);
    }
  });

  return mutateDeleteOrg;
};

const useBundleTemplateUniqueCheck = (): ((
  bundleTemplate: string
) => Promise<any>) => {
  return async (bundleTemplate: string) => {
    const { data } = await axios.get('/bundle-catalog', {
      params: {
        filter: `name|eq|${bundleTemplate}`
      }
    });
    return data.data.length > 0;
  };
};

const getManagedBy = async (): Promise<ManagedBy[]> => {
  const { data } = await axios.get(`/equipment-owners`, {
    params: {
      page: 1,
      limit: 1000
    }
  });
  return data?.data;
};

const useManagedBy = (): UseQueryResult<ManagedBy[], Error> => {
  return useQuery<ManagedBy[], AxiosError>([query.managedBy], getManagedBy);
};

export const getBundleCatalogDependency = async ({
  queryKey
}: GetBundleTemplatesParams): Promise<BundleCatalogDependency> => {
  const [, bundleCatalogId] = queryKey;
  const { data } = await axios.get(
    `/bundle-catalog/${bundleCatalogId}/dependencies`
  );
  return data;
};

const useBundleCatalogDependency = (
  bundleCatalogId: string
): UseQueryResult<BundleCatalogDependency, Error> => {
  return useQuery<BundleCatalogDependency, AxiosError>(
    [query.bundleTemplateDependencies, bundleCatalogId],
    getBundleCatalogDependency,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

const editBundleCatalogStatus = async (
  bundleCatalogId: string,
  statusId: string
): Promise<void> => {
  await axios.patch(`/bundle-catalog/${bundleCatalogId}/status`, { statusId });
};

export {
  editBundleCatalogStatus,
  useBundleTemplates,
  useBundleTemplate,
  useCreateBundleTemplate,
  useUpdateBundleTemplate,
  useDeleteBundleTemplate,
  useBundleTemplateUniqueCheck,
  useManagedBy,
  useBundleCatalogDependency
};

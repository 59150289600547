import React from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';

interface Props {
  tabValue: number;
  handleTabChange: (_: any, newValue: number) => void;
}

export default function ActiveTabs({ tabValue, handleTabChange }: Props) {
  return (
    <Grid item xs={12}>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Data to use"
      >
        <Tab label="Zoominfo" />
        <Tab label="Custom Locations" />
        <Tab label="Generate Reports" />
        <Tab label="Customers" />
      </Tabs>
    </Grid>
  );
}

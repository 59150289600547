import { useUserUIContext } from 'app/contexts/useUserUIContext';
import * as React from 'react';
import './headwaywidget.scss';

const HeadwayWidget = () => {
  const widget = React.useRef(null);
  const { setUnreadCount } = useUserUIContext();

  React.useEffect(() => {
    if (widget.current) {
      // eslint-disable-next-line
      // @ts-ignore
      window.Headway.init({
        selector: `#hway-widget`,
        account: process.env.REACT_APP_HEADWAY_ACCOUNT,
        trigger: '#changelog',
        callbacks: {
          onWidgetReady: (readyWidget: any) => {
            setUnreadCount(readyWidget.getUnseenCount());
          },
          onShowDetails: () => {
            setUnreadCount(0);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="hway-widget" ref={widget}></div>
    </>
  );
};

export default React.memo(HeadwayWidget);

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

import NavsDropdownListItem, {
  NavsDropdownListItemProps
} from './NavsDropdownListItem';

export interface NavsDropdownProps {
  menuItems: NavsDropdownListItemProps[];
  anchorEl: null | Element;
  handleClose: () => void;
  classes?: any;
  className?: string;
  user?: string;
}

/**
 * NavDropdown Menu
 * @param props
 */
const NavsDropdown = ({
  menuItems,
  anchorEl,
  handleClose,
  classes,
  className,
  user
}: NavsDropdownProps): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const componentClasses = classes ?? useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      /* internal styles: these settings are for the start location of the popup menu so it should be internal always */
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={componentClasses}
      className={className}
    >
      {menuItems.map(
        ({
          label,
          to,
          id,
          isHeader,
          hasSeparator,
          isVisible = true,
          isNotificationAvail
        }: NavsDropdownListItemProps) =>
          isVisible && (
            <NavsDropdownListItem
              label={label}
              id={id}
              to={to}
              isHeader={isHeader}
              hasSeparator={hasSeparator}
              key={label}
              handleClose={handleClose || undefined}
              user={user}
              isNotificationAvail={isNotificationAvail}
            />
          )
      )}
    </Menu>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: '100%',
    maxWidth: 300
  },
  list: {
    paddingTop: 0
  }
}));

export default NavsDropdown;

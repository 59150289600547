/**
 *
 * Error Content
 *
 */
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Purple_City from '../../../assets/img/Purple_City.png';
import Purple_City2x from '../../../assets/img/Purple_City@2x.png';

export interface ErrorContentProps {
  title: string;
  errorMessage: JSX.Element;
  callbackUri?: string;
  className?: any;
}

const ErrorContent = ({
  title,
  errorMessage,
  callbackUri,
  className
}: ErrorContentProps): JSX.Element => {
  const cc = useStyles();
  return (
    <div className={cc.pageContainer}>
      <picture id="page-image" className={cc.thePicture}>
        <source srcSet={Purple_City2x} media="(min-width: 1200px)" />
        <source srcSet={Purple_City} media="(max-width: 1199px)" />
        <img src={Purple_City2x} alt="Page Background" />
      </picture>
      <div className={className ? `${className} ${cc.content}` : cc.content}>
        <h1>{title}</h1>
        {errorMessage}
        {callbackUri ? (
          <span>
            <br />
            <Link to={callbackUri}>Return to Dashboard.</Link>
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    textAlign: 'left'
  },
  thePicture: {
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      top: 0,
      left: 0
    }
  },
  content: {
    '& h1, p': {
      zIndex: 200000
    },
    '& h1': {
      color: theme.palette.common.black
    },
    '& p': {
      color: theme.palette.grey[500],
      font: 'normal normal 18px/21px Inter',
      marginTop: 0,
      marginBottom: 0
    },
    '& span, a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      font: 'normal normal 14px/17px Inter'
    },
    // zIndex: 100000,
    position: 'absolute',
    letterSpacing: '0px',
    maxWidth: 333
  }
}));

export default ErrorContent;

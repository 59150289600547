import { Typography, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { TablePaginated } from 'design';
import { pageSizeOptions } from 'constants/app';
import useSort from 'hooks/useSort';
import useTableSort from 'hooks/useTableSort';
import { uniqBy } from 'lodash';
import {
  ZoomBusinessInfo,
  GeoJsonDataZipcodeMap
} from '@marketspark/ms-utils-and-interfaces';

interface Props {
  businessLocations: ZoomBusinessInfo[];
  disclosureZipcodeMap: GeoJsonDataZipcodeMap;
  businessGeoZipcodeMap: GeoJsonDataZipcodeMap;
  handleCenterMap: (coords: [number, number]) => void;
}

interface TableData {
  id: string;
  address: string;
  zip: string;
  retirementZip: string;
  match: number;
  coordinates: [number, number];
}

// This table data will need to be reusable for real locations
// and businessLocations
// I guess i can just normalize the zoomBusinesses to match real data

function createTableData(
  data: ZoomBusinessInfo[],
  disclosureZipcodeMap: GeoJsonDataZipcodeMap,
  businessGeoZipcodeMap: GeoJsonDataZipcodeMap
): TableData[] {
  const result: TableData[] = data.reduce((acc: TableData[], business) => {
    const zip = business.zip;
    let fullAddress = business.street;
    if (business.street2) {
      fullAddress += ` ${business.street2}`;
    }

    const disclosureGeoInfo = disclosureZipcodeMap[zip];
    const businessGeoInfo = businessGeoZipcodeMap[zip];
    const hasMatches = !!businessGeoInfo?.properties?.impactedStores;

    if (hasMatches) {
      acc.push({
        id: business.id,
        address: fullAddress,
        zip,
        retirementZip: disclosureGeoInfo?.properties?.zip ?? zip,
        match: businessGeoInfo?.properties?.impactedStores ?? 0,
        coordinates: [
          parseFloat(business.longitude),
          parseFloat(business.latitude)
        ]
      });
    }

    return acc;
  }, []);

  const uniqueResult = uniqBy(result, 'address');
  return uniqueResult;
}

export default React.memo(function BusinessLocationsTable({
  disclosureZipcodeMap,
  businessLocations,
  businessGeoZipcodeMap,
  handleCenterMap
}: Props) {
  const cc = useStyles();

  const [sort, setSort] = useSort();
  const [tableData, setTableData] = React.useState<TableData[]>([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);

  const currentRows = React.useMemo(() => {
    const baseValueForPage = (pageIndex - 1) * pageSize;
    const rows = tableData.slice(baseValueForPage, pageSize + baseValueForPage);
    return rows;
  }, [tableData, pageIndex, pageSize]);

  const [sortedRows, handleSort] = useTableSort<TableData>(currentRows, {
    key: 'match',
    direction: 'DESC'
  });

  useEffect(() => {
    const transformedData = createTableData(
      businessLocations,
      disclosureZipcodeMap,
      businessGeoZipcodeMap
    ).sort((a, b) => b.match - a.match);

    setTableData(transformedData);
  }, [businessLocations, businessGeoZipcodeMap, disclosureZipcodeMap]);

  const handleTableSort = (accessor: string) => {
    handleSort(accessor as keyof TableData);
    setSort(accessor);
  };

  const columns = [
    { accessor: 'id', label: 'id', hidden: true },
    { accessor: 'address', label: 'address', sortable: true },
    { accessor: 'zip', label: 'zip', sortable: true },
    { accessor: 'retirementZip', label: 'Retirement Zip', sortable: true },
    { accessor: 'match', label: 'Match', sortable: true }
  ];

  const total = tableData.length;

  return (
    <Paper className={cc.paper} data-testid="businessLcationsTableWrapper">
      <Typography className={cc.title} component="div">
        Impacted Location Details
      </Typography>
      <TablePaginated
        id="businessLocationsTable"
        rows={sortedRows}
        sort={sort}
        total={total}
        columns={columns}
        handlePageSize={setPageSize}
        handlePageNav={setPageIndex}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        handleSort={handleTableSort}
        isLoading={false}
      />
    </Paper>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    maxHeight: 425,
    minWidth: 650,
    overflowY: 'scroll'
  },
  paper: {
    marginTop: '3rem',
    background: theme.palette.grey[300],
    paddingTop: '.2rem',
    minHeight: '100%'
  },
  title: {
    margin: theme.spacing(2),
    fontWeight: 600
  }
}));

import React from 'react';
import { Card, CardHeader, CardContent, CardFooter, Button } from 'design';
import CsvUpload from 'app/components/CsvUpload';
import { useInsertNewDisclosures } from 'api/useDecommissionMap';

import styles from './uploadNewDisclosures.module.scss';

export default function UploadNewDisclosures() {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const {
    isLoading,
    mutateAsync: uploadDisclosures,
    isSuccess
  } = useInsertNewDisclosures();

  const handleSetFile = (csvFile: File): void => {
    setFile(csvFile);
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const submitFile = async () => {
    if (file) {
      await uploadDisclosures(file);
      if (isSuccess) {
        removeFile();
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Upload Disclosures" />
      <CardContent fullHeight>
        <div className={styles.card}>
          <CsvUpload
            file={file}
            setFile={handleSetFile}
            removeFile={removeFile}
          />
          <Button
            className={styles.button}
            onClick={submitFile}
            isLoading={isLoading}
          >
            Upload
          </Button>
        </div>
      </CardContent>
      <CardFooter>{null}</CardFooter>
    </Card>
  );
}

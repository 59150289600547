import {
  createTheme as createMuiTheme,
  unstable_createMuiStrictModeTheme
} from '@material-ui/core';
import { themeConfig } from 'design';

// attempt to silence bs https://github.com/mui-org/material-ui/issues/13394#issuecomment-815452717
const createTheme =
  process.env.NODE_ENV === 'production'
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

const theme = createTheme(themeConfig);

export default theme;

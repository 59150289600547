import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser, Organization, User } from 'types';
import { setLS } from 'utils/localStorage';
import { cleanIsms } from 'utils/transform';

export const initialUser = {
  id: '',
  username: ''
} as CurrentUser;

const slice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    loginUser(state, action: PayloadAction<User>) {
      Object.assign(state, {
        ...action.payload,
        sourceOrg: action.payload.organization
      });
      setLS({
        user: { ...action.payload, sourceOrg: state.sourceOrg }
      });
    },
    setOrg(state, action: PayloadAction<Organization>) {
      state.organization = action.payload;
      setLS({
        user: {
          ...state,
          organization: action.payload
        }
      });
    },
    setUser(state, action: PayloadAction<User>) {
      Object.assign(state, action.payload);
      setLS({
        user: cleanIsms(action.payload)
      });
    },
    logoutUser(state) {
      Object.assign(state, initialUser);
      setLS({ user: state });
    }
  }
});

export const { actions: userActions, reducer: user } = slice;

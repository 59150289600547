export type HardwareCategoryNames =
  | 'Bundle'
  | 'Routers'
  | 'Gateways'
  | 'Batteries'
  | 'Hybrids'
  | 'Communicators';

// These come from the catalog table. Maybe move these into constants/app?
export const HARDWARE_CATEGORY_NAMES: Record<HardwareCategoryNames, string> = {
  Bundle: 'bundle',
  Routers: 'router',
  Gateways: 'ata',
  Batteries: 'battery',
  Hybrids: 'integrated_router',
  Communicators: 'napco'
};

export const HARDWARE_CATEGORY_NAMES_PLURAL: Record<
  HardwareCategoryNames,
  string
> = {
  Bundle: 'bundle',
  Routers: 'routers',
  Gateways: 'gateways',
  Batteries: 'batteries',
  Hybrids: 'hybrids',
  Communicators: 'communicators'
};

export enum HardwareCategoryType {
  'bundle' = 'Bundle',
  'router' = 'Router',
  'ata' = 'Gateway',
  'battery' = 'Battery',
  'integrated_router' = 'Hybrid',
  'napco' = 'Communicator'
}

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Theme, makeStyles } from '@material-ui/core';

export interface IconProps extends SvgIconProps {
  Mark: typeof SvgIcon;
  title?: string;
}

/**
 * Icon wrapper that renders a Material UI Icon passed as props to 'Mark'
 * @param props
 */

const Icon = ({
  viewBox,
  fontSize = 'medium',
  shapeRendering,
  title,
  color = 'inherit',
  className,
  Mark
}: IconProps): JSX.Element => {
  const cc = useStyles();
  return (
    <Mark
      className={className}
      classes={cc}
      titleAccess={title}
      color={color}
      viewBox={viewBox}
      fontSize={fontSize}
      shapeRendering={shapeRendering}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary
  }
}));

export default Icon;

import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { HourglassFull, Sync, SyncProblem } from '@material-ui/icons';
import styles from './providercard.module.scss';

const SyncIcon = ({ status }: { status: string }): JSX.Element => {
  let icon;

  switch (status) {
    case 'setup': {
      icon = <HourglassFull data-testid="setup-icon" />;
      break;
    }
    case 'syncing': {
      icon = <CircularProgress thickness={5} />;
      break;
    }
    case 'synced': {
      icon = <Sync />;
      break;
    }
    case 'errored': {
      icon = <SyncProblem />;
      break;
    }
  }

  return <div className={styles.syncIcon}>{icon}</div>;
};

export default SyncIcon;

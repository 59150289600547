import * as MuiCard from '@material-ui/core/Card';
import * as React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface CardProps extends MuiCard.CardProps {
  children?: string | React.ReactNode;
  fullWidth?: boolean;
}

const MatuiCard = MuiCard.default;

const Card = ({
  children,
  raised = false,
  fullWidth,
  ...props
}: CardProps): JSX.Element => {
  const cc = useStyles();
  const cardClasses = clsx(cc.root, { [cc.fullWidth]: fullWidth });
  return (
    <MatuiCard
      classes={{ root: cardClasses }}
      raised={raised}
      elevation={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </MatuiCard>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: 328,
      marginBottom: 0
    }
  },
  fullWidth: {
    width: '100%'
  }
}));

export default Card;

import * as React from 'react';
import { Status } from 'types';
import { Badge, BadgeBar } from 'design';

export interface StatusBadgeBarProps {
  getStatusCount: any;
  statusFilter: string;
  setStatusFilter: React.Dispatch<React.SetStateAction<'' | Status>>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const StatusBadgeBar = ({
  getStatusCount,
  statusFilter,
  setStatusFilter,
  setPageIndex = () => {}
}: StatusBadgeBarProps): JSX.Element => {
  const statusCount = () => getStatusCount;
  const { isLoading, data, error } = statusCount();

  const { online, offline, trouble, total, none } = data || {
    online: 0,
    offline: 0,
    trouble: 0,
    total: 0,
    none: 0
  };

  if (error) {
    throw new Error(`${error.message} Failed to fetch Locations Statuses!`);
  }
  return (
    <BadgeBar>
      <Badge
        color="info"
        rightLabel="All"
        active={statusFilter === ''}
        handleClick={() => {
          setPageIndex(1);
          setStatusFilter('');
        }}
        isLoading={isLoading}
      >
        {total}
      </Badge>
      <Badge
        color="success"
        rightLabel="Online"
        active={statusFilter === 'Online'}
        handleClick={() => {
          setPageIndex(1);
          setStatusFilter('Online');
        }}
        isLoading={isLoading}
      >
        {online}
      </Badge>
      <Badge
        color="warning"
        rightLabel="Trouble"
        active={statusFilter === 'Trouble'}
        handleClick={() => {
          setPageIndex(1);
          setStatusFilter('Trouble');
        }}
        isLoading={isLoading}
      >
        {trouble}
      </Badge>
      <Badge
        color="error"
        rightLabel="Offline"
        active={statusFilter === 'Offline'}
        handleClick={() => {
          setPageIndex(1);
          setStatusFilter('Offline');
        }}
        isLoading={isLoading}
      >
        {offline}
      </Badge>
      <Badge
        color="secondary"
        rightLabel="No Status"
        active={statusFilter === 'None'}
        handleClick={() => {
          setPageIndex(1);
          setStatusFilter('None');
        }}
        isLoading={isLoading}
      >
        {none}
      </Badge>
    </BadgeBar>
  );
};

export default StatusBadgeBar;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { createHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';
import {
  routerDropDownInputs,
  routerRequiredInputs,
  routerTextInputs
} from './EquipmentConstants';
import RouterCatalogSchema from './RouterCatalogSchema';
import { RouterCreateEditData, RouterFormikValues } from 'types';

interface RouterCreateDialogProps {
  refetch: () => void;
  handleClose: () => void;
}

const RouterCreateDialog = ({
  refetch,
  handleClose
}: RouterCreateDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const routerCreateInitialValues: RouterFormikValues = {
    brand: '',
    model: '',
    power: '',
    wanPorts: '1',
    lanPorts: '4',
    simCount: '2',
    ipAddressRequired: false,
    userNameRequired: false,
    passwordRequired: false,
    serialNumberRequired: false,
    macAddressRequired: false,
    imeiRequired: false
  };

  const formik = useFormik({
    initialValues: routerCreateInitialValues,
    validationSchema: RouterCatalogSchema,
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    try {
      const data: RouterCreateEditData = {
        ...formik.values,
        lanPorts: parseInt(formik.values.lanPorts),
        wanPorts: parseInt(formik.values.wanPorts),
        simCount: parseInt(formik.values.simCount)
      };
      // API call
      await createHardwareCatalog('router', data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Router has been created',
          severity: 'success'
        })
      );

      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: `Failed to create router: ${error.response?.data.message}`,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'Failed to create router',
            severity: 'error'
          })
        );
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Router"
      type="Create"
      formik={formik}
      textInputs={routerTextInputs}
      dropDownInputs={routerDropDownInputs}
      requiredInputs={routerRequiredInputs}
      handleClose={handleClose}
    />
  );
};

export default RouterCreateDialog;

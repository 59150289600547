import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import CallLogIcon from '@material-ui/icons/ContactPhone';
import { Card, CardFooter, CardHeader, CardContent, Button } from 'design';
import { useCreateExportData } from 'api/useExportData';
import { CALL_LOG_DAYS, CALL_LOGS_EXPORT_TYPE } from 'constants/app';
import ExportDataFlow from 'app/components/ExportDataFlow';

export interface LocationCallLogExportCardProps {
  locationId: string;
}

const LocationCallLogExportCard = ({
  locationId
}: LocationCallLogExportCardProps): JSX.Element => {
  const cc = useStyles();
  // imparatively control the progress of export component display
  const [exportRequested, setExportRequested] = React.useState(false);
  const {
    mutateAsync: createLocationExportData,
    isLoading: loadingExportData
  } = useCreateExportData();

  const handleCreateExportData = React.useCallback(async () => {
    if (!locationId) return;
    await createLocationExportData({
      orgOrAccountId: locationId,
      dataType: CALL_LOGS_EXPORT_TYPE
    });
    // whether to display loading or not
    setExportRequested(true);
  }, [locationId, createLocationExportData]);

  return (
    <Card>
      <CardHeader title="Call Detail Records" />
      <CardContent noOverflow>
        {exportRequested ? (
          <div className={cc.contentWrapper}>
            <ExportDataFlow
              cancelExportRequested={() => setExportRequested(false)}
              typeData={CALL_LOGS_EXPORT_TYPE}
              orgOrAccountId={locationId}
            />
          </div>
        ) : (
          <div className={cc.contentWrapper}>
            <CallLogIcon className={cc.reportIcon} color="action" />
            <Button
              isLoading={loadingExportData}
              onClick={handleCreateExportData}
            >
              EXPORT DATA
            </Button>
          </div>
        )}
      </CardContent>

      <CardFooter>
        <Typography variant="body2">
          Call detail records only goes back {CALL_LOG_DAYS} days
        </Typography>
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '9%'
  },
  reportIcon: {
    fontSize: '6rem',
    margin: theme.spacing(0, 0, 2, 0)
  }
}));

export default LocationCallLogExportCard;

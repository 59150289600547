/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import { Dropdown, Text, TextInput } from 'design';
import SerialNumberFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/SerialNumberFieldInput';

import { EquipmentAndHardwareFormsProps } from '../types';
import { getEquipments, PagedEquipment } from 'api/useEquipment';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { POWER_TEC, query } from 'constants/app';

type PowersourceFormProps = {
  locationId?: string;
};

const PowersourceForm = ({
  subName,
  formContext,
  locationId,
  subHeaderText
}: EquipmentAndHardwareFormsProps & PowersourceFormProps) => {
  const { register, formData } = formContext();

  const { data: routersData } = useQuery<PagedEquipment, AxiosError>(
    [query.equipment, locationId, ['', 'router'], ['', 'ASC'], 1, 50],
    getEquipments,
    {
      keepPreviousData: true,
      enabled: !!locationId
    }
  );

  const routers = routersData?.equipment || formData.routers || [];

  //shows managed by dropdown only for powertec battery
  const showRouterDropdown = locationId
    ? _.get(formData, 'catalogBrand') === POWER_TEC
      ? true
      : false
    : true;

  const sub = subName !== undefined ? `powersources.${subName}.` : '';
  const serialNumberInput = register(sub + 'serialNumber');
  const powerSourceManagedBy = register(sub + 'routerId');

  return (
    <div className="form-container">
      <Text className="sub-header">
        {subHeaderText ?? 'Create Power Source'}
      </Text>
      <Grid container className="grid-container">
        <Grid item xs={12} md={6}>
          <div className="input-wrapper">
            <SerialNumberFieldInput
              value={serialNumberInput.value}
              isError={serialNumberInput.isError}
              validationMessage={serialNumberInput.validationMessage}
              onChange={serialNumberInput.onChange}
              onBlur={serialNumberInput.onBlur}
              name={serialNumberInput.name}
            />
            {showRouterDropdown && (
              <Dropdown
                id="powersourcemanagedBy"
                label="Powersource Managed By"
                placeholder="Select One"
                dropdownItems={routers?.map((router: any) => {
                  return {
                    label: router.serialNumber,
                    value: router.id
                  };
                })}
                {...powerSourceManagedBy}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="input-wrapper">
            {_.get(formData, `powersources.${subName}`)?.batteries?.map(
              (__: any, i: number) => {
                const batteryInput = register(
                  `powersources.${subName}.batteries.${i}.batterySerialNumber`
                );
                return (
                  <TextInput
                    id={batteryInput.name}
                    type="text"
                    placeholder="123456789"
                    label={`Battery ${i + 1} serial number`}
                    {...batteryInput}
                  />
                );
              }
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PowersourceForm;

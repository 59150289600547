import * as React from 'react';
import { Grid } from '@material-ui/core';

import {
  getStatusRetrievalOptions,
  INHAND_ROUTER,
  POLL_AND_WEBHOOK_DEVICES,
  POLL_ONLY_DEVICES
} from 'constants/app';
import _ from 'lodash';
import {
  SerialNumberFieldInput,
  MacAddressFieldInput,
  ImeiFieldInput,
  VirtualIpFieldInput,
  UsernameFieldInput,
  PasswordFieldInput
} from 'app/components/EquipmentAndHardwareFieldInputs';
import { EquipmentAndHardwareFormsProps } from '../types';
import { FormDropdown, Text } from 'design';
import AdminStatusForRouterForm from '../AdminStatusForRouterForm';

const RouterForm = ({
  subName: sub,
  formContext,
  updateForm = false,
  subHeaderText
}: EquipmentAndHardwareFormsProps): JSX.Element => {
  const { register, formData } = formContext();

  const subName = sub !== undefined ? `routers.${sub}.` : '';

  const serialNumberInput = register(subName + 'serialNumber');
  const macInput = register(subName + 'mac');
  const imeiInput = register(subName + 'imei');
  const staticIpInput = register(subName + 'ip');
  const usernameInput = register(subName + 'userName');
  const passwordInput = register(subName + 'password');
  const statusRetrieval = register(subName + 'statusRetrieval');

  const catalogBrand = _.get(
    formData,
    subName.length ? `${subName}routerCatalog.brand` : 'catalogBrand'
  );

  React.useEffect(() => {
    statusRetrieval.setValue('');
    if (catalogBrand) {
      if (
        POLL_AND_WEBHOOK_DEVICES.includes(catalogBrand) ||
        POLL_ONLY_DEVICES.includes(catalogBrand)
      ) {
        statusRetrieval.setValue('poll');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogBrand]);

  return (
    <div className={'form-container'}>
      {!updateForm && (
        <Text className={'sub-header'}>{subHeaderText ?? 'Create Router'}</Text>
      )}
      <Grid container className={'grid-container'}>
        <Grid item xs={12} md={6}>
          {updateForm && (
            <div className={'input-wrapper'}>
              <AdminStatusForRouterForm formContext={formContext} />
            </div>
          )}

          <div className={'input-wrapper'}>
            <SerialNumberFieldInput
              value={serialNumberInput.value}
              isError={serialNumberInput.isError}
              validationMessage={serialNumberInput.validationMessage}
              onChange={serialNumberInput.onChange}
              onBlur={serialNumberInput.onBlur}
              name={serialNumberInput.name}
            />
          </div>
          <div className={'input-wrapper'}>
            <ImeiFieldInput
              value={imeiInput.value}
              isError={imeiInput.isError}
              validationMessage={imeiInput.validationMessage}
              onChange={imeiInput.onChange}
              onBlur={imeiInput.onBlur}
              name={imeiInput.name}
            />
          </div>
          <div className={'input-wrapper'}>
            <MacAddressFieldInput
              value={macInput.value}
              isError={macInput.isError}
              validationMessage={macInput.validationMessage}
              onChange={macInput.onChange}
              onBlur={macInput.onBlur}
              name={macInput.name}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {catalogBrand === INHAND_ROUTER && (
            <div className={'input-wrapper'}>
              <UsernameFieldInput
                value={usernameInput.value}
                isError={usernameInput.isError}
                validationMessage={usernameInput.validationMessage}
                onChange={usernameInput.onChange}
                onBlur={usernameInput.onBlur}
                name={usernameInput.name}
              />
            </div>
          )}
          {catalogBrand === INHAND_ROUTER && (
            <div className={'input-wrapper'}>
              <PasswordFieldInput
                value={passwordInput.value}
                isError={passwordInput.isError}
                validationMessage={passwordInput.validationMessage}
                onChange={passwordInput.onChange}
                onBlur={passwordInput.onBlur}
                name={passwordInput.name}
              />
            </div>
          )}
          {catalogBrand === INHAND_ROUTER && (
            <div className={'input-wrapper'}>
              <VirtualIpFieldInput
                value={staticIpInput.value}
                isError={staticIpInput.isError}
                validationMessage={staticIpInput.validationMessage}
                onChange={staticIpInput.onChange}
                onBlur={staticIpInput.onBlur}
                name={staticIpInput.name}
              />
            </div>
          )}
          {getStatusRetrievalOptions(catalogBrand).length > 0 && (
            <div className="input-wrapper">
              <FormDropdown
                id="statusRetrieval"
                label="Status Retrieval"
                placeholder="Select an option"
                dropdownItems={getStatusRetrievalOptions(catalogBrand)}
                formState={statusRetrieval}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default RouterForm;

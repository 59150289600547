import React from 'react';
import { useDispatch } from 'react-redux';

import ToolsDefaultUI from '../ToolsDefaultUI';
import ToolCreateSubscriptionModal from './ToolCreateSubscriptionModal';
import axiosInstance, { AxiosError } from 'utils/axiosProvider';
import { useDashboardSlice } from 'app/layouts';
import { AlertObjectProp } from 'design/Alert/Alert';
import { DialogReason } from 'design/Dialog/Dialog';

const ToolDomainStatus = (): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const [domain, setDomain] = React.useState<string>('');
  const [siteExists, setSiteExists] = React.useState<boolean>(false);
  const [getSiteIsLoading, setGetSiteIsLoading] = React.useState<boolean>(
    false
  );
  const [
    showCreateSubscriptionModal,
    setShowCreateSubscriptionModal
  ] = React.useState<boolean>(false);

  // Constants / Messages
  const constants = {
    name: 'N2P Domain Status',
    label: 'Domain',
    placeHolder: 'ABC123.ms'
  };
  const toastMessages: {
    [key: string]: AlertObjectProp;
  } = {
    subscriptionIsActive: {
      message: `Subscription status for ${domain} is true`,
      severity: 'success'
    }
  };

  // On Domain Submit
  const onSubmit = async () => {
    if (domain.length === 0) {
      return;
    }
    try {
      setGetSiteIsLoading(true);
      // API Call
      const { data } = await axiosInstance.get(
        `n2p/domain/${domain.trim()}/n2pSite`
      );

      // Cannot find domain
      if (data.status === 404) {
        setSiteExists(false);
        setShowCreateSubscriptionModal(true);
        return;
      }

      // Found Domain, Check Subsription Status
      if (data.status === 200) {
        const { data: checkSubscriptions } = await axiosInstance.get(
          `n2p/domain/check-subscriptions/${domain}`
        );

        // Already active
        if (checkSubscriptions.statusSubscriptions) {
          dispatch(actions.showNotify(toastMessages.subscriptionIsActive));
          return;
        }

        // Create Subscription Modal
        setSiteExists(true);
        setShowCreateSubscriptionModal(true);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: err.response?.data.message,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'An error occurred',
            severity: 'error'
          })
        );
      }
    } finally {
      setGetSiteIsLoading(false);
    }
  };

  // Close Modal
  const handleCloseDialog = (
    _event?: React.MouseEvent<HTMLElement> | object,
    reason?: DialogReason
  ) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setShowCreateSubscriptionModal(false);
  };

  return (
    <>
      <ToolsDefaultUI
        name={constants.name}
        label={constants.label}
        placeHolder={constants.placeHolder}
        domain={domain}
        isLoading={getSiteIsLoading}
        setDomain={setDomain}
        onSubmit={onSubmit}
      />
      {showCreateSubscriptionModal && (
        <ToolCreateSubscriptionModal
          open={showCreateSubscriptionModal}
          domain={domain}
          siteExists={siteExists}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};

export default ToolDomainStatus;

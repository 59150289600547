import * as Yup from 'yup';

import {
  hardwareCatalogUniqueModel,
  catchCase
} from '../../../../utils/commonValidations';
import { NUMBER_REGEX } from 'constants/app';

const PowerSourceCatalogSchema = (id?: string) =>
  Yup.object().shape({
    brand: Yup.string().required('Brand is required'),
    model: Yup.string().test(async (value, context) => {
      try {
        return await hardwareCatalogUniqueModel(
          value,
          'powersource',
          context,
          id
        );
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    power: Yup.string().required('Power is required'),
    maxBatteryCount: Yup.string()
      .required('Max Battery Count is required')
      .matches(NUMBER_REGEX, 'Max Battery Count must be a number')
  });

export default PowerSourceCatalogSchema;

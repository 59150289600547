import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { isEmpty } from 'lodash';
import { query } from 'constants/app';
import { Media } from 'types';

export interface PagedMedia {
  media?: Media;
  total: number;
}

interface GetMediasParams {
  queryKey: [__0: string, locationId: string, pageIndex: number];
}

export interface CreatableBase64Media extends Partial<Media> {
  locationId: string;
}

export interface MultiFileMedia {
  images: File[];
  locationId: string;
}

const getLocationMedias = async ({
  queryKey
}: GetMediasParams): Promise<PagedMedia> => {
  const [, locationId, pageIndex] = queryKey;

  const { data } = await axios.get(`/accounts/${locationId}/media`, {
    params: {
      page: pageIndex,
      limit: 1,
      sort: 'createdAt|desc'
    }
  });

  if (isEmpty(data.data)) {
    return { media: undefined, total: Number(data.total) };
  }

  const { metadata, ...fetchedMedia } = data.data[0];

  let uploadedBy;

  // get the uploadedBy user
  if (fetchedMedia.uploadedBy?.id) {
    uploadedBy = {
      id: fetchedMedia.uploadedBy.id,
      firstName: fetchedMedia.uploadedBy.firstName,
      lastName: fetchedMedia.uploadedBy.lastName,
      name: fetchedMedia.uploadedBy.name,
      username: fetchedMedia.uploadedBy.username
    };
  }

  const media: Media = {
    id: fetchedMedia.id,
    url: fetchedMedia.url,
    createdAt: fetchedMedia.createdAt,
    updatedAt: fetchedMedia.updatedAt,
    metadata: {
      encoding: metadata.encoding,
      mimeType: metadata.mimeType,
      originalName: metadata.originalName
    },
    uploadedBy
  };

  return {
    media,
    total: Number(data.total)
  };
};

const useLocationMedia = (
  locationId: string,
  pageIndex: number
): UseQueryResult<PagedMedia, AxiosError> => {
  return useQuery<PagedMedia, AxiosError>(
    [query.medias, locationId, pageIndex],
    getLocationMedias,
    {
      keepPreviousData: true
    }
  );
};

const createLocationMedia = async (
  mediaData: CreatableBase64Media
): Promise<PagedMedia> => {
  const { url, metadata, locationId } = mediaData;
  const { data } = await axios.post('/media/many/base64', {
    files: [{ base64: url, name: metadata?.originalName }]
  });

  if (isEmpty(data[0])) {
    throw new Error('No media created');
  }

  const { data: locationData } = await axios.post(
    `/accounts/${locationId}/media`,
    {
      mediaIds: [data[0].id]
    }
  );

  const { metadata: fetchedMetadata, ...fetchedMedia } = data[0];

  // get who created the file
  let uploadedBy;
  // get the uploadedBy user
  if (fetchedMedia.uploadedBy?.id) {
    uploadedBy = {
      id: fetchedMedia.uploadedBy.id,
      firstName: fetchedMedia.uploadedBy.firstName,
      lastName: fetchedMedia.uploadedBy.lastName,
      name: fetchedMedia.uploadedBy.name,
      username: fetchedMedia.uploadedBy.username
    };
  }

  const media: Media = {
    id: fetchedMedia.id,
    url: fetchedMedia.url,
    createdAt: fetchedMedia.createdAt,
    updatedAt: fetchedMedia.updatedAt,
    metadata: {
      encoding: fetchedMetadata.encoding,
      mimeType: fetchedMetadata.mimeType,
      originalName: fetchedMetadata.originalName
    },
    uploadedBy
  };

  // FYI we're doing some funky mutation here in the creator :O
  return { media, total: Number(locationData.total) + 1 };
};

const createLocationMedias = async (files: MultiFileMedia) => {
  const { images, locationId } = files;

  const fauxForm = new FormData();

  images.forEach((image: File) => {
    // Removes the commas in the file name to resolve Content-Disposition header issues
    fauxForm.append(`files`, image, image.name.replace(/,/g, ''));
  });

  const { data } = await axios.post('/media/many', fauxForm, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  if (isEmpty(data[0])) {
    throw new Error('No media created');
  }

  await axios.post(`/accounts/${locationId}/media`, {
    mediaIds: data.map((d: any) => d.id)
  });
};

const useCreateLocationMedias = () => {
  const queryClient = useQueryClient();

  const mutateMedia = useMutation(createLocationMedias, {
    onMutate: async () => {
      await queryClient.cancelQueries([query.medias]);
    },

    // After success or failure, refetch the media query
    onSettled: () => {
      queryClient.invalidateQueries([query.medias]);
    }
  });

  return mutateMedia;
};

const useCreateLocationMedia = (): UseMutationResult<
  PagedMedia,
  AxiosError,
  CreatableBase64Media
> => {
  const queryClient = useQueryClient();

  const mutateMedia = useMutation<PagedMedia, AxiosError, CreatableBase64Media>(
    createLocationMedia,
    {
      onMutate: async base64Media => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([query.medias, base64Media.locationId]);

        const previousValue = queryClient.getQueryData<PagedMedia>([
          query.medias,
          base64Media.locationId
        ]);

        queryClient.setQueryData(
          [query.medias, base64Media.locationId],
          () => ({
            ...base64Media
          })
        );

        return previousValue;
      },
      // On failure, roll back to the previous value
      onError: (err, base64Media, context) => {
        queryClient.setQueryData(
          [query.medias, base64Media.locationId],
          context
        );
      },
      // After success or failure, refetch the media query
      onSettled: (pagedMedia, error, base64Media) => {
        queryClient.invalidateQueries([query.medias, base64Media.locationId]);
      }
    }
  );

  return mutateMedia;
};

const locationDeleteMedia = async (id: string): Promise<void> => {
  await axios.delete(`/media/${id}`);
};

const useLocationDeleteMedia = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteMedia = useMutation<void, AxiosError, any>(
    locationDeleteMedia,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.medias]);
      }
    }
  );

  return mutateDeleteMedia;
};

export {
  useCreateLocationMedia,
  useCreateLocationMedias,
  useLocationDeleteMedia
};

export default useLocationMedia;

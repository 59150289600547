/**
 *
 * Dashboard
 *
 */
import { Hidden, makeStyles, Theme } from '@material-ui/core';
import Dialog from 'app/components/Dialog';
import Notify from 'app/components/Notify';
import PageLoader from 'app/components/PageLoader';
import { DashRoute } from 'app/routers/DashboardRouter';
import clsx from 'clsx';
import {
  AUTH_LOGOUT_URI,
  dashboardUri,
  IN_CONTRACT,
  MARKETSPARK_EMAIL
} from 'constants/app';
import {
  Appbar,
  Button,
  Footer,
  NavsDropdown,
  NavsDropdownListItemProps,
  Sidebar,
  UserAvatar,
  useSidebar
} from 'design';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/selectors';
import packageJson from '../../../package.json';
import OrgAutoCompleteDropdown from '../components/OrgDropdown/OrgAutoCompleteDropdown';
import { useDashboardSlice } from './slice';
import { HelpCenterDialog } from 'app/components/HelpCenterComponents';
import { UserUIProvider } from 'app/contexts/useUserUIContext';

interface DashboardProps {
  children: React.ReactNode;
  routes: DashRoute[];
}

function Dashboard({ children, routes }: DashboardProps): JSX.Element {
  const cc = useStyles();
  const [open] = useSidebar();
  const contentClasses = clsx(cc.content, {
    [cc.contentDrawerOpen]: open,
    [cc.contentDrawerClose]: !open
  });
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const user = useSelector(selectCurrentUser);
  const anchorRef = React.createRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openHelpCenter, setOpenHelpCenter] = React.useState(false);

  const handleClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Memoized nav links passed as props to menu. See `src/app/routers.
   * DashboardRouter.tsx & AuthRouter.tsx for handled routes
   */

  const menuItems = React.useMemo((): NavsDropdownListItemProps[] => {
    // Only show the Notifications menu option if the org is In Contract, or if the user is a Super Admin.
    // Or, if enableNotificationOptIn is true (an override flag set by Super Admins).
    const showNotifications = () => {
      if (
        user.isSuperAdmin ||
        user.organization?.status?.name === IN_CONTRACT ||
        user.organization?.enableNotificationOptIn
      ) {
        return true;
      } else {
        return false;
      }
    };

    return [
      {
        label: `${user.firstName} ${user.lastName} | ${user.primaryRole.name}`,
        isHeader: true,
        to: ''
      },
      {
        label: `Profile`,
        to: `/me`
      },
      {
        label: `Notifications`,
        to: `/notifications/settings`,
        isVisible: showNotifications()
      },
      {
        label: 'Release Notes',
        to: '#',
        id: 'changelog'
      },
      {
        label: `Log Out`,
        to: AUTH_LOGOUT_URI,
        hasSeparator: true
      }
    ];
  }, [user]);

  React.useEffect(() => {
    dispatch(actions.setSidebarOpen(open));

    return () => {
      handleClose();
    };
  }, [open, dispatch, actions]);

  return (
    <>
      <div className={cc.loaderContainer}>
        <PageLoader />
      </div>
      <div className={cc.root}>
        <Dialog />
        <Notify />
        <Appbar>
          <Button
            variant="text"
            className={cc.helpCenterButton}
            onClick={() => setOpenHelpCenter(true)}
          >
            HELP CENTER
          </Button>
          {user.username.includes(MARKETSPARK_EMAIL) && (
            <Hidden only="xs">
              <div className={cc.orgDropdownContainer}>
                <OrgAutoCompleteDropdown userOrgId="userOrgDropdownMd" />
              </div>
            </Hidden>
          )}
          <UserUIProvider>
            <UserAvatar
              onClick={handleClick}
              ref={anchorRef}
              firstName={user?.firstName}
              lastName={user?.lastName}
            />
            <NavsDropdown
              className={cc.dropDown}
              menuItems={menuItems}
              anchorEl={anchorEl}
              handleClose={handleClose}
              user={user?.firstName}
            />
          </UserUIProvider>
        </Appbar>
        <Sidebar rootUri={dashboardUri} routes={routes} />
        <main className={contentClasses}>
          <div className={cc.contentSpacer} />
          <Hidden smUp>
            <div className={cc.contentSpacerSm} />
          </Hidden>
          <div className={cc.contentInner}>{children}</div>
          <Footer>Command Center v{packageJson.version}</Footer>
        </main>
      </div>
      {openHelpCenter && (
        <HelpCenterDialog handleClose={() => setOpenHelpCenter(false)} />
      )}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', maxWidth: '100vw', overflow: 'hidden' },
  dropDown: {
    marginTop: 20
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    padding: theme.spacing(5, 3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 3, 2)
    },
    width: `100%`
  },
  contentInner: {
    minHeight: `calc( 99.3vh - 62px - ${theme.spacing(11.5)}px)`
  },
  contentDrawerOpen: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.mixins.sidebar.width}px)`
    }
  },
  contentDrawerClose: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(8) + 1}px)`
    }
  },
  contentSpacer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  contentSpacerSm: {
    padding: 20
  },
  loaderContainer: {
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 50000
  },
  orgDropdownContainer: {
    height: 5,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    margin: '-8px 30px -8px -8px'
  },
  helpCenterButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 600,
    textTransform: 'none'
  }
}));

export default Dashboard;

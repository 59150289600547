import { makeStyles, Theme, Typography } from '@material-ui/core';
// TODO: update to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useOrg } from 'api/useOrgs';
import DashboardOrgDataCard from 'app/components/DashboardOrgDataCard';
import OrgProviderCard from 'app/components/NetProviderCard/OrgProviderCard';
import OrgOverviewCard from 'app/components/OrgOverviewCard/OrgOverviewCard';
import { dashboardUri, MARKETSPARK_ORG_NAME } from 'constants/app';
import { Breadcrumbs, CardGrid, OrgAvatar } from 'design';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';

const OrganizationPage = (): JSX.Element => {
  const cc = useStyles();
  const { id: orgId } = useParams<{ id: string }>();
  const { data: orgData } = useOrg(orgId);
  const orgName = orgData ? orgData.name : '';
  const { data: currentUser } = useCurrentUser();

  return (
    <>
      {currentUser?.organization.name === MARKETSPARK_ORG_NAME && (
        <Breadcrumbs
          items={[
            {
              label: 'Organizations',
              url: `/organizations`
            },
            { label: orgName, url: '#' }
          ]}
        />
      )}
      <Helmet>
        <title> Organization </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={cc.header}>
        {orgData?.logo && (
          <Link to={dashboardUri} className={cc.avatarWrapper}>
            <OrgAvatar
              src={orgData?.logo?.url}
              name={orgData?.name}
              className={cc.avatar}
            />
          </Link>
        )}
        <div className={cc.headerText}>
          <div className={cc.headerTextInner}>
            <Typography variant="h4" component="h1">
              {orgData?.name ?? 'Dashboard'}
            </Typography>
          </div>
        </div>
      </div>
      <CardGrid>
        {currentUser?.isUser ? (
          <>
            <DashboardOrgDataCard />
          </>
        ) : currentUser?.isSuperAdmin ? (
          <>
            <OrgOverviewCard />
            <DashboardOrgDataCard />
            {orgData?.isNewFlow && <OrgProviderCard organizationId={orgId} />}
          </>
        ) : (
          <>
            <OrgOverviewCard />
            <DashboardOrgDataCard />
          </>
        )}
      </CardGrid>
    </>
  );
};
export default OrganizationPage;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    margin: theme.spacing(3, 0, 6),
    alignItems: 'center'
  },
  headerText: {
    flexGrow: 1
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: 'auto !important',
    height: 'auto !important',
    maxWidth: '70px',
    maxHeight: '70px'
  },
  avatarWrapper: {
    marginTop: -2
  },
  headerTextInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
  }
}));

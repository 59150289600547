import * as React from 'react';
import MuiCardContent, {
  CardContentProps as MuiCardContentProps
} from '@material-ui/core/CardContent';
import { makeStyles, Theme, CardMedia } from '@material-ui/core';
import clsx from 'clsx';

export interface BackgroundImage {
  alt: string;
  url: string;
}
export interface CardContentProps extends MuiCardContentProps {
  fullHeight?: boolean;
  bgImage?: BackgroundImage;
  noOverflow?: boolean;
  overflowScroll?: boolean;
}

const CardContent = ({
  fullHeight,
  children,
  className,
  bgImage = { alt: '', url: '' },
  noOverflow,
  overflowScroll,
  ...props
}: CardContentProps): JSX.Element => {
  const cc = useStyles();

  const muiCardClasses = clsx(cc.root, className, {
    [cc.cardContent]: !bgImage.alt,
    [cc.mediaCardContent]: !!bgImage.alt,
    [cc.noOverflow]: noOverflow,
    [cc.overflowScroll]: overflowScroll
  });

  const mediaOuter = clsx({ [cc.mediaOuter]: !!bgImage.alt });

  const MediaElement = () => {
    return (
      <CardMedia className={cc.media} title={bgImage.alt} image={bgImage.url} />
    );
  };

  return (
    <div className={mediaOuter}>
      {!bgImage.alt || <MediaElement />}
      <MuiCardContent
        className={muiCardClasses}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {bgImage.alt ? (
          children
        ) : (
          <>
            <div className={cc.heightSplint} />
            <div className={cc.belowSplint}>{children}</div>
          </>
        )}
      </MuiCardContent>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative'
  },
  heightSplint: {
    paddingTop: `calc(66.66%)`,
    zIndex: -1
  },
  belowSplint: {
    position: 'absolute',
    top: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`
  },
  cardContent: {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'relative',
    minHeight: 237.31
  },
  noOverflow: {
    overflow: 'hidden'
  },
  overflowScroll: {
    overflow: 'scroll'
  },
  mediaOuter: {
    backgroundColor: `rgba(0, 0, 0, 0.64)`,
    position: 'relative'
  },
  media: {
    opacity: 1,
    top: 0,
    paddingTop: `calc(66.66% + ${theme.spacing(2.33)}px)`,
    maxWidth: '100%',
    mixBlendMode: 'multiply'
  },
  mediaCardContent: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    paddingBottom: `${theme.spacing(2)}px !important`
  }
}));

export default CardContent;

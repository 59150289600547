import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './Dialog.module.scss';

interface DialogCloseIconProps {
  onReset: () => void;
}

const DialogCloseIcon = ({ onReset }: DialogCloseIconProps) => {
  return (
    <IconButton
      data-testid="closeIcon"
      className={styles.closeButton}
      onClick={onReset}
    >
      <CloseIcon className={styles.closeIcon} fontSize="medium" />
    </IconButton>
  );
};

export default DialogCloseIcon;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import AddHardwareForm from './AddHardwareForm';

import EquipmentBundleCreate from 'app/components/EquipmentBundleCreate/components/EquipmentBundleCreate';
import { usePostCreateEquipment } from 'api/useEquipment';
import { HARDWARE_CATEGORY_NAMES_PLURAL } from '../constants';
import {
  FormProvider,
  Step,
  Stepper,
  useForm,
  useFormContext
} from 'design/Form/Form';
import { Heading } from 'design';
import RouterForm from 'app/components/EquipmentAndHardwareForms/RouterForm';
import CommunicatorForm from 'app/components/EquipmentAndHardwareForms/CommunicatorForm';

import HardwareEquipmentSchema from '../HardwareEquipmentSchema';
import PowersourceForm from 'app/components/EquipmentAndHardwareForms/PowersourceForm';
import HybridForm from 'app/components/EquipmentAndHardwareForms/HybridForm';
import GatewayForm from 'app/components/EquipmentAndHardwareForms/GatewayForm';

import {
  validateGatewayEquipmentFields,
  validatePowersourceEquipmentFields,
  validateRouterEquipmentFields,
  validateCommunicatorEquipmentFields
} from 'utils/schemaValidators/equipmentValidators';
import { convertEmptyStringsToNull } from 'utils/yupHelpers';
import { CatalogItem } from 'types';

interface ChooseHardwareBundleProps {
  locationId: string;
  erpLocationId: string | null;
  domain?: string;
  closeFormHandler: () => void;
}

/**
 * Create One Equipment or a complete Equipment Bundle
 * @param { locationId } props
 * @returns {JSX.Element} JSX.Element
 */
const ChooseHardwareBundle = ({
  locationId,
  erpLocationId,
  domain,
  closeFormHandler
}: ChooseHardwareBundleProps): JSX.Element => {
  const [isCreateBundle, setIsCreateBundle] = React.useState<boolean>(false);
  const [hardwareCategory, setHardwareCategory] = React.useState<string>('');

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const { mutateAsync: postCreateEquipment } = usePostCreateEquipment();

  const initialValues = {
    accountId: locationId,
    catalogItemId: '',
    category: '',
    installStatusId: '',
    ownerId: '',
    imei: '',
    mac: '',
    ip: '',
    domain: '',
    catalogBrand: '',
    catalogItem: {} as CatalogItem,
    userName: '',
    password: '',
    serialNumber: '',
    routerId: '',
    statusRetrieval: ''
  };

  const formContext = useForm({
    initialValues: initialValues,
    validationSchema: HardwareEquipmentSchema(locationId),
    onSubmit: values => handleSubmit(values),
    onClose: closeFormHandler
  });

  const { trigger } = formContext;

  const handleSubmit = async (values: typeof initialValues) => {
    let result: string | undefined;
    try {
      const {
        category,
        catalogItem,
        catalogBrand,
        ip,
        userName,
        routerId,
        ...rest
      } = values;
      result = await postCreateEquipment(
        convertEmptyStringsToNull({
          ...rest,
          staticIp: ip,
          username: userName,
          parentId: routerId
        })
      );
    } catch (e: any) {
      if (e.response?.data?.message) {
        dispatch(
          actions.showNotify({
            message: `Equipment create errors:\n${e.response?.data?.message}`,
            severity: 'error'
          })
        );
      }
    }
    if (result) {
      dispatch(
        actions.showNotify({
          message: 'Equipment created successfully.',
          severity: 'success'
        })
      );
      closeFormHandler();
    }
  };

  const validateHardwareDropdowns = async () => {
    const dropdownsToValidate = [
      'category',
      'catalogItemId',
      'installStatusId',
      'ownerId'
    ];

    const isValid = await trigger(dropdownsToValidate);
    return isValid;
  };

  function renderFormComponent() {
    switch (hardwareCategory.toLowerCase()) {
      case HARDWARE_CATEGORY_NAMES_PLURAL.Routers:
        return (
          <Step name="" validate={validateRouterEquipmentFields}>
            <RouterForm formContext={useFormContext} />
          </Step>
        );

      case HARDWARE_CATEGORY_NAMES_PLURAL.Communicators:
        return (
          <Step name="" validate={validateCommunicatorEquipmentFields}>
            <CommunicatorForm formContext={useFormContext} />
          </Step>
        );
      case HARDWARE_CATEGORY_NAMES_PLURAL.Batteries:
        return (
          <Step name="" validate={validatePowersourceEquipmentFields}>
            <PowersourceForm
              locationId={locationId}
              formContext={useFormContext}
            />
          </Step>
        );
      case HARDWARE_CATEGORY_NAMES_PLURAL.Gateways:
        return (
          <Step name="" validate={validateGatewayEquipmentFields}>
            <GatewayForm
              erpLocationId={erpLocationId}
              domain={domain}
              gatewayBrand={formContext.formData.catalogBrand}
              formContext={useFormContext}
            />
          </Step>
        );
      default:
        return (
          <Step name="" validate={validateGatewayEquipmentFields}>
            <HybridForm formContext={useFormContext} />
          </Step>
        );
    }
  }

  return (
    <>
      {(() => {
        if (isCreateBundle) {
          // When the user selects "Bundle" in the AddHardwareForm page,
          // we jump to the EquipmentBundleCreate form.
          return (
            <EquipmentBundleCreate
              locationId={locationId}
              erpLocationId={erpLocationId}
              domain={domain}
              closeFormHandler={closeFormHandler}
            />
          );
        } else {
          return (
            <>
              <Heading variant="h5">Build Hardware</Heading>
              <FormProvider data={formContext}>
                <Stepper>
                  <Step name="" validate={validateHardwareDropdowns}>
                    <AddHardwareForm
                      setIsCreateBundle={() => {
                        setIsCreateBundle(true);
                      }}
                      setHardwareCategory={setHardwareCategory}
                    />
                  </Step>
                  {renderFormComponent()}
                </Stepper>
              </FormProvider>
            </>
          );
        }
      })()}
    </>
  );
};

export default ChooseHardwareBundle;

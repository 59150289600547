import axios, { AxiosError } from 'utils/axiosProvider';
import _ from 'lodash';
import qs from 'qs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Contact, PostContact, Sort } from 'types';
import { GetManyRes, GetOneArgs } from 'types/GetMany';
import { removeEmptyProps } from 'utils/helpers';

interface ContactOptions {
  queryKey: [
    __0: string,
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

export const getContacts = async (
  filter: string,
  sort: Sort,
  index: number,
  pageSize: number
) => {
  const contactFilter = filter
    ? {
        filter: [
          `firstName|cont|${filter}`,
          `lastName|cont|${filter}`,
          `email|cont|${filter}`,
          `organization.name|cont|${filter}`
        ]
      }
    : {};

  const { data } = await axios.get('/contacts', {
    params: {
      ...contactFilter,
      sort: sort.join('|'),
      page: index,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data;
};

export const getContactsQuery = async ({
  queryKey
}: ContactOptions): Promise<GetManyRes<Contact>> => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;

  return getContacts(filter, sort, pageIndex, pageSize);
};

export const useContacts = (
  filter: string,
  sort: Sort,
  pageIndex: number,
  pageSize: number
) => {
  return useQuery<GetManyRes<Contact>, AxiosError>(
    ['contacts', filter, sort, pageIndex, pageSize],
    getContactsQuery,
    {
      keepPreviousData: true
    }
  );
};

export const getContact = async ({
  queryKey
}: GetOneArgs): Promise<Contact> => {
  const [, id] = queryKey;

  const { data } = await axios.get(`/contacts/${id}`);

  return data;
};

export const useGetContact = (
  id: string
): UseQueryResult<Contact, AxiosError> => {
  return useQuery<Contact, AxiosError>(['contacts', id], getContact);
};

export const createContact = async (
  createData: Partial<PostContact>
): Promise<Contact> => {
  const cleanedData = removeEmptyProps(createData);
  const { data: newContact } = await axios.post('contacts', cleanedData);

  return newContact;
};

export const useCreateContact = (): UseMutationResult<
  Contact,
  AxiosError,
  Partial<PostContact>
> => {
  const queryClient = useQueryClient();

  const mutateCreateContact = useMutation<
    Contact,
    AxiosError,
    Partial<PostContact>
  >(createContact, {
    onSettled: () => {
      queryClient.invalidateQueries(['contacts']);
    }
  });
  return mutateCreateContact;
};

export const patchContact = async (
  updateData: Partial<PostContact>
): Promise<Contact> => {
  const cleanedData = removeEmptyProps(updateData);

  const { data } = await axios.patch(
    `/contacts/${updateData.id}`,
    _.omit(cleanedData, ['id'])
  );

  return data;
};

export const useUpdateContact = (): UseMutationResult<
  Contact,
  AxiosError,
  Partial<PostContact>
> => {
  const mutatePatchContact = useMutation<
    Contact,
    AxiosError,
    Partial<PostContact>
  >(patchContact);

  return mutatePatchContact;
};

import React from 'react';

import { Button } from 'design';
import styles from './CancelSubmitFooter.module.scss';

export interface CancelSubmitFooterProps {
  isLoading: boolean;
  disabled?: boolean;
  cancelButtonOnClick: () => void;
}

const CancelSubmitFooter = ({
  isLoading,
  disabled,
  cancelButtonOnClick
}: CancelSubmitFooterProps): JSX.Element => {
  return (
    <div className={styles.formButtons}>
      <Button
        isLoading={isLoading}
        type="button"
        onClick={cancelButtonOnClick}
        variant="outlined"
      >
        CANCEL
      </Button>
      <Button isLoading={isLoading} disabled={disabled} type="submit">
        CONFIRM
      </Button>
    </div>
  );
};

export default CancelSubmitFooter;

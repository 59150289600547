import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core';

export interface ToolTipProps {
  children: any;
  footer?: React.ReactElement | string;
  body: React.ReactElement | string;
  title?: React.ReactElement | string;
  tipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  openOnClick?: boolean;
  className?: any;
}

const ToolTip = ({
  children,
  title,
  body,
  footer,
  tipPlacement,
  openOnClick,
  className
}: ToolTipProps) => {
  const cc = useStyles();

  return (
    <Tooltip
      interactive
      open={openOnClick}
      classes={{
        arrow: cc.arrow,
        tooltip: cc.tooltip,
        popper: cc.popper
      }}
      className={className}
      title={
        <div className={cc.toolTipElementsWrapper}>
          {title && <div className="header">{title}</div>}
          <div className="body">{body}</div>
          <div className="footer">{footer}</div>
        </div>
      }
      placement={tipPlacement}
      arrow
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
    fontSize: '13px'
  },
  tooltip: {
    padding: '8px',
    maxWidth: 250,
    border: `none`,
    backgroundColor: theme.palette.common.black
  },
  toolTipElementsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  popper: {
    pointerEvents: 'auto'
  }
}));

export default ToolTip;

import React from 'react';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ChooseHardwareBundle from './ChooseHardwareBundle';

import { Dialog as DesignDialog } from 'design';
import { DialogReason } from 'design/Dialog/Dialog';

/**
 * @param {AddHardwareProps} props
 * @returns {JSX.Element} JSX.Element
 */

interface AddHardwareProps {
  locationId: string;
  erpLocationId: string | null;
  domain?: string;
}

const AddHardware = ({
  locationId,
  erpLocationId,
  domain
}: AddHardwareProps): JSX.Element => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const handleCloseDialog = (
    _event: React.MouseEvent<HTMLElement> | object,
    reason?: DialogReason
  ) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setShowDialog(false);
  };

  return (
    <>
      <div className="button-wrapper">
        <IconButton
          onClick={() => {
            setShowDialog(true);
          }}
          data-testid="addButton"
          className="add-hardware-button"
        >
          <AddCircleIcon color="primary" className="add-hardware-icon" />
        </IconButton>
      </div>
      <DesignDialog
        open={showDialog}
        handleClose={(e, reason) => handleCloseDialog}
        size="xlarge"
      >
        <div className="dialog-container">
          <ChooseHardwareBundle
            locationId={locationId}
            erpLocationId={erpLocationId}
            domain={domain}
            closeFormHandler={() => setShowDialog(false)}
          />
        </div>
      </DesignDialog>
    </>
  );
};

export default AddHardware;

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';

const FormikSubmitListener: React.FC = () => {
  const formik = useFormikContext();

  useEffect(() => {
    if (
      !isEqual(formik.values, formik.initialValues) &&
      !Object.entries(formik.errors).length
    ) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.errors, formik.submitForm]);

  return null;
};

export default FormikSubmitListener;

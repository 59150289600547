import { ButtonGroup, Grid } from '@material-ui/core';
import { useUploadDecomClientsCsvFile } from 'api/useDecommissionMap';
import CsvUpload from 'app/components/CsvUpload';
import { Button } from 'design';
import React from 'react';

interface Props {
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  handleRemoveFile: () => void;
}

export default function GenerateReports({
  file,
  setFile,
  handleRemoveFile
}: Props) {
  const {
    isLoading,
    mutateAsync: uploadDecomClientsCsvFile
  } = useUploadDecomClientsCsvFile();

  const handleUploadDecomFile = async () => {
    if (file) {
      await uploadDecomClientsCsvFile(file);
    }
  };

  const hasFile = file !== undefined;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CsvUpload
          setFile={setFile}
          file={file}
          removeFile={handleRemoveFile}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary">
          <Button
            disabled={!hasFile}
            isLoading={isLoading}
            onClick={handleUploadDecomFile}
          >
            Generate Reports
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

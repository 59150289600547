import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import _ from 'lodash';
import axios, { AxiosError } from 'utils/axiosProvider';
import { User, CurrentUser, Organization } from 'types';
import { query, MARKETSPARK_ORG_NAME } from 'constants/app';
import { recursiveEmptyProps } from 'utils/helpers';
import { RoleName } from '../types/AuthRoles';

export interface UpdatableOrg {
  id: string;
  orgId: string;
}

// :sunglasses:
const toCurrentUser = (user: User): CurrentUser => {
  // MS super admin role
  const isSuperAdmin = user.primaryRole.name === RoleName.superAdmin;

  // Org Admin role
  const isOrgAdmin = user.primaryRole.name === RoleName.orgAdmin;

  // Location Admin role
  const isLocationAdmin = user.primaryRole.name === RoleName.locationAdmin;

  // user role
  const isUser = user.primaryRole.name === RoleName.user;

  // MS org
  const isMSOrg = user.organization.name === MARKETSPARK_ORG_NAME;

  // MS Admin or user
  const isOrgAdminOrUser = isOrgAdmin || isUser || isLocationAdmin;

  return _.merge(user, {
    isSuperAdmin,
    isOrgAdmin,
    isLocationAdmin,
    isUser,
    isMSOrg,
    isOrgAdminOrUser
  });
};

const getCurrentUser = async (): Promise<CurrentUser> => {
  const { data } = await axios.get(`/users/me`);

  return toCurrentUser(data);
};

const useCurrentUser = (): UseQueryResult<CurrentUser, Error> => {
  return useQuery<CurrentUser, Error>([query.currentUser], getCurrentUser);
};

export interface UpdatableUser extends Partial<CurrentUser> {
  password?: string;
}

/**
 * This is a patch function for a user's profile. It dynamically constructs updatable
 * information.
 * @returns {Promise}
 * @param userData
 */
const patchCurrentUser = async (
  userData: UpdatableUser
): Promise<CurrentUser> => {
  // WARN: We only pass the primaryRole since we only work with 1 role
  let updateCurrentUser = _.omit(userData, [
    'id',
    'roleId',
    'userRole',
    'authRoleIds'
  ]);

  updateCurrentUser = recursiveEmptyProps(updateCurrentUser);

  const { data } = await axios.patch(`/users/me`, updateCurrentUser);

  return toCurrentUser(data);
};

const useUpdateCurrentUser = (): UseMutationResult<
  UpdatableUser,
  AxiosError,
  UpdatableUser,
  CurrentUser | undefined
> => {
  const queryClient = useQueryClient();

  const mutateCurrentUser = useMutation<
    UpdatableUser,
    AxiosError,
    UpdatableUser,
    CurrentUser | undefined
  >(patchCurrentUser, {
    onSuccess: data => {
      queryClient.setQueryData([query.currentUser], data);
    }
  });

  return mutateCurrentUser;
};

/**
 * Update temporary Organization in Cookies
 * @returns Organization
 * @param user
 */
const patchMeOrg = async (user: UpdatableOrg): Promise<Organization> => {
  const { orgId } = user;
  const { data } = await axios.patch(`/users/tempOrgId/${orgId}`);
  const organization = data.organization;

  return {
    name: organization.name,
    logo: organization.logo,
    id: organization.id,
    status: organization.status,
    enableNotificationOptIn: organization.enableNotificationOptIn
  };
};

/**
 * Wrapper method to update User Organization
 * @return {UseMutationResult}
 */
const useTempOrg = (): UseMutationResult<
  Organization,
  AxiosError,
  UpdatableOrg
> => {
  const queryClient = useQueryClient();
  const mutatePatchUser = useMutation<Organization, AxiosError, UpdatableOrg>(
    patchMeOrg,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([query.locations]);
        queryClient.invalidateQueries([query.organizations]);
        queryClient.invalidateQueries([query.lines]);
        queryClient.invalidateQueries([query.users]);
      }
    }
  );

  return mutatePatchUser;
};

const clearCookies = async () => {
  await axios.delete('/users/clearCookie');
};

export { useUpdateCurrentUser, useTempOrg, clearCookies };

export default useCurrentUser;

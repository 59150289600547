import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { DropdownOption } from 'design';
import clsx from 'clsx';
import Dropdown from 'design/Dropdown';

export interface PaginationDropdownProps {
  currentPage: number;
  pageSize: number;
  total: number;
  pageSizeOptions: DropdownOption[];
  handlePageSize: (pageSize: number) => void;
  handlePageNav: (pageIndex: number) => void;
  className?: string;
}

const PaginationDropdown = ({
  pageSizeOptions,
  handlePageSize,
  handlePageNav,
  currentPage,
  pageSize,
  className = '',
  total
}: PaginationDropdownProps): JSX.Element => {
  const cc = useStyles();
  const [size, setSize] = React.useState<DropdownOption>({
    label: `${pageSize}`,
    value: `${pageSize}`
  });
  const paginationDropdownClasses = clsx(cc.paginationDropdown, {
    [className]: className
  });
  const totalPages = Math.ceil(total / pageSize);

  /**
   * Handles the change on the page size Dropdown. Also watches for running out of
   * rows, in that case it will set to the right most available page.
   * @param {React.SyntheticEvent} e
   */
  const handleChange = (e: any) => {
    const { value } = e;
    let newPage: number = Math.ceil(
      (currentPage * (total / Number(value))) / totalPages
    );
    // check if the new page is great than total amount of pages, if so then
    // set the page to be the last page possible
    newPage = totalPages < newPage ? totalPages : newPage;
    handlePageSize(Number(value));
    handlePageNav(newPage);
    setSize(e);
  };

  return (
    <div className={cc.paginationDropDownRoot}>
      <span>Rows per page </span>
      <Dropdown
        id="selectPage"
        name="selectPage"
        label=""
        className={paginationDropdownClasses}
        isError={false}
        disabled={false}
        value={size.value}
        onChange={handleChange}
        dropdownItems={pageSizeOptions}
        style={{ marginRight: 8 }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paginationDropDownRoot: {
    display: 'flex',
    alignItems: 'center',
    right: 0,
    color: theme.palette.grey['500'],
    [theme.breakpoints.up('sm')]: {
      position: 'absolute'
    },
    '& span': {
      paddingBottom: '24px'
    }
  },
  paginationDropdown: {
    maxWidth: 86,
    '& > div': {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      '& > div > div': {
        paddingRight: '17px !important'
      }
    }
  }
}));

export default PaginationDropdown;

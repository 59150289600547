import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';

import { useDashboardSlice } from 'app/layouts';
import { ActionButton } from 'design';
import { User } from 'types';
import { USER_CREDENTIALS } from '../../../constants/app';
import { RoleName } from '../../../types/AuthRoles';
import { selectCurrentUser } from '../../../store/selectors';

interface UserActionsProp {
  user: User;
}

const UserActions = ({ user }: UserActionsProp): JSX.Element => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const { actions } = useDashboardSlice();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cc = useStyles();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const lineMoreMenuItems = [
    {
      label: 'Edit',
      visible: currentUser.primaryRole.name !== RoleName.locationAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            size: 'xlarge',
            title: 'Edit User',
            lazyComponent: 'UserUpdate',
            lazyComponentProps: {
              id: user.id
            }
          })
        );
      }
    },
    {
      label: 'Delete',
      visible: currentUser.primaryRole.name !== RoleName.locationAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'UserDelete',
            lazyComponentProps: {
              id: user.id,
              firstName: user?.firstName,
              lastName: user?.lastName,
              username: user?.username
            }
          })
        );
      }
    },
    {
      label: 'Reset Password',
      visible: user.organization.authMethod === USER_CREDENTIALS,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            size: 'small',
            title: ``,
            lazyComponent: 'EditPassword',
            lazyComponentProps: {
              email: user?.username,
              updateSelf: false,
              firstName: user?.firstName,
              lastName: user?.lastName
            }
          })
        );
      }
    }
  ];
  return (
    <>
      <div style={{ float: 'right' }}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={cc}
      >
        {lineMoreMenuItems.map(
          ({ label, action, visible }) =>
            visible && (
              <MenuItem
                button
                onClick={() => {
                  action();
                  if (handleMenuClose) {
                    handleMenuClose();
                  }
                }}
                key={label}
                // className={cc.menu}
              >
                {label}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 150
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  }
}));
export default UserActions;

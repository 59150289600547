import React from 'react';
import { Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../../../store/selectors';
import PageHeader from 'app/components/PageHeader';
import { CardGrid } from 'design';
import NotificationSubsLocationCard from 'app/components/NotificationSubsLocationCard';
import NotificationSubsPowerSourceCard from 'app/components/NotificationSubsPowerSourceCard';
import { MARKETSPARK_ORG_NAME } from 'constants/app';
import styles from './notificationsubscriptionspage.module.scss';

const NotificationSubscriptionsPage = (): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <>
      <Helmet>
        <title> Notifications </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <PageHeader title="Notifications" />
      {currentUser?.organization.name === MARKETSPARK_ORG_NAME ? (
        <div className={styles.emptyCard}>
          <Typography className={styles.cardText}>
            Please choose an organization in the org drop-down selector.
          </Typography>
        </div>
      ) : (
        <CardGrid>
          <NotificationSubsLocationCard />
          <NotificationSubsPowerSourceCard />
        </CardGrid>
      )}
    </>
  );
};

export default NotificationSubscriptionsPage;

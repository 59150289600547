import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import React, { forwardRef, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useSidebar } from '../theme/SidebarProvider';

export interface ListLinkProps {
  label: React.ReactNode;
  to: string;
  Icon: typeof SvgIcon;
  classes?: any;
}

const ListLink = ({ label, to, Icon, classes }: ListLinkProps): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const itemClasses = classes ?? useStyles();
  // eslint-disable-next-line
  const [open, setOpen] = useSidebar();

  const memoizedLink = useMemo(
    () =>
      forwardRef<any, Omit<NavLinkProps, 'to'>>((props, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <NavLink ref={ref} exact to={to} {...props} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={memoizedLink}
        className={itemClasses.listItem}
        onClick={() => {
          if (window.innerWidth < 600) {
            setOpen(false);
          }
        }}
      >
        <ListItemIcon className={itemClasses.listIcon}>
          <Icon />
        </ListItemIcon>
        <ListItemText className={itemClasses.listText} disableTypography>
          {label}
        </ListItemText>
      </ListItem>
    </li>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    '&:hover, &.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:hover svg, &.active svg': {
      color: theme.palette.common.white
    }
  },
  listIcon: {
    color: theme.palette.common.white
  },
  listText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    // fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem'
  }
}));

export default ListLink;

import React, { createContext, ReactNode, useContext } from 'react';

export interface UserUIContextProps {
  unreadNotificationCount: number;
  setUnreadCount: (count: number) => void;
}

const UserUIContext = createContext<UserUIContextProps>(
  {} as UserUIContextProps
);

const UserUIProvider = ({ children }: { children: ReactNode }) => {
  const [unreadNotificationCount, setUnreadNotificationCount] = React.useState(
    0
  );

  const setUnreadCount = (count: number) => {
    setUnreadNotificationCount(count);
  };

  return (
    <UserUIContext.Provider value={{ unreadNotificationCount, setUnreadCount }}>
      {children}
    </UserUIContext.Provider>
  );
};

const useUserUIContext = () => {
  return useContext(UserUIContext);
};

export { UserUIProvider, useUserUIContext };

import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { Router } from 'types/Router';
import { AxiosRequestConfig } from 'axios';

interface RouterParams {
  queryKey: [__0: string, id: string];
}

const getRouter = async ({ queryKey }: RouterParams): Promise<Router> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/routers/${id}`);
  return data;
};
const useRouter = (
  id: string,
  pathName: string
): UseQueryResult<Router, Error> => {
  return useQuery<Router, AxiosError>([query.router, id], getRouter, {
    // api to call only if the pathName is router.
    enabled: pathName === 'router'
  });
};

interface GetRoutersParams {
  queryKey: [__0: string, locationId: string, adminStatusName: string];
}

// gets bundle routers
const getRouters = async ({
  queryKey
}: GetRoutersParams): Promise<Router[]> => {
  const [, locationId, adminStatusName] = queryKey;
  const config: AxiosRequestConfig = {};
  if (adminStatusName) {
    config.params = {
      adminStatusName
    };
  }
  const { data } = await axios.get(`/routers/location/${locationId}`, config);
  return data;
};

const useRouters = (
  locationId: string,
  adminStatusName?: string
): UseQueryResult<Router[], AxiosError> => {
  return useQuery<Router[], AxiosError>(
    [query.routers, locationId, adminStatusName],
    getRouters
  );
};

interface Params {
  queryKey: [__0: string, filter: string];
}

const getRoutersByParams = async ({ queryKey }: Params) => {
  const [, filter] = queryKey;
  const { data } = await axios.get('routers', {
    params: { filter: [`bundleId|eq|${filter}`] }
  });

  return data.data;
};

const useRoutersByParams = (
  filter: string
): UseQueryResult<Router[], AxiosError> => {
  return useQuery<Router[], AxiosError>(
    [query.routers, filter],
    getRoutersByParams
  );
};

const deleteRouter = async (id: string): Promise<void> => {
  await axios.delete(`/routers/${id}`);
};

const useDeleteRouter = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteRouter = useMutation<void, AxiosError, any>(deleteRouter, {
    onSettled: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutateDeleteRouter;
};

export { useRouters, getRouters, useDeleteRouter, useRoutersByParams };

export default useRouter;

/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/**
 *
 * Error
 *
 */
import * as React from 'react';
import ErrorContent from 'app/components/ErrorContent';
import { dashboardUri } from 'constants/app';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  error: null | any;
  errorInfo: null | any;
}

// TODO: This needs a production friendlt message!
/**
 * This class component gives us a boundary to catch application's throwing
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: any, errorInfo: any): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render(): React.ReactNode {
    if (this.state.errorInfo) {
      // Error path
      return (
        <ErrorContent
          title="An Error Has Occurred"
          errorMessage={
            <p>
              An unexpected error occurred on our website. The website
              administrator has been notified.
            </p>
          }
          callbackUri={dashboardUri}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

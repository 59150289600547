/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit';
import { isEmpty, omit } from 'lodash';
import { getLS } from 'utils/localStorage';
import { auth } from 'app/slice';
import { dashboard } from 'app/layouts/slice';
import { RootState } from 'types';
import { user } from './userSlice';

// TODO: review if this return type is needed, and add it
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function configureAppStore(initialState: RootState = {}) {
  const localStore = getLS();

  const store = configureStore({
    reducer: { auth, dashboard, user },
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: !isEmpty(localStore)
      ? omit(localStore, 'returnURL')
      : initialState
  });

  return store;
}

export default configureAppStore;

/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import * as React from 'react';
import { Button } from 'design';
import { TextInputBuilderState, DropdownBuilderState } from 'design';
import { FormEvent } from 'react';
import { debounce, isNestedObjectEmpty } from 'utils/yupHelpers';
import { MSFieldInput } from 'types/FieldInput';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { ValidationError } from 'yup';

export type InputBuilderState = TextInputBuilderState | DropdownBuilderState;

export type FormValues<T> = T;
type FormErrors = Record<string, string | undefined>;

export type RegisterProps = {
  value: string;
  name: string;
  validationMessage: string | undefined;
  isError: boolean;
  setValue: (value?: string) => void;
  onChange: (e: MSFieldInput) => void;
  onBlur: (e: MSFieldInput) => void;
};

export type FormTrigger = (
  name: string | string[]
) => Promise<boolean> | boolean;

type UseFormReturn<T> = {
  formData: FormValues<T>;
  setFormData: React.Dispatch<any>;
  formErrors: FormErrors;
  handleChange: (name: string, value: any) => void;
  handleSubmit: (e?: FormEvent) => void;
  resetForm: (initialFormValue: any) => void;
  multiStep?: {
    stepCount: number;
    step: number;
    setStep: React.Dispatch<any>;
    setStepCount: React.Dispatch<any>;
  };
  handleClose: (() => any) | undefined;
  isSubmitting: boolean;
  isValidating: boolean;
  trigger: FormTrigger;
  register: (name: string) => RegisterProps;
};

interface UseForm<T> {
  initialValues?: T;
  multiStep?: {
    stepCount: number;
    setStepCount?: React.Dispatch<any>;
  };
  validateOnSubmit?: boolean;
  onSubmit: (values: FormValues<T>) => void | Promise<void>;
  onClose?: (() => void) | undefined;
  validationSchema?: any;
  validate?: (
    value: string,
    parent: FormValues<T>,
    path: string
  ) => Promise<string | undefined> | string | undefined;
}

export function useForm<T>(config?: UseForm<T>): UseFormReturn<T> {
  const [formData, setFormData] = React.useState(
    config?.initialValues || ({} as T)
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [initValues, setInitValues] = React.useState(
    config?.initialValues || ({} as T)
  );

  //reinitialize initialvalue
  React.useEffect(() => {
    if (config?.initialValues) {
      setFormData({ ..._.merge(formData, config?.initialValues) });
      setInitValues(config?.initialValues);
    }
  }, [!_.isEqual(config?.initialValues, initValues)]);

  const [formErrors, setFormErrors] = React.useState<FormErrors>({});
  const [touched, setTouched] = React.useState<FormErrors>({});
  const [step, setStep] = React.useState<number>(1);
  const [stepCount, setStepCount] = React.useState<number>(
    config?.multiStep?.stepCount || 0
  );
  const [isValidating, setIsValidating] = React.useState(false);
  const validateField = async (schema: any, fieldName: string, value: any) => {
    try {
      await schema.validateAt(fieldName, value);
      // Validation successful
      return undefined;
    } catch (error) {
      //schema.validateAt throws 'ValidationError' and 'Error'. We want to return error message only for ValidationError
      //Some fields do not require validation but validateAt forces us to define a schema for that else it will throw an
      //error as "schema does not contain the path".
      if (error instanceof ValidationError) {
        // Validation failed, return the error message
        return error.message;
      }
      return undefined;
    }
  };

  const validateForm = async (schema: any) => {
    setIsValidating(true);
    let isValid = true;
    await schema.validate(formData, { abortEarly: false }).catch((err: any) => {
      // collect all the errors and touch those fields
      const errors = err.inner.reduce((acc: any, error: any) => {
        return _.set(acc, error.path, error.errors[0]);
      }, {});
      const touchErroredFields = err.inner.reduce((acc: any, error: any) => {
        return _.set(acc, error.path, true);
      }, {});
      setFormErrors(errors);
      setTouched(touchErroredFields);
      isValid = false;
    });
    setIsValidating(false);
    return isValid;
  };

  const validator = async (parent: any, value: string, name: string) => {
    const schema = config?.validationSchema;
    const errorRes = await validateField(schema, name, parent);
    const customValidateFn = config?.validate
      ? await config?.validate(value, parent, name)
      : undefined;
    setFormErrors({
      ...formErrors,
      ..._.set(formErrors, name, errorRes || customValidateFn)
    });
    setIsValidating(false);
    return errorRes || customValidateFn;
  };

  const debouncedValidator = React.useMemo(() => debounce(validator, 500), []);

  const handleChange = async (name: string, value: any) => {
    setFormData({
      ...formData,
      ..._.set(formData as any, name, value)
    });
    setIsValidating(true);
    await debouncedValidator(formData, value, name);
    setIsValidating(false);
  };

  const handleBlur = (name: string) => {
    setTouched(prev => {
      return { ...prev, ..._.set(touched, name, true) };
    });
  };

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault();
    if (config?.validateOnSubmit) {
      //validate the entire form
      const schema = await config?.validationSchema;
      const isValid = await validateForm(schema);
      if (!isValid) {
        return;
      }
    }
    setIsSubmitting(true);
    await config?.onSubmit(formData);
    setIsSubmitting(false);
  };

  const resetForm = (fieldsToReset?: T) => {
    setFormErrors({});
    setTouched({});
    if (fieldsToReset) {
      setFormData({ ...formData, ...fieldsToReset });
    } else {
      setFormData(config?.initialValues || ({} as T));
    }
  };

  const register = (name: string) => {
    const value = _.get(formData, name);
    const error = _.get(formErrors, name);

    const onChange = (e: MSFieldInput) => {
      let changeValue = e?.target?.value;
      if (!e.target && e?.value) {
        changeValue = e?.value;
      }
      handleChange(name, changeValue);
    };

    const onBlur = () => {
      handleBlur(name);
    };

    const setValue = (newValue?: string): void => {
      handleChange(name, newValue);
    };

    return {
      name,
      validationMessage: error,
      isError: !!_.get(touched, name) && !!error,
      value: value || '',
      setValue,
      onChange,
      onBlur
    };
  };

  const trigger = async (name: string | string[]) => {
    if (_.isArray(name)) {
      const triggered = name.map(na => {
        handleBlur(na);
        return validator(formData, _.get(formData, na), na);
      });
      const error = await Promise.all(triggered);
      return isNestedObjectEmpty(error);
    }
    handleBlur(name);
    const error = await validator(formData, _.get(formData, name), name);
    return !!error;
  };
  return {
    formData,
    trigger,
    setFormData,
    formErrors,
    handleChange,
    handleSubmit,
    resetForm,
    isSubmitting,
    handleClose: config?.onClose,
    isValidating,
    multiStep: { step, setStep, stepCount, setStepCount },
    register
  };
}

export type FormContextType = UseFormReturn<any>;

export const FormContext = React.createContext<FormContextType | undefined>(
  undefined
);

export function useFormContext(): FormContextType {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error(`useFormContext must be used within a Provider`);
  }
  return context;
}

export function FormProvider({
  children,
  data
}: {
  children: React.ReactNode;
  data: FormContextType;
}) {
  return <FormContext.Provider value={data}>{children}</FormContext.Provider>;
}

export const Form = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <form>{children}</form>;
};

export type ValidateFunc = <T>(
  err: any,
  path: string,
  trigger: FormTrigger,
  formData?: FormValues<T>
) => Promise<boolean>;

export const Step = ({
  children
}: {
  children: React.ReactNode;
  name: string;
  validate: string[] | ValidateFunc;
}) => {
  return <>{children}</>;
};

export function Stepper({
  children
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const formContext = useFormContext();
  const {
    formErrors,
    trigger,
    isValidating,
    isSubmitting,
    handleClose
  } = formContext;
  const multiStep = formContext.multiStep;
  const steps = React.Children.toArray(children) as React.ReactElement[];
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  if (!multiStep?.step) {
    throw new Error('Step functions missing');
  }

  React.useEffect(() => {
    multiStep?.setStepCount(steps.length);
  }, [steps]);

  const props = steps[multiStep?.step - 1]?.props;

  const errors = _.get(formErrors, props.name);

  const validateFn = async (paths: string[]) => {
    const existingErrors = paths.map(path => {
      return _.get(errors, path);
    });
    if (!isNestedObjectEmpty(existingErrors)) {
      return false;
    }

    const extendedPath = paths.map(p =>
      props.name && props.name !== '' ? `${props.name}.${p}` : p
    );

    const isError = await trigger(extendedPath);
    return isError;
  };

  const nextStep = async () => {
    if (isValidating) {
      return;
    }
    setLoading(true);
    let currentStepValidation = true;
    if (props && props.validate) {
      currentStepValidation =
        typeof props.validate === 'function'
          ? await props?.validate(
              errors,
              props.name,
              trigger,
              formContext.formData
            )
          : await validateFn(props.validate);
    }
    if (!currentStepValidation) {
      setLoading(false);
      return;
    }
    if (multiStep.step < steps.length) {
      multiStep.setStep(multiStep.step + 1);
    }
    if (multiStep.step === steps.length) {
      formContext.handleSubmit();
    }
    setLoading(false);
  };

  const prevStep = () => {
    if (multiStep.step > 1) {
      multiStep.setStep(multiStep.step - 1);
    }
  };

  const handleCancel = () => {
    dispatch(actions.hideDialog());
  };

  return (
    <form>
      <div className="form-container">{steps[multiStep.step - 1]}</div>
      <div className={'form-footer'}>
        <Button variant="outlined" onClick={handleClose || handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={prevStep}
          variant="outlined"
          disabled={multiStep.step === 0}
        >
          Previous
        </Button>
        <Button
          onClick={nextStep}
          isLoading={loading || isValidating || isSubmitting}
        >
          {multiStep.step === steps.length ? 'Submit' : 'Next'}
        </Button>
      </div>
    </form>
  );
}

import * as Yup from 'yup';

import {
  equipmentDomainValidator,
  equipmentImeiValidator,
  equipmentMacValidator,
  equipmentPasswordValidator,
  equipmentSerialNumberValidator,
  equipmentStaticIpValidator,
  equipmentUsernameValidator
} from 'utils/schemaValidators/equipmentValidators';

const HardwareEquipmentSchema = (accountId: string) => {
  const schema = Yup.object().shape(
    {
      category: Yup.string().required('Please select a category'),
      catalogBrand: Yup.object().nullable(),
      catalogItemId: Yup.string().required('Please select a catalog'),
      installStatusId: Yup.string().required(
        'Please select an install status.'
      ),
      routerId: Yup.string().nullable(),
      // parentId: Yup.string().required('Please select a router.'),
      ownerId: Yup.string().required('Please select an owner'),
      domain: equipmentDomainValidator('catalogItem', accountId),
      ip: equipmentStaticIpValidator('catalogItem'),
      serialNumber: equipmentSerialNumberValidator,
      username: equipmentUsernameValidator('catalogItem'),
      password: equipmentPasswordValidator('catalogItem'),
      mac: equipmentMacValidator('catalogItem'),
      imei: equipmentImeiValidator
    },
    [['catalogItem', 'catalogItem']]
  );
  return schema;
};

export default HardwareEquipmentSchema;

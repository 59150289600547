import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  CardFooter,
  CardHeader,
  Progress,
  Link,
  CardContent
} from 'design';
import useLinesStatusCnt from 'api/useLinesStatusCnt';

const DashboardOrgLinesCard = (): JSX.Element => {
  const cc = useStyles();
  const { isLoading, data, error } = useLinesStatusCnt();

  const { online, offline } = data || {
    online: 0,
    offline: 0
  };

  if (error) {
    throw new Error(`${error.message} Failed to fetch Location Line Statuses!`);
  }

  return (
    <Card>
      <CardHeader title="Lines" />
      <CardContent>
        {isLoading ? <Progress thin /> : ''}
        <div className={cc.statusPanelWrapper}>
          <div className={cc.statusRow}>
            <Link
              to={{ pathname: '/lines', state: 'Online' }}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant="h3"
                className={`${cc.statusSection} ${cc.online}`}
              >
                {isLoading ? (
                  <CircularProgress thickness={5} className={cc.loadingIcon} />
                ) : (
                  online
                )}
                <br />
                <Typography className={cc.statusLabel} variant="body2">
                  Online
                </Typography>
              </Typography>
            </Link>
            <Link
              to={{ pathname: '/lines', state: 'Offline' }}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant="h3"
                className={`${cc.statusSection} ${cc.offline}`}
              >
                {isLoading ? (
                  <CircularProgress thickness={5} className={cc.loadingIcon} />
                ) : (
                  offline
                )}
                <br />
                <Typography className={cc.statusLabel} variant="body2">
                  Offline
                </Typography>
              </Typography>
            </Link>
          </div>
        </div>
      </CardContent>

      <CardFooter>
        <Link to={`/lines`}>View Lines</Link>
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  statusPanelWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  statusRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto 0 auto',
    paddingTop: '25%'
  },
  statusSection: {
    textAlign: 'center'
  },
  statusLabel: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(1)
  },
  online: { color: theme.palette.success.main },
  trouble: { color: theme.palette.warning.main },
  offline: { color: theme.palette.error.main },
  loadingIcon: {
    color: theme.palette.common.white,
    width: '26px !important',
    height: '26px !important'
  }
}));

export default DashboardOrgLinesCard;

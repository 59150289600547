import React from 'react';
import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { ActionButton } from 'design';
import styles from './TableAction.module.scss';

export interface TableActionProps {
  menuItems: {
    label: string;
    isVisible: boolean;
    action: () => void;
  }[];
}

const TableAction = ({ menuItems }: TableActionProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  };
  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right'
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOnClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.iconButton}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuOnClose}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: styles.paper, list: styles.list }}
        className={styles.menuBar}
      >
        {menuItems.map(({ label, action, isVisible }, i: number) => {
          return (
            <div key={i}>
              {isVisible && (
                <MenuItem
                  button
                  onClick={() => {
                    action();
                    if (menuOnClose) {
                      menuOnClose();
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default TableAction;

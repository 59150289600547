import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import HeadwayWidget from 'app/components/HeadwayWidget';
export interface NavsDropdownListItemProps {
  label: string;
  to: string;
  id?: string;
  classes?: any;
  isHeader?: boolean;
  hasSeparator?: boolean;
  isNotificationAvail?: boolean;
  handleClose?: () => void;
  user?: string;
  isVisible?: boolean;
}

/**
 * NavDropdown item. This comopnent recieves its forwarded ref's from parent
 * Menu. Ref here is intentionally unused.
 * @param props
 */
const NavsDropdownListItem = React.forwardRef<
  unknown,
  NavsDropdownListItemProps
>(
  (
    {
      label,
      to,
      id,
      classes,
      isHeader,
      hasSeparator,
      handleClose,
      user
    }: NavsDropdownListItemProps,
    ref
  ): JSX.Element => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const componentClasses = classes ?? useStyles();
    const history = useHistory();
    const isHeaderClass = clsx({
      [componentClasses.navListHeader]: isHeader,
      [componentClasses.separatedMenuItem]: hasSeparator
    });
    return (
      <>
        {hasSeparator && (
          <Divider classes={{ root: componentClasses.divider }} />
        )}
        <MenuItem
          button={isHeader ? undefined : true}
          classes={{ root: componentClasses.menuItem }}
          className={isHeaderClass}
          component={isHeader ? 'li' : 'button'}
          onClick={() => {
            history.push(`${to}`);
            if (handleClose) {
              handleClose();
            }
          }}
          id={id}
        >
          {label}
          {label === 'Profile' && !user && (
            <div
              className={`${componentClasses.indicatorWrapper} ${componentClasses.center}`}
            >
              <span
                className={`${componentClasses.indicator} ${componentClasses.center}`}
              >
                1
              </span>
            </div>
          )}
          {id === 'changelog' && (
            <div
              className={`${componentClasses.indicatorWrapper} ${componentClasses.center}`}
            >
              <HeadwayWidget />
            </div>
          )}
        </MenuItem>
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '&:hover': {
      background: theme.palette.info.main
    },
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 'auto',
    width: '100%'
  },
  separatedMenuItem: {
    color: theme.palette.primary.main,
    '&:hover': { color: theme.palette.secondary.main }
  },
  navListHeader: {
    fontWeight: 'bolder'
  },
  divider: {
    margin: '8px 16px'
  },
  indicator: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: '#F71735',
    color: '#fff',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700
  },
  indicatorWrapper: {
    height: 21,
    width: 21
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notificationBadge: {
    color: theme.palette.primary.main,
    fontSize: '21px'
  }
}));

export default NavsDropdownListItem;

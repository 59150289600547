import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';
import { NotificationSubscription } from 'types/NotificationSubscription';

export interface NotificationSubscriptionPayload {
  id: string;
  accountId: string;
  locationName: string;
  notificationType: string;
  notificationCategory: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetNotificationSubsLocationNames {
  locationNames: string[];
  notificationType?: string;
}

export interface PutNotificationSubscriptionPayload {
  accountIds: string[];
  notificationTypes: string[];
}

export interface GetNotificationSubscriptionsParams {
  queryKey: [__0: string, notificationType: string];
}

export interface NotificationTypes {
  notificationType: string;
}

export interface GetNotificationSubscriptionsLocationNames {
  locationNames: string[];
  notificationType: string;
}

const getNotificationSubscriptions = async ({
  queryKey
}: GetNotificationSubscriptionsParams): Promise<NotificationSubscription[]> => {
  const [, notificationType] = queryKey;
  const { data } = await axios.get(`/notification-subscriptions`, {
    params: {
      notificationType
    }
  });

  return data as NotificationSubscription[];
};

const useGetNotificationSubscriptions = (
  notificationType?: string
): UseQueryResult<NotificationSubscription[], AxiosError> => {
  return useQuery<NotificationSubscription[], AxiosError>(
    [query.notificationSubscriptions, notificationType],
    getNotificationSubscriptions,
    {
      keepPreviousData: true
    }
  );
};

const updateNotificationSubscriptions = async (
  subscriptionsData: PutNotificationSubscriptionPayload
): Promise<NotificationSubscriptionPayload[]> => {
  const { data } = await axios.put(
    `/notification-subscriptions`,
    subscriptionsData
  );

  return data?.data;
};

const useUpdateNotificationSubscriptions = (): UseMutationResult<
  NotificationSubscriptionPayload[],
  AxiosError,
  PutNotificationSubscriptionPayload
> => {
  const queryClient = useQueryClient();
  const mutateNotificationSubscriptions = useMutation<
    NotificationSubscriptionPayload[],
    AxiosError,
    PutNotificationSubscriptionPayload
  >(updateNotificationSubscriptions, {
    onSettled: () => {
      queryClient.invalidateQueries([query.notificationSubscriptions]);
    }
  });

  return mutateNotificationSubscriptions;
};

export { useGetNotificationSubscriptions, useUpdateNotificationSubscriptions };

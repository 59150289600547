import React from 'react';
import {
  createTheme as createMuiTheme,
  MuiThemeProvider as ThemeProvider
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import themeConfig from './themeConfig';
import SidebarProvider from './SidebarProvider';

export const theme = createMuiTheme(themeConfig);

interface StoryThemeProps {
  children: React.ReactNode;
}

/**
 * This is the main storybook theme that is also used in snapshot testing as TestTheme.
 * @param {children} ReactNode
 */
const StoryTheme = ({ children }: StoryThemeProps): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SidebarProvider>{children}</SidebarProvider>
  </ThemeProvider>
);

export default StoryTheme;

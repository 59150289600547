import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import {
  Card,
  CardFooter,
  CardHeader,
  CardContent,
  Button,
  Dropdown,
  DropdownOption
} from 'design';
import { useCreateExportData } from 'api/useExportData';
import ExportDataFlow from 'app/components/ExportDataFlow';
import { CALL_LOG_DAYS } from 'constants/app';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/selectors';

const DashboardOrgDataCard = (): JSX.Element => {
  const user = useSelector(selectCurrentUser);
  const { id: organizationId } = useParams<{ id: string }>();
  const cc = useStyles();
  const [typeData, setTypeData] = React.useState<DropdownOption>({
    label: 'Master Data',
    value: 'master'
  });
  // imperatively control the progress of export component display
  const [exportRequested, setExportRequested] = React.useState(false);
  const {
    mutateAsync: createOrgExportData,
    isLoading: loadingExportData
  } = useCreateExportData();
  const csvDownloaderRef = React.useRef<any>();

  const handleCreateExportData = React.useCallback(async () => {
    if (!organizationId) return;
    await createOrgExportData({
      orgOrAccountId: organizationId,
      dataType: typeData.value
    });
    // whether to display loading or not
    setExportRequested(true);
  }, [typeData, organizationId, createOrgExportData]);

  const dropDownItems = [
    { label: 'Organization Data', value: 'master' },
    { label: 'Call Detail Records', value: 'call-logs' }
  ];

  if (user.isSuperAdmin) {
    dropDownItems.push(
      { label: 'SIM Data', value: 'sims' },
      { label: 'Lines Data', value: 'dids' },
      { label: 'Signal Links', value: 'installations' }
    );
  }

  return (
    <Card>
      <CardHeader title="Data" />
      <CardContent noOverflow>
        {exportRequested ? (
          <div className={cc.contentWrapper}>
            <ExportDataFlow
              cancelExportRequested={() => setExportRequested(false)}
              typeData={typeData.value}
              orgOrAccountId={organizationId}
            />
          </div>
        ) : (
          <div className={cc.contentWrapper}>
            <a ref={csvDownloaderRef} style={{ visibility: 'hidden' }} href="/">
              &nbsp;
            </a>
            <Dropdown
              id="ddTypeData"
              name="ddTypeData"
              disabled={loadingExportData}
              isError={false}
              label="CHOOSE DATA TYPE"
              onChange={(e: any) => {
                setTypeData(e);
              }}
              dropdownItems={dropDownItems}
              value={typeData.value}
              placeholder="Select One"
              variant="alt"
              style={{ marginLeft: 0 }}
            />

            <Button
              isLoading={loadingExportData}
              onClick={handleCreateExportData}
              className={cc.ddButton}
            >
              DOWNLOAD
            </Button>
          </div>
        )}
      </CardContent>

      <CardFooter>
        {typeData.value === 'call-logs' ? (
          <Typography variant="body2">
            Call detail records only goes back {CALL_LOG_DAYS} days
          </Typography>
        ) : (
          <>&nbsp;</>
        )}
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10%',
    '& #ddTypeData': {
      backgroundColor: theme.palette.common.white,
      textAlign: 'left'
    }
  },
  ddButton: {
    marginTop: theme.spacing(2.5)
  }
}));

export default DashboardOrgDataCard;

import * as React from 'react';
import useSort from 'hooks/useSort';
import useEquipment from 'api/useEquipment';
import LocationLines from '../LocationLines';
import { useGatewaysByLocation } from 'api/useGateways';
import useCurrentUser from 'api/useCurrentUser';
import LocationIVRLines from '../LocaionIVRLines';
import _ from 'lodash';
import { Typography } from '@material-ui/core';

export interface LocationEquipmentLinesProps {
  locationId: string;
  erpLocationId?: string;
}

const LocationEquipmentLines = ({
  locationId,
  erpLocationId
}: LocationEquipmentLinesProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [sort] = useSort(['dids.port', 'ASC']);
  const [isIVRDidsEmpty, setIVRDidsEmpty] = React.useState<boolean>(false);
  const [displayEmptyCard, setDisplayEmptyCard] = React.useState(false);
  const {
    isLoading: isEquipmentLoading,
    data: equipmentData,
    isError,
    error,
    refetch: equipmentRefetch
  } = useEquipment({ filter: locationId, sort });

  const {
    isError: isGatewayError,
    error: gatewayError,
    isLoading: isGatewayLoading,
    data: gatewayData,
    refetch: gatewayRefetch
  } = useGatewaysByLocation(locationId);

  const { equipment } = equipmentData || { equipment: [], total: 0 };
  const gateways = React.useMemo(() => gatewayData || [], [gatewayData]);

  React.useEffect(() => {
    const equipmentDidsEmpty =
      equipmentData &&
      _.every(equipmentData.equipment, item => _.isEmpty(item.dids));
    const gatewayDidsEmpty =
      gatewayData && _.every(gatewayData, item => _.isEmpty(item.dids));
    const shouldDisplayEmptyCard =
      equipmentDidsEmpty && gatewayDidsEmpty && isIVRDidsEmpty;
    setDisplayEmptyCard(shouldDisplayEmptyCard || false);
  }, [isIVRDidsEmpty, gatewayData, equipmentData]);

  if (isError && error) {
    throw new Error(`${error.message} Failed to fetch Equipments!`);
  }
  if (isGatewayError && gatewayError) {
    throw new Error(`${gatewayError.message} Failed to fetch Gateways!`);
  }

  if (isEquipmentLoading || isGatewayLoading)
    return (
      <div className="empty-card">
        <Typography className="card-text">Loading...</Typography>
      </div>
    );

  return displayEmptyCard ? (
    <div className="empty-card">
      <Typography className="card-text">
        You have no lines at this location.
      </Typography>
    </div>
  ) : (
    <>
      {equipment.map(equip => {
        return (
          <div key={`${equip.id}`}>
            <LocationLines
              locationId={locationId}
              equipment={equip}
              currentUser={currentUser}
              refetch={equipmentRefetch}
              locationErpId={erpLocationId}
            />
          </div>
        );
      })}
      {gateways.map(gateway => {
        return gateway.gatewayCatalog.fxoPorts ? (
          <div key={`${gateway.id}`}>
            <LocationLines
              locationId={locationId}
              gateway={gateway}
              currentUser={currentUser}
              refetch={gatewayRefetch}
              locationErpId={erpLocationId}
            />
          </div>
        ) : (
          <></>
        );
      })}
      <LocationIVRLines
        locationId={locationId}
        setIsIVRLinesEmpty={setIVRDidsEmpty}
      />
    </>
  );
};

export default LocationEquipmentLines;

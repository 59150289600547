import * as Yup from 'yup';

import {
  hardwareCatalogUniqueModel,
  catchCase
} from '../../../../utils/commonValidations';
import { NUMBER_REGEX } from 'constants/app';

const GatewayCatalogSchema = (id?: string) =>
  Yup.object().shape({
    brand: Yup.string().required('Brand is required'),
    model: Yup.string().test(async (value, context) => {
      try {
        return await hardwareCatalogUniqueModel(value, 'gateway', context, id);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    power: Yup.string().required('Power is required'),
    fxoPorts: Yup.string()
      .required('FXO Ports is required')
      .matches(NUMBER_REGEX, 'FXO Ports must be a number')
  });

export default GatewayCatalogSchema;

import React from 'react';
import { IconButton } from '@material-ui/core';

import { Card, CardContent, CardFooter, CardHeader } from '..';
import { ReactComponent as DayFilterIcon } from 'assets/img/IconDayFilter.svg';
import { ReactComponent as WeekFilterIcon } from 'assets/img/IconWeekFilter.svg';
import { ReactComponent as MonthFilterIcon } from 'assets/img/IconMonthFilter.svg';
import { ReactComponent as FilterIcon } from 'assets/img/IconFilter.svg';
import { CDRDaysAgo } from 'types';
import styles from './KPIDashboardCard.module.scss';

interface KPIDashboardCardProps {
  title: string;
  activeIcon: CDRDaysAgo;
  setActiveIcon: (icon: CDRDaysAgo) => void;
  setFilterOpen: (value: boolean) => void;
  children: React.ReactNode;
}

const KPIDashboardCard = ({
  title,
  activeIcon,
  setActiveIcon,
  setFilterOpen,
  children
}: KPIDashboardCardProps): JSX.Element => {
  const headerIcons = [
    {
      icon: (
        <DayFilterIcon
          className={
            activeIcon === CDRDaysAgo.Day ? styles.primaryFill : styles.greyFill
          }
          onClick={() => setActiveIcon(CDRDaysAgo.Day)}
        />
      )
    },
    {
      icon: (
        <WeekFilterIcon
          className={
            activeIcon === CDRDaysAgo.Week
              ? styles.primaryFill
              : styles.greyFill
          }
          onClick={() => setActiveIcon(CDRDaysAgo.Week)}
        />
      )
    },
    {
      icon: (
        <MonthFilterIcon
          className={
            activeIcon === CDRDaysAgo.Month
              ? styles.primaryFill
              : styles.greyFill
          }
          onClick={() => setActiveIcon(CDRDaysAgo.Month)}
        />
      )
    },
    {
      icon: (
        <FilterIcon
          onClick={() => setFilterOpen(true)}
          data-testid="filter-button"
        />
      )
    }
  ];
  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            <>
              {headerIcons.map((item, index) => (
                <IconButton key={index} className={styles.iconWrapper}>
                  {item.icon}
                </IconButton>
              ))}
            </>
          }
        />
        <CardContent>{children}</CardContent>
        <CardFooter>&nbsp;</CardFooter>
      </Card>
    </>
  );
};

export default KPIDashboardCard;

import { getGeoData } from '../api/useGoogleApi';
import { InputAddress } from '../types/Addresses';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator';
import * as Yup from 'yup';
import _ from 'lodash';

// TODO: UT is needed for all the functions below
/**
 * Yup Debouncer
 * @param fn
 * @param time
 * @returns
 */
function debouncer(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  this: any,
  fn: (value: any) => Promise<boolean>,
  time?: number
): (value: any | undefined) => boolean | Promise<boolean> {
  let timer: NodeJS.Timeout;
  return (...args) => {
    return new Promise(resolve => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resolve(fn.apply(this, args));
      }, time ?? 1000);
    });
  };
}

/**
 * Checks string for symbols @,$,!,%,*,#,?,&
 * @param {string} p
 * @returns {boolean}
 */
const specChars = (p: string): boolean => {
  return p.search(/([@$!%*#?&]+)/) > -1;
};
/**
 * Checks string for number characters 0-9
 * @param {string} p
 * @returns {boolean}
 */
const nums = (p: string): boolean => {
  return p.search(/([0-9]+)/) > -1;
};
/**
 * Checks string for uppercase characters A-Z
 * @param {string} p
 * @returns {boolean}
 */
const uppCaseLetter = (p: string): boolean => {
  return p.search(/([A-Z]+)/) > -1;
};
/**
 * Checks string for lowercase characters a-z
 * @param {string} p
 * @returns {boolean}
 */

const lwrCaseLetter = (p: string): boolean => {
  return p.search(/([a-z]+)/) > -1;
};
/**
 * Checks string is longer than or equal to 8 characters
 * @param {string} p
 * @returns {boolean}
 */

const eightChars = (p: string): boolean => {
  return p.length >= 8;
};

/**
 * Uses several functions to determin if the password contains all necessary characters
 * @param {string} value
 * @return {boolean}
 */
const testPassword = (value: string | undefined): boolean => {
  if (!value) {
    return false;
  }
  if (!eightChars(value)) {
    return false;
  }
  const checks = [
    specChars(value),
    nums(value),
    uppCaseLetter(value),
    lwrCaseLetter(value)
  ];

  const foundFalse = checks.some(check => check === false);
  return !foundFalse;
};

const validatePassword = (value: string | undefined): boolean => {
  if (!value) {
    return false;
  }
  if (eightChars(value) && testMinValidation(value)) {
    return true;
  }
  return false;
};

const testMinValidation = (password: string | undefined): boolean => {
  if (!password) {
    return false;
  }
  if (password) {
    const checks = [
      specChars(password),
      nums(password),
      uppCaseLetter(password),
      lwrCaseLetter(password)
    ];

    const foundFalse = false;
    let count = 0;
    for (let index = 0; index < checks.length; index++) {
      const element = checks[index];
      if (element) {
        count++;
      }
    }

    if (count >= 3) {
      return !foundFalse;
    }
  }

  return false;
};

/**
 * Takes a formatted number and
 * @param phoneNumber
 * @returns
 */
const castPhoneAsNumber = (phoneNumber: string): number => {
  if (!phoneNumber) return 0;
  // if starts with one, get string without leading 1
  const parseablePhone =
    phoneNumber.substring(0, 1) === '1'
      ? phoneNumber.substring(1)
      : phoneNumber;
  return Number(`1${parseablePhone.replace(/[(,),\s,-]/gm, '')}`);
};

/**
 * Validates input address with google's response
 * @param address InputAddress
 * @returns {boolean}
 */
const validCityZipCode = async (address: InputAddress): Promise<boolean> => {
  const res = await getGeoData(address);

  if (res.length > 0) {
    if (
      res[0]?.address1?.toLowerCase() ===
        address.address1.toLowerCase().trim() &&
      res[0]?.city?.toLowerCase() === address.city.toLowerCase().trim() &&
      res[0]?.postalCode?.toString() === address.postalCode.trim() &&
      res[0]?.state.toUpperCase() === address.state.trim()
    ) {
      return true;
    }
  }
  return false;
};

const validateUniversalPhoneNumber = (
  value: string | undefined | null
): boolean => {
  if (!!value) {
    if (/^\+[0-9]*$/.test(value)) {
      const validatePhoneNumber = isValidPhoneNumber(value);
      return validatePhoneNumber;
    }
    return false;
  }
  return true;
};

const validateStringDateFormat = async (
  value: string | undefined | null
): Promise<boolean> => {
  // Assumes s is "mm/dd/yyyy"
  if (!!value) {
    if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
      return false;
    }
    const parts = value.split('/').map((p: any) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return (
      d.getMonth() === parts[0] &&
      d.getDate() === parts[1] &&
      d.getFullYear() === parts[2]
    );
  }
  return true;
};

const castPhoneNumberToFormat = async (phoneNumber: string | number) => {
  const phone = phoneNumber?.toString();
  if (phone.toString().length >= 10) {
    const cursor = phone.length === 10 ? 0 : 1;
    const areaCode = `${phone.substring(0 + cursor, 3 + cursor)}`;
    const centralOfficeCode = `${phone.substring(3 + cursor, 6 + cursor)}`;
    const stationNumber = `${phone.substring(6 + cursor, 10 + cursor)}`;
    return `1 (${areaCode}) ${centralOfficeCode}-${stationNumber}`;
  }
  return phone;
};

const validateEmails = async (val: string) => {
  return val
    .split(',')
    .map(email => email.toLowerCase().trim())
    .filter(e => !Yup.string().email().isValidSync(e))
    .join(', ');
};
const checkForDuplicateEmails = async (val: string) => {
  const splittedValue = await val.split(',').map(function (item) {
    return item.trim();
  });
  const compactValues = _.compact(splittedValue);
  if (new Set(compactValues).size !== compactValues.length) return false;

  return true;
};

const postalValidator = (postalCode: any, countryCode: any) => {
  if (
    postalCode &&
    postalCode !== '' &&
    countryCode &&
    countryCode !== '' &&
    postcodeValidatorExistsForCountry(countryCode)
  ) {
    return postcodeValidator(postalCode, countryCode);
  }
  return true;
};

function debounce(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  this: any,
  fn: (...args: any) => any,
  time?: number
): (...args: any | undefined) => any {
  let timer: any;

  return (...args) => {
    return new Promise(resolve => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resolve(fn.apply(this, args));
      }, time ?? 1000);
    });
  };
}

function isNestedObjectEmpty(obj: object): boolean {
  if (!_.isObject(obj)) {
    return true;
  }

  return _.every(obj, value => {
    if (_.isObject(value)) {
      return isNestedObjectEmpty(value);
    }
    return _.isEmpty(value);
  });
}

//This function converts empty or undefined object properties to null
function convertEmptyStringsToNull<T>(obj: T): T {
  return _.mapValues(obj as any, value => {
    if (_.isObject(value)) {
      return convertEmptyStringsToNull(value);
    } else {
      return value === '' || undefined ? null : value;
    }
  }) as any;
}

export {
  castPhoneAsNumber,
  debouncer,
  debounce,
  testPassword,
  postalValidator,
  validatePassword,
  specChars,
  convertEmptyStringsToNull,
  isNestedObjectEmpty,
  nums,
  uppCaseLetter,
  lwrCaseLetter,
  eightChars,
  testMinValidation,
  validCityZipCode,
  validateUniversalPhoneNumber,
  validateStringDateFormat,
  castPhoneNumberToFormat,
  validateEmails,
  checkForDuplicateEmails
};

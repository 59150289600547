import React from 'react';
import { TextField } from '@material-ui/core';
import { useZoomBusinessNameOptions } from 'api/useDecommissionMap';
import { toast } from 'react-toastify';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';

interface Props {
  searchByBusinessName: (value: string) => void;
}

/**
 *
 * @param param
 * @returns
 *
 * Todo: refactor this component to be more generic to take in zipcode and business options
 */

export default function BusinessNamesDropdown({ searchByBusinessName }: Props) {
  const [inputValue, setInputValue] = React.useState('');

  const { isError, error, data } = useZoomBusinessNameOptions(inputValue);
  const options = data?.map(d => d.name) || null;

  const initialDropdownOption = options?.find(value => value === inputValue);

  const [value, setValue] = React.useState<string | null>(
    initialDropdownOption || null
  );

  if (isError && error) {
    toast(error.message, { type: 'error' });
  }

  const handleInputChange = debounce((newInputValue: string) => {
    setInputValue(newInputValue);
  }, 1000);

  const handleDropdownSelect = (newValue: string | null) => {
    setValue(newValue);
    if (newValue !== null) {
      searchByBusinessName(newValue);
    } else {
      searchByBusinessName('');
    }
  };

  return (
    <Autocomplete
      filterOptions={x => x}
      value={value}
      onChange={(_, newValue: string | null) => {
        handleDropdownSelect(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      id="zoomBusinessNamesDropdown"
      data-testid="zoomBusinessNamesDropdown"
      options={options || []}
      style={{ width: 400 }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          value={inputValue}
          label="Search business name"
          variant="outlined"
        />
      )}
    />
  );
}

import * as React from 'react';
import { makeStyles, Theme, IconButton } from '@material-ui/core';
import PlusOne from '@material-ui/icons/AddCircle';
import {
  OpenWith as ExpandGallery,
  ArrowLeft,
  ArrowRight
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import {
  Card,
  CardHeader,
  CardFooter,
  Text,
  Progress,
  CardContent
} from 'design';
import useLocationMedias from 'api/useLocationMedia';
import FormatDate from 'app/components/FormatDate';
import CardNavButton from '../../../design/Card/CardNavButton';
import { ReactComponent as Delete } from '../../../assets/img/delete.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../../assets/img/delete_gray.svg';
import useCurrentUser from '../../../api/useCurrentUser';

export interface LocationMediaCardProps {
  locationId: string;
}

const LocationMediaCard = ({
  locationId
}: LocationMediaCardProps): JSX.Element => {
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const { isLoading, error, data } = useLocationMedias(locationId, pageIndex);
  const { media, total } = data || { media: undefined, total: 0 };
  const { data: currentUser } = useCurrentUser();

  if (error) {
    throw new Error(`Error: ${error}, could not fetch media!`);
  }

  const handleAddMedia = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: 'Add Media',
        lazyComponent: 'LocationAddMedia',
        lazyComponentProps: {
          locationId
        },
        size: 'xlarge'
      })
    );
  }, [actions, dispatch, locationId]);

  const handleDeleteMedia = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: 'Delete Media',
        lazyComponent: 'LocationDeleteMedia',
        lazyComponentProps: {
          media,
          setPageIndex
        },
        size: 'large'
      })
    );
  }, [actions, dispatch, media]);

  const handleExpandGalleryAction = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: `Viewing Media`,
        lazyComponent: 'MediaGallery',
        lazyComponentProps: {
          mediaParentId: locationId,
          currentPage: pageIndex
        },
        closeable: true,
        size: 'xlarge'
      })
    );
  }, [actions, dispatch, locationId, pageIndex]);

  return (
    <Card>
      <CardHeader
        title="Media"
        action={
          <>
            {currentUser?.isSuperAdmin && (
              <>
                <IconButton
                  data-testid={'delete-media'}
                  className={cc.small}
                  aria-label="settings"
                  onClick={handleDeleteMedia}
                  style={{ marginRight: '8px' }}
                  disabled={!media ? true : false}
                >
                  {!media ? <DisabledDeleteIcon /> : <Delete />}
                </IconButton>
                <IconButton
                  data-testid={'add-media'}
                  className={cc.small}
                  aria-label="settings"
                  onClick={handleAddMedia}
                >
                  <PlusOne data-testid="addButton" color="primary" />
                </IconButton>
              </>
            )}
          </>
        }
      />
      {media !== undefined ? (
        <CardContent
          bgImage={{ url: media.url, alt: media.metadata.originalName }}
        >
          {isLoading ? <Progress thin /> : ''}
          <div className={cc.mediaContentInnerLeft}>
            <div className={cc.mediaMetaData}>
              <div>
                <Text>
                  Viewing {pageIndex} of {total}
                </Text>
              </div>
              <div>
                <Text>
                  Submitted <FormatDate>{media.createdAt}</FormatDate>
                  <br />
                  {media.uploadedBy?.firstName} {media.uploadedBy?.lastName}
                </Text>
              </div>
            </div>
          </div>
          <div className={cc.mediaContentInnerRight}>
            <IconButton onClick={handleExpandGalleryAction}>
              <ExpandGallery fontSize="inherit" />
            </IconButton>
          </div>
        </CardContent>
      ) : (
        <CardContent noOverflow>
          <></>
        </CardContent>
      )}
      <CardFooter>
        <CardNavButton
          disabled={pageIndex === 1}
          onClick={() => setPageIndex(old => Math.max(old - 1, 1))}
        >
          <ArrowLeft fontSize="small" color="action" />
        </CardNavButton>
        <CardNavButton
          disabled={total === 0 || pageIndex === total}
          onClick={() => setPageIndex(old => old + 1)}
        >
          <ArrowRight fontSize="small" color="action" />
        </CardNavButton>
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mediaMetaData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
    height: '100%'
  },
  mediaContentInnerLeft: {
    flexGrow: 1
  },
  mediaContentInnerRight: {
    fontSize: 24,
    '& button': {
      padding: '0 !important',
      marginTop: theme.spacing(-2)
    }
  },
  small: {
    width: 24,
    height: 24,
    padding: 0,
    '& svg': {
      width: 24,
      height: 24
    },
    '&:hover': {
      background: 'none'
    }
  }
}));

export default LocationMediaCard;

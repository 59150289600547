import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

export interface SidebarToggleProps {
  isOpen: boolean;
  handleToggle(): void;
}

const SidebarToggle = ({
  isOpen,
  handleToggle
}: SidebarToggleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <IconButton
      classes={classes}
      size="small"
      color="primary"
      aria-label="Toggle Sidebar"
      onClick={handleToggle}
      component="div"
    >
      {isOpen ? <ArrowLeft /> : <ArrowRight />}
    </IconButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& svg': { color: theme.palette.primary.main }
    }
  }
}));

export default SidebarToggle;

import React from 'react';

import { useRouterCatalogs } from 'api/useHardwareCatalogs';
import {
  HardwareCatalogDefaultPage,
  RouterEditDialog,
  RouterCreateDialog
} from 'app/components/HardwareTables';
import { ColumnProps } from '../../../design';

const RoutersPage = (): JSX.Element => {
  const routerColumns: ColumnProps[] = [
    {
      accessor: 'brand',
      label: 'Brand',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'model',
      label: 'Model',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'lanPorts',
      label: 'LAN Ports',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'wanPorts',
      label: 'WAN Ports',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'simCount',
      label: 'SIM Count',
      sortable: true,
      resizable: true
    }
  ];

  return (
    <HardwareCatalogDefaultPage
      title="Routers"
      deviceColumns={routerColumns}
      getCatalogsHook={useRouterCatalogs}
      CreateDialog={RouterCreateDialog}
      EditDialog={RouterEditDialog}
    />
  );
};

export default RoutersPage;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { editHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';
import {
  routerDropDownInputs,
  routerRequiredInputs,
  routerTextInputs
} from './EquipmentConstants';
import RouterCatalogSchema from './RouterCatalogSchema';
import {
  HardwareCatalog,
  RouterCatalog,
  RouterCreateEditData,
  RouterFormikValues
} from 'types';
import validateOnlyEditableFields from '../ValidationEditDialog';

interface RouterEditDialogProps {
  actionedCatalog: HardwareCatalog;
  refetch: () => void;
  handleClose: () => void;
}

const RouterEditDialog = ({
  actionedCatalog,
  refetch,
  handleClose
}: RouterEditDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const actionedRouter = actionedCatalog as RouterCatalog;

  const routerEditInitialValues: RouterFormikValues = {
    brand: actionedRouter.brand,
    model: actionedRouter.model,
    power: actionedRouter.power,
    wanPorts: actionedRouter.wanPorts?.toString() ?? '',
    lanPorts: actionedRouter.lanPorts?.toString() ?? '',
    simCount: actionedRouter.simCount?.toString() ?? '',
    ipAddressRequired: actionedRouter.ipAddressRequired,
    userNameRequired: actionedRouter.userNameRequired,
    passwordRequired: actionedRouter.passwordRequired,
    serialNumberRequired: actionedRouter.serialNumberRequired,
    macAddressRequired: actionedRouter.macAddressRequired,
    imeiRequired: actionedRouter.imeiRequired
  };

  const formik = useFormik({
    initialValues: routerEditInitialValues,
    validationSchema: RouterCatalogSchema(actionedRouter.id),
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    try {
      const data: RouterCreateEditData = {
        ...formik.values,
        lanPorts: parseInt(formik.values.lanPorts),
        wanPorts: parseInt(formik.values.wanPorts),
        simCount: parseInt(formik.values.simCount)
      };

      // Check only allowable values have been changed
      if (actionedCatalog.locationCount > 0) {
        const invalidField = validateOnlyEditableFields(actionedRouter, data);
        if (invalidField) {
          dispatch(
            actions.showNotify({
              message: `Cannot change ${invalidField} for this powersource as it has locations`,
              severity: 'error'
            })
          );
          return;
        }
      }

      // API call
      await editHardwareCatalog('router', actionedRouter.id, data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Router has been edited',
          severity: 'success'
        })
      );

      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: `Failed to edit router: ${error.response?.data.message}`,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'Failed to edit router',
            severity: 'error'
          })
        );
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Router"
      type="Edit"
      formik={formik}
      textInputs={routerTextInputs}
      dropDownInputs={routerDropDownInputs}
      requiredInputs={routerRequiredInputs}
      handleClose={handleClose}
    />
  );
};

export default RouterEditDialog;

/**
 *
 * AuthRouter
 *
 * This component is for the authUri routes.
 * It allows the routes coming from the auth path
 * to be visible for anyone.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthCallback from 'app/components/AuthCallback';
import Logout from 'app/components/Logout';
import Login from 'app/components/Login';
import {
  AUTH_LOGIN_URI,
  AUTH_LOGOUT_URI,
  AUTH_CALLBACK_URI,
  AUTH_ERROR_URI
} from 'constants/app';
import AuthError from 'app/components/AuthError';

const AuthRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route path={AUTH_LOGIN_URI} exact component={Login} />
      <Route path={AUTH_CALLBACK_URI} exact component={AuthCallback} />
      <Route path={AUTH_LOGOUT_URI} exact component={Logout} />
      <Route path={AUTH_ERROR_URI} exact component={AuthError} />
    </Switch>
  );
};

export default AuthRouter;

import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import qs from 'qs';

import { query } from 'constants/app';
import { CallDirection, CDR, Sort } from 'types';
import { GetManyRes } from 'types';

export const E911EmergencyFilter = 'e911EmergencyFilter';

interface GetCDRsKey {
  queryKey: [
    __0: string,
    searchFilter: string,
    idFilter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number,
    number: string,
    days: number,
    direction: CallDirection,
    statistics: boolean,
    group: string
  ];
}

const getCdrs = async ({ queryKey }: GetCDRsKey) => {
  const [
    ,
    searchFilter,
    idFilter,
    sort,
    pageIndex,
    pageSize,
    number,
    days,
    direction,
    statistics,
    group
  ] = queryKey;

  let filter: string[] | null = null;
  if (searchFilter) {
    if (searchFilter === E911EmergencyFilter) {
      filter = [`called|eq|911`, `called|eq|+911`];
    } else {
      filter = [`called|cont|${searchFilter}`, `calling|cont|${searchFilter}`];
    }
  }

  const andfilter = idFilter
    ? [`locationId|eq|${idFilter}`, `orgId|eq|${idFilter}`]
    : null;

  const sortParam = sort ? sort.join('|') : null;

  const { data } = await axios.get(`/cdrs`, {
    params: {
      filter,
      andfilter,
      sort: sortParam,
      page: pageIndex,
      limit: pageSize,
      number,
      days,
      direction,
      statistics,
      group
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data;
};

const useCdrs = ({
  searchFilter,
  idFilter,
  sort,
  pageIndex,
  pageSize,
  number,
  days,
  direction,
  statistics,
  group
}: {
  searchFilter?: string;
  idFilter?: string;
  sort?: Sort;
  pageIndex?: number;
  pageSize?: number;
  number?: string;
  days?: number;
  direction?: CallDirection;
  statistics?: boolean;
  group?: string;
}): UseQueryResult<GetManyRes<CDR>, AxiosError> => {
  return useQuery<GetManyRes<CDR>, AxiosError>(
    [
      query.lineCallLogs,
      searchFilter,
      idFilter,
      sort,
      pageIndex,
      pageSize,
      number,
      days,
      direction,
      statistics,
      group
    ],
    getCdrs,
    {
      keepPreviousData: true
    }
  );
};

export default useCdrs;

import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Grid, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { isNestedObjectEmpty } from 'utils/yupHelpers';

import PageHeader from '../../PageHeader';
import { useDashboardSlice } from 'app/layouts';
import {
  Breadcrumbs,
  Button,
  Dialog,
  Dropdown,
  DropdownOption,
  TextInput
} from 'design';
import { labelValuesFor } from 'design/Dropdown/Dropdown';
import PhoneInputMask from 'app/components/PhoneInputMask';
import {
  DataRemote,
  defaultLineType,
  mixnetworks,
  net2phone
} from 'constants/app';
import Schema from './Schema';
import useAttachLines, {
  useEquipmentLines,
  useGatewayLines,
  useLineTypes,
  useProviders
} from 'api/useAttachLines';
import { useEquipmentByID } from 'api/useEquipment';
import useGateway from 'api/useGateways';
import { useDetachLines } from 'api/useLocationLines';
import { Line } from 'types';
import { ManageLine, HistoryState } from '../types/ManageLinesType';
import { Account } from 'types/Equipment';
import { AlertObjectProp } from 'design/Alert/Alert';
import { ReactComponent as DetachIconRed } from '../../../../assets/img/Detach_icon_red.svg';
import { ReactComponent as DetachIconGray } from '../../../../assets/img/Detach_icon_gray.svg';
import styles from './manageLines.module.scss';
import { DidAndDesignation } from 'app/components/NumberPurchaseTool/NumberDesignationForm';
import NumberPurchaseTool from 'app/components/NumberPurchaseTool';
import { AddCircle } from '@material-ui/icons';
import useCurrentUser from 'api/useCurrentUser';

const ManageLines = (): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [portCount, setPortCount] = React.useState<number | undefined>();
  const [location, setLocation] = React.useState<Account | undefined>();
  const [isDataRemote, setIsDataRemote] = React.useState<boolean>(false);
  const [lines, setLines] = React.useState<Line[]>([]);
  const [detachedLines, setDetachedLines] = React.useState<string[]>([]);
  const [
    defaultExtensionIdentifer,
    setDefaultExtensionIdentifier
  ] = React.useState<string>('1');
  const [defaultLineTypeOption, setDefaultLineTypeOption] = React.useState<
    DropdownOption
  >({
    label: '',
    value: ''
  });
  const [defaultProviderOption, setDefaultProviderOption] = React.useState<
    DropdownOption
  >({
    label: '',
    value: ''
  });

  const history = useHistory();
  const { state } = useLocation<HistoryState>();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  // Grab gateway infomation
  const { id, pathName } = useParams<{ id: string; pathName: string }>();

  const {
    data: equipmentData,
    isLoading: equipmentDataLoading
  } = useEquipmentByID(id, pathName);
  const { data: gatewayData, isLoading: gatewayDataLoading } = useGateway(
    id,
    pathName
  );
  const { data: equipmentDetails } = useEquipmentLines(id, pathName);
  const { data: gatewayDetails } = useGatewayLines(id, pathName);

  const { data: lineTypesList } = useLineTypes();
  const { data: providers } = useProviders();

  // Hooks for attaching / detaching lines
  const {
    mutateAsync: attachLines,
    isLoading: attachLinesLoading
  } = useAttachLines();
  const {
    mutateAsync: detachLines,
    isLoading: detachLinesLoading
  } = useDetachLines();

  // Set default extension identifer
  React.useEffect(() => {
    if (isDataRemote || defaultProviderOption.label === mixnetworks) {
      setDefaultExtensionIdentifier('1');
      return;
    }
    const extensionIdentiferLocation = 4;
    if (lines.length > 0 && lines?.[0].extension) {
      setDefaultExtensionIdentifier(
        lines[0].extension?.[extensionIdentiferLocation]
      );
    } else if (gatewayData?.rank) {
      setDefaultExtensionIdentifier(gatewayData.rank.toString());
    } else {
      setDefaultExtensionIdentifier('1');
    }
  }, [lines, gatewayData, isDataRemote, defaultProviderOption]);

  // Set Dropdown Values
  const lineTypes: DropdownOption[] = React.useMemo(() => {
    const allLineTypes = labelValuesFor(lineTypesList);
    // Set default line types
    let defaultOption = allLineTypes.find(
      lineType => (lineType.label = defaultLineType)
    );
    if (!defaultOption) {
      defaultOption = {
        label: '',
        value: ''
      };
    }
    setDefaultLineTypeOption(defaultOption);
    return allLineTypes;
  }, [lineTypesList]);

  const providerList: DropdownOption[] = React.useMemo(() => {
    if (!providers) {
      return [];
    }
    if (isDataRemote) {
      setDefaultProviderOption(
        providers.find(provider => provider.label === mixnetworks) ?? {
          label: '',
          value: ''
        }
      );
      return providers.filter(item => item.label === mixnetworks);
    }
    setDefaultProviderOption(
      providers.find(provider => provider.label === net2phone) ?? {
        label: '',
        value: ''
      }
    );
    return providers;
  }, [providers, isDataRemote]);

  // IMPORTANT VARIABLE: USED FOR ALMOST ALL LOGIC
  const gatewayNotInBundle = pathName === 'equipment';

  // Set Breadcrumbs
  const equipmentParentName = gatewayNotInBundle
    ? equipmentData?.account?.organization
    : gatewayData?.equipmentBundleItem?.equipmentBundle?.location.organization;
  const breadCrumbsItems = [
    {
      label: `${equipmentParentName?.name}`,
      url: `/Organization/${equipmentParentName?.id}`
    },
    {
      label: 'Lines',
      url: '/lines'
    },
    { label: 'Manage Lines', url: '#' }
  ];

  // Page Header Subtitle
  const subTitle = gatewayNotInBundle
    ? `${equipmentData?.catalogItem.name} - ${equipmentData?.serialNumber}`
    : `${gatewayData?.gatewayCatalog.model} - ${gatewayData?.serialNumber}`;

  // Set inital values for formik
  const fxoPortCount = gatewayNotInBundle
    ? equipmentData?.catalogItem?.fxoPorts
    : gatewayData?.gatewayCatalog?.fxoPorts;
  const initialValues = React.useMemo(
    () =>
      [...Array(fxoPortCount).keys()].map(portIndex => {
        const indexLine = lines.find(line => line.port === portIndex + 1);
        if (isDataRemote || indexLine?.networkProvider?.name === mixnetworks) {
          setDefaultProviderOption(
            providers?.find(options => options.label === mixnetworks) ?? {
              label: '',
              value: ''
            }
          );
        }

        const extension =
          defaultProviderOption.label === net2phone
            ? `8000${defaultExtensionIdentifer}0${portIndex + 1}`
            : null;

        return {
          id: indexLine?.id ?? '',
          port: indexLine?.port ?? portIndex + 1,
          line: indexLine?.value.toString() ?? '',
          tempPortingNumber: indexLine?.tempPortingNumber ?? null,
          callerId: indexLine?.callerId ?? '',
          description: indexLine?.description ?? '',
          lineType: indexLine?.lineType
            ? {
                label: indexLine.lineType.name,
                value: indexLine.lineType.id
              }
            : defaultLineTypeOption,
          accountId: indexLine?.account?.id ?? location?.id ?? '',
          equipmentId: gatewayNotInBundle ? id : undefined,
          gatewayId: !gatewayNotInBundle ? id : undefined,
          extension: indexLine?.extension ?? extension,
          provider: indexLine?.networkProvider
            ? {
                label: indexLine.networkProvider.name,
                value: indexLine.networkProvider.id
              }
            : defaultProviderOption,
          detach: indexLine?.id ? true : false
        };
      }),
    [
      fxoPortCount,
      gatewayNotInBundle,
      id,
      lines,
      location,
      defaultExtensionIdentifer,
      defaultLineTypeOption,
      defaultProviderOption,
      isDataRemote,
      providers
    ]
  );

  // Initialize Schema
  const yupSchema = () => {
    return Schema({
      id,
      lines: formikProps.values,
      portCount,
      erpLocationId: !!location?.erpLocationId
    });
  };

  // Set states based on if ATA is in bundle or not
  React.useEffect(() => {
    if (gatewayNotInBundle) {
      if (!equipmentDetails || !equipmentData) return;

      setLines(equipmentDetails ?? []);
      setPortCount(equipmentData?.catalogItem?.fxoPorts);
      setLocation(equipmentData?.account);
      setIsDataRemote(equipmentData?.catalogItem?.brand === DataRemote);
    } else {
      if (!gatewayDetails || !gatewayData) return;
      setLines(gatewayDetails ?? []);
      setPortCount(gatewayData?.gatewayCatalog?.fxoPorts);
      setLocation(gatewayData?.equipmentBundleItem?.equipmentBundle?.location);
      setIsDataRemote(gatewayData?.gatewayCatalog?.brand === DataRemote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDetails, gatewayDetails, equipmentData, gatewayData]);

  const handleSubmit = async () => {
    if (formikProps.isValidating) {
      return;
    }
    const toastMessages: AlertObjectProp[] = [];
    // Detach Lines
    if (detachedLines.length) {
      try {
        await detachLines(detachedLines);
        toastMessages.push({
          message: 'Line(s) detached successfully.',
          severity: 'success'
        });
      } catch (err: any) {
        toastMessages.push({
          message: err.message || 'Error: could not detach lines!',
          severity: 'error'
        });
      }
    }

    if (!isNestedObjectEmpty(formikProps.errors)) {
      return;
    }

    // Attach Lines
    try {
      const validLines = formikProps.values.filter(item => {
        return item.line;
      });
      if (validLines.length) {
        await attachLines(validLines);
        toastMessages.push({
          message: 'Line(s) updated successfully.',
          severity: 'success'
        });
      }
    } catch (err: any) {
      toastMessages.push({
        message: err.message || 'Error: could not update lines!',
        severity: 'error'
      });
    }

    // Show toast messages and return
    dispatch(
      actions.showNotify({
        messages: toastMessages
      })
    );
    history.push(`${state.goBack}`);
  };

  // Initialize Formik
  const formikProps = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: handleSubmit
  });

  // Handle OnBlur
  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    await formikProps.setFieldTouched(name, true);
    if (location?.erpLocationId) {
      if (name.includes('line')) {
        const num = name.split('.')[0];
        if (!num) return;
        if (formikProps.values[Number(num)].callerId === '') {
          formikProps.setFieldValue(
            `${num}.callerId`,
            value.replace(/[- ()]/g, '')
          );
        }
      }
    }
  };

  // Handle dropdown change
  const handleChangeDropdown = async (
    fieldName: string,
    value: DropdownOption
  ) => {
    await formikProps.setFieldValue(fieldName, value);
    formikProps.validateField(fieldName);
  };

  // Handle provider dropdown change
  const handleChangeDropdownProvider = async (
    index: number,
    value: DropdownOption
  ) => {
    // Set extension to empty string on mixnetworks
    const extension =
      value.label === net2phone
        ? `8000${defaultExtensionIdentifer}0${index + 1}`
        : null;
    await formikProps.setFieldValue(`${index}.extension`, extension);

    // Set dropdown
    await formikProps.setFieldValue(`${index}.provider`, value);
    formikProps.validateField(`${index}.provider`);
  };

  // Handle text input change
  const handleTextInputChange = async (e: any) => {
    const { name, value } = e.target;
    await formikProps.setFieldValue(name, value);
    await formikProps.validateField(name);
  };

  // Passed to redux dialog to detach line on callback
  const detachCallback = (index: number, lineId: string) => {
    setDetachedLines(curr => [...curr, lineId]);

    const extension =
      defaultProviderOption.label === net2phone
        ? `8000${defaultExtensionIdentifer}0${index + 1}`
        : null;

    // Reset line to default
    formikProps.values[index] = {
      id: formikProps.values[index].id,
      port: index + 1,
      line: '',
      tempPortingNumber: null,
      callerId: '',
      description: '',
      lineType: defaultLineTypeOption,
      accountId: location?.id || '',
      equipmentId: gatewayNotInBundle ? id : undefined,
      gatewayId: !gatewayNotInBundle ? id : undefined,
      provider: defaultProviderOption,
      detach: false,
      extension
    };
    formikProps.setValues(formikProps.values);
  };

  const handleDetachLine = async (index: number) => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'large',
        title: 'Detach Line',
        lazyComponent: 'LineDetach',
        lazyComponentProps: {
          id: formikProps.values[index].id,
          phoneNumber: formikProps.values[index].line,
          locationName: location?.name ?? '',
          index,
          detachCallback
        }
      })
    );
  };

  const handleReset = () => {
    history.goBack();
  };

  const [numberDialogOpen, setNumberDialogOpen] = React.useState(false);

  const assignPurchasedNumbers = (designations: DidAndDesignation[]) => {
    designations.forEach((designation, i) => {
      const designationType =
        designation.designation === 'primary' ? 'line' : 'tempPortingNumber';
      formikProps.setFieldValue(`${i}.${designationType}`, designation.did);
    });
  };

  if (equipmentDataLoading || gatewayDataLoading) return <div>Loading...</div>;

  return (
    <div>
      <Breadcrumbs items={breadCrumbsItems} />
      <div className="page-header">
        <PageHeader title="Manage Lines" subTitle={subTitle} />
        {currentUser?.isSuperAdmin && (
          <div className="page-header__options">
            <IconButton
              size="medium"
              className="page-header__options__add-button"
              onClick={() => {
                setNumberDialogOpen(true);
              }}
            >
              <AddCircle color="primary" className="button-icon" />
            </IconButton>
          </div>
        )}
      </div>

      <form onSubmit={formikProps.handleSubmit}>
        {formikProps.values.map((lineData: ManageLine, i: number) => {
          const identifers = {
            port: `${i}.port`,
            line: `${i}.line`,
            tempPortingNumber: `${i}.tempPortingNumber`,
            callerId: `${i}.callerId`,
            description: `${i}.description`,
            lineType: `${i}.lineType`,
            provider: `${i}.provider`,
            extension: `${i}.extension`
          };
          const inputError = formikProps.errors[i];
          const inputTouched = formikProps.touched[i];

          return (
            <div key={i} className={styles.bottomBorder}>
              <Grid
                container
                justifyContent="space-between"
                className={styles.linesContainer}
              >
                {/* Port */}
                <Grid item xs={1} sm={1} md={1} className={styles.gridItem}>
                  <TextInput
                    id={identifers.port}
                    name={identifers.port}
                    type="number"
                    size="small"
                    disabled={true}
                    inputDisabledStyle={styles.inputDisabledStyle}
                    isError={Boolean(inputTouched?.port && inputError?.port)}
                    validationMessage={inputError?.port}
                    label="Port"
                    value={lineData.port}
                    onChange={handleTextInputChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                {/* Rows container */}
                <Grid container item xs={12} sm={10} md={11}>
                  {/* Extension */}
                  {lineData?.provider?.label === net2phone ? (
                    <Grid item xs={8} md={4} className={styles.gridItem}>
                      <TextInput
                        id={identifers.extension}
                        name={identifers.extension}
                        placeholder="9999999"
                        isError={Boolean(
                          inputTouched?.extension && inputError?.extension
                        )}
                        label="Extension"
                        validationMessage={inputError?.extension}
                        value={lineData.extension}
                        onChange={handleTextInputChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {/* DID and detach icon */}
                  <Grid container item xs={8} md={4} alignItems="center">
                    <Grid
                      item
                      xs={9}
                      sm={10}
                      md={10}
                      className={styles.gridItem}
                    >
                      <TextInput
                        id={identifers.line}
                        name={identifers.line}
                        type="text"
                        disabled={lineData.detach}
                        inputDisabledStyle={styles.inputDisabledStyle}
                        isError={Boolean(
                          inputTouched?.line && inputError?.line
                        )}
                        validationMessage={inputError?.line}
                        label="DID"
                        placeholder="1 (999) 999-9999"
                        value={lineData.line}
                        onChange={handleTextInputChange}
                        inputComponent={PhoneInputMask}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={1} md={1} className={styles.iconStyle}>
                      {lineData.detach ? (
                        <DetachIconRed
                          onClick={() => handleDetachLine(i)}
                        ></DetachIconRed>
                      ) : (
                        <DetachIconGray />
                      )}
                    </Grid>
                  </Grid>
                  {/* Temp DID */}
                  <Grid item xs={8} md={4} className={styles.gridItem}>
                    <TextInput
                      id={identifers.tempPortingNumber}
                      name={identifers.tempPortingNumber}
                      type="text"
                      isError={Boolean(
                        inputTouched?.tempPortingNumber &&
                          inputError?.tempPortingNumber
                      )}
                      validationMessage={inputError?.tempPortingNumber}
                      label="TEMP DID"
                      placeholder="1 (999) 999-9999"
                      value={lineData.tempPortingNumber}
                      onChange={handleTextInputChange}
                      inputComponent={PhoneInputMask}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {/* Description */}
                  <Grid item xs={8} md={4} className={styles.gridItem}>
                    <TextInput
                      id={identifers.description}
                      name={identifers.description}
                      type="text"
                      isError={Boolean(
                        inputTouched?.description && inputError?.description
                      )}
                      validationMessage={inputError?.description}
                      label="Description"
                      value={lineData.description}
                      onChange={handleTextInputChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {/* CallerId */}
                  {location?.erpLocationId && (
                    <Grid item xs={8} md={4} className={styles.gridItem}>
                      <TextInput
                        id={identifers.callerId}
                        name={identifers.callerId}
                        placeholder="Defaults to DID"
                        type="text"
                        isError={Boolean(
                          inputTouched?.callerId && inputError?.callerId
                        )}
                        validationMessage={inputError?.callerId}
                        label="Caller ID (CNAM)"
                        value={lineData.callerId}
                        onChange={handleTextInputChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  )}
                  {/* Line Type */}
                  <Grid item xs={8} md={4} className={styles.gridItem}>
                    <Dropdown
                      id={identifers.lineType}
                      name={identifers.lineType}
                      isError={Boolean(
                        inputTouched?.lineType && inputError?.lineType
                      )}
                      validationMessage={inputError?.lineType?.value}
                      label="Line Type"
                      onChange={e => {
                        handleChangeDropdown(identifers.lineType, e);
                      }}
                      dropdownItems={lineTypes}
                      value={lineData.lineType?.value}
                      onBlur={handleBlur}
                      placeholder="Select One"
                    />
                  </Grid>
                  {/* Provider */}
                  <Grid item xs={8} md={4} className={styles.gridItem}>
                    <Dropdown
                      id={identifers.provider}
                      name={identifers.provider}
                      isError={Boolean(
                        inputTouched?.provider && inputError?.provider
                      )}
                      validationMessage={inputError?.provider?.value}
                      label="Provider"
                      onChange={e => {
                        handleChangeDropdownProvider(i, e);
                      }}
                      dropdownItems={providerList}
                      value={lineData.provider?.value}
                      onBlur={handleBlur}
                      placeholder="Select One"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <div className={styles.formButtons}>
          <Button
            isLoading={attachLinesLoading || detachLinesLoading}
            disabled={formikProps.isSubmitting}
            type="button"
            onClick={handleReset}
            variant="outlined"
          >
            cancel
          </Button>
          <Button
            isLoading={
              equipmentDataLoading || gatewayDataLoading || detachLinesLoading
            }
            disabled={
              formikProps.isValidating ||
              formikProps.isSubmitting ||
              (_.isEqual(formikProps.values, initialValues) &&
                detachedLines.length === 0)
            }
            type="submit"
          >
            submit
          </Button>
        </div>
      </form>
      <Dialog size="xlarge" open={numberDialogOpen} handleClose={() => {}}>
        <NumberPurchaseTool
          handleClose={() => {
            setNumberDialogOpen(false);
          }}
          numberOfLines={portCount}
          purchasedCb={designations => {
            setNumberDialogOpen(false);
            assignPurchasedNumbers(
              designations.map(designation => ({
                did: designation.did.replace('+', ''),
                designation: designation.designation
              }))
            );
          }}
        />
      </Dialog>
    </div>
  );
};

export default ManageLines;

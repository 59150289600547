import React, {
  createContext,
  useMemo,
  useContext,
  useState,
  useLayoutEffect
} from 'react';
import { useTheme } from '@material-ui/core';

type SidebarCtx = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const SidebarContext = createContext<SidebarCtx | null>(null);

export const useSidebar = (): SidebarCtx => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used inside a SidebarProvider!');
  }
  return context;
};

export interface SidebarProviderProps {
  defaultOpen?: boolean;
  children: React.ReactNode;
}

const SidebarProvider = ({
  children,
  defaultOpen
}: SidebarProviderProps): JSX.Element => {
  const [open, setOpen] = useState(defaultOpen ?? false);
  const theme = useTheme();
  const mobileWidth = theme.breakpoints.values.sm;

  useLayoutEffect(() => {
    /**
     * Closure function:
     * onMobile sets the open state to false (mobile default view has sidebar hidden).
     * additionally only set the sidebar to open if its not mobile & not open
     */
    const onMobile = () => {
      const currentViewportWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const isViewportSmall = mobileWidth >= currentViewportWidth;
      if (isViewportSmall) {
        setOpen(false);
      }
    };

    // add event listener whenever the window is resized
    window.addEventListener('resize', onMobile);
    // get our first determination
    onMobile();
    // on cleanup stop event listening for resizing
    return () => window.removeEventListener('resize', onMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileWidth]);

  return (
    <SidebarContext.Provider value={useMemo(() => [open, setOpen], [open])}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

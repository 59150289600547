import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import {
  StandardIdNameType,
  Line,
  Sort,
  LocationLinesWithE911,
  E911Address
} from 'types';
import axios, { AxiosError } from 'utils/axiosProvider';

export interface PagedLocationLines {
  lines: Line[];
  total: number;
}

export interface GetLocationsParams {
  queryKey: [
    __0: string,
    locationId: string,
    andFilter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}
interface GetLineParams {
  queryKey: [__0: string, id: string];
}

export interface GetLocationLinesWithE911Params {
  queryKey: [_0: string, locationId: string];
}

interface UpdatableCallForwarding {
  lineId: string;
  forwardValue: number | null;
  forwardEnable: boolean;
}

const getLocationLines = async ({
  queryKey
}: GetLocationsParams): Promise<PagedLocationLines> => {
  const [, locationId, andFilter, sort, pageIndex, pageSize] = queryKey;

  const { data } = await axios.get(`/dids`, {
    params: {
      filter: `account.id|eq|${locationId}`,
      andfilter: andFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    }
  });

  const lines: Line[] = data.data;
  return {
    lines,
    total: Number(data.total)
  };
};

const useLocationLines = (
  locationId: string,
  andFilter = '',
  sort: Sort = ['', 'ASC'],
  pageIndex = 1,
  pageSize = 100,
  enabled = true
): UseQueryResult<PagedLocationLines, AxiosError> => {
  return useQuery<PagedLocationLines, AxiosError>(
    [query.locationLines, locationId, andFilter, sort, pageIndex, pageSize],
    getLocationLines,
    {
      keepPreviousData: true,
      enabled: enabled // enable automatic refetching when query mounts
    }
  );
};

const doesCallforwardingExists = async ({
  queryKey
}: GetLineParams): Promise<boolean> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/dids/call-forwarding-exists`, {
    params: {
      id
    }
  });
  return data;
};

const useCallforwardingExists = (
  id?: string
): UseQueryResult<boolean, AxiosError> => {
  return useQuery<boolean, AxiosError>(
    [query.callForwardingExists, id],
    doesCallforwardingExists,
    {
      keepPreviousData: true
    }
  );
};

/**
 * Updates one did with forwarding number and whether or not call forwarding
 * should be enabled for the given did and forwarded number
 * @param {UpdatableCallForwarding} CallForwarding
 * @returns {Line}
 */
const patchCallForwarding = async (
  CallForwarding: UpdatableCallForwarding
): Promise<Line> => {
  const { lineId, forwardValue, forwardEnable } = CallForwarding;
  const { data } = await axios.put(`/dids/${lineId}/forward`, {
    forwardValue,
    forwardEnable
  });
  return data;
};

const useUpdateCallForwarding = (): UseMutationResult<
  Line,
  AxiosError,
  UpdatableCallForwarding
> => {
  const queryClient = useQueryClient();
  const mutateCallForwarding = useMutation<
    Line,
    AxiosError,
    UpdatableCallForwarding
  >(patchCallForwarding, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locationLines]);
    }
  });

  return mutateCallForwarding;
};

const patchLine = async (line: Partial<Line>): Promise<Line> => {
  const { data } = await axios.patch(`/dids/${line.id}`, {
    lineTypeId: line?.lineType?.id || null,
    description: line?.description || null,
    providerId: line?.networkProvider?.id || null,
    callerId: line?.callerId || null,
    accountId: line?.account?.id || null,
    tempPortingNumber: line?.tempPortingNumber || null
  });
  return data;
};

const useLineUpdate = (): UseMutationResult<
  Line,
  AxiosError,
  Partial<Line>
> => {
  const queryClient = useQueryClient();
  const mutateCallForwarding = useMutation<Line, AxiosError, Partial<Line>>(
    patchLine,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locationLines]);
        queryClient.invalidateQueries([query.lines]);
      }
    }
  );

  return mutateCallForwarding;
};

const getLine = async ({ queryKey }: GetLineParams): Promise<Line> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/dids/${id}`);
  return data;
};

const useLine = (id?: string): UseQueryResult<Line, Error> => {
  return useQuery<Line, AxiosError>([query.lines, id], getLine, {
    // api to call only if the pathName is router.
    enabled: !!id
  });
};

const detachManyLines = async (detachDids: string[]): Promise<void> => {
  await axios.delete(`/dids`, { data: { didIds: detachDids } });
};

const useDetachLines = (): UseMutationResult<void, AxiosError, string[]> => {
  const queryClient = useQueryClient();

  const mutateDetachLine = useMutation<void, AxiosError, string[]>(
    detachManyLines,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locationLinesStatus]);
        queryClient.invalidateQueries([query.locations]);
        queryClient.invalidateQueries([query.lines]);
        queryClient.invalidateQueries([query.gatewayLines]);
      }
    }
  );
  return mutateDetachLine;
};

const detachOneLine = async (didId: string): Promise<void> => {
  await axios.delete(`/dids/detach/${didId}`);
};

const useDetachOneLine = (): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();

  const mutateDetachLine = useMutation<void, AxiosError, string>(
    detachOneLine,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([query.locationLinesStatus]);
        queryClient.invalidateQueries([query.locations]);
        queryClient.invalidateQueries([query.lines]);
        queryClient.invalidateQueries([query.gatewayLines]);
      }
    }
  );
  return mutateDetachLine;
};

const deleteLine = async (id: string): Promise<void> => {
  await axios.delete(`/dids/${id}`);
};

const useDeleteLine = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDetachLine = useMutation<void, AxiosError, any>(deleteLine, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locationLinesStatus]);
      queryClient.invalidateQueries([query.lines]);
    }
  });
  return mutateDetachLine;
};

const createOneLocationLine = async (
  createData: Partial<Line>
): Promise<string> => {
  const payload = {
    accountId: createData.account?.id,
    lineTypeId: createData.lineType?.id,
    providerId: createData.networkProvider?.id,
    value: createData.value,
    description: createData.description
  };
  const { data } = await axios.post(`/dids`, payload);
  return data.id;
};

const useCreateOneLocationLine = (): UseMutationResult<
  string,
  AxiosError,
  Partial<Line>
> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<string, AxiosError, Partial<Line>>(
    createOneLocationLine,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locationLines]);
        queryClient.invalidateQueries([query.lines]);
      }
    }
  );
  return mutatePostOrg;
};

//Line types DropDown
const getLineTypes = async ({
  queryKey
}: GetLocationsParams): Promise<StandardIdNameType[]> => {
  const [, pageIndex, pageSize] = queryKey;
  const { data } = await axios.get(`/line-types`, {
    params: {
      page: pageIndex,
      limit: pageSize
    }
  });
  return data.data;
};
const useGetLineTypes = (
  pageIndex: number,
  pageSize: number
): UseQueryResult<StandardIdNameType[], Error> => {
  return useQuery<StandardIdNameType[], AxiosError>(
    [query.lines, pageIndex, pageSize],
    getLineTypes,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );
};

const getLocationLinesWithE911 = async ({
  queryKey
}: GetLocationLinesWithE911Params): Promise<LocationLinesWithE911[]> => {
  const [, locationId] = queryKey;

  const { data } = await axios.get(
    `/dids/location/${locationId}/withPsapAddress`
  );

  return data;
};

const useLocationLinesWithE911 = (
  locationId: string
): UseQueryResult<LocationLinesWithE911[], AxiosError> => {
  return useQuery<LocationLinesWithE911[], AxiosError>(
    [query.locationE911Lines, locationId],
    getLocationLinesWithE911,
    {
      keepPreviousData: true
    }
  );
};

interface PatchLineE911AddressArgs {
  id: string;
  address: Partial<E911Address>;
}

const patchLineE911Address = async ({
  id,
  address
}: PatchLineE911AddressArgs): Promise<Line> => {
  const { data } = await axios.patch(`/dids/${id}/e911Address`, address);
  return data;
};

const useLineE911Update = (): UseMutationResult<
  Line,
  AxiosError,
  PatchLineE911AddressArgs
> => {
  const queryClient = useQueryClient();
  const mutateLineAddress = useMutation<
    Line,
    AxiosError,
    PatchLineE911AddressArgs
  >(patchLineE911Address, {
    onSettled: () => {
      queryClient.invalidateQueries([query.lines]);
    },
    // update cache to display updated line's values
    onSuccess: updatedLine => {
      queryClient.setQueryData(
        [query.locationE911Lines, updatedLine.account.id],
        (oldData: LocationLinesWithE911[] | undefined) => {
          return oldData?.map(line => {
            if (line.id === updatedLine.id) {
              return {
                ...updatedLine,
                psapAddress: line.psapAddress // Keep the old psapAddress since it is not returned
              };
            }
            return line;
          }) as LocationLinesWithE911[];
        }
      );
    }
  });

  return mutateLineAddress;
};

interface PatchAllLinesE911AddressArgs {
  locationId: string;
  linesWithPsapAddress: LocationLinesWithE911[];
}

const patchAllLinesE911Address = async ({
  locationId,
  linesWithPsapAddress
}: PatchAllLinesE911AddressArgs): Promise<Line[]> => {
  const { data } = await axios.patch(
    `/dids/location/${locationId}/e911Address/all`,
    linesWithPsapAddress
  );
  return data;
};

const useAllLinesE911Update = (): UseMutationResult<
  Line[],
  AxiosError,
  PatchAllLinesE911AddressArgs
> => {
  const queryClient = useQueryClient();
  const mutateLineAddress = useMutation<
    Line[],
    AxiosError,
    PatchAllLinesE911AddressArgs
  >(patchAllLinesE911Address, {
    onSettled: () => {
      queryClient.invalidateQueries([query.lines]);
    }
  });

  return mutateLineAddress;
};

export {
  useUpdateCallForwarding,
  useGetLineTypes,
  useLine,
  useLineUpdate,
  useDetachLines,
  useDetachOneLine,
  getLocationLines,
  useDeleteLine,
  useCallforwardingExists,
  useCreateOneLocationLine,
  useLocationLinesWithE911,
  useLineE911Update,
  useAllLinesE911Update
};

export default useLocationLines;

import React from 'react';
import _ from 'lodash';
import { useGetContact, useUpdateContact } from 'api/useContacts';
import { useFormik } from 'formik';
import contactSchema from 'schemas/contactSchema';
import ContactForm from '../forms/ContactForm';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';

interface UpdateContactProps {
  id: string;
  afterSubmit?: () => void;
  handleClose: () => void;
}

const UpdateContact = ({
  id,
  afterSubmit,
  handleClose
}: UpdateContactProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const { data } = useGetContact(id);
  const {
    mutateAsync: updateContact,
    isLoading: contactUpdateLoading
  } = useUpdateContact();

  const formProps = useFormik({
    initialValues: contactSchema.cast({}),
    validationSchema: contactSchema,
    onSubmit: async values => {
      try {
        await updateContact({
          id,
          ...values
        });
        handleClose();

        dispatch(
          actions.showNotify({
            message: 'Contact updated',
            severity: 'success'
          })
        );

        if (afterSubmit) {
          afterSubmit();
        }
      } catch (e: any) {
        dispatch(
          actions.showNotify({
            message: e?.response?.data?.message || 'Could not update contact',
            severity: 'error'
          })
        );
      }
    }
  });

  React.useEffect(() => {
    const dbContact = contactSchema.cast({
      ..._.omit(data, ['id', 'updatedAt', 'createdAt', 'name'])
    });
    formProps.setValues(dbContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ContactForm
      isLoading={contactUpdateLoading}
      handleClose={() => {
        handleClose();
      }}
      formProps={formProps}
    />
  );
};

export default UpdateContact;

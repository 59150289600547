import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import axiosInstance, { AxiosError } from 'utils/axiosProvider';
import { AlertObjectProp } from 'design/Alert/Alert';
import { Button, Dialog, Text } from 'design';
import { DialogReason } from 'design/Dialog/Dialog';
import styles from './ToolCreateSubscription.module.scss';

export interface ToolCreateSubsriptionProps {
  open: boolean;
  domain: string;
  siteExists: boolean;
  handleClose: (
    event?: object | React.MouseEvent<HTMLElement>,
    reason?: DialogReason
  ) => void;
}

const ToolCreateSubscriptionModal = ({
  open,
  domain,
  siteExists,
  handleClose
}: ToolCreateSubsriptionProps): JSX.Element => {
  const [subscriptionIsLoading, setSubscriptionIsLoading] = React.useState<
    boolean
  >(false);

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  // Toast Messages
  const toastMessages: {
    [key: string]: AlertObjectProp;
  } = {
    errorCreatingSubscription: {
      message: 'There was an issue with Net2Phone',
      severity: 'error'
    },
    subscriptionIsActive: {
      message: `Subscription status for ${domain} is true`,
      severity: 'success'
    }
  };

  const createSubscription = async (e: SyntheticEvent) => {
    setSubscriptionIsLoading(true);
    const subscriptionPayload = { domain, siteExists };

    try {
      const { data } = await axiosInstance.put(
        'n2p/domain',
        subscriptionPayload
      );

      // Did not create subscripition
      if (data.status !== 200) {
        dispatch(actions.showNotify(toastMessages.errorCreatingSubscription));
        return;
      }

      // Successfully created subscription
      if (data.status === 200) {
        dispatch(actions.showNotify(toastMessages.subscriptionIsActive));
        return;
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: err.response?.data.message,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'An error occurred',
            severity: 'error'
          })
        );
      }
    } finally {
      setSubscriptionIsLoading(false);
      handleClose();
    }
  };
  return (
    <Dialog handleClose={handleClose} open={open}>
      <div className={styles.container}>
        <Text variant="h4" className={styles.headerText}>
          N2P Domain Status
        </Text>
        <Text variant="body2">
          {`Subscription status for ${domain} is `}
          <strong>inactive</strong>.
        </Text>
        <Text variant="body2" className={styles.bodyText}>
          Create Subscription?
        </Text>
        <div className={styles.footer}>
          <Button
            isLoading={subscriptionIsLoading}
            type="button"
            onClick={handleClose}
            variant="outlined"
          >
            CANCEL
          </Button>
          <Button
            isLoading={subscriptionIsLoading}
            onClick={createSubscription}
          >
            CREATE
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ToolCreateSubscriptionModal;

import * as React from 'react';
import {
  DEFAULT_ROW_LIMIT,
  pageSizeOptions,
  StatusToColor
} from 'constants/app';
import { Dot, ColumnProps, Link, TablePaginated, Text } from 'design';
import useSort from 'hooks/useSort';
import LineActions from './LineActions';
import PhoneNumber from '../PhoneNumber';
import { Helmet } from 'react-helmet-async';
import { Equipment, Line, CurrentUser } from 'types';
import { Gateway } from 'types/Gateway';
import styles from './locationLines.module.scss';
import NetProviderCell from '../NetProviderCell';
import { SyncTypeEnum } from 'types/ApiSync';

export interface LocationLinesProps {
  locationId: string;
  locationErpId?: string;
  equipment?: Equipment;
  gateway?: Gateway;
  currentUser?: CurrentUser;
  refetch?: () => Promise<any>;
}

const LocationLines = ({
  locationId,
  equipment,
  gateway,
  currentUser,
  locationErpId,
  refetch
}: LocationLinesProps): JSX.Element => {
  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(DEFAULT_ROW_LIMIT.min);
  const [pageIndex, setPageIndex] = React.useState(1);
  const equipmentId =
    gateway && gateway.id
      ? gateway?.id
      : equipment && equipment.id
      ? equipment?.id
      : '';

  const equipmentLocName = equipment ? equipment?.account?.name : '';

  const gatewayLocName = gateway
    ? gateway?.bundleId?.equipmentBundle?.location?.name
    : '';

  const lines: Line[] =
    equipment && equipment.dids
      ? equipment.dids
      : gateway && gateway.dids
      ? gateway.dids
      : [];

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'status',
        label: '',
        align: 'center',
        padding: 'none',
        render: ({ serviceStatus }: Line) => (
          <Dot color={StatusToColor[serviceStatus.name]} />
        ),
        cellWidth: 32
      },
      {
        accessor: 'value',
        label: 'Phone Number',
        render: ({ value }: Line) => {
          const to = `/location/${locationId}/cdr/${value}`;
          return (
            <Link to={to}>
              <PhoneNumber>{value}</PhoneNumber>
            </Link>
          );
        },
        resizable: true
      },
      {
        accessor: 'description',
        label: 'description',
        resizable: true
      },
      {
        accessor: 'lineType.name',
        label: 'Line Type',
        resizable: true
      },
      {
        accessor: 'port',
        label: 'Port',
        resizable: true
      },
      {
        accessor: 'forwardValue',
        label: 'Call Forwarding',
        render: ({ forwardValue, forwardEnable }: Line) =>
          forwardEnable ? <PhoneNumber>{forwardValue}</PhoneNumber> : <>-</>,
        resizable: true,
        hidden: false
      },
      {
        accessor: 'tempPortingNumber',
        label: 'Temp DID (Porting)',
        render: ({ tempPortingNumber }: Line) =>
          tempPortingNumber ? (
            <PhoneNumber>{tempPortingNumber}</PhoneNumber>
          ) : (
            <>-</>
          ),
        resizable: true
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'emergencyAddressRegistered',
        label: 'E911 Address',
        render: ({ emergencyAddressRegistered }: Line) =>
          emergencyAddressRegistered ? (
            <Text> Registered </Text>
          ) : (
            <Text> Not Registered</Text>
          ),
        resizable: true
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: '',
        label: 'Equipment',
        resizable: true,
        render: () =>
          equipment ? (
            <Text>{equipment.catalogItem.name}</Text>
          ) : gateway ? (
            <Text>{gateway?.gatewayCatalog.model}</Text>
          ) : (
            <>-</>
          )
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: '',
        label: 'MAC',
        resizable: true,
        render: () =>
          equipment ? (
            <Text>{equipment.mac}</Text>
          ) : gateway ? (
            <Text>{gateway?.mac}</Text>
          ) : (
            <>-</>
          )
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'networkProvider.name',
        label: 'Provider',
        resizable: true
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'n2pExt.syncStatus',
        label: 'API Status',
        resizable: true,
        render: ({ n2pExt }: Line) => {
          if (
            !gateway?.bundle?.location?.organization?.isNewFlow &&
            !gateway?.bundle?.location?.organization?.isPartialFlow
          )
            return <>N/A</>;
          if (!n2pExt) return <>-</>;
          return NetProviderCell({
            sub: { ...n2pExt, type: SyncTypeEnum.Extension }
          });
        }
      },
      {
        accessor: '',
        label: '',
        render: ({
          id,
          value,
          forwardValue,
          forwardEnable,
          networkProvider
        }: Line) => {
          return (
            <div>
              <LineActions
                id={id}
                equipmentId={equipmentId}
                pathName={!!gateway?.id ? `gateway` : `equipment`}
                phoneNumber={value}
                forwardValue={forwardValue}
                forwardEnable={forwardEnable}
                locationName={equipmentLocName || gatewayLocName}
                locationErpId={locationErpId ?? undefined}
                providerName={networkProvider?.name}
                refetch={refetch}
              />
            </div>
          );
        },
        cellWidth: '32px'
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  return (
    <>
      {lines.length ? (
        <>
          <Helmet>
            <title> Lines </title>
            <meta
              name="description"
              content="MarketSpark - Command Center"
              data-rh="true"
            />
          </Helmet>
          <div className={styles['table-wrapper']}>
            <Text variant="subtitle2" className={styles['sub-title']}>
              Gateway {equipment?.serialNumber || gateway?.serialNumber} Lines
            </Text>
            <TablePaginated
              id={equipmentId}
              rows={lines}
              sort={sort}
              total={lines.length}
              columns={columns}
              handlePageSize={setPageSize}
              handlePageNav={setPageIndex}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              handleSort={setSort}
              isLoading={false}
              hidePagination={lines.length < 10 ? true : false}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default LocationLines;

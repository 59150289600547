// New home for all selectors

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { userToCurrentUser } from 'utils/transform';
import { initialUser } from './userSlice';

// eslint-disable-next-line import/prefer-default-export
export const selectCurrentUser = createSelector(
  (state: RootState) => state.user || initialUser,
  userToCurrentUser
);

import * as Yup from 'yup';

const ContactUsFormSchema = Yup.object().shape({
  priority: Yup.string().required('Priority is required'),
  subject: Yup.string().required('Topic is required'),
  location: Yup.string(),
  telephoneNumber: Yup.string(),
  siteContact: Yup.string().email('Invalid email'),
  equipment: Yup.array(),
  lines: Yup.array(),
  description: Yup.string().required('Description is required')
});

export default ContactUsFormSchema;

import React from 'react';

import CDRFilterModal from './CDRFilterModal';
import { KPIDashboardCard, Progress, Text } from 'design';
import useCdrs from 'api/useCdrs';
import { ReactComponent as AllCallsIcon } from 'assets/img/IconAllCalls.svg';
import { ReactComponent as InboundCallsIcon } from 'assets/img/IconInboundCalls.svg';
import { ReactComponent as OutboundCallsIcon } from 'assets/img/IconOutboundCalls.svg';
import { CallDirection, CDRDaysAgo } from 'types';
import styles from './CDRCard.module.scss';

interface CDRCardProps {
  id: string;
}

const CDRCard = ({ id }: CDRCardProps): JSX.Element => {
  const [activeIcon, setActiveIcon] = React.useState<CDRDaysAgo>(
    CDRDaysAgo.Month
  );
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [callDirection, setCallDirection] = React.useState<CallDirection>(
    CallDirection.All
  );

  const { data, isLoading } = useCdrs({
    idFilter: id,
    direction: callDirection,
    days: activeIcon,
    statistics: true
  });

  const total = data?.total ?? 0;

  const mappingData = [
    {
      value: data?.data[0]?.count ?? 0,
      text: 'CALLS'
    },
    {
      value: data?.data[0]?.minutes ?? 0,
      text: 'MINUTES'
    }
  ];

  const renderCallDirectionIcon = () => {
    switch (callDirection) {
      case CallDirection.All:
        return <AllCallsIcon />;
      case CallDirection.Inbound:
        return <InboundCallsIcon />;
      case CallDirection.Outbound:
        return <OutboundCallsIcon />;
    }
  };

  return (
    <>
      <KPIDashboardCard
        title="Insights"
        activeIcon={activeIcon}
        setActiveIcon={setActiveIcon}
        setFilterOpen={setFilterOpen}
      >
        {isLoading ? (
          <Progress thin />
        ) : total === 0 ? (
          <Text align="center" className={styles.textCenter}>
            No call insights are presently available for the selected timeframe.
          </Text>
        ) : (
          <div className={styles.contentContainer}>
            {mappingData.map((item, index) => (
              <div key={index} className={styles.columnContainer}>
                {renderCallDirectionIcon()}
                <Text variant="h3" color="primary" className={styles.largeText}>
                  {item.value}
                </Text>
                <Text
                  variant="body2"
                  color="inherit"
                  className={styles.captionText}
                >
                  {item.text}
                </Text>
              </div>
            ))}
          </div>
        )}
      </KPIDashboardCard>
      {filterOpen && (
        <CDRFilterModal
          callDirection={callDirection}
          setCallDirection={setCallDirection}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </>
  );
};

export default CDRCard;

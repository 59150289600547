import * as Yup from 'yup';
import { validateUniversalPhoneNumber } from 'utils/yupHelpers';

const contactSchema = Yup.object().shape({
  firstName: Yup.string()
    .default('')
    .transform(value => (typeof value !== 'string' ? '' : value)),
  lastName: Yup.string()
    .default('')
    .transform(value => (typeof value !== 'string' ? '' : value)),
  email: Yup.string()
    .email('Please enter valid email')
    .default('')
    .transform(value => (typeof value !== 'string' ? '' : value)),
  phoneMobile: Yup.string()
    .typeError('Expected format +xxxxxxxxxxx')
    .nullable()
    .test(
      'formatCheck',
      'Expected format +xxxxxxxxxxx',
      validateUniversalPhoneNumber
    )
    .default('')
    .transform(value => (typeof value !== 'string' ? '' : value)),
  phoneWork: Yup.string()
    .typeError('Expected format +xxxxxxxxxxx')
    .nullable()
    .test(
      'formatCheck',
      'Expected format +xxxxxxxxxxx',
      validateUniversalPhoneNumber
    )
    .default('')
    .nullable()
    .transform(value => (typeof value !== 'string' ? '' : value))
});

export default contactSchema;

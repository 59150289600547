import React from 'react';
import { useDispatch } from 'react-redux';

import ToolsDefaultUI from '../ToolsDefaultUI';
import ToolLineStatusModal from './ToolLineStatusModal';
import axiosInstance, { AxiosError } from 'utils/axiosProvider';
import { useDashboardSlice } from 'app/layouts';

export interface N2PEventItem {
  sipUser: string;
  sipDomain: string;
  extension: string;
  registrationDate: string | Date;
  registrationExpiresDate: string | Date;
  registrationExpires: number;
  contact: string;
  receivedFrom: string;
  numbers: string[];
}

const ToolLineStatus = () => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const [domain, setDomain] = React.useState<string>('');
  const [getSiteIsLoading, setGetSiteIsLoading] = React.useState<boolean>(
    false
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [lines, setLines] = React.useState<N2PEventItem[]>([]);

  // Constants
  const constants = {
    name: 'N2P Line Status',
    label: 'Domain',
    placeHolder: 'ABC123.ms'
  };

  // On Domain Submit
  const onSubmit = async () => {
    if (domain.length === 0) {
      return;
    }
    try {
      setGetSiteIsLoading(true);
      // API Call
      const { data } = await axiosInstance.get(`n2p/events/${domain.trim()}`);

      setLines(data[0].events);
      setShowModal(true);
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: err.response?.data.message,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'An error occurred',
            severity: 'error'
          })
        );
      }
    } finally {
      setGetSiteIsLoading(false);
    }
  };

  // Close Modal
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <ToolsDefaultUI
        name={constants.name}
        label={constants.label}
        placeHolder={constants.placeHolder}
        domain={domain}
        isLoading={getSiteIsLoading}
        setDomain={setDomain}
        onSubmit={onSubmit}
      />
      {showModal && (
        <ToolLineStatusModal
          open={showModal}
          handleClose={handleClose}
          lines={lines}
        />
      )}
    </>
  );
};

export default ToolLineStatus;

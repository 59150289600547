import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient
} from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'utils/axiosProvider';
import { ATA, INTEGRATED_ROUTER, query } from 'constants/app';
import { Equipment, Sort, Status } from 'types';
import qs from 'qs';
import { InstallStatus } from 'types';
import { omit, isEmpty } from 'lodash';

const decanonizeEquipment = (equipment: any): Equipment => {
  const {
    id,
    catalogItem,
    serviceStatus,
    batteryStatus,
    mac,
    serialNumber,
    installStatus,
    account,
    owner,
    imei,
    staticIp,
    username,
    password,
    description,
    service,
    domain,
    sims,
    netcloud,
    dids,
    statusRetrieval
  } = equipment;

  return {
    id,
    status: {
      id: serviceStatus.id,
      name:
        catalogItem?.group === ATA || catalogItem?.group === INTEGRATED_ROUTER
          ? 'None'
          : serviceStatus.name
    },
    catalogItem: {
      id: catalogItem?.id,
      name: catalogItem?.name,
      group: catalogItem?.group,
      brand: catalogItem?.brand,
      fxoPorts: catalogItem?.fxoPorts,
      simCount: catalogItem?.simCount
    },
    mac,
    serialNumber,
    installStatus: {
      id: installStatus.id,
      name: installStatus?.name
    },
    staticIp,
    account: {
      id: account?.id,
      name: account?.name,
      erpLocationId: account?.erpLocationId,
      organization: {
        id: account?.organization?.id,
        name: account?.organization?.name
      }
    },
    owner: {
      name: owner?.name
    },
    imei,
    username,
    password,
    description,
    service,
    domain,
    parentId: equipment?.parent?.id,
    batteryStatus,
    sims,
    dids,
    statusRetrieval,
    netcloud
  };
};

interface GetEquipmentParams {
  queryKey: [
    __0: string,
    filter: string,
    andFilter: Status,
    sort: Sort,
    pageIndex: number,
    pageSize: number,
    installStatusName: string
  ];
}

interface GetBatteryStatusParams {
  queryKey: [
    __0: string,
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

export interface GetInstallStatusDataParams {
  queryKey: [
    __0: string,
    andfilter: string,
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

export interface LineType {
  id: string;
  name: string;
}

interface GetEquipment {
  queryKey: [__0: string, id: string];
}

const getInstallStatus = async ({
  queryKey
}: GetEquipmentParams): Promise<InstallStatus[]> => {
  const [, pageIndex, pageSize] = queryKey;
  const { data } = await axios.get(`/equipment-install-status`, {
    params: {
      page: pageIndex,
      limit: pageSize
    }
  });
  return data?.data;
};

const useInstallStatus = (
  pageIndex: number,
  pageSize: number
): UseQueryResult<InstallStatus[], Error> => {
  return useQuery<InstallStatus[], AxiosError>(
    [query.installStatus, pageIndex, pageSize],
    getInstallStatus
  );
};

const getEquipments = async ({ queryKey }: GetEquipmentParams) => {
  const [
    ,
    filter,
    andfilter,
    sort,
    pageIndex,
    pageSize,
    installStatusName
  ] = queryKey;

  let filterParams: string | string[] = `catalogItem.brand|eq|${andfilter}`;
  if (Array.isArray(andfilter)) {
    const [catalogBrand, catalogGroup] = andfilter;
    if (isEmpty(catalogBrand)) {
      filterParams = [`catalogItem.group|eq|${catalogGroup}`];
    } else {
      filterParams = [
        `catalogItem.brand|eq|${catalogBrand}`,
        `catalogItem.group|eq|${catalogGroup}`
      ];
    }
  }

  const possibleFilter = { filter: [`account.id|eq|${filter}`] };
  let paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';
  const possibleBrandFilter = andfilter
    ? { [paramFilterKey]: filterParams }
    : '';

  paramFilterKey =
    isEmpty(possibleFilter) && isEmpty(possibleBrandFilter)
      ? 'filter'
      : 'andfilter';

  const possibleStatusFilter = installStatusName
    ? { [paramFilterKey]: `installStatus.name|eq|${installStatusName}` }
    : '';
  const { data } = await axios.get('/equipment', {
    params: {
      ...possibleFilter,
      ...possibleBrandFilter,
      ...possibleStatusFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  const equipment: Equipment[] = data.data.map(decanonizeEquipment);
  return {
    equipment,
    page: Number(data.page),
    pageCount: Number(data.pageCount),
    total: Number(data.total)
  };
};

export interface PagedEquipment {
  equipment: Equipment[];
  total: number;
}

const useEquipment = ({
  filter = '',
  statusFilter = '',
  sort = ['', 'ASC'],
  pageIndex = 1,
  pageSize = 100,
  installStatusName
}: {
  filter?: string;
  statusFilter?: string | string[];
  sort?: Sort;
  pageIndex?: number;
  pageSize?: number;
  installStatusName?: string;
}): UseQueryResult<PagedEquipment, AxiosError> => {
  return useQuery<PagedEquipment, AxiosError>(
    [
      query.equipment,
      filter,
      statusFilter,
      sort,
      pageIndex,
      pageSize,
      installStatusName
    ],
    getEquipments,
    {
      keepPreviousData: true
    }
  );
};

const equipmentDomainExists = async (filter: string): Promise<boolean> => {
  const url = `/equipment?filter=${filter}`;
  const { data } = await axios.get(url);
  return data.data.length > 0;
};

const getBatteryStatus = async ({ queryKey }: GetBatteryStatusParams) => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;

  const possibleFilter = { filter: [`account.id|eq|${filter}`] };

  const { data } = await axios.get('/equipment/battery', {
    params: {
      ...possibleFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  const equipment: Equipment[] = data.data.map(decanonizeEquipment);
  return {
    equipment,
    page: Number(data.page),
    pageCount: Number(data.pageCount),
    total: Number(data.total)
  };
};

const useBatteryStatus = ({
  filter = '',
  sort = ['', 'ASC'],
  pageIndex = 1,
  pageSize = 100
}): UseQueryResult<PagedEquipment, AxiosError> => {
  return useQuery<PagedEquipment, AxiosError>(
    [query.equipment, filter, sort, pageIndex, pageSize],
    getBatteryStatus,
    {
      keepPreviousData: true
    }
  );
};

const getEquipmentByID = async ({
  queryKey
}: GetEquipment): Promise<Equipment> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/equipment/${id}`);
  return decanonizeEquipment(data);
};

const useEquipmentByID = (
  id: string,
  pathName: string
): UseQueryResult<Equipment, Error> => {
  return useQuery<Equipment, AxiosError>(
    [query.equipment, id],
    getEquipmentByID,
    {
      // api to call only if the pathName is equipment.
      enabled: pathName === 'equipment'
    }
  );
};

interface EquipmentDelete {
  dids: number;
  sims: number;
  batteries: Equipment[];
}

export const getEquipmentDependency = async ({
  queryKey
}: GetEquipmentParams): Promise<EquipmentDelete> => {
  const [, equipmentId] = queryKey;
  const { data } = await axios.get(
    `/equipment/${equipmentId}/dependencies/count`
  );
  return data;
};

const useEquipmentDependency = (
  equipmentId: string
): UseQueryResult<EquipmentDelete, Error> => {
  return useQuery<EquipmentDelete, AxiosError>(
    [query.equipmentDelete, equipmentId],
    getEquipmentDependency,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

const deleteEquipment = async (id: string): Promise<void> => {
  await axios.delete(`/equipment/${id}`);
};

const useDeleteEquipment = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteOrg = useMutation<void, AxiosError, any>(deleteEquipment, {
    onSettled: () => {
      queryClient.invalidateQueries([query.equipment]);
    }
  });

  return mutateDeleteOrg;
};

/**
 * @param {GetInstallStatusDataParams}
 * @returns {id: string, name: string}[]
 */
const getInstallStatusData = async ({
  queryKey
}: GetInstallStatusDataParams) => {
  const [, pageIndex, pageSize] = queryKey;

  const { data }: AxiosResponse = await axios.get('/equipment-install-status', {
    params: {
      page: pageIndex,
      limit: pageSize
    }
  });

  return data.data;
};

const useGetInstallStatus = (
  pageIndex: number,
  pageSize: number
): UseQueryResult<InstallStatus[], AxiosError> => {
  return useQuery<InstallStatus[], AxiosError>(
    [query.equipment, pageIndex, pageSize],
    getInstallStatusData,
    {
      keepPreviousData: true
    }
  );
};

const patchEquipment = async (
  updateData: Partial<Equipment>
): Promise<void> => {
  const updatedEquipment = {
    ...omit(updateData, ['installStatus', 'id']),
    installStatusId: updateData?.installStatus?.id
  };
  await axios.patch(`equipment/${updateData?.id}`, updatedEquipment);
};

const useUpdateEquipment = (): UseMutationResult<
  void,
  AxiosError,
  Partial<Equipment>
> => {
  const queryClient = useQueryClient();

  const mutatePatchUser = useMutation<void, AxiosError, Partial<Equipment>>(
    patchEquipment,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.equipmentData]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([query.equipment]);
      }
    }
  );

  return mutatePatchUser;
};

const postCreateEquipment = async (
  createData: Record<string, any>
): Promise<string> => {
  const { data } = await axios.post(`/equipment`, createData);
  return data.id;
};

const usePostCreateEquipment = (): UseMutationResult<
  string,
  AxiosError,
  Record<string, any>
> => {
  const queryClient = useQueryClient();
  const equipmentMutation = useMutation<
    string,
    AxiosError,
    Record<string, any>
  >(postCreateEquipment, {
    onSuccess: () => {
      queryClient.invalidateQueries([query.equipment]);
    }
  });

  return equipmentMutation;
};

/*Validate Uniqueness
 */
const useCheckUniqueness = async (
  field: string,
  value: string,
  id?: string
): Promise<boolean> => {
  // if no id is passed, do not send the query param
  const idParam = id ? `?id=${id}` : '';
  const responseData = await axios.get(
    `/equipment/field/${field}/value/${value}${idParam}`
  );
  return responseData.data && responseData.data.status === 200;
};

export {
  useEquipmentByID,
  useInstallStatus,
  useEquipmentDependency,
  useDeleteEquipment,
  useGetInstallStatus,
  useUpdateEquipment,
  postCreateEquipment,
  useBatteryStatus,
  useCheckUniqueness,
  getEquipments,
  usePostCreateEquipment,
  equipmentDomainExists
};
export default useEquipment;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useApiInfo from 'api/useApiInfo';

import ListLink from './ListLink';
import CmdcIcon from '../CmdcIcon';
import useCurrentUser from '../../api/useCurrentUser';
import { envColorMap } from '../../constants/app';

export interface CmdcListItemProps {
  to: string;
}

const CmdcListItem = ({ to }: CmdcListItemProps): JSX.Element => {
  const { data: apiInfo } = useApiInfo();
  const { data: currentUser } = useCurrentUser();
  const classes = useStyles();
  let iconFill = '';
  if (currentUser?.isSuperAdmin) {
    iconFill = envColorMap[apiInfo?.environment || ''];
  }
  return (
    <ListLink
      label={
        <>
          <p className={classes.headerText}>MarketSpark</p>
          <p className={classes.subHeaderText}>Command Center</p>
        </>
      }
      Icon={() => <CmdcIcon fill={iconFill} />}
      to={to}
      classes={classes}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerText: {
    fontSize: '15px',
    margin: '0px',
    textTransform: 'none'
  },
  subHeaderText: {
    fontSize: '7px',
    margin: '0px',
    letterSpacing: '2px',
    marginTop: '2px'
  },
  listItem: {
    padding: `${theme.spacing(0.625)}px ${theme.spacing(2)}px ${theme.spacing(
      0.625
    )}px`,
    height: 62,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    '& svg': {
      color: theme.palette.common.black
    },
    '&:hover': {
      backgroundColor: theme.palette.grey['50']
    }
  },
  listText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1rem'
  }
}));

export default CmdcListItem;

import * as React from 'react';
import { Text } from 'design';
import { Link } from '@material-ui/core';
import portalUrls from './EquipmentUrls';

interface EquipmentProps {
  brand?: string;
  group?: string;
  serialNumber?: string;
}

// React component which will turn the serial number into a link to the equipment portal.
// If there is no serial number, it will display a dash.
// If there is no portal url for the equipment, it will display the serial number without a link.
const EquipmentPortalLink = ({
  brand,
  group,
  serialNumber
}: EquipmentProps): JSX.Element => {
  if (!serialNumber) {
    return <Text>-</Text>;
  }

  // Gets the portal url for the equipment based on the brand and group (equipment type).
  // The serialNumber is used in generating the equipment-specific URL.
  // Returns undefined if there exists no portal url for the combination of brand and group.
  function getPortalUrl(): string | undefined {
    if (
      brand?.toLowerCase() === 'cradlepoint' &&
      group?.toLowerCase() === 'router'
    ) {
      return portalUrls.cradlepoint.router(serialNumber ?? '');
    }

    return undefined;
  }

  const portalUrl = getPortalUrl();

  // If there is a portal url, the serial number will be a link to the portal.
  return portalUrl ? (
    <Link href={portalUrl} target="_blank">
      <Text>{serialNumber}</Text>
    </Link>
  ) : (
    <Text>{serialNumber}</Text>
  );
};

export default EquipmentPortalLink;

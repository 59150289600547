import React from 'react';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { deleteHardwareCatalog } from 'api/useHardwareCatalogs';
import { Dialog, Delete } from 'design';
import {
  Catalogs,
  GatewayCatalog,
  PowersourceCatalog,
  RouterCatalog
} from 'types';

export interface HardwareDeleteDialogProps {
  title: 'Router' | 'Gateway' | 'Power Source';
  identifier: Catalogs;
  actionedCatalog: RouterCatalog | GatewayCatalog | PowersourceCatalog;
  refetch: () => void;
  handleClose: () => void;
}

const HardwareDeleteDialog = ({
  title,
  identifier,
  actionedCatalog,
  refetch,
  handleClose
}: HardwareDeleteDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const handleOnSubmit = async () => {
    try {
      // API Call
      await deleteHardwareCatalog(identifier, actionedCatalog.id);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: `${title} "${actionedCatalog.model}" has been deleted`,
          severity: 'success'
        })
      );
    } catch (error) {
      dispatch(
        actions.showNotify({
          message: `Failed to delete the ${identifier}`,
          severity: 'error'
        })
      );
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open={true} handleClose={handleClose}>
      <Delete
        isDelete={true}
        onSubmit={handleOnSubmit}
        onReset={handleClose}
        title={title}
        deleteContent={`This will permantely remove the ${title.toLocaleLowerCase()} "${
          actionedCatalog.model
        }" from CMDC.`}
        onSubmitLoading={false}
        type="delete"
        errorText="Type delete to confirm"
      />
    </Dialog>
  );
};

export default HardwareDeleteDialog;

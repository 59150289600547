import React from 'react';

import { TextInput } from 'design';
import { EquipmentAndHardwareFieldInputProps } from '../types';

const ImeiFieldInput = ({
  name,
  value,
  validationMessage,
  isError,
  onChange,
  onBlur
}: EquipmentAndHardwareFieldInputProps) => {
  return (
    <TextInput
      id="imei"
      name={name}
      label="IMEI"
      maxLength={15}
      placeholder="###############"
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default ImeiFieldInput;

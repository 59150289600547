/* eslint-disable react/default-props-match-prop-types */
import * as React from 'react';
import { selectAuth } from 'app/slice/selectors';
import { useSelector } from 'react-redux';

export interface UserCanProps {
  perform: string;
  yes: React.ReactNode;
  no: React.ReactNode;
}

/**
 * Check if the user has permission to perform an action
 * @param {UserCanProps}
 * @return {ReactNode}
 */
const UserCan = ({ perform, yes, no }: UserCanProps): JSX.Element => {
  const { permissions } = useSelector(selectAuth); // get current user permissions
  const hasPermission = permissions.indexOf(perform) > -1; // check permission to perform action
  return hasPermission ? <>{yes}</> : <>{no}</>;
};

UserCan.defaultProps = {
  yes: undefined,
  no: undefined
};

export default UserCan;

import React, { SyntheticEvent } from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextInput,
  CardFooter
} from 'design';
import { N2P_DOMAIN_REGEX } from 'constants/app';
import styles from './ToolsDefaultUI.module.scss';

export interface ToolsDefaultUIProps {
  name: string;
  label: string;
  domain: string;
  placeHolder: string;
  isLoading: boolean;
  setDomain: (domain: string) => void;
  onSubmit: () => void;
}

const ToolsDefaultUI = (props: ToolsDefaultUIProps): JSX.Element => {
  const {
    name,
    label,
    domain,
    placeHolder,
    isLoading,
    setDomain,
    onSubmit
  } = props;

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>(false);

  const textInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  // Include basic validation before calling onSubmit
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsError(false);
    if (domain.length === 0) {
      setErrorMessage('Please enter a domain');
      setIsError(true);
      return;
    }
    if (!N2P_DOMAIN_REGEX.test(domain)) {
      setErrorMessage('Domain should have the format: domain.ms');
      setIsError(true);
      return;
    }

    onSubmit();
  };

  return (
    <Card>
      <CardHeader title={name} />
      <CardContent noOverflow>
        <form onSubmit={handleSubmit}>
          <div className={styles.container}>
            <TextInput
              id={name}
              name={name}
              label={label}
              isError={isError}
              validationMessage={errorMessage}
              onChange={textInputOnChange}
              value={domain}
              placeholder={placeHolder}
              adornments={{
                className: styles.inputStyle
              }}
            />
            <Button isLoading={isLoading} type="submit">
              Find
            </Button>
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <>&nbsp;</>
      </CardFooter>
    </Card>
  );
};

export default ToolsDefaultUI;

import * as React from 'react';
import { Form, Formik } from 'formik';
import { Button, TextInput } from 'design';
import CloseIcon from '@material-ui/icons/Close';
import { Text } from 'design';
import { makeStyles, Theme, IconButton } from '@material-ui/core';
import * as Yup from 'yup';

/**
 * Formik inital values
 */
const initialValues = {
  confirmAction: ''
};

export interface DeleteProps {
  isDelete: boolean;
  onSubmit: (() => Promise<void>) | (() => void);
  onReset: () => void;
  title?: string;
  deleteContent: string | JSX.Element;
  problemContent?: string;
  onSubmitLoading: boolean;
  dependencyData?: {
    label: string;
    value: number | string | undefined;
  }[];
  dependencyLoading?: boolean;
  errorText: string;
  type: 'delete' | 'detach';
}

const Delete = ({
  isDelete,
  onSubmit,
  onReset,
  deleteContent,
  title,
  problemContent,
  onSubmitLoading,
  dependencyData,
  dependencyLoading,
  errorText,
  type
}: DeleteProps) => {
  const cc = useStyles();
  if (dependencyLoading) return <>loading...</>;

  /**
   * Validation schema
   */
  const schema = Yup.object().shape({
    confirmAction: Yup.string()
      .required(`${errorText}`)
      .test('fileExists', `You need to type ${type}`, value => {
        if (type === 'detach') {
          return value?.toLowerCase() === 'detach';
        }
        return value?.toLowerCase() === 'delete';
      })
  });
  return (
    <>
      {isDelete ? (
        <Formik
          key="delete"
          initialValues={initialValues}
          initialErrors={{}}
          validationSchema={schema}
          onSubmit={onSubmit}
          onReset={onReset}
        >
          {({ values, errors, handleBlur, handleChange, isValid }) => (
            <Form>
              {type === 'delete' && title ? (
                <div className={cc.headerWrapper}>
                  <Text
                    variant="h5"
                    className={cc.header}
                  >{`Deleting ${title}`}</Text>
                </div>
              ) : (
                <></>
              )}
              <div className={cc.actionInfo}>
                {typeof deleteContent === 'string' ? (
                  <Text variant="body1">{deleteContent}</Text>
                ) : (
                  deleteContent
                )}
              </div>
              <div className={cc.inputWrapper}>
                <TextInput
                  id="confirmAction"
                  name="confirmAction"
                  type="text"
                  isError={!!errors.confirmAction}
                  validationMessage={errors.confirmAction}
                  label={`Type ${type} to confirm`}
                  value={values.confirmAction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={cc.formFooter}>
                <Button
                  isLoading={onSubmitLoading}
                  type="button"
                  onClick={onReset}
                  variant="outlined"
                >
                  cancel
                </Button>
                <Button
                  isLoading={onSubmitLoading}
                  disabled={!isValid || values.confirmAction === ''}
                  type="submit"
                  className={cc.deleteBtn}
                >
                  {type}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <div className={cc.errorHeaderWrapper}>
            <Text variant="h5">There was a problem</Text>
            <IconButton
              data-testid="closeIcon"
              className={cc.closeButton}
              onClick={onReset}
            >
              <CloseIcon style={{ color: '#A9B3B7' }} fontSize="small" />
            </IconButton>
          </div>
          <div className={cc.errorActionInfo}>
            <Text variant="body1">{problemContent}</Text>
          </div>
          <div>
            {dependencyData?.map((e, i) => {
              return (
                <div key={i}>
                  {e.value !== 0 && (
                    <Text variant="body1" className={cc.dependencyLists}>
                      {e.label} {` - `} {e.value}
                    </Text>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  header: {
    wordBreak: 'break-word'
  },
  actionInfo: {
    marginBottom: theme.spacing(4),
    marginTop: '16px'
  },
  inputWrapper: {
    marginBottom: theme.spacing(1),
    minWidth: '100%'
  },
  formFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    '& > button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  deleteBtn: {
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
      curser: 'poniter'
    }
  },
  closeButton: {
    padding: '5px'
  },
  errorActionInfo: {
    margin: theme.spacing(2, 1, 2, 0)
  },
  dependencyLists: {
    margin: theme.spacing(0.5, 0.5, 0, 0),
    fontSize: '13px'
  }
}));

export default Delete;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useQueryClient } from 'react-query';

import { useDashboardSlice } from 'app/layouts';
// TODO: Refactor to use current user from current user store
import useCurrentUser, { useTempOrg } from 'api/useCurrentUser';
import { useGetAllOrgMS } from 'api/useOrgs';

import FormikSubmitListener from 'utils/formikHelpers';
import AutocompleteDropdown from './AutocompleteDropdown';
import { Organization } from 'types';
import { userActions } from 'store/userSlice';
import { AxiosError } from 'axios';

interface OrgAutoCompleteDropdownProps {
  userOrgId: string;
}

const OrgAutoCompleteDropdown = ({
  userOrgId
}: OrgAutoCompleteDropdownProps): JSX.Element => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions: dashboardActions } = useDashboardSlice();
  const { mutateAsync: setTempOrg } = useTempOrg();
  const { data: currentUser } = useCurrentUser();
  const { data: orgs } = useGetAllOrgMS(true);

  const { organization, id: userId } = currentUser ?? {
    id: '',
    organization: {} as Organization
  };
  const orgList = orgs || [];

  const initialValues = {
    [userOrgId]: {
      id: organization.id,
      name: organization.name,
      status: organization.status
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        if (values[userOrgId].id) {
          try {
            const { id, name, logo, status } = await setTempOrg({
              id: userId,
              orgId: values[userOrgId].id
            });
            dispatch(
              userActions.setOrg({
                id,
                name,
                logo,
                status,
                authMethod: currentUser?.organization.authMethod
              } as Organization)
            );
            dispatch(
              dashboardActions.showNotify({
                message: 'User organization updated successfully',
                severity: 'success'
              })
            );

            if (history.location.pathname.includes('location')) {
              history.push('/locations');
            }
            queryClient.invalidateQueries();
          } catch (err) {
            let error = "Error: could not update user's organization!";
            if (err instanceof Error) {
              error = err.message;
            } else if (err instanceof AxiosError) {
              error = err.response?.data.message;
            }
            dispatch(
              dashboardActions.showNotify({
                message: error
              })
            );
          }
        }
      }}
      /**
       * updates the initialValues within FormikContext.Provider once options data is received from api
       * to display the current user's org
       */
      enableReinitialize
    >
      <Form style={{ backgroundColor: '#222' }}>
        <AutocompleteDropdown
          id={userOrgId}
          name={userOrgId}
          options={orgList}
          setValueDefaultProps={{ id: '', name: '' }}
        />
        <FormikSubmitListener />
      </Form>
    </Formik>
  );
};

export default OrgAutoCompleteDropdown;

import {
  createSlice as createSliceOriginal,
  SliceCaseReducers,
  CreateSliceOptions,
  Slice
} from '@reduxjs/toolkit';
import { RootStateKeyType } from 'types';

/* istanbul ignore next */
const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends RootStateKeyType
>(
  options: CreateSliceOptions<State, CaseReducers, Name>
): Slice<State, CaseReducers, Name> => {
  return createSliceOriginal(options);
};

export default createSlice;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Hidden,
  Popover,
  Typography
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DECOMMISSION, Hardware, Overview } from 'constants/app';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSidebar } from '../theme';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/selectors';
import { RoleName } from '../../types/AuthRoles';

interface SideBarPopperMenuProps {
  label: React.ReactNode;
  Icon: typeof SvgIcon;
  classes?: any;
}

interface Menu {
  name: string;
  path?: string;
}

const SideBarPopperMenu = ({
  label,
  Icon,
  classes
}: SideBarPopperMenuProps): JSX.Element => {
  const cc = useStyles();
  const itemClasses = classes ?? cc;

  const [, setOpen] = useSidebar();
  const [openPopover, setOpenPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const [hoverList, setHoverList] = useState<Menu[]>([]);
  const user = useSelector(selectCurrentUser);

  const overviewMenu: Menu[] = [
    {
      name: 'ORGANIZATIONS',
      path: `/organizations`
    },
    {
      name: 'ORGANIZATION',
      path: `/organization/${user.organization?.id}`
    },
    {
      name: 'LOCATIONS',
      path: `/locations`
    },
    {
      name: 'LINES',
      path: `/lines`
    }
  ];

  const hardware: Menu[] = [
    {
      name: 'BUNDLES',
      path: `/bundles`
    },
    {
      name: 'ROUTERS',
      path: `/routers`
    },
    {
      name: 'GATEWAYS',
      path: `/gateways`
    },
    {
      name: 'BATTERIES',
      path: '/power-sources'
    },
    {
      name: 'HYBRIDS'
    },
    {
      name: 'FIRE ALARMS'
    }
  ];

  const decommission: Menu[] = [
    {
      name: 'DECOM MAP',
      path: `/decommission`
    },
    {
      name: 'DISCLOSURES',
      path: `/decommission/disclosures`
    }
  ];

  React.useEffect(() => {
    switch (label) {
      case Overview:
        setHoverList(overviewMenu);
        break;
      case Hardware:
        setHoverList(hardware);
        break;
      case DECOMMISSION:
        setHoverList(decommission);
        break;
      default:
        break;
    }
    // We disable eslint rule here
    // because hardware, decommission, and overviewMenu are not dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, user]);

  const popoverLeave = () => {
    setOpenPopover(false);
  };

  return (
    <li>
      <Hidden xsDown>
        <ListItem
          button
          className={
            openPopover ? itemClasses.listItemHover : itemClasses.listItem
          }
          ref={popoverAnchor}
          aria-owns="mouse-over-popover"
          aria-haspopup="true"
          onMouseEnter={() => {
            setOpenPopover(true);
          }}
          onClick={() => {
            setOpenPopover(true);
          }}
          onMouseLeave={popoverLeave}
        >
          <ListItemIcon className={itemClasses.listIcon}>
            <Icon />
          </ListItemIcon>
          <ListItemText className={itemClasses.listText} disableTypography>
            {label}
          </ListItemText>
        </ListItem>
      </Hidden>
      <Hidden smUp>
        <ListItem button className={itemClasses.overview}>
          <Accordion className={itemClasses.accordion}>
            <AccordionSummary
              aria-controls="panel1a-content"
              className={itemClasses.accordionSummary}
            >
              <ListItemIcon className={itemClasses.listIcon}>
                <Icon />
              </ListItemIcon>
              <ListItemText className={itemClasses.listText} disableTypography>
                {label}
              </ListItemText>
            </AccordionSummary>
            <>
              {hoverList
                .filter(list => {
                  if (
                    list.name === 'ORGANIZATION' &&
                    user.primaryRole.name === RoleName.installPartner
                  ) {
                    return false;
                  }
                  if (list.name !== 'ORGANIZATION' && user.isSuperAdmin) {
                    return true;
                  }
                  if (list.name !== 'ORGANIZATIONS' && !user.isSuperAdmin) {
                    return true;
                  }
                  return false;
                })
                .map((list, i) => {
                  return (
                    <AccordionDetails
                      key={i}
                      className={itemClasses.accordionDetails}
                    >
                      {list?.path ? (
                        <Link
                          to={list.path}
                          className={itemClasses.link}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Typography className={itemClasses.dropdownList}>
                            {list?.name}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography
                          className={itemClasses.dropdownList}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          {list?.name}
                        </Typography>
                      )}
                    </AccordionDetails>
                  );
                })}
            </>
          </Accordion>
        </ListItem>
      </Hidden>
      <Popover
        id="mouse-over-popover"
        className={itemClasses.popover}
        classes={{
          paper: itemClasses.popoverContent
        }}
        open={openPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          onMouseEnter: () => setOpenPopover(true),
          onMouseLeave: popoverLeave
        }}
      >
        <Card className={itemClasses.popper}>
          <>
            {hoverList
              ?.filter(
                list =>
                  (list.name !== 'ORGANIZATION' && user.isSuperAdmin) ||
                  (list.name !== 'ORGANIZATIONS' && !user.isSuperAdmin)
              )
              .map((list, i) => {
                return (
                  <div key={i}>
                    {list?.path ? (
                      <Link to={list.path} className={itemClasses.link}>
                        <Typography className={itemClasses.dropdownList}>
                          {list?.name}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography className={itemClasses.dropdownList}>
                        {list?.name}
                      </Typography>
                    )}
                  </div>
                );
              })}
          </>
        </Card>
      </Popover>
    </li>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  listItemHover: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  listIcon: {
    color: theme.palette.common.white
  },
  listText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    // fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem'
  },
  link: {
    textDecoration: 'none',
    width: '100%'
  },
  popper: {
    width: 200,
    background: '#222222',
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 0
  },
  dropdownList: {
    background: '#222222',
    width: '100%',
    color: '#fff',
    padding: 8,
    '&:hover, &.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  popover: {
    pointerEvents: 'none',
    '& .MuiPaper-rounded': {
      borderRadius: 0
    }
  },
  popoverContent: {
    pointerEvents: 'auto'
  },
  accordion: {
    background: '#222222',
    color: '#fff',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 30
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 30
    }
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0
    }
  },
  accordionDetails: {
    margin: 0,
    padding: 0,
    marginLeft: 42,
    '&:hover, &.active': {
      backgroundColor: 'white',
      color: theme.palette.common.black
    }
  }
}));

export default SideBarPopperMenu;

/**
 *
 * UsersPage
 *
 */
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useUsers from 'api/useUsers';
import PageHeader from 'app/components/PageHeader';
import { useDashboardSlice } from 'app/layouts';
import { pageSizeOptions } from 'constants/app';
import { ColumnProps, Link, SearchInput, TablePaginated } from 'design';
import useSort from 'hooks/useSort';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import UserActions from './UserActions';
import { Helmet } from 'react-helmet-async';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import useRolesCount from 'api/useRolesCount';
import RolesBadgeBar from 'app/components/RolesBadgeBar';
import { User } from 'types/User';
import { RoleName } from '../../../types/AuthRoles';

const UsersPage = (): JSX.Element => {
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [filter, setFilter] = React.useState('');
  const [rolesFilter, setRolesFilter] = React.useState<RoleName | ''>('');
  const [searchInput, setSearchInput] = React.useState('');
  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const { isLoading, data, error } = useUsers(
    filter,
    rolesFilter,
    sort,
    pageIndex,
    pageSize
  );
  const { data: currentUser } = useCurrentUser();
  const { users, total } = data || { users: [], total: 0 };
  const {
    data: counts,
    isLoading: isRoleCountLoading,
    error: rolesCountError
  } = useRolesCount();

  if (error) {
    throw new Error(`${error.message} Failed to fetch Users!`);
  }

  /**
   * Dispatch dialog to invite user
   */
  const handleAddUser = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Invite Users',
        lazyComponent: 'UsersInvite'
      })
    );
  }, [actions, dispatch]);
  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'isPendingConfirm',
        label: 'Status',
        sortable: true,
        resizable: true,
        hidden: !counts?.pending,
        render: (user: User) => (
          <>{!user.isPendingConfirm ? 'Pending' : 'Active'}</>
        )
      },
      {
        accessor: 'firstName',
        label: 'First Name',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'lastName',
        label: 'Last Name',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'username',
        label: 'Email',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'phoneMobile',
        label: 'Contact number',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'orgPosition',
        label: 'Title',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'organization.name',
        label: 'Organization',
        sortable: true,
        hidden: !currentUser?.isMSOrg,
        render: ({ organization }: User) => (
          <Link to={`/organization/${organization?.id}`}>
            {organization?.name}
          </Link>
        ),
        resizable: true
      },
      {
        accessor: 'primaryRole.name',
        label: 'Role',
        sortable: true,
        resizable: true
      },
      {
        accessor: '',
        label: '',
        hidden: currentUser?.isUser,
        render: (user: User) => <UserActions user={user} />,
        sortable: false
      }
    ],
    [currentUser?.isMSOrg, currentUser?.isUser, counts]
  );

  return (
    <>
      <Helmet>
        <title> Users </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={cc.headWrapper}>
        <PageHeader title="Users" />
        <div className={cc.optionsWrapper}>
          <SearchInput
            className={cc.searchInput}
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          {!currentUser?.isUser && (
            <IconButton
              onClick={handleAddUser}
              data-testid="addButton"
              classes={{ root: cc.addUserButton }}
            >
              <AddCircleIcon
                color="primary"
                classes={{ root: cc.addUserIcon }}
              />
            </IconButton>
          )}
        </div>
      </div>
      <RolesBadgeBar
        countData={
          counts ?? {
            superAdmin: 0,
            orgAdmin: 0,
            locationAdmin: 0,
            user: 0,
            total: 0,
            pending: 0
          }
        }
        isLoading={isRoleCountLoading}
        error={rolesCountError}
        rolesFilter={rolesFilter}
        setRolesFilter={setRolesFilter}
        isMsOrg={currentUser?.isMSOrg ?? false}
      />
      <div>
        <TablePaginated
          id="users"
          rows={users}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    }
  },
  searchInput: {
    margin: theme.spacing(0, 2, 1, 0)
  },
  optionsWrapper: {
    display: 'flex'
  },
  button: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    overflow: 'hidden',
    fontSize: theme.typography.body1.fontSize,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit'
  },
  addUserButton: {
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  addUserIcon: {
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  popper: {
    backgroundColor: '#fff',
    borderColor: '#D6E5EB',
    borderStyle: 'solid',
    border: '2px',
    width: 150,
    cursor: 'pointer',
    marginRight: '10px'
  },
  hover: {
    padding: '5px',
    width: '100%',
    '&:hover': {
      backgroundColor: '#D6E5EB'
    }
  }
}));

export default UsersPage;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

export interface FooterProps {
  children: React.ReactNode;
}

/**
 * Footer of the application with responsivity
 * @param {FooterProps} props
 * @returns {JSX.Element}
 */
const Footer = ({ children }: FooterProps): JSX.Element => {
  const cc = useStyles();
  return (
    <div className={cc.footerRoot}>
      <div className={cc.footerRight}>
        &copy; {new Date().getFullYear()} MarketSpark
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    color: theme.palette.grey['500'],
    textAlign: 'right',
    width: '100%',
    minWidth: '100%',
    paddingTop: '36px',
    paddingBottom: '0',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'right'
    }
  },
  footerRight: {
    padding: theme.spacing(1, 1, 0, 0),
    fontSize: '16px',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 0, 0, 2),
      flexGrow: 0
    }
  }
}));

export default Footer;

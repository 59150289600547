import * as React from 'react';
import { useFormik } from 'formik';
import { useCreateContact } from 'api/useContacts';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import contactSchema from 'schemas/contactSchema';
import ContactForm from '../forms/ContactForm';

const CreateContact = ({ handleClose }: { handleClose: () => void }) => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const { mutateAsync: createContact, isLoading } = useCreateContact();

  // NOTE: I hate this. :)
  const formProps = useFormik({
    initialValues: contactSchema.cast({}),
    validationSchema: contactSchema,
    onSubmit: async values => {
      try {
        await createContact(values);
        handleClose();
        dispatch(
          actions.showNotify({
            message: 'Contact created',
            severity: 'success'
          })
        );
      } catch (e: any) {
        dispatch(
          actions.showNotify({
            message:
              e?.response?.data?.message ?? 'Error: Could not create contact'
          })
        );
      }
    }
  });

  return (
    <ContactForm
      isLoading={isLoading}
      handleClose={() => {
        handleClose();
      }}
      formProps={formProps}
    />
  );
};

export default CreateContact;

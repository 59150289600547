import React from 'react';

import { KPIDashboardCard, Link, Progress, Text } from 'design';
import useCdrs from 'api/useCdrs';
import { CallDirection, CDRDaysAgo, SortDirection } from 'types';
import FilterModal from './FilterModal';
import styles from './DashboardStatisticCardTemplate.module.scss';
import PhoneNumber from '../PhoneNumber';

export enum CardType {
  Locations = 'Locations',
  Lines = 'Lines'
}

export enum StatisticType {
  Calls = 'calls',
  Minutes = 'minutes'
}

interface MappedDataType {
  value?: string;
  text: string;
  location?: string;
  number?: string;
  locationId?: string;
  orgId?: string;
}

interface DashboardStatisticCardTemplateProps {
  id: string;
  type: CardType;
  title: string;
  group: string;
  IconAll: React.FC<React.SVGProps<SVGSVGElement>>;
  IconInbound: React.FC<React.SVGProps<SVGSVGElement>>;
  IconOutbound: React.FC<React.SVGProps<SVGSVGElement>>;
}

const DashboardStatisticCardTemplate = ({
  id,
  type,
  title,
  group,
  IconAll,
  IconInbound,
  IconOutbound
}: DashboardStatisticCardTemplateProps): JSX.Element => {
  const [statisticType, setStatisticType] = React.useState<StatisticType>(
    StatisticType.Calls
  );
  const [activeIcon, setActiveIcon] = React.useState<CDRDaysAgo>(
    CDRDaysAgo.Day
  );
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [callDirection, setCallDirection] = React.useState<CallDirection>(
    CallDirection.All
  );
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(
    'DESC'
  );

  const { data, isLoading } = useCdrs({
    idFilter: id,
    days: activeIcon,
    direction: callDirection,
    statistics: true,
    group,
    pageSize: 4,
    sort: [statisticType, sortDirection]
  });

  const total = data?.total ?? 0;

  const mappedData: MappedDataType[] | undefined = data?.data.map(item => ({
    value: statisticType === StatisticType.Calls ? item.count : item.minutes,
    text: statisticType === StatisticType.Calls ? 'calls' : 'minutes',
    number: item.number?.slice(1),
    location: item.locationName,
    orgId: item.orgId,
    locationId: item.locationId
  }));

  const renderIcon = () => {
    switch (callDirection) {
      case CallDirection.All:
        return <IconAll className={styles.icon} />;
      case CallDirection.Inbound:
        return <IconInbound className={styles.icon} />;
      case CallDirection.Outbound:
        return <IconOutbound className={styles.icon} />;
    }
  };

  const renderLink = (item: MappedDataType) => {
    switch (type) {
      case CardType.Locations:
        return (
          <Link to={`/${item.orgId}/location/${item.locationId}/section/lines`}>
            {item.location}
          </Link>
        );
      case CardType.Lines:
        return (
          <Link to={`/location/${item.locationId}/cdr/${item.number}`}>
            <PhoneNumber>{item.number}</PhoneNumber>
          </Link>
        );
    }
  };

  const emtpyStateText = () => {
    switch (type) {
      case CardType.Locations:
        return `No location insights are presently available for the selected timeframe.`;
      case CardType.Lines:
        return `No line insights are presently available for the selected timeframe.`;
    }
  };

  return (
    <>
      <KPIDashboardCard
        title={title}
        activeIcon={activeIcon}
        setActiveIcon={setActiveIcon}
        setFilterOpen={setFilterOpen}
      >
        {isLoading ? (
          <Progress thin />
        ) : total === 0 ? (
          <Text align="center" className={styles.textCenter}>
            {emtpyStateText()}
          </Text>
        ) : (
          <div className={styles.contentContainer}>
            {mappedData?.map((item, index) => (
              <div key={index} className={styles.columnContainer}>
                {renderIcon()}
                <Text variant="h6" className={styles.largeText}>
                  {`${item.value} ${item.text}`}
                </Text>
                {renderLink(item)}
              </div>
            ))}
          </div>
        )}
      </KPIDashboardCard>
      {filterOpen && (
        <FilterModal
          callDirection={callDirection}
          setCallDirection={setCallDirection}
          statisticType={statisticType}
          setStatisticType={setStatisticType}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </>
  );
};

export default DashboardStatisticCardTemplate;

import { query, countries } from 'constants/app';
import { isEmpty } from 'lodash';
import qs from 'qs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Contact, Location, Sort, Status } from 'types';
import { LocationDetails } from 'types/Location';
import axios, { AxiosError } from 'utils/axiosProvider';
import _ from 'lodash';
import { N2PLoc } from 'types/N2PLoc';
const isSuggestedCountry = (country: string | undefined) => {
  return country ? countries.some((e: any) => e.value === country) : false;
};

// Cannot wait to remove this
const decanonizeLocation = (account: any): Location => {
  const data = _.merge(account, {
    location: _.merge(account.location, {
      orgId: account?.organization?.id,
      organizationName: account?.organization?.name,
      /* This is temp until account / org split */
      organizationLogo: account?.organization?.logo,
      customerid: account?.externalRef,
      primaryContact: account?.location?.primaryContact,
      secondaryContact: account?.location?.secondaryContact,
      e911Address: account?.location?.e911Address
    })
  });

  return data;
};

interface CloneLocation {
  account: {
    id: string;
    name: string;
    typeId: string;
    externalRef: string;
    serviceStatus: {
      id: string;
    };
    address: {
      address1: string;
      address2: string;
      city: string;
      country: string;
      postalCode: string;
      state: string;
    };
  };
  e911Address: {
    addressName: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    postalCode: string;
    state: string;
  };
  isNewConstruction: string;
  telcoRooms: string;
  telcoRoomsLocation: string;
  floor: string;
  room: string;
  securityMeasures: string;
  accessHours: string;
  primaryContact: string;
  primaryEmail: string;
  primaryPhone: string;
  secondaryContactId: string;
  secondaryEmail: string;
  secondaryPhone: string;
  status: string;
}
export interface PagedLocations {
  locations: Location[];
  total: number;
}

interface GetLocationsParams {
  queryKey: [
    __0: string,
    filter: string,
    andFilter: Status,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

interface GetLocationParams {
  queryKey: [__0: string, locationId: string];
}

const getLocations = async ({ queryKey }: GetLocationsParams) => {
  const [, filter, andFilter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [
          `name|cont|${filter}`,
          `location.status|cont|${filter}`,
          `serviceStatus.name|cont|${filter}`,
          `address.address1|cont|${filter}`,
          `address.state|cont|${filter}`,
          `address.postalCode|cont|${filter}`,
          `equipment.mac|cont|${filter}`,
          `equipment.serialNumber|cont|${filter}`,
          `equipment.staticIp|cont|${filter}`,
          `equipment.imei|cont|${filter}`,
          `sims.iccId|cont|${filter}`,
          `router.serialNumber|cont|${filter}`,
          `router.mac|cont|${filter}`,
          `router.imei|cont|${filter}`,
          `router.ip|cont|${filter}`,
          `gateway.serialNumber|cont|${filter}`,
          `gateway.mac|cont|${filter}`,
          `gateway.ip|cont|${filter}`,
          `powersource.serialNumber|cont|${filter}`,
          `routercatalog.model|eq|${filter}`,
          `gatewaycatalog.model|eq|${filter}`,
          `powersourcecatalog.model|eq|${filter}`,
          `catalogItem.name|eq|${filter}`
        ]
      }
    : {};
  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';
  const possibleStatusFilter = andFilter
    ? { [paramFilterKey]: `serviceStatus.name|cont|${andFilter}` }
    : {};

  const { data } = await axios.get(`/accounts/locations`, {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  const locations: Location[] = data.data.map(decanonizeLocation);
  return {
    locations,
    total: Number(data.total)
  };
};

const useLocations = (
  filter: string,
  statusFilter: Status | '',
  sort: Sort,
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedLocations, AxiosError> => {
  return useQuery<PagedLocations, AxiosError>(
    [query.locations, filter, statusFilter, sort, pageIndex, pageSize],
    getLocations,
    {
      keepPreviousData: true
    }
  );
};

export const getSimpleLocations = async (name: string): Promise<Location[]> => {
  const { data } = await axios.get(`/accounts/locations`, {
    params: {
      filter: `name|cont|${name}`,
      sort: 'name|ASC',
      page: 1,
      limit: 50
    }
  });

  return data.data;
};

const getLocation = async ({
  queryKey
}: GetLocationParams): Promise<Location> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/accounts/${locationId}`);
  return decanonizeLocation(data);
};

const useLocation = (locationId: string): UseQueryResult<Location, Error> => {
  return useQuery<Location, AxiosError>(
    [query.location, locationId],
    getLocation
  );
};

interface GetLocationsByOrgParams {
  queryKey: [
    __0: string,
    orgId: string,
    filter: string,
    andFilter: boolean,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

const getLocationsByOrg = async ({
  queryKey
}: GetLocationsByOrgParams): Promise<Location[]> => {
  const [, orgId, filter, andFilter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: []
      }
    : {};
  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';
  const possibleStatusFilter = andFilter ? { [paramFilterKey]: `` } : {};

  const { data } = await axios.get(`/organizations/${orgId}/locations`, {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  return data.data.map(decanonizeLocation);
};

const useLocationsByOrg = (
  orgId: string,
  filter = '',
  andFilter = false,
  sort: Sort = ['name', 'ASC'],
  page = 1,
  limit = 10000
) => {
  return useQuery(
    [query.location, orgId, filter, andFilter, sort, page, limit],
    getLocationsByOrg,
    {
      enabled: orgId ? true : false
    }
  );
};

const postLocation = async (createData: Partial<Location>): Promise<string> => {
  const { name, division, address, erpLocationId, location } = createData;
  const e911Address = {
    addressName: isSuggestedCountry(address?.country)
      ? location?.e911Address?.addressName
      : null,
    address1: location?.e911Address?.address1,
    address2: location?.e911Address?.address2 || null,
    city: location?.e911Address?.city,
    state: location?.e911Address?.state,
    postalCode: location?.e911Address?.postalCode,
    country: location?.e911Address?.country
  };

  const newLocation = {
    isNewConstruction: location?.isNewConstruction || null,
    telcoRooms: location?.telcoRooms || null,
    telcoRoomsLocation: location?.telcoRoomsLocation || null,
    floor: location?.floor ? Number(location?.floor) : null,
    room: location?.room ? Number(location?.room) : null,
    securityMeasures: location?.securityMeasures || null,
    accessHours: location?.accessHours || null,
    isAddressAsE911Address: location?.isAddressAsE911Address,
    e911Address: isSuggestedCountry(address?.country) ? e911Address : null,
    account: {
      organizationId: location?.orgId,
      name,
      division: { id: division?.id ? division?.id : null },
      externalRef: createData?.location?.customerid || null,
      erpLocationId,
      address: {
        address1: address?.address1,
        address2: address?.address2 ? address?.address2 : null,
        city: address?.city,
        state: address?.state,
        postalCode: address?.postalCode,
        country: address?.country
      }
    }
  };

  const contacts: {
    primaryContact?: Partial<Contact>;
    secondaryContact?: Partial<Contact>;
  } = {};

  if (!_.isEmpty(location?.primaryContact) && location?.primaryContact) {
    contacts.primaryContact = location.primaryContact;
  }

  if (!_.isEmpty(location?.secondaryContact) && location?.secondaryContact) {
    contacts.secondaryContact = location.secondaryContact;
  }

  const { data } = await axios.post(`/locations`, {
    ...newLocation,
    ...contacts
  });
  return data.id;
};

const useCreateLocation = (): UseMutationResult<
  string,
  AxiosError,
  Partial<Location>
> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<string, AxiosError, Partial<Location>>(
    postLocation,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locationDetails]);
      }
    }
  );
  return mutatePostOrg;
};

export const getLocationPageDetails = async ({
  queryKey
}: GetLocationParams): Promise<Location> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/locations/${locationId}`);
  return decanonizeLocation(data);
};

const useLocationPageDetails = (
  locationId: string
): UseQueryResult<Location, Error> => {
  return useQuery<Location, AxiosError>(
    [query.locationDetails, locationId],
    getLocationPageDetails,
    {
      cacheTime: 1000
    }
  );
};

export const getLocationInfo = async ({
  queryKey
}: GetLocationParams): Promise<Location> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/locations/${locationId}/info`);
  return decanonizeLocation(data);
};

const useLocationInfo = (
  locationId: string
): UseQueryResult<Location, Error> => {
  return useQuery<Location, AxiosError>(
    [query.locationDetails, locationId, 'info'],
    getLocationInfo,
    {
      cacheTime: 1000
    }
  );
};

export const getLocationWithOrgInfo = async ({
  queryKey
}: GetLocationParams): Promise<Location> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/locations/${locationId}/parent-info`);
  return decanonizeLocation(data);
};

const useLocationWithOrgInfo = (
  locationId: string
): UseQueryResult<Location, Error> => {
  return useQuery<Location, AxiosError>(
    [query.locationDetails, locationId],
    getLocationWithOrgInfo,
    {
      cacheTime: 1000
    }
  );
};

export const getLocationCloneDetails = async ({
  queryKey
}: GetLocationParams): Promise<Location> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/locations/${locationId}/clone`);
  return decanonizeLocation(data);
};

const useLocationCloneDetails = (
  locationId: string
): UseQueryResult<Location, Error> => {
  return useQuery<Location, AxiosError>(
    [query.locationDetails, locationId],
    getLocationCloneDetails,
    {
      cacheTime: 1000
    }
  );
};

const patchAccountLocation = async (
  updateData: Partial<Location>
): Promise<void> => {
  const { name, division, address, location } = updateData;

  const e911Address = _.merge(location?.e911Address, {
    addressName: isSuggestedCountry(address?.country)
      ? location?.e911Address?.addressName
      : null,
    address2: location?.e911Address?.address2 || null
  });

  const account = {
    organizationId: location?.orgId,
    name,
    division: { id: division?.id ? division?.id : null },
    externalRef: updateData?.location?.customerid || null,
    address: { ...address, address2: address?.address2 || null }
  };

  const canonLocation = _.mergeWith(
    {
      e911Address: isSuggestedCountry(address?.country) ? e911Address : null,
      account
    },
    _.omit(location, ['id', 'orgId', 'customerid']),
    (obj, value) => (value === '' ? null : value)
  );
  await axios.patch(`locations/account/${updateData?.id}`, canonLocation);
};

const useUpdateLocation = (): UseMutationResult<
  void,
  AxiosError,
  Partial<Location>
> => {
  const queryClient = useQueryClient();

  const mutatePatchUser = useMutation<void, AxiosError, Partial<Location>>(
    patchAccountLocation,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locationDetails]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([query.locations]);
      }
    }
  );

  return mutatePatchUser;
};

interface LocationDelete {
  dids: number;
  equipment: number;
  services: number;
  sim: number;
  equipmentBundles: number;
}
export const getLocationDependency = async ({
  queryKey
}: GetLocationParams): Promise<LocationDelete> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(
    `/locations/${locationId}/dependencies/count`
  );
  return data;
};

const useLocationDependency = (
  locationId: string
): UseQueryResult<LocationDelete, Error> => {
  return useQuery<LocationDelete, AxiosError>(
    [query.locationDelete, locationId],
    getLocationDependency,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

const deleteLocation = async (id: string): Promise<void> => {
  await axios.delete(`/accounts/${id}/location`);
};

const useDeleteLocation = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteLocation = useMutation<void, AxiosError, any>(
    deleteLocation,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locations]);
      }
    }
  );

  return mutateDeleteLocation;
};

interface LocationEndService {
  id: string;
  effectiveDate: Date | null;
  uninstallType: string;
  isProfessionalUninstall: boolean;
}

/**
 * Update a location address through the CMDC api
 * @param {User}
 * @returns {Promise}
 */
const locationendService = async ({
  id,
  effectiveDate,
  uninstallType,
  isProfessionalUninstall
}: LocationEndService): Promise<void> => {
  await axios.post(`/locations/${id}/endService`, {
    effectiveDate,
    uninstallType,
    isProfessionalUninstall
  });
};

const useLocationEndService = (): UseMutationResult<
  void,
  AxiosError,
  LocationEndService
> => {
  const queryClient = useQueryClient();

  const mutateEndLocationService = useMutation<
    void,
    AxiosError,
    LocationEndService
  >(locationendService, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locations]);
    }
  });
  return mutateEndLocationService;
};
const patchLocationdetail = async (
  locationDetails: Partial<LocationDetails>
): Promise<string> => {
  const updatePayload = _.omit(locationDetails, [
    'primaryContact',
    'secondaryContact',
    'orgId',
    'customerid',
    'organizationName'
  ]);
  const { data } = await axios.patch(
    `/locations/${locationDetails.id}`,
    updatePayload
  );
  return data.id;
};

const useUpdateLocationDetails = (): UseMutationResult<
  string,
  AxiosError,
  Partial<LocationDetails>
> => {
  const queryClient = useQueryClient();

  const mutatePatchLocationdetails = useMutation<
    string,
    AxiosError,
    Partial<LocationDetails>
  >(patchLocationdetail, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locations]);
      queryClient.invalidateQueries([query.locationDetails]);
      queryClient.invalidateQueries([query.equipment]);
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });
  return mutatePatchLocationdetails;
};

const postLocationClone = async (
  createData: CloneLocation[]
): Promise<string> => {
  const { data } = await axios.post(`/locations/clone`, createData);
  return data.id;
};

const useLocationClone = (): UseMutationResult<
  string,
  AxiosError,
  CloneLocation[]
> => {
  const queryClient = useQueryClient();

  const mutatePostLocationClone = useMutation<
    string,
    AxiosError,
    CloneLocation[]
  >(postLocationClone, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locations]);
    }
  });
  return mutatePostLocationClone;
};

/* Additional APIS */

const getLocNetProvider = async ({
  queryKey
}: GetLocationParams): Promise<N2PLoc> => {
  const [, locationId] = queryKey;
  const { data } = await axios.get(`/accounts/${locationId}/provider`);

  return data;
};

const useLocNetProvider = (
  locId: string,
  enabled = true
): UseQueryResult<N2PLoc, AxiosError> => {
  return useQuery<N2PLoc, AxiosError>(
    [query.locNetProvider, locId],
    getLocNetProvider,
    {
      keepPreviousData: false,
      cacheTime: 1000,
      enabled
    }
  );
};

const syncLocNetProvider = async (locId: string): Promise<void> => {
  await axios.patch(`/accounts/${locId}/provider/sync`);
};

const useSyncLocNetProvider = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  const queryClient = useQueryClient();

  const updateLocNetProvider = useMutation<void, AxiosError, string>(
    syncLocNetProvider,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locNetProvider]);
      }
    }
  );

  return updateLocNetProvider;
};

const createProviderSub = async ({
  locId,
  type
}: {
  locId: string;
  type: string;
}): Promise<void> => {
  await axios.post(`/accounts/${locId}/provider/sub/${type}`);
};

const useCreateProviderSub = (): UseMutationResult<
  void,
  AxiosError,
  { locId: string; type: string }
> => {
  const queryClient = useQueryClient();

  const setupSubSync = useMutation<
    void,
    AxiosError,
    {
      locId: string;
      type: string;
    }
  >(createProviderSub, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locNetProvider]);
    }
  });
  return setupSubSync;
};

const syncProviderSub = async (id: string): Promise<void> => {
  await axios.patch(`/accounts/provider/${id}/sync`);
};

const useSyncProviderSub = (): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();

  const syncSub = useMutation<void, AxiosError, string>(syncProviderSub, {
    onSettled: () => {
      queryClient.invalidateQueries([query.locNetProvider]);
    }
  });

  return syncSub;
};

const getLocationStatuses = async (): Promise<string[]> => {
  const { data } = await axios.get('/locations/statuses');

  return data;
};

const useGetLocationStatuses = (): UseQueryResult<string[], AxiosError> => {
  return useQuery<string[], AxiosError>(
    [query.locationStatuses],
    getLocationStatuses,
    {
      cacheTime: 10000,
      keepPreviousData: true
    }
  );
};

export {
  useLocation,
  useGetLocationStatuses,
  useCreateLocation,
  useUpdateLocation,
  useDeleteLocation,
  useLocationDependency,
  isSuggestedCountry,
  useLocationEndService,
  useUpdateLocationDetails,
  getLocationsByOrg,
  useLocationsByOrg,
  useLocationClone,
  useLocationInfo,
  useLocationPageDetails,
  useLocationCloneDetails,
  useLocationWithOrgInfo,
  useLocNetProvider,
  useSyncLocNetProvider,
  useCreateProviderSub,
  useSyncProviderSub
};

export default useLocations;

import { useQuery } from 'react-query';
import qs from 'qs';

import axiosInstance, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';
import {
  Catalogs,
  GatewayCatalog,
  GetManyArgs,
  GetManyRes,
  HardwareCatalog,
  HardwareCatalogEditStatus,
  HardwareCreateEditData,
  PowersourceCatalog,
  RouterCatalog,
  Sort
} from 'types';
import { HardwareCatalogStatus } from '../types';

const getRouterCatalogs = async ({ queryKey }: GetManyArgs) => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;
  let possibleFilters: string[] = [];
  if (filter) {
    possibleFilters = [
      `model|cont|${filter}`,
      `brand|cont|${filter}`,
      `status.name|eq|${filter}`
    ];
  }

  const { data } = await axiosInstance.get('/hardware-catalogs/router', {
    params: {
      filter: possibleFilters,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data;
};

const useRouterCatalogs = (
  filter = '',
  sort: Sort = ['model', 'ASC'],
  pageIndex = 1,
  pageSize = 1000
) => {
  return useQuery<GetManyRes<RouterCatalog>, AxiosError>(
    [query.routerCatalog, filter, sort, pageIndex, pageSize],
    getRouterCatalogs
  );
};

const getGatewayCatalogs = async ({ queryKey }: GetManyArgs) => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;
  let possibleFilters: string[] = [];
  if (filter) {
    possibleFilters = [
      `model|cont|${filter}`,
      `brand|cont|${filter}`,
      `status.name|eq|${filter}`
    ];
  }

  const { data } = await axiosInstance.get('/hardware-catalogs/gateway', {
    params: {
      filter: possibleFilters,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data;
};

const useGatewayCatalogs = (
  filter = '',
  sort: Sort = ['model', 'ASC'],
  pageIndex = 1,
  pageSize = 1000
) => {
  return useQuery<GetManyRes<GatewayCatalog>, AxiosError>(
    [query.gatewayCatalog, filter, sort, pageIndex, pageSize],
    getGatewayCatalogs
  );
};

const getPowerSourceCatalogs = async ({ queryKey }: GetManyArgs) => {
  const [, filter, sort, pageIndex, pageSize] = queryKey;
  let possibleFilters: string[] = [];
  if (filter) {
    possibleFilters = [
      `model|cont|${filter}`,
      `brand|cont|${filter}`,
      `status.name|eq|${filter}`
    ];
  }

  const { data } = await axiosInstance.get('/hardware-catalogs/powersource', {
    params: {
      filter: possibleFilters,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data;
};

const usePowerSourceCatalogs = (
  filter = '',
  sort: Sort = ['model', 'ASC'],
  pageIndex = 1,
  pageSize = 1000
) => {
  return useQuery<GetManyRes<PowersourceCatalog>, AxiosError>(
    [query.powersourceCatalog, filter, sort, pageIndex, pageSize],
    getPowerSourceCatalogs
  );
};

const createHardwareCatalog = async (
  catalog: Catalogs,
  data: HardwareCreateEditData
): Promise<HardwareCatalog> => {
  try {
    const result = await axiosInstance.post(
      `/hardware-catalogs/${catalog}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const editHardwareCatalog = async (
  catalog: Catalogs,
  id: string,
  data: HardwareCreateEditData | HardwareCatalogEditStatus
): Promise<HardwareCatalog> => {
  try {
    const result = await axiosInstance.put(
      `/hardware-catalogs/${catalog}/${id}`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

const deleteHardwareCatalog = async (catalog: Catalogs, id: string) => {
  try {
    await axiosInstance.delete(`/hardware-catalogs/${catalog}/${id}`);
  } catch (error) {
    throw error;
  }
};

const getHardwareCatalogStatuses = async (): Promise<
  HardwareCatalogStatus[]
> => {
  try {
    const { data } = await axiosInstance.get('/hardware-catalogs/statuses');
    return data;
  } catch (error) {
    throw error;
  }
};

const useHardwareCatalogStatuses = () => {
  return useQuery<HardwareCatalogStatus[], AxiosError>(
    query.hardwareCatalogStatuses,
    getHardwareCatalogStatuses
  );
};

const isHardwareCatalogUnique = async (
  catalog: Catalogs,
  model: string,
  id?: string
): Promise<boolean> => {
  const filter: {
    filter: string;
    andfilter?: string;
  } = { filter: `model|eq|${model}` };
  if (id) {
    filter.andfilter = `id|!eq|${id}`;
  }

  const { data } = await axiosInstance.get(`/hardware-catalogs/${catalog}`, {
    params: {
      ...filter
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return data.data.length === 0;
};

export {
  createHardwareCatalog,
  deleteHardwareCatalog,
  editHardwareCatalog,
  isHardwareCatalogUnique,
  useGatewayCatalogs,
  useHardwareCatalogStatuses,
  usePowerSourceCatalogs,
  useRouterCatalogs
};

import * as React from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardContent, CardFooter } from 'design';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';
import axiosInstance from 'utils/axiosProvider';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { GATEWAY_METRIC_BRANDS } from 'constants/app';
import OpenInNew from '@material-ui/icons/OpenInNew';
import styles from './gatewayMetrics.module.scss';

interface GatewayMetricsCardProps {
  id: string;
  mac: string;
  serialNumber: string;
  catalogBrand: string;
  model: string;
  domain: string;
  rank?: number;
}

const GatewayMetricsCard = ({
  id,
  mac,
  serialNumber,
  catalogBrand,
  model,
  domain,
  rank
}: GatewayMetricsCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [isLoading, setIsLoading] = React.useState(false);

  // using name specific state since each gateway in the future will have different data.
  const [
    grandstreamResponsePortData,
    setGrandstreamResponsePortData
  ] = React.useState([]);
  const [
    grandstreamResponseDeviceData,
    setGrandstreamResponseDeviceData
  ] = React.useState({});

  const [rebootTime, setRebootTime] = React.useState('');
  const [ipv4, setIpv4] = React.useState('');
  const [dnsServer, setDnsServer] = React.useState('');
  const [hardwareRevision, setHardwareRevision] = React.useState('');
  const [softwareVersion, setSoftwareVersion] = React.useState('');

  const [metricSupport, setMetricSupport] = React.useState(false);

  // fetch grandstream gateway metrics / status
  const getMetrics = React.useCallback(async () => {
    setIsLoading(true);
    if (catalogBrand === GATEWAY_METRIC_BRANDS.GRANDSTREAM) {
      try {
        // api fetch grandstream metrics and store into appropriate variables / dialog display
        const { data } = await axiosInstance.get(`/grandstream/metrics/${id}`);

        if (!data) {
          throw new Error('Failed to find gateway metrics');
        }

        setRebootTime(data.device.rebootTime);
        setIpv4(data.device.ipv4Address);
        setDnsServer(data.device.dnsServer1);
        setHardwareRevision(data.device.hardwareRevision);
        setSoftwareVersion(data.device.softwareVersion);
        setGrandstreamResponsePortData(data.fxsPortList);
        setGrandstreamResponseDeviceData(data.device);
      } catch (err: any) {
        setIsLoading(false);
        dispatch(
          actions.showNotify({
            message: `Failed to fetch gateway metrics: ${err.response.data.message}`
          })
        );
      }
      setIsLoading(false);
    }
  }, [id, catalogBrand, dispatch, actions]);

  const handleRefetch = async () => {
    await getMetrics();
  };

  // Display additional metric data in modal
  const handleGatewayMetricsDialog = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'large',
        title: 'Gateway Details',
        lazyComponent: 'GatewayMetricsExpand',
        closeable: true,
        lazyComponentProps: {
          grandstreamResponsePortData: grandstreamResponsePortData,
          grandstreamResponseDeviceData: grandstreamResponseDeviceData
        }
      })
    );
  }, [
    actions,
    dispatch,
    grandstreamResponsePortData,
    grandstreamResponseDeviceData
  ]);

  // get metrics on initial card load
  React.useEffect(() => {
    // only fetch metrics if gateway is grandstream
    if (catalogBrand === GATEWAY_METRIC_BRANDS.GRANDSTREAM) {
      const getMetricsAsync = async () => {
        await getMetrics();
      };
      getMetricsAsync();
      setMetricSupport(true);
    }
  }, [getMetrics, catalogBrand]);

  return (
    <Card>
      {metricSupport ? (
        <CardHeader
          title={`Gateway ${rank ? `${rank}` : ''}`}
          action={
            <div className={styles.actionWrapper}>
              {!_.isEmpty(grandstreamResponseDeviceData) ? (
                <OpenInNew
                  className={styles.icon}
                  color="action"
                  onClick={handleGatewayMetricsDialog}
                />
              ) : (
                <></>
              )}
              {isLoading ? (
                <CircularProgress className={styles.icon} />
              ) : (
                <CachedIcon
                  className={styles.icon}
                  color="primary"
                  onClick={handleRefetch}
                />
              )}
            </div>
          }
        />
      ) : (
        <CardHeader title={`Gateway`} />
      )}

      <CardContent className={styles.cardContentBody}>
        <p>{model}</p>
        <p>
          {serialNumber} | {mac}
        </p>
        <p>Domain: {domain}</p>
        <p>Reboot Time: {rebootTime}</p>
        <p>ipv4: {ipv4}</p>
        <p>Primary DNS server: {dnsServer}</p>
        <Grid
          container
          justifyContent="center"
          spacing={6}
          style={{ textAlign: 'center' }}
        >
          <Grid item>
            <h3>HARDWARE</h3>
            <p>{hardwareRevision}</p>
          </Grid>
          <Grid item>
            <h3>SOFTWARE</h3>
            <p>{softwareVersion}</p>
          </Grid>
        </Grid>
      </CardContent>
      <CardFooter>
        <></>
      </CardFooter>
    </Card>
  );
};

export default GatewayMetricsCard;

import * as React from 'react';
import SyncIcon from './SyncIcon';
import clsx from 'clsx';
import { SyncStatusEnum } from 'types/SyncStatus';
import styles from './providercard.module.scss';

const SetupStatus = ({
  type,
  isLoading,
  onClick
}: {
  type: string;
  isLoading?: boolean;
  onClick: () => void;
}): JSX.Element => {
  return (
    <div>
      {!isLoading ? (
        <>
          <SyncIcon status={SyncStatusEnum.Setup} />
          <div className={styles.syncTitle}>{type}</div>
          <div
            className={clsx({
              [styles.syncStatus]: true,
              [styles.syncLink]: !!onClick
            })}
            onClick={onClick}
          >
            Create
          </div>
        </>
      ) : (
        <>
          <SyncIcon status={SyncStatusEnum.Syncing} />
          <div className={styles.syncTitle}>{type}</div>
          <div
            className={clsx({
              [styles.syncStatus]: true,
              [styles.syncLink]: !!onClick
            })}
          >
            Setting Up
          </div>
        </>
      )}
    </div>
  );
};

export default SetupStatus;

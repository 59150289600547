import * as React from 'react';
import { SubSync } from 'types/ApiSync';
import SyncModal from '../SyncModal';
import style from './netprovidercell.module.scss';

interface NetProviderCellProps {
  sub: SubSync;
}

const NetProviderCell = ({ sub }: NetProviderCellProps): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <div
        className={style.providercell}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {sub.syncStatus}
      </div>
      {showModal && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <SyncModal
            syncData={sub}
            open={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
          />
        </React.Suspense>
      )}
    </>
  );
};

export default NetProviderCell;

import React from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import useCdrs, { E911EmergencyFilter } from 'api/useCdrs';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Progress,
  Text,
  Link,
  CardNavButton
} from 'design';
import { ReactComponent as E911Icon } from 'assets/img/IconE911Calls.svg';
import styles from './E911Card.module.scss';
import PhoneNumber from '../../PhoneNumber';
import CallDuration from '../../CallDuration';
import FormatDate from '../../FormatDate';

interface E911CardProps {
  id: string;
}

const E911Card = ({ id }: E911CardProps): JSX.Element => {
  const [pageIndex, setPageIndex] = React.useState(1);

  const { data, isLoading } = useCdrs({
    idFilter: id,
    searchFilter: E911EmergencyFilter,
    sort: ['createdAt', 'DESC'],
    pageSize: 3,
    pageIndex
  });

  const total = data?.total || 0;

  return (
    <Card>
      <CardHeader title="Emergency Calls" />
      <CardContent>
        {isLoading ? (
          <Progress thin />
        ) : total === 0 ? (
          <Text align="center" className={styles.textCenter}>
            No E911 insights are presently available for the selected timeframe.
          </Text>
        ) : (
          <div className={styles.container}>
            {data?.data.map(cdr => (
              <div key={cdr.id} className={styles.rowContainer}>
                <E911Icon />
                <div className={styles.textContainer}>
                  <Link
                    to={`/location/${cdr.locationId}/cdr/${cdr.number?.slice(
                      1
                    )}`}
                  >
                    <PhoneNumber>{cdr.number}</PhoneNumber>
                  </Link>
                  <span>{cdr.locationName}</span>
                  <span>
                    <CallDuration>{cdr.duration}</CallDuration>
                    {' | '}
                    <FormatDate separator={'|'}>{cdr.timestamp}</FormatDate>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
      <CardFooter>
        <CardNavButton
          disabled={pageIndex === 1}
          onClick={() => setPageIndex(old => Math.max(old - 1, 1))}
        >
          <ArrowLeftIcon fontSize="small" color="action" />
        </CardNavButton>
        <CardNavButton
          disabled={total === 0 || pageIndex === total}
          onClick={() => setPageIndex(old => old + 1)}
        >
          <ArrowRightIcon fontSize="small" color="action" />
        </CardNavButton>
      </CardFooter>
    </Card>
  );
};

export default E911Card;

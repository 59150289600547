/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layer, Source } from 'react-map-gl';

interface Props {
  disclosureGeoSource: any;
}

export default React.memo(function DisclosureSource({
  disclosureGeoSource
}: Props) {
  return (
    <Source
      id="disclosures"
      type="geojson"
      data={disclosureGeoSource}
      cluster={true}
      clusterMaxZoom={7}
      clusterRadius={40}
    >
      <Layer
        id="clusters"
        type="circle"
        source="disclosures"
        filter={['has', 'point_count']}
        paint={{
          'circle-color': '#F71735',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            20,
            20,
            30,
            25,
            40,
            30,
            50,
            40,
            750,
            50
          ],
          'circle-opacity': ['step', ['get', 'point_count'], 0.7, 100, 1]
        }}
      />
    </Source>
  );
});

import React, { useEffect } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  calculateImpactedLocations,
  GeoJsonDataZipcodeMap,
  ZoomBusinessInfo
} from '@marketspark/ms-utils-and-interfaces';

interface Props {
  disclosureZipcodeMap: GeoJsonDataZipcodeMap;
  zoomBusinessLocations: ZoomBusinessInfo[];
}

const ImpactedCountTypography = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.h6.fontSize
  }
}))(Typography);

const Header = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.h5.fontSize
  }
}))(Typography);

export default React.memo(function ImpactedStoresCount({
  disclosureZipcodeMap,
  zoomBusinessLocations
}: Props) {
  const cc = useStyles();

  const [totalImpactedStores, setTotalImpactedStores] = React.useState(0);
  const [totalStores, setTotalStores] = React.useState(0);
  const [impactPercentage, setImpactPercentage] = React.useState(0);

  const calculate = React.useCallback(() => {
    const impactedStores = calculateImpactedLocations(
      zoomBusinessLocations,
      disclosureZipcodeMap
    );

    setTotalImpactedStores(impactedStores);
    setTotalStores(zoomBusinessLocations.length);

    if (zoomBusinessLocations.length > 0 && impactedStores > 0) {
      const percentage = (impactedStores / zoomBusinessLocations.length) * 100;
      setImpactPercentage(Math.ceil(percentage));
    } else {
      setImpactPercentage(0);
    }
  }, [zoomBusinessLocations, disclosureZipcodeMap]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  return (
    <Box className={cc.root} data-testid="impactedStoresCount">
      <Header paragraph={true}>Locations within a Retirement Zone</Header>

      {impactPercentage > 0 ? (
        <ImpactedCountTypography>
          {totalImpactedStores} of {totalStores} are impacted
        </ImpactedCountTypography>
      ) : null}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 5,
    margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px auto`
  }
}));

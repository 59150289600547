import { TextInput } from 'design';
import React from 'react';
import { EquipmentAndHardwareFieldInputProps } from '../types';

const SerialNumberFieldInput = ({
  name,
  value,
  validationMessage,
  isError,
  onChange,
  onBlur
}: EquipmentAndHardwareFieldInputProps) => {
  return (
    <TextInput
      id="serialNumber"
      name={name}
      label="Serial Number"
      placeholder="123456789"
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default SerialNumberFieldInput;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

export interface SearchProps {
  handleSearch: (value: string) => void;
  onChange: (value: string) => void;
  handleClear: () => void;
  value: string;
  className?: string;
}

const SearchInput = ({
  handleSearch,
  onChange,
  handleClear,
  value,
  className
}: SearchProps): JSX.Element => {
  const cc = useStyles();
  const [searched, setSearched] = React.useState(false);

  return (
    <Paper classes={{ root: cc.paperRoot }} elevation={0} className={className}>
      <InputBase
        className={cc.input}
        placeholder="Search"
        onChange={e => onChange(e.currentTarget.value)}
        value={value}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setSearched(true);
            handleSearch(value);
          }
        }}
      />
      {!searched ? (
        <IconButton
          classes={{ root: cc.button }}
          onClick={() => {
            setSearched(true);
            handleSearch(value);
          }}
        >
          <SearchIcon classes={{ root: cc.iconRoot }} />
        </IconButton>
      ) : (
        <IconButton
          classes={{ root: cc.button }}
          onClick={() => {
            setSearched(false);
            handleClear();
          }}
        >
          <CloseIcon classes={{ root: cc.iconRoot }} />
        </IconButton>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    padding: theme.spacing(0.25, 0.5),
    display: 'flex',
    alignItems: 'center',
    height: 37,
    borderRadius: 20,
    border: `2px solid ${theme.palette.grey[50]}`,
    background: `${theme.palette.grey[300]} 0% 0% no-repeat padding-box`,
    opacity: 1,
    [theme.breakpoints.up('sm')]: {
      width: 271
    }
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  iconRoot: {
    fontSize: '15px !important',
    color: theme.palette.grey[500],
    opacity: 1
  }
}));

export default SearchInput;

import { Grid } from '@material-ui/core';
import { Text, TextInput } from 'design';
import React from 'react';

import SerialNumberFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/SerialNumberFieldInput';
import MacAddressFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/MacAddressFieldInput';
import VirtualIpFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/VirtualIpFieldInput';
import { SANGOMA } from 'constants/app';
import { EquipmentAndHardwareFormsProps } from '../types';

interface Props extends EquipmentAndHardwareFormsProps {
  gatewayBrand?: string;
  erpLocationId: string | null;
  domain?: string;
}

export default function GatewayForm({
  subName,
  formContext,
  gatewayBrand,
  subHeaderText,
  erpLocationId,
  domain
}: Props) {
  const { register } = formContext();
  const sub = subName !== undefined ? `gateways.${subName}.` : '';
  const serialNumberInput = register(sub + 'serialNumber');
  const macInput = register(sub + 'mac');
  const domainInput = register(sub + 'domain');
  const staticIpInput = register(sub + 'ip');

  React.useEffect(() => {
    if (domain) {
      domainInput.setValue(domain);
    } else if (erpLocationId) {
      domainInput.setValue(`${erpLocationId.replace(/-/g, '')}.ms`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainInput.value]);

  return (
    <div className={'form-container'}>
      <Text className={'sub-header'}>{subHeaderText ?? 'Create Gateway'}</Text>
      <Grid container className={'grid-container'}>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <SerialNumberFieldInput
            value={serialNumberInput.value}
            isError={serialNumberInput.isError}
            validationMessage={serialNumberInput.validationMessage}
            onChange={serialNumberInput.onChange}
            onBlur={serialNumberInput.onBlur}
            name={serialNumberInput.name}
          />
        </Grid>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <MacAddressFieldInput
            value={macInput.value}
            isError={macInput.isError}
            validationMessage={macInput.validationMessage}
            onChange={macInput.onChange}
            onBlur={macInput.onBlur}
            name={macInput.name}
          />
        </Grid>
        {gatewayBrand === SANGOMA && (
          <Grid item xs={12} md={6} className={'input-wrapper'}>
            <VirtualIpFieldInput
              value={staticIpInput.value}
              isError={staticIpInput.isError}
              validationMessage={staticIpInput.validationMessage}
              onChange={staticIpInput.onChange}
              onBlur={staticIpInput.onBlur}
              name={staticIpInput.name}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <TextInput
            id="domain"
            label="domain"
            value={domainInput.value}
            isError={domainInput.isError}
            validationMessage={domainInput.validationMessage}
            onChange={domainInput.onChange}
            onBlur={domainInput.onBlur}
            name={domainInput.name}
            disabled={!!erpLocationId || !!domain}
            helperText={
              domain
                ? 'This is auto generated from the location domain'
                : erpLocationId
                ? `This is auto generated from the ERP Location ID`
                : undefined
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

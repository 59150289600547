import * as React from 'react';
// import { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import cx from 'clsx';

export interface BaseDropdownOption {
  label: string;
  value: string;
}

export interface BaseDropdownProps {
  id: string;
  name?: string;
  disabled?: boolean;
  value: any;
  label: string;
  isError: boolean;
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  onBlur?: (e: any) => void;
  validationMessage?: string;
  validationType?: 'info' | 'success';
  dropdownItems: BaseDropdownOption[];
  className?: string;
  sort?: boolean;
}

/**
 * Select wrapper component. Must be passed MenuItems from material-ui/core
 * @param {DropdownProps} props
 * @returns {JSX.Element}
 */
const BaseDropdown = (props: BaseDropdownProps): JSX.Element => {
  const {
    id,
    name,
    disabled,
    label,
    isError,
    onChange,
    validationMessage,
    validationType,
    value,
    dropdownItems,
    className,
    onBlur,
    sort
  } = props;

  const cc = useStyles();
  const [items, setItems] = React.useState<BaseDropdownOption[]>(dropdownItems);

  const validationMessageClass = cx(cc.validationMessage, {
    [cc.validationInfoMessage]: validationType === 'info',
    [cc.validationSuccessMessage]: validationType === 'success',
    [cc.validationErrorMessage]: isError
  });

  React.useEffect(() => {
    if (sort) {
      const itemList = dropdownItems.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setItems(itemList);
    }
  }, [items, dropdownItems, sort]);

  React.useEffect(() => {
    setItems(dropdownItems);
  }, [items, dropdownItems]);

  return (
    <FormControl
      classes={{ root: cc.formControlRoot }}
      disabled={disabled}
      className={className}
      fullWidth
    >
      <InputLabel
        classes={{
          root: cc.inputLabelRoot,
          focused: cc.inputLabelFocused,
          disabled: cc.selectDisabled
        }}
        disableAnimation
        shrink
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        name={name || id}
        classes={{
          root: cc.selectRoot,
          icon: cc.selectIcon,
          iconOpen: cc.selectIconOpen,
          disabled: cc.selectDisabled
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoWidth
        disableUnderline
        displayEmpty
      >
        <MenuItem value="" selected disabled>
          Select One
        </MenuItem>
        {items.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <div className={validationMessageClass}>
        {isError && validationMessage}&nbsp;
      </div>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  selectRoot: {
    backgroundColor: theme.palette.grey[300],
    border: `2px solid ${theme.palette.grey[50]}`,
    paddingLeft: theme.spacing(2),
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  selectDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },
  selectIcon: {
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    top: 0,
    height: '100%'
  },
  selectIconOpen: {
    borderRight: `2px solid ${theme.palette.grey[50]}`,
    top: 0,
    height: '100%'
  },
  formControlRoot: {
    minWidth: 230
  },
  inputLabelRoot: {
    paddingLeft: `${theme.spacing(2)}px `,
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    transform: 'none',
    fontSize: theme.typography.body2.fontSize
  },
  inputLabelFocused: {
    color: `${theme.palette.grey[500]} !important`
  },
  validationMessage: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    fontSize: '0.875rem'
  },
  validationErrorMessage: {
    color: theme.palette.error.main
  },
  validationSuccessMessage: {
    color: theme.palette.error.main
  },
  validationInfoMessage: {
    color: theme.palette.error.main
  }
}));

export default BaseDropdown;

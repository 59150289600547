import React from 'react';
import { Link } from 'react-router-dom';

import styles from './HardwareTables.module.scss';

interface HardwareLinkProps {
  model: string;
  locationCount: number;
}

const HardwareLink = ({ model, locationCount }: HardwareLinkProps) => {
  return (
    <Link to={`/locations?filter=${model}`} className={styles.link}>
      {locationCount}
    </Link>
  );
};

export default HardwareLink;

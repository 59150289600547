import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { AUTH_LOGIN_URI } from 'constants/app';
import { RouteChildrenProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logoutState, useAuthSlice } from 'app/slice';
import { userActions } from 'store/userSlice';
import { AuthErrors } from 'types/AuthErrors';
import * as Sentry from '@sentry/react';
import { clearCookies } from 'api/useCurrentUser';

const AuthError = ({ history, location }: RouteChildrenProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();

  const [message, setMessage] = React.useState('error');
  const redirectTimer = 5000;

  const resetLocal = async () => {
    try {
      await clearCookies();
    } catch (e) {
      console.error(e);
    }
    dispatch(actions.setAuth(logoutState));
    dispatch(userActions.logoutUser());

    const cookieNames = Object.keys(Cookies.get());
    cookieNames.forEach(cookieName => {
      Cookies.remove(cookieName);
    });
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorType = searchParams.get('type') as AuthErrors;
    const hash = searchParams.get('hash');
    // Setup our error message
    switch (errorType) {
      case AuthErrors.noAuth: {
        // This should never be the case without intent.
        setMessage('Authentication error, redirecting to login');
        break;
      }
      // In case the hash is incorrect
      case AuthErrors.badHash: {
        const error = searchParams.get('error');
        Sentry.captureException(errorType, {
          data: {
            hash,
            error
          }
        });
        setMessage('Error processing auth, redirecting to login');
        break;
      }
      // Covers issue in access token, as well as dispatch error
      case AuthErrors.authError: {
        const error = searchParams.get('error');
        Sentry.captureException(errorType, {
          data: {
            error,
            hash
          }
        });
        setMessage('Error processing auth, redirecting to login');
        break;
      }

      case AuthErrors.userError: {
        const error = searchParams.get('error');
        Sentry.captureException(errorType, {
          data: {
            error,
            hash
          }
        });
        setMessage('App authentication failed, redirecting to login');
        break;
      }
    }

    setTimeout(() => {
      // Reset the local storage
      resetLocal().then(() => {
        history.push(AUTH_LOGIN_URI);
      });
      // Redirect back to login with clean local storage
    }, redirectTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>MarketSpark</title>
        <meta name="description" content="Error Occured" />
      </Helmet>

      <div className="login-logout">{message}</div>
    </>
  );
};

export default AuthError;

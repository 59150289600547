import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface PageNavButtonProps {
  currentPage?: number;
  newIndex: number;
  onClick: () => void;
  disabled?: boolean;
  label?: number | React.ReactNode;
  className?: string;
}

const PageNavButton = ({
  newIndex,
  currentPage,
  onClick,
  disabled,
  label,
  className = ''
}: PageNavButtonProps): JSX.Element => {
  const pageButtonClasses = usePageButtonStyles();

  const dynCc = clsx(pageButtonClasses.paginationButton, {
    [pageButtonClasses.paginationButtonActive]: newIndex === currentPage,
    [pageButtonClasses.pageButtonPsuedos]: true,
    [className]: className
  });

  return (
    <button
      type="button"
      disabled={disabled}
      className={dynCc}
      onClick={onClick}
    >
      {label || newIndex.toString()}
    </button>
  );
};

const usePageButtonStyles = makeStyles((theme: Theme) => ({
  paginationButton: {
    border: 0,
    width: 36,
    height: 34,
    padding: 0,
    textAlign: 'center',
    background: 'none',
    cursor: 'pointer',
    transitionProperty: 'hover',
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    transitionTimingFunction: theme.transitions.easing.easeOut,
    color: theme.palette.grey['500'],
    '&:hover > svg': {
      color: `${theme.palette.common.white} !important`
    }
  },
  paginationButtonActive: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  pageButtonPsuedos: {
    '&:hover,&:active': {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`
    },
    '& svg:hover': {
      color: `${theme.palette.common.white} !important`
    }
  }
}));

export default PageNavButton;

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useGetAllOrg } from 'api/useOrgs';
import { debounce } from 'lodash';
import React from 'react';
import { Organization } from 'types';

interface Props {
  setOrganization: (name: Organization) => void;
}

export default function OrganizationDropdown({ setOrganization }: Props) {
  const { data: orgs } = useGetAllOrg('', 1, 100000, true);
  const orgList = orgs || [];
  const [inputValue, setInputValue] = React.useState('');

  const options = orgList.map(org => ({
    name: org.name,
    id: org.id,
    enableNotificationOptIn: org.enableNotificationOptIn
  }));
  const initialDropdownOption = orgList?.find(org => org.name === inputValue);
  const [value, setValue] = React.useState<Organization | null>(
    initialDropdownOption || null
  );

  const handleDropdownSelect = (newValue: Organization | null) => {
    if (newValue !== null) {
      setValue(newValue);
      setOrganization(newValue);
    }
  };

  const handleInputChange = debounce((newInputValue: string) => {
    setInputValue(newInputValue);
  }, 1000);

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue: Organization | null) => {
        handleDropdownSelect(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      getOptionSelected={(option: Organization, selectedValue: Organization) =>
        option.name === selectedValue.name
      }
      getOptionLabel={option => option.name}
      id="organizationsDropdown"
      data-testid="organizationsDropdown"
      options={options || []}
      style={{ width: 400 }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          value={inputValue}
          label="Search organization"
          variant="outlined"
        />
      )}
    />
  );
}

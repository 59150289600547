import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Badge, OrgAvatar, EditLocation } from 'design';
import { dashboardUri, StatusToColor } from 'constants/app';
import clsx from 'clsx';
import { Status } from 'types';
import { Link } from 'react-router-dom';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { LocationDetails } from 'types/Location';

export interface ViewHeaderProps {
  title?: React.ReactNode;
  subTitle?: string;
  status?: Status;
  location?: LocationDetails;
  handleUpdateAddress?: (event: React.MouseEvent<HTMLElement>) => void;
  displayEditE911?: boolean;
}

const ViewHeader = ({
  title,
  subTitle,
  status,
  location,
  handleUpdateAddress,
  displayEditE911
}: ViewHeaderProps): JSX.Element => {
  const cc = useStyles();
  const { data: user, isLoading } = useCurrentUser();

  const header = clsx(cc.header, {
    [cc.headerNoSub]: !subTitle
  });

  const headerText = clsx(cc.headerText, {
    [cc.headerWithSubTitle]: subTitle
  });

  const avatarWrapper = clsx(cc.avatarWrapper, {
    [cc.avatarWrapperWithoutSubTitle]: !subTitle
  });

  if (isLoading) return <>Loading...</>;

  return (
    <div className={header}>
      {location?.organizationLogo?.url ? (
        <Link to={dashboardUri} className={avatarWrapper}>
          <OrgAvatar
            src={location?.organizationLogo?.url}
            name={location?.organizationName}
            className={cc.avatar}
          />
        </Link>
      ) : user?.organization?.logo ? (
        <Link to={dashboardUri} className={avatarWrapper}>
          <OrgAvatar
            src={user?.organization?.logo.url}
            name={user?.organization?.name}
            className={cc.avatar}
          />
        </Link>
      ) : null}
      <div className={headerText}>
        <div className={cc.headerTextInner}>
          <Typography variant="h4" component="h1">
            {(title || user?.organization?.name) ?? 'Dashboard'}
          </Typography>
          &ensp;
          {!status || (
            <Badge
              color={StatusToColor[status]}
              active
              className={cc.badgeSpacing}
            >
              {status}
            </Badge>
          )}
        </div>
        {!subTitle || (
          <Typography variant="h2" className={cc.subTitle}>
            {subTitle}{' '}
            {displayEditE911 ? (
              <EditLocation handleClick={handleUpdateAddress} />
            ) : (
              <></>
            )}
          </Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex'
  },
  headerNoSub: {
    padding: theme.spacing(3, 0, 6),
    alignItems: 'center'
  },
  headerText: {
    flexGrow: 1
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: 'auto !important',
    height: 'auto !important',
    maxWidth: '70px',
    maxHeight: '70px'
  },
  avatarWrapper: {
    marginTop: -2
  },
  avatarWrapperWithoutSubTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0
  },
  headerWithSubTitle: {
    '& > h1': {
      marginBottom: 0
    }
  },
  subTitle: {
    fontWeight: theme.typography.body1.fontWeight,
    color: theme.palette.grey['500'],
    fontSize: 18,
    marginTop: 0,
    marginBottom: theme.spacing(3)
  },
  headerTextInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    columnGap: '5px'
  },
  badgeSpacing: {
    marginLeft: theme.spacing(2),
    '& .makeStyles-badgeRoot': {
      marginLeft: '10px'
    }
  }
}));

export default ViewHeader;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import PageHeader from 'app/components/PageHeader';
import ToolDomainStatus from 'app/components/ToolsPage/ToolDomainStatus';
import ToolSipCredentials from 'app/components/ToolsPage/ToolSipCredentials';
import ToolLineStatus from 'app/components/ToolsPage/ToolLineStatus';
import ToolLastCallExport from '../../components/ToolsPage/ToolLastCallExport';
import { Card, CardHeader, CardContent, CardFooter, CardGrid } from 'design';

interface ToolItem {
  name: string;
  element?: JSX.Element;
  description?: string | JSX.Element;
  action?: () => void;
  actionTerm?: string;
  overflowScroll?: boolean;
}

const ToolsPage = (): JSX.Element => {
  const tools: ToolItem[] = [
    {
      name: 'N2P SIP Credentials',
      element: <ToolSipCredentials key="N2P SIP Credentials" />
    },
    {
      name: 'N2P Line Status',
      element: <ToolLineStatus key="N2P Line Status" />
    },
    {
      name: 'N2P Domain Status',
      element: <ToolDomainStatus key="N2P Domain Status" />
    },
    {
      name: 'Last Call',
      element: <ToolLastCallExport key="Last Call" />
    },
    {
      name: 'Modem AT Command Decoder',
      description:
        'Modem AT commands commonly used/provided by leading GSM/HF/CDMA/LTE modem manufacturers',
      action: () =>
        dispatch(
          actions.showDialog({
            open: true,
            size: 'xlarge',
            title: 'AT command',
            lazyComponent: 'ATCommandTool'
          })
        ),
      actionTerm: 'Decode'
    },
    {
      name: 'Quick Links',
      description: (
        <div>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dtmf-decoder-beta.marketspark.com/"
            >
              DTMF Decoder
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.datadoghq.com"
            >
              Datadog
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://accounts.cradlepointecm.com/"
            >
              NetCloud / Cradlepoint
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://account.gdms.cloud/"
            >
              GDMS / Grandstream
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://patton.io/"
            >
              Patton IO
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ncthv.net2phone.com"
            >
              Net2Phone
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ics.inhandnetworks.com"
            >
              InHand InConnect
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ui.mixnetworks.com/dashboard"
            >
              Mix Networks
            </a>
          </p>
          <p>
            Pepwave Incontrol 2:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://incontrol2.peplink.com"
            >
              Link 1
            </a>
            /
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://earth.ic.peplink.com/o/K1uMfJ/overview"
            >
              Link 2
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://dashboard.dashcs.com/dash-board/login.jsp"
            >
              Bandwidth.com
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://trace.nsnct.com/voipmonitor/"
            >
              Voipmon
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cpe-2.dataremote.com/admin/Login.aspx"
            >
              DataRemote
            </a>
          </p>
        </div>
      ),
      actionTerm: '',
      overflowScroll: true
    }
  ];
  const cc = useStyles();
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();

  return (
    <>
      <div className={cc.headWrapper}>
        <PageHeader title="Tools" />
      </div>
      <CardGrid>
        {tools.map(t => {
          if (t.element) {
            return t.element;
          } else {
            return (
              <Card key={t.name}>
                <CardHeader title={t.name} />
                <CardContent overflowScroll={t.overflowScroll || false}>
                  {t.description}
                </CardContent>
                <CardFooter>
                  <div onClick={t.action} className={cc.FindWrapper}>
                    {t.actionTerm ?? 'View'}
                  </div>
                </CardFooter>
              </Card>
            );
          }
        })}
      </CardGrid>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  headWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    }
  },
  searchInput: {
    margin: theme.spacing(0, 2, 1, 0)
  },
  optionsWrapper: {
    display: 'flex'
  },
  text: {
    width: '100%',
    flexGrow: 1
  },
  textField: {
    width: '100%',
    backgroundColor: theme.palette.info.main,
    marginBottom: theme.spacing(5),
    padding: 5,
    border: 'none',
    '&.Mui-focused': {
      outline: `${theme.palette.primary.main} 1px solid`
    }
  },
  FindWrapper: {
    color: '#AD4EFF',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default ToolsPage;

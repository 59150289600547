import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Line } from 'types';
import axios, { AxiosError } from 'utils/axiosProvider';
import { ManageLine } from 'app/components/ManageLines/types/ManageLinesType';
import { DropdownOption } from 'design';

interface GetAttchedLines {
  queryKey: [__0: string, equipmentId: string];
}

export interface AttachLine {
  id?: string;
  port: number;
  line: string;
  description: string | null;
  lineType: {
    value: string;
    label: string;
  };
  accountId: string;
  equipmentId: string;
  provider: {
    value: string | null;
    label: string | null;
  };
}

export interface LineType {
  id: string;
  name: string;
}

interface GetLineTypes {
  queryKey: [__0: string, filter: string];
}

const getLineTypes = async ({
  queryKey
}: GetLineTypes): Promise<LineType[]> => {
  const [, filter] = queryKey;
  const { data } = await axios.get(`/line-types`, {
    params: {
      filter
    }
  });
  return data.data;
};

const useLineTypes = (filter?: string): UseQueryResult<LineType[], Error> => {
  return useQuery<LineType[], AxiosError>(
    [query.lineTypes, filter],
    getLineTypes
  );
};

const getProviders = async (): Promise<DropdownOption[]> => {
  const { data } = await axios.get(`/dids/providers`);
  const provider: DropdownOption[] = [];
  data?.forEach((item: any) => {
    provider.push({
      label: item?.name,
      value: item?.id
    });
  });
  return provider;
};

const useProviders = (): UseQueryResult<DropdownOption[], Error> => {
  return useQuery<DropdownOption[], AxiosError>(
    [query.providers],
    getProviders
  );
};

const getEquipmentLines = async ({ queryKey }: GetAttchedLines) => {
  const [, equipmentId] = queryKey;

  const { data } = await axios.get(`/equipment/${equipmentId}/dids`);
  return data.data;
};

const useEquipmentLines = (
  equipmentId: string,
  value: string
): UseQueryResult<Line[], AxiosError> => {
  return useQuery<Line[], AxiosError>(
    [query.locations, equipmentId],
    getEquipmentLines,
    {
      // api to call only if the value is equipment.
      keepPreviousData: true,
      enabled: value === 'equipment'
    }
  );
};
const getGatewayLines = async ({ queryKey }: GetAttchedLines) => {
  const [, gatewayId] = queryKey;

  const { data } = await axios.get(`/dids/gateway/${gatewayId}`);
  return data.data;
};

const useGatewayLines = (
  gatewayId: string,
  value: string
): UseQueryResult<Line[], AxiosError> => {
  return useQuery<Line[], AxiosError>(
    [query.gatewayLines, gatewayId],
    getGatewayLines,
    {
      // api to call only if the value is gateway.
      keepPreviousData: true,
      enabled: value === 'gateway'
    }
  );
};

const lineExists = async (
  line: string,
  equipmentId?: string
): Promise<{ line: string; exists: boolean }> => {
  const { data } = await axios.get(`/dids/${line}/validate`, {
    params: {
      equipmentId
    }
  });

  return {
    line,
    exists: data
  };
};

const postLines = async (createData: ManageLine[]): Promise<string> => {
  const payload: any = [];
  for (const lineData of createData) {
    payload.push({
      port: lineData.port,
      value: lineData.line.replace(/[ ()-]/g, '').trim(),
      lineTypeId: lineData.lineType.value,
      description: lineData.description || null,
      callerId: lineData.callerId || null,
      accountId: lineData.accountId,
      equipmentId: lineData.equipmentId,
      tempPortingNumber: lineData.tempPortingNumber
        ?.replace(/[ ()-]/g, '')
        .trim(),
      gatewayId: lineData.gatewayId,
      providerId: lineData.provider?.value || null,
      extension: lineData.extension || null
    });
  }
  const { data } = await axios.post(`/dids/attach`, { data: payload });
  return data.id;
};

const useAttachLines = (): UseMutationResult<
  string,
  AxiosError,
  ManageLine[]
> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<string, AxiosError, ManageLine[]>(
    postLines,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.locations]);
        queryClient.invalidateQueries([query.gatewayLines]);
        queryClient.invalidateQueries([query.locationLines]);
        queryClient.invalidateQueries([query.lines]);
      }
    }
  );
  return mutatePostOrg;
};

export {
  useLineTypes,
  useEquipmentLines,
  lineExists,
  useProviders,
  useGatewayLines
};
export default useAttachLines;

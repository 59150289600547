/**
 * Transforms a hex color string into rgb values.
 * @param {string} hexString
 * @returns {number[]}
 */
const hexToDec = (hexString: string): number[] => {
  let color: string[] = hexString.split('');
  // remove the # if its there
  if (color[0] === '#') {
    color = [...color.slice(1, color.length)];
  }

  if (color.length > 6) throw new Error('This is not a web color hex string!');

  // Explode shorthand hex colors
  if (color.length < 4) {
    color = [color[0], color[0], color[1], color[1], color[2], color[2]];
  }

  // convert to [r, g, b] values;
  let hexBits: number[] = [];
  let i = 0;
  while (i < color.length) {
    if (i % 2 < 1) {
      hexBits = [...hexBits, parseInt(`${color[i]}${color[i + 1]}`, 16)];
    }
    i++;
  }

  return hexBits;
};

export default hexToDec;

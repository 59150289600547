/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { SidebarProvider } from 'design';
import { useSelector } from 'react-redux';
import { authUri } from 'constants/app';
import PrivateRoute from 'app/components/PrivateRoute';
import { DashboardRouter, AuthRouter } from 'app/routers';
import { selectSidebar } from 'app/layouts/slice/selectors';

function App(): JSX.Element {
  const sidebarOpen = useSelector(selectSidebar);
  return (
    <Router>
      <Helmet
        titleTemplate="%s | Command Center"
        defaultTitle="Command Center | MarketSpark"
      >
        <meta
          name="description"
          content="The Command Center - A data driven portal which provides customer insight to HaaS telecommunications solutions!"
        />
      </Helmet>
      <Switch>
        <Route path={authUri}>
          <AuthRouter />
        </Route>

        <PrivateRoute>
          <SidebarProvider defaultOpen={sidebarOpen}>
            <DashboardRouter />
          </SidebarProvider>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;

import { ThemeOptions } from '@material-ui/core/styles';
import hexToDec from 'utils/hexToDec';

/**
 * This themes color pallete is based ont the Cassian Design System here:
 * https://xd.adobe.com/view/6475d245-11c7-4e62-9ea8-fb8c230470dd-3126/screen/9cd8b4c8-3352-4cbc-89f7-8be42eaf902b/?hints=off
 * Some variations are omitted purposefully.
 */
const themeConfig: ThemeOptions = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 44,
      '@media (min-width:600px)': {
        minHeight: 62
      },
      justifyContent: 'flex-start'
    },
    // special mixin for the application sidebar
    sidebar: {
      width: 200
    }
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        color: '#fff',
        fontSize: 30
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 50
      }
    }
  },
  palette: {
    common: {
      black: '#222',
      white: '#fff'
    },
    type: 'light',
    primary: {
      main: '#ad4eff',
      light: '',
      dark: '',
      contrastText: '#fff'
    },
    secondary: {
      main: '#7802dd',
      light: '',
      dark: '',
      contrastText: '#fff'
    },
    error: {
      light: '',
      main: '#f71735',
      dark: '',
      contrastText: '#fff'
    },
    warning: {
      light: '',
      main: '#f79f09',
      dark: '',
      contrastText: '#fff'
    },
    info: {
      light: '',
      main: '#F8F7FE',
      dark: '',
      contrastText: '#fff'
    },
    success: {
      light: '',
      main: '#0cce6b',
      dark: '',
      contrastText: '#fff'
    },
    grey: {
      '50': '#d6e5eB',
      '100': '#c2c2c2',
      '200': '#8f8f8f',
      '300': '#f8f7fe',
      '500': '#A9B3B7'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: `#222`,
      secondary: `rgba(${hexToDec('#222')}, 0.54)`,
      disabled: `rgba(${hexToDec('#222')}.38)`,
      hint: `rgba(${hexToDec('#222')}, 0.38)`
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff'
    },
    action: {
      active: `#A9B3B7`,
      hover: `rgba(${hexToDec('#A9B3B7')}, 0.04)`,
      hoverOpacity: 0.04,
      selected: `rgba(${hexToDec('#A9B3B7')}, 0.08)`,
      selectedOpacity: 0.08,
      disabled: '#fff',
      disabledBackground: '#d6e5eB',
      disabledOpacity: 1,
      focus: `rgba(${hexToDec('#A9B3B7')}, 0.12)`,
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  props: {},
  shadows: [
    'none',
    `0px 2px 1px -1px rgba(${hexToDec(
      '#222'
    )},0.2),0px 1px 1px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 3px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 3px 1px -2px rgba(${hexToDec(
      '#222'
    )},0.2),0px 2px 2px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 5px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 3px 3px -2px rgba(${hexToDec(
      '#222'
    )},0.2),0px 3px 4px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 8px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 2px 4px -1px rgba(${hexToDec(
      '#222'
    )},0.2),0px 4px 5px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 10px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 3px 5px -1px rgba(${hexToDec(
      '#222'
    )},0.2),0px 5px 8px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 14px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 3px 5px -1px rgba(${hexToDec(
      '#222'
    )},0.2),0px 6px 10px 0px rgba(${hexToDec(
      '#222'
    )},0.14),0px 1px 18px 0px rgba(${hexToDec('#222')},0.12)`,
    `0px 4px 5px -2px rgba(${hexToDec(
      '#222'
    )},0.2),0px 7px 10px 1px rgba(${hexToDec(
      '#222'
    )},0.14),0px 2px 16px 1px rgba(${hexToDec('#222')},0.12)`,
    `0px 5px 5px -3px rgba(${hexToDec(
      '#222'
    )},0.2),0px 8px 10px 1px rgba(${hexToDec(
      '#222'
    )},0.14),0px 3px 14px 2px rgba(${hexToDec('#222')},0.12)`,
    `0px 5px 6px -3px rgba(${hexToDec(
      '#222'
    )},0.2),0px 9px 12px 1px rgba(${hexToDec(
      '#222'
    )},0.14),0px 3px 16px 2px rgba(${hexToDec('#222')},0.12)`,
    `0px 6px 6px -3px rgba(${hexToDec(
      '#222'
    )},0.2),0px 10px 14px 1px rgba(${hexToDec(
      '#222'
    )},0.14),0px 4px 18px 3px rgba(${hexToDec('#222')},0.12)`,
    `0px 6px 7px -4px rgba(${hexToDec(
      '#222'
    )},0.2),0px 11px 15px 1px rgba(${hexToDec(
      '#222'
    )},0.14),0px 4px 20px 3px rgba(${hexToDec('#222')},0.12)`,
    `0px 7px 8px -4px rgba(${hexToDec(
      '#222'
    )},0.2),0px 12px 17px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 5px 22px 4px rgba(${hexToDec('#222')},0.12)`,
    `0px 7px 8px -4px rgba(${hexToDec(
      '#222'
    )},0.2),0px 13px 19px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 5px 24px 4px rgba(${hexToDec('#222')},0.12)`,
    `0px 7px 9px -4px rgba(${hexToDec(
      '#222'
    )},0.2),0px 14px 21px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 5px 26px 4px rgba(${hexToDec('#222')},0.12)`,
    `0px 8px 9px -5px rgba(${hexToDec(
      '#222'
    )},0.2),0px 15px 22px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 6px 28px 5px rgba(${hexToDec('#222')},0.12)`,
    `0px 8px 10px -5px rgba(${hexToDec(
      '#222'
    )},0.2),0px 16px 24px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 6px 30px 5px rgba(${hexToDec('#222')},0.12)`,
    `0px 8px 11px -5px rgba(${hexToDec(
      '#222'
    )},0.2),0px 17px 26px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 6px 32px 5px rgba(${hexToDec('#222')},0.12)`,
    `0px 9px 11px -5px rgba(${hexToDec(
      '#222'
    )},0.2),0px 18px 28px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 7px 34px 6px rgba(${hexToDec('#222')},0.12)`,
    `0px 9px 12px -6px rgba(${hexToDec(
      '#222'
    )},0.2),0px 19px 29px 2px rgba(${hexToDec(
      '#222'
    )},0.14),0px 7px 36px 6px rgba(${hexToDec('#222')},0.12)`,
    `0px 10px 13px -6px rgba(${hexToDec(
      '#222'
    )},0.2),0px 20px 31px 3px rgba(${hexToDec(
      '#222'
    )},0.14),0px 8px 38px 7px rgba(${hexToDec('#222')},0.12)`,
    `0px 10px 13px -6px rgba(${hexToDec(
      '#222'
    )},0.2),0px 21px 33px 3px rgba(${hexToDec(
      '#222'
    )},0.14),0px 8px 40px 7px rgba(${hexToDec('#222')},0.12)`,
    `0px 10px 14px -6px rgba(${hexToDec(
      '#222'
    )},0.2),0px 22px 35px 3px rgba(${hexToDec(
      '#222'
    )},0.14),0px 8px 42px 7px rgba(${hexToDec('#222')},0.12)`,
    `0px 11px 14px -7px rgba(${hexToDec(
      '#222'
    )},0.2),0px 23px 36px 3px rgba(${hexToDec(
      '#222'
    )},0.14),0px 9px 44px 8px rgba(${hexToDec('#222')},0.12)`,
    `0px 11px 15px -7px rgba(${hexToDec(
      '#222'
    )},0.2),0px 24px 38px 3px rgba(${hexToDec(
      '#222'
    )},0.14),0px 9px 46px 8px rgba(${hexToDec('#222')},0.12)`
  ],
  typography: {
    htmlFontSize: 14, // Fonts & HTML body MUST be set in the static HTML
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '2.857rem',
      lineHeight: '3.5rem',
      letterSpacing: '-0.01562em'
    },
    h2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '2.286rem',
      lineHeight: '2.786rem',
      letterSpacing: '-0.00833em'
    },
    h3: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '2rem',
      lineHeight: '2.429rem',
      letterSpacing: '0em'
    },
    h4: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '1.714rem',
      lineHeight: '2.071rem',
      letterSpacing: '0.00735em'
    },
    h5: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '1.429rem',
      lineHeight: '1.714rem',
      letterSpacing: '0em'
    },
    h6: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '1.143rem',
      lineHeight: '1.429rem',
      letterSpacing: '0.0075em'
    },
    subtitle1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    },
    subtitle2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em'
    },
    body1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em'
    },
    body2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },
    button: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.21rem',
      letterSpacing: '0.02857em',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },
    overline: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase'
    }
  },
  shape: {
    borderRadius: 3
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
};

export default themeConfig;

import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  setFile: (userFile: File) => void;
  removeFile: () => void;
  file?: File;
}

export default function CsvUpload({ setFile, file, removeFile }: Props) {
  const cc = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop: files => {
      setFile(files[0]);
    },
    validator: uploadedFile => {
      if (uploadedFile.type !== 'text/csv') {
        return { code: 'file-invalid-type', message: 'File must be CSV' };
      }
      return null;
    }
  });

  const hasError = fileRejections.length > 0 && fileRejections[0]?.errors;
  const fileError = fileRejections[0]?.errors[0]?.message;
  return (
    <div className="container">
      {!file ? (
        <div
          {...getRootProps({ className: cc.dropzone })}
          data-testid="csvUpload"
        >
          <input {...getInputProps()} aria-label="upload CSV file" />
          {hasError && <p>{fileError}</p>}
          {!isDragActive && !hasError && <p>Drop csv File here</p>}
        </div>
      ) : (
        <div className={cc.fileWrapper}>
          <div className={cc.fileInfo}>{file?.name}</div>
          <div className={cc.remove}>
            <Button
              onClick={removeFile}
              className={cc.removeButton}
              variant="text"
            >
              X
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#c9c9c9',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#6a6a6a',
    outline: 'none',
    cursor: 'pointer',
    transition: 'border .24s ease-in-out'
  },
  fileWrapper: {
    display: 'flex'
  },
  fileInfo: {
    display: 'flex'
  },
  remove: {
    fontSize: 8
  },
  removeButton: {
    padding: 1,
    border: 'none',
    background: 'none',
    minWidth: 20,
    cursor: 'pointer'
  }
}));

import React from 'react';

import {
  CancelSubmitFooter,
  Dialog,
  DialogCloseIcon,
  Dropdown,
  Text
} from '../../../design';
import { CallDirection } from 'types';
import styles from './CDRCard.module.scss';

interface CDRFilterModalProps {
  callDirection: CallDirection;
  setCallDirection: React.Dispatch<React.SetStateAction<CallDirection>>;
  handleClose: () => void;
}

const CDRFilterModal = ({
  callDirection,
  setCallDirection,
  handleClose
}: CDRFilterModalProps): JSX.Element => {
  const [tempDirection, setTempDirection] = React.useState<CallDirection>(
    callDirection
  );

  const dropdownItems = Object.values(CallDirection).map(direction => ({
    value: direction,
    label: direction.charAt(0).toUpperCase() + direction.slice(1)
  }));

  const handleSubmit = () => {
    setCallDirection(tempDirection);
    handleClose();
  };

  return (
    <Dialog open={true} handleClose={() => handleClose()} size="large">
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">Filter</Text>
        <DialogCloseIcon onReset={() => handleClose()} />
      </div>
      <form onSubmit={() => handleSubmit()}>
        <Dropdown
          id="callDirection"
          label="Call Direction"
          value={tempDirection}
          onChange={e => setTempDirection(e.value as CallDirection)}
          dropdownItems={dropdownItems}
          isError={false}
          className={styles.dropdown}
        />
        <CancelSubmitFooter
          isLoading={false}
          cancelButtonOnClick={() => handleClose()}
        />
      </form>
    </Dialog>
  );
};

export default CDRFilterModal;

import * as Yup from 'yup';

import {
  hardwareCatalogUniqueModel,
  catchCase
} from '../../../../utils/commonValidations';
import { NUMBER_REGEX } from '../../../../constants/app';

const RouterCatalogSchema = (id?: string) =>
  Yup.object().shape({
    brand: Yup.string().required('Brand is required'),
    model: Yup.string().test(async (value, context) => {
      try {
        return await hardwareCatalogUniqueModel(value, 'router', context, id);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    power: Yup.string().required('Power is required'),
    wanPorts: Yup.string()
      .required('WAN Ports is required')
      .matches(NUMBER_REGEX, 'WAN Ports must be a number'),
    lanPorts: Yup.string()
      .required('LAN Ports is required')
      .matches(NUMBER_REGEX, 'LAN Ports must be a number'),
    simCount: Yup.string()
      .required('SIM Count is required')
      .matches(NUMBER_REGEX, 'SIM Count must be a number')
  });

export default RouterCatalogSchema;

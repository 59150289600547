import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';
import { Line, Sort, Status, E911Address } from 'types';
import qs from 'qs';
import { isEmpty, omit } from 'lodash';

interface GetLinesParams {
  queryKey: [
    __0: string,
    filter: string,
    andFilter: Status,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

const getLines = async ({ queryKey }: GetLinesParams) => {
  const [, filter, andFilter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [
          `value|cont|${filter}`,
          `lineType.name|cont|${filter}`,
          `description|cont|${filter}`,
          `equipment.mac|cont|${filter}`,
          `port|cont|${filter}`,
          `forwardValue|cont|${filter}`,
          `serviceStatus.name|cont|${filter}`,
          `provider|cont|${filter}`,
          `tempPortingNumber|cont|${filter}`
        ]
      }
    : {};
  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';
  const possibleStatusFilter = andFilter
    ? { [paramFilterKey]: `serviceStatus.name|cont|${andFilter}` }
    : {};
  const { data } = await axios.get(`/dids`, {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  const lines: Line[] = data.data;

  return {
    lines,
    total: Number(data.total)
  };
};

export interface PagedLines {
  lines: Line[];
  total: number;
}

const useLines = (
  filter: string,
  statusFilter: Status | '',
  sort: Sort,
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedLines, AxiosError> => {
  return useQuery<PagedLines, AxiosError>(
    [query.lines, filter, statusFilter, sort, pageIndex, pageSize],
    getLines,
    {
      keepPreviousData: true
    }
  );
};

interface GetLineParams {
  queryKey: [__0: string, id: string];
}

const getLine = async ({ queryKey }: GetLineParams): Promise<Line> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/dids/${id}`);
  return data;
};

export const useLine = (id: string): UseQueryResult<Line, Error> => {
  return useQuery<Line, AxiosError>([query.line, id], getLine);
};

const patchLinesE911 = async (updateData: E911Address): Promise<void> => {
  const { id } = updateData;
  const updateTemplate = omit(updateData, ['id']);
  await axios.patch(`/dids/${id}/emergency-address`, updateTemplate);
};

export const useUpdateLinesE911 = (): UseMutationResult<
  void,
  AxiosError,
  E911Address
> => {
  const queryClient = useQueryClient();

  const mutatePatchLines = useMutation<void, AxiosError, E911Address>(
    patchLinesE911,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.lines]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([query.lines]);
      }
    }
  );

  return mutatePatchLines;
};

export default useLines;

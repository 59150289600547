import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { editHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';
import {
  gatewayDropDownInputs,
  gatewayRequiredInputs,
  gatewayTextInputs
} from './EquipmentConstants';
import GatewayCatalogSchema from './GatewayCatalogSchema';
import {
  GatewayCatalog,
  GatewayCreateEditData,
  GatewayFormikValues,
  HardwareCatalog
} from 'types';
import validateOnlyEditableFields from '../ValidationEditDialog';

interface GatewayEditDialogProps {
  actionedCatalog: HardwareCatalog;
  refetch: () => void;
  handleClose: () => void;
}

const GatewayEditDialog = ({
  actionedCatalog,
  refetch,
  handleClose
}: GatewayEditDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const actionedGateway = actionedCatalog as GatewayCatalog;

  const gatewayEditInitialValues: GatewayFormikValues = {
    brand: actionedGateway.brand,
    model: actionedGateway.model,
    power: actionedGateway.power,
    fxoPorts: actionedGateway.fxoPorts?.toString() ?? '',
    ipAddressRequired: actionedGateway.ipAddressRequired,
    serialNumberRequired: actionedGateway.serialNumberRequired,
    macAddressRequired: actionedGateway.macAddressRequired,
    imeiRequired: actionedGateway.imeiRequired
  };

  const formik = useFormik({
    initialValues: gatewayEditInitialValues,
    validationSchema: GatewayCatalogSchema(actionedGateway.id),
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    const data: GatewayCreateEditData = {
      ...formik.values,
      fxoPorts: parseInt(formik.values.fxoPorts)
    };

    // Check only allowable values have been changed
    if (actionedCatalog.locationCount > 0) {
      const invalidField = validateOnlyEditableFields(actionedGateway, data);
      if (invalidField) {
        dispatch(
          actions.showNotify({
            message: `Cannot change ${invalidField} for this gateway as it has locations`,
            severity: 'error'
          })
        );
        return;
      }
    }
    try {
      // API call
      await editHardwareCatalog('gateway', actionedGateway.id, data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Gateway edited successfully',
          severity: 'success'
        })
      );
      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: error.message,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'Failed to edit gateway',
            severity: 'error'
          })
        );
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Gateway"
      type="Edit"
      formik={formik}
      dropDownInputs={gatewayDropDownInputs}
      requiredInputs={gatewayRequiredInputs}
      textInputs={gatewayTextInputs}
      handleClose={handleClose}
    />
  );
};

export default GatewayEditDialog;

import React from 'react';

export interface FormatDateProps {
  children: string;
  options?: Intl.DateTimeFormatOptions;
  className?: string;
  separator?: string;
}

const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

/**
 * Takes a parsable date string and options and displays a formatted date
 * @param {FormatDateProps} props
 * @returns {JSX.Element}
 */
const FormatDate = ({
  children,
  options = defaultOptions,
  className,
  separator
}: FormatDateProps): JSX.Element => {
  const dateObj = new Date(children);
  let text = new Intl.DateTimeFormat('en-US', options)
    .format(dateObj)
    .replace(/, /g, ' ');
  if (separator) {
    const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(
      dateObj
    );
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(
      dateObj
    );
    text = `${formattedDate} ${separator} ${formattedTime}`;
  }

  return <span className={className}>{text}</span>;
};

export default FormatDate;

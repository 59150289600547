import * as React from 'react';

import { ALLOWABLE_THREE_DIGIT_NUMBERS } from '../../../constants/app';

export interface PhoneNumberProps {
  className?: string;
  /*
   ** Component requires 'children' prop, however 'children' value is allowed to be 'undefined'
   ** This is to prevent misuse of the component without a closing tag, ex: '<PhoneNumber />'
   */
  children: string | number | undefined | null;
}

/**
 * Expected phone number format
 * 1 (###) ###-####
 */
const expectedFormat = /^[1]{1,1}\s{1,1}[(]{1,1}\d{3,3}[)]{1,1}\s{1,1}\d{3,3}-\d{4,4}/;

/**
 * Phone number formated without country prefix
 * (###) ###-####
 */
const formatedWithoutContryPrefix = /^[(]{1,1}\d{3,3}[)]{1,1}\s{1,1}\d{3,3}-\d{4,4}/;

const formattedWithE164 = /^\+1\d{10}$/;

/**
 * Receives value and transform it to North American Numbering Plan (NANP) format
 * or return an hyphen char
 * @param {string} value
 * @return {string} NANP formated number: 1 (###) ###-#### or '-'
 */
const PhoneNumber = ({
  children,
  className
}: PhoneNumberProps): JSX.Element => {
  // value is falsy
  if (!children) {
    return <span className={className}>-</span>;
  }

  let phoneNumber = `${children}`;

  // value already has NANP format
  if (expectedFormat.test(phoneNumber)) {
    return <span className={className}>{phoneNumber}</span>;
  }

  // number already formated but without country prefix
  if (formatedWithoutContryPrefix.test(phoneNumber)) {
    return <span className={className}>{`1 ${phoneNumber}`}</span>;
  }

  // number already formated with E164 format (i.e: prefixed with +1)
  if (formattedWithE164.test(phoneNumber)) {
    phoneNumber = `${phoneNumber.substring(1)}`;
  }

  // extract phoneNumber if SIP formatted
  if (phoneNumber.includes('sip:+')) {
    phoneNumber = phoneNumber.split('sip:+')[1].substring(0, 11);
  }

  if (
    (phoneNumber.length === 4 && phoneNumber.startsWith('+')) ||
    phoneNumber.length === 3
  ) {
    let checkMe = phoneNumber;
    if (phoneNumber.length === 4) {
      checkMe = phoneNumber.substring(1);
    }
    if (!ALLOWABLE_THREE_DIGIT_NUMBERS.includes(checkMe)) {
      return <span className={className}>-</span>;
    }
    return <span className={className}>{checkMe}</span>;
  }

  // escape hatch if the number still isn't compatible
  if (phoneNumber.length !== 10 && phoneNumber.length !== 11) {
    return <span className={className}>-</span>;
  }

  // dynamic cursor to select phoneNumbers depending of the input length
  const cursor = phoneNumber.length === 10 ? 0 : 1;
  const areaCode = `${phoneNumber.substring(0 + cursor, 3 + cursor)}`;
  const centralOfficeCode = `${phoneNumber.substring(3 + cursor, 6 + cursor)}`;
  const stationNumber = `${phoneNumber.substring(6 + cursor, 10 + cursor)}`;
  // Set the exploded parts into phoneNumber
  phoneNumber = `1 (${areaCode}) ${centralOfficeCode}-${stationNumber}`;

  if (!expectedFormat.test(phoneNumber)) {
    return <span className={className}>-</span>;
  }

  return <span className={className}>{phoneNumber}</span>;
};

export default PhoneNumber;

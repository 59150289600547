import {
  DisclosureKey,
  GeoJsonDataFeature,
  GeoJsonDataZipcodeMap,
  ZoomBusinessKey
} from '@marketspark/ms-utils-and-interfaces';
import { Location } from 'types';
import { ProspectAddress } from './addressesHelper';

// eslint-disable-next-line import/prefer-default-export
export function convertLocationsToClusterDataMap(
  disclosureZipcodeMap: GeoJsonDataZipcodeMap,
  businesses?: Location[]
): GeoJsonDataZipcodeMap {
  const map: GeoJsonDataZipcodeMap = {};
  const zipcodeCountMap: Record<string, number> = {};

  businesses?.forEach(business => {
    const { address } = business;
    const zipcode = address.postalCode as DisclosureKey;
    if (zipcodeCountMap[zipcode] === undefined) {
      zipcodeCountMap[zipcode] = 1;
    } else {
      zipcodeCountMap[zipcode] += 1;
    }
  });

  businesses?.forEach(({ address, id }, i) => {
    const zipcode = address.postalCode as ZoomBusinessKey;
    const matchingDiclosure = disclosureZipcodeMap[zipcode];
    const hasImpactedZip = !!matchingDiclosure;

    const clusterData: GeoJsonDataFeature = {
      type: 'Feature',
      properties: {
        type: 'Real business location',
        city: address.city,
        state: address.state,
        zip: zipcode,
        impactedStores: hasImpactedZip ? zipcodeCountMap[zipcode] : 0
      },
      geometry: matchingDiclosure?.geometry,
      id: `${id}_${i}`
    };

    if (map[zipcode] === undefined) {
      map[zipcode] = clusterData;
    }
  });

  return map;
}

export function convertProspectLocationsToClusterMap(
  disclosureZipcodeMap: GeoJsonDataZipcodeMap,
  addresses: ProspectAddress[]
): GeoJsonDataZipcodeMap {
  const map: GeoJsonDataZipcodeMap = {};
  const zipcodeCountMap: Record<string, number> = {};

  addresses?.forEach(address => {
    const addressZip = `${address.street}_${address.zip}` as DisclosureKey;
    if (zipcodeCountMap[addressZip] === undefined) {
      zipcodeCountMap[addressZip] = 1;
    } else {
      zipcodeCountMap[addressZip] += 1;
    }
  });

  addresses?.forEach((address, i) => {
    const zipcode = address.zip as ZoomBusinessKey;
    const addressZip = `${address.street}_${address.zip}` as DisclosureKey;
    const matchingDiclosure = disclosureZipcodeMap[zipcode];
    const hasImpactedZip = !!matchingDiclosure;

    const clusterData: GeoJsonDataFeature = {
      type: 'Feature',
      properties: {
        type: 'Real business location',
        city: address?.city,
        state: address?.state,
        zip: zipcode,
        impactedStores: hasImpactedZip ? zipcodeCountMap[addressZip] : 0
      },
      geometry: matchingDiclosure?.geometry,
      id: `${address.street}_${address.zip}_${i}`
    };

    if (map[zipcode] === undefined) {
      map[zipcode] = clusterData;
    }
  });

  return map;
}

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';

export interface OrgAvatarProps {
  src?: string;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: any;
}

/**
 * Component wrapper that renders a Material UI avatar.
 * In case there is an error rendering the Image Avatar,
 * a default icon will be displated.
 * @param props
 */
const OrgAvatar = React.forwardRef<HTMLDivElement, OrgAvatarProps>(
  ({ src, name, onClick, className }: OrgAvatarProps, ref): JSX.Element => {
    const classes = useStyles();

    return (
      <Avatar
        alt={name ? `${name} Logo` : 'Org Logo'}
        src={src}
        onClick={onClick}
        className={className}
        classes={classes}
        ref={ref}
      >
        <BusinessIcon className={classes.fallback} />
      </Avatar>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  colorDefault: {
    backgroundColor: 'transparent'
  },
  fallback: {
    color: theme.palette.common.black
  }
}));

export default OrgAvatar;

import axios from '../utils/axiosProvider';

interface GetIDTNumbersParams {
  country: string;
  areaCode: string;
  pageSize: number;
  pageNumber: number;
}

export interface IDTNumber {
  sku: string;
  did: string;
}

interface PagedIDTNumbers {
  data: IDTNumber[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

const getIDTNumbers = async ({
  country,
  areaCode,
  pageSize,
  pageNumber
}: GetIDTNumbersParams): Promise<PagedIDTNumbers> => {
  const { data } = await axios.get('n2p/numbers', {
    params: {
      country,
      areaCode,
      pageSize,
      pageNumber
    }
  });

  return data;
};

const purchaseIDTNumbers = async (
  skus: string[]
): Promise<{ status: string; statusMessage: string }> => {
  const { data } = await axios.post('n2p/numbers/purchase', {
    skus
  });

  return data;
};

export { getIDTNumbers, purchaseIDTNumbers };

import { PayloadAction } from '@reduxjs/toolkit';
import createSlice from 'utils/@reduxjs/toolkit';
import { setLS } from 'utils/localStorage';
import { AuthState } from './types';

export const initialState: AuthState = {
  returndirect: '',
  exp: 0,
  token: '',
  permissions: []
};

export const logoutState: AuthState = {
  ...initialState,
  exp: 0
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<AuthState>) {
      Object.assign(state, action.payload);
      setLS({
        auth: state
      });
    },
    setReturndirect(state, action: PayloadAction<string>) {
      state.returndirect = action.payload;
      setLS({
        auth: {
          ...state,
          returndirect: action.payload
        }
      });
    }
  }
});

export const { actions: authActions, reducer: auth } = slice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthSlice = () => {
  return { actions: slice.actions };
};

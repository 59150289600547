import _ from 'lodash';
import React from 'react';
import Dropdown, { DropdownOption } from './Dropdown';

export interface DropdownBuilderState {
  formKey: string;
  items: DropdownOption[];
  onChange: (args: any) => void;
  setItems: (newItems: any) => void;
  setValue: (newValue: any) => void;
  value: string | number | undefined;
  required: boolean;
  setRequired: (newValue: boolean) => void;
}

export interface UseDropdownProps {
  items?: DropdownOption[];
  value?: string;
  formKey?: string;
  name: string;
  required?: boolean;
  state?: { formData: Record<string, any> };
  onChange?: (args: Record<string, any>) => void;
}

/* Custom hook to be used with DropDownBuilder.
 * The returned memoized object is the "state" argument passed
 * to DropDownBuilder state=state
 */
export function useDropdown(props: UseDropdownProps): DropdownBuilderState {
  const formKeyName = props.formKey ?? `${props.name}Id`;
  const [required, setRequired] = React.useState<boolean>(
    props?.required ?? false
  );
  const [items, setItems] = React.useState<DropdownOption[]>(props.items ?? []);
  const [formKey] = React.useState<string>(formKeyName);
  const [value, setValue] = React.useState<string | undefined>(props?.value);

  const handleOnChange = React.useCallback(
    ({ value: newValue }: Record<string, any>) => {
      setValue(newValue);
    },
    []
  );

  const onChange = props?.onChange ? props.onChange : handleOnChange;

  React.useEffect(() => {
    if (props?.state?.formData?.[formKey]) {
      const newValue = props?.state?.formData[formKey];
      if (newValue) {
        setValue(newValue);
      }
    }
  }, [props.state, formKey]);

  return {
    formKey,
    items,
    setItems,
    onChange,
    value,
    setValue,
    setRequired,
    required
  };
}

export interface DropDownBuilderProps {
  label?: string;
  name: string;
  placeholder?: string;
  state: Record<string, any>;
}

export const DropDownBuilder = ({
  name,
  label,
  placeholder,
  state
}: DropDownBuilderProps): JSX.Element => {
  return (
    <Dropdown
      id={name}
      className={name}
      name={name}
      placeholder={placeholder}
      label={label ? _.capitalize(label) : _.capitalize(name)}
      onChange={(args: any) => {
        state.onChange(args);
      }}
      dropdownItems={state.items}
      isError={false}
      value={state.value}
    />
  );
};

import React from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddCircle from '@material-ui/icons/AddCircle';
import { LaunchRounded } from '@material-ui/icons';

import axiosInstance from '../../../utils/axiosProvider';
import { selectCurrentUser } from '../../../store/selectors';
import { useDashboardSlice } from '../../layouts';
import { ReactComponent as DeleteIcon } from '../../../assets/img/delete.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../../assets/img/delete_gray.svg';
import { useLocationNotes } from 'api/useLocationNotes';
import {
  Card,
  CardHeader,
  CardFooter,
  CardNavButton,
  CardContent,
  Delete,
  Dialog
} from 'design';
import AccountNotesSkeleton from './LocationNotesSkeleton';
import LocationAddNote from '../LocationAddNote';
import MultiViewNotes from './MultiViewNotesModal';
import styles from './LocationNotesCard.module.scss';

export interface AccountNotesCardProps {
  locationId: string;
}

const LocationNotesCard = ({
  locationId
}: AccountNotesCardProps): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const { data, isLoading, isError, refetch, error } = useLocationNotes(
    locationId,
    pageIndex
  );

  const [modalOpen, setModalOpen] = React.useState<
    'add' | 'delete' | 'expand' | ''
  >('');

  if (isError && error) {
    throw new Error(`${error.message}, could not fetch Location Notes`);
  }

  const note = data?.notes?.[0] || {
    text: '',
    createdAt: '',
    userName: '',
    id: ''
  };
  const total = data?.total || 0;

  // Handle delete note
  const handleDeleteNote = async () => {
    try {
      await axiosInstance.delete(`/accounts/${locationId}/notes/${note.id}`);
      refetch();
      dispatch(
        actions.showNotify({ message: 'Note deleted', severity: 'success' })
      );
      setModalOpen('');
    } catch (err) {
      const message =
        err instanceof Error
          ? err.message
          : 'An error occurred trying to delete the note';
      dispatch(actions.showNotify({ message, severity: 'error' }));
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Notes"
          action={
            <>
              {currentUser?.isSuperAdmin && (
                <>
                  {data?.total !== 0 && (
                    <IconButton
                      data-testid={'expand-note'}
                      aria-label="settings"
                      className={styles.iconWrapperMargin}
                      onClick={() => setModalOpen('expand')}
                    >
                      <LaunchRounded color="primary" />
                    </IconButton>
                  )}
                  <IconButton
                    data-testid={'delete-note'}
                    aria-label="settings"
                    className={styles.iconWrapperMargin}
                    onClick={() => setModalOpen('delete')}
                    disabled={note.id === undefined}
                  >
                    {note.id === undefined ? (
                      <DisabledDeleteIcon />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                  <IconButton
                    data-testid={'add-note'}
                    aria-label="settings"
                    className={styles.iconWrapper}
                    onClick={() => setModalOpen('add')}
                  >
                    <AddCircle data-testid="addButton" color="primary" />
                  </IconButton>
                </>
              )}
            </>
          }
        />

        <CardContent>
          {isLoading ? (
            <AccountNotesSkeleton />
          ) : note?.text ? (
            <div className={styles.contentWrapper}>
              <Typography color="primary">{`Viewing ${
                total ? pageIndex : 0
              } of ${total} Notes`}</Typography>
              <Typography paragraph={true}>
                {moment.utc(note.createdAt).format('MM/DD/YYYY')}:{' '}
                {note.userName} - {note.text}
              </Typography>
            </div>
          ) : (
            <></>
          )}
        </CardContent>
        <CardFooter>
          <CardNavButton
            disabled={pageIndex === 1}
            onClick={() => setPageIndex(old => Math.max(old - 1, 1))}
          >
            <ArrowLeftIcon fontSize="small" color="action" />
          </CardNavButton>
          <CardNavButton
            disabled={total === 0 || pageIndex === total}
            onClick={() => setPageIndex(old => old + 1)}
          >
            <ArrowRightIcon fontSize="small" color="action" />
          </CardNavButton>
        </CardFooter>
      </Card>
      {modalOpen === 'expand' && data?.notes && (
        <MultiViewNotes
          locationId={locationId}
          handleClose={() => setModalOpen('')}
        />
      )}
      {modalOpen === 'add' && (
        <LocationAddNote
          locationId={locationId}
          userId={currentUser.id}
          handleClose={() => setModalOpen('')}
        />
      )}
      {modalOpen === 'delete' && (
        <Dialog open={true} handleClose={() => setModalOpen('')}>
          <Delete
            isDelete={true}
            onSubmit={handleDeleteNote}
            onReset={() => setModalOpen('')}
            title={'Note'}
            deleteContent={`This will permanently delete the note from this location`}
            onSubmitLoading={false}
            type="delete"
            errorText="Please type delete to confirm"
          />
        </Dialog>
      )}
    </>
  );
};

export default LocationNotesCard;

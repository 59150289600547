import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

export interface DotProps {
  color?: 'success' | 'error' | 'warning' | 'info' | 'secondary' | null;
}

const Dot = ({ color = 'success' }: DotProps): JSX.Element => {
  const cc = useStyles({ color: color || 'info' });
  return <span className={`${cc.dot} ${color}`}>&nbsp;</span>;
};

const useStyles = makeStyles<Theme, Partial<DotProps>, 'dot'>(
  (theme: Theme) => ({
    dot: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      display: 'inline-block',
      marginTop: '4px',
      backgroundColor: theme.palette.grey['500'],
      '&.success': {
        backgroundColor: theme.palette.success.main
      },
      '&.error': {
        backgroundColor: theme.palette.error.main
      },
      '&.warning': {
        backgroundColor: theme.palette.warning.main
      },
      '&.info': {
        backgroundColor: theme.palette.info.main
      },
      '&.secondary': {
        backgroundColor: theme.palette.grey['500']
      }
    }
  })
);

export default Dot;

/**
 *
 * Login
 *
 */
import * as React from 'react';
import { webAuth } from 'utils/auth';

function Login(): JSX.Element {
  React.useEffect(() => {
    webAuth.authorize({
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      responseType: 'token id_token',
      redirectUri: `${window.location.origin}${process.env.REACT_APP_AUTH_CALLBACK_URI}`
    });
  }, []);
  return <div className="login-logout">Logging in...</div>;
}

export default Login;

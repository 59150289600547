import _ from 'lodash';

export const removeEmptyProps = (obj: any) => {
  const copy = Object.assign({}, obj);

  for (const key of Object.keys(copy)) {
    if (copy[key] === '' || copy[key] === null || copy[key] === undefined) {
      delete copy[key];
    }
  }

  return copy;
};

// Get it?
export const recursiveEmptyProps = (obj: any) => {
  const copy = Object.assign({}, obj);

  for (const key of Object.keys(copy)) {
    if (typeof copy[key] === 'object' && !Array.isArray(copy[key])) {
      copy[key] = recursiveEmptyProps(copy[key]);
    } else {
      if (copy[key] === '' || copy[key] === undefined || copy[key] === null) {
        delete copy[key];
      }
    }
  }

  return copy;
};

export const onlyDifferent = <Type>(obj1: Type, obj2: Type): Partial<Type> => {
  // Setup our copies of the object
  const oldObj = obj1,
    newObj = obj2;
  // Setup our new object which will only contain the differences
  const diffs: Partial<Type> = {};
  for (const key of Object.keys(oldObj)) {
    // Typescript is so cool. :)
    const theKey = key as keyof Type;
    // If the object's key is an object, we recursively do the same thing
    if (typeof oldObj[theKey] === 'object' && !Array.isArray(oldObj[theKey])) {
      diffs[theKey] = onlyDifferent(
        oldObj[theKey],
        newObj[theKey]
      ) as Type[keyof Type];
    } else {
      if (!_.isEqual(oldObj[theKey], newObj[theKey])) {
        diffs[theKey] = newObj[theKey];
      }
    }
  }

  return diffs;
};

export const sortObjectByKeys = (object: any) => {
  const keys = Object.keys(object);
  const sortedKeys = _.sortBy(keys);

  return _.fromPairs(_.map(sortedKeys, key => [key, object[key]]));
};

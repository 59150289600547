import React from 'react';

import { Text } from 'design';
import { phoneSupport, phoneSupportFormatted } from 'constants/app';
import styles from './PhoneLink.module.scss';

const PhoneLink = () => {
  return (
    <div className={styles.container}>
      <a href={`tel:${phoneSupport}`} className={styles.phoneLink}>
        <Text variant="body1">{phoneSupportFormatted}</Text>
      </a>
    </div>
  );
};

export default PhoneLink;

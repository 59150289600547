import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import styles from './numberpurchasetool.module.scss';
import { Dropdown, TextInput } from 'design';

interface NumberFinderFormProps {
  handleChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  values: any;
}

const NumberFinderForm = ({
  handleChange,
  errors,
  values
}: NumberFinderFormProps) => {
  return (
    <>
      <Typography variant="body1">Choose area.</Typography>
      <Grid
        className={clsx(['grid-container', styles.numberToolInner])}
        container
      >
        <Grid item xs={12} md={6}>
          <div className="input-wrapper">
            <Dropdown
              id="country"
              label="Country"
              value={values.country}
              onChange={handleChange}
              isError={!!errors.country}
              validationMessage={errors.country}
              dropdownItems={[
                { label: 'United States', value: 'US' },
                { label: 'Canada', value: 'CA' }
              ]}
              className={styles.formDropdown}
            />
          </div>
          <div className="input-wrapper">
            <TextInput
              id="areaCode"
              name="areaCode"
              label="Area Code*"
              placeholder="123"
              value={values.areaCode}
              onChange={handleChange}
              isError={!!errors.areaCode}
              validationMessage={errors.areaCode}
              maxLength={3}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NumberFinderForm;

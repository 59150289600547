/**
 *
 * Call Detail Records page
 *
 */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'api/useLocations';
import {
  TablePaginated,
  ColumnProps,
  Breadcrumbs,
  SearchInput,
  Text
} from 'design';
import PageHeader from 'app/components/PageHeader';
import { CDR } from 'types';
import { CDRDetails, pageSizeOptions } from 'constants/app';
import useSort from 'hooks/useSort';
import useCdrs from 'api/useCdrs';
import FormatDate from 'app/components/FormatDate';
import PhoneNumber from 'app/components/PhoneNumber';
import CallDuration from 'app/components/CallDuration';

function CdrPage(): JSX.Element {
  const { phoneId, id } = useParams<{
    phoneId: string;
    id: string;
  }>();

  const { data: locationData } = useLocation(id);
  const [sort, setSort] = useSort(['timestamp', 'DESC']);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [filter, setFilter] = React.useState('');
  const [searchInput, setSearchInput] = React.useState('');

  const { data: cdrData, isLoading, error } = useCdrs({
    number: phoneId,
    searchFilter: filter,
    sort,
    pageIndex,
    pageSize
  });

  const locationName = locationData ? locationData.name : '';
  const { data: logs, total } = cdrData ? cdrData : { data: [], total: 0 };

  if (error) {
    throw new Error(`${error.message} Failed to fetch Line CDRs!`);
  }

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      {
        accessor: 'timestamp',
        label: 'TIMESTAMP',
        sortable: true,
        render: ({ timestamp }: CDR) => {
          return timestamp ? <FormatDate>{timestamp}</FormatDate> : <span />;
        },
        resizable: true
      },
      {
        accessor: 'from',
        label: 'FROM NUMBER',
        render: ({ calling }: CDR) => <PhoneNumber>{calling}</PhoneNumber>,
        resizable: true
      },
      {
        accessor: 'to',
        label: 'TO NUMBER',
        render: ({ called }: CDR) => <PhoneNumber>{called}</PhoneNumber>,
        resizable: true
      },
      {
        accessor: 'duration',
        label: 'CALL DURATION',
        render: ({ duration }: CDR) => (
          <CallDuration>{`${duration}`}</CallDuration>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'direction',
        label: 'DIRECTION',
        render: ({ direction }: CDR) => (
          <Text>
            {`${
              direction === 'O'
                ? 'Outbound'
                : direction === 'I'
                ? 'Inbound'
                : undefined
            }`}
          </Text>
        ),
        sortable: true,
        resizable: true
      }
    ],
    []
  );

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'Locations',
            url: `/locations`
          },
          {
            label: `${locationName}`,
            url: `/${locationData?.location?.orgId}/location/${id}/section/lines`
          },
          {
            label: <PhoneNumber>{phoneId}</PhoneNumber>,
            url: `#`
          }
        ]}
      />
      <div className="page-header align-top">
        <PageHeader
          title={<PhoneNumber>{phoneId}</PhoneNumber>}
          subTitle="Call Detail Records"
          status={locationData?.serviceStatus?.name}
        />
        <div className="page-header__options">
          <SearchInput
            className="page-header__search-input"
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
        </div>
      </div>
      <hr className="divider" />
      <p className="table-top-text">
        Records are delayed up to {CDRDetails.loadInterval}
      </p>
      <div>
        {!isLoading && total > 0 ? (
          <TablePaginated
            id="LineCallLogs"
            rows={logs}
            sort={sort}
            total={total}
            columns={columns}
            handlePageSize={setPageSize}
            handlePageNav={setPageIndex}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            handleSort={setSort}
            isLoading={isLoading}
          />
        ) : (
          <div className="empty-card">
            <p className="card-text">
              No CDRs found for the past {CDRDetails.numberOfDays} days
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default CdrPage;

import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL } from 'constants/app';
import moment from 'moment';
import webAuth from './auth';
import { getLS, setLS } from './localStorage';

// Set default base URL to axios instance
const axiosInstance = axios.create({ baseURL: API_URL });

/**
 * Set default values to axions instance
 * @param {AxiosRequestConfig} req
 * @returns {Object}
 */
const requestHandler = async (
  req: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const storage = getLS().auth;
  //throw error and authenticate when session is expired
  const exp = storage?.exp;
  if (exp && exp - moment().unix() <= 0) {
    setLS({ auth: { returndirect: window.location.pathname } });
    webAuth.authorize({
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      responseType: 'token id_token',
      redirectUri: `${window.location.origin}${process.env.REACT_APP_AUTH_CALLBACK_URI}`
    });

    throw new Error(
      'Session Expired. Please wait for the page to refresh and try again'
    );
  }
  req.headers.Authorization = `Bearer ${storage?.token}`;
  req.withCredentials = true;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  return req;
};

/**
 * Set interceptor to axios instance
 * @param {AxiosRequestConfig} req
 * @param {any} error
 * @returns {Object}
 */
axiosInstance.interceptors.request.use(
  async req => requestHandler(req),
  error => Promise.reject(error)
);

export * from 'axios';

export default axiosInstance;

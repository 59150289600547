/* eslint-disable react/jsx-props-no-spreading */
import { useAdminStatuses, useCreateBundle } from 'api/useBundle';
import { useBundleTemplates, useManagedBy } from 'api/useBundleTemplate';
import { useDashboardSlice } from 'app/layouts';
import _ from 'lodash';
import {
  INHAND_ROUTER,
  SANGOMA,
  DEFAULT_BUNDLE_HEADER_TEXT
} from 'constants/app';
import { Dropdown, Heading } from 'design';
import { LabelValuesMap, labelValuesFor } from 'design/Dropdown/Dropdown';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RouterCatalog, GatewayCatalog, PowersourceCatalog } from 'types';

import { v4 as uuidV4 } from 'uuid';
import { Router } from 'types/Router';
import { Gateway } from 'types/Gateway';
import { Battery, Powersource } from 'types/PowerSource';
import {
  FormProvider,
  Step,
  Stepper,
  useForm,
  useFormContext
} from 'design/Form/Form';
import BundleIncludesCard from './BundleIncludesCard';
import { Grid } from '@material-ui/core';
import Schema from './Schema';
import trimValuesDeep from 'utils/trimValuesDeep';
import RouterForm from 'app/components/EquipmentAndHardwareForms/RouterForm';
import GatewayForm from 'app/components/EquipmentAndHardwareForms/GatewayForm';
import PowersourceForm from 'app/components/EquipmentAndHardwareForms/PowersourceForm';
import { isNestedObjectEmpty } from 'utils/yupHelpers';
import {
  validateGateway,
  validatePowersource,
  validateRouter
} from 'utils/schemaValidators/bundleValidators';
import { MSFieldInput } from 'types/FieldInput';
import { HardwareCatalogStatusEnum } from '../../../../types/HardwareCatalogTypes';
import { AxiosError } from 'axios';

interface CreateBundleProps {
  locationId: string;
  erpLocationId: string | null;
  domain?: string;
  closeFormHandler?: () => void;
}

/**
 * Create bundle
 * @param {CreateBundleProps} props
 * @returns {JSX.Element}
 */

const EquipmentBundleCreate = ({
  locationId,
  erpLocationId,
  domain,
  closeFormHandler
}: CreateBundleProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const { data } = useBundleTemplates(
    HardwareCatalogStatusEnum.ACTIVE,
    ['', 'ASC'],
    1,
    1000
  );
  const { data: managedBy } = useManagedBy();
  const { data: adminStatuses } = useAdminStatuses();
  const { mutateAsync: createBundle } = useCreateBundle();

  const [headerText, setHeaderText] = React.useState<string>(
    DEFAULT_BUNDLE_HEADER_TEXT
  );
  const [selectedBundleCatalog, setSelectedBundleCatalog] = React.useState<
    string
  >();
  const [adminStatus, setAdminStatus] = React.useState({
    id: '',
    name: ''
  });

  const bundleTemplateList: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(data?.bundleTemplates);
  }, [data?.bundleTemplates]);

  const managedByList: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(managedBy);
  }, [managedBy]);

  const adminStatusList: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(adminStatuses);
  }, [adminStatuses]);

  // Handle when a bundle is selected
  const handleBundleDropdown = (e: MSFieldInput) => {
    bundleCatalog.onChange(e);
    setSelectedBundleCatalog(e.value);
    setHeaderText(e.label ?? DEFAULT_BUNDLE_HEADER_TEXT);
  };

  //BeLOW function checks for duplicate values within the form
  //For now, this is needed only for Equipment bundle create form.
  //We may need to move some of the logics into Form hook itself if we are going to use this in other forms
  const validateDuplicates = (value: string, parentData: any, path: string) => {
    const shouldValidateOnly = [
      'imei',
      'mac',
      'serialNumber',
      'ip',
      'batterySerialNumber'
    ];
    const splittedPath = path.split('.');
    const objectName = splittedPath[splittedPath.length - 3];
    const objectPath = splittedPath.slice(0, splittedPath.length - 2);
    const object = _.get(parentData, objectPath);
    const index = splittedPath[splittedPath.length - 2];
    const inputName = splittedPath[splittedPath.length - 1];
    const returnError: any = {
      routers: 'router',
      gateways: 'gateway',
      batteries: 'battery'
    };
    let error;
    if (shouldValidateOnly.includes(inputName)) {
      object.forEach((item: any, i: number) => {
        let perviousValue = value;
        let currentValue = item[inputName];

        if (inputName === 'serialNumber') {
          perviousValue = value.toUpperCase();
          currentValue = item[inputName].toUpperCase();
        }

        if (inputName === 'mac') {
          perviousValue = value.toLowerCase();
          currentValue = item[inputName].toLowerCase();
        }

        if (
          perviousValue &&
          currentValue === perviousValue &&
          Number(index) !== i
        ) {
          error = `Duplicate value provided. Value already exists in ${
            returnError[objectName]
          } ${i + 1}`;
        }
      });
    }
    return error;
  };

  const handleClose = () => {
    if (closeFormHandler) closeFormHandler();
    dispatch(actions.hideDialog());
  };

  const form = useForm({
    initialValues: {
      ownerId: managedByList?.[0].value,
      routers: [] as Router[],
      gateways: [] as Gateway[],
      powersources: [] as any[]
    },
    validationSchema: Schema(locationId),
    onClose: handleClose,
    validate: validateDuplicates,
    onSubmit: async values => {
      try {
        const routers = values.routers.map((router: Router) => ({
          ...router,
          serialNumber: router.serialNumber.toUpperCase()
        }));
        const gateways = values.gateways.map((gateway: Gateway) => ({
          ..._.omit(gateway, 'id'),
          serialNumber: gateway.serialNumber.toUpperCase()
        }));
        const powersources = [];
        powersources.push({
          ..._.omit(values.powersources[0], ['id', 'batteries']),
          serialNumber: values.powersources[0].serialNumber
            ? values.powersources[0].serialNumber.toUpperCase()
            : null,
          batterySerialNumber: !isNestedObjectEmpty(
            values.powersources[0].batteries
          )
            ? values.powersources[0].batteries
                .map((battery: Battery) =>
                  battery.batterySerialNumber
                    ? battery.batterySerialNumber.toUpperCase()
                    : null
                )
                .toString()
            : null,
          routerId: values.powersources[0].routerId || null
        });
        await createBundle(
          trimValuesDeep({
            ..._.omit(values, 'id'),
            locationId: locationId,
            routers,
            gateways,
            powersources
          })
        );

        dispatch(actions.hideDialog());
        // Note: when this form is rendered inside the Add Hardware form, we have to also close the
        // Add Hardware form. Ideally the action/dispatch would be removed from this form and just
        // directly call Dialog.
        if (closeFormHandler) closeFormHandler();
        dispatch(
          actions.showNotify({
            message: 'Bundle created successfully.',
            severity: 'success'
          })
        );
      } catch (err) {
        let message = 'Error creating bundle';
        if (err instanceof AxiosError) {
          message = err.response?.data?.message;
        } else if (err instanceof Error) {
          message = err.message;
        }
        dispatch(actions.showNotify({ message }));
      }
    }
  });
  const { register, formData, setFormData } = form;

  // set default admin status as "Shipped" | "Shipping"
  React.useEffect(() => {
    if (adminStatuses && !adminStatus.id) {
      setTimeout(() => {
        const shippedStatus = adminStatuses?.find(
          item => item.name === 'Shipped' || item.name === 'Shipping'
        );
        if (shippedStatus) {
          setAdminStatus(shippedStatus);
          adminStatusInput.setValue(shippedStatus.id);
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminStatuses]);

  // create bundle form according to bundle catalog
  React.useEffect(() => {
    if (data?.bundleTemplates) {
      const selectedBundle = data.bundleTemplates.find(
        item => item.id === selectedBundleCatalog
      );
      if (!selectedBundle) return;
      const routers: Router[] = [];
      const gateways: Gateway[] = [];
      const powersources: Powersource[] = [];
      if (
        selectedBundle.routersCatalog &&
        selectedBundle.routersCatalog?.length
      ) {
        selectedBundle.routersCatalog.forEach((element: RouterCatalog) => {
          routers.push({
            id: uuidV4(),
            routerCatalog: {
              id: element?.id,
              brand: element?.brand,
              model: element?.model
            },
            routerCatalogId: element?.id,
            adminStatusId: adminStatus.id,
            adminStatus: {
              id: adminStatus?.id || '',
              name: adminStatus?.name || ''
            },
            serialNumber: '',
            mac: '',
            imei: '',
            ...(element?.brand === INHAND_ROUTER && {
              ip: '',
              userName: '',
              password: ''
            })
          });
        });
      }

      if (
        selectedBundle.gatewaysCatalog &&
        selectedBundle.gatewaysCatalog?.length
      ) {
        selectedBundle.gatewaysCatalog.forEach((element: GatewayCatalog) => {
          gateways.push({
            id: '',
            gatewayCatalog: {
              id: element?.id,
              brand: element?.brand,
              model: element?.model
            },
            gatewayCatalogId: element?.id,
            adminStatusId: adminStatus.id,
            adminStatus: {
              id: adminStatus?.id || '',
              name: adminStatus?.name || ''
            },
            serialNumber: '',
            mac: '',
            domain: '',
            ...(element?.brand === SANGOMA && {
              ip: ''
            })
          });
        });
      }

      if (
        selectedBundle.powersourcesCatalog &&
        selectedBundle.powersourcesCatalog?.length
      ) {
        selectedBundle.powersourcesCatalog.forEach(
          (element: PowersourceCatalog) => {
            powersources.push({
              id: '',
              powersourceCatalog: {
                id: element?.id,
                brand: element.brand,
                model: element.model
              },
              powersourceCatalogId: element.id,
              adminStatusId: adminStatus.id,
              adminStatus: {
                id: adminStatus?.id || '',
                name: adminStatus?.name || ''
              },
              serialNumber: '',
              batteries: [...Array(element.maxBatteryCount).keys()].map(() => {
                return {
                  batterySerialNumber: ''
                };
              }),
              routerId: ''
            });
          }
        );
      }

      setFormData({
        ...formData,
        routers,
        gateways,
        powersources
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBundleCatalog, adminStatus]);

  const bundleCatalog = register('bundleCatalogId');
  const owner = register('ownerId');
  const adminStatusInput = register('adminStatusId');

  return (
    <>
      <Heading variant="h5">{headerText}</Heading>
      <FormProvider data={form}>
        <Stepper>
          <Step
            name=""
            validate={['bundleCatalogId', 'ownerId', 'adminStatusId']}
          >
            <div className="grid-container">
              <div className="includes-container">
                <Grid item xs={12} md={6}>
                  <div className="input-wrapper">
                    <Dropdown
                      id="bundleCatalogId"
                      placeholder="Select a SKU"
                      label="Hardware bundle"
                      dropdownItems={bundleTemplateList}
                      {...bundleCatalog}
                      onChange={handleBundleDropdown}
                    />
                  </div>
                </Grid>
                {selectedBundleCatalog && <BundleIncludesCard />}
              </div>
              <Grid item xs={12} md={6} className="picker-container">
                <div className="input-wrapper">
                  <Dropdown
                    id="ownerId"
                    label="Managed By"
                    placeholder="Select One"
                    dropdownItems={managedByList}
                    {...owner}
                  />
                </div>
                <div className="input-wrapper">
                  <Dropdown
                    id="adminStatusId"
                    placeholder="Select One"
                    label="Install Status"
                    dropdownItems={adminStatusList}
                    {...adminStatusInput}
                    onChange={(e: any) => {
                      setAdminStatus({ id: e.value, name: e.label });
                      adminStatusInput.onChange(e);
                    }}
                  />
                </div>
              </Grid>
            </div>
          </Step>
          {formData.routers?.map((i, index: number) => {
            return (
              <Step
                key={`${i}-${index}`}
                name={`routers.${index}`}
                validate={validateRouter}
              >
                <RouterForm
                  subName={index}
                  formContext={useFormContext}
                  maxCount={formData.routers.length}
                  subHeaderText={`${i.routerCatalog.model} (Router ${
                    index + 1
                  } of ${formData.routers.length})`}
                  catalogBrand={i.routerCatalog.brand}
                />
              </Step>
            );
          })}
          {formData.gateways?.map((i, index: number) => {
            return (
              <Step
                key={`${i}-${index}`}
                name={`gateways.${index}`}
                validate={validateGateway}
              >
                <GatewayForm
                  erpLocationId={erpLocationId}
                  domain={domain}
                  subName={index}
                  formContext={useFormContext}
                  maxCount={formData?.gateways?.length}
                  gatewayBrand={
                    formData?.gateways?.[index]?.gatewayCatalog?.brand
                  }
                  subHeaderText={`${i.gatewayCatalog.model} (Gateway ${
                    index + 1
                  } of ${formData.gateways.length})`}
                />
              </Step>
            );
          })}
          {formData.powersources?.map((i, index: number) => (
            <Step
              key={`${i}-${index}`}
              name={`powersources.${index}`}
              validate={validatePowersource}
            >
              <PowersourceForm
                subName={index}
                formContext={useFormContext}
                subHeaderText={`Powersource - ${i.powersourceCatalog.model}`}
              />
            </Step>
          ))}
        </Stepper>
      </FormProvider>
    </>
  );
};

export default EquipmentBundleCreate;

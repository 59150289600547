import * as React from 'react';
import { useDispatch } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';
import { MoreMenu, ActionButton } from 'design';
import { useDashboardSlice } from 'app/layouts';
import useCurrentUser from 'api/useCurrentUser';
import { MARKETSPARK_ORG_NAME } from 'constants/app';
import { StandardIdNameType } from 'types/Line';
import { Location } from 'types';

interface IVRLineActionProps {
  id: string;
  location: Location;
  phoneNumber: number;
  description: string | null;
  provider?: StandardIdNameType;
  refetch?: any;
}
const IVRLineAction = ({
  id,
  location,
  phoneNumber,
  description,
  provider,
  refetch
}: IVRLineActionProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const lineMoreMenuItems = [
    {
      label: 'Delete',
      isVisible:
        currentUser?.isSuperAdmin && location.name === MARKETSPARK_ORG_NAME,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LineDelete',
            lazyComponentProps: {
              id,
              phoneNumber,
              locationName: location.name
            }
          })
        );
      }
    },
    {
      label: 'Detach',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LineDetach',
            lazyComponentProps: {
              id,
              phoneNumber,
              locationName: location.name,
              refetch
            }
          })
        );
      }
    },
    {
      label: 'Edit',
      isVisible:
        currentUser?.isSuperAdmin && !(location.name === MARKETSPARK_ORG_NAME)
          ? true
          : false,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Edit IVR Line',
            lazyComponent: 'ManageIVRLine',
            lazyComponentProps: {
              id,
              location,
              phoneNumber,
              description,
              provider
            }
          })
        );
      }
    }
  ];
  return (
    <>
      <div className="float-right">
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <MoreMenu
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
        menuItems={lineMoreMenuItems}
      />
    </>
  );
};

export default IVRLineAction;

import { CircularProgress } from '@material-ui/core';
import { useDashboardSlice } from 'app/layouts';
import { Button, TextInput } from 'design';
import React from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from 'utils/axiosProvider';

import styles from './delete.module.scss';

export interface DeleteProps {
  id: string;
  name: string;
  entityType: string;
  deleteURL: string;
  dependencyURL?: string;
  handleClose: () => void;
  afterSubmit?: () => void;
}

// Will need to normalize the returned values
// of dependency URLS

const Delete = ({
  name,
  entityType,
  deleteURL,
  dependencyURL,
  handleClose,
  afterSubmit
}: DeleteProps) => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const [isLoadingDeps, setIsLoadingDeps] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [depList, setDepList] = React.useState<Record<string, string[]>[]>([]);
  const [totalDeps, setTotalDeps] = React.useState(0);
  const [deleteText, setDeleteText] = React.useState('');

  const submitDelete = async (e: any) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await axiosInstance.delete(deleteURL);
      handleClose();

      dispatch(
        actions.showNotify({
          message: `${name} successfully deleted`,
          severity: 'success'
        })
      );

      if (afterSubmit) {
        afterSubmit();
      }
    } catch (err) {
      dispatch(
        actions.showNotify({
          message: err?.message || `Error deleting ${name}`
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (dependencyURL) {
        try {
          setIsLoadingDeps(true);
          const { data } = await axiosInstance.get(dependencyURL);
          setDepList(data.items);
          setTotalDeps(data.total);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoadingDeps(false);
        }
      }
    })();
  }, [dependencyURL]);

  if (isLoadingDeps)
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );

  if (!isLoadingDeps && totalDeps > 0) {
    return (
      <div>
        <h4>Dependent Entities - {totalDeps}</h4>
        <div className={styles.deleteList}>
          {Object.entries(depList).map(([key, value]) => (
            <div key={key}>
              <p>
                {key.charAt(0).toUpperCase() + key.slice(1)} - {value.length}
              </p>
              <ul>
                {Array.isArray(value) &&
                  value.map((val: string) => (
                    <li key={`${key}-${val}`}>{val}</li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
        <p>Remove these associations to delete {entityType}</p>
      </div>
    );
  }

  return (
    <form onSubmit={submitDelete}>
      {!isSubmitting ? (
        <>
          <p>
            This will permanently remove the {entityType} {name} from Command
            Center
          </p>
          <div>
            <TextInput
              name="delete"
              id="delete"
              label="Type delete to confirm"
              isError={deleteText !== 'delete'}
              value={deleteText}
              onChange={e => {
                setDeleteText(e.target.value);
              }}
            />
          </div>
          <div className="form-footer">
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={deleteText !== 'delete'} type="submit">
              Delete
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </form>
  );
};

export default Delete;

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// Slice selection with fallback initial values
const selectSliceDialog = (state: RootState) =>
  state?.dashboard?.dialog ?? initialState.dialog;
const selectSliceNotify = (state: RootState) =>
  state?.dashboard?.notify ?? initialState.notify;
const selectSliceSidebar = (state: RootState) =>
  state?.dashboard?.sidebarOpen ?? initialState.sidebarOpen;

/**
 * Select Dialog state only
 */
export const selectDialog = createSelector([selectSliceDialog], state => state);

/**
 * Select Notify comopnent state only
 */
export const selectNotify = createSelector([selectSliceNotify], state => state);

/**
 * Select Sidebar open state only
 */
export const selectSidebar = createSelector(
  [selectSliceSidebar],
  state => state
);

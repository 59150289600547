// Constant with template strings to specify the URLs for equipment portals.
// Retrieving a URL would look like, for example:
//  portalUrls.cradlepoint.ata('1234567890')
const portalUrls = {
  cradlepoint: {
    router: (serialNumber: string) =>
      `https://www.cradlepointecm.com/ecm.html#devices/routers?search=${serialNumber}`
  }
};

export default portalUrls;

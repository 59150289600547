/**
 *
 * Auth
 *
 */
import React, { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Auth0DecodedHash } from 'auth0-js';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_ERROR_URI, dashboardUri } from 'constants/app';
import { webAuth } from 'utils/auth';
import jwtDecode from 'jwt-decode';
import { AccessToken } from 'types';
import { useAuthSlice } from 'app/slice';
import { selectReturnDirect } from 'app/slice/selectors';
import axiosInstance from 'utils/axiosProvider';
import { User } from 'types';
import { userActions } from 'store/userSlice';
import QueryString from 'qs';
import { AuthErrors } from 'types/AuthErrors';

function sendToError(errors: string) {
  return `${AUTH_ERROR_URI}?${errors}`;
}

function AuthCallback({ history, location }: RouteChildrenProps): JSX.Element {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const returndirect = useSelector(selectReturnDirect);

  /**
   * Once location hash is there parse it out, set the results & the user.
   */
  useEffect(() => {
    let loginErrors = '';
    if (!location.hash) {
      loginErrors = QueryString.stringify({ type: AuthErrors.noAuth });
      history.push(sendToError(loginErrors));
      return;
    }

    let webAuthResult: Auth0DecodedHash;
    // parse the has and set auth0 results
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err || authResult === null) {
        loginErrors = QueryString.stringify({
          type: AuthErrors.badHash,
          hash: window.location.hash,
          error: authResult
        });
        history.push(sendToError(loginErrors));
        return;
      }

      webAuthResult = authResult;

      // webAuth is set and now get user info from auth0
      webAuth.client.userInfo(`${authResult.accessToken}`, (authErr, _) => {
        if (authErr) {
          loginErrors = QueryString.stringify({
            type: AuthErrors.authError,
            hash: window.location.hash,
            error: authErr
          });
          history.push(sendToError(loginErrors));
          return;
        }

        const {
          permissions,
          exp,
          'https://cmdc-api.cellcs.com/email': email
        } = jwtDecode<AccessToken>(`${webAuthResult?.accessToken}`);

        if (!email) {
          loginErrors = QueryString.stringify({
            type: AuthErrors.invalidLogin,
            hash: window.location.hash,
            error: 'email missing'
          });
          history.push(sendToError(loginErrors));
          return;
        }

        // Set the user auth in state
        try {
          dispatch(
            actions.setAuth({
              token: webAuthResult?.accessToken ?? '',
              exp,
              permissions
            })
          );
          axiosInstance
            .patch(`/users/login`, { isPendingConfirm: true })
            .then(({ data }: { data: User }) => {
              dispatch(userActions.loginUser(data));
              // Move here to avoid race condition of user getting to UI without necessary
              history.push(returndirect || dashboardUri);
            })
            .catch(e => {
              console.error(e);
              loginErrors = QueryString.stringify({
                type: AuthErrors.userError,
                hash: window.location.hash,
                error: e
              });
              history.push(sendToError(loginErrors));
              return;
            });
        } catch (e) {
          loginErrors = QueryString.stringify({
            type: AuthErrors.authError,
            error: e
          });
          history.push(sendToError(loginErrors));
          return;
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="login-logout">Logging in...</div>;
}

export default AuthCallback;

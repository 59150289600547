import * as React from 'react';
import * as MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

export interface AlertObjectProp {
  message: string;
  severity: MuiAlert.Color;
}

export interface AlertProps {
  className?: string;
  message?: string;
  handleClose: () => void;
  open: boolean;
  severity: MuiAlert.Color;
  position?: boolean;
  messages?: AlertObjectProp[];
}

const MatuiAlert = MuiAlert.default;

const Alert = ({
  className,
  message,
  handleClose,
  open,
  severity,
  position,
  messages
}: AlertProps): JSX.Element => {
  const cc = useStyles();

  return (
    <div>
      {messages && !_.isEmpty(messages) ? (
        messages.map((e: AlertObjectProp, i: number) => (
          <Snackbar
            id={`${i}`}
            key={i}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            style={{ marginBottom: `${i * 50}px` }}
          >
            <MatuiAlert
              icon={false}
              className={className}
              classes={{ root: cc.smallAlertRoot }}
              variant="filled"
              severity={e.severity}
              elevation={6}
              action={
                <Button
                  classes={{
                    sizeSmall: cc.smallAlertButtonSizeSmall,
                    root: cc.smallAlertButtonRoot
                  }}
                  color="inherit"
                  size="small"
                  disableRipple
                  onClick={handleClose}
                  startIcon={
                    <CloseIcon classes={{ root: cc.smallAlertCloseIconRoot }} />
                  }
                />
              }
            >
              {e.message}
            </MatuiAlert>
          </Snackbar>
        ))
      ) : message ? (
        <>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            className={position ? cc.position : cc.normal}
          >
            <MatuiAlert
              icon={false}
              className={className}
              classes={{ root: cc.smallAlertRoot }}
              variant="filled"
              severity={severity}
              elevation={6}
              action={
                <Button
                  classes={{
                    sizeSmall: cc.smallAlertButtonSizeSmall,
                    root: cc.smallAlertButtonRoot
                  }}
                  color="inherit"
                  size="small"
                  disableRipple
                  onClick={handleClose}
                  startIcon={
                    <CloseIcon classes={{ root: cc.smallAlertCloseIconRoot }} />
                  }
                />
              }
            >
              {message}
            </MatuiAlert>
          </Snackbar>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  smallAlertRoot: {
    padding: '12px 16px',
    height: '38px',
    lineHeight: '0.1rem'
  },
  smallAlertButtonRoot: {
    minWidth: '25px',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  smallAlertButtonSizeSmall: {
    width: '5px',
    height: 5
  },
  smallAlertCloseIconRoot: {
    fontSize: '15px !important'
  },
  position: {
    marginBottom: '50px'
  },
  normal: {
    marginBottom: 0
  }
}));

export default Alert;

import { SyncStatus } from './SyncStatus';

export enum SyncTypeEnum {
  Service = 'service',
  Status = 'status',
  CDR = 'cdr',
  Extension = 'extension'
}

export interface ApiSync {
  id: string;
  syncStatus: SyncStatus;
  tag?: string;
  reason?: string | null;
}

export interface SubSync extends ApiSync {
  type: SyncTypeEnum;
}

export interface ExtSync {
  id: string;
  syncStatus: SyncStatus;
  type: SyncTypeEnum;
  reason?: string | null;
}

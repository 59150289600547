import { query, successStatus } from 'constants/app';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import axiosInstance, { AxiosError } from 'utils/axiosProvider';

import {
  Bundle,
  InstallStatus,
  IdentiferType,
  EquipmentType,
  Router,
  Gateway,
  Powersource,
  PrefixValidationResponse
} from 'types';

interface GetAllBundleParams {
  queryKey: [
    __0: string,
    locationId: string,
    pageIndex: number,
    pageSize: number
  ];
}

export interface PagedBundles {
  bundles: Bundle[];
  total: number;
}

interface UpdateStatusObject {
  adminStatusId: string;
  bundleId?: string;
}
interface GetBundle {
  queryKey: [__0: string, id: string];
}

interface BundleDelete {
  dids: number;
  sims: number;
}

const createBundle = async (bundle: Partial<Bundle>): Promise<string> => {
  const { data } = await axiosInstance.post(`/equipment-bundles`, bundle);
  return data.id;
};

const useCreateBundle = (): UseMutationResult<
  string,
  AxiosError,
  Partial<Bundle>
> => {
  const queryClient = useQueryClient();
  const mutateCreateUser = useMutation<string, AxiosError, Partial<Bundle>>(
    createBundle,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.equipmentBundle]);
      }
    }
  );

  return mutateCreateUser;
};

const updateAdminStatus = async (
  updatedData: UpdateStatusObject
): Promise<void> => {
  const canonAdminStatus = {
    adminStatusId: updatedData?.adminStatusId
  };
  await axiosInstance.patch(
    `/equipment-bundles/${updatedData.bundleId}`,
    canonAdminStatus
  );
};

const useUpdateAdminStatus = (): UseMutationResult<
  void,
  AxiosError,
  UpdateStatusObject
> => {
  const queryClient = useQueryClient();

  const mutatePostAdminStatus = useMutation<
    void,
    AxiosError,
    UpdateStatusObject
  >(updateAdminStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutatePostAdminStatus;
};

const getBundles = async ({ queryKey }: GetAllBundleParams) => {
  const [, locationId, pageIndex, pageSize] = queryKey;

  const { data } = await axiosInstance.get('/equipment-bundles', {
    params: {
      filter: `account.id|eq|${locationId}`,
      page: pageIndex,
      limit: pageSize
    }
  });

  const bundles: Bundle[] = data.data;
  return {
    bundles,
    total: Number(data.total)
  };
};

const useBundles = (
  locationId: string,
  pageIndex = 1,
  pageSize = 10
): UseQueryResult<PagedBundles, AxiosError> => {
  return useQuery<PagedBundles, AxiosError>(
    [query.equipmentBundle, locationId, pageIndex, pageSize],
    getBundles
  );
};

const getBundleByID = async ({ queryKey }: GetBundle): Promise<Bundle> => {
  const [, id] = queryKey;
  const { data } = await axiosInstance.get(`/equipment-bundles/${id}`);
  return data;
};

const useBundleByID = (id: string): UseQueryResult<Bundle, Error> => {
  return useQuery<Bundle, AxiosError>(
    [query.equipmentBundle, id],
    getBundleByID
  );
};

const getBundleDependency = async ({
  queryKey
}: GetAllBundleParams): Promise<BundleDelete> => {
  const [, id] = queryKey;
  const { data } = await axiosInstance.get(
    `/equipment-bundles/${id}/dependencies/count`
  );
  return data;
};

const useBundleDeleteDependency = (
  id: string
): UseQueryResult<BundleDelete, Error> => {
  return useQuery<BundleDelete, Error>(
    [query.equipmentDelete, id],
    getBundleDependency,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

const deleteBundle = async (id: string): Promise<void> => {
  await axiosInstance.delete(`/equipment-bundles/${id}`);
};

const useDeleteBundle = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteBundle = useMutation<void, AxiosError, any>(deleteBundle, {
    onSettled: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutateDeleteBundle;
};

const getAdminStatuses = async (): Promise<InstallStatus[]> => {
  const { data } = await axiosInstance.get(`/equipment-administration-status`);
  return data?.data;
};

const useAdminStatuses = (): UseQueryResult<InstallStatus[], Error> => {
  return useQuery<InstallStatus[], AxiosError>(
    [query.adminStatus],
    getAdminStatuses
  );
};

const patchBundleRouter = async (router: Partial<Router>): Promise<void> => {
  await axiosInstance.patch(`/routers/${router.id}`, router);
};

const useUpdateBundleRouter = (): UseMutationResult<
  void,
  AxiosError,
  Partial<Router>
> => {
  const queryClient = useQueryClient();
  const mutatePatchBundleRouter = useMutation<
    void,
    AxiosError,
    Partial<Router>
  >(patchBundleRouter, {
    onSuccess: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutatePatchBundleRouter;
};

const patchBundleGateway = async (gateway: Partial<Gateway>): Promise<void> => {
  await axiosInstance.patch(`/gateways/${gateway.id}`, gateway);
};
const useUpdateBundleGateway = (): UseMutationResult<
  void,
  AxiosError,
  Partial<Gateway>
> => {
  const queryClient = useQueryClient();
  const mutatePatchBundleGateway = useMutation<
    void,
    AxiosError,
    Partial<Gateway>
  >(patchBundleGateway, {
    onSuccess: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutatePatchBundleGateway;
};

const patchBundlePowersource = async (
  powersource: Partial<Powersource>
): Promise<void> => {
  await axiosInstance.patch(`/powersources/${powersource.id}`, powersource);
};

const useUpdateBundlePowersource = (): UseMutationResult<
  void,
  AxiosError,
  Partial<Powersource>
> => {
  const queryClient = useQueryClient();
  const mutatePatchBundlePowersource = useMutation<
    void,
    AxiosError,
    Partial<Powersource>
  >(patchBundlePowersource, {
    onSuccess: () => {
      queryClient.invalidateQueries([query.equipmentBundle]);
    }
  });

  return mutatePatchBundlePowersource;
};

// Check if router, gateway, or powersource field already exists
const doesItExist = async (
  equipment: EquipmentType,
  field: IdentiferType,
  value: string,
  id?: string
): Promise<boolean> => {
  try {
    const apiCall = id
      ? `/${equipment}/field/${field}/value/${value}?id=${id}`
      : `/${equipment}/field/${field}/value/${value}`;

    const response = await axiosInstance.get(apiCall);
    return response.status !== successStatus;
  } catch (error) {
    return true;
  }
};

const gatewayDomainExists = async (value: string) => {
  const { data } = await axiosInstance.get(`/gateways?filter=${value}`);
  return data.data.length > 0;
};

const isMacPrefixValid = async (
  brand: string,
  value: string
): Promise<PrefixValidationResponse> => {
  const { data } = await axiosInstance.get(
    `/brand-mac-prefixes/${brand}/mac/${value}`
  );
  return data;
};

const isSerialPrefixValid = async (
  model: string,
  value: string
): Promise<PrefixValidationResponse> => {
  const { data } = await axiosInstance.get(
    `/brand-mac-prefixes/${model}/serial/${value.toLowerCase()}`
  );
  return data;
};

export {
  useAdminStatuses,
  useBundleByID,
  useBundles,
  useCreateBundle,
  useDeleteBundle,
  useUpdateAdminStatus,
  useUpdateBundleGateway,
  useUpdateBundlePowersource,
  useUpdateBundleRouter,
  useBundleDeleteDependency,
  getBundleDependency,
  doesItExist,
  gatewayDomainExists,
  isMacPrefixValid,
  isSerialPrefixValid
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog as DesignDialog, Heading } from 'design';
import { selectDialog } from 'app/layouts/slice/selectors';
import { useDashboardSlice } from 'app/layouts';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, makeStyles, Theme } from '@material-ui/core';

const { Suspense } = React;

/**
 * This component's rendering is ENTIRELY dependant on redux state. Please reference
 * `app/layouts/slice/index` for the "Dashboard Slice" which controls this component.
 * @returns {JSX.Element}
 */
const Dialog = (): JSX.Element => {
  const dispatch = useDispatch();
  const dialog = useSelector(selectDialog);
  const { actions } = useDashboardSlice();

  const cc = useStyles();

  // This Dialog can only consume dynamic components from its parent dir
  const DynamicComponent = React.lazy(
    () => import(`../${dialog.lazyComponent}`)
  );
  const handleClose = () => {
    dispatch(actions.hideDialog());
  };
  return dialog?.open ? (
    <DesignDialog
      open={dialog.open}
      handleClose={(_, reason) => {
        if (
          dialog.disableBackdropClick &&
          (reason === 'backdropClick' || reason === 'escapeKeyDown')
        ) {
          return;
        }
        handleClose();
      }}
      size={dialog.size}
    >
      <>
        {!dialog.title || (
          <div className={cc.headerWrapper}>
            <Heading variant="h5">{dialog.title}</Heading>
            {!dialog.closeable || (
              <IconButton
                onClick={handleClose}
                className={cc.closeButton}
                aria-label="close dialog"
              >
                <CloseIcon
                  color="action"
                  style={{ color: '#A9B3B7' }}
                  fontSize="small"
                />
              </IconButton>
            )}
          </div>
        )}
        <Suspense fallback={<>loading..</>}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <DynamicComponent {...dialog.lazyComponentProps} />
        </Suspense>
      </>
    </DesignDialog>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '1.285rem',
    lineHeight: '1.285rem',
    marginTop: '2px',
    marginBottom: theme.spacing(2)
  },
  closeButton: {
    padding: '3px'
  }
}));

export default Dialog;

/**
 *
 * Logout
 *
 */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { webAuth } from 'utils/auth';
import { logoutState, useAuthSlice } from 'app/slice';
import Cookies from 'js-cookie';
import { clearCookies } from 'api/useCurrentUser';
import { userActions } from 'store/userSlice';

function Logout(): JSX.Element {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();

  const logoutClearly = React.useCallback(async () => {
    try {
      await clearCookies();
    } catch (e) {
      console.error(e);
    }
    dispatch(actions.setAuth(logoutState));
    dispatch(userActions.logoutUser());
    const cookieNames = Object.keys(Cookies.get());
    // Loop through the cookie names and remove each cookie
    cookieNames.forEach(cookieName => {
      Cookies.remove(cookieName);
    });
    webAuth.logout({
      returnTo: `${window.location.origin}`
    });
  }, [dispatch, actions]);

  React.useEffect(() => {
    logoutClearly();
  });

  return <div className="login-logout">Logging out...</div>;
}

export default Logout;

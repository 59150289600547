import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { useDashboardSlice } from 'app/layouts';
import { ActionButton } from 'design';
import * as React from 'react';
import { useDispatch } from 'react-redux';
// TODO: Refactor to use current user from current use store
import useCurrentUser from 'api/useCurrentUser';
import { PagedLocations } from 'api/useLocations';
import { AxiosError } from 'axios';
import { RefetchOptions, QueryObserverResult } from 'react-query';

interface LocationActionProp {
  id: string;
  locationMetricsUrl?: string;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<PagedLocations, AxiosError<any>>>;
}
const LocationAction = ({
  id,
  locationMetricsUrl,
  refetch
}: LocationActionProp): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin || currentUser?.isOrgAdmin)
      setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'Clone',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Clone Location',
            lazyComponent: 'LocationClone',
            size: 'large',
            lazyComponentProps: {
              id
            }
          })
        );
      }
    },
    {
      label: 'Delete',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LocationDelete',
            size: 'large',
            lazyComponentProps: {
              id,
              refetch
            }
          })
        );
      }
    },
    {
      label: 'Edit',
      isVisible: currentUser?.isSuperAdmin || currentUser?.isOrgAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Edit Location',
            lazyComponent: 'LocationUpdate',
            size: 'xlarge',
            lazyComponentProps: {
              id,
              refetch
            }
          })
        );
      }
    },
    {
      label: 'End Service',
      // MS-6862 Hide End Service feature while the business figures out new
      // processes for ending services.
      isVisible: false,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LocationEndService',
            size: 'large',
            lazyComponentProps: {
              id
            }
          })
        );
      }
    },
    {
      label: 'Signal Link',
      isVisible: currentUser?.isSuperAdmin && locationMetricsUrl,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Signal Link',
            lazyComponent: 'SignalLink',
            size: 'large',
            lazyComponentProps: {
              url: locationMetricsUrl
            }
          })
        );
      }
    }
  ];

  return (
    <>
      <div className={cc.iconButton}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: cc.paper, list: cc.list }}
        className={cc.menuBar}
      >
        {menuItems.map(({ label, action, isVisible }, i: number) => {
          return (
            <div key={i}>
              {isVisible && (
                <MenuItem
                  button
                  onClick={() => {
                    action();
                    if (handleMenuClose) {
                      handleMenuClose();
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 150
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  },
  menuBar: {
    marginLeft: '20px'
  },
  iconButton: {
    float: 'right'
  }
}));
export default LocationAction;

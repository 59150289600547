import * as Yup from 'yup';

import {
  routersValidatorArray,
  gatewaysValidatorArray,
  powersourcesValidatorArray
} from 'utils/schemaValidators/bundleValidators';
import { validationErrorMessages } from 'constants/app';

const Schema = (locationId: string) => {
  const schema = Yup.object().shape({
    bundleCatalogId: Yup.string().required(
      validationErrorMessages.bundleCatalogRequired
    ),
    ownerId: Yup.string().required(validationErrorMessages.ownerRequired),
    adminStatusId: Yup.string().required(
      validationErrorMessages.installStatusRequired
    ),
    routers: routersValidatorArray(),
    gateways: gatewaysValidatorArray(locationId),
    powersources: powersourcesValidatorArray()
  });
  return schema;
};

export default Schema;

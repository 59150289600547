import { useQuery, UseQueryResult } from 'react-query';
import { query } from 'constants/app';
import axios, { AxiosError, AxiosResponse } from '../utils/axiosProvider';

export interface StatusData {
  total: number;
  online: number;
  offline: number;
  trouble: number;
}

interface GetLinesParams {
  queryKey: [__0: string];
}

const getLineStatusCnt = async ({ queryKey }: GetLinesParams) => {
  const { data }: AxiosResponse = await axios.get('/dids/status/count');
  const { total, online, offline, trouble, none } = data;

  return {
    total: Number(total),
    online: Number(online),
    offline: Number(offline),
    trouble: Number(trouble),
    none: Number(none)
  };
};

const useLinesStatusCnt = (): UseQueryResult<StatusData, AxiosError> => {
  return useQuery<StatusData, AxiosError>(
    [query.locationLinesStatus],
    getLineStatusCnt
  );
};

export default useLinesStatusCnt;

import { MARKETSPARK_ORG_NAME } from 'constants/app';
import { omit } from 'lodash';
import { CurrentUser, Organization, User } from 'types';
import { RoleName } from '../types/AuthRoles';

export const userPerms = (user: User) => {
  const isSetup: Record<string, boolean> = {
    isSuperAdmin: user?.primaryRole?.name === RoleName.superAdmin,
    isOrgAdmin: user?.primaryRole?.name === RoleName.orgAdmin,
    isLocationAdmin: user?.primaryRole?.name === RoleName.locationAdmin,
    isUser: user?.primaryRole?.name === RoleName.user,
    isMSOrg: user?.organization?.name === MARKETSPARK_ORG_NAME
  };

  isSetup.isAdminOrUser =
    isSetup.isOrgAdmin || isSetup.isLocationAdmin || isSetup.isUser;

  return isSetup;
};

export const userToCurrentUser = (
  user: User,
  sourceOrg?: Organization
): CurrentUser => {
  const currentUser: CurrentUser = {
    ...user,
    ...userPerms(user)
  };

  if (sourceOrg) {
    currentUser.sourceOrg = sourceOrg;
  }

  return currentUser;
};

// This is so we don't pollute our local storage with these computed state properties
export const cleanIsms = (user: CurrentUser): CurrentUser => {
  return omit(user, [
    'isSuperAdmin',
    'isOrgAdmin',
    'isUser',
    'isMSOrg',
    'isAdminOrUser'
  ]) as CurrentUser;
};

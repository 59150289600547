import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

export interface CardNavButonProps {
  // active?: number;
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

const CardNavButon = ({
  onClick,
  disabled,
  children,
  className = ''
}: CardNavButonProps): JSX.Element => {
  const cc = useStyles();

  const arrowButtonClass = clsx(cc.cardNavButton, {
    [cc.custtomButtonDisabled]: disabled,
    [className]: className
  });

  return (
    <ButtonBase
      type="button"
      disabled={disabled}
      className={arrowButtonClass}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardNavButton: {
    border: 0,
    width: 26,
    height: 24,
    textAlign: 'center',
    background: 'none',
    cursor: 'pointer',
    transitionProperty: 'hover',
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    transitionTimingFunction: theme.transitions.easing.easeOut,
    padding: theme.spacing(0.25, 0, 0, 0),
    fontSize: theme.typography.body2.fontSize,
    '& > svg': {
      color: theme.palette.grey['500'],
      '&:active, &:hover': {
        color: theme.palette.common.black,
        backroundColor: 'none'
      },
      transitionProperty: 'hover',
      transitionDuration: `${theme.transitions.duration.short}ms`,
      transitionTimingFunction: theme.transitions.easing.easeOut
    }
  },
  custtomButtonDisabled: {
    '& > svg': {
      color: theme.palette.grey['50'],
      '&:active, &:hover': {
        color: theme.palette.grey['50'],
        backroundColor: 'none'
      }
    }
  }
}));

export default CardNavButon;

import { CreateEditModalDropDownInputs } from 'types/HardwareCatalogTypes';
import {
  CreateEditModalTextInputs,
  CreateEditModalRequiredInputs
} from '../../../../types';

export const routerTextInputs: CreateEditModalTextInputs[] = [
  {
    value: 'brand',
    label: 'Brand'
  },
  {
    value: 'model',
    label: 'Model Name'
  },
  {
    value: 'power',
    label: 'Power / Amperage'
  }
];

export const routerDropDownInputs: CreateEditModalDropDownInputs[] = [
  {
    value: 'lanPorts',
    label: 'LAN',
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' }
    ]
  },
  {
    value: 'wanPorts',
    label: 'WAN',
    options: [
      { label: '0', value: '0' },
      { label: '1', value: '1' }
    ]
  },
  {
    value: 'simCount',
    label: 'SIM',
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' }
    ]
  }
];

export const routerRequiredInputs: CreateEditModalRequiredInputs[] = [
  {
    value: 'ipAddressRequired',
    label: 'IP Address'
  },
  {
    value: 'userNameRequired',
    label: 'User Name'
  },
  {
    value: 'passwordRequired',
    label: 'Password'
  },
  {
    value: 'serialNumberRequired',
    label: 'Serial Number'
  },
  {
    value: 'macAddressRequired',
    label: 'MAC Address'
  },
  {
    value: 'imeiRequired',
    label: 'IMEI'
  }
];

export const gatewayTextInputs: CreateEditModalTextInputs[] = [
  {
    value: 'brand',
    label: 'Brand'
  },
  {
    value: 'model',
    label: 'Model Name'
  },
  {
    value: 'power',
    label: 'Power / Amperage'
  }
];

export const gatewayDropDownInputs: CreateEditModalDropDownInputs[] = [
  {
    value: 'fxoPorts',
    label: 'FXO Ports',
    options: [
      { label: '2', value: '2' },
      { label: '4', value: '4' },
      { label: '8', value: '8' }
    ]
  }
];

export const gatewayRequiredInputs: CreateEditModalRequiredInputs[] = [
  {
    value: 'ipAddressRequired',
    label: 'IP Address'
  },
  {
    value: 'serialNumberRequired',
    label: 'Serial Number'
  },
  {
    value: 'macAddressRequired',
    label: 'MAC Address'
  },
  {
    value: 'imeiRequired',
    label: 'IMEI'
  }
];

export const powerSourceTextInputs: CreateEditModalTextInputs[] = [
  {
    value: 'brand',
    label: 'Brand'
  },
  {
    value: 'model',
    label: 'Model Name'
  },
  {
    value: 'power',
    label: 'Power / Amperage'
  }
];

export const powerSourceDropDownInputs: CreateEditModalDropDownInputs[] = [
  {
    value: 'maxBatteryCount',
    label: 'Batteries',
    options: [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' }
    ]
  }
];

export const powerSourceRequiredInputs: CreateEditModalRequiredInputs[] = [
  {
    value: 'serialNumberRequired',
    label: 'Serial Number'
  },
  {
    value: 'quantityRequired',
    label: 'Quantity'
  }
];

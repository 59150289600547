/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import MaskedInput from 'react-text-mask';

/*
How this works:
MaskedInput requires a regex pattern to mask the inputs.
For mac, mask regex pattern is same for all mac addresses so we can use the regex pattern directly.
For Ip, the pattern differs as ip should have only 4 parts (ipv4) and each part should be between 0 to 255.
mask function will split the values into chunks by '.'
Next it will check if the chunk is greater than 255. if it is greater, it will add '.' to the regex pattern.
Pipe function is used to do the post validation to make sure that the values are in the correct length (only 4 parts and each part is not more than 255)
*/

const inputMaskProps = {
  ip: {
    mask: (val: string) => {
      const value = val.replace(/[^\d.]/g, '');
      const mask = Array(value.length).fill(/[\d.]/);
      const chunks = value.split('.');
      for (let i = 0; i < 4; ++i) {
        const chunk = chunks[i] || '';
        if (255 % +chunk === 255) {
          mask[value.length - 1] = '.';
          mask[value.length] = /[\d.]/;
        }
      }
      return mask;
    },
    pipe: (value: string) => {
      if (value === '.' || value.endsWith('..')) return false;
      const parts = value.split('.');
      if (
        parts.length > 4 ||
        parts.some(
          part => part === '00' || Number(part) < 0 || Number(part) > 255
        )
      ) {
        return false;
      }
      return value;
    }
  },
  mac: {
    mask: [
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/,
      ':',
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/,
      ':',
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/,
      ':',
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/,
      ':',
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/,
      ':',
      /[0-9A-Fa-f]/,
      /[0-9A-Fa-f]/
    ]
  }
};

const IpInputMask = ({ inputRef, ...other }: any) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...inputMaskProps.ip}
    />
  );
};
const MacInputMask = ({ inputRef, ...other }: any) => {
  return (
    <MaskedInput
      {...other}
      guide={false}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...inputMaskProps.mac}
    />
  );
};

export { IpInputMask, MacInputMask };

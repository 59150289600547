import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useField } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

interface AutocompleteDropdownProps {
  id: string;
  name: string;
  options: any;
  setValueDefaultProps: any;
  blurOnSelected?: boolean;
}

const AutocompleteDropdown = ({
  id,
  options,
  setValueDefaultProps,
  blurOnSelected = true
}: AutocompleteDropdownProps): JSX.Element => {
  const cc = useStyles();
  const [open, setOpen] = useState(false);
  const [field, , helpers] = useField(id);

  return (
    <Autocomplete
      data-testid="autocomplete"
      classes={{
        input: cc.input,
        clearIndicatorDirty: cc.clearIndicatorDirty,
        popupIndicator: cc.popupIndicator,
        option: cc.dropDownList
      }}
      id={id}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        helpers.setValue(value || setValueDefaultProps);
      }}
      getOptionSelected={(option, value) =>
        // OR returns true if user clears input
        option.name === value.name || value.name === ''
      }
      getOptionLabel={option => option.name || ''}
      value={field.value}
      PaperComponent={({ children }) => (
        <Paper classes={{ root: cc.paper }}>{children}</Paper>
      )}
      blurOnSelect={blurOnSelected}
      renderInput={params => (
        <TextField
          data-testid="autocompleteTextField"
          classes={{ root: cc.textFieldRoot }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          size="small"
          id={id}
          name={id}
          variant="outlined"
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  textFieldRoot: {
    border: '2px solid #fff',
    width: '300px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'transparent !important'
      }
    }
  },
  input: {
    color: theme.palette.common.white,
    fontSize: 16,
    height: 15
  },
  clearIndicatorDirty: {
    paddingRight: 10
  },
  popupIndicator: {
    border: '2px solid #fff',
    width: 40,
    marginRight: -11,
    marginTop: -5,
    paddingBottom: 2,
    borderRadius: '0px !important'
  },
  paper: {
    background: theme.palette.common.black,
    color: '#fff',
    border: '2px solid #fff'
  },
  dropDownList: {
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#fff !important',
      color: theme.palette.common.black
    }
  }
}));

export default AutocompleteDropdown;

import React from 'react';
import { makeStyles, Theme, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export interface BadgeProps {
  color: 'primary' | 'success' | 'error' | 'warning' | 'info' | 'secondary';
  children: React.ReactNode;
  active?: boolean;
  handleClick?: () => void;
  rightLabel?: string;
  isLoading?: boolean;
  className?: string;
}

const Badge = ({
  color,
  handleClick,
  rightLabel,
  children,
  active,
  isLoading
}: BadgeProps): JSX.Element => {
  const cc = useStyles({ color: color === 'info' ? 'secondary' : color });

  return !isLoading ? (
    <Button
      type="button"
      className={`${cc.badgeRoot} ${color} ${active ? 'active' : ''}`}
      onClick={() => handleClick && handleClick()}
      disableRipple={active}
    >
      <>
        <div className="chip">{children}</div>
        {!rightLabel || <div className={cc.rightLabelRoot}>{rightLabel}</div>}
      </>
    </Button>
  ) : (
    <Button
      type="button"
      className={`${cc.badgeRoot} ${color} ${active ? 'active' : ''}`}
      onClick={() => handleClick && handleClick()}
      disableRipple={!handleClick}
    >
      <>
        <Skeleton component="div" width={46} className="chip" variant="rect">
          ...
        </Skeleton>
        {!rightLabel || <div className={cc.rightLabelRoot}>{rightLabel}</div>}
      </>
    </Button>
  );
};

const useStyles = makeStyles<
  Theme,
  Partial<BadgeProps>,
  'badgeRoot' | 'rightLabelRoot'
>((theme: Theme) => ({
  badgeRoot: {
    border: 0,
    padding: 0,
    margin: 0,
    background: 'none',
    textTransform: 'none',
    minWidth: 0,
    '&:hover': {
      background: 'none'
    },
    '& div.chip': {
      display: 'inline-block',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 0.75),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey['500'],
      backgroundColor: theme.palette.info.main,
      transitionProperty: 'background-color, color',
      transitionDuration: `${theme.transitions.duration.standard}ms`,
      transitionTimingFunction: theme.transitions.easing.easeOut
    },
    '&.active': {
      cursor: 'default'
    },
    '&.primary': {
      '& div.chip': {
        color: theme.palette.primary.main
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    '&.success': {
      '& div.chip': {
        color: theme.palette.success.main
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText
      }
    },

    '&.error': {
      '& div.chip': {
        color: theme.palette.error.main
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
      }
    },
    '&.warning': {
      '& div.chip': {
        color: theme.palette.warning.main
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
      }
    },
    '&.info': {
      '& div.chip': {
        color: theme.palette.secondary.main
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
      }
    },
    '&.secondary': {
      '& div.chip': {
        color: theme.palette.grey['500']
      },
      '&:hover div.chip, &.active div.chip': {
        backgroundColor: theme.palette.grey['500'],
        color: theme.palette.secondary.contrastText
      }
    }
  },
  rightLabelRoot: {
    display: 'inline-block',
    padding: theme.spacing(0, 1)
  }
}));

export default Badge;

import { Entity } from './Entity';
import { Location } from './Location';

export interface CDR extends Entity {
  timestamp: string;
  calling: string; // from
  called: string; // to
  duration: number;
  direction: string;
  count?: string;
  minutes?: string;
  location?: Location;
  locationName?: string;
  locationId?: string;
  orgId?: string;
  number?: string;
}

export enum CallDirection {
  All = 'all',
  Inbound = 'inbound',
  Outbound = 'outbound'
}

export enum CDRDaysAgo {
  Day = 1,
  Week = 7,
  Month = 30
}

export enum CDRStatisticType {
  Calls = 'calls',
  Minutes = 'minutes'
}

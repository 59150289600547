import React from 'react';
import Typography from '@material-ui/core/Typography';

export interface HeadingProps {
  className?: string;
  children: React.Component | string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined;
}

const Heading = ({
  children,
  variant = 'h1',
  className
}: HeadingProps): JSX.Element => {
  return (
    <Typography className={className} variant={variant}>
      {children}
    </Typography>
  );
};

export default Heading;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';

import { ReactComponent as PowerAdapter } from '../../../assets/img/poweradapter.svg';

const PowerStatusIcon = ({
  isOnBackup
}: {
  isOnBackup?: boolean;
}): JSX.Element => {
  const cc = useStyles();
  let powerIcon = <PowerAdapter data-testid="power plug" />;
  let powerStatus = 'AC';

  if (isOnBackup) {
    powerIcon = (
      <BatteryChargingFullIcon
        fontSize="large"
        className={cc.normal}
        titleAccess="battery charging full"
      />
    );
    powerStatus = 'DC';
  }

  return (
    <div className={cc.batterySet}>
      {powerIcon}
      <p className={cc.stateTitle}>Source</p>
      <p className={cc.state}>{powerStatus}</p>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  normal: {
    color: `${theme.palette.success.main} !important`
  },
  stateTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '5px 0 -10px',
    color: theme.palette.grey[500]
  },
  state: {
    fontSize: '14px'
  },
  batterySet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default PowerStatusIcon;

import * as React from 'react';
import { pageSizeOptions, StatusToColor } from 'constants/app';
import { Dot, ColumnProps, TablePaginated, Text } from 'design';
import { Equipment } from 'types';
import EquipmentPortalLink from '../EquipmentPortalLink/EquipmentPortalLink';
import { makeStyles, Typography } from '@material-ui/core';
import useSort from 'hooks/useSort';
import { Helmet } from 'react-helmet-async';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import useEquipment, { PagedEquipment } from 'api/useEquipment';
import { useBundles, PagedBundles } from 'api/useBundle';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import LaunchIcon from '@material-ui/icons/Launch';
import LocationEquipmentAction from '../LocationEquipmentAction/LocationEquipmentAction';
import AddHardware from './components/AddHardware';
import BundleTables from '../EquipmentBundle/BundleTables';

export interface LocationEquipmentsProps {
  locationId: string;
  erpLocationId: string | null;
  locationDomain?: string;
}

const LocationEquipments = ({
  locationId,
  erpLocationId,
  locationDomain
}: LocationEquipmentsProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [andFilter] = React.useState('');
  const {
    data: equipmentData,
    isLoading: isLoadingEquipment,
    isError: isErrorEquipment,
    error: errorEquipment
  } = useEquipment({
    filter: locationId,
    statusFilter: andFilter,
    sort,
    pageIndex,
    pageSize
  });

  const equipment = React.useMemo((): PagedEquipment => {
    return {
      equipment: equipmentData?.equipment ?? [],
      total: equipmentData?.total ?? 0
    };
  }, [equipmentData]);

  const {
    data: bundlesData,
    isLoading: isLoadingBundles,
    isError: isErrorBundles,
    error: errorBundles
  } = useBundles(locationId, 1, 10);

  const bundles = React.useMemo((): PagedBundles => {
    return {
      bundles: bundlesData?.bundles ?? [],
      total: bundlesData?.total ?? 0
    };
  }, [bundlesData]);

  if (isErrorEquipment && errorEquipment) {
    throw new Error(`${errorEquipment.message} Failed to fetch Equipments!`);
  }
  if (isErrorBundles && errorBundles) {
    throw new Error(`${errorBundles.message} Failed to fetch Bundles!`);
  }

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'status',
        label: '',
        align: 'center',
        render: ({ status }: Equipment) => (
          <Dot color={status?.name ? StatusToColor[status?.name] : null} />
        ),
        cellWidth: 43,
        padding: 'none',
        verticalAlign: 'top',
        hidden: !currentUser?.isSuperAdmin
      },
      {
        accessor: 'expandIcon',
        label: '',
        align: 'center',
        padding: 'none',
        verticalAlign: 'top',
        cellWidth: 43,
        resizable: true,
        render: ({ id, catalogItem }: Equipment) => (
          <IconButton
            data-testid="expandButton"
            className={cc.expandButton}
            onClick={() => {
              dispatch(
                actions.showDialog({
                  open: true,
                  title: '',
                  lazyComponent: 'LocationEquipmentExpand',
                  size: 'large',
                  lazyComponentProps: {
                    id,
                    catalogItem
                  }
                })
              );
            }}
          >
            <LaunchIcon fontSize="small" className={cc.linkIcon} />
          </IconButton>
        )
      },
      {
        accessor: 'catalogItem.name',
        label: 'Name',
        sortable: true,
        verticalAlign: 'top',
        resizable: true
      },
      {
        accessor: 'installStatus.name',
        label: 'Install Status',
        sortable: true,
        verticalAlign: 'top',
        resizable: true
      },
      {
        accessor: 'serialNumber',
        label: 'Serial Number',
        sortable: true,
        verticalAlign: 'top',
        render: (equipmentRow: Equipment) => (
          <EquipmentPortalLink
            brand={equipmentRow.catalogItem.brand}
            group={equipmentRow.catalogItem.group}
            serialNumber={equipmentRow.serialNumber}
          />
        ),
        resizable: true
      },
      {
        accessor: 'mac',
        label: 'MAC Address',
        sortable: true,
        verticalAlign: 'top',
        resizable: true
      },
      {
        accessor: 'iccId',
        label: 'ICCID',
        render: ({ sims }: Equipment) => (
          <div>
            {sims?.length > 0 ? (
              <>
                {sims.map((sim, i) => {
                  return <Text key={i}>{sim?.iccId}</Text>;
                })}
              </>
            ) : (
              <Text>-</Text>
            )}
          </div>
        ),
        resizable: true
      },
      {
        accessor: 'carrier',
        label: 'Carrier',
        render: ({ sims }: Equipment) => (
          <div>
            {sims?.length > 0 ? (
              <>
                {sims.map((sim, i) => {
                  return (
                    <Text key={i}>
                      {sim?.carrier ? sim?.carrier?.name : '-'}
                    </Text>
                  );
                })}
              </>
            ) : (
              <Text>-</Text>
            )}
          </div>
        ),
        resizable: true
      },
      {
        accessor: 'mdn',
        label: 'MDN',
        render: ({ sims }: Equipment) => (
          <div>
            {sims?.length > 0 ? (
              <>
                {sims.map((sim, i) => {
                  return <Text key={i}>{sim?.mdn ? sim?.mdn : '-'}</Text>;
                })}
              </>
            ) : (
              <Text>-</Text>
            )}
          </div>
        ),
        resizable: true
      },
      {
        accessor: 'simStatus',
        label: 'SIM Status',
        render: ({ sims }: Equipment) => (
          <div>
            {sims?.length > 0 ? (
              <>
                {sims.map((sim, i) => {
                  return <Text key={i}>{sim?.simStatus?.name}</Text>;
                })}
              </>
            ) : (
              <Text>-</Text>
            )}
          </div>
        ),
        resizable: true
      },
      {
        accessor: '',
        label: '',
        verticalAlign: 'top',
        render: ({ id, catalogItem, serialNumber, domain, mac }: Equipment) => (
          <div>
            <LocationEquipmentAction
              id={id}
              catalogItem={catalogItem}
              serialNumber={serialNumber}
              domain={domain}
              mac={mac}
            />
          </div>
        ),
        hidden: !currentUser?.isSuperAdmin,
        sortable: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  if (isLoadingEquipment && isLoadingBundles) return <></>;

  return (
    <>
      <Helmet>
        <title> Equipment </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className="add-hardware-wrapper">
        {currentUser?.isSuperAdmin && (
          <AddHardware
            locationId={locationId}
            erpLocationId={erpLocationId}
            domain={locationDomain}
          />
        )}
        {(() => {
          if (equipment?.total === 0 && bundles?.total === 0) {
            return (
              <div className="empty-card">
                <Typography className="card-text">
                  You have no hardware at this location.
                </Typography>
              </div>
            );
          } else {
            return <></>;
          }
        })()}
        {(() => {
          if (equipment?.total > 0) {
            return (
              <TablePaginated
                id="Equipment"
                rows={equipment.equipment}
                sort={sort}
                total={equipment.total}
                columns={columns}
                handlePageSize={setPageSize}
                handlePageNav={setPageIndex}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                handleSort={setSort}
                isLoading={false}
              />
            );
          } else {
            return <></>;
          }
        })()}
        {(() => {
          if (bundles?.total > 0) {
            return (
              <div className="bundle-table-wrapper">
                <BundleTables bundlesData={bundles} locationId={locationId} />
              </div>
            );
          } else {
            return <></>;
          }
        })()}
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  expandButton: {
    padding: 0,
    marginTop: '2px'
  },
  linkIcon: {
    color: '#A9B3B7',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '-8px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
export default LocationEquipments;

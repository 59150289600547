import React, { SyntheticEvent } from 'react';
import { FormikProps } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

import {
  CancelSubmitFooter,
  Dialog,
  DialogCloseIcon,
  Dropdown,
  DropdownOption,
  Text,
  TextInput
} from 'design';
import {
  CreateEditModalTextInputs,
  CreateEditModalDropDownInputs,
  CreateEditModalRequiredInputs,
  GatewayFormikValues,
  PowerSourceFormikValues,
  RouterFormikValues
} from 'types';
import styles from './HardwareTables.module.scss';

interface HardwareCreateEditDialogProps {
  title: 'Router' | 'Gateway' | 'Power Source';
  type: 'Create' | 'Edit';
  formik:
    | FormikProps<RouterFormikValues>
    | FormikProps<GatewayFormikValues>
    | FormikProps<PowerSourceFormikValues>;
  textInputs: CreateEditModalTextInputs[];
  dropDownInputs: CreateEditModalDropDownInputs[];
  requiredInputs: CreateEditModalRequiredInputs[];
  handleClose: () => void;
}

const HardwareCreateEditDialog = ({
  title,
  type,
  formik,
  textInputs,
  dropDownInputs,
  requiredInputs,
  handleClose
}: HardwareCreateEditDialogProps): JSX.Element => {
  const textInputOnchange = (e: SyntheticEvent) => {
    formik.setFieldTouched(e.currentTarget.id, true);
    formik.handleChange(e);
  };

  const dropdownOnChange = (id: string, option: DropdownOption) => {
    formik.setFieldTouched(id, true);
    formik.setFieldValue(id, option.value);
  };

  return (
    <Dialog open={true} size="xlarge" handleClose={handleClose}>
      <div className={styles.modalHeaderWrapperMarginBtm}>
        <Text variant="h5">{`${type} ${title}`}</Text>
        <DialogCloseIcon onReset={handleClose} />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.leftContainer}>
            {textInputs.map(input => {
              return (
                <Grid
                  item
                  xs={12}
                  key={input.value}
                  className={styles.rowSpacing}
                >
                  <TextInput
                    id={input.value}
                    label={input.label}
                    name={input.value}
                    type="text"
                    size="medium"
                    validationMessage={formik.errors[input.value]}
                    value={formik.values[input.value]}
                    onChange={textInputOnchange}
                    isError={
                      Boolean(formik.touched[input.value]) &&
                      Boolean(!formik.isValidating) &&
                      Boolean(formik.errors[input.value])
                    }
                  />
                </Grid>
              );
            })}
            <div className={styles.dropdownContainer}>
              {dropDownInputs.map(input => {
                return (
                  <Grid item xs={6} md={4} key={input.value}>
                    <Dropdown
                      id={input.value}
                      label={input.label}
                      name={input.value}
                      dropdownItems={input.options}
                      value={
                        formik.values[input.value as keyof typeof formik.values]
                      }
                      placeholder={input.options[0].label}
                      onChange={(option: DropdownOption) =>
                        dropdownOnChange(input.value, option)
                      }
                      isError={
                        Boolean(
                          formik.touched[
                            input.value as keyof typeof formik.values
                          ]
                        ) &&
                        Boolean(!formik.isValidating) &&
                        Boolean(
                          formik.errors[
                            input.value as keyof typeof formik.values
                          ]
                        )
                      }
                    />
                  </Grid>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={styles.rightContainer}>
            <Text variant="body2" className={styles.radioLabel}>
              REQUIRED INPUTS
            </Text>
            <div className={styles.requiredInputs}>
              {requiredInputs.map(input => {
                return (
                  <FormControlLabel
                    key={input.value}
                    className={styles.radioContainer}
                    control={
                      <Checkbox
                        icon={
                          <RadioButtonUnchecked className={styles.radioIcons} />
                        }
                        checkedIcon={
                          <RadioButtonChecked className={styles.radioIcons} />
                        }
                        checked={Boolean(
                          formik.values[
                            input.value as keyof typeof formik.values
                          ]
                        )}
                        onClick={() => {
                          formik.setFieldTouched(input.value, true);
                          formik.setFieldValue(
                            input.value,
                            !formik.values[
                              input.value as keyof typeof formik.values
                            ]
                          );
                        }}
                      />
                    }
                    label={input.label}
                  />
                );
              })}
            </div>
          </Grid>
        </Grid>
        <CancelSubmitFooter
          isLoading={false}
          disabled={!formik.dirty || !formik.isValid}
          cancelButtonOnClick={handleClose}
        />
      </form>
    </Dialog>
  );
};

export default HardwareCreateEditDialog;

import * as React from 'react';
import { useSyncLocNetProvider, useSyncProviderSub } from 'api/useLocations';
import { Button, Dialog } from 'design';
import { ExtSync, SubSync, SyncTypeEnum } from 'types/ApiSync';
import { SyncStatusEnum } from 'types/SyncStatus';
import SyncIcon from '../NetProviderCard/SyncIcon';

import styles from './syncmodal.module.scss';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';

const SyncModal = ({
  syncData,
  open = false,
  handleClose
}: {
  open: boolean;
  syncData: SubSync | ExtSync;
  handleClose: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const {
    mutateAsync: syncLoc,
    isLoading: locLoading
  } = useSyncLocNetProvider();
  const { mutateAsync: syncSub, isLoading: subLoading } = useSyncProviderSub();

  const handleSync = async () => {
    switch (syncData.type) {
      case SyncTypeEnum.Service: {
        try {
          await syncLoc(syncData.id);
          dispatch(
            actions.showNotify({
              message: 'Sent Sync Request',
              severity: 'success'
            })
          );
          handleClose();
        } catch (e) {
          dispatch(
            actions.showNotify({
              message: e?.message || 'Error syncing subscription',
              severity: 'error'
            })
          );
        }
        break;
      }
      case SyncTypeEnum.CDR:
      case SyncTypeEnum.Status: {
        try {
          await syncSub(syncData.id);
          dispatch(
            actions.showNotify({
              message: 'Sent Sync Request',
              severity: 'success'
            })
          );
          handleClose();
        } catch (e) {
          dispatch(
            actions.showNotify({
              message: e?.message || 'Error syncing subscription',
              severity: 'error'
            })
          );
        }
        break;
      }
      case SyncTypeEnum.Extension: {
        dispatch(
          actions.showNotify({
            message: 'Extension sync not supported yet, contact tech support',
            severity: 'error'
          })
        );
        break;
      }
      default: {
        return;
      }
    }
  };

  const statusMessage = React.useMemo(() => {
    let action = 'Refresh';
    let message = '';
    switch (syncData.syncStatus) {
      case SyncStatusEnum.Errored:
        message = `${syncData.reason}`;
        break;
      case SyncStatusEnum.Setup:
        action = 'Setup';
        message = 'Setup required';
        break;
      case SyncStatusEnum.Synced:
        message = 'Everything looks good';
        break;
      case SyncStatusEnum.Syncing:
        message = 'Syncing...';
        break;
    }
    return {
      action,
      message
    };
  }, [syncData]);

  return (
    <Dialog open={open} handleClose={handleClose}>
      <h3 className={styles.modalTitle}>{syncData.type}</h3>
      <h5 className={styles.syncReason}>{statusMessage.message}</h5>
      <div className={styles.syncData}>
        <>
          {locLoading || subLoading ? (
            <SyncIcon status={SyncStatusEnum.Syncing} />
          ) : (
            <SyncIcon status={syncData.syncStatus} />
          )}
          <p className={styles.syncStatus}>{syncData.syncStatus}</p>
          {'tag' in syncData && (
            <p className={styles.syncTag}>Tag: {syncData?.tag}</p>
          )}
        </>
      </div>
      <div className="form-footer">
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={
            locLoading ||
            subLoading ||
            [SyncStatusEnum.Synced, SyncStatusEnum.Syncing].includes(
              syncData.syncStatus as SyncStatusEnum
            )
          }
          onClick={handleSync}
        >
          {statusMessage.action}
        </Button>
      </div>
    </Dialog>
  );
};

export default SyncModal;

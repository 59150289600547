import { useQuery, UseQueryResult } from 'react-query';
import { query } from 'constants/app';
import qs from 'qs';
import axios, { AxiosError, AxiosResponse } from '../utils/axiosProvider';

export interface RolesData {
  superAdmin: number;
  orgAdmin: number;
  locationAdmin: number;
  user: number;
  total: number;
  pending: number;
}

const getRolesCount = async () => {
  const { data }: AxiosResponse = await axios.get('auth-roles/count', {
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  const { superAdmin, orgAdmin, user, locationAdmin, total, pending } = data;

  return {
    total: Number(total),
    orgAdmin: Number(orgAdmin),
    locationAdmin: Number(locationAdmin),
    superAdmin: Number(superAdmin),
    user: Number(user),
    pending: Number(pending)
  };
};

const useRolesCount = (): UseQueryResult<RolesData, AxiosError> => {
  return useQuery<RolesData, AxiosError>([query.rolesCount], getRolesCount);
};

export default useRolesCount;

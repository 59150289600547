import * as React from 'react';
import useSort from 'hooks/useSort';
import styles from './notificationsubslocationcard.module.scss';
import { IconButton } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Dialog as DesignDialog
} from 'design';
import { labelValuesFor, LabelValuesMap } from 'design/Dropdown/Dropdown';
import MultiSelect from 'design/MultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { selectCurrentUser } from '../../../store/selectors';
import { useLocationsByOrg } from 'api/useLocations';
import {
  useGetNotificationSubscriptions,
  useUpdateNotificationSubscriptions
} from 'api/useNotificationSubscriptions';
import NotificationSubsLocationList from '../NotificationSubsLocationList';

const NotificationSubsLocationCard = (): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const currentUser = useSelector(selectCurrentUser);
  const [sort] = useSort(['name', 'ASC']);
  const [locations, setLocations] = React.useState<string[]>([]);
  const [orgId, setOrgId] = React.useState<string>('');
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (currentUser?.organization.id) {
      setOrgId(currentUser.organization.id);
    }
  }, [currentUser?.organization.id]);

  function handleChangeDropdown(value: any) {
    setLocations(value);
  }

  const {
    data: locationsData,
    isLoading: islocationsLoading
  } = useLocationsByOrg(orgId, '', false, sort, 1, 10000);

  const {
    data: notificationSubscriptionsData
  } = useGetNotificationSubscriptions('Location');

  const locationsLabels: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(locationsData);
  }, [locationsData]);

  const {
    mutateAsync: updateNotificationSubscriptions
  } = useUpdateNotificationSubscriptions();

  React.useEffect(() => {
    if (notificationSubscriptionsData) {
      const locationIds = notificationSubscriptionsData.map(item => {
        return item.accountId;
      });
      setLocations([...new Set(locationIds)]);
    }
  }, [notificationSubscriptionsData]);

  const handleNotificationSub = React.useCallback(async () => {
    if (!orgId) return;

    try {
      const notificationsData = {
        accountIds: locations,
        notificationTypes: ['Location'] // hard-coded based on this component
      };
      await updateNotificationSubscriptions(notificationsData);
      dispatch(
        actions.showNotify({
          message: 'Notifications updated successfully.',
          severity: 'success'
        })
      );
    } catch (err) {
      dispatch(
        actions.showNotify({
          message: 'Error: could not create notifications!'
        })
      );
    }
  }, [orgId, locations, updateNotificationSubscriptions, dispatch, actions]);

  const locationNames = notificationSubscriptionsData?.map(item => {
    return item.locationName;
  });

  return (
    <>
      <DesignDialog
        open={showDialog}
        handleClose={() => {
          setShowDialog(false);
        }}
      >
        <NotificationSubsLocationList
          locationNames={locationNames ? locationNames : []}
          handleClose={() => setShowDialog(false)}
        />
      </DesignDialog>
      <Card>
        <CardHeader
          title="Status Offline"
          action={
            <>
              <IconButton
                data-testid="expandButton"
                className={styles.expandButton}
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                <LaunchIcon fontSize="medium" className={styles.linkIcon} />
              </IconButton>
            </>
          }
        ></CardHeader>
        <CardContent noOverflow>
          <div className={styles.contentWrapper}>
            <MultiSelect
              id="locations"
              name="locations"
              className={styles.multiselect}
              isError={false}
              label="locations"
              placeholder="Select Locations"
              onChange={(e: any) => {
                handleChangeDropdown(e);
                setIsDisabled(false);
              }}
              validationType="info"
              showSelectAll={true}
              dropdownItems={locationsLabels}
              value={locations}
              isLoading={islocationsLoading}
              defaultListHeader="Select Location(s)"
            />
            <Button
              disabled={isDisabled}
              isLoading={false}
              onClick={() => {
                handleNotificationSub();
                setIsDisabled(true);
              }}
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </CardContent>
        <CardFooter>&nbsp;</CardFooter>
      </Card>
    </>
  );
};

export default NotificationSubsLocationCard;

import * as React from 'react';
import { Sort } from 'types';

/**
 * Handles sortings of table accessors sstarting with ASC, then ASC/DESC toggles
 * @param {Sort} defaultState
 * @returns
 */
const useSort = (
  defaultState: Sort = ['', 'ASC']
): [Sort, (accessor: string) => void] => {
  const [sort, setSort] = React.useState<Sort>(defaultState);
  const handleSort = React.useCallback((accessor: string) => {
    setSort(preVal => {
      if (preVal[0] !== accessor) {
        return [accessor, 'ASC'];
      }
      return [preVal[0], preVal[1] === 'ASC' ? 'DESC' : 'ASC'];
    });
  }, []);
  return [sort, handleSort];
};

export default useSort;

import React from 'react';
import { useSelector } from 'react-redux';

import { selectAuth } from 'app/slice/selectors';
import { TableAction } from 'design';

interface HardwareTablesActionMenuProps {
  clickEdit: () => void;
  clickDelete: () => void;
}

const HardwareTablesActionMenu = ({
  clickEdit,
  clickDelete
}: HardwareTablesActionMenuProps): JSX.Element => {
  const { permissions } = useSelector(selectAuth);
  const canEditEquipment = permissions?.includes('update:equipment');

  const menuItems = [
    {
      label: 'Edit',
      isVisible: canEditEquipment,
      action: clickEdit
    },
    {
      label: 'Delete',
      isVisible: canEditEquipment,
      action: clickDelete
    }
  ];

  return <TableAction menuItems={menuItems} />;
};

export default HardwareTablesActionMenu;

import { Menu, MenuItem } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useDashboardSlice } from 'app/layouts';
import { ATA, BATTERY } from 'constants/app';
import { ActionButton } from 'design';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CatalogItem } from 'types';
import { HardwareCategoryType } from '../LocationEquipments/constants';

interface LocationEquipmentActionProp {
  id: string;
  catalogItem: CatalogItem;
  serialNumber: string;
  domain?: string;
  mac?: string;
}

const LocationEquipmentAction = ({
  id,
  catalogItem,
  serialNumber,
  domain,
  mac
}: LocationEquipmentActionProp): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin || currentUser?.isOrgAdmin)
      setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuItems = [
    {
      label: 'Manage Lines',
      isVisible: currentUser?.isSuperAdmin && !!catalogItem?.fxoPorts,
      action: () => {
        history.push({
          pathname: `/location/equipment/${id}/managelines`,
          state: { goBack: history?.location?.pathname }
        });
      }
    },
    {
      label: 'Manage SIMs',
      isVisible: currentUser?.isSuperAdmin && !!catalogItem?.simCount,
      action: () => {
        history.push({
          pathname: `/location/equipment/${id}/manage-sims`,
          state: { goBack: history?.location?.pathname }
        });
      }
    },
    {
      label: 'Delete',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'LocationEquipmentDelete',
            size: 'large',
            lazyComponentProps: {
              id,
              catalogItem,
              serialNumber
            }
          })
        );
      }
    },
    {
      label: 'Edit',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: `Edit ${
              catalogItem.group
                ? HardwareCategoryType[
                    catalogItem.group as keyof typeof HardwareCategoryType
                  ] ?? 'Equipment'
                : 'Equipment'
            }`,
            lazyComponent: 'LocationEquipmentUpdate',
            size: catalogItem.group === BATTERY ? 'large' : 'xlarge',
            lazyComponentProps: {
              id,
              catalogItem
            }
          })
        );
      }
    },
    {
      label: 'Download Config',
      isVisible:
        catalogItem?.name?.toLowerCase().includes('vega') &&
        domain &&
        domain.toLowerCase().includes('.ms'),
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Download Vega Config',
            lazyComponent: 'VegaConfigExport',
            lazyComponentProps: {
              domain,
              mac,
              catalogItem
            }
          })
        );
      }
    },
    {
      label: 'Export GDMS',
      isVisible:
        catalogItem?.brand === 'Grandstream' &&
        domain &&
        domain.toLowerCase().includes('.ms'),
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Download GDMS Template',
            lazyComponent: 'GdmsTemplate',
            lazyComponentProps: {
              gatewayCatalog: catalogItem.group === ATA,
              domain,
              serialNumber
            },
            disableBackdropClick: true
          })
        );
      }
    }
  ];
  return (
    <>
      <div className={cc.iconButton}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: cc.paper, list: cc.list }}
        className={cc.menuBar}
      >
        {menuItems.map(({ label, action, isVisible }) => {
          return (
            isVisible && (
              <MenuItem
                button
                onClick={() => {
                  action();
                  if (handleMenuClose) {
                    handleMenuClose();
                  }
                }}
                key={label}
              >
                {label}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 150
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  },
  menuBar: {
    marginLeft: '20px'
  },
  iconButton: {
    float: 'right'
  }
}));
export default LocationEquipmentAction;

import React from 'react';

import DashboardStatisticCardTemplate, {
  CardType
} from '../DashboardStatisticCardTemplate';
import { ReactComponent as AllCallsLocationIcon } from 'assets/img/IconAllCalls.svg';
import { ReactComponent as InboundCallsLocationIcon } from 'assets/img/IconInboundCalls.svg';
import { ReactComponent as OutboundCallsLocationIcon } from 'assets/img/IconOutboundCalls.svg';

interface TopLinesCardProps {
  id: string;
}

const TopLinesCard = ({ id }: TopLinesCardProps): JSX.Element => {
  return (
    <DashboardStatisticCardTemplate
      id={id}
      type={CardType.Lines}
      title="Top Lines"
      group="number"
      IconAll={AllCallsLocationIcon}
      IconInbound={InboundCallsLocationIcon}
      IconOutbound={OutboundCallsLocationIcon}
    />
  );
};

export default TopLinesCard;

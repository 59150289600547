/**
 *
 * LocationsPage
 *
 */
import * as React from 'react';
import styles from './locationspage.module.scss';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LaunchIcon from '@material-ui/icons/Launch';
import useLocations from 'api/useLocations';
import useLocationsStatusCnt from 'api/useLocationsStatusCnt';
import LocationAction from 'app/components/LocationAction';
import PageHeader from 'app/components/PageHeader';
import StatusBadgeBar from 'app/components/StatusBadgeBar';
import NetProviderCell from 'app/components/NetProviderCell';
import { useDashboardSlice } from 'app/layouts';
import { pageSizeOptions, StatusToColor } from 'constants/app';
import {
  ColumnProps,
  Dot,
  Link,
  SearchInput,
  TablePaginated,
  Text
} from 'design';
import useSort from 'hooks/useSort';
import { Location, Sort, Status } from 'types';
import moment from 'moment';
import { SubSync, SyncTypeEnum } from 'types/ApiSync';
import { selectCurrentUser } from 'store/selectors';
import { RoleName } from '../../../types/AuthRoles';

const LocationsPage = (): JSX.Element => {
  const { pathname, search, state } = useLocation();
  const urlSearchParams = React.useMemo(() => new URLSearchParams(search), [
    search
  ]);
  const history = useHistory();
  const user = useSelector(selectCurrentUser);

  const urlFilter = urlSearchParams.get('filter');
  const [filter, setFilter] = React.useState(urlFilter ? urlFilter : '');

  const urlPage = urlSearchParams.get('page');
  const [pageIndex, setPageIndex] = React.useState(
    urlPage ? parseInt(urlPage) : 1
  );

  const urlPageSize = urlSearchParams.get('limit');
  const [pageSize, setPageSize] = React.useState(
    urlPageSize ? parseInt(urlPageSize) : 10
  );

  const [sort, setSort] = useSort(
    urlSearchParams.get('sort')?.split('|') as Sort
  );

  // Get the status filter from the URL, or from the URL Location state.
  //  Location state may get set from the DashboardOverviewCard.
  const urlStatusFilter = urlSearchParams.get('serviceStatus');
  const locationStateStatus = state as Status;
  const [statusFilter, setStatusFilter] = React.useState<Status | ''>(
    urlStatusFilter ? (urlStatusFilter as Status) : locationStateStatus ?? ''
  );

  const prevOrganization = React.useRef(user.organization);
  const [searchInput, setSearchInput] = React.useState(filter);
  const [isDivision, setIsDivision] = React.useState(false);

  const { isLoading, data, error, refetch } = useLocations(
    filter,
    statusFilter,
    sort,
    pageIndex,
    pageSize
  );
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  React.useEffect(() => {
    if (filter === '') {
      urlSearchParams.delete('filter');
    } else {
      urlSearchParams.set('filter', filter);
    }

    if (pageIndex <= 1) {
      urlSearchParams.delete('page');
    } else {
      urlSearchParams.set('page', pageIndex.toString());
    }

    if (pageSize === 10) {
      urlSearchParams.delete('limit');
    } else {
      urlSearchParams.set('limit', pageSize.toString());
    }

    // If sort is the default sort, don't include it in the URL
    if ((sort as Sort) === undefined || sort.join('|') === '|ASC') {
      urlSearchParams.delete('sort');
    } else {
      urlSearchParams.set('sort', sort.join('|'));
    }

    if (statusFilter === '') {
      setStatusFilter('');
      urlSearchParams.delete('serviceStatus');
    } else {
      setStatusFilter(statusFilter);
      urlSearchParams.set('serviceStatus', statusFilter);
    }

    history.push({
      pathname,
      search: urlSearchParams.toString()
    });
  }, [
    filter,
    pageIndex,
    pageSize,
    sort,
    statusFilter,
    history,
    pathname,
    urlSearchParams
  ]);

  React.useEffect(() => {
    // If the (super) user changes organizations, reset the page index and sort
    if (
      user?.organization !== undefined &&
      prevOrganization.current !== undefined &&
      user?.organization !== prevOrganization.current
    ) {
      setPageIndex(1);
      setSort('');
    }
    prevOrganization.current = user?.organization;
  }, [user, setSort]);

  const { locations, total } = data || { locations: [], total: 0 };

  if (error) {
    throw new Error(`${error.message} Failed to fetch Locations!`);
  }

  React.useEffect(() => {
    if (locations) {
      const isDiv = locations.some(item => item.division);
      setIsDivision(isDiv);
    }
  }, [locations]);

  const handleBilling = (id: any) => {
    if (user.isSuperAdmin) {
      dispatch(
        actions.showDialog({
          open: true,
          title: 'Billing',
          lazyComponent: 'LocationBilling',
          size: 'large',
          lazyComponentProps: {
            id
          }
        })
      );
    }
  };

  const handleAdminState = (id: any) => {
    if (user.isSuperAdmin) {
      dispatch(
        actions.showDialog({
          open: true,
          title: 'Admin State',
          lazyComponent: 'LocationAdminState',
          size: 'large',
          closeable: true,
          lazyComponentProps: {
            id
          }
        })
      );
    }
  };
  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'status',
        label: '',
        align: 'center',
        padding: 'none',
        render: ({ serviceStatus }: Location) => (
          <Dot color={StatusToColor[serviceStatus.name]} />
        ),
        cellWidth: 43
      },
      {
        accessor: 'expandIcon',
        label: '',
        align: 'center',
        padding: 'none',
        cellWidth: 43,
        render: ({ id }: Location) => (
          <div>
            <IconButton
              data-testid="expandButton"
              className={styles.expandButton}
              onClick={() => {
                dispatch(
                  actions.showDialog({
                    open: true,
                    title: '',
                    lazyComponent: 'LocationExpand',
                    size: 'large',
                    lazyComponentProps: {
                      id
                    }
                  })
                );
              }}
            >
              <LaunchIcon fontSize="small" className={styles.linkIcon} />
            </IconButton>
          </div>
        )
      },
      {
        accessor: 'organization.name',
        label: 'Organization',
        render: ({ organization }: Location) => (
          <Link to={`/organization/${organization.id}`}>
            {organization.name}
          </Link>
        ),
        sortable: true,
        hidden: !(user.isMSOrg && user.isSuperAdmin),
        resizable: true
      },
      {
        accessor: 'name',
        label: 'Name',
        render: ({ id, name, organization, address }: Location) => (
          <>
            <Link to={`/${organization.id}/location/${id}/section/general`}>
              {name}
            </Link>
            <Text className={styles.subRow}>
              {address.address1},{address.city}
            </Text>
          </>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'address.state',
        label: 'State',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'address.postalCode',
        label: 'Zip',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'linesofservice',
        label: 'Lines',
        render: ({ id, linesofservice, location }: Location) => {
          return (
            <div>
              {user.isSuperAdmin ? (
                <Link to={`/${location?.orgId}/location/${id}/section/lines`}>
                  {linesofservice}
                </Link>
              ) : (
                <>{linesofservice}</>
              )}
            </div>
          );
        },
        sortable: true,
        resizable: true
      },
      {
        accessor: 'bundle',
        label: 'Equipment',
        render: ({ id, equipment, equipmentBundle, location }: Location) => {
          return (
            <div>
              {/* If location has bundle show bundle name, if location has only one equipment show its
               * model name, else show count of equipment
               */}
              {user.isSuperAdmin ? (
                <Link
                  to={`/${location?.orgId}/location/${id}/section/equipment`}
                >
                  {equipmentBundle.length
                    ? equipmentBundle[0]?.bundleCatalog?.name
                    : equipment.length === 1
                    ? equipment[0]?.catalogItem.name
                    : equipment.length}
                </Link>
              ) : (
                <>
                  {equipmentBundle.length
                    ? equipmentBundle[0]?.bundleCatalog?.name
                    : equipment.length === 1
                    ? equipment[0]?.catalogItem.name
                    : equipment.length}
                </>
              )}
            </div>
          );
        },
        sortable: true,
        resizable: true
      },
      {
        accessor: 'location.status',
        label: 'Admin',
        render: ({ id, location }: Location) => {
          return (
            <>
              <div
                className={
                  user.isSuperAdmin ? styles.billingWrapper : styles.billing
                }
                onClick={() => handleAdminState(id)}
              >
                {location?.status}
              </div>
              <div className={styles.subRow}>
                {'Install: '}
                {location?.installDate ? (
                  moment(location?.installDate).format('MM/DD/YYYY')
                ) : (
                  <>-</>
                )}
                | Notifications:{' '}
                <>{location?.notificationsEnabled ? <>On</> : <>-</>}</>
              </div>
            </>
          );
        },
        sortable: true,
        resizable: true
      },
      {
        accessor: 'location.billing',
        label: 'Billing',
        hidden: user.primaryRole.name === RoleName.installPartner,
        render: ({ id, location }: Location) => {
          return (
            <>
              <div
                className={
                  user.isSuperAdmin ? styles.billingWrapper : styles.billing
                }
                onClick={() => handleBilling(id)}
              >
                {location?.billing === null ? (
                  <>-</>
                ) : (
                  <>{location?.billing === true ? <>On</> : <>Off</>}</>
                )}
              </div>
              <div className={styles.subRow}>
                Effective:&nbsp;
                {location?.effectiveDate ? (
                  moment(location?.effectiveDate).format('MM/DD/YYYY')
                ) : (
                  <>-</>
                )}
              </div>
            </>
          );
        },
        sortable: true,
        resizable: true
      },
      {
        accessor: 'location.n2pLoc',
        label: 'Service',
        hidden: !user.isSuperAdmin,
        render: (location: Location) => {
          if (
            !location?.organization?.isNewFlow &&
            !location?.organization.isPartialFlow
          ) {
            return <>N/A</>;
          }
          if (!location?.n2pLoc) {
            return <>-</>;
          }
          return (
            <NetProviderCell
              sub={{
                ...location?.n2pLoc,
                type: SyncTypeEnum.Service
              }}
            />
          );
        },
        sortable: false,
        resizable: true
      },
      {
        accessor: 'location.n2pLoc.statusSub',
        label: 'Status',
        hidden: !user.isSuperAdmin,
        render: (location: Location) => {
          if (
            !location?.organization?.isNewFlow &&
            !location?.organization.isPartialFlow
          ) {
            return <>N/A</>;
          }
          const statusSub = location?.n2pLoc?.n2pSubs?.find(
            (sub: SubSync) => sub.type === 'status'
          );
          if (!statusSub) {
            return <>-</>;
          } else {
            return <NetProviderCell sub={statusSub} />;
          }
        },
        sortable: false,
        resizable: true
      },
      {
        accessor: 'location.n2pLoc.cdrSub',
        label: 'CDR',
        hidden: !user.isSuperAdmin,
        render: location => {
          if (
            !location?.organization?.isNewFlow &&
            !location?.organization.isPartialFlow
          ) {
            return <>N/A</>;
          }
          const cdrSub = location?.n2pLoc?.n2pSubs?.find(
            (sub: SubSync) => sub.type === 'cdr'
          );
          if (!cdrSub) {
            return <>-</>;
          } else {
            return <NetProviderCell sub={cdrSub} />;
          }
        },
        sortable: false,
        resizable: true
      },
      {
        accessor: '',
        label: '',
        // Not super admin nor org admin, or in MarketSpark
        hidden: !(user.isSuperAdmin || user.isOrgAdmin) || user.isMSOrg,
        render: ({ id, locationMetricsUrl }: Location) => (
          <div>
            <LocationAction
              id={id}
              refetch={refetch}
              locationMetricsUrl={locationMetricsUrl}
            />
          </div>
        ),
        sortable: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDivision, user]
  );
  const handleAddLoc = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Create Location',
        lazyComponent: 'LocationCreate',
        disableBackdropClick: true
      })
    );
  }, [actions, dispatch]);

  return (
    <>
      <Helmet>
        <title> Locations </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={styles.headWrapper}>
        <PageHeader title="Locations" />
        <div className={styles.optionsWrapper}>
          <SearchInput
            className={styles.searchInput}
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          {user?.isSuperAdmin && !user.isMSOrg ? (
            <IconButton
              onClick={() => handleAddLoc()}
              data-testid="addButton"
              classes={{ root: styles.addLocationButton }}
            >
              <AddCircleIcon
                color="primary"
                classes={{ root: styles.addLocationIcon }}
              />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>
      <StatusBadgeBar
        getStatusCount={useLocationsStatusCnt()}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        setPageIndex={setPageIndex}
      />
      <div>
        <TablePaginated
          id="Locations"
          rows={locations}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default LocationsPage;

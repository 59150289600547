import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import useSort from 'hooks/useSort';
import { useBatteryStatus } from 'api/useEquipment';
import { Equipment } from 'types';
import { ROUTER_METRIC_BRANDS, GATEWAY_METRIC_BRANDS } from 'constants/app';
import SignalStatusCard from '../SignalStatus';
import { useRouters } from 'api/useRouters';
import useEquipment from 'api/useEquipment';
import { usePowersourceBatteryStatus } from 'api/usePowerSource';
import PowerSourceCard from '../PowerSourceCard';
import { Powersource } from 'types/PowerSource';
import { useGatewaysByLocation } from 'api/useGateways';
import GatewayMetricsCard from '../GatewayMetrics';

interface LocationInfoProps {
  locationId: string;
  organizationId: string;
  installationId: string;
}

const DiagnosticsTab = ({
  installationId,
  locationId,
  organizationId
}: LocationInfoProps): JSX.Element => {
  const cc = useStyles();
  const [filter] = React.useState(locationId);
  const [sort] = useSort();
  const { isLoading, data, error } = useBatteryStatus({ filter, sort });

  // get routers from bundle based on location
  const {
    data: routers,
    isLoading: routersIsLoading,
    error: routerFetchError
  } = useRouters(locationId);

  const {
    isLoading: equipmentIsLoading,
    data: equipments,
    error: equipmentFetchError
  } = useEquipment({ filter, sort });

  // gets powersources from bundle including batteryStatus
  const {
    data: powersourceBatteryStatus,
    isLoading: powersourceIsLoading
  } = usePowersourceBatteryStatus(locationId);

  const {
    data: gateways,
    isLoading: gatewaysIsLoading,
    error: gatewaysFetchError
  } = useGatewaysByLocation(locationId);

  if (error) {
    throw new Error(`${error.message} Failed to fetch the Equipment!`);
  }

  if (routerFetchError) {
    throw new Error(`${routerFetchError.message} Failed to fetch the Routers!`);
  }

  if (equipmentFetchError) {
    throw new Error(
      `${equipmentFetchError?.message} Failed to fetch equipments`
    );
  }

  if (gatewaysFetchError) {
    throw new Error(`${gatewaysFetchError?.message} Failed to fetch gateways`);
  }

  return (
    <div>
      {!isLoading ? (
        <>
          <div className={cc.cardContainer}>
            {!isLoading
              ? data &&
                data.equipment.map((battery: Equipment) => (
                  <PowerSourceCard key={battery.id} battery={battery} />
                ))
              : ''}
            {!powersourceIsLoading
              ? powersourceBatteryStatus &&
                powersourceBatteryStatus.map((powersource: Powersource) => (
                  <PowerSourceCard
                    key={powersource.id}
                    powersource={powersource}
                  />
                ))
              : ''}
            {/* loads signalstatus card for routers */}
            {!routersIsLoading &&
              routers &&
              routers.map((router: any) => (
                <SignalStatusCard
                  key={router.serialNumber}
                  id={router.id}
                  serialNumber={router.serialNumber}
                  catalogBrand={router.routerCatalog.brand}
                  model={router.routerCatalog.model}
                  netCloudId={router?.netcloud?.netCloudId}
                  staticIp={router?.ip}
                  installationId={installationId}
                />
              ))}
            {/* renders Signal card for equipments table */}
            {!equipmentIsLoading &&
              equipments &&
              equipments.equipment.map((equipment: any) =>
                [
                  ROUTER_METRIC_BRANDS.CRADLEPOINT,
                  ROUTER_METRIC_BRANDS.INHAND
                ].includes(equipment.catalogItem.brand) ? (
                  <SignalStatusCard
                    key={equipment.serialNumber}
                    id={equipment.id}
                    serialNumber={equipment.serialNumber}
                    catalogBrand={equipment.catalogItem.brand}
                    model={equipment.catalogItem.name}
                    netCloudId={equipment?.netcloud?.netCloudId}
                    staticIp={equipment.staticIp}
                    installationId={installationId}
                  />
                ) : null
              )}
            {/* render gateway & equipments that are gateways (GDMS for now) to display fetched metrics */}
            {!gatewaysIsLoading &&
              gateways &&
              gateways.map(gateway =>
                [GATEWAY_METRIC_BRANDS.GRANDSTREAM].includes(
                  gateway.gatewayCatalog.brand
                ) ? (
                  <GatewayMetricsCard
                    key={gateway.id}
                    id={gateway.id}
                    serialNumber={gateway.serialNumber}
                    mac={gateway.mac}
                    catalogBrand={gateway.gatewayCatalog.brand}
                    model={gateway.gatewayCatalog.model}
                    domain={gateway.domain}
                    rank={gateway?.rank || undefined}
                  />
                ) : null
              )}

            {!equipmentIsLoading &&
              equipments &&
              equipments.equipment.map((equipment: any) =>
                [GATEWAY_METRIC_BRANDS.GRANDSTREAM].includes(
                  equipment.catalogItem.brand
                ) ? (
                  <GatewayMetricsCard
                    key={equipment.id}
                    id={equipment.id}
                    serialNumber={equipment.serialNumber}
                    mac={equipment.mac}
                    catalogBrand={equipment.catalogItem.brand}
                    model={equipment.catalogItem.model}
                    domain={equipment.domain}
                  />
                ) : null
              )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  }
}));

export default DiagnosticsTab;

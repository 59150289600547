import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useIsFetching } from 'react-query';
import { Progress } from 'design';

const PageLoader = (): JSX.Element => {
  const cc = useStyles();
  const isFetching = useIsFetching();
  return (
    <div className={cc.loaderWrapper}>
      {isFetching ? <Progress thin /> : ''}{' '}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  loaderWrapper: {
    width: '100%'
  }
}));

export default PageLoader;

import * as React from 'react';
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  TableCell as MuiTableCell,
  Paper
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import { ColumnProps } from 'design/types';
import TableCell from './TableCell';
import SkeletonRows from './SkeletonRows';

export type { ColumnProps };

export interface SortChevronProps {
  sort: 'ASC' | 'DESC';
}

const SortChevron = ({ sort }: SortChevronProps): JSX.Element => {
  const cc = useChevronStyles();
  return sort === 'DESC' ? (
    <DownArrow classes={{ root: cc.iconRoot }} color="primary" />
  ) : (
    <UpArrow classes={{ root: cc.iconRoot }} color="primary" />
  );
};

const useChevronStyles = makeStyles((theme: Theme) => ({
  iconRoot: {
    padding: 0,
    margin: '-2px 0 0 8px',
    maxWidth: 16,
    maxHeight: 18,
    display: 'inline',
    color: theme.palette.grey['500']
  }
}));

export type Sort = [string, 'ASC' | 'DESC'];

export interface TableProps {
  id: string;
  rows: any[]; // TODO: suggestions better typing required?
  columns: ColumnProps[];
  handleSort?: (accessor: string) => void;
  sort?: Sort;
  isLoading: boolean;
  /**
   * FLUID - will make the other columns shrink up to its minimum width when a column is resized.
   * SOLID - will not shrink other columns when a column is resized.
   */
  resizeBehavior?: 'solid' | 'fluid';
}

interface ColumnPropsWithRefs extends ColumnProps {
  ref?: any;
}

const createHeaders = (headers: ColumnProps[]) => {
  return headers.map(item => ({
    ...item,
    ref: React.useRef()
  }));
};

/**
 * This Table takes props for rendering a static data table. Should not be used
 * directly if the data set needs to be paginated. Please also review {ColumnProps}
 * type which controls much of the display & sorting functionality here.
 * @param {TableProps}
 * @returns {JSX.Element}
 */

const Table = ({
  id,
  rows,
  columns,
  handleSort,
  sort = ['', 'ASC'],
  isLoading,
  resizeBehavior = 'solid' // resizeBehavior defaulted to SOLID
}: TableProps): JSX.Element => {
  const cc = useStyles();
  const customCols: ColumnProps[] = createHeaders(columns);
  const displayCols: ColumnPropsWithRefs[] = React.useMemo(
    () => customCols?.filter(col => !col.hidden),
    [customCols]
  );

  const [activeIndex, setActiveIndex] = React.useState(-1);
  const tableRef = React.useRef<any>(null);

  const mouseDown = (index: any) => {
    setActiveIndex(index);
  };

  // RECURSIVELY FINDS ALL COLUMN CURRENT WIDTH BY CHECKING THE SIBLING ELEMENT OF AN ELEMENT
  const findSiblingWidths = (
    element: any,
    arr: string[],
    type: 'Prev' | 'Next'
  ): string[] => {
    const sibling =
      type === 'Next' ? element?.nextSibling : element?.previousSibling;
    if (sibling !== null) {
      const offsetWidth = sibling.offsetWidth;
      type === 'Next' ? arr.push(offsetWidth) : arr.unshift(offsetWidth);
      return findSiblingWidths(sibling, arr, type);
    }
    return arr;
  };

  const mouseMove = React.useCallback(
    e => {
      const rect = tableRef.current.parentElement.getBoundingClientRect().x;
      const currentEleOffset = displayCols[activeIndex].ref.current.offsetLeft;
      const eleLeftPos = currentEleOffset + rect;
      const clientX = e.clientX || e.changedTouches[0].clientX;
      const width = clientX - eleLeftPos + 2;
      displayCols[activeIndex].ref.current.style.minWidth = `${width}px`;
      displayCols[activeIndex].ref.current.style.width = `${width}px`;

      //MAKES THE RESIZE BEHAVIOR SOLID. i.e. OTHER COLUMNS WONT SHRINK WHEN RESIZING A COLUMN
      if (resizeBehavior === 'solid') {
        const currentElement = displayCols[activeIndex].ref.current;
        const allColumnWidths: string[] = [];
        //FINDS AND PUSHES LEFT COLUMN WIDTHS TO AN ARRAY
        findSiblingWidths(currentElement, allColumnWidths, 'Prev');
        //PUSHES CURRENT COLUMN WIDTH TO AN ARRAY
        allColumnWidths.push(currentElement.offsetWidth);
        //FINDS AND PUSHES RIGHT COLUMN WIDTHS TO AN ARRAY
        findSiblingWidths(currentElement, allColumnWidths, 'Next');
        for (let i = 0; i < allColumnWidths.length - 1; i++) {
          if (displayCols[i]?.resizable) {
            displayCols[
              i
            ].ref.current.style.minWidth = `${allColumnWidths[i]}px`;
            displayCols[i].ref.current.style.width = `${allColumnWidths[i]}px`;
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex, displayCols]
  );

  const removeListeners = React.useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('touchmove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
    window.removeEventListener('touchend', removeListeners);
  }, [mouseMove]);

  const mouseUp = React.useCallback(() => {
    setActiveIndex(-1);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  React.useEffect(() => {
    if (activeIndex !== -1) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('touchmove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
      window.addEventListener('touchend', mouseUp);
    }
    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const resizer = (i: number) => {
    return (
      <span
        onTouchMove={() => mouseDown(i)}
        onMouseDown={() => {
          mouseDown(i);
        }}
        className={`${cc.resizeIcon}`}
      >
        <span className={cc.resizeIconCursor}></span>
      </span>
    );
  };

  return (
    <TableContainer
      id={id}
      component={Paper}
      elevation={0}
      classes={{ root: cc.containerRoot }}
    >
      <MuiTable size="small" aria-label="table data">
        <TableHead classes={{ root: cc.headerRoot }} ref={tableRef}>
          <TableRow>
            {displayCols.map((col, i) =>
              col.sortable ? (
                <MuiTableCell
                  classes={{ root: cc.headerCellRoot }}
                  key={`${col.accessor}-head-${i}`}
                  ref={col.ref}
                >
                  <div className={cc.tableCellWrapper}>
                    <div
                      className={cc.headerLabel}
                      onClick={
                        handleSort ? () => handleSort(col.accessor) : undefined
                      }
                    >
                      {col.label}
                      {col.accessor !== sort[0] || (
                        <SortChevron sort={sort[1]} />
                      )}
                    </div>
                    {col?.resizable && resizer(i)}
                  </div>
                </MuiTableCell>
              ) : (
                <MuiTableCell
                  key={`${col.accessor}-head-${i}`}
                  classes={{ root: cc.headerCellRootNotSortable }}
                  ref={col.ref}
                >
                  <div className={cc.tableCellWrapper}>
                    <div className={cc.headerLabel}>{col.label}</div>
                    {col?.resizable && resizer(i)}
                  </div>
                </MuiTableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            rows?.map(row => (
              <TableRow key={row.id} classes={{ root: cc.rowRoot }}>
                {displayCols.map((col, i) => (
                  <TableCell
                    key={`${col.accessor}-${col.label}-body-${i}`}
                    row={row}
                    col={col}
                  />
                ))}
              </TableRow>
            ))
          ) : (
            <SkeletonRows cols={displayCols} rowCount={10} />
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  containerRoot: {
    borderRadius: 0
  },
  tableCellWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'relative'
  },
  resizeIcon: {
    display: 'flex',
    position: 'absolute',
    right: '-33px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: '10px',
    width: '20px',
    touchAction: 'none',
    '&:not(:active):hover': {
      cursor: 'col-resize'
    },
    '&:active > span': {
      cursor: 'pointer',
      borderColor: theme.palette.grey['500']
    }
  },
  resizeIconCursor: {
    display: 'flex',
    width: '1px',
    height: '25px',
    borderRight: `2px solid ${theme.palette.grey['50']}`
  },
  rowRoot: {
    background: theme.palette.info.main,
    borderBottomWidth: 2
  },
  headerLabel: { display: 'flex', alignItems: 'center' },
  headerRoot: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderTop: `2px solid ${theme.palette.primary.main}`
  },
  headerCellRoot: {
    color: theme.palette.grey['500'],
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    height: 32,
    maxHeight: 32,
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  headerCellRootNotSortable: {
    color: theme.palette.grey['500'],
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    height: 32,
    maxHeight: 32,
    cursor: 'default',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export default Table;

import * as React from 'react';
import { Badge, BadgeBar } from 'design';

export interface OrgStatusBadgeBarProps {
  getOrgStatusCount: any;
  statusFilter: string;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const OrgStatusBadgeBar = ({
  getOrgStatusCount,
  statusFilter,
  setStatusFilter,
  setPageIndex = () => {}
}: OrgStatusBadgeBarProps): JSX.Element => {
  const rolesCount = () => getOrgStatusCount;
  const rolesCountResult = rolesCount();
  const error = rolesCountResult.error;
  const data = rolesCountResult.data || {};
  const isLoading = rolesCountResult.isLoading;

  if (error) {
    throw new Error(`${error.message} Failed to fetch organization status!`);
  }
  return (
    <BadgeBar>
      {Object.keys(data).map(key => {
        if (key === 'total') {
          return (
            <Badge
              key={key}
              color="info"
              rightLabel="All"
              active={!statusFilter}
              handleClick={() => {
                setPageIndex(1);
                setStatusFilter('');
              }}
              isLoading={isLoading}
            >
              {data.total}
            </Badge>
          );
        }
        return (
          <Badge
            key={key}
            color="info"
            rightLabel={key}
            active={statusFilter === key}
            handleClick={() => {
              setPageIndex(1);
              setStatusFilter(key);
            }}
            isLoading={isLoading}
          >
            {data[key]}
          </Badge>
        );
      })}
    </BadgeBar>
  );
};

export default OrgStatusBadgeBar;

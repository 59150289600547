import * as React from 'react';
import * as MuiDialog from '@material-ui/core/Dialog';
import { makeStyles, Theme, Hidden } from '@material-ui/core';
import clsx from 'clsx';
export type DialogReason = 'backdropClick' | 'escapeKeyDown' | undefined;
export interface DialogProps {
  handleClose: (
    event?: React.MouseEvent<HTMLElement> | object,
    reason?: DialogReason
  ) => void;
  open: boolean;
  isModal?: boolean;
  size?: 'small' | 'large' | 'xlarge' | 'xxlarge';
  children: React.ReactNode;
  fullScreen?: boolean;
}

// makes a construct of MuiDialog
export const MatuiDialog = MuiDialog.default;

const Dialog = ({
  children,
  handleClose,
  open,
  size = 'large',
  fullScreen
}: DialogProps): JSX.Element => {
  const cc = useStyles();
  const dialogPaperClass = clsx(cc.dialogPaper, {
    [cc.largePaper]: size === 'large',
    [cc.smallPaper]: size === 'small',
    [cc.xLargePaper]: size === 'xlarge',
    [cc.xxLargePaper]: size === 'xxlarge'
  });
  return (
    <>
      <Hidden xsDown>
        <MatuiDialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          scroll="body"
          classes={{
            paper: dialogPaperClass
          }}
        >
          <>{children}</>
        </MatuiDialog>
      </Hidden>
      <Hidden smUp>
        <MatuiDialog
          open={open}
          onClose={handleClose}
          scroll="body"
          fullScreen
          classes={{
            paper: cc.dialogPaperMobile
          }}
        >
          <>{children}</>
        </MatuiDialog>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaperMobile: {
    padding: theme.spacing(5, 4, 5)
  },
  dialogPaper: {
    padding: theme.spacing(2, 2.5, 2),
    width: '100%'
  },
  smallPaper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 300
    }
  },
  largePaper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 353
    }
  },
  xLargePaper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 706
    }
  },
  xxLargePaper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 1000
    }
  }
}));

export default Dialog;

import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Entity } from 'types';
import axios, { AxiosError, AxiosResponse } from 'utils/axiosProvider';
import qs from 'qs';

export interface OrgStatus extends Entity {
  name: string;
}

interface UpdateStatus {
  orgId: string;
  status: OrgStatus;
}
export interface OrgStatusCount {
  total: number;
  [key: string]: number;
}

const getOrgStatusList = async (): Promise<OrgStatus[]> => {
  const { data } = await axios.get(`/organizations/status`);
  return data.data;
};

const useOrgStatusList = (): UseQueryResult<OrgStatus[], Error> => {
  return useQuery<OrgStatus[], AxiosError>(
    [query.orgStatusList],
    getOrgStatusList
  );
};

const patchOrgStatus = async (org: UpdateStatus): Promise<void> => {
  const status = {
    organizationStatusId: org.status.id
  };
  await axios.patch(`/organizations/${org.orgId}/status`, status);
};

const useUpdateOrgStatus = (): UseMutationResult<
  void,
  AxiosError,
  UpdateStatus
> => {
  const queryClient = useQueryClient();

  const mutatePatchOrg = useMutation<void, AxiosError, UpdateStatus>(
    patchOrgStatus,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.orgStatus]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([query.organizations]);
        queryClient.invalidateQueries([query.orgStatusCount]);
      }
    }
  );

  return mutatePatchOrg;
};

const getOrgStatusCount = async (): Promise<OrgStatusCount> => {
  const { data }: AxiosResponse = await axios.get(
    '/organizations/status/count',
    {
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  );
  return data;
};

const useOrgStatusCount = (): UseQueryResult<OrgStatusCount, AxiosError> => {
  return useQuery<OrgStatusCount, AxiosError>(
    [query.orgStatusCount],
    getOrgStatusCount
  );
};

export { useOrgStatusList, useUpdateOrgStatus, useOrgStatusCount };

import * as React from 'react';
import clsx from 'clsx';
import * as MuiButton from '@material-ui/core/Button';
import LoadingIcon from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface IconButtonProps {
  children: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  disabled: boolean;
  isLoading: boolean;
  icon: JSX.Element;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

// Get the Mui Button Component
const MatuiButton = MuiButton.default;

const IconButton = ({
  children,
  variant = 'contained',
  disabled,
  isLoading = false,
  handleClick,
  icon,
  className
}: IconButtonProps): JSX.Element => {
  const cc = useStyles();

  const loadingIconSvg = clsx({
    [cc.loadingIconSvg]: true,
    [cc.loadingIconSvgOutlined]: variant === 'outlined'
  });

  const activeButton = clsx({
    [cc.buttonRoot]: true,
    [cc.buttonActiveLoading]: isLoading
  });

  const buttonOutlined = clsx({
    [cc.buttonOutlined]: variant === 'outlined',
    [cc.buttonOutlinedLoading]: isLoading
  });

  const buttonDisabled = clsx({
    [cc.buttonActiveDisabled]: disabled && variant === 'contained',
    [cc.buttonOutlinedDisabled]: disabled && variant === 'outlined'
  });

  return (
    <MatuiButton
      startIcon={!isLoading ? icon : null}
      color="secondary"
      variant={variant}
      disabled={disabled}
      classes={{
        root: activeButton,
        outlined: buttonOutlined,
        disabled: buttonDisabled
      }}
      size="large"
      onClick={e => {
        if (handleClick) {
          handleClick(e);
        }
      }}
      disableElevation
      className={className}
    >
      {isLoading ? (
        <LoadingIcon
          size={14}
          thickness={7}
          classes={{ svg: loadingIconSvg }}
        />
      ) : (
        children
      )}
    </MatuiButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    fontWeight: 'bold',
    fontSize: theme.typography.body1.fontSize,
    padding: '12px 20px',
    lineHeight: '1rem',
    minWidth: 124,
    maxHeight: 41,
    '& .MuiSvgIcon-root': {
      maxHeight: theme.typography.body1.fontSize
    },
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  buttonOutlined: {
    border: `2px solid ${theme.palette.secondary.main}`,
    '&[disabled=""]': { color: theme.palette.grey['50'] },
    backgroundColor: `${theme.palette.common.white} !important`,
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    borderStyle: 'solid',
    borderWidth: 2,
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.black
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: 2,
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main
      }
    }
  },
  buttonOutlinedLoading: {
    borderColor: `${theme.palette.secondary.main} !important`
  },
  loadingIconSvg: {
    color: theme.palette.common.white
  },
  loadingIconSvgOutlined: {
    '&[disabled=""]': { color: theme.palette.grey['50'] },
    color: theme.palette.secondary.main
  },
  buttonActiveLoading: {
    backgroundColor: theme.palette.secondary.main
  },
  buttonActiveDisabled: {},
  buttonOutlinedDisabled: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.grey['50']
    },
    borderWidth: 2,
    borderColor: `${theme.palette.grey['50']} !important`
  }
}));

export default IconButton;

import { lineExists } from 'api/useAttachLines';
import { CNAM_REGEX, DID_LENGTH, net2phone } from 'constants/app';
import * as Yup from 'yup';
import { ManageLine } from '../types/ManageLinesType';
import { debounce } from 'perfect-debounce';
import { DropdownOption } from 'design';
import { manageLinesMessages } from 'constants/app';
import { validateUniversalPhoneNumber } from 'utils/yupHelpers';

interface SchemaProps {
  id: string;
  lines: ManageLine[];
  portCount: any;
  erpLocationId?: boolean;
}

const debouncedLineExist = debounce(lineExists, 1000);
const cleanLine = (did: string): string => {
  return did?.replace(/[ ()-]/g, '').trim();
};

const Schema = ({ id, lines, portCount, erpLocationId }: SchemaProps) => {
  const validateDid = async (
    identifier: string,
    ctx: Yup.TestContext,
    value?: string
  ): Promise<Yup.ValidationError | boolean> => {
    // Handle Empty Case
    if (!value) {
      return true;
    }

    const cleanedLine = cleanLine(value);
    // Validate Length
    if (cleanedLine.length !== DID_LENGTH) {
      return ctx.createError({
        message: identifier + manageLinesMessages.didLength
      });
    }

    // Validate Format
    const checkFormat = validateUniversalPhoneNumber('+' + cleanedLine);
    if (!checkFormat) {
      return ctx.createError({
        message: identifier + manageLinesMessages.didFormat
      });
    }

    // Check if DID is present under the equipmentId locally.
    const doesNumberExist = lines.filter(
      e => e.line.replace(/[ ()-]/g, '').trim() === cleanedLine
    );
    const tempNumberExists = lines.filter(
      e => e.tempPortingNumber?.replace(/[ ()-]/g, '').trim() === cleanedLine
    );

    const allExistingLines = doesNumberExist.concat(tempNumberExists);
    if (allExistingLines.length > 1) {
      return ctx.createError({
        message: identifier + manageLinesMessages.existsThisSite
      });
    }

    // Check if exists in another site
    const { line: queriedLine, exists } = await debouncedLineExist(
      cleanedLine,
      id
    );
    if (
      // Avoid race conditions caused by to debounce by
      // ensuring we are validating the right line
      queriedLine === cleanedLine &&
      exists
    ) {
      return ctx.createError({
        message: identifier + manageLinesMessages.existsOtherSite
      });
    }
    return true;
  };

  //yup validation
  const schema = Yup.array().of(
    Yup.object().shape({
      //port number validate
      port: Yup.number()
        .transform((_, val) => Number(val))
        .required('Please provide a port')
        .moreThan(0, 'Minimum port number 1')
        .lessThan(portCount + 1, `Maximum port number ${portCount}.`)
        .test({
          name: 'Port already exists',
          message: 'Port already exists',
          test: async value => {
            if (value) {
              const doesPortExist = lines.filter(
                e => Number(e.port) === Number(value)
              );
              return !(doesPortExist && doesPortExist.length > 1);
            }
            return true;
          }
        }),
      //line number validate
      line: Yup.string().test({
        name: 'DID Validation',
        test: async (value, ctx) => {
          const result = await validateDid('DID', ctx, value);
          return result;
        }
      }),
      // validate tempPortingNumber (same as line)
      tempPortingNumber: Yup.string()
        .nullable()
        .test({
          name: 'Temp DID Validation',
          test: async (value, ctx) => {
            const result = await validateDid(
              'Temp DID',
              ctx,
              value ?? undefined
            );
            return result;
          }
        }),
      // description validate
      description: Yup.string()
        .max(32, 'Description must be at most 32 characters')
        .nullable(),
      callerId: Yup.string().when('line', {
        is: (line: string) => {
          if (erpLocationId) {
            return line && line.length;
          } else {
            return false;
          }
        },
        then: Yup.string()
          .required('Please provide a Caller ID')
          .test('callerId', (value, ctx) => {
            if (value === '') {
              return ctx.createError({ message: 'Caller ID cannot be blank' });
            }
            if (!value)
              return ctx.createError({ message: 'Caller ID cannot be blank' });

            if (value.length < 1) {
              return ctx.createError({
                message: 'Caller ID must be at least 1 character'
              });
            }

            if (value.length > 14) {
              return ctx.createError({
                message: 'Caller ID cannot be more than 14 characters'
              });
            }

            const cidMatch = CNAM_REGEX.test(value);

            if (!cidMatch) {
              return ctx.createError({
                message:
                  'Caller ID must only include letters, numbers and spaces'
              });
            }

            return true;
          })
      }),
      lineType: Yup.object().shape({
        value: Yup.string().required('Please select a line type')
      }),
      provider: Yup.object().shape({
        value: Yup.string().required('Please select a provider')
      }),
      extension: Yup.string()
        .nullable()
        .when('line', {
          is: (line: string) => line,
          then: Yup.string().when('provider', {
            is: (provider: DropdownOption) => provider?.label === net2phone,
            then: Yup.string().required('Please provide an extension'),
            otherwise: Yup.string().nullable()
          })
        })
    })
  );
  return schema;
};

export default Schema;

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

import {
  Breadcrumbs,
  Button,
  Dropdown,
  DropdownOption,
  TextInput
} from 'design';
import { useDashboardSlice } from 'app/layouts';
import {
  useCarriers,
  useSimStatuses,
  useApnList,
  useSimsByEquipment,
  useManageSims,
  useDeleteSims
} from 'api/useSim';
import { labelValuesFor } from 'design/Dropdown/Dropdown';
import { useEquipmentByID } from 'api/useEquipment';
import {
  activatedByOptions,
  APN_LIMIT,
  CARRIER,
  CARRIER_LIMIT,
  MARKETSPARK
} from 'constants/app';
import useRouter from 'api/useRouters';
import schema from './schema';
import { AlertObjectProp } from 'design/Alert/Alert';
import { isNestedObjectEmpty } from 'utils/yupHelpers';
import PageHeader from '../PageHeader';
import { ReactComponent as DeleteIconRed } from '../../../assets/img/delete_icon_red.svg';
import { ReactComponent as DeleteIconGrey } from '../../../assets/img/delete_icon_grey.svg';
import styles from './manageSims.module.scss';

const ManageSims = (): JSX.Element => {
  const [deletedSim, setDeletedSim] = React.useState<string[]>([]);
  const [apnDropdownLoading, setApnDropdownLoading] = React.useState<boolean>(
    true
  );
  const [filteredApnOne, setFilteredApnOne] = React.useState<DropdownOption[]>(
    []
  );
  const [filteredApnTwo, setFilteredApnTwo] = React.useState<DropdownOption[]>(
    []
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useDashboardSlice();

  // Workaround since material-ui is not v5
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    mutateAsync: manageSims,
    isLoading: manageSimsLoading
  } = useManageSims();
  const { mutateAsync: deleteSims } = useDeleteSims();

  // Dropdown labels
  const { data: carrierList } = useCarriers(CARRIER_LIMIT);
  const { data: simStatusList } = useSimStatuses();
  const { data: apnList } = useApnList(APN_LIMIT);
  const carrier = React.useMemo(() => {
    return labelValuesFor(carrierList);
  }, [carrierList]);
  const simStatus = React.useMemo(() => {
    return labelValuesFor(simStatusList);
  }, [simStatusList]);
  const apn = React.useMemo(() => {
    return labelValuesFor(apnList);
  }, [apnList]);

  // Initialize filter APNs
  React.useEffect(() => {
    setFilteredApnOne(apn);
    setFilteredApnTwo(apn);
    setApnDropdownLoading(false);
  }, [apn]);

  // Existing SIMs and equipment info
  const { id, pathName } = useParams<{ id: string; pathName: string }>();
  const {
    data: equipmentSims,
    isLoading: equipmentSimsLoading
  } = useSimsByEquipment(id, pathName);
  const {
    data: equipmentData,
    isLoading: equipmentDataLoading
  } = useEquipmentByID(id, pathName);
  const { data: routerData } = useRouter(id, pathName);

  const pathIsEquipment = pathName === 'equipment';

  // Set Breadcrumbs
  const equipmentParentName = pathIsEquipment
    ? equipmentData?.account?.organization
    : routerData?.equipmentBundleItem?.equipmentBundle?.location;
  const breadCrumbsItems = [
    {
      label: `${equipmentParentName?.name}`,
      url: `/Organization/${equipmentParentName?.id}`
    },
    {
      label: 'Locations',
      url: `/locations`
    },
    { label: 'Manage SIMs', url: '#' }
  ];

  // Page Header Subtitle
  const subTitle = pathIsEquipment
    ? equipmentData?.catalogItem.name
    : routerData?.routerCatalog.model;

  // Set initial values
  const slotsCount =
    equipmentData?.catalogItem?.simCount || routerData?.routerCatalog?.simCount;
  const activeSimStatus = simStatus?.find(v => v.label === 'Active');
  const initialValues = React.useMemo(
    () =>
      [...Array(slotsCount).keys()].map((slot, i) => {
        const sim = equipmentSims?.find(s => s.slot === slot + 1);
        const activatedBy = i ? MARKETSPARK : CARRIER;
        return {
          simId: sim?.id || '',
          slot: (slot + 1).toString(),
          iccId: sim?.iccId ?? '',
          activatedBy: {
            label: sim?.ownership ?? activatedBy,
            value: sim?.ownership ?? activatedBy
          },
          apn: {
            label: sim?.apn?.name ?? '',
            value: sim?.apn?.id ?? ''
          },
          mdn: sim?.mdn ?? '',
          carrier: {
            label: sim?.carrier?.name ?? '',
            value: sim?.carrier?.id ?? ''
          },
          simStatus: {
            label: sim?.simStatus.name || activeSimStatus?.label || '',
            value: sim?.simStatus.id || activeSimStatus?.value || ''
          }
        };
      }),
    [slotsCount, equipmentSims, activeSimStatus]
  );

  // Filter APNs if carrier already set
  React.useEffect(() => {
    initialValues.forEach((sim, index) => {
      if (sim.carrier.label !== '') {
        let setFunctionToUse = setFilteredApnOne;
        if (index !== 0) {
          setFunctionToUse = setFilteredApnTwo;
        }
        const carrierExisting = carrierList?.find(
          carrierOption => carrierOption.name === sim.carrier.label
        );

        if (carrierExisting) {
          const apnListFiltered = apnList?.filter(
            apnOption => apnOption.carrierId === carrierExisting.id
          );
          setFunctionToUse(labelValuesFor(apnListFiltered));
        }
      }
    });
  }, [apnList, carrierList, initialValues]);

  // Initialize Schema
  const yupSchema = () => {
    return schema(initialValues, formikProps.values, slotsCount || 1);
  };

  //  Initialize formik
  const formikProps = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yupSchema,
    onSubmit: () => {}
  });

  // Handle OnBlur
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formikProps.setFieldTouched(e.target.name, true);
  };

  // Handle OnChange
  const handleTextInputOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    await formikProps.setFieldValue(name, value);
    formikProps.validateField(name);
  };

  // Handle Change Dropdown
  const handleChangeDropdown = async (option: DropdownOption, name: string) => {
    await formikProps.setFieldValue(name, option);
    formikProps.validateField(name);
  };

  // Handle Carrier Dropdown Change
  const handleCarrierChange = async (option: DropdownOption, index: number) => {
    // Carrier Dropdown
    await formikProps.setFieldValue(`${index}.carrier`, option);
    formikProps.validateField(`${index}.carrier`);

    // APN Dropdown (reset APN dropdown to all before selecting default, then apply filter to avoid providing invalid value)
    let setFunctionToUse = setFilteredApnOne;
    if (index !== 0) {
      setFunctionToUse = setFilteredApnTwo;
    }
    setFunctionToUse(apn);

    // Find default APN and set if exists
    const findDefault = carrierList?.find(
      carrierOption => carrierOption.id === option.value
    );
    const defaultApn = apn?.find(
      apnOption => apnOption.value === findDefault?.defaultApnId
    );

    await formikProps.setFieldValue(
      `${index}.apn`,
      defaultApn ?? { label: '', value: '' }
    );

    // Re Filter APN dropdown
    const apnListFiltered = apnList?.filter(
      apnOption => apnOption.carrierId === option.value
    );
    setFunctionToUse(labelValuesFor(apnListFiltered));
  };

  // Delete Sim
  const deleteSimCallback = (
    index: number,
    deletedSimId: string | undefined
  ) => {
    if (deletedSimId) {
      setDeletedSim(prev => [...prev, deletedSimId]);
      formikProps.values[index] = {
        simId: '',
        slot: initialValues[index].slot,
        iccId: '',
        activatedBy: {
          label: '',
          value: ''
        },
        apn: {
          label: '',
          value: ''
        },
        mdn: '',
        carrier: {
          label: '',
          value: ''
        },
        simStatus: activeSimStatus || {
          label: '',
          value: ''
        }
      };
      formikProps.setValues(formikProps.values);
    }
  };

  // Confirmation to delete sim modal
  const handleDeleteSim = (
    index: number,
    iccId: string,
    simId: string | undefined
  ) => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'large',
        title: '',
        lazyComponent: 'DeleteSim',
        lazyComponentProps: {
          id: simId,
          iccId,
          index,
          deleteSimCallback
        }
      })
    );
  };

  // Handle Sumbit
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formikProps.isValidating || !isNestedObjectEmpty(formikProps.errors)) {
      return;
    }
    const toastMessages: AlertObjectProp[] = [];
    try {
      if (deletedSim.length) {
        try {
          await deleteSims(deletedSim);
          toastMessages.push({
            message: 'SIMs deleted successfully.',
            severity: 'success'
          });
        } catch (err: any) {
          toastMessages.push({
            message: err.message || 'Error: Could not delete SIMs.',
            severity: 'error'
          });
        }
      }

      if (equipmentData || routerData) {
        const filteredValues = formikProps.values.filter(v => v.iccId);
        const manageSimsData = filteredValues.map(value => {
          return {
            id: value.simId,
            iccId: value.iccId,
            locationId:
              (pathIsEquipment
                ? equipmentData?.account?.id
                : routerData?.equipmentBundleItem?.equipmentBundle?.location
                    ?.id) || null,
            equipmentId: equipmentData?.id || null,
            simStatusId: value.simStatus.value,
            carrierId: value.carrier.value || null,
            routerId: routerData?.id || null,
            slot: parseInt(value.slot),
            ownership: value.activatedBy.value,
            apnId: value.apn.value || null,
            mdn: value.mdn || null
          };
        });
        if (manageSimsData.length) {
          await manageSims(manageSimsData);

          toastMessages.push({
            message: 'SIMs updated successfully.',
            severity: 'success'
          });
        }
      }
    } catch (err: any) {
      toastMessages.push({
        message: err.message || 'Error: could not update SIMs!',
        severity: 'error'
      });
    }
    dispatch(
      actions.showNotify({
        messages: toastMessages
      })
    );
    handleReset();
  };

  const handleReset = () => {
    history.goBack();
  };

  if (equipmentSimsLoading || equipmentDataLoading || apnDropdownLoading)
    return <div>Loading...</div>;

  return (
    <>
      <Breadcrumbs items={breadCrumbsItems} />
      <PageHeader title="Manage SIMs" subTitle={subTitle} />
      <form onSubmit={handleSubmit}>
        {formikProps.values.map((sim, i) => {
          const slotPath = `${i}.slot`;
          const inputError = formikProps.errors[i];
          const inputTouched = formikProps.touched[i];
          const inputValue = formikProps.values[i];

          return (
            <div key={i} className={styles.bottomBorder}>
              <Grid
                container
                justifyContent="space-between"
                className={styles.simsContainer}
              >
                <Grid item xs={1} sm={1} md={1}>
                  <TextInput
                    id={slotPath}
                    name={slotPath}
                    type="number"
                    size="small"
                    isError={!!inputTouched?.slot && !!inputError?.slot}
                    validationMessage={inputError?.slot}
                    label="Slot"
                    value={sim.slot}
                    onChange={handleTextInputOnChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid container item xs={12} sm={10} md={11} direction="column">
                  <Grid container spacing={isSmall ? 0 : 4}>
                    <Grid container item xs={8} md={4} alignItems="center">
                      <Grid item xs={10} md={10}>
                        <TextInput
                          id={`${i}.iccId`}
                          name={`${i}.iccId`}
                          disabled={Boolean(formikProps.values[i]?.simId)}
                          type="text"
                          inputDisabledStyle={styles.inputDisabledStyle}
                          isError={Boolean(
                            inputTouched?.iccId && inputError?.iccId
                          )}
                          validationMessage={inputError?.iccId}
                          label="ICCID"
                          value={inputValue.iccId}
                          onChange={handleTextInputOnChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={1} md={1} className={styles.iconStyle}>
                        {inputValue.simId ? (
                          <DeleteIconRed
                            onClick={() =>
                              handleDeleteSim(
                                i,
                                inputValue.iccId,
                                inputValue.simId
                              )
                            }
                          ></DeleteIconRed>
                        ) : (
                          <DeleteIconGrey></DeleteIconGrey>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Dropdown
                        id={`${i}.activatedBy`}
                        name={`${i}.activatedBy`}
                        placeholder="Select One"
                        isError={Boolean(
                          inputTouched?.activatedBy?.value &&
                            inputError?.activatedBy?.value
                        )}
                        validationMessage={inputError?.activatedBy?.value}
                        label="activated by"
                        onChange={e =>
                          handleChangeDropdown(e, `${i}.activatedBy`)
                        }
                        onBlur={handleBlur}
                        dropdownItems={activatedByOptions}
                        value={inputValue.activatedBy.value}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Dropdown
                        id={`${i}.carrier`}
                        name={`${i}.carrier`}
                        placeholder="Select One"
                        isError={Boolean(
                          inputTouched?.carrier?.value &&
                            inputError?.carrier?.value
                        )}
                        validationMessage={inputError?.carrier?.value}
                        label="carrier"
                        onChange={e => handleCarrierChange(e, i)}
                        onBlur={handleBlur}
                        dropdownItems={carrier}
                        value={inputValue.carrier.value}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={isSmall ? 0 : 4}>
                    <Grid item xs={8} md={4}>
                      <TextInput
                        id={`${i}.mdn`}
                        name={`${i}.mdn`}
                        type="text"
                        inputDisabledStyle={styles.inputDisabledStyle}
                        isError={Boolean(inputTouched?.mdn && inputError?.mdn)}
                        validationMessage={inputError?.mdn}
                        label="mdn"
                        value={inputValue.mdn}
                        onChange={handleTextInputOnChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Dropdown
                        id={`${i}.apn`}
                        name={`${i}.apn`}
                        placeholder="Select One"
                        disabled={inputValue.carrier.value === ''}
                        isError={Boolean(
                          inputTouched && inputError?.apn?.value
                        )}
                        validationMessage={inputError?.apn?.value}
                        label="apn"
                        onChange={e => handleChangeDropdown(e, `${i}.apn`)}
                        onBlur={handleBlur}
                        dropdownItems={
                          i === 0 ? filteredApnOne : filteredApnTwo
                        }
                        value={inputValue.apn.value}
                        autoComplete={false}
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Dropdown
                        id={`${i}.simStatus`}
                        name={`${i}.simStatus`}
                        placeholder="Select One"
                        isError={Boolean(
                          inputTouched && inputError?.simStatus?.value
                        )}
                        validationMessage={inputError?.simStatus?.value}
                        label="sim status"
                        onChange={e =>
                          handleChangeDropdown(e, `${i}.simStatus`)
                        }
                        onBlur={handleBlur}
                        dropdownItems={simStatus}
                        value={inputValue.simStatus.value}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          );
        })}

        <div className={styles.formButtons}>
          <Button
            isLoading={manageSimsLoading}
            disabled={formikProps.isSubmitting}
            type="button"
            onClick={handleReset}
            variant="outlined"
          >
            cancel
          </Button>
          <Button
            isLoading={manageSimsLoading}
            disabled={
              formikProps.isSubmitting ||
              (!formikProps.values.some(v => v.iccId) && !deletedSim.length)
            }
            type="submit"
          >
            submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default ManageSims;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { createHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';

import {
  gatewayDropDownInputs,
  gatewayRequiredInputs,
  gatewayTextInputs
} from './EquipmentConstants';
import GatewayCatalogSchema from './GatewayCatalogSchema';
import { GatewayCreateEditData, GatewayFormikValues } from 'types';

interface GatewayCreateDialogProps {
  refetch: () => void;
  handleClose: () => void;
}

const GatewayCreateDialog = ({
  refetch,
  handleClose
}: GatewayCreateDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const gatewayCreateInitialValues: GatewayFormikValues = {
    brand: '',
    model: '',
    power: '',
    fxoPorts: '4',
    ipAddressRequired: false,
    serialNumberRequired: false,
    macAddressRequired: false,
    imeiRequired: false
  };

  const formik = useFormik({
    initialValues: gatewayCreateInitialValues,
    validationSchema: GatewayCatalogSchema,
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    try {
      const data: GatewayCreateEditData = {
        ...formik.values,
        fxoPorts: parseInt(formik.values.fxoPorts)
      };
      // API call
      await createHardwareCatalog('gateway', data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Gateway has been created',
          severity: 'success'
        })
      );

      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: `Failed to create gateway: ${error.response?.data.message}`,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'Failed to create gateway',
            severity: 'error'
          })
        );
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Gateway"
      type="Create"
      formik={formik}
      handleClose={handleClose}
      textInputs={gatewayTextInputs}
      dropDownInputs={gatewayDropDownInputs}
      requiredInputs={gatewayRequiredInputs}
    />
  );
};

export default GatewayCreateDialog;

import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import useSort from 'hooks/useSort';
import { selectAuth } from 'app/slice/selectors';
import {
  HardwareDeleteDialog,
  HardwareDependencyError,
  HardwareLink,
  HardwareTablesActionMenu,
  HardwareTablesHeader
} from 'app/components/HardwareTables';
import { ColumnProps, TablePaginated } from 'design';
import { pageSizeOptions } from 'constants/app';
import { Catalogs, HardwareCatalog, Sort } from 'types';
import styles from './HardwareTables.module.scss';
import HardwareStatusDialog from './HardwareStatusDialog';

export interface HardwareCatalogDefaultPageProps {
  title: string;
  deviceColumns: ColumnProps[];
  getCatalogsHook: (
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ) => {
    data: any;
    isLoading: boolean;
    error: any;
    isError: boolean;
    refetch: () => void;
  };
  CreateDialog: React.ComponentType<{
    refetch: () => void;
    handleClose: () => void;
  }>;
  EditDialog: React.ComponentType<{
    actionedCatalog: HardwareCatalog;
    refetch: () => void;
    handleClose: () => void;
  }>;
}

const HardwareCatalogDefaultPage = ({
  title,
  deviceColumns,
  getCatalogsHook,
  CreateDialog,
  EditDialog
}: HardwareCatalogDefaultPageProps) => {
  const { permissions } = useSelector(selectAuth);
  const canEditEquipment = permissions?.includes('update:equipment');
  const canDeleteEquipment = permissions?.includes('delete:equipment');

  const [hardwareCatalogs, setHardwareCatalogs] = React.useState<
    HardwareCatalog[]
  >([]);
  const [total, setTotal] = React.useState(0);
  const [filter, setFilter] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState('');
  const [
    actionedCatalog,
    setActionedCatalog
  ] = React.useState<HardwareCatalog | null>(null);

  const [openModalIdentifier, setOpenModalIdentifier] = React.useState('');

  const [sort, setSort] = useSort(['model', 'ASC']);
  const { data, isLoading, error, isError, refetch } = getCatalogsHook(
    filter,
    sort,
    pageIndex,
    pageSize
  );

  if (isError) {
    throw new Error(`${error?.message} Failed to fetch Gateway catalogs!`);
  }

  React.useEffect(() => {
    if (data && !_.isEqual(data?.data, hardwareCatalogs)) {
      setHardwareCatalogs(data?.data);
      setTotal(data?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const clickDelete = (hardwareCatalog: HardwareCatalog) => {
    setActionedCatalog(hardwareCatalog);
    if (hardwareCatalog.locationCount > 0) {
      setOpenModalIdentifier('dependency-error');
    } else {
      setOpenModalIdentifier('delete');
    }
  };
  const clickEdit = (hardwareCatalog: HardwareCatalog) => {
    setActionedCatalog(hardwareCatalog);
    setOpenModalIdentifier('edit');
  };
  const clickStatus = (hardwareCatalog: HardwareCatalog) => {
    setActionedCatalog(hardwareCatalog);
    setOpenModalIdentifier('status');
  };

  const handleClose = () => {
    setOpenModalIdentifier('');
    setActionedCatalog(null);
  };

  let dialogInfo: {
    title: 'Router' | 'Gateway' | 'Power Source';
    identifier: Catalogs;
  } = {
    title: 'Router',
    identifier: 'router'
  };
  if (title === 'Gateways') {
    dialogInfo = {
      title: 'Gateway',
      identifier: 'gateway'
    };
  }
  if (title === 'Power Sources') {
    dialogInfo = {
      title: 'Power Source',
      identifier: 'powersource'
    };
  }

  // Table Columns
  const columns = React.useMemo<ColumnProps[]>(
    () => [
      ...deviceColumns.slice(0, 2),
      {
        accessor: 'status.name',
        label: 'Status',
        sortable: true,
        resizable: true,
        render: (hardwareCatalog: HardwareCatalog) => {
          return (
            <div
              className={styles.status}
              onClick={() => clickStatus(hardwareCatalog)}
            >
              {hardwareCatalog.status.name}
            </div>
          );
        }
      },
      ...deviceColumns.slice(2),
      {
        accessor: 'locationCount',
        label: 'Locations',
        sortable: true,
        resizable: true,
        render: (hardwareCatalog: HardwareCatalog) => {
          return (
            <HardwareLink
              model={hardwareCatalog.model}
              locationCount={hardwareCatalog.locationCount}
            />
          );
        }
      },
      {
        accessor: '',
        label: '',
        render: (hardwareCatalog: HardwareCatalog) => {
          return (
            <HardwareTablesActionMenu
              clickEdit={() => clickEdit(hardwareCatalog)}
              clickDelete={() => clickDelete(hardwareCatalog)}
            />
          );
        },
        hidden: !canEditEquipment && !canDeleteEquipment
      }
    ],
    [deviceColumns, canDeleteEquipment, canEditEquipment]
  );

  return (
    <>
      <HardwareTablesHeader
        title={title}
        searchInput={searchInput}
        setFilter={setFilter}
        setSearchInput={setSearchInput}
        setPageIndex={setPageIndex}
        plusButtonOnClick={() => setOpenModalIdentifier('create')}
      />
      <TablePaginated
        id={`${title}CatalogTable`}
        rows={hardwareCatalogs}
        sort={sort}
        total={total}
        columns={columns}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        isLoading={isLoading}
        handlePageSize={setPageSize}
        handlePageNav={setPageIndex}
        handleSort={setSort}
      />
      {/* Modals */}
      {openModalIdentifier === 'create' && (
        <CreateDialog refetch={refetch} handleClose={handleClose} />
      )}
      {openModalIdentifier === 'edit' && actionedCatalog && (
        <EditDialog
          actionedCatalog={actionedCatalog}
          refetch={refetch}
          handleClose={handleClose}
        />
      )}
      {openModalIdentifier === 'delete' && actionedCatalog && (
        <HardwareDeleteDialog
          title={dialogInfo.title}
          identifier={dialogInfo.identifier}
          actionedCatalog={actionedCatalog}
          refetch={refetch}
          handleClose={handleClose}
        />
      )}
      {openModalIdentifier === 'status' && actionedCatalog && (
        <HardwareStatusDialog
          identifier={dialogInfo.identifier}
          actionedCatalog={actionedCatalog}
          refetch={refetch}
          handleClose={handleClose}
        />
      )}
      {openModalIdentifier === 'dependency-error' && actionedCatalog && (
        <HardwareDependencyError
          actionedCatalog={actionedCatalog}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default HardwareCatalogDefaultPage;

import React, { useState } from 'react';
import * as yup from 'yup';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { useFormik } from 'formik';
import {
  IDTNumber,
  getIDTNumbers,
  purchaseIDTNumbers
} from 'api/useNumberAcquisition';
import { Button, Heading, TablePaginated } from 'design';
import useSort from 'hooks/useSort';
import styles from './numberpurchasetool.module.scss';
import { isNaN } from 'lodash';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import clsx from 'clsx';
import NumberDesignationForm, {
  DidAndDesignation
} from './NumberDesignationForm';
import NumberFinderForm from './NumberFinderForm';

interface N2PNumberToolProps {
  numberOfLines?: number;
  handleClose: () => void;
  purchasedCb?: (purchasedNumbers: DidAndDesignation[]) => void;
}

const N2PNumberTool = ({
  numberOfLines,
  handleClose,
  purchasedCb
}: N2PNumberToolProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  // Form needs
  const [sort] = useSort();
  const [results, setResults = useState] = useState<IDTNumber[]>([]);
  const [resultTotal, setResultTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Storage for the numbers we want to acquire
  const [desiredNums, setDesiredNums] = useState<IDTNumber[]>([]);

  // Purchase states
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const [designations, setDesignations] = useState<DidAndDesignation[]>([]);

  const numberFormSchema = yup.object().shape({
    country: yup.string().required('Country is required'),
    areaCode: yup.string().required('Area Code is required').min(3).max(3)
  });

  const [currentStep, setCurrentStep] = useState(1);

  const numberForm = useFormik({
    initialValues: {
      country: 'US',
      areaCode: '',
      pageSize: 8,
      pageNumber: 1
    },
    validationSchema: numberFormSchema,
    onSubmit: async values => {
      try {
        setIsError(false);
        setIsLoading(true);
        const data = await getIDTNumbers({
          country: values.country,
          areaCode: values.areaCode,
          pageSize: values.pageSize,
          pageNumber: values.pageNumber
        });
        setResults(data.data);
        setResultTotal(data.total);
        setCurrentStep(2);
      } catch (e) {
        setIsError(true);
        dispatch(
          actions.showNotify({
            message: e.message,
            severity: 'error'
          })
        );
      } finally {
        setIsLoading(false);
      }
    }
  });

  const buySkus = async (skus: string[]) => {
    try {
      setPurchaseLoading(true);
      const data = await purchaseIDTNumbers(skus);
      dispatch(
        actions.showNotify({
          message: data.statusMessage,
          severity: 'success'
        })
      );

      if (purchasedCb) {
        purchasedCb(designations);
      } else {
        // If there's no callback, we'll refresh the list of numbers
        numberForm.handleSubmit();
      }
      // Clear the desired numbers
      setDesiredNums([]);
    } catch (e) {
      dispatch(
        actions.showNotify({
          message: e.message,
          severity: 'error'
        })
      );
    } finally {
      setPurchaseLoading(false);
    }
  };

  const selectNumberStep = () => {
    if (desiredNums.length > 0) {
      setCurrentStep(3);
    } else {
      dispatch(
        actions.showNotify({
          message: 'You must select at least one number.',
          severity: 'warning'
        })
      );
    }
  };

  return (
    <div className="dialog-container">
      <div className="sub-header">
        <Heading variant="h5">Purchase Numbers</Heading>
      </div>
      <form onSubmit={numberForm.handleSubmit}>
        <div className={styles.numberFormOverride}>
          {currentStep === 1 && (
            <NumberFinderForm
              handleChange={numberForm.handleChange}
              errors={numberForm.errors}
              values={numberForm.values}
            />
          )}
          {currentStep === 2 && (
            <>
              <Typography variant="body1">Designate phone numbers.</Typography>
              {!isError ? (
                <div className={styles.numberToolInner}>
                  <TablePaginated
                    id="n2pNumbers"
                    rows={results}
                    sort={sort}
                    total={resultTotal}
                    columns={[
                      {
                        accessor: '',
                        label: ' ',
                        render: num => {
                          return (
                            <>
                              <IconButton size="small">
                                {desiredNums
                                  .map(({ sku }) => sku)
                                  .includes(num.sku) ? (
                                  <RemoveCircle
                                    onClick={() => {
                                      setDesiredNums(
                                        desiredNums.filter(
                                          ({ sku }) => sku !== num.sku
                                        )
                                      );
                                    }}
                                    className={
                                      styles.numberActionColumnActionRemove
                                    }
                                  />
                                ) : (
                                  <AddCircle
                                    onClick={() => {
                                      if (
                                        numberOfLines &&
                                        desiredNums.length > numberOfLines - 1
                                      ) {
                                        dispatch(
                                          actions.showNotify({
                                            message: `You can only select up to ${numberOfLines} numbers.`,
                                            severity: 'warning'
                                          })
                                        );
                                        return;
                                      }
                                      setDesiredNums(cur => {
                                        return [...cur, num];
                                      });
                                    }}
                                    className={styles.numberActionColumnAction}
                                  />
                                )}
                              </IconButton>
                            </>
                          );
                        },
                        resizable: true
                      },
                      {
                        accessor: 'did',
                        label: 'Number',
                        resizable: true
                      },
                      {
                        accessor: 'sku',
                        label: 'SKU',
                        resizable: true
                      }
                    ]}
                    handlePageSize={(size: number) => {
                      if (isNaN(size)) return;
                      numberForm.setFieldValue('pageSize', size);
                      numberForm.handleSubmit();
                    }}
                    handlePageNav={(page: number) => {
                      if (isNaN(page)) return;
                      numberForm.setFieldValue('pageNumber', page);
                      numberForm.handleSubmit();
                    }}
                    pageIndex={numberForm.values.pageNumber}
                    pageSize={8}
                    pageSizeOptions={[
                      {
                        label: '8',
                        value: '8'
                      }
                    ]}
                    isLoading={isLoading}
                    includeDropdown={false}
                    handleSort={() => {}}
                  />
                </div>
              ) : (
                <p>Something went wrong fetching numbers</p>
              )}
            </>
          )}
          {currentStep === 3 && (
            <>
              <NumberDesignationForm
                desiredNumbers={desiredNums}
                handleDesignation={(numDesignations: DidAndDesignation[]) => {
                  setDesignations(numDesignations);
                }}
              />
            </>
          )}
          <div className={clsx(['form-footer', styles.bSpacing])}>
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={isLoading || purchaseLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={currentStep === 1 || isLoading || purchaseLoading}
              variant="outlined"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            >
              Previous
            </Button>
            <Button
              disabled={isLoading || purchaseLoading}
              onClick={async () => {
                if (currentStep === 1) {
                  setDesiredNums([]);
                  numberForm.handleSubmit();
                } else if (currentStep === 2) {
                  selectNumberStep();
                } else if (currentStep === 3) {
                  await buySkus(desiredNums.map(({ sku }) => sku));
                }
              }}
            >
              {isLoading || purchaseLoading ? (
                <>
                  <CircularProgress size={20} />
                </>
              ) : (
                <>{currentStep === 3 ? 'Confirm' : 'Next'}</>
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default N2PNumberTool;

import React from 'react';
import { useDispatch } from 'react-redux';

import { ColumnProps, TablePaginated, Text } from 'design';
import { useDashboardSlice } from 'app/layouts';
import PhoneNumber from '../PhoneNumber';
import useSort from 'hooks/useSort';
import { pageSizeOptions, DidTypes } from 'constants/app';
import useLocationLines, {
  useLocationLinesWithE911
} from 'api/useLocationLines';
import ManageAllEmergencyLines from 'app/components/ManageAllEmergencyLines';
import { LocationLinesWithE911 } from '../../../types';

export interface LocationEmergencyLinesProps {
  locationId: string;
}

interface ApiError extends Error {
  response?: {
    data?: {
      message?: string;
    };
  };
}

const LocationEmergencyLines = ({
  locationId
}: LocationEmergencyLinesProps): JSX.Element => {
  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [lineWithPsap, setLineWithPsap] = React.useState<
    LocationLinesWithE911[]
  >([]);

  const { data: locationLines, isLoading } = useLocationLines(locationId);

  const {
    data: locationLinesWithE911,
    isLoading: psapIsLoading,
    error,
    refetch
  } = useLocationLinesWithE911(locationId);

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      {
        accessor: 'value',
        label: 'phone number',
        render: ({ value }) => {
          return <PhoneNumber>{value}</PhoneNumber>;
        },
        resizable: true
      },
      {
        accessor: 'didType',
        label: 'did type',
        render: ({ didType }) => <>{didType}</>,
        resizable: true
      },
      {
        accessor: 'emergencyAddressRegistered',
        label: 'e911 address',
        render: ({ emergencyAddressRegistered }) =>
          emergencyAddressRegistered ? <>Registered</> : <> Not Registered</>,
        resizable: true
      },
      {
        accessor: 'emergencyAddress',
        label: 'Line address',
        render: ({ emergencyAddress }) => (
          <>{`${emergencyAddress.address1}${
            emergencyAddress.address2 ? ', ' + emergencyAddress.address2 : ''
          }, ${emergencyAddress.city}, ${emergencyAddress.state} ${
            emergencyAddress.postalCode
          }`}</>
        ),
        resizable: true
      },
      {
        accessor: 'psapAddress',
        label: 'psap address',
        verticalAlign: 'top',
        render: ({ psapAddress }) => {
          if (error) {
            return <Text color="error">Error fetching PSAP Address</Text>;
          }
          if (psapAddress) {
            return (
              <>{`${psapAddress.address1}${
                psapAddress.address2 ? ', ' + psapAddress.address2 : ''
              }, ${psapAddress.city}, ${psapAddress.state} ${
                psapAddress.postalCode
              }`}</>
            );
          }
          if (!psapIsLoading) {
            return <Text color="error">Not Provisioned</Text>;
          }
          return <></>;
        },
        resizable: true,
        cellWidth: '50%'
      }
    ],
    [error, psapIsLoading]
  );

  React.useEffect(() => {
    if (error) {
      const apiError = error as ApiError;

      dispatch(
        actions.showNotify({
          message: `Error: ${
            apiError?.response?.data?.message || 'failed to get psap address'
          }`
        })
      );
    }
  }, [error, dispatch, actions]);

  React.useEffect(() => {
    if (locationLinesWithE911) {
      setLineWithPsap(locationLinesWithE911);
      return;
    }
    if (locationLines) {
      const linesWithDefaultPsap: LocationLinesWithE911[] = [];
      // Separate Temp and Perm DIDs
      for (const line of locationLines.lines) {
        linesWithDefaultPsap.push({
          ...line,
          id: line.id + 'perm', // make a distinction between id set with locationLinesWithE911 to avoid duplicate key
          didType: DidTypes.PERM
        });
        if (line.tempPortingNumber) {
          linesWithDefaultPsap.push({
            ...line,
            value: parseInt(line.tempPortingNumber),
            didType: DidTypes.TEMP
          });
        }
      }
      setLineWithPsap(linesWithDefaultPsap);
      return;
    }
  }, [locationLines, locationLinesWithE911]);

  return (
    <>
      <div>
        <ManageAllEmergencyLines
          locationId={locationId}
          lineWithPsap={lineWithPsap.filter(
            lines => lines.didType === DidTypes.PERM
          )}
          refetch={refetch}
        />
      </div>
      <TablePaginated
        id={locationId}
        rows={lineWithPsap}
        sort={sort}
        total={lineWithPsap.length}
        columns={columns}
        handlePageSize={setPageSize}
        handlePageNav={setPageIndex}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        handleSort={setSort}
        isLoading={isLoading}
        hidePagination={true}
      />
    </>
  );
};

export default LocationEmergencyLines;

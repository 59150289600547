import React from 'react';
import { Text } from 'design';
import { Grid } from '@material-ui/core';

import SerialNumberFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/SerialNumberFieldInput';
import { EquipmentAndHardwareFormsProps } from '../types';
import ImeiFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/ImeiFieldInput';

const CommunicatorForm = ({
  subName: sub,
  formContext
}: EquipmentAndHardwareFormsProps): JSX.Element => {
  const { register } = formContext();

  const subName = sub !== undefined ? `communicators.${sub}.` : '';

  const serialNumberInput = register(subName + 'serialNumber');
  const imeiInput = register(subName + 'imei');

  return (
    <div className={'form-container'}>
      <Text className={'sub-header'}>Create Communicator</Text>
      <Grid container className={'grid-container'}>
        <Grid item xs={12} md={6}>
          <div className={'input-wrapper'}>
            <SerialNumberFieldInput
              value={serialNumberInput.value}
              isError={serialNumberInput.isError}
              validationMessage={serialNumberInput.validationMessage}
              onChange={serialNumberInput.onChange}
              onBlur={serialNumberInput.onBlur}
              name={serialNumberInput.name}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={'input-wrapper'}>
            <ImeiFieldInput
              value={imeiInput.value}
              isError={imeiInput.isError}
              validationMessage={imeiInput.validationMessage}
              onChange={imeiInput.onChange}
              onBlur={imeiInput.onBlur}
              name={imeiInput.name}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunicatorForm;

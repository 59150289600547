import { makeStyles, Theme, IconButton } from '@material-ui/core';
import React from 'react';

import EditLocationOutlinedIcon from '@material-ui/icons/EditLocationOutlined';

export interface EditLocationProps {
  active?: boolean;
  className?: string;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const EditLocation = ({
  active,
  className,
  handleClick
}: EditLocationProps): JSX.Element => {
  const cc = useStyles();

  return (
    <IconButton
      classes={{
        root: cc.iconRootMedium
      }}
      size="medium"
      disableRipple={active}
      disableFocusRipple={active}
      onClick={e => {
        if (handleClick) {
          handleClick(e);
        }
      }}
    >
      <EditLocationOutlinedIcon classes={{ root: cc.iconRoot }} />
    </IconButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  iconRoot: {
    color: theme.palette.primary.main
  },
  iconRootMedium: {
    display: 'inline-block',
    fontSize: 23
  }
}));

export default EditLocation;

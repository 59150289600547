import React from 'react';

import { usePowerSourceCatalogs } from 'api/useHardwareCatalogs';
import {
  HardwareCatalogDefaultPage,
  PowerSourceCreateDialog,
  PowerSourceEditDialog
} from 'app/components/HardwareTables';
import { ColumnProps } from '../../../design';

const PowerSourcesPage = (): JSX.Element => {
  const powersourceColumns: ColumnProps[] = [
    {
      accessor: 'brand',
      label: 'Brand',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'model',
      label: 'Model',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'power',
      label: 'Power',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'maxBatteryCount',
      label: 'Max Battery Count',
      sortable: true,
      resizable: true
    }
  ];

  return (
    <HardwareCatalogDefaultPage
      title="Power Sources"
      deviceColumns={powersourceColumns}
      getCatalogsHook={usePowerSourceCatalogs}
      CreateDialog={PowerSourceCreateDialog}
      EditDialog={PowerSourceEditDialog}
    />
  );
};

export default PowerSourcesPage;

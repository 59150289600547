import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';
import { Note } from 'types/Note';

export interface GetLocationNotesParams {
  queryKey: [__0: string, locationId: string, pageIndex: number, limit: number];
}

export interface PagedNotes {
  notes: Note[];
  total: number;
}

const getLocationNotes = async ({
  queryKey
}: GetLocationNotesParams): Promise<PagedNotes> => {
  const [, locationId, pageIndex, limit] = queryKey;
  const { data } = await axios.get(`/accounts/${locationId}/notes`, {
    params: { limit, sort: 'createdAt|desc', page: pageIndex }
  });
  const { data: fetchedNotes, total } = data;
  const notes: Note[] = fetchedNotes;
  return { notes: notes, total };
};

const useLocationNotes = (
  locationId: string,
  pageIndex: number,
  limit = 1
): UseQueryResult<PagedNotes, Error> => {
  return useQuery<PagedNotes, Error>(
    [query.locationNotes, locationId, pageIndex, limit],
    getLocationNotes
  );
};

export interface AddLocationNoteParams {
  locationId: string;
  text: string;
  userId: string;
}

/**
 * This creates a new Location  through the cmdc api
 * @param {AddLocationNoteParams}
 * @returns {Promise}
 */
const postLocationNote = async ({
  locationId,
  userId,
  text
}: AddLocationNoteParams): Promise<void> => {
  await axios.post(`/accounts/${locationId}/notes`, {
    userId,
    text
  });
};

const useCreateLocationNote = (): UseMutationResult<
  void,
  AxiosError,
  AddLocationNoteParams
> => {
  const queryClient = useQueryClient();
  // TODO: change this to handle optimistic rendering in future. Implement onMutate as seen in api/useLocationMedia
  const mutateNote = useMutation<void, AxiosError, AddLocationNoteParams>(
    postLocationNote,
    {
      // On failure, roll back to the previous value
      onError: (_err, locationNotesParams, context) => {
        queryClient.setQueryData(
          [query.locationNotes, locationNotesParams.locationId],
          context
        );
      },
      // After success or failure, refetch the media query
      onSettled: (_pagedMedia, _error, locationNotesParams) => {
        queryClient.invalidateQueries([
          query.locationNotes,
          locationNotesParams.locationId
        ]);
      }
    }
  );

  return mutateNote;
};

export { useCreateLocationNote, useLocationNotes };

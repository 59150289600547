import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export type TextProps = TypographyProps;
/**
 * This is a simple wrapper component for the Mui Typography component. It just
 * has a few chosen defaults.
 * @param {TextProps}
 * @returns {JSX.Element}
 */
const Text = ({ children, ...props }: TypographyProps): JSX.Element => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Typography {...props}>{children}</Typography>;
};

export default Text;

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';
import { Sim } from 'types/Sim';
import { isEmpty } from 'lodash';
import qs from 'qs';

interface GetSimsParams {
  queryKey: [
    __0: string,
    filter: string,
    andFilter: string,
    pageIndex: number,
    pageSize: number
  ];
}

export interface PagedSims {
  sims: Sim[];
  total: number;
}

interface GetSimsByEquipmentParams {
  queryKey: [__0: string, simId: string, pathName?: string];
}

interface Carrier {
  id: string;
  name: string;
  defaultApnId: string;
}

interface SimStatus {
  id: string;
  name: string;
}

interface Apn {
  id: string;
  name: string;
  carrierId: string;
}
export interface GetApnListParams {
  queryKey: [__0: string, limit: number];
}

export interface CarriersParams {
  queryKey: [__0: string, limit: number];
}

interface PostSimParams {
  iccId: string;
  locationId: string | null;
  simStatusId: string;
  carrierId: string | null;
  equipmentId?: string | null;
  routerId?: string | null | undefined;
  slot: number;
  ownership: string;
  apnId: string | null;
  mdn: string | null;
}

const decanonizeSims = (sims: any): Sim => {
  const {
    id,
    iccId,
    apn,
    mdn,
    simStatus,
    carrier,
    slot,
    ownership,
    equipment
  } = sims;
  return {
    id,
    iccId,
    apn,
    mdn,
    simStatus,
    carrier,
    slot,
    ownership,
    equipment
  };
};

const getSims = async ({ queryKey }: GetSimsParams) => {
  const [, filter, andFilter, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [`sim.routerId|eq|${filter}`]
      }
    : {};
  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';
  const possibleStatusFilter = andFilter ? { [paramFilterKey]: andFilter } : {};
  const { data } = await axios.get(`/sims`, {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort: '|ASC',
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  const sims: Sim[] = data.data.map(decanonizeSims);

  return {
    sims,
    total: Number(data.total)
  };
};

const iccIdExists = async (iccId: string) => {
  const checkIccId = iccId.toString();
  const { data } = await axios.get(`/sims?filter=iccId|eq|${checkIccId}`);
  return data.data.length > 0;
};

const useSims = (
  filter: string,
  andFilter: string,
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedSims, AxiosError> => {
  return useQuery<PagedSims, AxiosError>(
    [query.sims, filter, andFilter, pageIndex, pageSize],
    getSims,
    {
      keepPreviousData: true
    }
  );
};

const getSimsByEquipment = async ({
  queryKey
}: GetSimsByEquipmentParams): Promise<Sim[]> => {
  const [, equipmentId, pathName] = queryKey;

  const url =
    pathName === 'router'
      ? `/sims/router/${equipmentId}`
      : `/equipment/${equipmentId}/sims`;

  const { data } = await axios.get(url);
  return data.data;
};

const useSimsByEquipment = (
  equipmentId: string,
  pathName?: string
): UseQueryResult<Sim[], Error> => {
  return useQuery<Sim[], AxiosError>(
    [query.sims, equipmentId, pathName],
    getSimsByEquipment
  );
};

const getCarriers = async ({
  queryKey
}: CarriersParams): Promise<Carrier[]> => {
  const [, limit] = queryKey;
  const { data } = await axios.get(`/carriers`, {
    params: {
      limit: limit
    }
  });
  return data.data;
};

const useCarriers = (limit: number): UseQueryResult<Carrier[], Error> => {
  return useQuery<Carrier[], AxiosError>([query.carriers, limit], getCarriers);
};

const getSimStatuses = async (): Promise<SimStatus[]> => {
  const { data } = await axios.get(`/sim-status`);
  return data.data;
};

const useSimStatuses = (): UseQueryResult<SimStatus[], Error> => {
  return useQuery<SimStatus[], AxiosError>([query.carriers], getSimStatuses);
};

const getApnList = async ({ queryKey }: GetApnListParams): Promise<Apn[]> => {
  const [, limit] = queryKey;
  const { data } = await axios.get(`/apn`, {
    params: {
      limit: limit
    }
  });
  return data.data;
};

const useApnList = (limit: number): UseQueryResult<Apn[], Error> => {
  return useQuery<Apn[], AxiosError>([query.apnList, limit], getApnList);
};

const manageSims = async (createData: PostSimParams[]) => {
  const { data } = await axios.post(`/sims/manage-sims`, { data: createData });
  return data.id;
};

const useManageSims = (): UseMutationResult<
  string,
  AxiosError,
  PostSimParams[]
> => {
  const queryClient = useQueryClient();
  const mutatePostSim = useMutation<string, AxiosError, PostSimParams[]>(
    manageSims,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.equipment]);
      }
    }
  );
  return mutatePostSim;
};

const deleteSim = async (id: string): Promise<void> => {
  await axios.delete(`/sims/${id}`);
};

const useDeleteSim = (id: string): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteOrg = useMutation<void, AxiosError, any>(deleteSim, {
    onSettled: () => {
      queryClient.invalidateQueries([query.equipments]);
    }
  });

  return mutateDeleteOrg;
};

const deleteSims = async (ids: string[]): Promise<void> => {
  await axios.delete(`/sims`, { data: { simIds: ids } });
};

const useDeleteSims = (): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteOrg = useMutation<void, AxiosError, any>(deleteSims, {
    onSettled: () => {
      queryClient.invalidateQueries([query.equipments]);
    }
  });

  return mutateDeleteOrg;
};

export {
  useSimStatuses,
  useCarriers,
  useApnList,
  useManageSims,
  useDeleteSims,
  useSims,
  useDeleteSim,
  useSimsByEquipment,
  iccIdExists
};

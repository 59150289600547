import React from 'react';

import MacAddressFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/MacAddressFieldInput';
import SerialNumberFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/SerialNumberFieldInput';
import ImeiFieldInput from 'app/components/EquipmentAndHardwareFieldInputs/ImeiFieldInput';
import { Grid } from '@material-ui/core';
import { Text, TextInput } from 'design';
import { EquipmentAndHardwareFormsProps } from '../types';

export default function HybridForm({
  subName,
  formContext,
  maxCount
}: EquipmentAndHardwareFormsProps) {
  const { register } = formContext();
  const sub = subName !== undefined ? `hybrids.${subName}.` : '';
  const serialNumberInput = register(sub + 'serialNumber');
  const macInput = register(sub + 'mac');
  const domainInput = register(sub + 'domain');
  const imeiInput = register(sub + 'imei');

  return (
    <div className={'form-container'}>
      <Text className={'sub-header'}>Create DataRemote</Text>
      <Grid container className={'grid-container'}>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <SerialNumberFieldInput
            value={serialNumberInput.value}
            isError={serialNumberInput.isError}
            validationMessage={serialNumberInput.validationMessage}
            onChange={serialNumberInput.onChange}
            onBlur={serialNumberInput.onBlur}
            name={serialNumberInput.name}
          />
        </Grid>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <ImeiFieldInput
            value={imeiInput.value}
            isError={imeiInput.isError}
            validationMessage={imeiInput.validationMessage}
            onChange={imeiInput.onChange}
            onBlur={imeiInput.onBlur}
            name={imeiInput.name}
          />
        </Grid>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <MacAddressFieldInput
            value={macInput.value}
            isError={macInput.isError}
            validationMessage={macInput.validationMessage}
            onChange={macInput.onChange}
            onBlur={macInput.onBlur}
            name={macInput.name}
          />
        </Grid>
        <Grid item xs={12} md={6} className={'input-wrapper'}>
          <TextInput
            id="domain"
            label="domain"
            value={domainInput.value}
            isError={domainInput.isError}
            validationMessage={domainInput.validationMessage}
            onChange={domainInput.onChange}
            onBlur={domainInput.onBlur}
            name={domainInput.name}
          />
        </Grid>
      </Grid>
    </div>
  );
}

import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDashboardSlice } from 'app/layouts';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import useCurrentUser from 'api/useCurrentUser';
import cc from './manageLocation.module.scss';

interface ManageLocationProp {
  id: string;
}
const ManageLocation = ({ id }: ManageLocationProp): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin || currentUser?.isOrgAdmin)
      setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'Operational Status',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Admin State',
            lazyComponent: 'LocationAdminState',
            size: 'large',
            closeable: true,
            lazyComponentProps: {
              id
            }
          })
        );
      }
    }
  ];

  return (
    <>
      <div className={cc.buttonWrapper}>
        <Button classes={{ root: cc.button }} onClick={handleMenuClick}>
          Manage
          <ArrowDropDownIcon fontSize="small" className={cc.dropDownIcon} />
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: cc.paper, list: cc.list }}
        className={cc.menuBar}
      >
        {menuItems.map(({ label, action, isVisible }, i: number) => {
          return (
            <div key={i}>
              {isVisible && (
                <MenuItem
                  button
                  onClick={() => {
                    action();
                    if (handleMenuClose) {
                      handleMenuClose();
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};
export default ManageLocation;

import { Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import UserCan from 'app/components/UserCan';
import clsx from 'clsx';
import React from 'react';
import OrgAutoCompleteDropdown from '../../app/components/OrgDropdown/OrgAutoCompleteDropdown';
import {
  permissions as APP_PERMISSIONS,
  envColorMap
} from '../../constants/app';
import Heading from '../Heading';
import { useSidebar } from '../theme';
import useCurrentUser from '../../api/useCurrentUser';
import useApiInfo from '../../api/useApiInfo';

export interface AppbarProps {
  // defaultOpen?: boolean;
  children?:
    | React.ReactNode
    | React.ReactNode[]
    | string
    | JSX.Element
    | JSX.Element[];
}

/**
 * This is the themed main Appbar for the MarketSpark Cassian FE.
 * @param {AppbarProps} props
 */
const AppBarWrapper = ({ children }: AppbarProps): JSX.Element => {
  let iconFill = '';
  const { data: apiInfo } = useApiInfo();
  const { data: currentUser } = useCurrentUser();
  if (currentUser?.isSuperAdmin) {
    iconFill = envColorMap[apiInfo?.environment || ''];
  }
  const classes = useStyles(iconFill)();
  const [open, setOpen] = useSidebar();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx({
          [classes.appBar]: true,
          [classes.appBarShift]: open
        })}
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="toggle sidebar"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
            <div className={classes.innerToolbar}>
              <Heading variant="h1" className={classes.cmdcHeading}>
                Command Center
              </Heading>
            </div>
          </Hidden>
          <div className={classes.innerToolbarRight}>{children}</div>
        </Toolbar>
        <Hidden smUp>
          <UserCan
            perform={APP_PERMISSIONS.users.scope}
            yes={
              <div className={classes.orgDropDownContainer}>
                <OrgAutoCompleteDropdown userOrgId="userOrgDropdownMd" />
              </div>
            }
          />
        </Hidden>
      </AppBar>
    </div>
  );
};

const useStyles = (toolbarBackgroundColor: string) =>
  makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1
    },
    cmdcHeading: {
      fontSize: '1rem',
      textTransform: 'uppercase',
      textAlign: 'center'
    },
    toolbar: {
      ...theme.mixins.toolbar,
      color: theme.palette.common.white,
      backgroundColor: toolbarBackgroundColor,
      '& svg': {
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
          color: theme.palette.common.white
        }
      }
    },
    innerToolbarRight: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
      }
    },
    innerToolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      width: '100%',
      textAlign: 'center'
    },
    appBar: {
      gap: '2px',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(8) + 1}px)`
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
      },
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    },
    appBarShift: {
      marginLeft: theme.mixins.sidebar.width,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.mixins.sidebar.width}px)`
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    orgDropDownContainer: {
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 55,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
      }
    }
  }));

export default AppBarWrapper;

import * as React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';

export default function MapLegends() {
  const cc = useStyles();
  return (
    <Grid container className={cc.wrapper}>
      <div className={cc.iconWrapper}>
        <span className={`${cc.legendIcon} ${cc.impactedStore}`}></span>
        <p className={cc.text}>Impacted location</p>
      </div>
      <div className={cc.iconWrapper}>
        <span className={`${cc.legendIcon} ${cc.impactedRadius}`}></span>
        <p className={cc.text}>Impacted zone</p>
      </div>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    columnGap: theme.spacing(2),
    fontSize: '.9rem',
    marginBottom: 10
  },
  iconWrapper: {
    display: 'flex',
    columnGap: theme.spacing(1),
    alignItems: 'center'
  },
  legendIcon: {
    fontSize: '.8rem',
    display: 'inline-block',
    width: 13,
    height: 13,
    borderRadius: 10
  },
  impactedStore: {
    backgroundColor: '#222222',
    borderColor: '#fff'
  },
  impactedRadius: {
    backgroundColor: '#F71735'
  },
  text: {
    margin: 0,
    fontSize: '.8rem'
  }
}));

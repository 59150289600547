import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { selectCurrentUser } from '../../../store/selectors';
import BundleTemplateActions from 'app/components/BundleTemplateActions';
import PageHeader from 'app/components/PageHeader';
import { useDashboardSlice } from 'app/layouts';
import { pageSizeOptions } from 'constants/app';
import { ColumnProps, SearchInput, TablePaginated, Text } from 'design';
import useSort from 'hooks/useSort';
import { BundleTemplate } from '../../../types';
import { useBundleTemplates } from 'api/useBundleTemplate';
import styles from './BundlesPage.module.scss';
import HardwareStatusDialog from '../../components/HardwareTables/HardwareStatusDialog';

const BundlesPage = (): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);
  const [searchInput, setSearchInput] = React.useState('');
  const [sort, setSort] = useSort();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [filter, setFilter] = React.useState('');
  const { isLoading, data, error, isError, refetch } = useBundleTemplates(
    filter,
    sort,
    pageIndex,
    pageSize
  );
  const [showStatusDialog, setShowStatusDialog] = React.useState(false);
  const [actionedBundle, setActionedBundle] = React.useState<BundleTemplate>();

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const { bundleTemplates, total } = data || { bundleTemplates: [], total: 0 };

  if (isError) {
    throw new Error(`${error?.message} Failed to fetch  Bundle template!`);
  }

  const clickStatus = (bundleCatalog: BundleTemplate) => {
    setActionedBundle(bundleCatalog);
    setShowStatusDialog(true);
  };

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      {
        accessor: 'name',
        label: 'Name',
        render: ({ name }: BundleTemplate) => <div>{name}</div>,
        sortable: true,
        resizable: true
      },
      {
        accessor: 'status.name',
        label: 'Status',
        sortable: true,
        resizable: true,
        render: (bundleCatalog: BundleTemplate) => {
          return (
            <div
              className={styles.status}
              onClick={() => clickStatus(bundleCatalog)}
            >
              {bundleCatalog.status?.name}
            </div>
          );
        }
      },
      {
        accessor: 'routers',
        label: 'Router',
        render: ({ routersCatalog }: BundleTemplate) => (
          <>
            {routersCatalog?.length ? (
              <div>
                {routersCatalog[0].model}
                <Text className={styles.subRow}>
                  {routersCatalog[0].isCellular && 'Cellular'}
                  {routersCatalog[0].isCellular &&
                    routersCatalog[0].isWired &&
                    '-'}
                  {routersCatalog[0].isWired && 'Wired'}
                </Text>
              </div>
            ) : (
              '-'
            )}
          </>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'gatewayOneModel',
        label: 'GateWay One',
        render: ({ gatewaysCatalog }: BundleTemplate) => (
          <>
            {gatewaysCatalog?.length ? (
              <div>
                {gatewaysCatalog[0].model}
                <Text className={styles.subRow}>
                  {gatewaysCatalog[0].fxoPorts} Port Count
                </Text>
              </div>
            ) : (
              '-'
            )}
          </>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'gatewayTwoModel',
        label: 'GateWay two',
        render: ({ gatewaysCatalog }: BundleTemplate) => (
          <div>
            {gatewaysCatalog && gatewaysCatalog?.length > 1 ? (
              <div>
                {gatewaysCatalog[1].model}
                <Text className={styles.subRow}>
                  {gatewaysCatalog[1].fxoPorts} Port Count
                </Text>
              </div>
            ) : (
              '-'
            )}
          </div>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: 'powersources_catalog.model',
        label: 'Power Supply',
        render: ({ powersourcesCatalog }: BundleTemplate) => (
          <>
            {powersourcesCatalog && (
              <div>
                <Text>{powersourcesCatalog[0]?.model}</Text>
                {powersourcesCatalog[0]?.batteryCount &&
                powersourcesCatalog[0]?.batteryCount > 0 ? (
                  <Text className={styles.subRow}>
                    {powersourcesCatalog[0].batteryCount} Battery Count
                  </Text>
                ) : (
                  '-'
                )}
              </div>
            )}
          </>
        ),
        sortable: true,
        resizable: true
      },
      {
        accessor: '',
        label: '',
        render: ({ id, name }: BundleTemplate) => (
          <BundleTemplateActions id={id} bundleName={name} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleAddLoc = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Create Bundle Template',
        lazyComponent: 'BundleTemplateCreate'
      })
    );
  }, [actions, dispatch]);

  return (
    <>
      <Helmet>
        <title> Bundles </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={styles.headWrapper}>
        <PageHeader title="Bundles" />
        <div className={styles.optionsWrapper}>
          <SearchInput
            className={styles.searchInput}
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          {currentUser?.isSuperAdmin ? (
            <IconButton
              onClick={() => handleAddLoc()}
              data-testid="addButton"
              classes={{ root: styles.addLocationButton }}
            >
              <AddCircleIcon
                color="primary"
                classes={{ root: styles.addLocationIcon }}
              />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <TablePaginated
          id="BundleTemples"
          rows={bundleTemplates}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>

      {showStatusDialog && actionedBundle && (
        <HardwareStatusDialog
          identifier="bundle"
          actionedCatalog={actionedBundle}
          refetch={refetch}
          handleClose={() => setShowStatusDialog(false)}
        />
      )}
    </>
  );
};

export default BundlesPage;

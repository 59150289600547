import * as MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import cx from 'clsx';
import HomeIcon from '@material-ui/icons/Home';
import { Theme, makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { dashboardUri } from 'constants/app';

export interface BreadcrumbsItem {
  label: React.ReactNode;
  url: string;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

const MatuiBreadcrumbs = MuiBreadcrumbs.default;

const Breadcrumbs = ({ items }: BreadcrumbsProps): JSX.Element => {
  const cc = useStyles();
  return (
    <MatuiBreadcrumbs
      aria-label="breadcrumb"
      classes={{
        root: cc.root,
        separator: cc.separator
      }}
      separator={
        <ArrowRightIcon fontSize="small" classes={{ root: cc.homeIcon }} />
      }
    >
      <Link to={dashboardUri}>
        <HomeIcon classes={{ root: cc.homeIcon }} fontSize="small" />
      </Link>
      {items.map(({ label, url, handleClick }, itemIndex) => {
        const activeClass = cx(cc.li, {
          [cc.activeLi]: itemIndex === items.length - 1
        });
        return (
          <NavLink
            color="inherit"
            to={url}
            onClick={handleClick}
            className={activeClass}
            key={url}
          >
            {label}
          </NavLink>
        );
      })}
    </MatuiBreadcrumbs>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'uppercase',
    padding: theme.spacing(3.25, 0, 4.25)
  },
  li: {
    display: 'block',
    paddingTop: theme.spacing(0.25),

    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    '& a:hover': {
      textDecoration: 'none'
    },
    '& .active': {
      color: theme.palette.primary.main
    },
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  separatorIcon: {
    color: theme.palette.grey[500],
    margin: theme.spacing(0, 1.5)
  },
  separator: {
    paddingBottom: theme.spacing(0.25),
    color: 'red'
  },
  homeIcon: {
    color: theme.palette.grey[500],
    marginBottom: -6
  },
  activeLi: {
    color: theme.palette.primary.main
  }
}));

export default Breadcrumbs;

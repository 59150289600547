export enum AssessmentScore {
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Acceptable = 'ACCEPTABLE',
  Fair = 'FAIR',
  Poor = 'POOR',
  Marginal = 'MARGINAL',
  NotAvailable = 'NOT_AVAILABLE'
}

// SignalStrengthMetrics are the metrics for a router
// regarding signal strength
export interface SignalStrengthMetrics {
  rsrq?: string;
  rsrp?: string;
  rssi?: string;
  rfBand?: string;
}

// RouterMetrics are all the metrics for a router
interface RouterMetrics extends SignalStrengthMetrics {
  id: string;
  uptime?: string;
  updatedAt?: string;
  model?: string;
  serialNumber?: string;
  assessment?: SignalStrengthAssessment;
  activeSim?: string;
}

export interface SignalStrengthAssessment {
  rsrq: AssessmentScore;
  rsrp: AssessmentScore;
  rssi: AssessmentScore;
  aggregate: AssessmentScore;
}

export interface InstallationMetrics {
  id: string;
  routers: RouterMetrics[];
}

import * as React from 'react';
import { Avatar, makeStyles, Theme, Typography } from '@material-ui/core';
import { Card, CardHeader, CardFooter, Text, Icon } from 'design';
import { useLocationInfo } from 'api/useLocations';
import { Phone as PhoneIcon, Email as EmailIcon } from '@material-ui/icons';
import { useOrg } from 'api/useOrgs';

export interface LocationContactDetailsProps {
  locationId: string;
  organizationId: string;
}

interface DetailItem {
  id: string | undefined;
  label: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  name: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
}

const RenderContact = ({
  contacts
}: {
  contacts: DetailItem[];
}): JSX.Element => {
  const cc = useStyles();

  const list = contacts.map(detail => {
    const avatarName = [
      detail.firstName?.charAt(0),
      detail.lastName?.charAt(0)
    ].filter(Boolean);
    return (
      <div key={detail.id} className={cc.rowWrapper}>
        <Typography color="primary">{detail.label}</Typography>
        <div className={cc.userCardContent}>
          <div>
            <Avatar
              data-testid="avatar-element"
              alt="User Profile Image"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={cc.avatar}
              classes={{ root: cc.avatarRoot }}
            >
              {avatarName.length ? avatarName?.join('') : '-'}
            </Avatar>
          </div>
          <div>
            <Text>
              {detail.firstName} {detail.lastName}
            </Text>
            <span className={cc.row}>
              <Icon Mark={PhoneIcon} fontSize="small" />{' '}
              <Text>
                {detail.phoneNumber && detail.phoneNumber !== ''
                  ? detail.phoneNumber
                  : '-'}
              </Text>
            </span>
            <span className={cc.row}>
              <Icon Mark={EmailIcon} fontSize="small" />{' '}
              <Text style={{ wordBreak: 'break-word' }}>{detail.email}</Text>
            </span>
          </div>
        </div>
      </div>
    );
  });

  return <>{list}</>;
};

const LocationContactDetails = ({
  locationId,
  organizationId
}: LocationContactDetailsProps): JSX.Element => {
  const cc = useStyles();
  const [cardVisible, setCardVisible] = React.useState<boolean>(true);
  const { data: locationData, isLoading } = useLocationInfo(locationId);
  const { data: orgData, isLoading: organizationLoading } = useOrg(
    organizationId
  );

  const locationContacts = React.useMemo(() => {
    if (!locationData) {
      return [];
    }

    return locationData?.locationContacts
      ?.map(locContact => ({
        id: locContact.contact?.id,
        label: locContact.contactType.name,
        firstName: locContact.contact?.firstName,
        lastName: locContact.contact?.lastName,
        name: locContact.contact?.name,
        phoneNumber: locContact.contact?.phoneMobile ?? '-',
        email: locContact.contact?.email
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) as DetailItem[];
  }, [locationData]);

  const orgContacts = React.useMemo(() => {
    if (!orgData) {
      return [];
    }

    return orgData?.organizationUsers
      ?.map(orgUser => ({
        id: orgUser.user?.id,
        label: `Organization ${orgUser.contactType.name}`,
        firstName: orgUser.user?.firstName,
        lastName: orgUser.user?.lastName,
        name: orgUser.user.name,
        phoneNumber: orgUser.user.phoneMobile ?? '-',
        email: orgUser.user.username
      }))
      .sort()
      .reverse() as DetailItem[];
  }, [orgData]);

  React.useEffect(() => {
    if (orgData && locationData) {
      setCardVisible(!!locationContacts?.length || !!orgContacts?.length);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, orgData]);

  return cardVisible ? (
    <Card>
      <CardHeader title="Contacts" />

      <div className={cc.cardContent}>
        <div className={cc.contentWrapper}>
          {isLoading || organizationLoading ? (
            <div className={cc.loading}>Loading...</div>
          ) : locationContacts?.length ? (
            <RenderContact contacts={locationContacts} />
          ) : (
            <RenderContact contacts={orgContacts} />
          )}
        </div>
      </div>

      <CardFooter>&nbsp;</CardFooter>
    </Card>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    paddingRight: theme.spacing(2)
  },
  contentWrapper: {
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    height: 237.31,
    '&:hover': { overflowY: 'auto' },
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 50,
      backgroundColor: theme.palette.grey[50],
      minHeight: 70
    }
  },
  rowWrapper: {
    marginBottom: theme.spacing(2)
  },
  userCardContent: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > div:first-child': {
      paddingRight: theme.spacing(2)
    },
    '& > div:nth-child(2)': {
      paddingTop: theme.spacing(0.25)
    },
    '& p': {
      paddingBottom: theme.spacing(0.25)
    }
  },
  avatarRoot: {
    width: 26,
    height: 26
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '0.7rem'
  },
  loading: {
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    color: theme.palette.text.primary,
    '& > svg': {
      marginRight: theme.spacing(2)
    }
  }
}));

export default LocationContactDetails;

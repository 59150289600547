import * as React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableRow, TableCell, makeStyles, Theme } from '@material-ui/core';
import { ColumnProps } from 'design/types';

export interface SkeletonRowsProps {
  rowCount: number;
  cols: ColumnProps[];
}

const SkeletonRows = ({ cols, rowCount }: SkeletonRowsProps): JSX.Element => {
  const cc = useStyles();
  const skeletonCells = cols.map((col, i) => (
    <TableCell
      colSpan={1}
      scope="row"
      classes={{ root: cc.bodyCellRoot }}
      key={`${col.accessor}-body-${i}`}
    >
      <Skeleton
        animation="wave"
        height={20}
        variant="rect"
        classes={{ root: cc.skeletonRoot }}
      />
    </TableCell>
  ));

  const rows = new Array(rowCount);
  let iter = 0;
  while (iter < rowCount) {
    rows.push(
      <TableRow key={iter} classes={{ root: cc.rowRoot }}>
        {skeletonCells}
      </TableRow>
    );
    iter++;
  }

  return <>{rows}</>;
};

const useStyles = makeStyles((theme: Theme) => ({
  rowRoot: {
    background: theme.palette.info.main,
    borderBottomWidth: 2
  },
  bodyCellRoot: {
    whiteSpace: 'nowrap',
    maxWidth: 192,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  skeletonRoot: {
    maxHeight: 17
  }
}));

export default SkeletonRows;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Icon, Text } from 'design';
import { ReactComponent as Title } from '../../../assets/img/title.svg';
import { ReactComponent as Role } from '../../../assets/img/role.svg';
import { ReactComponent as Avatar } from '../../../assets/img/Icons_User.svg';
import { Business, SvgIconComponent } from '@material-ui/icons';
import { formatNumber } from 'libphonenumber-js';
import { CurrentUser } from 'types';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/selectors';

const IconListItem = ({
  icon,
  iconMute,
  text,
  children
}: {
  icon: SvgIconComponent;
  iconMute: SvgIconComponent;
  text?: JSX.Element | string | null;
  children: string;
}) => {
  const cc = useStyles();

  return (
    <Text>
      <span className={cc.row}>
        <Icon
          Mark={text ? icon : iconMute}
          className={text ? cc.active : cc.inactive}
        />
        <span className={text ? cc.active : cc.inactive}>
          {text ?? children}
        </span>
      </span>
    </Text>
  );
};

const UserCardContent = ({
  userData
}: {
  userData: CurrentUser;
}): JSX.Element => {
  const cc = useStyles();

  const user = useSelector(selectCurrentUser);

  const orgText =
    userData.organization.id !== user.sourceOrg?.id ? (
      <>
        {user?.sourceOrg?.name}{' '}
        <strong>(currently {userData.organization.name})</strong>
      </>
    ) : (
      <>{user?.sourceOrg?.name}</>
    );

  return (
    <div className={cc.userCardContent}>
      <div className={cc.profileWrapper}>
        <IconListItem
          icon={AvatarIcon}
          iconMute={AvatarIconMute}
          text={userData?.name}
        >
          First and Last Name
        </IconListItem>
        <IconListItem
          icon={TitleIcon}
          iconMute={TitleIconMute}
          text={userData?.orgPosition}
        >
          Title
        </IconListItem>
        <IconListItem icon={Business} iconMute={Business} text={orgText}>
          Organization
        </IconListItem>
        <IconListItem
          icon={RoleIcon}
          iconMute={RoleIcon}
          text={userData?.primaryRole.name}
        >
          Role
        </IconListItem>
        <IconListItem
          icon={PhoneIcon}
          iconMute={PhoneIcon}
          text={
            userData?.phoneMobile
              ? formatNumber(userData?.phoneMobile, 'INTERNATIONAL')
              : null
          }
        >
          Phone Number (Primary)
        </IconListItem>
        <IconListItem
          icon={PhoneIcon}
          iconMute={PhoneIcon}
          text={
            userData?.phoneWork
              ? formatNumber(userData?.phoneWork, 'INTERNATIONAL')
              : null
          }
        >
          Phone Number (Secondary)
        </IconListItem>
        <IconListItem
          icon={EmailIcon}
          iconMute={EmailIcon}
          text={userData?.username}
        >
          Email
        </IconListItem>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  userCardContent: {
    display: 'flex',
    '& > div:first-child': {
      paddingRight: theme.spacing(2)
    },
    '& > div:nth-child(2)': {
      paddingTop: theme.spacing(0.25)
    },
    '& p': {
      paddingBottom: theme.spacing(0.25)
    },
    wordBreak: 'break-word'
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  row: {
    display: 'flex',
    color: theme.palette.text.primary,
    '& > svg': {
      marginRight: theme.spacing(2),
      width: '22px',
      height: '22px',
      padding: '2px'
    },
    fontSize: 15
  },
  inactive: {
    color: '#A9B3B7',
    fontSize: 15
  },
  active: {
    fontSize: 15,
    color: '#000'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}));
export const TitleIcon = (): JSX.Element => {
  return <Title style={{ color: '#000' }} />;
};
export const TitleIconMute = (): JSX.Element => {
  return <Title style={{ color: '#A9B3B7' }} />;
};
export const RoleIcon = (): JSX.Element => {
  return <Role style={{ color: '#000' }} />;
};
export const AvatarIcon = (): JSX.Element => {
  return <Avatar style={{ color: '#000' }} />;
};
export const AvatarIconMute = (): JSX.Element => {
  return <Avatar style={{ color: '#A9B3B7' }} />;
};
export default UserCardContent;

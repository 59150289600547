import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, InputProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import cx from 'clsx';

export interface TextareaProps extends InputProps {
  label?: string;
  id: string;
  name: string;
  inputProps: any;
  disabled: boolean;
  placeholder?: string;
  formControlProps: any;
  isError: boolean;
  rows?: number;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  value: string;
  validationMessage?: string;
  validationType?: 'info' | 'success';
}

const Textarea = (props: TextareaProps): JSX.Element => {
  const {
    label,
    id,
    name,
    inputProps,
    disabled,
    formControlProps,
    rows,
    isError,
    placeholder,
    onChange,
    onBlur,
    value,
    validationMessage,
    validationType
  } = props;

  const componentClasses = useStyles();

  const validationMessageClass = cx(componentClasses.validationMessage, {
    [componentClasses.validationInfoMessage]: validationType === 'info',
    [componentClasses.validationSuccessMessage]: validationType === 'success',
    [componentClasses.validationErrorMessage]: isError
  });

  const [currentValue, setCurrentValue] = React.useState('');

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const setValue = (e: any) => {
    setCurrentValue(e.target.value);
    onChange(e);
  };

  return (
    <FormControl
      classes={{ root: componentClasses.formControl }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formControlProps}
      disabled={disabled}
    >
      {label !== undefined ? (
        <InputLabel
          classes={{
            root: componentClasses.labelRoot,
            focused: componentClasses.labelFocused
          }}
          htmlFor={id}
          disableAnimation
          shrink
        >
          {label}{' '}
        </InputLabel>
      ) : null}
      <TextField
        multiline
        id={id}
        name={name}
        error={isError}
        disabled={disabled}
        placeholder={placeholder}
        onChange={setValue}
        onBlur={onBlur}
        value={currentValue}
        InputProps={{
          ...inputProps,
          rows
        }}
        classes={{
          root: componentClasses.root
        }}
      />
      <div className={validationMessageClass}>
        {isError && validationMessage ? <>{validationMessage}</> : <>&nbsp;</>}
      </div>
    </FormControl>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  labelRoot: {
    position: 'relative',
    paddingRight: '2px',
    paddingLeft: '15px',
    paddingBottom: '5px',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    transform: 'none',
    fontSize: theme.typography.body2.fontSize
  },
  labelRootError: {
    color: 'red',
    paddingLeft: theme.spacing(1)
  },
  root: {
    backgroundColor: theme.palette.grey[300],
    '& .MuiInput-underline': {
      borderBottom: `1px solid ${theme.palette.grey[50]} !important`
    },
    '& .Mui-focused': {
      borderBottom: `1px solid ${theme.palette.grey[50]} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${theme.palette.grey[50]} !important`
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.grey[50]} !important`
    },
    '& textarea': {
      fontFamily: theme.typography.fontFamily,
      paddingLeft: theme.spacing(2)
    },
    // TODO: resolve JSS console output from here...
    '& .MuiInput-underline:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.grey[50]}`
    },
    '& .MuiTextField-root': {
      marginTop: '4px'
    }
  },
  whiteUnderline: {},
  whiteInput: {},
  formControl: { color: theme.palette.grey[500] },
  labelFocused: {
    color: `${theme.palette.grey[500]} !important`
  },
  fullWidthFormControl: {},
  disabled: {},
  error: {},
  focused: {},
  outlined: {},
  validationMessage: {
    paddingLeft: theme.spacing(2),
    paddingTop: '6px',
    fontSize: '0.875rem'
  },
  validationErrorMessage: {
    color: theme.palette.error.main
  },
  validationSuccessMessage: {
    color: theme.palette.error.main
  },
  validationInfoMessage: {
    color: theme.palette.error.main
  }
}));
export default Textarea;

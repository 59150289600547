import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { useDashboardSlice } from 'app/layouts';
import { ActionButton } from 'design';
import * as React from 'react';
import { useDispatch } from 'react-redux';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';

interface OrganizationActionsProp {
  id: string;
  orgName: string;
  enableNotificationOptIn: boolean;
  orgStatus: string | undefined;
}
const OrganizationActions = ({
  id,
  orgName,
  enableNotificationOptIn,
  orgStatus
}: OrganizationActionsProp): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cc = useStyles();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'Edit',
      isVisible: currentUser?.isSuperAdmin || currentUser?.isOrgAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Edit Organization',
            lazyComponent: 'OrganizationUpdate',
            size: 'xlarge',
            lazyComponentProps: {
              id,
              orgName,
              currentUser
            }
          })
        );
      }
    },
    {
      label: 'Delete',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: '',
            lazyComponent: 'OrganizationDelete',
            size: 'large',
            lazyComponentProps: {
              id,
              orgName
            }
          })
        );
      }
    },
    {
      label: 'Notifications',
      isVisible: currentUser?.isSuperAdmin,
      action: () => {
        dispatch(
          actions.showDialog({
            open: true,
            title: 'Notifications',
            lazyComponent: 'OrganizationNotifications',
            size: 'large',
            lazyComponentProps: {
              id,
              enableNotificationOptIn,
              orgStatus
            }
          })
        );
      }
    }
  ];

  return (
    <>
      <div
        style={{
          float: 'right'
        }}
      >
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={cc}
        style={{ marginLeft: '20px' }}
      >
        {menuItems.map(({ label, action, isVisible }, i: number) => {
          return (
            <div key={i}>
              {isVisible && (
                <MenuItem
                  button
                  onClick={() => {
                    action();
                    if (handleMenuClose) {
                      handleMenuClose();
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 150
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  }
}));
export default OrganizationActions;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';

export interface ProgressProps {
  completed?: number;
  thin?: boolean;
  className?: string;
}

const Progress = ({
  completed,
  thin,
  className
}: ProgressProps): JSX.Element => {
  const cc = useStyles();
  const progClasses = clsx({ [cc.thick]: !thin, [cc.thin]: thin });
  const thickBar = clsx({ [cc.thickBar]: !thin });
  return (
    <LinearProgress
      className={className || progClasses}
      classes={{ bar: thickBar }}
      value={completed}
      variant={completed ? 'determinate' : 'indeterminate'}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  thick: {
    height: 16,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    background: 'none'
  },
  thickBar: { borderRadius: 3 },
  thin: {
    background: theme.palette.grey['50'],
    marginBottom: -4,
    zIndex: 20
  }
}));

export default Progress;

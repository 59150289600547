import * as React from 'react';
import { Badge, BadgeBar } from 'design';
import { RoleName } from 'types/AuthRoles';
import { RolesData } from 'api/useRolesCount';

export interface RolesBadgeBarProps {
  countData: RolesData;
  isLoading: boolean;
  error: any;
  rolesFilter: string;
  isMsOrg: boolean;
  setRolesFilter: React.Dispatch<React.SetStateAction<'' | RoleName>>;
}

const RolesBadgeBar = ({
  countData,
  isLoading,
  error,
  rolesFilter,
  setRolesFilter,
  isMsOrg
}: RolesBadgeBarProps): JSX.Element => {
  const { superAdmin, orgAdmin, user, locationAdmin, total } = countData;

  if (error) {
    throw new Error(`${error.message} Failed to fetch user roles!`);
  }
  return (
    <BadgeBar>
      <Badge
        color="info"
        rightLabel="All"
        active={!rolesFilter}
        handleClick={() => setRolesFilter('')}
        isLoading={isLoading}
      >
        {total}
      </Badge>
      {isMsOrg ? (
        <Badge
          color="info"
          rightLabel="Super Admin"
          active={rolesFilter === RoleName.superAdmin}
          handleClick={() => setRolesFilter(RoleName.superAdmin)}
          isLoading={isLoading}
        >
          {superAdmin}
        </Badge>
      ) : (
        <></>
      )}
      <Badge
        color="info"
        rightLabel="Org Admin"
        active={rolesFilter === RoleName.orgAdmin}
        handleClick={() => setRolesFilter(RoleName.orgAdmin)}
        isLoading={isLoading}
      >
        {orgAdmin}
      </Badge>
      <Badge
        color="info"
        rightLabel="Location Admin"
        active={rolesFilter === RoleName.locationAdmin}
        handleClick={() => setRolesFilter(RoleName.locationAdmin)}
        isLoading={isLoading}
      >
        {locationAdmin}
      </Badge>
      <Badge
        color="info"
        rightLabel="User"
        active={rolesFilter === RoleName.user}
        handleClick={() => setRolesFilter(RoleName.user)}
        isLoading={isLoading}
      >
        {user}
      </Badge>
    </BadgeBar>
  );
};

export default RolesBadgeBar;

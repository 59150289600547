import { LabelValuesMap } from 'design/Dropdown/Dropdown';
import { Env } from '../types';

export const dashboardUri = '/dashboard';
export const authUri = '/auth';

export const MARKETSPARK_ORG_NAME = 'MarketSpark';

export const MARKETSPARK_EMAIL = '@marketspark.com';

export const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;

/**
 * Auth0 permissions.
 */
export const permissions = {
  accounts: {
    read: 'read:accounts',
    create: 'create:accounts',
    update: 'update:accounts',
    delete: 'delete:accounts'
  },
  users: {
    read: 'read:users',
    create: 'create:users',
    update: 'update:users',
    delete: 'delete:users',
    scope: 'scope:users'
  },
  decommission: {
    read: 'read:decommission',
    create: 'create:decommission'
  }
};

export const query = {
  apiInfo: 'apiInfo',
  adminStatus: 'adminStatus',
  apnList: 'apnList',
  authRoles: 'authRoles',
  bundleTemplate: 'bundleTemplate',
  bundleTemplateDependencies: 'bundleTemplateDependencies',
  bundleTemplates: 'bundleTemplates',
  callForwardingExists: 'callForwardingExists',
  carriers: 'carriers',
  catalog: 'catalog',
  disclosureAddressCsvUpload: 'disclosureAddressCsvUpload',
  disclosureClientMap: 'disclosureClientMap',
  disclosureDownloadPdf: 'disclosureDownloadPdf',
  disclosureEmailReport: 'disclosureEmailReport',
  disclosures: 'disclosures',
  disclosureTileset: 'disclosureTileset',
  disclosureUploadDecomClientsCsvFile: 'disclosureUploadDecomClientsCsvFile',
  disclosureZipcodeBoundaries: 'disclosureZipcodeBoundaries',
  division: 'division',
  equipmentBundle: 'equipmentBundle',
  equipmentData: 'equipmentData',
  equipmentDelete: 'equipmentDelete',
  equipment: 'equipment',
  equipments: 'equipment',
  gatewayCatalog: 'GatewayCatalog',
  gateway: 'gateway',
  gateways: 'gateways',
  gatewayLines: 'gatewayLines',
  hardwareCatalogStatuses: 'hardwareCatalogStatuses',
  installStatus: 'installStatus',
  lineCallLogs: 'lineCallLogs',
  line: 'line',
  lines: 'lines',
  lineTypes: 'lineTypes',
  locationDelete: 'locationDelete',
  locationDetails: 'locationDetails',
  locationE911Lines: 'locationE911Lines',
  locationLines: 'locationLines',
  locationLinesStatus: 'locationLinesStatus',
  location: 'location',
  locationNotes: 'locationNotes',
  locationReportCategories: 'locationReportCategories',
  locationReport: 'locationReport',
  locations: 'locations',
  locationsStatus: 'locationsStatus',
  locationStatuses: 'locationStatuses',
  macPrefixes: 'macPrefixes',
  managedBy: 'managedBy',
  medias: 'medias',
  currentUser: 'currentUser',
  notificationCategories: 'notificationCategories',
  notificationSetting: 'notificationSettings',
  notificationSettings: 'notificationSettings',
  notificationSubscriptions: 'notificationSubscriptions',
  notifications: 'notifications',
  notificationTypes: 'notificationTypes',
  organizations: 'organizations',
  organizationCDRs: 'organizationCDRs',
  orgData: 'orgData',
  orgDelete: 'orgDelete',
  orgNetProvider: 'orgNetProvider',
  locNetProvider: 'locNetProvider',
  orgExportData: 'orgExportData',
  orgNotification: 'orgNotification',
  orgReportTypes: 'orgReportTypes',
  orgStatusCount: 'orgStatusCount',
  orgStatusList: 'orgStatusList',
  orgStatus: 'orgStatus',
  orgAuthMethods: 'authMethods',
  powersource: 'powersource',
  powersources: 'powesources',
  powersourceCatalog: 'powersourceCatalog',
  powerSupply: 'powerSupply',
  primaryUsers: 'primaryUsers',
  providers: 'providers',
  rolesCount: 'rolesCount',
  routerCatalog: 'routerCatalog',
  routerMetrics: 'routerMetrics',
  router: 'router',
  routers: 'routers',
  secondaryUsers: 'secondaryUsers',
  serviceDelete: 'serviceDelete',
  service: 'service',
  services: 'services',
  serviceStatus: 'serviceStatus',
  serviceTypes: 'serviceTypes',
  installations: 'installations',
  simDetails: 'simDetails',
  sim: 'sim',
  sims: 'sims',
  simStatuses: 'simStatuses',
  userContactType: 'userContactType',
  users: 'users',
  zoomBusinessInfo: 'zoomBusinessInfo',
  zoomBusinessNames: 'zoomBusinessNames',
  zoomInfoByOrgName: 'zoomInfoByOrgName',
  routerById: 'routerById',
  gatewayById: 'gatewayById'
};

export const API_URL = process.env.REACT_APP_API_URL;
export const AUTH_LOGOUT_URI =
  process.env.REACT_APP_AUTH_LOGOUT_URI || `${authUri}/logout`;
export const AUTH_CALLBACK_URI =
  process.env.REACT_APP_AUTH_CALLBACK_URI || `${authUri}/callback`;
export const AUTH_LOGIN_URI =
  process.env.REACT_APP_AUTH_LOGIN_URI || `${authUri}/login`;
export const AUTH_ERROR_URI = `${authUri}/error`;

// Mapbox api
export const REACT_APP_MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY || '';
export const STATIC_MAP_ID = process.env.REACT_APP_STATIC_MAP_ID || '';

export const DID_REGEX = new RegExp('^[0-9)( -]+$');

export const EQUIPMENT_SERIAL_NUMBER_REGEX = new RegExp(/^[a-zA-Z0-9]+$/);

export const IMEI_REGEX = new RegExp(/^[0-9]{15}$/);

export const NUMBER_REGEX = new RegExp(/^[0-9]+$/);

export enum DidTypes {
  PERM = 'Perm DID',
  TEMP = 'Temp DID'
}

export const pageSizeOptions = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' }
];

export const CARRIER = 'Carrier';
export const MARKETSPARK = 'MarketSpark';
export const CARRIER_LIMIT = 11;
export const APN_LIMIT = 13;

export const activatedByOptions = [
  { label: CARRIER, value: CARRIER },
  { label: MARKETSPARK_ORG_NAME, value: MARKETSPARK_ORG_NAME }
];

export const installationManagement = [
  { label: MARKETSPARK_ORG_NAME, value: MARKETSPARK_ORG_NAME },
  { label: 'Customer', value: 'Customer' }
];

export const net2phone = 'net2phone';
export const mixnetworks = 'mixnetworks';

export const defaultLineType = 'Voice';
export const IVR = 'IVR';

export const DataRemote = 'DataRemote';

export const DEFAULT_BUNDLE_HEADER_TEXT = 'Build Hardware';

export const DID_LENGTH = 11;

export const manageLinesMessages = {
  didLength: ` should contain ${DID_LENGTH} digits`,
  didFormat: ' invalid format',
  existsThisSite: ' already exists in this site',
  existsOtherSite: ' already exists in another site'
};

export enum StatusToColor {
  Online = 'success',
  Offline = 'error',
  Trouble = 'warning',
  None = 'secondary'
}

// Static support information
export const phoneSupport = '18443355153';
export const phoneSupportFormatted = '1 (844) 335-5153';
export const LOCATION_MEDIA_FILE_LIMIT_SIZE = 15 * 1024 * 1024; // 10MB in bytes

export const CALL_LOG_DAYS = process.env.REACT_APP_CALL_LOG_DAYS ?? 30;

export const CALL_LOGS_EXPORT_TYPE = 'call-logs';

export const PHONE_VALUE_MIN = 10000000000;

export const DEFAULT_ROW_LIMIT = {
  min: 10,
  max: 100
};

export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

export const countries = [
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'CA',
    label: 'Canada'
  },
  {
    value: 'PR',
    label: 'Puerto Rico'
  }
];

export const SUPER_ADMIN = 'Super Admin';

export const Overview = 'Overview';

export const Hardware = 'Hardware';

export const POWER_TEC = 'Powertec';

export const DECOMMISSION = 'Decommission';

export const PILOT = 'Pilot';

export const IN_CONTRACT = 'In Contract';

export const TO_BE_RETURNED = 'To Be Returned';

export const batteryMaxCount = [
  {
    name: 'Powertec',
    maxCount: 3
  }
];

export const PEPWAVE_ROUTER = 'Pepwave';

export const CRADLEPOINT_ROUTER = 'Cradlepoint';

export const INHAND_ROUTER = 'Inhand';

export const GRANDSTREAM_GATEWAY = 'Grandstream';

export const POLL_ONLY_DEVICES = [PEPWAVE_ROUTER];

export const POLL_AND_WEBHOOK_DEVICES = [INHAND_ROUTER, CRADLEPOINT_ROUTER];

export const ROUTER = 'router';

export const ATA = 'ata';

export const NAPCO = 'napco';

export const SANGOMA = 'Sangoma';

export const INTEGRATED_ROUTER = 'integrated_router';

export const DATAREMOTE = ['CDS-9090', 'CDS-9010'];

export const BATTERY = 'battery';

export const PBX_DOMAIN_REGEX = new RegExp(
  /(^(?:[a-zA-Z0-9]+\.){2}pbx\.supersip\.net$)|(^([a-zA-Z0-9]+\.)ms$)/
);

export const MIX_DOMAIN_REGEX = new RegExp(
  /^(?:[a-zA-Z0-9]+\.){2}pbx\.supersip\.net$/
);

export const N2P_DOMAIN_REGEX = new RegExp(/(^([a-zA-Z0-9]+\.)ms$)/);
export const N2P_TAG_LIMIT = 32;
export const N2P_TAG_REGEX = new RegExp(/^[0-9a-zA-Z]+$/);
export const N2P_TAG_REGEX_DASH = new RegExp(/^[-0-9a-zA-Z]{2,}$/);

export const CDS_9090 = 'CDS-9090';

export const CDS_9010 = 'CDS-9010';

export const MAC_ADDRESS_REGEX = new RegExp(
  /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/
);

export const IMEI_ALL_ZERO_REGEX = new RegExp(/^(.(?!0{14}))*$/);

export const IPADDRESS_REGEX = new RegExp(
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
);

export const ICCID_REGEX = new RegExp(/^[0-9]*$/);

export const CNAM_REGEX = new RegExp(/^[A-Za-z0-9 ]{1,14}$/);

export const PAYABLE_UNINSTALL_TYPE = 'Professional Uninstall ($300)';

export const LOCATION_UNINSTALL_TYPES = [
  'Professional Uninstall ($300)',
  'Self Uninstall ($0)'
];

export const PRIMARY_CONTACT = 'Primary Contact';

export const SECONDARY_CONTACT = 'Secondary Contact';

export const ADDRESS_PROPERTIES = [
  'addressName',
  'address1',
  'address2',
  'state',
  'city',
  'postalCode',
  'country'
];

export const ORG_STATUS = {
  PILOT: 'Pilot',
  IN_CONTRACT: 'In Contract'
};

export const USER_CREDENTIALS = 'User Credentials';

export const ROUTER_METRIC_BRANDS = {
  CRADLEPOINT: 'Cradlepoint',
  INHAND: 'Inhand'
};

export const GATEWAY_METRIC_BRANDS = {
  GRANDSTREAM: 'Grandstream'
};

export const MIX_MANAGE_DOMAIN_URL =
  'https://my.mixnetworks.com/portal/domains/manage/';

export const NET2PHONE_MANAGE_DOMAIN_URL =
  'https://ncthv.net2phone.com/portal/domains/manage/';

export const NOTIFICATION_RULES_WIKI_URL =
  'https://marketspark.atlassian.net/wiki/spaces/CMDC1/pages/1748598793/Rules+Notifications';

export const getStatusRetrievalOptions = (brand: string | undefined) => {
  let options: LabelValuesMap[] = [];

  if (!brand) {
    return options;
  }

  if (POLL_ONLY_DEVICES.includes(brand)) {
    options = [{ label: 'Poll', value: 'poll' }];
  }

  if (POLL_AND_WEBHOOK_DEVICES.includes(brand)) {
    options = [
      { label: 'Poll', value: 'poll' }
      // NOTE: Temporarily commented out
      // { label: 'Webhook', value: 'webhook' }
    ];
  }

  return options;
};

export const CDRDetails = {
  numberOfDays: 30,
  loadInterval: '2 hours'
};

/**
 * Mapping of environment names to their corresponding colors.
 * Some elements will have different colors in different environments
 * in order to help testers quickly identify the current enviornment.
 */
export const envColorMap: Record<string, string> = {
  [Env.Dev]: '#F79F09',
  [Env.Staging]: '#0CCE6B'
};

export const LINE_STATUS_MINUTE_THRESHOLD = 10;

export const successStatus = 200;

export const ACCEPTABLE_IP_PREFIXES = ['10.16', '10.32'];

export const validationErrorMessages = {
  somethingBroke: 'Something broke, check the console for more information',
  bundleCatalogRequired: 'Please select a hardware bundle',
  ownerRequired: 'Please select an owner',
  installStatusRequired: 'Please select an install status',
  userNameRequired: 'Please enter a username',
  passwordRequired: 'Please enter a password',
  serialNumberRequired: 'Please enter a serial number',
  serialNumberInvalid: 'Serial Number can only include alphanumeric characters',
  serialNumberExists: 'Serial Number already exists',
  ipAddressRequired: 'Please enter an IP address',
  ipAddressInvalid: 'Invalid IP address',
  ipAddressStartsWith: `IP address must start with ${ACCEPTABLE_IP_PREFIXES.join(
    ' or '
  )}`,
  ipAddressExists: 'IP address already exists',
  inHandIpAddressExists: 'IP address already exists in ICS Account',
  macAddressRequired: 'Please enter a MAC address',
  macAddressInvalid: 'Invalid MAC address',
  macAddressExists: 'MAC address already exists',
  macAddressExistsGDMS: 'MAC address already exists in GDMS',
  imeiRequired: 'Please enter an IMEI number',
  imeiInvalid: 'IMEI must be 15 digits',
  imeiAllZero: 'IMEI cannot be all zeros',
  imeiExists: 'IMEI already exists',
  domainRequired: 'Please enter a domain',
  domainExists: 'Domain already exists',
  mixDomainInvalid: 'Domain format should be "domain.bin.pbx.supersip.net"',
  domainInvalid:
    'Format should be either "domain.bin.pbx.supersip.net" or "domain.ms"',
  managedByRequired: 'Please select a managed by option',
  batterySerialNumberUnique: 'Battery serial numbers must be unique',
  catalogModelRequired: 'Model is required',
  catalogModelExists: 'Model name already exists'
};

export const helpCenterRoutes = {
  gettingStarted:
    'https://support.marketspark.com/hc/en-us/categories/4405801664660-Getting-Started',
  installationSupport:
    'https://support.marketspark.com/hc/en-us/categories/4402646273428-Installation-Support',
  howTo:
    'https://support.marketspark.com/hc/en-us/categories/7574672307220-How-To'
};

export const contactUsPriorityOptions = [
  {
    label: 'Critical',
    value: 'critical'
  },
  {
    label: 'Urgent',
    value: 'urgent'
  },
  {
    label: 'High',
    value: 'high'
  },
  {
    label: 'Normal',
    value: 'normal'
  },
  {
    label: 'Low',
    value: 'low'
  }
];

export const contactUsTopicOptions = [
  {
    label: 'Account',
    value: 'Account'
  },
  {
    label: 'Command Center',
    value: 'Command Center'
  },
  {
    label: 'Service Issues',
    value: 'Service Issues'
  },
  {
    label: 'Records Request',
    value: 'Records Request'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const ALLOWABLE_THREE_DIGIT_NUMBERS = [
  '211',
  '311',
  '411',
  '511',
  '611',
  '711',
  '811',
  '911',
  '988'
];

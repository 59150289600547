import {
  useQuery,
  UseQueryResult,
  QueryFunctionContext,
  UseMutationResult,
  useMutation,
  useQueryClient
} from 'react-query';
import { toast } from 'react-toastify';
import {
  convertZoomBusinessDataToClusterDataMap,
  GeoJsonDataFeature,
  GeoJsonDataZipcodeMap,
  ZipcodeBoundaries,
  ZoomBusinessInfo,
  ZoomBusinessNames
} from '@marketspark/ms-utils-and-interfaces';

import { query } from 'constants/app';

import axios, { AxiosError, AxiosResponse } from 'utils/axiosProvider';
import { ProspectAddress } from 'app/pages/DecommissionPage/DecommissionMap/utils/addressesHelper';

export enum ZoomInfoQueryKey {
  BusinessName = 'businessName',
  OrgId = 'organizationId'
}
export interface Recipient {
  email: string;
  name: string;
}

export interface PostClientMapReport {
  disclosureZipcodeMap: GeoJsonDataZipcodeMap;
  zoomBusinessGeoZipcodeMap: GeoJsonDataZipcodeMap;
  clientMapLink: string;
}

export interface PdfReport {
  businessName: string;
  numberOfLocations: number;
  imageBlob: string;
  isCustomLocations?: boolean;
}

export interface SendGeneratedReportEmail extends PdfReport {
  recipients?: Recipient[];
  recipientEmails?: string[];
}

export interface ReportRequestsCsv {
  businessName: string;
  recipientEmail: string;
  recipientName: string;
}

function setPropertiesForZipBoundaries(
  boundariesFeatures: ZipcodeBoundaries[],
  disclosureZipcodeMap: GeoJsonDataZipcodeMap
): GeoJsonDataFeature[] {
  const result = boundariesFeatures.map(boundary => {
    const { zipcode } = boundary;
    const matchingDiclosure = disclosureZipcodeMap[zipcode];

    return {
      type: 'Feature',
      id: `${matchingDiclosure.id}_${zipcode}`,
      geometry: boundary.geometry,
      properties: matchingDiclosure.properties
    } as GeoJsonDataFeature;
  });

  return result;
}

const getAllDisclosures = async () => {
  const { data }: AxiosResponse = await axios.get('decommission/disclosures');
  return data;
};

const useDisclosures = (): UseQueryResult<GeoJsonDataZipcodeMap, AxiosError> =>
  useQuery({
    queryKey: [query.disclosures],
    queryFn: getAllDisclosures
  });

const insertNewDisclosures = async (csvFile: File) => {
  const formData = new FormData();
  formData.append('newDisclosuresCsv', csvFile);
  const { data } = await axios.post('/decommission/disclosures', formData);
  return data;
};

const useInsertNewDisclosures = (): UseMutationResult<
  GeoJsonDataZipcodeMap,
  AxiosError,
  File
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<GeoJsonDataZipcodeMap, AxiosError, File>(
    insertNewDisclosures,
    {
      onSuccess: () => {
        toast('Successfully uploaded new disclosures', { type: 'success' });
        queryClient.invalidateQueries([query.disclosures]);
      },
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosures]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosures]);
      }
    }
  );
  return mutateClient;
};

const updateDisclosuresMapTileset = async (
  tilesetId: string
): Promise<string> => {
  const { data } = await axios.patch(
    `/decommission/disclosures/mapbox/tileset/${tilesetId}`
  );
  return data;
};

const useUpdateDisclosuresMapTileset = (): UseMutationResult<
  string,
  AxiosError,
  string
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<string, AxiosError, string>(
    updateDisclosuresMapTileset,
    {
      onSuccess: () => {
        toast('Successfully updated disclosures map tileset', {
          type: 'success'
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosureTileset]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosureTileset]);
      }
    }
  );
  return mutateClient;
};

const getZoomInfoBusinessData = async ({
  queryKey
}: QueryFunctionContext<[string, string, ZoomInfoQueryKey]>) => {
  const [, businessNameOrOrgId, zoomInfoQueryKey] = queryKey;

  let baseUrl = `decommission/business/${ZoomInfoQueryKey.BusinessName}`;

  if (zoomInfoQueryKey === ZoomInfoQueryKey.OrgId) {
    baseUrl = `decommission/business/${ZoomInfoQueryKey.OrgId}`;
  }
  if (businessNameOrOrgId === '') {
    return [];
  }

  const { data } = await axios.get(
    `${baseUrl}/${encodeURIComponent(businessNameOrOrgId)}`
  );
  return data;
};

const useZoomInfoBusinessData = (
  businessName: string,
  disclosureZipcodes: string[],
  zoomInfoQueryKey: ZoomInfoQueryKey
): UseQueryResult<[GeoJsonDataZipcodeMap, ZoomBusinessInfo[]], AxiosError> =>
  useQuery({
    queryKey: [query.zoomBusinessInfo, businessName, zoomInfoQueryKey],
    queryFn: getZoomInfoBusinessData,
    select: (data: ZoomBusinessInfo[]) => {
      const zoomBusinessGeoZipcodeMap = convertZoomBusinessDataToClusterDataMap(
        data,
        disclosureZipcodes
      );
      return [zoomBusinessGeoZipcodeMap, data];
    },
    enabled: disclosureZipcodes.length > 0
  });

const getZoomBusinessNameOptions = async ({
  queryKey
}: QueryFunctionContext<[string, string]>) => {
  const [, name] = queryKey;

  const nameQuery = name ? `businessName=${encodeURIComponent(name)}` : '';

  const { data }: AxiosResponse = await axios.get(
    `decommission/business/options?${nameQuery}`
  );
  return data;
};

// To get dropdown options so user can search for
// matching business names in our zoom table
const useZoomBusinessNameOptions = (
  businessName: string
): UseQueryResult<ZoomBusinessNames[], AxiosError> =>
  useQuery<ZoomBusinessNames[], AxiosError>(
    [query.zoomBusinessNames, businessName],
    getZoomBusinessNameOptions
  );

const getZipcodeBoundaries = async ({
  queryKey
}: QueryFunctionContext<[string, GeoJsonDataZipcodeMap], AxiosError>) => {
  const [, disclosureZipcodesMap] = queryKey;

  const { data }: AxiosResponse = await axios.post('decommission/boundaries', {
    zipcodes: Object.keys(disclosureZipcodesMap)
  });
  return data;
};

const useZipcodeBoundaries = (
  disclosureZipcodeMap: GeoJsonDataZipcodeMap
): UseQueryResult<GeoJSON.FeatureCollection, AxiosError> =>
  useQuery({
    queryKey: [query.disclosureZipcodeBoundaries, disclosureZipcodeMap],
    queryFn: getZipcodeBoundaries,
    select: data => {
      const features = setPropertiesForZipBoundaries(
        data,
        disclosureZipcodeMap
      );
      const geoSource: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features
      };

      return geoSource;
    },
    enabled: !!disclosureZipcodeMap
  });

const getClientMapReport = async ({
  queryKey
}: QueryFunctionContext<[string, string], AxiosError>) => {
  const [, businessName] = queryKey;
  if (businessName === '') {
    return;
  }
  const { data } = await axios.get(
    `/decommission/client/static-map?businessName=${encodeURIComponent(
      businessName
    )}`
  );

  return data;
};

const useGetDecomClientMapReport = (
  businessName: string
): UseQueryResult<
  {
    disclosureZipcodeMap: GeoJsonDataZipcodeMap;
    zoomBusinessLocations: ZoomBusinessInfo[];
    zoomBusinessGeoZipcodeMap: GeoJsonDataZipcodeMap;
  },
  AxiosError
> =>
  useQuery({
    queryKey: [query.disclosureClientMap, businessName],
    queryFn: getClientMapReport,
    select: data => {
      const { zoomBusinessLocations, disclosureZipcodeMap } = data;
      const zoomBusinessGeoZipcodeMap = convertZoomBusinessDataToClusterDataMap(
        zoomBusinessLocations,
        Object.keys(disclosureZipcodeMap)
      );

      return {
        disclosureZipcodeMap,
        zoomBusinessLocations,
        zoomBusinessGeoZipcodeMap
      };
    }
  });

// This is not even useful right now lol
const postClientMapReport = async (payload: PostClientMapReport) => {
  const { data } = await axios.post('/decommission/client/static-map', payload);

  // We are testing this for now, Not even sure how this would even be handled, yet
  return data;
};

// Not sure if this will be needed
// Since we are already caching business info and disclosure data
// I should just have one endpoint that clients that do not have
// some sort of validation to be able to utilize
const useCreateDecomClientMapReport = (): UseMutationResult<
  string,
  AxiosError,
  PostClientMapReport
> => {
  const queryClient = useQueryClient();
  const mutateClientMap = useMutation<string, AxiosError, PostClientMapReport>(
    postClientMapReport,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosureClientMap]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosureClientMap]);
      }
    }
  );
  return mutateClientMap;
};

const sendGeneratedReportEmail = async ({
  recipients,
  recipientEmails,
  numberOfLocations,
  businessName,
  imageBlob,
  isCustomLocations
}: SendGeneratedReportEmail) => {
  const postData = {
    recipients,
    recipientEmails,
    numberOfLocations,
    businessName,
    imageBlob,
    isCustomLocations
  };

  const { data } = await axios.post('/decommission/pdf/send-report', postData);
  return data;
};

const usePostGenerateReportEmail = (): UseMutationResult<
  string,
  AxiosError,
  any
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<string, AxiosError, any>(
    sendGeneratedReportEmail,
    {
      onSuccess: () => {
        toast('Email(s) sent successfully', { type: 'success' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosureEmailReport]);
      },
      onError: (error: any) => {
        // figureout how to handle (error: any) here
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosureEmailReport]);
      }
    }
  );
  return mutateClient;
};

const postAddressCsvFile = async (csvFile: File) => {
  const formData = new FormData();
  formData.append('addressesCsv', csvFile);
  const { data } = await axios.post(
    'decommission/csv/parse-addresses',
    formData
  );

  return data;
};

const usePostAddressCsvFile = (): UseMutationResult<
  ProspectAddress[],
  AxiosError,
  File
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<ProspectAddress[], AxiosError, File>(
    postAddressCsvFile,
    {
      onSuccess: () => {
        toast('Successfully uploaded locations', { type: 'success' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosureAddressCsvUpload]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosureAddressCsvUpload]);
      }
    }
  );
  return mutateClient;
};

const downloadPdfReport = async ({
  numberOfLocations,
  businessName,
  imageBlob,
  isCustomLocations
}: PdfReport): Promise<Blob> => {
  const response = await axios.post(
    'decommission/pdf/download-report',
    { numberOfLocations, businessName, imageBlob, isCustomLocations },
    { responseType: 'blob' }
  );

  return response.data;
};

const useDownloadPdfReport = (): UseMutationResult<
  Blob,
  AxiosError,
  PdfReport
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<Blob, AxiosError, PdfReport>(
    downloadPdfReport,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.disclosureDownloadPdf]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([query.disclosureDownloadPdf]);
      }
    }
  );
  return mutateClient;
};

const uploadDecomClientsCsvFile = async (csvFile: File) => {
  const formData = new FormData();
  formData.append('reportRequestsCsv', csvFile);
  await axios.post('decommission/pdf/send-reports', formData);
};

const useUploadDecomClientsCsvFile = (): UseMutationResult<
  void,
  AxiosError,
  File
> => {
  const queryClient = useQueryClient();
  const mutateClient = useMutation<void, AxiosError, File>(
    uploadDecomClientsCsvFile,
    {
      onSuccess: () => {
        toast('Request to create reports accepted.', { type: 'success' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          query.disclosureUploadDecomClientsCsvFile
        ]);
      },
      onError: (error: any) => {
        const { data } = error?.response || {};
        if (Array.isArray(data.message)) {
          toast(data.message[0], { type: 'error' });
        } else {
          toast(data.message, { type: 'error' });
        }
        queryClient.invalidateQueries([
          query.disclosureUploadDecomClientsCsvFile
        ]);
      }
    }
  );
  return mutateClient;
};

export {
  useZoomBusinessNameOptions,
  useDisclosures,
  useZoomInfoBusinessData,
  useZipcodeBoundaries,
  useCreateDecomClientMapReport,
  useGetDecomClientMapReport,
  usePostGenerateReportEmail,
  usePostAddressCsvFile,
  useDownloadPdfReport,
  useUploadDecomClientsCsvFile,
  useUpdateDisclosuresMapTileset,
  useInsertNewDisclosures
};

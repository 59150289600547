import { useAdminStatuses } from 'api/useBundle';
import { labelValuesFor } from 'design';
import { LabelValuesMap } from 'design/Dropdown/Dropdown';
import FormDropdown from 'design/Dropdown/FormDropdown';
import React from 'react';
import { EquipmentAndHardwareFormsProps } from './types';

const AdminStatusForRouterForm = ({
  formContext
}: EquipmentAndHardwareFormsProps) => {
  const { register } = formContext();
  const adminStatus = register('adminStatusId');
  const { data: adminStatuses } = useAdminStatuses();

  const adminStatusList: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(adminStatuses);
  }, [adminStatuses]);
  return (
    <FormDropdown
      id="adminStatusId"
      label="Install Status"
      placeholder="Select Install Status"
      dropdownItems={adminStatusList}
      formState={adminStatus}
    />
  );
};

export default AdminStatusForRouterForm;

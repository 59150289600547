import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { HoveredFeatureType } from '../../types';

interface Props {
  hoveredFeature: HoveredFeatureType;
}

export default React.memo(function MapPopup({ hoveredFeature }: Props) {
  const cc = useStyles();
  return (
    <div
      className={cc.toolTip}
      style={{ left: hoveredFeature?.x, top: hoveredFeature?.y }}
    >
      <Typography component="div">
        Zipcode: {hoveredFeature?.feature.properties?.zip}
      </Typography>
      <Typography component="div">
        City: {hoveredFeature?.feature.properties?.city}
      </Typography>
      {hoveredFeature?.feature.properties?.retiredLines && (
        <Typography component="div">
          Retired lines: {hoveredFeature?.feature.properties?.retiredLines}
        </Typography>
      )}
      {hoveredFeature?.feature.properties?.impactedStores && (
        <Typography component="div">
          Impacted Location(s):{' '}
          {hoveredFeature?.feature.properties?.impactedStores}
        </Typography>
      )}
    </div>
  );
});

const useStyles = makeStyles(() => ({
  toolTip: {
    position: 'absolute',
    margin: '10px',
    padding: '8px',
    background: '#000000d1',
    color: '#fff',
    fontSize: '12px',
    zIndex: 9,
    pointerEvents: 'none'
  }
}));

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  panelClass?: string;
  index: any;
  value: any;
}

function TabPanel({ children, value, index, panelClass }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && <div className={panelClass}>{children}</div>}
    </div>
  );
}

export interface TabItemProps {
  text: string;
  icon: JSX.Element;
  visible?: boolean;
  url?: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export interface TabContainerProps {
  items: TabItemProps[];
  initialTab?: number;
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const TabView = ({ items, initialTab = 0 }: TabContainerProps): JSX.Element => {
  const cc = useStyles();
  const history = useHistory();

  const [value, setValue] = React.useState<number | undefined>(initialTab);

  React.useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  const handleChange = (event: React.ChangeEvent<any>, index: number) => {
    if (items[index].url) {
      history.push(String(items[index].url));
    }

    setValue(index);
  };

  return (
    <div>
      <Box className={cc.tabContainer}>
        <Tabs
          TabIndicatorProps={{ style: { height: 4 } }}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
          onChange={handleChange}
          variant="scrollable"
        >
          {items.map(
            (item, index) =>
              item.visible && (
                <Tab
                  key={index}
                  color="primary"
                  textColor="black"
                  disabled={item.disabled}
                  label={item.text}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...a11yProps(index)}
                  className={cc.tab}
                />
              )
          )}
        </Tabs>
      </Box>
      {items.map(
        (item, index) =>
          item.visible && (
            <TabPanel
              value={value}
              index={index}
              key={item.url}
              panelClass={cc.tabContentPanel}
            >
              {item.children}
            </TabPanel>
          )
      )}
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  tabContentPanel: {
    marginTop: theme.spacing(5),
    fontFamily: theme.typography.fontFamily
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: '2px solid #d6e5eB'
  },
  tab: {
    minWidth: 'initial',
    padding: '16px',
    fontSize: '15px',
    color: 'black',
    fontWeight: 1000
  }
}));

export default TabView;

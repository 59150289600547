import { Theme, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useDashboardSlice } from 'app/layouts';
import { StatusToColor, pageSizeOptions } from 'constants/app';
import { ColumnProps, Dot, TablePaginated, Text } from 'design';
import React from 'react';
import { Router } from 'types/Router';
import { useDispatch } from 'react-redux';
import EquipmentTableAction from '../EquipmentTableActions';
import { MenuActionItemProps } from '../EquipmentTableActions/EquipmentTableAction';
import { Sim } from 'types/Sim';
import EquipmentPortalLink from '../EquipmentPortalLink/EquipmentPortalLink';
import { Powersource } from 'types/PowerSource';
interface RouterTableProps {
  routers?: Router[];
  powersources?: Powersource[];
}

/**
 * Router table props
 * @param {RouterTableProps} props
 * @returns {JSX.Element}
 */
const RouterTable = ({
  routers,
  powersources
}: RouterTableProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const cc = useStyles();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const menuItems: MenuActionItemProps[] = [
    {
      label: 'Manage SIMs',
      isVisible: true,
      lazyComponent: '',
      size: 'large'
    },
    {
      label: 'Edit',
      isVisible: true,
      lazyComponent: 'BundleRouterUpdate',
      size: 'xlarge'
    }
  ];

  const Columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'operationalStatus.name',
        label: '',
        align: 'center',
        hidden: currentUser?.isOrgAdminOrUser,
        render: ({ operationalStatus }: Router) => (
          <Dot
            color={
              operationalStatus?.name
                ? StatusToColor[operationalStatus?.name]
                : null
            }
          />
        ),
        cellWidth: 43,
        padding: 'none'
      },
      {
        accessor: 'expandIcon',
        label: '',
        align: 'center',
        padding: 'none',
        cellWidth: 43,
        resizable: true,
        render: ({ id }: Router) => (
          <IconButton
            data-testid="expandButton"
            className={cc.expandButton}
            onClick={() => {
              dispatch(
                actions.showDialog({
                  open: true,
                  title: '',
                  lazyComponent: 'BundleRouterExpand',
                  size: 'large',
                  lazyComponentProps: {
                    id: id
                  }
                })
              );
            }}
          >
            <LaunchIcon fontSize="small" className={cc.linkIcon} />
          </IconButton>
        )
      },
      {
        accessor: 'routerCatalog.model',
        label: 'Name',
        verticalAlign: 'top',
        resizable: true,
        render: ({ routerCatalog, adminStatus }: Router) => (
          <>
            <Text>{routerCatalog?.model}</Text>
            <Text className={cc.subRow}>{adminStatus?.name}</Text>
          </>
        )
      },
      {
        accessor: 'serialNumber',
        label: 'Serial Number',
        verticalAlign: 'center',
        render: (routerRow: Router) => (
          <EquipmentPortalLink
            brand={routerRow.routerCatalog.brand}
            group="router"
            serialNumber={routerRow.serialNumber}
          />
        ),
        resizable: true
      },
      {
        accessor: 'ip',
        label: 'Virtual IP',
        resizable: true
      },
      {
        accessor: 'mac',
        label: 'MAC Address',
        resizable: true
      },
      {
        accessor: 'id',
        label: 'Power',
        render: (routerRow: Router) => (
          <Text>
            {powersources?.find(item => item.routerId === routerRow.id)
              ?.powersourceCatalog?.model ?? '-'}
          </Text>
        ),
        resizable: true
      },
      // TODO:  SIM implementation for location bundle
      {
        accessor: 'iccId',
        label: 'SIM 1',
        render: ({ sims }) => {
          const sim = sims?.length
            ? sims?.find((simInfo: Sim) => simInfo.slot === 1)
            : null;
          return (
            <>
              <Text>{sim?.iccId || '-'}</Text>
              <Text className={cc.subRow}>
                {sim?.carrier?.name || '-'} | {sim?.simStatus?.name || '-'}
              </Text>
            </>
          );
        },
        resizable: true
      },
      {
        accessor: 'iccId',
        label: 'SIM 2',
        render: ({ sims }) => {
          const sim = sims?.length
            ? sims?.find((simInfo: Sim) => simInfo.slot === 2)
            : null;
          return (
            <>
              <Text>{sim?.iccId || '-'}</Text>
              <Text className={cc.subRow}>
                {sim?.carrier?.name || '-'} | {sim?.simStatus?.name || '-'}
              </Text>
            </>
          );
        },
        resizable: true
      },
      {
        accessor: '',
        label: '',
        hidden: currentUser?.isOrgAdminOrUser,
        render: (router: Router) => (
          <EquipmentTableAction menuItems={menuItems} equipmentItem={router} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  return (
    <TablePaginated
      id="Router"
      columns={Columns}
      handlePageSize={setPageSize}
      handlePageNav={setPageIndex}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageSizeOptions={pageSizeOptions}
      isLoading={false}
      rows={routers ? routers : []}
      total={0}
      hidePagination={true}
    />
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  expandButton: {
    padding: 0,
    marginTop: '2px'
  },
  linkIcon: {
    color: '#A9B3B7',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '-8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  subRow: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
export default RouterTable;

import { useQuery, UseQueryResult } from 'react-query';
import { query } from 'constants/app';
import qs from 'qs';
import axios, { AxiosError, AxiosResponse } from '../utils/axiosProvider';

export interface StatusData {
  total: number;
  online: number;
  offline: number;
  trouble: number;
}

interface GetLocationsParams {
  queryKey: [__0: string, accountId: string | undefined];
}

const getLocationStatusCnt = async ({ queryKey }: GetLocationsParams) => {
  const [, accountId] = queryKey;
  const { data }: AxiosResponse = await axios.get('/accounts/status/count', {
    params: {
      accountId
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  const { total, online, offline, trouble, none } = data;

  return {
    total: Number(total),
    online: Number(online),
    offline: Number(offline),
    trouble: Number(trouble),
    none: Number(none)
  };
};

const useLocationsStatusCnt = (
  orgId?: string | undefined
): UseQueryResult<StatusData, AxiosError> => {
  return useQuery<StatusData, AxiosError>(
    [query.locationsStatus, orgId],
    getLocationStatusCnt
  );
};

export default useLocationsStatusCnt;

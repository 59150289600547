import React from 'react';
import styles from './styles.module.scss';
import { AssessmentScore } from '../../../types/AssessmentScore';

const scoreMap = {
  [AssessmentScore.VeryGood]: {
    message: 'Very Good',
    className: 'success'
  },
  [AssessmentScore.Good]: {
    message: 'Good',
    className: 'success'
  },
  [AssessmentScore.Acceptable]: {
    message: 'Acceptable',
    className: 'warn'
  },
  [AssessmentScore.Fair]: {
    message: 'Fair',
    className: 'warn'
  },
  [AssessmentScore.Marginal]: {
    message: 'Marginal',
    className: 'error'
  },
  [AssessmentScore.Poor]: {
    message: 'Poor',
    className: 'error'
  },
  [AssessmentScore.NotAvailable]: {
    message: 'No Status',
    className: 'no-status'
  }
};

const ScorePill = ({ score }: { score: AssessmentScore }) => {
  return (
    <span
      data-testid="status-pill"
      className={`${styles.statusPill} ${styles[scoreMap[score].className]}`}
    >
      {scoreMap[score].message}
    </span>
  );
};

export default ScorePill;

import React from 'react';

import { Card, CardContent, Text } from 'design';
import { ReactComponent as ContactUsIcon } from 'assets/img/ContactUsIcon.svg';
import ContactUsForm from './ContactUsDialog/ContactUsForm';
import styles from './HelpCenter.module.scss';

export interface ContactUsCardProps {
  dialog?: boolean;
  closeDialog?: () => void;
}

const ContactUsCard = ({ dialog = false, closeDialog }: ContactUsCardProps) => {
  const contactUsCardProps = {
    title: 'CONTACT US',
    subTitle: 'Open a Support Ticket',
    linkRoute: '',
    icon: <ContactUsIcon />
  };

  const [supportTicketDialogOpen, setSupportTicketDialogOpen] = React.useState(
    false
  );

  return (
    <>
      <Card
        className={dialog ? styles.cardDialog : styles.card}
        onClick={() => setSupportTicketDialogOpen(true)}
      >
        <CardContent>
          <div className={styles.cardContent}>
            {contactUsCardProps.icon}
            <Text variant="subtitle2" className={styles.titleText}>
              {contactUsCardProps.title}
            </Text>
            <Text variant="body1">{contactUsCardProps.subTitle}</Text>
          </div>
        </CardContent>
      </Card>

      {supportTicketDialogOpen && (
        <ContactUsForm
          handleClose={() => {
            setSupportTicketDialogOpen(false);
            if (closeDialog) {
              closeDialog();
            }
          }}
        />
      )}
    </>
  );
};

export default ContactUsCard;

import { useMutation, UseMutationResult } from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';

export interface SupportTicketParams {
  email: string;
  telephoneNumber: string;
  priority: string;
  subject: string;
  siteContact: string;
  requester: string;
  organization: string;
  location?: string;
  equipments?: string[];
  lines?: string[];
  description: string;
}

/**
 * This creates a new support ticket through the cmdc api
 * @param reqBody SupportTicketParams
 * @returns Promise<number>
 */
const postSupportTicket = async (
  reqBody: SupportTicketParams
): Promise<number> => {
  const { data } = await axios.post(`/support`, reqBody);
  return data;
};

const useCreateSupportTicket = (): UseMutationResult<
  number,
  AxiosError,
  SupportTicketParams
> => {
  const mutateMe = useMutation<number, AxiosError, SupportTicketParams>(
    postSupportTicket
  );

  return mutateMe;
};

export { useCreateSupportTicket };

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectAuthSlice = (state: RootState) => state?.auth ?? initialState;

const selectReturndirectSlice = (state: RootState) =>
  state?.auth?.returndirect ?? '';

export const selectAuth = createSelector([selectAuthSlice], state => state);

export const selectReturnDirect = createSelector(
  [selectReturndirectSlice],
  state => state
);

import { iccIdExists } from 'api/useSim';
import { ICCID_REGEX } from 'constants/app';
import { getIn } from 'formik';
import { debounce } from 'perfect-debounce';
import * as Yup from 'yup';

const debouncedIccid = debounce(iccIdExists, 1000);

const checkDuplicates = (
  path: string,
  values: any,
  value: string | undefined,
  key: string
) => {
  const findIndex = path.match(/\d+/);
  const index = findIndex ? parseInt(findIndex[0]) : 0;
  const dups = values.some(
    (v: any, i: number) => i !== index && v[key] === value
  );
  return dups;
};

const schema = (equipmentSims: any, values: any, slotsCount: number) => {
  return Yup.array().of(
    Yup.object().shape({
      iccId: Yup.string()
        .notRequired()
        .matches(ICCID_REGEX, {
          excludeEmptyString: true,
          message: 'Please provide a valid ICCID'
        })
        .min(17, `Minimum 17 characters required.`)
        .max(
          23,
          ({ value }) =>
            `Your ICCID is ${value.length} characters long, it is too long.`
        )
        .test(
          'Duplicate ICCID',
          'ICCID already exists',
          async (value, context) => {
            if (getIn(equipmentSims, context.path) === value) return true;
            if (value && value.length <= 23 && value.length >= 17) {
              const checkIccIdExist = await debouncedIccid(value);
              if (checkIccIdExist) {
                return false;
              }
            }
            return true;
          }
        )
        .test('findDuplcates', 'ICCID already exists', (value, context) => {
          if (!value) return true;
          return !checkDuplicates(context.path, values, value, 'iccId');
        }),
      slot: Yup.string()
        .when('iccId', {
          is: (iccId: string) => !!iccId,
          then: Yup.string().required('Slot is required')
        })
        .test('findDuplcates', 'Slot already exists', (value, context) => {
          try {
            if (!value) return true;
            if (
              parseInt(value) > slotsCount ||
              parseInt(value) < 1 ||
              !/^\d+$/.test(value)
            )
              throw new Error(
                slotsCount === 1
                  ? 'Invalid slot number. Should be 1'
                  : `Invalid slot number. Should be between 1 and ${slotsCount}`
              );
            const dups = checkDuplicates(context.path, values, value, 'slot');
            if (dups) throw new Error('Slot already exists');
            return true;
          } catch (err) {
            return context.createError({ message: err.message });
          }
        }),
      activatedBy: Yup.object().when('iccId', {
        is: (iccId: string) => !!iccId,
        then: Yup.object().shape({
          value: Yup.string().required('Activated by is required')
        })
      }),
      simStatus: Yup.object().when('iccId', {
        is: (iccId: string) => !!iccId,
        then: Yup.object().shape({
          value: Yup.string().required('Sim status is required')
        })
      }),
      mdn: Yup.string(),
      apn: Yup.object(),
      carrier: Yup.object()
    })
  );
};

export default schema;

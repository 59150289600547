import * as React from 'react';
import {
  Table,
  Pagination,
  PaginationProps,
  PaginationDropdown,
  TableProps
} from 'design';
import { Hidden, makeStyles, Theme } from '@material-ui/core';

export interface TablePaginatedProps extends TableProps, PaginationProps {
  hidePagination?: boolean;
}

const TablePaginated = ({
  id,
  columns,
  rows,
  handleSort,
  sort,
  total,
  pageSizeOptions,
  pageIndex,
  pageSize,
  handlePageNav,
  handlePageSize,
  isLoading,
  resizeBehavior,
  hidePagination,
  includeDropdown
}: TablePaginatedProps): JSX.Element => {
  const cc = useStyles();
  return (
    <>
      <Hidden smUp>
        <div className={cc.mobileHeaderRoot}>
          {!hidePagination && (
            <PaginationDropdown
              handlePageSize={handlePageSize}
              handlePageNav={handlePageNav}
              pageSizeOptions={pageSizeOptions}
              pageSize={pageSize}
              currentPage={pageIndex}
              total={total}
              className={cc.paginationDropdownMobile}
            />
          )}
        </div>
      </Hidden>
      <Table
        id={id}
        columns={columns}
        rows={rows}
        handleSort={handleSort}
        sort={sort}
        resizeBehavior={resizeBehavior}
        isLoading={isLoading}
      />
      {!hidePagination && (
        <Pagination
          total={total}
          pageSizeOptions={pageSizeOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handlePageNav={handlePageNav}
          handlePageSize={handlePageSize}
          includeDropdown={includeDropdown}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileHeaderRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  paginationDropdownMobile: {
    position: 'relative'
  }
}));

export default TablePaginated;

import * as React from 'react';
import { DropdownOption } from 'design';
import { Hidden, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import ChevronLeft from '@material-ui/icons/ArrowDropDown';
import ChevronRight from '@material-ui/icons/ArrowDropUp';
import PageNavButton from './PageNavButton';
import PaginationDropdown from './PaginationDropdown';

export interface PaginationProps {
  total: number;
  pageIndex: number;
  pageSize: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNav: (pageIndex: number) => void;
  pageSizeOptions: DropdownOption[];
  includeDropdown?: boolean;
}

const Pagination = ({
  total,
  pageIndex = 1,
  pageSize,
  handlePageSize,
  handlePageNav,
  pageSizeOptions,
  includeDropdown = true
}: PaginationProps): JSX.Element => {
  const cc = useStyles();
  const l = 1;
  const r = Math.ceil(total / pageSize);

  // imperfect paging algo for rendering ellipsis conditionally :(
  const pageable = React.useMemo(() => {
    const dots = '...';
    let pages: any[] = [];

    if (total === 0) {
      return [1];
    }

    // if only one page
    if (r === 1) {
      return [pageIndex];
    }

    // if only two pages
    if (r === 2) {
      return [l, r];
    }

    // if only 3 pages
    if (r === 3) {
      return [l, r - 1, r];
    }

    // when the currentPage is far enough from the start and end
    pages = [l, dots, pageIndex - 1, pageIndex, pageIndex + 1, dots, r];

    // when the pageIndex is within left most page
    if (pageIndex < 3) {
      pages = [l, l + 1, l + 2, dots, r];
    }

    // when the pageIndex is within right most page
    if (pageIndex > r - 2) {
      pages = [l, dots, r - 2, r - 1, r];
    }

    return pages;
  }, [pageIndex, r, total]);

  const prevButtonClass = clsx(cc.customButton, {
    [cc.custtomButtonDisabled]: l >= pageIndex
  });
  const nextButtonClass = clsx(cc.customButton, {
    [cc.custtomButtonDisabled]: r <= pageIndex
  });

  return (
    <div className={cc.paginationRoot}>
      <div className={cc.paginationNav}>
        <PageNavButton
          className={prevButtonClass}
          disabled={l >= pageIndex}
          onClick={() => handlePageNav(pageIndex - 1)}
          newIndex={l}
          label={<ChevronLeft fontSize="small" />}
        />
        {pageable.map(pi => {
          // hanldes ellipsis as text
          if (typeof pi !== 'number')
            // TODO: create a ticket to refactor this(to avoid losing rel and re-renders)
            return <span key={`pageable-${Math.random()}`}>{pi}</span>;
          return (
            <PageNavButton
              onClick={() => handlePageNav(pi)}
              key={`pageable-${pi}`}
              newIndex={pi}
              currentPage={pageIndex}
            />
          );
        })}
        <PageNavButton
          className={nextButtonClass}
          label={<ChevronRight fontSize="small" />}
          newIndex={r}
          disabled={r <= pageIndex}
          onClick={() => handlePageNav(pageIndex + 1)}
        />
      </div>
      {includeDropdown ? (
        <Hidden xsDown>
          <PaginationDropdown
            handlePageSize={handlePageSize}
            handlePageNav={handlePageNav}
            pageSizeOptions={pageSizeOptions}
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
          />
        </Hidden>
      ) : (
        ''
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paginationRoot: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(2, 0)
  },
  paginationNav: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      color: theme.palette.grey['500']
    }
  },
  customButton: {
    padding: theme.spacing(0.25, 0, 0, 0),
    fontSize: theme.typography.body2.fontSize,
    '& > svg': {
      color: theme.palette.grey['500'],
      transform: `rotate(90deg)`,
      '&:active, &:hover': {
        color: theme.palette.common.black,
        backroundColor: 'none'
      },
      transitionProperty: 'hover',
      transitionDuration: `${theme.transitions.duration.short}ms`,
      transitionTimingFunction: theme.transitions.easing.easeOut
    }
  },
  custtomButtonDisabled: {
    '& > svg': {
      color: theme.palette.grey['50'],
      '&:active, &:hover': {
        color: theme.palette.grey['50'],
        backroundColor: 'none'
      }
    }
  }
}));

export default Pagination;

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { query } from 'constants/app';

export interface ExportDataParams {
  orgOrAccountId: string;
  dataType: string;
}

export interface ExportDataProgress {
  progress: number | null;
  url: string;
}

export interface GetExportDataProgressParams {
  queryKey: [__0: string, orgOrAccountId: string, dataType: string];
}

export interface DownloadOrgDataParams {
  entityId: string;
  resourceName: string;
  dataType: string;
}

/**
 * Creates a new report process if one is not already running
 * @param {ExportDataParams}
 */
const createExportData = async ({
  orgOrAccountId,
  dataType
}: ExportDataParams): Promise<void> => {
  // TODO: move this fetch & logic into the create export data mutator options
  const { progress } = await getExportProgress({
    queryKey: [query.orgExportData, orgOrAccountId, dataType]
  });
  if (progress === 100 || progress === null) {
    // no report process running, request new report
    await axios.post(`/data-export/${dataType}/${orgOrAccountId}`);
  }
};

/**
 * Create an organization report for master data or call logs
 * @returns react query mutator along with many helper props/methods
 */
const useCreateExportData = (): UseMutationResult<
  void,
  AxiosError,
  ExportDataParams
> => {
  const queryClient = useQueryClient();
  const mutateAction = useMutation<void, AxiosError, ExportDataParams>(
    createExportData,
    {
      onSuccess: (data, params) => {
        queryClient.invalidateQueries([
          query.orgExportData,
          params.orgOrAccountId,
          params.dataType
        ]);
      }
    }
  );

  return mutateAction;
};

/**
 *
 * @param {ExportDataParams}
 * @returns {ExportDataProgress}
 */
const getExportProgress = async ({
  queryKey
}: GetExportDataProgressParams): Promise<ExportDataProgress> => {
  const [, orgOrAccountId, dataType] = queryKey;
  const reportProcess = await axios.get(
    `/data-export/${dataType}/${orgOrAccountId}`
  );

  return {
    progress: reportProcess.data.progress ?? null,
    url: reportProcess.data.url ?? ''
  };
};

const useExportData = (
  orgOrAccountId: string | undefined,
  dataType: string,
  enabled: boolean
): UseQueryResult<ExportDataProgress, AxiosError> => {
  return useQuery<ExportDataProgress, AxiosError>(
    [query.orgExportData, orgOrAccountId, dataType],
    getExportProgress,
    {
      refetchInterval: 10000,
      enabled
    }
  );
};

export { useCreateExportData, useExportData };

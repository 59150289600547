import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardFooter } from 'design';
import PowerStatusIcon from '../PowerStatusIcon';
import BatteryStatusIcon from '../BatteryStatusIcon';
import { Powersource } from 'types/PowerSource';
import { Equipment } from 'types';

interface BatteryProps {
  battery?: Equipment;
  powersource?: Powersource;
}

const PowerSourceCard = ({
  battery,
  powersource
}: BatteryProps): JSX.Element => {
  const cc = useStyles();
  return (
    <Card key={powersource?.id ?? battery?.id}>
      <CardHeader title="Power Source" />
      <CardContent>
        <p className={cc.subTitle}>
          Viewing{' '}
          {battery?.catalogItem.brand ?? powersource?.powersourceCatalog.brand}{' '}
          Battery
        </p>
        <p className={cc.serialNumber}>
          {battery?.serialNumber ?? powersource?.serialNumber}
        </p>
        {battery?.batteryStatus !== null ||
        powersource?.batteryStatus !== null ? (
          <div className={cc.powerStatus}>
            <div>
              <PowerStatusIcon
                isOnBackup={
                  battery?.batteryStatus?.isOnBackup ??
                  powersource?.batteryStatus?.isOnBackup
                }
              />
            </div>
            <div>
              <BatteryStatusIcon
                isLowBattery={
                  battery?.batteryStatus?.isLowBattery ??
                  powersource?.batteryStatus?.isLowBattery
                }
                isMissing={
                  battery?.batteryStatus?.isMissing ??
                  powersource?.batteryStatus?.isMissing
                }
                needsReplacement={
                  battery?.batteryStatus?.needsReplacement ??
                  powersource?.batteryStatus?.needsReplacement
                }
                isUnknown={
                  battery?.batteryStatus?.isUnknown ??
                  powersource?.batteryStatus?.isUnknown
                }
              />
            </div>
          </div>
        ) : (
          <div className={cc.missingStatus}>
            <BatteryStatusIcon isMissing />
          </div>
        )}
      </CardContent>
      <CardFooter>
        <a
          href="https://support.marketspark.com/hc/en-us"
          className={cc.link}
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </a>
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    marginTop: '-5px'
  },
  serialNumber: {
    color: theme.palette.grey[500],
    marginTop: '-5px'
  },

  powerStatus: {
    paddingTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& svg': {
      color: theme.palette.grey[500]
    }
  },

  missingStatus: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },

  link: {
    fontSize: '14px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}));

export default PowerSourceCard;

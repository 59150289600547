/**
 *
 * PrivateRoute
 *
 */
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import moment from 'moment';
import { selectAuth } from 'app/slice/selectors';
import { useAuthSlice } from 'app/slice';
import { selectCurrentUser } from 'store/selectors';

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({
  children,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { exp } = useSelector(selectAuth);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => {
        // you're logged in, be on your way
        if (exp) {
          const tokenIsNotExpired = exp - moment().unix() >= 0;
          // if user has an id, and not expired
          const authValid = tokenIsNotExpired && user?.id;

          // we got a valid auth somehow
          if (authValid) {
            // did valid auth come from the store?
            return children;
          }
        }

        // set the current url to get back here
        dispatch(actions.setReturndirect(location.pathname));
        // you're not ok, go login
        return (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_AUTH_LOGIN_URI,
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LaunchIcon from '@material-ui/icons/Launch';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  PagedBundles,
  useAdminStatuses,
  useUpdateAdminStatus
} from 'api/useBundle';
import { selectCurrentUser } from 'store/selectors';
import { useDashboardSlice } from 'app/layouts';
import { Text } from 'design';
import Dropdown from 'design/Dropdown';
import {
  DropdownOption,
  labelValuesFor,
  LabelValuesMap
} from 'design/Dropdown/Dropdown';
import GatewayTable from '../BundleGatewayTable';
import PowerSourceTable from '../BundlePowerSourceTable';
import RouterTable from '../BundleRouterTable';
import { Bundle } from 'types';
import { TO_BE_RETURNED } from 'constants/app';
import styles from './BundleTables.module.scss';

interface BundleTablesProps {
  bundlesData: PagedBundles;
  locationId: string;
}

/**
 * Bundle table props
 * @param {BundleTablesProps} props
 * @returns {JSX.Element}
 */
const BundleTables = ({
  bundlesData,
  locationId
}: BundleTablesProps): JSX.Element => {
  const dispatch = useDispatch();
  const { data: installStatuses } = useAdminStatuses();
  const { actions } = useDashboardSlice();
  const { mutateAsync: updateAdminStatus } = useUpdateAdminStatus();

  const currentUser = useSelector(selectCurrentUser);

  const installStatusList: LabelValuesMap[] = React.useMemo(() => {
    return labelValuesFor(installStatuses);
  }, [installStatuses]);

  /**
   * Update bundle status
   */
  const updateStatus = async (statusId: string, bundleId: string) => {
    try {
      if (bundlesData) {
        const statusInfo = {
          adminStatusId: statusId,
          bundleId
        };
        await updateAdminStatus(statusInfo);
        dispatch(
          actions.showNotify({
            message: 'Status updated successfully.',
            severity: 'success'
          })
        );
      }
    } catch (err) {
      dispatch(
        actions.showNotify({
          message: 'Error: could not update status!',
          severity: 'error'
        })
      );
    }
  };

  const isDropdownDisabled = (bundle: Bundle): boolean => {
    if (currentUser.isOrgAdmin || currentUser.isUser) {
      return true;
    }
    if (bundlesData?.bundles.length === 1) {
      return false;
    }
    if (bundle.adminStatus?.name === TO_BE_RETURNED) {
      // Check if all other bundles are in 'To Be Returned' status
      return !bundlesData?.bundles.every(
        eve => eve.adminStatus?.name === TO_BE_RETURNED
      );
    }
    return false;
  };

  return (
    <>
      {bundlesData?.bundles.map((bundle, index) => {
        return (
          <React.Fragment key={bundle.id}>
            <div className={styles.headerLeft}>
              <LaunchIcon
                fontSize="small"
                className={styles.linkIcon}
                onClick={() => {
                  dispatch(
                    actions.showDialog({
                      open: true,
                      title: '',
                      lazyComponent: 'EquipmentBundleExpand',
                      size: 'large',
                      lazyComponentProps: {
                        id: bundle.id
                      }
                    })
                  );
                }}
              />
              <Text className={styles.bundleHeaderText}>
                {bundle.bundleCatalog?.name}
              </Text>
              {currentUser.isSuperAdmin && (
                <SettingsIcon
                  fontSize="small"
                  className={styles.linkIcon}
                  onClick={() => {
                    dispatch(
                      actions.showDialog({
                        open: true,
                        title: '',
                        lazyComponent: 'EquipmentBundleSettings',
                        size: 'large',
                        lazyComponentProps: {
                          bundle
                        }
                      })
                    );
                  }}
                />
              )}
              <Dropdown
                id={`${index}.installStatus`}
                name={`${index}.installStatus`}
                placeholder="Select One"
                isError={false}
                className={styles.dropdownSelection}
                disabled={isDropdownDisabled(bundle)}
                label=""
                onChange={(e: DropdownOption) => {
                  const selectedStatus = installStatuses?.find(
                    eve => eve.id === e.value
                  );
                  bundle.adminStatus = selectedStatus;
                  updateStatus(e.value, bundle.id);
                }}
                dropdownItems={installStatusList}
                value={bundle.adminStatus?.id}
              />
            </div>
            <div className={styles.routertable}>
              <Text className={styles.tableHeaderText}>Router</Text>
              <RouterTable
                routers={bundle.routers}
                powersources={bundle.powersources}
              />
            </div>
            <div className={styles.tables}>
              <Text className={styles.tableHeaderText}>Gateway</Text>
              <GatewayTable
                gateways={bundle.gateways}
                locationId={locationId}
              />
            </div>
            <div className={styles.tables}>
              <Text className={styles.tableHeaderText}>Power Source</Text>
              <PowerSourceTable
                powersources={bundle.powersources}
                bundleId={bundle.id}
              />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BundleTables;

import * as Yup from 'yup';
import _ from 'lodash';

import {
  INHAND_ROUTER,
  SANGOMA,
  POWER_TEC,
  validationErrorMessages
} from 'constants/app';
import {
  serialNumber,
  ipAddress,
  mac,
  imei,
  domain,
  catchCase,
  inHandIpAddress
} from 'utils/commonValidations';
import { ValidateFunc } from 'design';
import { Router, Gateway, Powersource } from 'types';

const routersValidator = (router?: Router) => {
  const schema = Yup.object().shape({
    serialNumber: Yup.string().test(async (value, context) => {
      try {
        const model =
          router?.routerCatalog?.model ?? context.parent.routerCatalog?.model;

        return await serialNumber(value, context, 'routers', router?.id, model);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    ip: Yup.string().when('routerCatalog.brand', {
      is: (brand: string) => {
        const fullBrand = brand ?? router?.routerCatalog?.brand;
        return fullBrand === INHAND_ROUTER;
      },
      then: Yup.string().test(async (value, context) => {
        try {
          return await inHandIpAddress(value, context, router?.id);
        } catch (error) {
          return catchCase(context, error);
        }
      })
    }),
    mac: Yup.string().test(async (value, context) => {
      try {
        const brand =
          router?.routerCatalog?.brand ?? context.parent.routerCatalog?.brand;
        return await mac(value, context, 'routers', router?.id, brand);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    userName: Yup.string().when('routerCatalog.brand', {
      is: (brand: string) => {
        const fullBrand = brand ?? router?.routerCatalog?.brand;
        return fullBrand === INHAND_ROUTER;
      },
      then: Yup.string().required(validationErrorMessages.userNameRequired)
    }),
    password: Yup.string().when('routerCatalog.brand', {
      is: (brand: string) => {
        const fullBrand = brand ?? router?.routerCatalog?.brand;
        return fullBrand === INHAND_ROUTER;
      },
      then: Yup.string().required(validationErrorMessages.passwordRequired)
    }),
    imei: Yup.string().test(async (value, context) => {
      try {
        return await imei(value, context, 'routers', router?.id);
      } catch (error) {
        return catchCase(context, error);
      }
    })
  });

  return schema;
};

const routersValidatorArray = () => Yup.array().of(routersValidator());

const gatewayValidator = (locationId: string, gateway?: Gateway) => {
  const schema = Yup.object().shape({
    serialNumber: Yup.string().test(async (value, context) => {
      try {
        return await serialNumber(value, context, 'gateways', gateway?.id);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    ip: Yup.string().when('gatewayCatalog.brand', {
      is: (brand: string) => {
        const fullBrand = brand ?? gateway?.gatewayCatalog?.brand;
        return fullBrand === INHAND_ROUTER;
      },
      then: Yup.string().test(async (value, context) => {
        try {
          return await ipAddress(value, context, 'gateways', gateway?.id);
        } catch (error) {
          return catchCase(context, error);
        }
      })
    }),
    mac: Yup.string().test(async (value, context) => {
      try {
        const brand =
          gateway?.gatewayCatalog?.brand ??
          context.parent.gatewayCatalog?.brand;

        return await mac(value, context, 'gateways', gateway?.id, brand);
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    domain: Yup.string().test(async (value, context) => {
      try {
        const brand =
          gateway?.gatewayCatalog?.brand ??
          context.parent.gatewayCatalog?.brand;

        return await domain(value, context, brand, locationId, gateway?.id);
      } catch (error) {
        return catchCase(context, error);
      }
    })
  });

  return schema;
};

const gatewaysValidatorArray = (locationId: string) =>
  Yup.array().of(gatewayValidator(locationId, undefined));

const powersourcesValidator = (powersource?: Powersource) => {
  const schema = Yup.object().shape({
    serialNumber: Yup.string().test(async (value, context) => {
      try {
        // Serial Required if PowerTec
        const brand =
          powersource?.powersourceCatalog?.brand ??
          context.parent.powersourceCatalog?.brand;
        if (brand === POWER_TEC && !value) {
          return context.createError({
            message: validationErrorMessages.serialNumberRequired
          });
        }
        // Otherwise can be empty
        if (!value) {
          return true;
        }

        return await serialNumber(
          value,
          context,
          'powersources',
          powersource?.id
        );
      } catch (error) {
        return catchCase(context, error);
      }
    }),
    routerId: Yup.string().required(validationErrorMessages.managedByRequired),
    batteries: Yup.array().of(
      Yup.string().test(async (value, context) => {
        try {
          // Can be empty
          if (!value) {
            return true;
          }

          // Only check values that are defined, type cast required to use filter
          const definedBatteries = (context.parent as (
            | string
            | undefined
          )[]).filter(battery => battery !== undefined);

          const unique =
            new Set(definedBatteries).size === definedBatteries.length;
          if (!unique) {
            return context.createError({
              message: validationErrorMessages.batterySerialNumberUnique
            });
          }

          return true;
        } catch (error) {
          return catchCase(context, error);
        }
      })
    )
  });

  return schema;
};

const powersourcesValidatorArray = () =>
  Yup.array().of(powersourcesValidator());

const validateRouter: ValidateFunc = async (err, path, trigger, formData) => {
  const isInhand =
    _.get(formData, path)?.routerCatalog?.brand === INHAND_ROUTER;
  const paths = ['serialNumber', 'mac', 'imei'];
  if (isInhand) {
    paths.push('ip', 'userName', 'password');
  }
  const extendedPath = paths.map(p => `${path}.${p}`);
  const isValid = await trigger(extendedPath);
  return isValid;
};

const validateGateway: ValidateFunc = async (err, path, trigger, formData) => {
  const isSangoma = _.get(formData, path)?.gatewayCatalog?.brand === SANGOMA;
  const paths = ['serialNumber', 'mac', 'domain'];
  if (isSangoma) {
    paths.push('ip');
  }
  const extendedPath = paths.map(p => `${path}.${p}`);
  const isValid = await trigger(extendedPath);
  return isValid;
};

const validatePowersource: ValidateFunc = async (err, path, trigger) => {
  const paths = ['serialNumber', 'routerId'];
  const extendedPath = paths.map(p => `${path}.${p}`);
  const isValid = await trigger(extendedPath);
  return isValid;
};

export {
  routersValidator,
  routersValidatorArray,
  gatewayValidator,
  gatewaysValidatorArray,
  powersourcesValidator,
  powersourcesValidatorArray,
  validateGateway,
  validateRouter,
  validatePowersource
};

import React from 'react';
import moment from 'moment';
import { CloseSharp as CloseIcon } from '@material-ui/icons';

import { Dialog, Icon, Text } from 'design';
import { N2PEventItem } from './ToolLineStatus';
import { LINE_STATUS_MINUTE_THRESHOLD } from 'constants/app';
import styles from './ToolLineStatus.module.scss';

export interface ToolLineStatusModalProps {
  open: boolean;
  handleClose: () => void;
  lines: N2PEventItem[];
}

const ToolLineStatusModal = ({
  open,
  handleClose,
  lines
}: ToolLineStatusModalProps): JSX.Element => {
  return (
    <Dialog open={open} handleClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Text variant="h5">N2P Line Status</Text>
          <div onClick={handleClose}>
            <Icon Mark={CloseIcon} className={styles.closeIcon} />
          </div>
        </div>
        <Text variant="h6" className={styles.subTitle}>
          Assigned Numbers
        </Text>
        {lines.map((line, index) => (
          <div key={index} className={styles.line}>
            <Text variant="body2" className={styles.purpleText}>
              {line.extension}
            </Text>
            <Text variant="body2">
              {line.registrationDate !== '' &&
              moment.utc().diff(moment.utc(line.registrationDate), 'minutes') <
                LINE_STATUS_MINUTE_THRESHOLD
                ? 'Online'
                : 'Offline'}
            </Text>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default ToolLineStatusModal;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { Button, Dialog } from 'design';
import Grid from '@material-ui/core/Grid';
import { Text, Textarea } from 'design';
import { useCreateLocationNote } from 'api/useLocationNotes';
import cc from './LocationAddNote.module.scss';

export interface AddLocationNoteProps {
  locationId: string;
  userId: string;
  handleClose: () => void;
}

const LocationAddNote = ({
  locationId,
  userId,
  handleClose
}: AddLocationNoteProps): JSX.Element => {
  const [newNoteText, setNewNoteText] = React.useState<string>('');
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useCreateLocationNote();

  const handleAddNote = React.useCallback(
    async (note: string) => {
      try {
        await mutateAsync({
          locationId,
          userId,
          text: note
        });
        dispatch(
          actions.showNotify({
            message: 'Note added successfully!',
            severity: 'success'
          })
        );
        handleClose();
      } catch (err) {
        dispatch(
          actions.showNotify({ message: 'Error: could not create note!' })
        );
      }
    },
    [locationId, actions, mutateAsync, dispatch, userId, handleClose]
  );

  return (
    <Dialog open={true} handleClose={handleClose}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={cc.header}>
          <Text variant="h4" align="left">
            Add Note
          </Text>
        </Grid>
        <Grid item className={cc.noteDialogContainer}>
          <Textarea
            rows={8}
            label="Description"
            name="Description"
            id="txtNote"
            inputProps={{ width: '100%' }}
            disabled={false}
            formControlProps={{ style: { width: '100%' } }}
            isError={false}
            onChange={(event: any) => setNewNoteText(event.target.value)}
            onBlur={() => {}}
            value=""
          />
        </Grid>

        <div className={cc.formFooter}>
          <Button
            variant="outlined"
            isLoading={isLoading}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!newNoteText}
            isLoading={isLoading}
            onClick={() => {
              handleAddNote(newNoteText);
            }}
          >
            Submit
          </Button>
        </div>
      </Grid>
    </Dialog>
  );
};

export default LocationAddNote;

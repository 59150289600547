import * as React from 'react';
import { useOrgNetProvider, useSyncOrgNetProvider } from 'api/useOrgs';
import { Card, CardContent, CardFooter, CardHeader } from 'design';
import { Refresh } from '@material-ui/icons';
import clsx from 'clsx';
import { SyncStatusEnum } from 'types/SyncStatus';
import { Box, CircularProgress } from '@material-ui/core';
import ProviderStatus from './ProviderStatus';
import ToolTip from 'design/Tooltip/Tooltip';
import styles from './providercard.module.scss';
import { SyncTypeEnum } from 'types/ApiSync';

const OrgProviderCard = ({
  organizationId
}: {
  organizationId: string;
}): JSX.Element => {
  const { data, isLoading, isError } = useOrgNetProvider(organizationId);
  const {
    mutateAsync: syncProviderData,
    isLoading: updateLoading
  } = useSyncOrgNetProvider();

  const resyncFunc = async () => {
    await syncProviderData(organizationId);
  };

  const anyLoad = updateLoading || isLoading;

  if (isError) {
    return (
      <Card>
        <CardHeader title="Provider" />
        <CardContent>
          <p>
            An error occured. Please refresh or contact support if this error
            persists
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Provider"
        action={
          <div className="card-action-wrapper">
            {!anyLoad && (
              <Refresh
                className={clsx({
                  'card-action-wrapper__icon': true,
                  [styles.providerAction]: true,
                  active: data?.syncStatus !== SyncStatusEnum.Synced
                })}
                onClick={async () => {
                  if (data?.syncStatus !== SyncStatusEnum.Synced) {
                    await resyncFunc();
                  }
                }}
              />
            )}
          </div>
        }
      />
      <CardContent style={{ display: 'flex' }}>
        {anyLoad && !isError && (
          <Box className={styles.loader}>
            <CircularProgress thickness={5} />
          </Box>
        )}
        {data && (
          <div className={styles.netDataCard}>
            <ProviderStatus
              syncData={{
                ...data,
                type: SyncTypeEnum.Service
              }}
            />
            {data &&
              ['errored', 'failed'].includes(data.syncStatus) &&
              data?.reason && (
                <div className={styles.netDataCardReason}>{data?.reason}</div>
              )}
          </div>
        )}
      </CardContent>
      <CardFooter>
        {data && (
          <>
            <ToolTip body="For troubleshooting purposes">
              <p className={styles.netDataCardTag}>Tag: {data?.tag}</p>
            </ToolTip>
          </>
        )}
      </CardFooter>
    </Card>
  );
};

export default OrgProviderCard;

import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { Powersource } from 'types/PowerSource';
import axios, { AxiosError } from 'utils/axiosProvider';

interface PowerSourceParams {
  queryKey: [__0: string, id: string];
}

interface PowersourceStatusParams {
  queryKey: [__0: string, locationId: string];
}

const getPowerSource = async ({
  queryKey
}: PowerSourceParams): Promise<Powersource> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/powersources/${id}`);
  return data;
};

const usePowerSource = (id: string): UseQueryResult<Powersource, Error> => {
  return useQuery<Powersource, AxiosError>(
    [query.powersource, id],
    getPowerSource
  );
};

// gets bundle powersources
const getPowerSourcesByLocationId = async ({
  queryKey
}: PowerSourceParams): Promise<Powersource[]> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/powersources/location/${id}`);
  return data;
};

const getPowersourceBatteryStatus = async ({
  queryKey
}: PowersourceStatusParams): Promise<Powersource[]> => {
  const [, locationId] = queryKey;

  const { data } = await axios.get(`/powersources/status/${locationId}`);

  const powersourceWithStatus: Powersource[] = data;

  return powersourceWithStatus;
};

const usePowersourceBatteryStatus = (
  filter: string
): UseQueryResult<Powersource[], AxiosError> => {
  return useQuery<Powersource[], AxiosError>(
    [query.powersource, filter],
    getPowersourceBatteryStatus,
    {
      keepPreviousData: true
    }
  );
};

const deletePowerSource = async (id: string): Promise<void> => {
  await axios.delete(`/powersources/${id}`);
};

const useDeletePowerSource = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeletePowerSource = useMutation<void, AxiosError, any>(
    deletePowerSource,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.equipmentBundle]);
      }
    }
  );

  return mutateDeletePowerSource;
};

export {
  useDeletePowerSource,
  getPowerSourcesByLocationId,
  usePowersourceBatteryStatus
};

export default usePowerSource;

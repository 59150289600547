import React from 'react';

import { useUpdateDisclosuresMapTileset } from 'api/useDecommissionMap';
import { Button, Card, CardFooter, CardContent, CardHeader } from 'design';
import { STATIC_MAP_ID } from 'constants/app';

import styles from './updateDisclosuresStaticMap.module.scss';

export default function UpdateDisclosureMap() {
  const {
    isLoading,
    mutateAsync: patchMapTileset
  } = useUpdateDisclosuresMapTileset();

  const onClick = async () => {
    await patchMapTileset(STATIC_MAP_ID);
  };

  return (
    <Card>
      <CardHeader title="Update Disclosure Map" />
      <CardContent>
        <div className={styles.card}>
          <Button
            className={styles.button}
            onClick={onClick}
            isLoading={isLoading}
          >
            Trigger Update
          </Button>
        </div>
      </CardContent>
      <CardFooter>{null}</CardFooter>
    </Card>
  );
}

import * as React from 'react';
import MatuiCardHeader from '@material-ui/core/CardHeader';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

export interface CardHeaderProps {
  actionIcon?: React.ReactNode;
  handleAction?: () => void;
  title?: React.ReactNode;
  action?: React.ReactNode;
}

const CardHeader = ({
  actionIcon,
  handleAction,
  title,
  action
}: CardHeaderProps): JSX.Element => {
  const cc = useStyles();
  return (
    <MatuiCardHeader
      classes={{
        root: cc.root,
        title: cc.title,
        content: cc.content,
        action: cc.content
      }}
      action={
        action ? (
          <>{action}</>
        ) : (
          <IconButton
            className={cc.small}
            aria-label="settings"
            onClick={handleAction}
          >
            {actionIcon}
          </IconButton>
        )
      }
      title={title}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.grey['50']}`,
    paddingBottom: 8.5,
    flexGrow: 0
  },
  title: {
    ...theme.typography.h6
  },
  content: {
    alignSelf: 'flex-end'
  },
  small: {
    width: 24,
    height: 24,
    padding: 0,
    '& svg': {
      width: 24,
      height: 24
    },
    '&:hover': {
      background: 'none'
    }
  }
}));

export default CardHeader;

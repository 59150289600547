import clsx from 'clsx';
import * as React from 'react';
import { SubSync } from 'types/ApiSync';
import SyncIcon from './SyncIcon';
import styles from './providercard.module.scss';

export interface ProviderStatusProps {
  syncData: SubSync;
  onClick?: () => void;
}

const ProviderStatus = ({
  syncData,
  onClick
}: ProviderStatusProps): JSX.Element => {
  return (
    <div>
      <SyncIcon status={syncData.syncStatus} />
      <div className={styles.syncTitle}>{syncData.type}</div>
      <div
        className={clsx({
          [styles.syncStatus]: true,
          [styles.syncLink]: !!onClick
        })}
        onClick={onClick}
      >
        {syncData.syncStatus}
      </div>
    </div>
  );
};

export default ProviderStatus;

import React from 'react';
import { TextInput, Button } from 'design';

interface ContactFormProps {
  isLoading: boolean;
  handleClose: () => void;
  formProps: any;
}

const ContactForm = ({
  isLoading,
  formProps,
  handleClose
}: ContactFormProps) => {
  return (
    <form onSubmit={formProps.handleSubmit}>
      <div className="form-container" style={{ paddingTop: 32 }}>
        <TextInput
          id="firstName"
          name="firstName"
          label="First Name"
          value={formProps.values.firstName}
          onChange={formProps.handleChange}
          isError={!!formProps.errors.firstName}
          validationMessage={formProps.errors.firstName}
        />
        <TextInput
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formProps.values.lastName}
          onChange={formProps.handleChange}
          isError={!!formProps.errors.lastName}
          validationMessage={formProps.errors.lastName}
        />
        <TextInput
          id="email"
          name="email"
          label="Email"
          value={formProps.values.email}
          onChange={formProps.handleChange}
          isError={!!formProps.errors.email}
          validationMessage={formProps.errors.email}
        />
        <TextInput
          id="phoneMobile"
          name="phoneMobile"
          label="Primary Phone"
          value={formProps.values.phoneMobile}
          onChange={formProps.handleChange}
          isError={!!formProps.errors.phoneMobile}
          validationMessage={formProps.errors.phoneMobile}
        />
        <TextInput
          id="phoneWork"
          name="phoneWork"
          label="Secondary Phone"
          value={formProps.values.phoneWork}
          onChange={formProps.handleChange}
          isError={!!formProps.errors.phoneWork}
          validationMessage={formProps.errors.phoneWork}
        />
      </div>
      <div className="form-footer">
        <Button onClick={handleClose} variant="outlined" isLoading={isLoading}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          disabled={formProps.isSubmitting}
          onClick={e => {
            e.preventDefault();
            formProps.handleSubmit();
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;

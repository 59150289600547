import { Card, Text } from 'design';
import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { useFormContext } from 'design/Form/Form';

const BundleIncludesCard = () => {
  const { formData } = useFormContext();
  return (
    <Grid item xs={12} md={6}>
      <div className="right-info">
        <Card className="card-container">
          <Text className="card-header">Hardware Bundle Includes:</Text>
          <List>
            {formData?.routers?.map(({ routerCatalog }: any) => {
              return (
                <ListItem key={routerCatalog?.id} className="list-item">
                  <Text variant="body2" className="list-item-text">
                    {routerCatalog?.model}
                  </Text>
                </ListItem>
              );
            })}
            {formData?.gateways?.map(({ gatewayCatalog }: any) => {
              return (
                <ListItem key={gatewayCatalog?.id} className="list-item">
                  <Text variant="body2" className="list-item-text">
                    {gatewayCatalog?.model}
                  </Text>
                </ListItem>
              );
            })}
            {formData?.powersources?.map(({ powersourceCatalog }: any) => {
              return (
                <ListItem key={powersourceCatalog?.id} className="list-item">
                  <Text variant="body2" className="list-item-text">
                    {powersourceCatalog?.model}
                  </Text>
                </ListItem>
              );
            })}
          </List>
        </Card>
      </div>
    </Grid>
  );
};

export default BundleIncludesCard;

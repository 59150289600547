/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'design';
import { selectNotify } from 'app/layouts/slice/selectors';
import { useDashboardSlice } from 'app/layouts';

/**
 * Notification state wrapper for Alert component
 * @returns {JSX.Element}
 */
const Dialog = (): JSX.Element => {
  const dispatch = useDispatch();
  const notify = useSelector(selectNotify);
  const { actions } = useDashboardSlice();

  // const cc = useStyles();

  // This Dialog can only consume dynamic components from its parent dir

  return (
    <Alert
      open={Boolean(notify.open)}
      severity={notify.severity || 'error'}
      message={notify?.message}
      handleClose={() => dispatch(actions.hideNotify())}
      messages={notify.messages}
    />
  );
};

export default Dialog;

import { query } from 'constants/app';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import axios, { AxiosError } from 'utils/axiosProvider';
import { Gateway } from 'types/Gateway';

interface GatewayParams {
  queryKey: [__0: string, id: string];
}

const getGateway = async ({ queryKey }: GatewayParams): Promise<Gateway> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/gateways/${id}`);
  return data;
};
const useGateway = (
  id: string,
  pathName: string
): UseQueryResult<Gateway, Error> => {
  return useQuery<Gateway, AxiosError>([query.gateway, id], getGateway, {
    // api to call only if the pathName is gateway.
    enabled: pathName === 'gateway'
  });
};

// gets bundle routers
const getGatewayByLocationId = async ({
  queryKey
}: GatewayParams): Promise<Gateway[]> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/gateways/location/${id}`);
  return data;
};
const useGatewaysByLocation = (
  locationId: string
): UseQueryResult<Gateway[], AxiosError> => {
  return useQuery<Gateway[], AxiosError>(
    [query.gateways, locationId],
    getGatewayByLocationId,
    { enabled: !!locationId }
  );
};

const deleteGateway = async (id: string): Promise<void> => {
  await axios.delete(`/gateways/${id}`);
};

const useDeleteGateway = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteGateway = useMutation<void, AxiosError, any>(
    deleteGateway,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.equipmentBundle]);
      }
    }
  );

  return mutateDeleteGateway;
};

const doesGrandStreamMaxExist = async (mac: string): Promise<boolean> => {
  const { data } = await axios.get(`/grandstream/check-existence/${mac}`);
  return data;
};

export {
  useDeleteGateway,
  getGatewayByLocationId,
  useGatewaysByLocation,
  doesGrandStreamMaxExist
};

export default useGateway;

import React from 'react';

import { IpInputMask } from 'app/components/InputMasks/InputMask';
import { TextInput } from 'design';
import { EquipmentAndHardwareFieldInputProps } from '../types';

const VirtualIpFieldInput = ({
  name,
  value,
  validationMessage,
  isError,
  onChange,
  onBlur
}: EquipmentAndHardwareFieldInputProps) => {
  return (
    <TextInput
      id="virtualIp"
      name={name}
      label="Virtual IP"
      placeholder="##.##.#.##"
      inputComponent={IpInputMask}
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default VirtualIpFieldInput;

import * as React from 'react';
import { BatteryFull, BatteryUnknown, Battery20 } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';

import { ReactComponent as BatterySaver } from '../../../assets/img/batterysaver.svg';

const BatteryStatusIcon = ({
  isLowBattery,
  needsReplacement,
  isMissing,
  isUnknown
}: {
  isLowBattery?: boolean;
  needsReplacement?: boolean;
  isMissing?: boolean;
  isUnknown?: boolean;
}): JSX.Element => {
  const cc = useStyles();
  let batteryState = (
    <BatteryFull
      titleAccess="battery full"
      fontSize="large"
      className={cc.normal}
    />
  );
  let batteryText = 'Good';

  if (isLowBattery) {
    batteryState = (
      <Battery20
        titleAccess="battery at 20 percent"
        fontSize="large"
        className={cc.bad}
      />
    );
    batteryText = 'Low';
  }

  if (needsReplacement) {
    batteryState = <BatterySaver data-testid="batterySaver" />;
    batteryText = 'Replace';
  }

  if (isMissing) {
    batteryState = (
      <BatteryUnknown
        titleAccess="battery missing"
        fontSize="large"
        className={cc.bad}
      />
    );
    batteryText = 'Missing';
  }

  if (isUnknown) {
    batteryState = (
      <BatteryUnknown
        titleAccess="battery unknown"
        fontSize="large"
        className={cc.bad}
      />
    );
    batteryText = 'Unknown';
  }

  return (
    <div className={cc.batterySet}>
      {batteryState}
      <p className={cc.stateTitle}>State</p>
      <p className={cc.state}>{batteryText}</p>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  bad: {
    color: `${theme.palette.error.main} !important`
  },
  normal: {
    color: `${theme.palette.success.main} !important`
  },
  stateTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '5px 0 -10px',
    color: theme.palette.grey[500]
  },
  state: {
    fontSize: '14px'
  },
  batterySet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default BatteryStatusIcon;

import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'clsx';
import * as React from 'react';

import { FormControl, Input, InputLabel } from '@material-ui/core';

export interface TextInputProps {
  label?: string;
  id: string;
  name: string;
  type?: string;
  disabled?: boolean;
  adornments?: any;
  isError: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyUp?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | null;
  validationMessage?: string;
  validationType?: 'info' | 'success';
  size?: 'small' | 'medium';
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  inputComponent?: any;
  inputDisabledStyle?: any;
  maxLength?: number;
  onFocus?: (e: any) => void;
  helperText?: string;
}

const TextInput = (props: TextInputProps): JSX.Element => {
  const {
    label,
    id,
    name,
    type,
    disabled,
    adornments,
    isError,
    validationMessage,
    onChange,
    onKeyUp,
    value,
    validationType,
    size,
    onBlur,
    multiline,
    rows,
    placeholder,
    inputComponent,
    inputDisabledStyle,
    maxLength,
    onFocus,
    helperText
  } = props;

  const cc = useStyles();

  const validationMessageClass = cx(cc.validationMessage, {
    [cc.validationInfoMessage]: validationType === 'info',
    [cc.validationSuccessMessage]: validationType === 'success',
    [cc.validationErrorMessage]: isError
  });

  const onWheel = (e: any) => e.target.blur();

  const handleChange = (e: any) => {
    if (type === 'email') {
      e.target.value = e.target.value?.toLowerCase();
    }
    onChange(e);
  };
  return (
    <FormControl
      classes={{
        root: size === 'small' ? cc.smallFormControlRoot : cc.formControlRoot
      }}
      disabled={disabled}
      fullWidth
    >
      {label !== undefined ? (
        <InputLabel
          classes={{
            root: cc.inputLabelRoot,
            focused: cc.inputLabelFocused,
            disabled: cc.inputLabelDisabled
          }}
          htmlFor={id}
          disableAnimation
          shrink
        >
          {label}{' '}
        </InputLabel>
      ) : null}
      <Input
        // InputLabelProps={{ shrink: true }}
        classes={{
          root: size === 'small' ? cc.smallInputRoot : cc.inputRoot,
          error: cc.inputError,
          disabled: inputDisabledStyle || cc.inputDisabled
        }}
        value={value}
        type={type === undefined ? 'text' : type}
        id={id}
        name={name}
        error={isError}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...adornments}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onWheel={e => (type === 'number' ? onWheel(e) : undefined)}
        inputProps={{
          className: cc.inputElement,
          maxLength: maxLength,
          ...(type === 'number' ? { pattern: '[0-9]*' } : {})
        }}
        placeholder={placeholder || label}
        multiline={multiline}
        rows={rows}
        inputComponent={inputComponent}
      />
      {helperText && !isError && (
        <div className={validationMessageClass}>
          <span className={cc.validationInfoMessage}>{helperText}</span>
        </div>
      )}
      {id !== 'division' ? (
        <div className={validationMessageClass}>
          {isError && validationMessage}&nbsp;
        </div>
      ) : null}
    </FormControl>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: '1px',
    '&:after': {
      borderBottom: `2px solid ${theme.palette.grey[50]}`,
      transition: 'none',
      transform: 'none'
    },
    paddingLeft: theme.spacing(2),
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  smallInputRoot: {
    width: 65,
    backgroundColor: theme.palette.grey[300],
    marginBottom: '1px',
    '&:after': {
      borderBottom: `2px solid ${theme.palette.grey[50]}`,
      transition: 'none',
      transform: 'none'
    },
    paddingLeft: theme.spacing(2),
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  inputError: {
    '&:after': {
      transform: 'none',
      borderColor: `${theme.palette.error.main} !important`
    }
  },
  inputDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },
  formControlRoot: {
    minWidth: 65
  },
  smallFormControlRoot: {
    minWidth: 65,
    maxWidth: 65
  },
  inputLabelRoot: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    transform: 'none',
    fontSize: theme.typography.body2.fontSize
  },
  inputLabelFocused: {
    color: `${theme.palette.grey[500]} !important`
  },
  inputLabelDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },
  inputElement: {
    padding: '8px 0'
  },
  validationMessage: {
    paddingLeft: theme.spacing(2),
    paddingTop: '6px',
    fontSize: '0.875rem'
  },
  validationErrorMessage: {
    color: theme.palette.error.main
  },
  validationSuccessMessage: {
    color: theme.palette.error.main
  },
  validationInfoMessage: {
    color: theme.palette.grey[500]
  }
}));
export default TextInput;

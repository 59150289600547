import _ from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'utils/axiosProvider';
import { query } from 'constants/app';

/**
 * @param {getCatalog}
 * @returns {id: string, name: string, brand: string, group: string}[]
 */
const getCatalog = async ({ queryKey }: Record<string, any>) => {
  const { data }: AxiosResponse = await axios.get('/catalog/skus');
  return _.sortBy(data, 'name');
};

const useCatalog = (): UseQueryResult<Record<string, any>[], AxiosError> => {
  return useQuery<Record<string, any>[], AxiosError>(
    [query.catalog],
    getCatalog,
    {
      keepPreviousData: true
    }
  );
};

export { useCatalog };
export default useCatalog;

/**
 *
 * Location Page
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import Phone from '@material-ui/icons/Phone';
import ErrorIcon from '@material-ui/icons/Error';
import Search from '@material-ui/icons/Search';
import { useParams } from 'react-router-dom';
import PageHeader from 'app/components/PageHeader';
import { useLocationPageDetails } from 'api/useLocations';
import Breadcrumbs from 'design/Breadcumbs';
import { CardGrid } from 'design';
import LocationNotesCard from 'app/components/LocationNotesCard';
import LocationMediaCard from 'app/components/LocationMediaCard';
import LocationCallLogExportCard from 'app/components/LocationCallLogExportCard';
import DiagnosticsTab from 'app/components/DiagnosticsTab';
import { Helmet } from 'react-helmet-async';
import LocationEquipments from 'app/components/LocationEquipments';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import TabView from 'app/components/TabView';
import { TabItemProps } from 'app/components/TabView/TabView';
import LocationSiteInformation from 'app/components/LocationSiteInformation';
import LocationContactDetails from 'app/components/LocationContactDetails';
import { useOrg } from 'api/useOrgs';
import { ReactComponent as IVRIcon } from '../../../assets/img/IVR_Icon.svg';
import styles from './locationPage.module.scss';
import { IconButton } from '@material-ui/core';
import LocationEquipmentLines from 'app/components/LocationEquipmentLines';
import ManageLocation from 'app/components/ManageLocation';
import LocationProviderCard from 'app/components/NetProviderCard/LocationProviderCard';
import LocationEmergencyLines from 'app/components/LocationEmergencyLines';
import { selectCurrentUser } from 'store/selectors';
import { RoleName } from '../../../types/AuthRoles';
import CDRCard from '../../components/CDRCard';

function LocationPage(): JSX.Element {
  const dispatch = useDispatch();
  const { data: currentUser, isFetched: currentUserFetched } = useCurrentUser();
  const { actions } = useDashboardSlice();
  const { orgId: organizationId, locId: locationId, tab } = useParams<{
    orgId: string;
    locId: string;
    tab: string;
  }>();
  const { data: organizationDetails } = useOrg(organizationId);
  const {
    data: locationData,
    isError,
    error,
    refetch
  } = useLocationPageDetails(locationId);
  const user = useSelector(selectCurrentUser);
  const locationName = locationData ? locationData.name : '';
  const handleAddIVRLine = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: 'Add IVR Line',
        lazyComponent: 'ManageIVRLine',
        lazyComponentProps: {
          location: locationData
        }
      })
    );
  }, [actions, dispatch, locationData]);

  const tabItems = React.useMemo<TabItemProps[]>(
    () => [
      {
        text: 'GENERAL',
        icon: <ErrorIcon fontSize="small" />,
        url: 'general',
        visible: true,
        children: (
          <>
            <Helmet>
              <title> General </title>
              <meta
                name="description"
                content="MarketSpark - Command Center"
                data-rh="true"
              />
            </Helmet>
            <div>
              {user.primaryRole.name !== RoleName.installPartner && (
                <ManageLocation id={locationId} />
              )}
            </div>

            <CardGrid>
              <LocationSiteInformation locationId={locationId} />
              <LocationContactDetails
                locationId={locationId}
                organizationId={organizationId}
              />
              <LocationMediaCard locationId={locationId} />
              {user.primaryRole.name !== RoleName.installPartner && (
                <>
                  <LocationNotesCard locationId={locationId} />
                  <LocationCallLogExportCard locationId={locationId} />
                </>
              )}
              {currentUser?.isSuperAdmin &&
                (locationData?.organization?.isNewFlow ||
                  locationData?.organization?.isPartialFlow) && (
                  <LocationProviderCard locationId={locationId} />
                )}
              <CDRCard id={locationId} />
            </CardGrid>
          </>
        )
      },
      {
        text: 'LINES',
        icon: <Phone fontSize="small" />,
        url: 'lines',
        visible: true,
        children: (
          <>
            <Helmet>
              <title> Lines </title>
              <meta
                name="description"
                content="MarketSpark - Command Center"
                data-rh="true"
              />
            </Helmet>
            <div className={styles['lines-wrapper']}>
              {currentUser?.isSuperAdmin ? (
                <div className={styles['button-wrapper']}>
                  <IconButton
                    onClick={() => {
                      handleAddIVRLine();
                    }}
                    size="small"
                    data-testid="addButton"
                    className={styles['add-ivr-line-button']}
                  >
                    <IVRIcon />
                  </IconButton>
                </div>
              ) : (
                <></>
              )}
              <LocationEquipmentLines
                locationId={locationId}
                erpLocationId={locationData?.erpLocationId}
              />
            </div>
          </>
        )
      },
      {
        text: 'DIAGNOSTICS',
        icon: <Search fontSize="small" />,
        url: 'diagnostics',
        visible: true,
        children: (
          <>
            <Helmet>
              <title>Diagnostic</title>
              <meta
                name="description"
                content="MarketSpark - Command Center"
                data-rh="true"
              />
            </Helmet>
            {locationData && (
              <DiagnosticsTab
                installationId={locationData.installationEntity.id}
                locationId={locationId}
                organizationId={organizationId}
              />
            )}
          </>
        )
      },
      {
        text: 'EQUIPMENT',
        icon: <Phone fontSize="small" />,
        url: 'equipment',
        visible: true,
        children: (
          <>
            <Helmet>
              <title> Equipment </title>
              <meta
                name="description"
                content="MarketSpark - Command Center"
                data-rh="true"
              />
            </Helmet>
            <LocationEquipments
              locationId={locationId}
              erpLocationId={locationData?.erpLocationId ?? null}
              locationDomain={locationData?.domain}
            />
          </>
        )
      },
      {
        text: 'EMERGENCY',
        icon: <Phone fontSize="small" />,
        url: 'emergency',
        visible: currentUser?.isSuperAdmin,
        children: (
          <>
            <Helmet>
              <title> Emergency </title>
              <meta
                name="description"
                content="MarketSparks - Command Center"
                data-rh="true"
              />
            </Helmet>
            <LocationEmergencyLines locationId={locationId} />
          </>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.isSuperAdmin, locationData]
  );
  /**
   * Dispatch dialog to update address
   */
  const handleUpdateAddress = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: 'Edit Location',
        lazyComponent: 'LocationUpdate',
        size: 'xlarge',
        lazyComponentProps: {
          id: locationId,
          refetch
        }
      })
    );
  }, [actions, dispatch, locationId, refetch]);

  if (isError) {
    throw new Error(`${error} could not fetch location!`);
  }

  let subtitle = locationData?.address?.address1
    ? `${locationData?.address?.address1}`
    : '';
  subtitle += locationData?.address?.address2
    ? `, ${locationData?.address?.address2}`
    : '';
  subtitle += locationData?.address?.city
    ? ` - ${locationData?.address?.city}`
    : '';
  subtitle += locationData?.address?.state
    ? `, ${locationData?.address?.state}`
    : '';

  const selectedTab = React.useMemo(() => {
    return tabItems
      .filter(item => item?.visible)
      .findIndex(item => item.url === tab.toLowerCase());
  }, [tab, tabItems]);

  const breadCrumbItems = React.useMemo(() => {
    return [
      {
        label:
          locationData?.location?.organizationName || organizationDetails?.name,
        url: `/organization/${
          locationData?.location?.orgId || organizationDetails?.id
        }`
      },
      {
        label: 'Locations',
        url: `/locations`
      },
      { label: locationName, url: '#' }
    ];
  }, [locationData, organizationDetails, locationName]);

  return (
    <>
      <Breadcrumbs items={breadCrumbItems} />
      {/*  */}
      <>
        <PageHeader
          title={locationName}
          subTitle={subtitle}
          status={locationData?.serviceStatus?.name}
          handleUpdateAddress={() => handleUpdateAddress()}
          location={locationData?.location}
          displayEditE911={currentUser?.isSuperAdmin || currentUser?.isOrgAdmin}
        />
      </>
      {currentUserFetched && (
        <TabView
          initialTab={selectedTab < 0 ? 0 : selectedTab}
          items={tabItems.filter(item => item?.visible)}
        />
      )}
    </>
  );
}

export default LocationPage;

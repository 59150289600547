import { Entity } from './Entity';

export interface AuthRoles extends Entity {
  name: RoleName;
}

export enum RoleName {
  superAdmin = 'Super Admin',
  user = 'User',
  salesPartner = 'Sales Partner',
  installPartner = 'Install Partner',
  locationAdmin = 'Location Admin',
  orgAdmin = 'Org Admin'
}

import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'store/selectors';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardContent,
  Text
} from 'design';
import { useCreateExportData } from '../../../../api/useExportData';
import ExportDataFlow from '../../ExportDataFlow';
import styles from './ToolLastCallExport.module.scss';

const dataType = 'last-call';
const daysAgo = 90;

const ToolLastCallExport = () => {
  const user = useSelector(selectCurrentUser);

  const [exportRequested, setExportRequested] = React.useState(false);
  const {
    mutateAsync: createOrgExportData,
    isLoading: loadingExportData
  } = useCreateExportData();
  const csvDownloaderRef = React.useRef<any>();

  const onClickExport = () => {
    createOrgExportData({
      orgOrAccountId: user.organization.id,
      dataType
    });
    setExportRequested(true);
  };

  return (
    <Card>
      <CardHeader title="Last Call" />
      <CardContent noOverflow>
        {exportRequested ? (
          <div className={styles.exportDataWrapper}>
            <ExportDataFlow
              cancelExportRequested={() => setExportRequested(false)}
              typeData={dataType}
              orgOrAccountId={user.organization.id}
            />
          </div>
        ) : (
          <div className={styles.buttonWrapper}>
            <a ref={csvDownloaderRef} style={{ visibility: 'hidden' }} href="/">
              &nbsp;
            </a>
            <Button isLoading={loadingExportData} onClick={onClickExport}>
              DOWNLOAD
            </Button>
          </div>
        )}
      </CardContent>

      <CardFooter>
        <Text variant="body2">
          Last Call records only go back {daysAgo} days
        </Text>
      </CardFooter>
    </Card>
  );
};

export default ToolLastCallExport;

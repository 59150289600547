import auth0 from 'auth0-js';

// Initialize Auth0
export const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH_DOMAIN || '',
  clientID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  scope: 'openid profile email app_metadata',
  responseType: 'token id_token',
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  redirectUri: `${process.env.REACT_APP_DASH_URL}${process.env.REACT_APP_AUTH_CALLBACK_URI}`
});

export default webAuth;

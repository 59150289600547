import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { Button, Text } from 'design';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import UploadIcon from '@material-ui/icons/Publish';
import FileIcon from '@material-ui/icons/Description';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { useUploadOrgLogo } from 'api/useOrgs';

export interface OrgAddLogoProps {
  orgId: string;
}

const OrgAddLogo = ({ orgId }: OrgAddLogoProps): JSX.Element => {
  const {
    mutateAsync: uploadLogo,
    isLoading: updateOrgLogoIsLoading
  } = useUploadOrgLogo();
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [logoData, setLogoData] = React.useState<File | null>(null);
  const [isImageSizeError, setIsImageSizeError] = React.useState(false);
  // Dropzone does all the heavy lifting of handling files client side
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    maxFiles: 1,
    accept: '.jpg,image/jpeg,image/png,image/gif',
    onDrop: files => {
      if (files.length) {
        files.forEach(async file => {
          if (file.size > 518400) {
            setIsImageSizeError(true);
          } else {
            setIsValid(true);
            setLogoData(file);
          }
        });
      }
    }
  });

  // hand that sweet file to the server
  const handleSubmit = async () => {
    try {
      await uploadLogo({
        // eslint-disable-next-line
        orgId: orgId,
        logo: logoData
      });
      dispatch(actions.hideDialog());
      dispatch(
        actions.showNotify({
          message: 'Organization logo added successfully.',
          severity: 'success'
        })
      );
      setLogoData(null);
      setIsValid(false);
    } catch (err) {
      dispatch(
        actions.showNotify({ message: 'Error: could not upload Logo!' })
      );
    }
  };

  const handleReset = () => {
    /* this component is rendered only in the dialog. It needs to handle 
        dialog closing for this reason. */
    dispatch(actions.hideDialog());
    setLogoData(null);
    setIsValid(false);
  };

  const uploadIconClasses = clsx(cc.uploadIcon, {
    [cc.uploadIconAccepted]: isDragAccept,
    [cc.uploadIconRejected]: isDragReject
  });

  return (
    <div>
      <ButtonBase component="div" className={cc.inputWrapper}>
        <span {...getRootProps({ className: cc.dropzone })}>
          <input {...getInputProps()} />
          <Text>
            {!logoData ? (
              <>
                <UploadIcon color="action" className={uploadIconClasses} />
                <span className={cc.dropMessage}>DRAG MEDIA HERE</span>
                <Button
                  isLoading={false}
                  disabled={updateOrgLogoIsLoading}
                  onClick={e => {
                    e.preventDefault();
                  }}
                  type="button"
                  size="small"
                  disableRipple
                >
                  Browse
                </Button>
              </>
            ) : (
              <>
                <FileIcon color="action" className={uploadIconClasses} />
                <span className={cc.dropMessage}>{logoData.name}</span>
              </>
            )}
          </Text>
        </span>
      </ButtonBase>
      <Typography
        className={!isImageSizeError ? cc.imageSize : cc.imageSizeError}
      >
        JPG, GIF, or PNG. Picture size 720 x 720 px. Max size of 800k
      </Typography>
      {fileRejections.map(({ file: any, errors }) =>
        errors.map(e => <span className={cc.validationError}>{e.message}</span>)
      )}

      <div className={cc.formFooter}>
        <Button
          isLoading={updateOrgLogoIsLoading}
          type="button"
          onClick={handleReset}
          variant="outlined"
        >
          cancel
        </Button>
        <Button
          isLoading={updateOrgLogoIsLoading}
          disabled={!isValid}
          onClick={e => {
            e.preventDefault();
            handleSubmit();
          }}
          type="submit"
        >
          upload
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formFooter: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    '& > button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  inputWrapper: {
    marginBottom: theme.spacing(2),
    minWidth: '100%',
    marginTop: '32px'
  },
  dropzone: {
    color: theme.palette.action.active,
    background: theme.palette.info.main,
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 196,
    '& span': {
      display: 'block'
    }
  },
  dropMessage: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold'
    // fontWeight: theme.typography.fontWeightBold
  },
  uploadIcon: {
    fontSize: '4.25rem'
  },
  uploadIconAccepted: {
    color: theme.palette.success.main
  },
  uploadIconRejected: {
    color: theme.palette.error.main
  },
  validationError: {
    color: theme.palette.error.main
  },
  imageSize: {
    fontSize: 12
  },
  imageSizeError: {
    color: 'red',
    fontSize: 12
  }
}));

export default OrgAddLogo;

import * as React from 'react';
import moment from 'moment';

export interface CallDurationProps {
  className?: string;
  /*
   ** Component requires 'children' prop, however 'children' value is allowed to be 'undefined'
   ** This is to prevent misuse of the component without a closing tag, ex: '<CallDuration />'
   */
  children: string | number | undefined;
}

/**
 * Receives value in miliseconds and return in ##h:##m:##s format
 * @param {string} value
 * @return {string} ##h:##m:##s format or '-'
 */
const CallDuration = ({
  children,
  className
}: CallDurationProps): JSX.Element => {
  // value is falsy
  if (!children) {
    return <span className={className}>-</span>;
  }

  const value =
    typeof children === 'number' ? children : parseInt(children, 10);

  const duration = moment()
    .startOf('day')
    .seconds(value)
    .format('HH[h]:mm[m]:ss[s]');

  return <span className={className}>{duration}</span>;
};

export default CallDuration;

import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import CsvUpload from 'app/components/CsvUpload';
import React from 'react';
import { CSVLink } from 'react-csv';

interface Props {
  prospectName: string;
  setProspectName: React.Dispatch<React.SetStateAction<string>>;
  handleProspectCsvSubmit: (file: File) => void;
  file: File | undefined;
  handleRemoveCsv: () => void;
  impactedLocations: any[];
}

export default React.memo(function ProspectReports({
  prospectName,
  setProspectName,
  handleProspectCsvSubmit,
  file,
  handleRemoveCsv,
  impactedLocations
}: Props) {
  const cc = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Prospect name"
          id="prospectName"
          name="prospectName"
          value={prospectName}
          className={cc.propspectNameField}
          onChange={e => setProspectName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <CsvUpload
          setFile={handleProspectCsvSubmit}
          file={file}
          removeFile={handleRemoveCsv}
        />
        {file && impactedLocations.length > 0 && (
          <CSVLink
            data={[['address', 'city', 'state', 'zip'], ...impactedLocations]}
            filename={`${file?.name}_impacted_locations.csv`}
          >
            Download impacted Locations
          </CSVLink>
        )}
        {file && impactedLocations.length === 0 && (
          <Typography className={cc.noImpact}>
            No impacted location(s) found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles(() => ({
  propspectNameField: {
    width: '50%'
  },
  noImpact: {
    marginTop: 5,
    fontSize: 16,
    fontWeight: 500
  }
}));

/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'utils/sentry';
import 'utils/amp';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  MuiThemeProvider as ThemeProvider,
  CssBaseline
} from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import './styles/_index.scss';

// Import root app
import App from 'app';
import configureAppStore from 'store/configureStore';
import theme from 'utils/theme';
import { StylesProvider } from '@material-ui/core/styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false
    }
  }
});
const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                style={{ fontSize: 16 }}
              />
              <App />
              <CssBaseline />
            </StylesProvider>
          </ThemeProvider>
        </React.StrictMode>
      </HelmetProvider>
    </Provider>
  </QueryClientProvider>,
  MOUNT_NODE
);

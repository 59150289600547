import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';
import useCurrentUser from 'api/useCurrentUser';
import { LocationLinesWithE911 } from 'types';
import cc from './manageAllEmergencyLines.module.scss';
import { useAllLinesE911Update } from 'api/useLocationLines';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';

interface ManageAllEmergencyLinesProp {
  locationId: string;
  lineWithPsap: LocationLinesWithE911[];
  refetch: () => void;
}

const ManageAllEmergencyLines = ({
  locationId,
  lineWithPsap,
  refetch
}: ManageAllEmergencyLinesProp): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { mutateAsync: updateAllLinesE911Address } = useAllLinesE911Update();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const menuItems = [
    {
      label: 'Update',
      isVisible: currentUser?.isSuperAdmin,
      action: async () => {
        try {
          await updateAllLinesE911Address({
            locationId,
            linesWithPsapAddress: lineWithPsap
          });
          refetch();
        } catch (err) {
          dispatch(
            actions.showNotify({
              message: 'Error: could not all update lines emergency address!'
            })
          );
        }
      }
    }
  ];

  // Check if all lines have psap address
  const allLinesHavePsapAddress = lineWithPsap.every(
    line => line.psapAddress !== null
  );

  return (
    <>
      {allLinesHavePsapAddress && (
        <div className={cc.buttonWrapper}>
          <Button classes={{ root: cc.button }} onClick={handleMenuClick}>
            Manage
            <ArrowDropDownIcon fontSize="small" className={cc.dropDownIcon} />
          </Button>
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: 'action-menu-paper ', list: 'action-menu-list' }}
        className={cc.menuBar}
      >
        {menuItems.map(({ label, action, isVisible }, i: number) => {
          return (
            <div key={i}>
              {isVisible && (
                <MenuItem
                  button
                  onClick={() => {
                    action();
                    if (handleMenuClose) {
                      handleMenuClose();
                    }
                  }}
                  key={label}
                >
                  {label}
                </MenuItem>
              )}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default ManageAllEmergencyLines;

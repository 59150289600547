import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Badge } from '@material-ui/core';
import { useUserUIContext } from 'app/contexts/useUserUIContext';
export interface UserAvatarProps {
  userProfileImage?: string;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: any;
  firstName?: string;
  lastName?: string;
  isBadgeVisible?: boolean;
  // ref: HTMLElement;
}

/**
 * Avatar wrapper that renders a wrapped Material UI avatar
 * @param props
 */
const UserAvatar = React.forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ onClick, firstName, lastName }: UserAvatarProps, ref): JSX.Element => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();
    const { unreadNotificationCount } = useUserUIContext();

    return (
      <>
        {!firstName ? (
          <>
            <Badge
              variant="dot"
              className={classes.avatarBadge}
              overlap="circular"
              invisible={!unreadNotificationCount}
            >
              <Avatar
                data-testid="avatar-element"
                alt="User Profile Image"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={onClick}
                className={classes.avatarNotification}
                classes={{ root: classes.root }}
                ref={ref}
              >
                -
              </Avatar>
            </Badge>
            <div className={classes.warning}></div>
          </>
        ) : (
          <Badge
            variant="dot"
            overlap="circular"
            className={classes.avatarBadge}
            invisible={!unreadNotificationCount}
          >
            <Avatar
              data-testid="avatar-element"
              alt="User Profile Image"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={onClick}
              className={classes.avatar}
              classes={{ root: classes.root }}
              ref={ref}
            >
              {`${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()}
            </Avatar>
          </Badge>
        )}
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 26,
    height: 26
  },
  withChildren: {
    fontSize: '0.9rem'
  },
  avatar: {
    backgroundColor: theme.palette.common.white,
    fontSize: '0.7rem',
    color: theme.palette.common.black
  },
  avatarNotification: {
    backgroundColor: '#0CCE6B',
    fontSize: '0.7rem',
    position: 'relative'
  },
  warning: {
    width: 10,
    height: 10,
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: '#F71735',
    top: '16px',
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      right: '14px',
      top: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      right: '23px',
      top: '16px'
    }
  },
  avatarBadge: {
    alignSelf: 'center',
    cursor: 'pointer',
    '& span': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export default UserAvatar;

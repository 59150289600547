import React from 'react';

import { MacInputMask } from 'app/components/InputMasks/InputMask';
import { TextInput } from 'design';

import { EquipmentAndHardwareFieldInputProps } from '../types';

const MacAddressFieldInput = ({
  name,
  value,
  validationMessage,
  isError,
  onChange,
  onBlur
}: EquipmentAndHardwareFieldInputProps) => {
  return (
    <TextInput
      id="mac"
      name={name}
      placeholder="##:##:##:##:##:##"
      label="Mac Address"
      inputComponent={MacInputMask}
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default MacAddressFieldInput;

import { PayloadAction } from '@reduxjs/toolkit';
import createSlice from 'utils/@reduxjs/toolkit';
import { setLS } from 'utils/localStorage';
import { DashboardState, DialogState, NotifyState } from './types';

export const initialState: DashboardState = {
  sidebarOpen: true,
  dialog: {
    lazyComponent: ''
  },
  notify: { open: false, message: '', severity: 'error', messages: [] }
};

/**
 * State Slice for Dashboard layout, includes Sidebar persistence as well as
 * Dialog component state management.
 */
const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // imperatively control the sidebar and persist slice to localStorage
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload;
      setLS({
        dashboard: { sidebarOpen: action.payload }
      });
    },
    // imperatively control dialog open state
    hideDialog(state) {
      state.dialog = initialState.dialog;
    },
    // set the dialogs internal state for display of custom components, etc.
    showDialog(state, action: PayloadAction<DialogState>) {
      state.dialog = {
        open: true,
        ...action.payload
      };
    },
    showNotify(state, action: PayloadAction<NotifyState>) {
      const { message, severity, messages } = action.payload;
      state.notify = {
        open: true,
        severity: severity || 'error',
        message,
        messages
      };
    },
    hideNotify(state) {
      state.notify = {
        ...initialState.notify,
        severity: state.notify.severity
      };
    }
  }
});

export const { actions: dashboardActions, reducer: dashboard } = slice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDashboardSlice = () => {
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDashboardSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */

import React from 'react';

import { Dialog, DialogCloseIcon, Text } from 'design';
import { GatewayCatalog, PowersourceCatalog, RouterCatalog } from 'types';
import HardwareLink from './HardwareLink';
import styles from './HardwareTables.module.scss';

interface HardwareErrorProps {
  actionedCatalog: RouterCatalog | GatewayCatalog | PowersourceCatalog;
  handleClose: () => void;
}

const HardwareDependencyError = ({
  actionedCatalog,
  handleClose
}: HardwareErrorProps): JSX.Element => {
  return (
    <Dialog open={true} handleClose={handleClose}>
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">There was a problem</Text>
        <DialogCloseIcon onReset={handleClose} />
      </div>
      <Text variant="body1" className={styles.errorMessageText}>
        You must delete all associated dependencies before continuing.
      </Text>
      <Text variant="body2" className={styles.errorLabel}>
        DEPENDENCIES
      </Text>
      <div className={styles.errorLocations}>
        <Text variant="body1" className={styles.errorBold}>
          {`\u2022  Locations: `}
          <HardwareLink
            model={actionedCatalog.model}
            locationCount={actionedCatalog.locationCount}
          />
        </Text>
      </div>
    </Dialog>
  );
};

export default HardwareDependencyError;

import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import {
  CancelSubmitFooter,
  Dialog,
  DialogCloseIcon,
  Dropdown,
  DropdownOption,
  LabelValuesMap,
  Text
} from '../../../design';
import {
  editHardwareCatalog,
  useHardwareCatalogStatuses
} from '../../../api/useHardwareCatalogs';
import styles from './HardwareTables.module.scss';
import { BundleTemplate, Catalogs, HardwareCatalog } from '../../../types';
import { AxiosError } from 'axios';
import { editBundleCatalogStatus } from '../../../api/useBundleTemplate';

interface HardwareStatusDialogProps {
  identifier: Catalogs | 'bundle';
  actionedCatalog: HardwareCatalog | BundleTemplate;
  refetch: () => void;
  handleClose: () => void;
}

const HardwareStatusDialog = ({
  identifier,
  actionedCatalog,
  refetch,
  handleClose
}: HardwareStatusDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const { data: statusData, isError, isLoading } = useHardwareCatalogStatuses();

  const textBody =
    identifier === 'bundle'
      ? 'Status will determine if this bundle can be provisioned in a location.'
      : 'Status will determine if this hardware can be included in a new bundle.';

  const [selectedStatus, setSelectedStatus] = React.useState<DropdownOption>(
    actionedCatalog.status
      ? {
          label: actionedCatalog.status.name,
          value: actionedCatalog.status.id
        }
      : { label: '', value: '' }
  );

  // Dropdown Options
  const statusOptions: LabelValuesMap[] = React.useMemo(() => {
    return (
      statusData?.map(status => ({
        label: status.name,
        value: status.id
      })) ?? []
    );
  }, [statusData]);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      if (identifier === 'bundle') {
        await editBundleCatalogStatus(actionedCatalog.id, selectedStatus.value);
      } else {
        const {
          id,
          locationCount,
          status,
          createdAt,
          updatedAt,
          ...rest
        } = actionedCatalog as HardwareCatalog;

        await editHardwareCatalog(identifier, id, {
          ...rest,
          statusId: selectedStatus.value
        });
      }

      refetch();
      dispatch(
        actions.showNotify({
          message: 'Status updated successfully',
          severity: 'success'
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: `Failed to edit ${identifier} status: ${error.response?.data.message}`,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: `Failed to edit ${identifier} status`,
            severity: 'error'
          })
        );
      }
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog handleClose={handleClose} open={true} size="large">
      <form onSubmit={onSubmit}>
        <div className={styles.modalHeaderWrapper}>
          <Text variant="h5">Status</Text>
          <DialogCloseIcon onReset={handleClose} />
        </div>
        <div className={styles.modalBodyWrapper}>
          <Text variant="body1">{textBody}</Text>
        </div>
        <Dropdown
          id="status"
          label="Status"
          value={selectedStatus.value}
          dropdownItems={statusOptions}
          placeholder="Select Status"
          onChange={value => {
            setSelectedStatus(value);
          }}
          isError={isError}
          isLoading={isLoading}
        />
        <CancelSubmitFooter
          isLoading={isLoading}
          disabled={actionedCatalog.status?.id === selectedStatus.value}
          cancelButtonOnClick={handleClose}
        />
      </form>
    </Dialog>
  );
};

export default HardwareStatusDialog;

import {
  GatewayCreateEditData,
  HardwareCatalog,
  PowerSourceCreateEditData,
  RouterCreateEditData
} from '../../../types';

const editableFieldsWithLocations = ['brand', 'model', 'power'];

const validateOnlyEditableFields = (
  initial: HardwareCatalog,
  changed:
    | RouterCreateEditData
    | GatewayCreateEditData
    | PowerSourceCreateEditData
): string | undefined => {
  const changedFields = Object.keys(changed).filter(
    key =>
      changed[key as keyof typeof changed] !==
      initial[key as keyof typeof initial]
  );

  const invalidField = changedFields.find(
    field => !editableFieldsWithLocations.includes(field)
  );

  return invalidField;
};

export default validateOnlyEditableFields;

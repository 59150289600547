import { useQuery, UseQueryResult } from 'react-query';
import axios from 'utils/axiosProvider';
import { ApiInfo } from 'types';
import { query } from 'constants/app';

const getApiInfo = async (): Promise<ApiInfo> => {
  const { data } = await axios.get(`/about`);
  return data;
};

const useApiInfo = (): UseQueryResult<ApiInfo, Error> => {
  return useQuery<ApiInfo, Error>([query.apiInfo], getApiInfo);
};

export default useApiInfo;

import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import useCurrentUser from 'api/useCurrentUser';
import { ActionButton } from 'design';
import styles from './tableAction.module.scss';

export interface MenuList<T> {
  label: string;
  isVisible: boolean | undefined;
  action: (arg: T) => void;
}

const TableAction = <T,>({
  row,
  menu = []
}: {
  row: T;
  menu: MenuList<T>[];
}) => {
  const { data: currentUser } = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin || currentUser?.isOrgAdmin) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.iconButton}>
        <ActionButton onClick={openMenu}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        className={styles.menuBar}
        classes={{
          paper: styles.paper,
          list: styles.list
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {menu.map(({ label, action, isVisible }, i: number) => (
          <div key={i}>
            {isVisible && (
              <MenuItem
                button
                onClick={() => {
                  action(row);
                  if (closeMenu) {
                    closeMenu();
                  }
                }}
                key={label}
              >
                {label}
              </MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default TableAction;

import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import * as MatuiLink from '@material-ui/core/Link';

export interface LinkProps extends NavLinkProps {
  to: any;
  children: React.ReactNode;
  className?: string;
  color?:
    | 'primary'
    | 'error'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'inherit'
    | 'initial';
}

const MatLink = MatuiLink.default;

const Link = ({
  to,
  children,
  color = 'primary',
  className,
  ...rest
}: LinkProps): JSX.Element => {
  const memoizedLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<NavLinkProps, 'to'>>((props, ref) => (
        <NavLink
          ref={ref}
          to={{
            pathname: to.pathname ? to.pathname : to,
            state: to.state ? to.state : null
          }}
          exact
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
        />
      )),
    [to]
  );

  return (
    <MatLink
      component={memoizedLink}
      color={color}
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </MatLink>
  );
};

export default Link;

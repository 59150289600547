import React from 'react';

import {
  CancelSubmitFooter,
  Dialog,
  DialogCloseIcon,
  Dropdown,
  Text
} from 'design';
import { CallDirection, SortDirection } from 'types';
import { StatisticType } from './DashboardStatisticCardTemplate';
import styles from './DashboardStatisticCardTemplate.module.scss';

interface TopLocationsFilterModalProps {
  callDirection: CallDirection;
  setCallDirection: React.Dispatch<React.SetStateAction<CallDirection>>;
  statisticType: StatisticType;
  setStatisticType: React.Dispatch<React.SetStateAction<StatisticType>>;
  sortDirection: SortDirection;
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
  handleClose: () => void;
}

const TopLocationsFilterModal = ({
  callDirection,
  setCallDirection,
  statisticType,
  setStatisticType,
  sortDirection,
  setSortDirection,
  handleClose
}: TopLocationsFilterModalProps): JSX.Element => {
  const [tempDirection, setTempDirection] = React.useState<CallDirection>(
    callDirection
  );
  const [tempStatisticType, setTempStatisticType] = React.useState<
    StatisticType
  >(statisticType);
  const [tempSortDirection, setTempSortDirection] = React.useState(
    sortDirection
  );

  const dropdownItemsDirection = Object.values(CallDirection).map(
    direction => ({
      value: direction,
      label: direction.charAt(0).toUpperCase() + direction.slice(1)
    })
  );

  const dropdownItemsStatistic = Object.values(StatisticType).map(type => ({
    value: type,
    label: type.charAt(0).toUpperCase() + type.slice(1)
  }));

  const dropdownItemsSort = [
    { value: 'DESC', label: 'Most Busy' },
    { value: 'ASC', label: 'Least Busy' }
  ];

  const handleSubmit = () => {
    setCallDirection(tempDirection);
    setStatisticType(tempStatisticType);
    setSortDirection(tempSortDirection);
    handleClose();
  };

  return (
    <Dialog open={true} handleClose={() => handleClose()} size="large">
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">Filter / Sort</Text>
        <DialogCloseIcon onReset={() => handleClose()} />
      </div>
      <form onSubmit={() => handleSubmit()}>
        <Dropdown
          id="callDirection"
          label="Call Direction"
          value={tempDirection}
          onChange={e => setTempDirection(e.value as CallDirection)}
          dropdownItems={dropdownItemsDirection}
          isError={false}
          className={styles.dropdown}
        />
        <Dropdown
          id="statisticType"
          label="Numbers for"
          value={tempStatisticType}
          onChange={e => setTempStatisticType(e.value as StatisticType)}
          dropdownItems={dropdownItemsStatistic}
          isError={false}
          className={styles.dropdown}
        />
        <Dropdown
          id="sort"
          label="Sort By"
          value={tempSortDirection}
          onChange={e => setTempSortDirection(e.value as SortDirection)}
          dropdownItems={dropdownItemsSort}
          isError={false}
          className={styles.dropdown}
        />
        <CancelSubmitFooter
          isLoading={false}
          cancelButtonOnClick={() => handleClose()}
        />
      </form>
    </Dialog>
  );
};

export default TopLocationsFilterModal;

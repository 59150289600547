import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';

import { Card, DropdownOption, labelValuesFor, Text } from 'design';
import FormDropdown from 'design/Dropdown/FormDropdown';
import { useFormContext } from 'design/Form/Form';

import { useManagedBy } from 'api/useBundleTemplate';
import { useGetInstallStatus } from 'api/useEquipment';
import { useCatalog } from 'api/useCatalog';

import {
  HardwareCategoryNames,
  HARDWARE_CATEGORY_NAMES,
  HARDWARE_CATEGORY_NAMES_PLURAL
} from '../constants';
import { capitalize } from 'lodash';

interface AddHardwareFormProps {
  setIsCreateBundle: () => void;
  setHardwareCategory: React.Dispatch<React.SetStateAction<string>>;
}

const AddHardwareForm = ({
  setIsCreateBundle,
  setHardwareCategory
}: AddHardwareFormProps): JSX.Element => {
  const formContext = useFormContext();
  const { register, handleChange, formData, resetForm } = formContext;

  const { data: ownersData } = useManagedBy();
  const { data: installStatusesData } = useGetInstallStatus(1, 20);
  const { data: catalogs } = useCatalog();

  const [catalogOptions, setCatalogOptions] = React.useState<DropdownOption[]>(
    []
  );
  const [owner, setOwner] = React.useState<DropdownOption[]>([]);
  const [installStatuses, setInstallStatuses] = React.useState<
    DropdownOption[]
  >([]);

  const categoryOptions = Object.entries(HARDWARE_CATEGORY_NAMES_PLURAL).map(
    ([key, name]): DropdownOption => {
      return {
        label: key,
        value: name
      };
    }
  );
  const [categories] = React.useState<DropdownOption[]>(categoryOptions);

  const categoryDropdown = register('category');
  const catalogDropdown = register('catalogItemId');
  const ownerDropdown = register('ownerId');
  const installStatusDropdown = register('installStatusId');

  /**
   * useEffect hook to populate the catalogDropdown.items with the devices from catalogs[].
   */
  React.useEffect(() => {
    if (categories.length > 0 && categoryDropdown.value && catalogs) {
      // i.e. if selected category is Routers then list all routers from catalogs[].
      // If the list in catalogs gets too large >100, this will need to be refactored/memoized.
      const hardwareCategoryValue =
        HARDWARE_CATEGORY_NAMES[
          capitalize(categoryDropdown.value) as HardwareCategoryNames
        ];

      const options = catalogs.reduce((labels: DropdownOption[], catalog) => {
        if (catalog.group === hardwareCategoryValue) {
          labels.push({
            label: catalog.name,
            value: catalog.id
          });
        }
        return labels;
      }, []);

      setCatalogOptions(options);
    }
  }, [categoryDropdown.value, catalogs, categories.length]);

  /**
   * useEffect hook to populate the ownerDropdown.items with the owners.
   */
  React.useEffect(() => {
    if (ownersData) {
      // only MarketSpark can be the owner
      const msOwner = ownersData.filter(o => {
        return o.name === 'MarketSpark';
      })[0];
      setOwner(labelValuesFor([msOwner]));
      ownerDropdown.setValue(msOwner.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownersData]);

  /**
   * useEffect hook to populate the installStatusDropdown.items with the install statuses.
   */
  React.useEffect(() => {
    if (installStatusesData) {
      setInstallStatuses(labelValuesFor(installStatusesData));
      if (!installStatusDropdown.value) {
        setTimeout(() => {
          const shippedStatus = installStatusesData?.find(
            item => item.name === 'Shipped' || item.name === 'Shipping'
          )?.id;
          installStatusDropdown.setValue(shippedStatus);
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installStatusesData]);

  /**
   * useEffect hook to populate the catalogBrand with the brand of the selected catalog.
   */
  React.useEffect(() => {
    if (catalogDropdown.value && catalogs?.length && formData) {
      const found = catalogs.find(catalog => {
        return catalog.id === catalogDropdown.value;
      });

      if (found) {
        handleChange('catalogItem', found);
        handleChange('catalogBrand', found.brand);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogDropdown.value, catalogs, formData.category]);

  // To track hardware category dropdown
  // And render correct form based on category
  React.useEffect(() => {
    setHardwareCategory(String(categoryDropdown.value));
  }, [categoryDropdown.value, setHardwareCategory]);

  return (
    <div className="form-container">
      <Grid container className="grid-container">
        <div className="includes-container">
          <Grid item xs={12} md={6}>
            <div className="input-wrapper">
              <FormDropdown
                id="category"
                placeholder="Select Category"
                label="Category"
                dropdownItems={categories}
                formState={categoryDropdown}
                onChange={e => {
                  const { value } = e;
                  categoryDropdown.onChange(e);
                  if (value === HARDWARE_CATEGORY_NAMES_PLURAL.Bundle) {
                    setIsCreateBundle();
                  }
                  resetForm({
                    imei: '',
                    ip: '',
                    domain: '',
                    serialNumber: '',
                    mac: '',
                    catalogItemId: ''
                  });
                }}
              />
            </div>
          </Grid>
          {categoryDropdown.value && (
            <Grid item xs={12} md={6}>
              <div className="right-info">
                <Card className="card-container">
                  <Text className="card-header">Hardware Bundle Includes:</Text>
                  <List>
                    <ListItem className="list-item">
                      <Text variant="body2" className="list-item-text">
                        {capitalize(categoryDropdown.value)}
                      </Text>
                    </ListItem>
                  </List>
                </Card>
              </div>
            </Grid>
          )}
        </div>
        <Grid item xs={12} md={6} className="picker-container">
          <div className="input-wrapper">
            <FormDropdown
              id="catalog"
              label="Sku List"
              placeholder="Select SKU"
              dropdownItems={catalogOptions}
              formState={catalogDropdown}
            />
          </div>
          <div className="input-wrapper">
            <FormDropdown
              id="owner"
              label="Managed By"
              formState={ownerDropdown}
              dropdownItems={owner}
              disabled={true}
            />
          </div>
          <div className="input-wrapper">
            <FormDropdown
              id="installStatus"
              label="Install Status"
              placeholder="Select Install Status"
              dropdownItems={installStatuses}
              formState={installStatusDropdown}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddHardwareForm;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Grid } from '@material-ui/core';

import { useDashboardSlice } from 'app/layouts';
import { selectCurrentUser } from 'store/selectors';
import {
  CancelSubmitFooter,
  Dialog,
  DialogCloseIcon,
  DropdownOption,
  Dropdown,
  MultiSelect,
  Text,
  LabelValuesMap,
  PhoneLink,
  Textarea,
  TextInput
} from 'design';
import ContactUsFormSchema from './ContactUsFormSchema';
import styles from '../HelpCenter.module.scss';
import { useLocationsByOrg } from 'api/useLocations';
import useEquipment from 'api/useEquipment';
import useLocationLines from 'api/useLocationLines';
import { contactUsPriorityOptions, contactUsTopicOptions } from 'constants/app';
import { useBundles } from 'api/useBundle';
import { useCreateSupportTicket } from 'api/useSupportTicket';
import { AxiosError } from 'axios';
import PhoneInputMask from '../../PhoneInputMask';

interface ContactUsFormProps {
  handleClose: () => void;
}

interface FormikValues {
  priority: string;
  subject: string;
  location: string;
  siteContact: string;
  equipments: string[];
  lines: string[];
  description: string;
  telephoneNumber: string;
}

interface FormField {
  type: 'single' | 'multi' | 'text' | 'textArea' | 'tel';
  value: string | string[];
  identifier: keyof FormikValues;
  label: string;
  options: DropdownOption[];
  placeholder: string;
}

const ContactUsForm = ({ handleClose }: ContactUsFormProps) => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const user = useSelector(selectCurrentUser);
  const {
    mutateAsync: createSupportTicket,
    isLoading
  } = useCreateSupportTicket();

  const [selectedLocationId, setSelectedLocationId] = React.useState('');

  // Hooks to get dropdown data
  const { data: locationData } = useLocationsByOrg(user.organization.id);
  const { data: equipmentData } = useEquipment({ filter: selectedLocationId });
  const { data: bundleData } = useBundles(selectedLocationId);
  const { data: lineData } = useLocationLines(selectedLocationId);

  // Dropdown Options
  const locationOptions: LabelValuesMap[] = React.useMemo(() => {
    return (
      locationData?.map(location => ({
        label: location.name,
        value: location.name
      })) ?? []
    );
  }, [locationData]);
  const equipmentOptions: LabelValuesMap[] = React.useMemo(() => {
    if (!equipmentData?.equipment && !bundleData?.bundles) {
      return [];
    }

    const equipment =
      equipmentData?.equipment.map(e => ({
        label: e.catalogItem.name,
        value: e.catalogItem.name
      })) ?? [];

    const bundles: DropdownOption[] = [];
    bundleData?.bundles.forEach(b => {
      b.routers.forEach(e => {
        bundles.push({
          label: e.routerCatalog.model,
          value: e.routerCatalog.model
        });
      });
      b.gateways.forEach(e => {
        bundles.push({
          label: e.gatewayCatalog.model,
          value: e.gatewayCatalog.model
        });
      });
      b.powersources.forEach(e => {
        bundles.push({
          label: e.powersourceCatalog.model,
          value: e.powersourceCatalog.model
        });
      });
    });

    return [...equipment, ...bundles];
  }, [equipmentData, bundleData]);
  const lineOptions: LabelValuesMap[] = React.useMemo(() => {
    return (
      lineData?.lines.map(line => ({
        label: line.value.toString(),
        value: line.value.toString()
      })) ?? []
    );
  }, [lineData]);

  // Formik
  const initialValues: FormikValues = {
    priority: '',
    subject: '',
    location: '',
    siteContact: '',
    equipments: [],
    lines: [],
    description: '',
    telephoneNumber: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ContactUsFormSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    try {
      const payload = {
        ...formik.values,
        organization: user.organization.name,
        requester: user.name,
        email: user.username
      };

      const ticketId = await createSupportTicket(payload);
      dispatch(
        actions.showNotify({
          message: `Support ticket created successfully - Ticket #${ticketId}`,
          severity: 'success'
        })
      );
      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: error.response?.data.message,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'An error occurred: could not create Support Ticket',
            severity: 'error'
          })
        );
      }
    }
  };

  // Dropdown Items
  const dropdownItemsLeft: FormField[] = [
    {
      type: 'single',
      value: formik.values.priority,
      identifier: 'priority',
      label: 'PRIORITY*',
      options: contactUsPriorityOptions,
      placeholder: 'Select One'
    },
    {
      type: 'single',
      value: formik.values.subject,
      identifier: 'subject',
      label: 'TOPIC*',
      options: contactUsTopicOptions,
      placeholder: 'Select One'
    },
    {
      type: 'single',
      value: formik.values.location,
      identifier: 'location',
      label: 'LOCATION (IF APPLICABLE)',
      options: locationOptions,
      placeholder: 'Select One'
    },
    {
      type: 'multi',
      value: formik.values.equipments,
      identifier: 'equipments',
      label: 'EQUIPMENT (IF APPLICABLE)',
      options: equipmentOptions,
      placeholder: 'Select One'
    },
    {
      type: 'multi',
      value: formik.values.lines,
      identifier: 'lines',
      label: 'LINES (IF APPLICABLE)',
      options: lineOptions,
      placeholder: 'Select One'
    }
  ];
  const dropdownItemsRight: FormField[] = [
    {
      type: 'text',
      value: formik.values.siteContact,
      identifier: 'siteContact',
      label: 'ADDITIONAL CONTACT',
      options: [],
      placeholder: 'Contact Email'
    },
    {
      type: 'tel',
      value: formik.values.telephoneNumber,
      identifier: 'telephoneNumber',
      label: 'TELEPHONE NUMBER',
      options: [],
      placeholder: '1 (999) 999-9999'
    },
    {
      type: 'textArea',
      value: formik.values.description,
      identifier: 'description',
      label: 'DESCRIPTION*',
      options: [],
      placeholder: 'Enter a brief description here.'
    }
  ];

  // On Change Functions
  const dropdownOnChange = (
    id: keyof FormikValues,
    value: string | string[]
  ) => {
    formik.setFieldValue(id, value);
    formik.setFieldError(id, '');
    if (id === 'location') {
      setSelectedLocationId(
        locationData?.find(l => l.name === value)?.id ?? ''
      );
    }
  };
  const textOnChange = (id: keyof FormikValues, value: string) => {
    formik.setFieldValue(id, value);
    formik.setFieldError(id, '');
  };

  // Auto select location if opened in a location page
  React.useEffect(() => {
    const pathName = window.location.pathname;
    const split = pathName.split('/');
    if (split.includes('location') && split.includes('section')) {
      setSelectedLocationId(split[split.length - 3]);
      const location = locationData?.find(
        l => l.id === split[split.length - 3]
      );
      if (location) {
        formik.setFieldValue('location', location.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  const dropdownMap = (input: FormField) => {
    return (
      <Grid item xs={12} key={input.identifier} className={styles.rowSpacing}>
        {input.type === 'single' ? (
          <Dropdown
            id={input.identifier}
            label={input.label}
            name={input.identifier}
            placeholder={input.placeholder}
            dropdownItems={input.options}
            value={input.value}
            onChange={(option: DropdownOption) => {
              dropdownOnChange(input.identifier, option.value);
            }}
            isError={
              !formik.isValidating && Boolean(formik.errors[input.identifier])
            }
            validationMessage={formik.errors[input.identifier] as string}
          />
        ) : input.type === 'multi' ? (
          <MultiSelect
            id={input.identifier}
            label={input.label}
            name={input.identifier}
            placeholder={input.placeholder}
            dropdownItems={input.options}
            value={input.value}
            onChange={(option: string[]) =>
              dropdownOnChange(input.identifier, option)
            }
            isError={
              !formik.isValidating && Boolean(formik.errors[input.identifier])
            }
            isLoading={false}
          />
        ) : input.type === 'text' ? (
          <TextInput
            id={input.identifier}
            label={input.label}
            name={input.identifier}
            placeholder={input.placeholder}
            value={input.value as string}
            onChange={e => textOnChange(input.identifier, e.target.value)}
            onBlur={formik.handleBlur}
            isError={
              !formik.isValidating && Boolean(formik.errors[input.identifier])
            }
            validationMessage={formik.errors[input.identifier] as string}
          />
        ) : input.type === 'tel' ? (
          <TextInput
            id={input.identifier}
            label={input.label}
            name={input.identifier}
            placeholder={input.placeholder}
            value={input.value as string}
            onChange={e => textOnChange(input.identifier, e.target.value)}
            onBlur={formik.handleBlur}
            isError={
              !formik.isValidating && Boolean(formik.errors[input.identifier])
            }
            inputComponent={PhoneInputMask}
            validationMessage={formik.errors[input.identifier] as string}
          />
        ) : (
          <Textarea
            id={input.identifier}
            label={input.label}
            name={input.identifier}
            placeholder={input.placeholder}
            value={input.value as string}
            onChange={e => textOnChange(input.identifier, e.target.value)}
            onBlur={formik.handleBlur}
            isError={
              !formik.isValidating && Boolean(formik.errors[input.identifier])
            }
            validationMessage={formik.errors[input.identifier] as string}
            rows={6}
            inputProps={{ width: '100%' }}
            formControlProps={{
              style: { width: '100%' }
            }}
            disabled={false}
          />
        )}
      </Grid>
    );
  };

  return (
    <Dialog open={true} size="xlarge" handleClose={handleClose}>
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">Contact Us</Text>
        <DialogCloseIcon onReset={handleClose} />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <Grid container className={styles.primaryContainer}>
          <Grid item xs={12} md={6} className={styles.leftContainer}>
            {dropdownItemsLeft.map(input => dropdownMap(input))}
          </Grid>
          <Grid item xs={12} md={6} className={styles.rightContainer}>
            {dropdownItemsRight.map(input => dropdownMap(input))}
          </Grid>
        </Grid>
        <CancelSubmitFooter
          isLoading={isLoading}
          cancelButtonOnClick={handleClose}
        />
      </form>
      <PhoneLink />
    </Dialog>
  );
};

export default ContactUsForm;

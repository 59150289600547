import { Grid, Button } from '@material-ui/core';
import React from 'react';

interface Props {
  disableGenerateReport?: boolean;
  openDialog: () => void;
}

export default function GenerateReportButton({
  disableGenerateReport,
  openDialog
}: Props) {
  return (
    <Grid item xs={12}>
      <Button
        onClick={openDialog}
        color="primary"
        variant="contained"
        disabled={disableGenerateReport}
      >
        Generate Report
      </Button>
    </Grid>
  );
}

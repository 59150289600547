import React from 'react';
import { CloseSharp as CloseIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { Dialog, Icon, Text } from 'design';
import { SipCredential } from './ToolSipCredentials';
import { ReactComponent as CopyIcon } from '../../../../assets/img/copyIcon.svg';
import styles from './ToolSipCredentials.module.scss';

export interface ToolSipCredentialsModalProps {
  open: boolean;
  domain: string;
  credentials: SipCredential[];
  handleClose: () => void;
}

const ToolSipCredentialsModal = ({
  open,
  domain,
  credentials,
  handleClose
}: ToolSipCredentialsModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const onIndividualCredentialClick = (sipSecret: string) => {
    if (!navigator.clipboard) {
      dispatch(
        actions.showNotify({
          message: 'Could not copy to clipboard. Check permissions',
          severity: 'error'
        })
      );
      return;
    }
    navigator.clipboard.writeText(sipSecret);
    dispatch(
      actions.showNotify({
        message: 'Sip secret copied to clipboard',
        severity: 'success'
      })
    );
  };

  const onCopyAllClick = () => {
    const allSipSecrets = credentials.map(credential => credential.sipSecret);
    if (!navigator.clipboard) {
      dispatch(
        actions.showNotify({
          message: 'Could not copy to clipboard. Check permissions',
          severity: 'error'
        })
      );
      return;
    }
    navigator.clipboard.writeText(allSipSecrets.join('\n '));
    dispatch(
      actions.showNotify({
        message: 'All sip secrets copied to clipboard',
        severity: 'success'
      })
    );
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Text variant="h5">N2P SIP Credentials</Text>
          <div onClick={handleClose}>
            <Icon Mark={CloseIcon} className={styles.closeIcon} />
          </div>
        </div>
        <Text variant="h6" className={styles.subTitle}>
          {domain}
        </Text>
        {credentials.map((credential, index) => (
          <div key={index} className={styles.line}>
            <Text variant="body2" className={styles.purpleText}>
              {credential.sipUser}
            </Text>
            <div className={styles.sipCredential}>
              <Text variant="body2">{credential.sipSecret}</Text>
              <CopyIcon
                className={styles.cursorPointer}
                onClick={() =>
                  onIndividualCredentialClick(credential.sipSecret)
                }
              />
            </div>
          </div>
        ))}
        <div className={styles.footer}>
          <Text
            variant="body2"
            className={styles.copyAll}
            onClick={onCopyAllClick}
          >
            Copy All
          </Text>
        </div>
      </div>
    </Dialog>
  );
};

export default ToolSipCredentialsModal;

import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Card, CardHeader, CardContent, CardFooter } from 'design';
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';
import axiosInstance from 'utils/axiosProvider';
import { makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import secondsToHms from 'utils/secondsToDhms';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { ROUTER_METRIC_BRANDS } from 'constants/app';
import { SignalStatusCardProps } from './types';
import {
  AssessmentScore,
  InstallationMetrics
} from '../../../types/AssessmentScore';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { sortObjectByKeys } from 'utils/helpers';
import ScorePill from '../ScorePill/ScorePill';

const SignalStatusCard = ({
  id,
  serialNumber,
  netCloudId,
  catalogBrand,
  model,
  staticIp,
  installationId
}: SignalStatusCardProps): JSX.Element => {
  const cc = useStyles();
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const [isLoading, setIsLoading] = React.useState(false);
  const [rsrp, setRsrp] = React.useState('');
  const [rsrq, setRsrq] = React.useState('');
  const [rssi, setRssi] = React.useState('');
  const [rf, setRf] = React.useState('');
  const [responseData, setResponseData] = React.useState({});
  const [assessmentScore, setAssessmentScore] = React.useState<
    AssessmentScore | undefined
  >(undefined);
  const [routerUptime, setRouterUptime] = React.useState('');
  const [routerUpdatedAt, setRouterUpdatedAt] = React.useState('');
  const [metricSupport, setMetricSupport] = React.useState(false);
  const [activeSim, setActiveSim] = React.useState('');

  // fetch cradlepoint signal data from api function
  // NOTE: using useCallback and making api call here rather than from an api hook due to
  // React Hook "useGetSignalMetrics" cannot be called inside a callback error. Could be refactored - danny
  const getMetrics = React.useCallback(async () => {
    setIsLoading(true);
    const { data } = await axiosInstance.get<InstallationMetrics>(
      `/installations/${installationId}/signalMetrics/full`
    );
    const router = data.routers.find(r => r.id === id);
    if (!router) {
      throw new Error('Failed to find signal metrics');
    }
    setAssessmentScore(router.assessment?.aggregate);
    if (catalogBrand === ROUTER_METRIC_BRANDS.CRADLEPOINT) {
      try {
        setResponseData(_.omit(router, 'assessment', 'routerCatalog'));
        setRsrp(router.rsrp ? router.rsrp.toString() : 'N/A');
        setRsrq(router.rsrq ? router.rsrq.toString() : 'N/A');
        setRssi(router.rssi ? router.rssi.toString() : 'N/A');
        setRf(router.rfBand || 'N/A');
        setActiveSim(router.activeSim || 'N/A');
        setRouterUpdatedAt(
          router.updatedAt
            ? moment.utc(router.updatedAt).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A'
        );
        const routerUptimeParsed = secondsToHms(parseInt(router.uptime || '0'));
        setRouterUptime(routerUptimeParsed);
      } catch (err: any) {
        dispatch(
          actions.showNotify({
            message: `Failed to fetch router metrics: ${err.message}`
          })
        );
      }
    }

    if (catalogBrand === ROUTER_METRIC_BRANDS.INHAND) {
      try {
        setResponseData(_.omit(router, 'assessment', 'routerCatalog'));
        setRsrp(router.rsrp ?? 'N/A');
        setRsrq(router.rsrq ?? 'N/A');
        setRf(router.rfBand ?? 'N/A');
        setRssi(router.rssi ?? 'N/A');
        setActiveSim(router.activeSim || 'N/A');
        // router uptime not available in inhand's payload
        setRouterUptime('N/A');
        setRouterUpdatedAt('N/A');
      } catch (err: any) {
        setResponseData({});
        setRsrp('N/A');
        setRsrq('N/A');
        setRf('N/A');
        setRssi('N/A');
        setRouterUptime('N/A');
        setRouterUpdatedAt('N/A');
        dispatch(
          actions.showNotify({
            message: `Failed to fetch router metrics for ${serialNumber}`
          })
        );
      }
    }
    setIsLoading(false);
  }, [actions, dispatch, catalogBrand, id, serialNumber, installationId]);

  const handleRefetch = async () => {
    await getMetrics();
  };

  const handleSignalStatusDialog = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'large',
        title: 'Signal Status Response',
        lazyComponent: 'SignalStatusExpand',
        closeable: true,
        lazyComponentProps: {
          data: sortObjectByKeys(responseData)
        }
      })
    );
  }, [actions, dispatch, responseData]);
  // get metrics on initial card load
  React.useEffect(() => {
    // only fetch metrics if router has netcloud id
    if (
      (catalogBrand === ROUTER_METRIC_BRANDS.CRADLEPOINT && netCloudId) ||
      (catalogBrand === ROUTER_METRIC_BRANDS.INHAND && staticIp)
    ) {
      const getMetricsAsync = async () => {
        await getMetrics();
      };
      getMetricsAsync();
      setMetricSupport(true);
    }
  }, [getMetrics, netCloudId, staticIp, catalogBrand]);

  return (
    <Card>
      {/* display refresh icon for router with netcloudId */}
      {metricSupport ? (
        <CardHeader
          title={`Signal`}
          action={
            <div className={cc.actionWrapper}>
              {!_.isEmpty(responseData) ? (
                <>
                  <ScorePill
                    score={assessmentScore || AssessmentScore.NotAvailable}
                  />
                  <OpenInNew
                    className={cc.icon}
                    color="action"
                    onClick={handleSignalStatusDialog}
                  />
                </>
              ) : (
                <></>
              )}
              {isLoading ? (
                <CircularProgress className={cc.icon} />
              ) : (
                <CachedIcon
                  className={cc.icon}
                  color="action"
                  onClick={handleRefetch}
                />
              )}
            </div>
          }
        />
      ) : (
        <CardHeader title={`Signal`} />
      )}

      <CardContent>
        {!metricSupport ? (
          <p className={`${cc.metricSupport} ${cc.content}`}>
            Metrics for this router is not supported
          </p>
        ) : (
          <></>
        )}

        <p className={cc.content}>
          {catalogBrand === ROUTER_METRIC_BRANDS.INHAND || catalogBrand} {model}
        </p>
        <p className={cc.content}>{serialNumber}</p>

        <p className={cc.content}>
          Active SIM: <span>{activeSim}</span>
        </p>
        {catalogBrand !== ROUTER_METRIC_BRANDS.INHAND && (
          <p className={cc.content}>
            Uptime: <span>{routerUptime}</span>
          </p>
        )}
        {catalogBrand !== ROUTER_METRIC_BRANDS.INHAND && (
          <p className={cc.content}>
            Last updated at : <span>{routerUpdatedAt}</span>
          </p>
        )}

        <Grid
          container
          justifyContent="center"
          spacing={4}
          style={{ textAlign: 'center' }}
        >
          <Grid item>
            <h1>{rsrq}</h1>
            <p>RSRQ</p>
          </Grid>
          <Grid item>
            <h1>{rsrp}</h1>
            <p>RSRP</p>
          </Grid>
          <Grid item>
            <h1>{rssi}</h1>
            <p>RSSI</p>
          </Grid>
          <Grid item>
            <h1>{rf}</h1>
            <p>RF-Band</p>
          </Grid>
        </Grid>
      </CardContent>

      <CardFooter>
        <a
          href="https://support.marketspark.com/hc/en-us/articles/27203439729300-Signal-Strength-Next-Steps"
          className={cc.link}
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </a>
      </CardFooter>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    cursor: 'pointer',
    fontSize: '25px !important',
    height: '25px !important',
    width: '25px !important',
    marginRight: theme.spacing(1),
    transform: 'none !important'
  },
  actionWrapper: {
    height: '25px'
  },
  content: {
    marginTop: '-5px',
    '& .metricSupport': {
      fontWeight: 'bold',
      fontStyle: 'italic'
    }
  },
  link: {
    fontSize: '14px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  routerInfo: {
    padding: theme.spacing(0)
  },
  metricSupport: {
    fontWeight: 'bold',
    fontStyle: 'italic'
  }
}));

export default SignalStatusCard;

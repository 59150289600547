import * as Yup from 'yup';
import _ from 'lodash';

import {
  ATA,
  BATTERY,
  CDS_9010,
  CDS_9090,
  DataRemote,
  EQUIPMENT_SERIAL_NUMBER_REGEX,
  IMEI_ALL_ZERO_REGEX,
  IMEI_REGEX,
  INHAND_ROUTER,
  INTEGRATED_ROUTER,
  IPADDRESS_REGEX,
  MIX_DOMAIN_REGEX,
  NAPCO,
  PBX_DOMAIN_REGEX,
  ROUTER,
  SANGOMA
} from 'constants/app';
import { CatalogItem } from 'types';
import { useCheckUniqueness } from 'api/useEquipment';
import { isMacPrefixValid, isSerialPrefixValid } from 'api/useBundle';
import { ValidateFunc } from 'design/Form/Form';
import { domain, catchCase } from 'utils/commonValidations';

const checkUniqueness = useCheckUniqueness;

const equipmentSerialNumberValidator = Yup.string().test(
  async (value, context) => {
    try {
      if (!value) {
        throw new Error('Please provide a serial number');
      }
      await Yup.string()
        .matches(EQUIPMENT_SERIAL_NUMBER_REGEX, {
          excludeEmptyString: true,
          message: 'Serial Number can only include alphanumeric characters.'
        })
        .validate(value);
      await new Promise(async (resolve, reject) => {
        const equipmentId = context.parent.id ?? null;
        const isSerialNumberUnique = await checkUniqueness(
          'serialNumber',
          value,
          equipmentId
        );
        if (!isSerialNumberUnique) {
          reject(Error('Serial number already exists'));
        }
        if (context?.parent?.catalogItem?.name) {
          const validateSerial = await isSerialPrefixValid(
            context?.parent?.catalogItem?.name,
            value.trim()
          );
          if (!validateSerial.isValid) {
            reject(Error(validateSerial.message));
          }
        }
        resolve(true);
      });
    } catch (error: any) {
      return context.createError({
        message: error.message,
        type: error.type
      });
    }
    return true;
  }
);

/**
 *
 * @param path - path to field value for brand
 */
const equipmentDomainValidator = (
  path: string,
  accountId: string,
  catalogItem?: CatalogItem
) =>
  Yup.string()
    .when(path, {
      is: (value: CatalogItem) =>
        [ATA, INTEGRATED_ROUTER].includes(
          value?.group ?? catalogItem?.group ?? ''
        ),
      then: Yup.string().required('Please enter a domain')
    })
    .when(path, {
      is: () => catalogItem?.brand === DataRemote,
      then: Yup.string().matches(MIX_DOMAIN_REGEX, {
        excludeEmptyString: true,
        message:
          'Your domain input must be in the format "domain.bin.pbx.supersip.net"'
      }),
      otherwise: Yup.string().when(path, {
        is: () => ![BATTERY, ROUTER].includes(catalogItem?.group ?? ''),
        then: Yup.string().matches(
          PBX_DOMAIN_REGEX,
          'Your domain input must be in the format "domain.bin.pbx.supersip.net" or "domain.ms"'
        )
      })
    })
    .test(async (value, context) => {
      try {
        return await domain(
          value,
          context,
          context.parent.catalogItem?.brand,
          accountId
        );
      } catch (error) {
        return catchCase(context, error);
      }
    });

/**
 * @param path - path to field value for brand
 */
const equipmentUsernameValidator = (path: string) =>
  Yup.string().when(path, {
    is: (value: CatalogItem) => value.brand === INHAND_ROUTER,
    then: Yup.string().required('Please provide a username.')
  });

/**
 *
 * @param path - path to field value for brand
 */
const equipmentPasswordValidator = (path: string) =>
  Yup.string().when(path, {
    is: (value: CatalogItem) => value.brand === INHAND_ROUTER,
    then: Yup.string().required('Please provide a password.')
  });

/**
 *
 * @param path - path to field value for brand
 */
const equipmentStaticIpValidator = (path: string) =>
  Yup.string().when(path, {
    is: (value: CatalogItem) =>
      value.brand === INHAND_ROUTER || value.brand === SANGOMA,
    then: Yup.string().test(async (value, context) => {
      try {
        if (!value) {
          throw new Error('Please enter an IP address');
        }
        await Yup.string()
          .matches(IPADDRESS_REGEX, {
            excludeEmptyString: true,
            message: 'You must provide a valid IP address.'
          })
          .validate(value);
        await new Promise(async (resolve, reject) => {
          const equipmentId = context.parent.id ?? null;
          const isIpAddressUnique = await checkUniqueness(
            'staticIp',
            value,
            equipmentId
          );
          if (!isIpAddressUnique) {
            reject(Error('IP address already exists'));
          }
          resolve(true);
        });
      } catch (error: any) {
        return context.createError({
          message: error.message,
          type: error.type
        });
      }
      return true;
    })
  });

const equipmentMacValidator = (path: string) =>
  Yup.string().when(path, {
    is: (value: CatalogItem) => value.group !== BATTERY,
    then: Yup.string()
      .when(path, {
        is: (value: CatalogItem) => value.group !== NAPCO,
        then: Yup.string().required('Please enter a MAC address')
      })
      .test(async (value: any, context) => {
        try {
          if (value) {
            await new Promise(async (resolve, reject) => {
              const { parent } = context;

              const validateMac = await isMacPrefixValid(
                parent.catalogItem?.brand,
                value?.trim()
              );
              if (!validateMac.isValid) {
                reject(Error(`${validateMac.message}`));
              } else {
                const equipmentId = context.parent.id ?? null;

                const isMacAddressUnique = await checkUniqueness(
                  'mac',
                  value,
                  equipmentId
                );
                if (!isMacAddressUnique) {
                  reject(Error('MAC address already exists'));
                }
              }
              resolve(true);
            });
          }
        } catch (error: any) {
          return context.createError({
            message: error.message,
            type: error.type
          });
        }
        return true;
      })
  });

const equipmentImeiValidator = Yup.string().when('catalogItem', {
  is: (value: CatalogItem) =>
    value.group === ROUTER ||
    value.group === NAPCO ||
    value.name === CDS_9010 ||
    value.name === CDS_9090,
  then: Yup.string()
    .when('catalogItem', {
      is: (value: CatalogItem) => value.group !== NAPCO,
      then: Yup.string().required('Please provide an IMEI number.')
    })
    .test(async (value, context) => {
      try {
        if (value) {
          await Yup.string()
            .matches(IMEI_REGEX, {
              excludeEmptyString: true,
              message: 'IMEI number must be 15 digits.'
            })
            .validate(value);
          await Yup.string()
            .matches(IMEI_ALL_ZERO_REGEX, {
              excludeEmptyString: true,
              message: 'Invalid IMEI number.'
            })
            .validate(value);
          await new Promise(async (resolve, reject) => {
            const equipmentId = context.parent.id ?? null;

            const isImeiUnique = await checkUniqueness(
              'imei',
              value,
              equipmentId
            );
            if (!isImeiUnique) {
              reject(Error('IMEI Number already exists'));
            }
            resolve(true);
          });
        }
      } catch (error: any) {
        return context.createError({
          message: error.message,
          type: error.type
        });
      }
      return true;
    })
});

const validateRouterEquipmentFields: ValidateFunc = async (
  err,
  path,
  trigger,
  formData
) => {
  const isInhand =
    _.get(formData, path)?.routerCatalog?.brand === INHAND_ROUTER;
  const paths = ['serialNumber', 'mac', 'imei'];
  if (isInhand) {
    paths.push('ip', 'userName', 'password');
  }
  const isValid = await trigger(paths);
  return isValid;
};

const validateGatewayEquipmentFields: ValidateFunc = async (
  err,
  path,
  trigger,
  formData
) => {
  const isSangoma = _.get(formData, path)?.gatewayCatalog?.brand === SANGOMA;
  const paths = ['serialNumber', 'mac', 'domain'];
  if (isSangoma) {
    paths.push('ip');
  }
  const isValid = await trigger(paths);
  return isValid;
};

const validatePowersourceEquipmentFields: ValidateFunc = async (
  err,
  path,
  trigger
) => {
  const paths = ['serialNumber', 'routerId'];
  const isValid = await trigger(paths);
  return isValid;
};

const validateCommunicatorEquipmentFields: ValidateFunc = async (
  err,
  path,
  trigger
) => {
  const paths = ['serialNumber', 'imei'];
  const isValid = await trigger(paths);
  return isValid;
};

export {
  equipmentSerialNumberValidator,
  equipmentDomainValidator,
  equipmentUsernameValidator,
  equipmentPasswordValidator,
  equipmentStaticIpValidator,
  equipmentMacValidator,
  equipmentImeiValidator,
  validateRouterEquipmentFields,
  validateGatewayEquipmentFields,
  validatePowersourceEquipmentFields,
  validateCommunicatorEquipmentFields
};

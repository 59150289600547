import * as React from 'react';
import { IVR, pageSizeOptions } from 'constants/app';
import { ColumnProps, Link, TablePaginated, Text } from 'design';
import useLocationLines from 'api/useLocationLines';
import useSort from 'hooks/useSort';
import IVRLineAction from './IVRLineAction';
import PhoneNumber from '../PhoneNumber';
import useCurrentUser from 'api/useCurrentUser';
import { Line } from 'types';

export interface LocationIVRLinesProps {
  locationId: string;
  setIsIVRLinesEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationIVRLines = ({
  locationId,
  setIsIVRLinesEmpty
}: LocationIVRLinesProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const [sort, setSort] = useSort(['port', 'ASC']);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const { isLoading, data, isError, error, refetch } = useLocationLines(
    locationId,
    `lineType.name|eq|${IVR}`,
    sort,
    pageIndex,
    pageSize
  );
  const { lines, total } = data || { lines: [], total: 0 };

  React.useEffect(() => {
    if (setIsIVRLinesEmpty) {
      setIsIVRLinesEmpty(data && data.total === 0 ? true : false);
    }
  }, [setIsIVRLinesEmpty, data, isLoading]);

  if (isError && error) {
    throw new Error(`${error.message} Failed to fetch Locations!`);
  }

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'value',
        label: 'Phone Number',
        sortable: true,
        render: ({ value }: Line) => {
          const to = `/location/${locationId}/cdr/${value}`;
          return (
            <Link to={to}>
              <PhoneNumber>{value}</PhoneNumber>
            </Link>
          );
        },
        resizable: true
      },
      {
        accessor: 'description',
        label: 'description',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'networkProvider.name',
        label: 'Provider',
        sortable: true,
        resizable: true
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: '',
        label: '',
        render: ({
          id,
          description,
          value,
          account,
          networkProvider
        }: Line) => {
          return (
            <div>
              <IVRLineAction
                id={id}
                location={account}
                phoneNumber={value}
                description={description}
                provider={networkProvider}
                refetch={refetch}
              />
            </div>
          );
        },
        cellWidth: '32px'
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  return (
    <>
      {lines.length ? (
        <>
          <Text variant="subtitle2">IVR Lines</Text>
          <TablePaginated
            id="IVRLines"
            rows={lines}
            sort={sort}
            total={total}
            columns={columns}
            handlePageSize={setPageSize}
            handlePageNav={setPageIndex}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            handleSort={setSort}
            isLoading={isLoading}
            hidePagination={lines.length > 10}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default LocationIVRLines;

import { useState, useMemo } from 'react';

type SortDirection = 'ASC' | 'DESC';

type SortConfig<T> = {
  key: keyof T;
  direction: SortDirection;
};

function useTableSort<T>(
  data: T[],
  initialConfig: SortConfig<T>
): [T[], (key: keyof T) => void] {
  const [sortConfig, setSortConfig] = useState(initialConfig);

  const sortedData = useMemo(() => {
    const sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ASC' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ASC' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const handleSort = (key: keyof T) => {
    let direction: SortDirection = 'ASC';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ASC'
    ) {
      direction = 'DESC';
    }
    setSortConfig({ key, direction });
  };

  return [sortedData, handleSort];
}

export default useTableSort;

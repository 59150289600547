import React from 'react';
import { Grid } from '@material-ui/core';

import { Dialog, DialogCloseIcon, PhoneLink, Text } from 'design';
import ContactUsCard from './ContactUsCard';
import DefaultHelpCenterCard, {
  DefaultHelpCenterCardProps
} from './DefaultHelpCenterCard';
import { helpCenterRoutes } from 'constants/app';
import { ReactComponent as GettingStartedIcon } from 'assets/img/GettingStartedIcon.svg';
import { ReactComponent as InstallationSupportIcon } from 'assets/img/InstallationSupport.svg';
import { ReactComponent as HowToIcon } from 'assets/img/HowToIcon.svg';
import styles from './HelpCenter.module.scss';

// Constants / Prop Setting
const pageTitle = 'Help Center';
const cardProps: DefaultHelpCenterCardProps[] = [
  {
    title: 'GETTING STARTED',
    subTitle: 'MarketSpark 411',
    linkRoute: helpCenterRoutes.gettingStarted,
    icon: <GettingStartedIcon />,
    className: styles.marginRightMarginBottom
  },
  {
    title: 'INSTALLATION SUPPORT',
    subTitle: 'Installing M1 & M2 Enclosures',
    linkRoute: helpCenterRoutes.installationSupport,
    icon: <InstallationSupportIcon />,
    className: styles.marginBottom
  },
  {
    title: 'HOW TO',
    subTitle: 'Command Center',
    linkRoute: helpCenterRoutes.howTo,
    icon: <HowToIcon />,
    className: styles.marginRight
  }
];

export interface HelpCenterDialogProps {
  handleClose: () => void;
}

const HelpCenterDialog = ({
  handleClose
}: HelpCenterDialogProps): JSX.Element => {
  return (
    <Dialog open={true} size="xlarge" handleClose={handleClose}>
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">{pageTitle}</Text>
        <DialogCloseIcon onReset={handleClose} />
      </div>

      <Grid container justifyContent="center" alignItems="center">
        {cardProps.map((card, index) => (
          <div key={index} className={card.className}>
            <DefaultHelpCenterCard
              title={card.title}
              subTitle={card.subTitle}
              linkRoute={card.linkRoute}
              icon={card.icon}
            />
          </div>
        ))}

        <ContactUsCard dialog={true} closeDialog={handleClose} />
      </Grid>
      <div className={styles.marginTop}>
        <PhoneLink />
      </div>
    </Dialog>
  );
};

export default HelpCenterDialog;

import * as React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';

export interface ActionButtonProps {
  children: React.ReactNode;
  onClick:
    | (() => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  'data-testid'?: string;
}

/**
 * This button is built to handle a material-ui/icon component and display it
 * in a rather small style.
 * @param {ActionButtonProps}
 * @returns {JSX.Element}
 */
const ActionButton = ({
  children,
  onClick,
  ...props
}: ActionButtonProps): JSX.Element => {
  const cc = useStyles();
  return (
    <IconButton
      size="small"
      onClick={onClick}
      data-testid={props['data-testid']}
      classes={{
        root: cc.actionButton
      }}
    >
      {children}
    </IconButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    '& > span > svg': {
      width: '17px !important',
      height: '17px !important'
    }
  }
}));

export default ActionButton;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { RefObject } from 'react';
import Map, { MapRef } from 'react-map-gl';
import { makeStyles } from '@material-ui/core';
import { GeoJsonDataCollection } from '@marketspark/ms-utils-and-interfaces';
import {
  MAP_LATITUDE,
  MAP_LONGITUDE,
  MAP_ZOOM
} from 'app/pages/DecommissionPage/constants';

import { REACT_APP_MAPBOX_KEY } from 'constants/app';
import DisclosureSource from '../Sources/DisclosureSource';
import BusinessSource from '../Sources/BusinessSource';
import MapPopup from '../MapPopup';
import { HoveredFeatureType } from '../../types';
import MapLegends from '../MapLegends';
import { ReactComponent as MarketSparkLogo } from '../../../../../../assets/img/MS_Logo_Dark.svg';

/**
 * Important note:
 * Test is not done for this component as it will require
 * a lot of setup in order to render the map itself.
 * I am not sure if this will bring any real world values as we won't be able to
 * actually test any of the additional renders
 */
interface Props {
  disclosureGeoSource: GeoJsonDataCollection | null;
  businessGeoSource: GeoJsonDataCollection | null;
  zipcodeBoundariesGeoSource?: GeoJsonDataCollection | null;
  prospectLocationsGeoSource: GeoJsonDataCollection | null;
  selectedTab?: number;
  mapRef: RefObject<MapRef>;
}

const DecommissionMap = React.forwardRef(
  (
    {
      disclosureGeoSource,
      businessGeoSource,
      prospectLocationsGeoSource,
      mapRef
    }: Props,
    ref: React.Ref<MapRef>
  ) => {
    const cc = useStyles();
    const [hoveredFeature, setHoveredFeature] = React.useState<
      HoveredFeatureType
    >(null);

    const onHover = React.useCallback(event => {
      const {
        features,
        point: { x, y }
      } = event;

      const featureInfo = features && features[0];
      setHoveredFeature(featureInfo && { feature: featureInfo, x, y });
    }, []);

    const onClick = React.useCallback(
      event => {
        const {
          features,
          lngLat: { lat, lng }
        } = event;
        const featureInfo = features && features[0];

        if (featureInfo) {
          mapRef.current?.easeTo({
            center: [lng, lat],
            zoom: 9,
            duration: 1500
          });
        }
      },
      [mapRef]
    );

    const createInteractiveLayers = React.useCallback(() => {
      const interactiveLayers = [];

      if (disclosureGeoSource && Object.keys(disclosureGeoSource).length > 0) {
        interactiveLayers.push('disclosure-cluster');
      }

      if (businessGeoSource && Object.keys(businessGeoSource).length > 0) {
        interactiveLayers.push(
          'impacted-store-locations',
          'unimpacted-store-locations'
        );
      }

      if (
        prospectLocationsGeoSource &&
        Object.keys(prospectLocationsGeoSource).length > 0
      ) {
        interactiveLayers.push(
          'impacted-prospect-locations',
          'unimpacted-prospect-locations'
        );
      }

      return interactiveLayers;
    }, [businessGeoSource, disclosureGeoSource, prospectLocationsGeoSource]);

    return (
      <div id="decomMap">
        <MapLegends />
        <div className={cc.mapContainer}>
          <Map
            ref={ref}
            id="disclosureDecommisionMap"
            initialViewState={{
              latitude: MAP_LATITUDE,
              longitude: MAP_LONGITUDE,
              zoom: MAP_ZOOM
            }}
            preserveDrawingBuffer={true}
            mapboxAccessToken={REACT_APP_MAPBOX_KEY}
            mapStyle="mapbox://styles/mapbox/light-v11"
            style={{ width: '100%', height: '500px' }}
            interactiveLayerIds={createInteractiveLayers()}
            onMouseMove={onHover}
            onClick={onClick}
            maxZoom={12}
            minZoom={2}
          >
            {disclosureGeoSource &&
              disclosureGeoSource?.features?.length > 0 && (
                <DisclosureSource disclosureGeoSource={disclosureGeoSource} />
              )}

            {businessGeoSource && businessGeoSource?.features?.length > 0 && (
              <BusinessSource businessGeoSource={businessGeoSource} />
            )}

            {prospectLocationsGeoSource &&
              prospectLocationsGeoSource?.features?.length > 0 && (
                <BusinessSource
                  businessGeoSource={prospectLocationsGeoSource}
                />
              )}

            {disclosureGeoSource &&
              disclosureGeoSource?.features?.length > 0 &&
              hoveredFeature && <MapPopup hoveredFeature={hoveredFeature} />}
          </Map>
          <div className={cc.waterMark}>
            <MarketSparkLogo className={cc.waterMarkImg} />
          </div>
        </div>
      </div>
    );
  }
);

const useStyles = makeStyles(() => ({
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  waterMark: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '7%',
    left: '6%',
    transform: 'translate(-50%, -50%)',
    fontSize: '10px',
    zIndex: 1,
    opacity: 0.6
  },
  waterMarkImg: {
    width: 75
  }
}));

export default React.memo(DecommissionMap);

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface BadgeBarProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * This provides a nice wrapper for the badges in the wild
 * @param {BadgeBarProps} props
 * @returns
 */
const BadgeBar = ({ children, className }: BadgeBarProps): JSX.Element => {
  const cc = useStyles();
  const classes = clsx(cc.badgeBar, className);
  return <div className={classes}>{children}</div>;
};

const useStyles = makeStyles((theme: Theme) => ({
  badgeBar: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > button ': {
      marginBottom: theme.spacing(2)
    },
    '& > button:not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    margin: theme.spacing(3, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      justifyContent: 'left'
    }
  }
}));

export default BadgeBar;

import React from 'react';

import { useGatewayCatalogs } from 'api/useHardwareCatalogs';
import {
  GatewayCreateDialog,
  GatewayEditDialog,
  HardwareCatalogDefaultPage
} from 'app/components/HardwareTables';
import { ColumnProps } from '../../../design';

const RoutersPage = (): JSX.Element => {
  const gatewayColumns: ColumnProps[] = [
    {
      accessor: 'brand',
      label: 'Brand',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'model',
      label: 'Model',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'power',
      label: 'Power',
      sortable: true,
      resizable: true
    },
    {
      accessor: 'fxoPorts',
      label: 'FXO Ports',
      sortable: true,
      resizable: true
    }
  ];

  return (
    <HardwareCatalogDefaultPage
      title="Gateways"
      deviceColumns={gatewayColumns}
      getCatalogsHook={useGatewayCatalogs}
      CreateDialog={GatewayCreateDialog}
      EditDialog={GatewayEditDialog}
    />
  );
};

export default RoutersPage;

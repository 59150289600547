import { CircularProgress, Grid } from '@material-ui/core';
import { useCreateProviderSub, useLocNetProvider } from 'api/useLocations';
import { Card, CardContent, CardFooter, CardHeader } from 'design';
import * as React from 'react';
import ProviderStatus from './ProviderStatus';
import { SubSync } from 'types/ApiSync';
import SetupStatus from './SetupStatus';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import styles from './providercard.module.scss';
const SyncModal = React.lazy(() => import('../SyncModal'));

const LocationProviderCard = ({
  locationId
}: {
  locationId: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const [showModal, setShowModal] = React.useState(false);
  const [syncItemData, setSyncItemData] = React.useState<SubSync>(
    {} as SubSync
  );
  const { data, isLoading, isError } = useLocNetProvider(locationId);

  const {
    mutateAsync: subSync,
    isLoading: syncLoading
  } = useCreateProviderSub();

  // Get status subscription from payload if exists
  const statusSub = React.useMemo(() => {
    if (!data) {
      return {} as SubSync;
    }
    const sub = data?.n2pSubs?.find(s => s.type === 'status');

    if (!sub) {
      return {} as SubSync;
    }

    return sub;
  }, [data]);

  // Get CDR subscription from payload if exists
  const cdrSub = React.useMemo(() => {
    if (!data) {
      return {} as SubSync;
    }

    const sub = data?.n2pSubs?.find(s => s.type === 'cdr');
    if (!sub) {
      return {} as SubSync;
    }

    return sub;
  }, [data]);

  // Shorthand function to set state and open modal to display
  const setSyncSub = (syncItem: SubSync | null) => {
    if (!syncItem) {
      return;
    }
    setSyncItemData(syncItem);
    setShowModal(true);
  };

  // Toast message and call subscription setup
  const setupSub = (type: string) => {
    dispatch(
      actions.showNotify({
        message: 'Setting up subscription',
        severity: 'success'
      })
    );

    subSync({
      locId: locationId,
      type
    });
  };

  if (isError) {
    return (
      <Card>
        <CardHeader title="Provider" />
        <CardContent>Something went wrong. Please contact support</CardContent>
        <CardFooter>&nbsp;</CardFooter>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <CardHeader title="Provider" />
        {isLoading ? (
          <CardContent>
            <CircularProgress thickness={5} />
          </CardContent>
        ) : (
          <CardContent>
            <Grid container className={styles.syncStatuses}>
              <Grid item xs={4} className={styles.providerStatus}>
                <ProviderStatus
                  syncData={
                    {
                      ...data,
                      type: 'service',
                      id: locationId
                    } as SubSync
                  }
                  onClick={() => {
                    setSyncSub({
                      ...data,
                      type: 'service',
                      id: locationId
                    } as SubSync);
                  }}
                />
              </Grid>
              <Grid item xs={4} className={styles.providerStatus}>
                {statusSub?.id ? (
                  <ProviderStatus
                    syncData={statusSub}
                    onClick={() => {
                      setSyncSub(statusSub);
                    }}
                  />
                ) : (
                  <SetupStatus
                    isLoading={syncLoading}
                    type="status"
                    onClick={() => {
                      setupSub('status');
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={styles.providerStatus}>
                {cdrSub?.id ? (
                  <ProviderStatus
                    syncData={cdrSub}
                    onClick={() => {
                      setSyncSub(cdrSub);
                    }}
                  />
                ) : (
                  <SetupStatus
                    isLoading={syncLoading}
                    type="cdr"
                    onClick={() => {
                      setupSub('cdr');
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        )}
        <CardFooter>&nbsp;</CardFooter>
      </Card>
      {showModal && (
        <React.Suspense fallback={<>Loading...</>}>
          <SyncModal
            syncData={syncItemData}
            open={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
          />
        </React.Suspense>
      )}
    </>
  );
};

export default LocationProviderCard;

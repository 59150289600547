import { TestContext, ValidationError } from 'yup';

import { equipmentDomainExists } from 'api/useEquipment';
import {
  doesItExist,
  gatewayDomainExists,
  isMacPrefixValid,
  isSerialPrefixValid
} from 'api/useBundle';
import { isHardwareCatalogUnique } from 'api/useHardwareCatalogs';
import { doesGrandStreamMaxExist } from 'api/useGateways';
import {
  DataRemote,
  GRANDSTREAM_GATEWAY,
  EQUIPMENT_SERIAL_NUMBER_REGEX,
  IMEI_ALL_ZERO_REGEX,
  IMEI_REGEX,
  IPADDRESS_REGEX,
  MAC_ADDRESS_REGEX,
  MIX_DOMAIN_REGEX,
  PBX_DOMAIN_REGEX,
  validationErrorMessages,
  ROUTER,
  BATTERY,
  ACCEPTABLE_IP_PREFIXES
} from 'constants/app';
import { Catalogs, EquipmentType } from 'types';

const validateDomainUniqueness = async (value: string, locationId: string) => {
  const hasMatchingDomainBundle = await gatewayDomainExists(
    `domain|eq|${value}&andfilter=accountId|!eq|${locationId}`
  );

  const hasMatchingDomainEquipment = await equipmentDomainExists(
    `domain|eq|${value}&andfilter=account.id|!eq|${locationId}`
  );

  if (hasMatchingDomainBundle || hasMatchingDomainEquipment) {
    throw new Error(validationErrorMessages.domainExists);
  }
};

const serialNumber = async (
  value: string | undefined,
  context: TestContext,
  equipmentType: EquipmentType,
  id?: string,
  modelName?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.serialNumberRequired);
    }
    if (!EQUIPMENT_SERIAL_NUMBER_REGEX.test(value)) {
      throw new Error(validationErrorMessages.serialNumberInvalid);
    }

    // Validate Serial Prefix
    if (modelName) {
      const validateSerial = await isSerialPrefixValid(modelName, value.trim());
      if (!validateSerial.isValid) {
        throw new Error(validateSerial.message);
      }
    }

    // Validate Unique Serial Number
    const validateUniqueness = await doesItExist(
      equipmentType,
      'serialNumber',
      value,
      id
    );
    if (validateUniqueness) {
      throw new Error(validationErrorMessages.serialNumberExists);
    }

    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const inHandIpAddress = async (
  value: string | undefined,
  context: TestContext,
  id?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.ipAddressRequired);
    }
    if (!IPADDRESS_REGEX.test(value)) {
      throw new Error(validationErrorMessages.ipAddressInvalid);
    }

    // Validate starts with either 10.16 or 10.32
    if (!ACCEPTABLE_IP_PREFIXES.some(start => value.startsWith(start))) {
      throw new Error(validationErrorMessages.ipAddressStartsWith);
    }

    // Validate Unique IP Address
    const validateUniqueness = await doesItExist('routers', 'ip', value, id);

    if (validateUniqueness) {
      throw new Error(validationErrorMessages.inHandIpAddressExists);
    }
    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const ipAddress = async (
  value: string | undefined,
  context: TestContext,
  equipmentType: EquipmentType,
  id?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.ipAddressRequired);
    }
    if (!IPADDRESS_REGEX.test(value)) {
      throw new Error(validationErrorMessages.ipAddressInvalid);
    }

    // Validate Unique IP Address
    const validateUniqueness = await doesItExist(
      equipmentType,
      'ip',
      value,
      id
    );

    if (validateUniqueness) {
      throw new Error(validationErrorMessages.ipAddressExists);
    }
    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const mac = async (
  value: string | undefined,
  context: TestContext,
  equipmentType: EquipmentType,
  id?: string,
  brand?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.macAddressRequired);
    }
    if (!MAC_ADDRESS_REGEX.test(value)) {
      throw new Error(validationErrorMessages.macAddressInvalid);
    }

    // Validate MAC Prefix
    if (brand) {
      const validateMac = await isMacPrefixValid(brand, value?.trim());
      if (!validateMac.isValid) {
        throw new Error(validateMac.message);
      }
    }

    // Validate Unique MAC Address in Our DB
    const validateUniqueness = await doesItExist(
      equipmentType,
      'mac',
      value,
      id
    );
    if (validateUniqueness) {
      throw new Error(validationErrorMessages.macAddressExists);
    }

    // Validate Unique MAC Address in GDMS
    if (!id && brand === GRANDSTREAM_GATEWAY) {
      const validateUniquenessGDMS = await doesGrandStreamMaxExist(
        value.trim()
      );
      if (validateUniquenessGDMS) {
        throw new Error(validationErrorMessages.macAddressExistsGDMS);
      }
    }

    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const imei = async (
  value: string | undefined,
  context: TestContext,
  equipmentType: EquipmentType,
  id?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.imeiRequired);
    }
    if (!IMEI_REGEX.test(value)) {
      throw new Error(validationErrorMessages.imeiInvalid);
    }
    if (!IMEI_ALL_ZERO_REGEX.test(value)) {
      throw new Error(validationErrorMessages.imeiAllZero);
    }

    // Validate Unique IMEI Number
    const validateUniqueness = await doesItExist(
      equipmentType,
      'imei',
      value,
      id
    );
    if (validateUniqueness) {
      throw new Error(validationErrorMessages.imeiExists);
    }

    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const domain = async (
  value: string | undefined,
  context: TestContext,
  brand: string,
  locationId: string,
  id?: string
): Promise<boolean | ValidationError> => {
  if ([BATTERY, ROUTER].includes(context.parent.catalogItem?.group)) {
    return true;
  }

  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.domainRequired);
    }
    // Skip unique domain validation for DataRemote gateways
    if (brand === DataRemote) {
      if (!MIX_DOMAIN_REGEX.test(value)) {
        throw new Error(validationErrorMessages.mixDomainInvalid);
      }
      return true;
    }

    if (!PBX_DOMAIN_REGEX.test(value)) {
      throw new Error(validationErrorMessages.domainInvalid);
    }

    // Validate Unique Domain
    await validateDomainUniqueness(value, locationId);
    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const hardwareCatalogUniqueModel = async (
  value: string | undefined,
  catalog: Catalogs,
  context: TestContext,
  id?: string
): Promise<boolean | ValidationError> => {
  try {
    // Validate Input
    if (!value) {
      throw new Error(validationErrorMessages.catalogModelRequired);
    }

    // Validate Unique Router Model
    const validateUniqueness = await isHardwareCatalogUnique(
      catalog,
      value,
      id
    );
    if (!validateUniqueness) {
      throw new Error(validationErrorMessages.catalogModelExists);
    }
    return true;
  } catch (error) {
    return handleValidationErrors(context, error);
  }
};

const catchCase = (context: TestContext, error: unknown): ValidationError => {
  console.error(error);
  return context.createError({
    message: validationErrorMessages.somethingBroke
  });
};

const handleValidationErrors = (
  context: TestContext,
  error: unknown
): boolean | ValidationError => {
  if (error instanceof Error) {
    return context.createError({
      message: error.message
    });
  }
  return false;
};

export {
  serialNumber,
  inHandIpAddress,
  ipAddress,
  mac,
  imei,
  domain,
  hardwareCatalogUniqueModel,
  catchCase
};

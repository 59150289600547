import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './notificationsubslocationlist.module.scss';
import { Text } from 'design';
import React from 'react';

export interface NotificationSubsLocationCardProps {
  locationNames: string[];
  handleClose: () => void;
}

const NotificationSubsLocationCard = ({
  locationNames,
  handleClose
}: NotificationSubsLocationCardProps): JSX.Element => {
  return (
    <div>
      <div className={styles.wholeWrapper}>
        <div className={styles.headerWrapper}>
          <Text className={styles.headerText}>Notifications</Text>
          <IconButton
            data-testid="closeIcon"
            onClick={handleClose}
            className={styles.closeButton}
          >
            <CloseIcon style={{ color: '#A9B3B7' }} fontSize="small" />
          </IconButton>
        </div>
        <div className={styles.gridContainer}>
          <Grid className={styles.gridWrap}>
            <p className={styles.titleText}>Locations</p>
            {locationNames.map((name, i) => {
              return (
                <div key={i}>
                  {<Text className={styles.valueText}>{name}</Text>}
                </div>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NotificationSubsLocationCard;

import { Grid, Typography } from '@material-ui/core';
import { IDTNumber } from 'api/useNumberAcquisition';
import { Dropdown, DropdownOption, TextInput } from 'design';
import React, { Fragment } from 'react';

import styles from './numberpurchasetool.module.scss';

interface NumberDesignationFormProps {
  desiredNumbers: IDTNumber[];
  handleDesignation: (designations: DidAndDesignation[]) => void;
}

export interface DidAndDesignation {
  did: string;
  designation: string;
}

const NumberDesignationForm = ({
  desiredNumbers,
  handleDesignation
}: NumberDesignationFormProps) => {
  const [numberDesignation, setNumberDesignation] = React.useState<
    DidAndDesignation[]
  >(() => {
    return desiredNumbers.map(number => ({
      did: number.did,
      designation: 'primary'
    }));
  });

  const designationOptions = [
    { label: 'Primary', value: 'primary' },
    { label: 'Temporary', value: 'temporary' }
  ];

  const formatNumber = (number: string) => {
    return number
      .replace('+', '')
      .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
  };

  const changeDesignation = (did: string, designation: string) => {
    setNumberDesignation(prev => {
      return prev.map(number => {
        if (number.did === did) {
          return { ...number, designation };
        }
        return number;
      });
    });
  };

  // We use this to keep the parent component aware of the designations when they change
  React.useEffect(() => {
    handleDesignation(numberDesignation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberDesignation]);

  return (
    <>
      <Typography variant="body1">Confirm your selection.</Typography>
      <Grid container spacing={2} className={styles.numberToolInner}>
        {desiredNumbers.map((number, i) => (
          <Fragment key={number.sku}>
            <Grid item xs={12} md={6}>
              <div className="input-wrapper">
                <TextInput
                  id="phonenumber"
                  label="Phone Number"
                  name="phonenumber"
                  value={formatNumber(number.did)}
                  disabled
                  onChange={() => {}}
                  isError={false}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="dropdown-wrapper">
                <Dropdown
                  id="designation"
                  label="Designation"
                  name="designation"
                  value={numberDesignation[i].designation}
                  dropdownItems={designationOptions}
                  onChange={(designation: DropdownOption) => {
                    changeDesignation(number.did, designation.value);
                  }}
                  isError={false}
                />
              </div>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </>
  );
};

export default NumberDesignationForm;

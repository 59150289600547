import React from 'react';
import {
  FormGroup,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MapRef } from 'react-map-gl';
import { toast } from 'react-toastify';

import 'mapbox-gl/dist/mapbox-gl.css';

import { useDashboardSlice } from 'app/layouts';
import {
  useDisclosures,
  usePostAddressCsvFile,
  useZoomInfoBusinessData,
  ZoomInfoQueryKey
} from 'api/useDecommissionMap';
import { Organization } from 'types';

import DecommissionMapBox from './components/DecommissionMapBox';
import BusinessLocations from './components/BusinessLocationsTable';
import ImpactedStoresCount from './components/ImpactedStoresCount';
import BusinessNamesDropdown from './components/AutoCompleteDropdowns/BusinessNamesDropdown';

import { ProspectAddress } from './utils/addressesHelper';
import ActiveTabs from './components/ActiveTabs/ActiveTabs';
import ProspectReports from './components/ProspectReports';
import GenerateReports from './components/GenerateReports';
import GenerateReportButton from './components/GenerateReportButton';
import {
  calculateImpactedLocations,
  createFeatureCollection,
  GeoJsonDataCollection,
  GeoJsonDataZipcodeMap,
  ZoomBusinessInfo
} from '@marketspark/ms-utils-and-interfaces';
import { convertProspectLocationsToClusterMap } from './utils/geoSourceConverters';
import OrganizationDropdown from './components/AutoCompleteDropdowns/OrganizationsDropdown';

const DecommissionMap = () => {
  const cc = useStyles();
  const mapRef = React.useRef<MapRef>(null);

  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  /**
   * Refactor to use either redux or useReducer
   * Most likely to use useReducer instead.
   */

  const [businessName, setBusinessName] = React.useState('');
  const [organizationId, setOrganizationId] = React.useState('');
  const [prospectName, setProspectName] = React.useState('');
  const [businessNameOrOrgId, setBusinessNameOrOrgId] = React.useState<
    ZoomInfoQueryKey
  >(ZoomInfoQueryKey.BusinessName);
  // const [, setProspectAddressesInput] = React.useState('');
  const [prospectAddresses, setProspectAddresses] = React.useState<
    ProspectAddress[]
  >([]);
  const [tabValue, setTabValue] = React.useState(1);
  const [file, setFile] = React.useState<File>();
  const [impactedLocations, setImpactedLocations] = React.useState<any[]>([]);

  // Data state
  const [prospectLocations, setProspectLocations] = React.useState<
    ProspectAddress[]
  >([]);
  const [prospectLocationsMap, setProspectLocationsMap] = React.useState<
    GeoJsonDataZipcodeMap
  >({});

  const { data: disclosureZipcodeMap } = useDisclosures();
  const { data: zoomBusinessData } = useZoomInfoBusinessData(
    businessName || organizationId,
    Object.keys(disclosureZipcodeMap || {}),
    businessNameOrOrgId
  );
  const [zoomBusinessGeoZipcodeMap, zoomBusinessLocations] =
    zoomBusinessData || [];

  const {
    mutateAsync: uploadAddressCsv,
    data: csvAddressData,
    reset: resetAddressCsv
  } = usePostAddressCsvFile();

  // state for map sources and layers
  const [
    businessGeoSource,
    setBusinessGeoSource
  ] = React.useState<GeoJsonDataCollection | null>(null);
  const [
    disclosureGeoSource,
    setDisclosureGeoSource
  ] = React.useState<GeoJsonDataCollection | null>(null);
  const [
    prospectLocationsGeoSource,
    setProspectLocationsGeoSource
  ] = React.useState<GeoJsonDataCollection | null>(null);

  React.useEffect(() => {
    if (disclosureZipcodeMap) {
      const geoSource = createFeatureCollection(disclosureZipcodeMap);
      setDisclosureGeoSource(geoSource);
    }
  }, [disclosureZipcodeMap]);

  const setBusinessLocationsData = React.useCallback(() => {
    if (zoomBusinessGeoZipcodeMap && tabValue !== 2) {
      const geoSource = createFeatureCollection(zoomBusinessGeoZipcodeMap);
      return setBusinessGeoSource(geoSource);
    }
  }, [zoomBusinessGeoZipcodeMap, tabValue]);

  const setProspectLocationsData = React.useCallback(() => {
    if (
      disclosureZipcodeMap &&
      prospectAddresses.length > 0 &&
      tabValue === 1
    ) {
      const prospectLocationsMapData = convertProspectLocationsToClusterMap(
        disclosureZipcodeMap,
        prospectAddresses
      );
      setProspectLocations(prospectAddresses);
      setProspectLocationsMap(prospectLocationsMapData);

      const geoSource = createFeatureCollection(prospectLocationsMapData);

      const impactedAddresses = prospectAddresses
        .filter(
          address =>
            prospectLocationsMapData?.[address.zip]?.properties
              ?.impactedStores > 0
        )
        .map(address => [
          address.street,
          address.city,
          address.state,
          address.zip
        ]);

      if (impactedAddresses.length > 0) {
        setImpactedLocations(impactedAddresses);
      }

      setProspectLocationsGeoSource(geoSource);
    } else {
      setProspectLocations([]);
      setProspectLocationsMap({});
      setProspectLocationsGeoSource(null);
      setImpactedLocations([]);
    }
  }, [prospectAddresses, disclosureZipcodeMap, tabValue]);

  React.useEffect(() => {
    setBusinessLocationsData();
  }, [setBusinessLocationsData]);

  React.useEffect(() => {
    if (csvAddressData && csvAddressData.length > 0) {
      setProspectAddresses(csvAddressData);
      setProspectLocationsData();
    }
  }, [csvAddressData, setProspectLocationsData]);

  const handleProspectCsvSubmit = (userFile: File) => {
    setFile(userFile);
    uploadAddressCsv(userFile);
  };

  const handleRemoveProspectAddressCsv = () => {
    resetAddressCsv();
    setFile(undefined);
    setProspectAddresses([]);
  };

  const handleRemoveGenerateReportsCsv = () => {
    setFile(undefined);
  };

  const searchByBusinessName = async (name: string) => {
    setBusinessName(name);
  };

  const handleCenterMap = (coordinateValues: [number, number]) => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.easeTo({
      center: coordinateValues,
      zoom: 9,
      duration: 1500
    });
  };

  const handleTabChange = (_: any, tabIndex: number) => {
    setTabValue(tabIndex);

    /**
     * clear the data if the user switches tabs
     * Tabs as followed:
     * Zoominfo, custom locations, generate reports, and customers
     */
    if (tabIndex !== 1) {
      handleRemoveProspectAddressCsv();
    }
    if (tabIndex !== 0) {
      setBusinessName('');
    }
    if (tabIndex !== 3) {
      setOrganizationId('');
    }

    // set the business name or org id to search by
    // based on only zoominfo or customers tab
    if (tabIndex === 3) {
      setBusinessNameOrOrgId(ZoomInfoQueryKey.OrgId);
    }
    if (tabIndex === 0) {
      setBusinessNameOrOrgId(ZoomInfoQueryKey.BusinessName);
    }
  };

  const handleOrganizationSelect = (org: Organization) => {
    setOrganizationId(org.id);
    setProspectName(org.name);
  };

  const openDialog = React.useCallback(() => {
    /**
     * There are plans to refactor these two logics
     * Not sure what the best approach is, yet
     */
    if (
      businessName === '' &&
      tabValue === 0 &&
      zoomBusinessLocations &&
      zoomBusinessLocations.length > 0
    ) {
      toast('Please select a business before generating a report.', {
        type: 'error',
        style: { fontSize: 16 }
      });
      return;
    }
    if (prospectName === '' && tabValue === 1 && prospectAddresses.length > 0) {
      toast('Please enter prospect name', {
        type: 'error'
      });
      return;
    }

    let locationsForCount: ProspectAddress[] | ZoomBusinessInfo[] = [];

    if (zoomBusinessLocations && zoomBusinessLocations?.length > 0) {
      locationsForCount = zoomBusinessLocations;
    } else if (prospectLocations?.length > 0) {
      locationsForCount = prospectLocations;
    }

    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: `Generate Report for ${businessName || prospectName}`,
        lazyComponent: 'DecommissionGenerateReportDialog',
        lazyComponentProps: {
          businessName: businessName || prospectName,
          numberOfLocations: calculateImpactedLocations(
            locationsForCount,
            disclosureZipcodeMap ?? {}
          ),
          isCustomLocations: tabValue === 1
        }
      })
    );
  }, [
    dispatch,
    actions,
    businessName,
    zoomBusinessLocations,
    disclosureZipcodeMap,
    prospectAddresses,
    prospectName,
    prospectLocations,
    tabValue
  ]);

  const hasNoZoomLocations =
    zoomBusinessLocations && zoomBusinessLocations.length === 0;
  const disableGenerateReport =
    hasNoZoomLocations && prospectLocations.length === 0;

  return (
    <div className={cc.paper} id="decommission-map">
      <>
        <Grid container>
          <Grid item xs={12}>
            <ActiveTabs tabValue={tabValue} handleTabChange={handleTabChange} />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup className={cc.formGroup}>
              {tabValue === 0 && (
                <>
                  <BusinessNamesDropdown
                    searchByBusinessName={searchByBusinessName}
                  />
                  <GenerateReportButton
                    openDialog={openDialog}
                    disableGenerateReport={disableGenerateReport}
                  />
                </>
              )}
              {tabValue === 1 && (
                <>
                  <ProspectReports
                    file={file}
                    handleProspectCsvSubmit={handleProspectCsvSubmit}
                    handleRemoveCsv={handleRemoveProspectAddressCsv}
                    impactedLocations={impactedLocations}
                    prospectName={prospectName}
                    setProspectName={setProspectName}
                  />
                  <GenerateReportButton
                    openDialog={openDialog}
                    disableGenerateReport={disableGenerateReport}
                  />
                </>
              )}
              {tabValue === 2 && (
                <GenerateReports
                  setFile={setFile}
                  file={file}
                  handleRemoveFile={handleRemoveGenerateReportsCsv}
                />
              )}
              {tabValue === 3 && (
                <>
                  <OrganizationDropdown
                    setOrganization={handleOrganizationSelect}
                  />
                  <GenerateReportButton
                    openDialog={openDialog}
                    disableGenerateReport={disableGenerateReport}
                  />
                </>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <main id="decommissionMapMain" className={cc.main}>
          <Typography className={cc.paperTitle} variant="h1">
            Decommissioning Map
          </Typography>

          <ImpactedStoresCount
            disclosureZipcodeMap={disclosureZipcodeMap ?? {}}
            zoomBusinessLocations={
              zoomBusinessLocations && zoomBusinessLocations?.length > 0
                ? zoomBusinessLocations
                : (prospectLocations as any)
            }
          />

          <div id="decomMapAndTable">
            <DecommissionMapBox
              ref={mapRef}
              mapRef={mapRef}
              disclosureGeoSource={disclosureGeoSource}
              businessGeoSource={businessGeoSource}
              prospectLocationsGeoSource={prospectLocationsGeoSource}
              selectedTab={tabValue}
            />
            {zoomBusinessLocations && zoomBusinessLocations.length > 0 && (
              <BusinessLocations
                handleCenterMap={handleCenterMap}
                disclosureZipcodeMap={disclosureZipcodeMap ?? {}}
                businessLocations={zoomBusinessLocations ?? []}
                businessGeoZipcodeMap={zoomBusinessGeoZipcodeMap ?? {}}
              />
            )}

            {prospectLocations.length > 0 && (
              <BusinessLocations
                handleCenterMap={handleCenterMap}
                disclosureZipcodeMap={disclosureZipcodeMap ?? {}}
                businessLocations={prospectLocations as ZoomBusinessInfo[]}
                businessGeoZipcodeMap={prospectLocationsMap ?? {}}
              />
            )}
          </div>
        </main>
      </>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    background: theme.palette.grey[300],
    minHeight: '100%',
    width: '100%',
    padding: '1rem'
  },
  paperTitle: {
    ...theme.typography.h6,
    borderBottom: `2px solid ${theme.palette.grey[50]}`,
    paddingBottom: '8.5px',
    marginBottom: 12,
    textIndent: '10px'
  },
  decomItem: {
    textIndent: '10px',
    paddingBottom: '0',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      paddingBottom: '8.5px'
    },
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  main: {
    marginTop: '2rem'
  },
  noResults: {
    textIndent: '10px'
  },

  inputWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1em',
    padding: '1.5em 1em 0'
  },

  submitButton: {
    background: theme.palette.primary.main,
    border: 'none',
    marginLeft: '5px',
    padding: '5 25px',
    display: 'inline-block'
  },

  clearButton: {
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  formGroup: {
    display: 'flex',
    rowGap: 20,
    margin: '15px auto 15px'
  },

  dragAndDropWrapper: {
    maxWidth: '100px'
  }
}));

export default DecommissionMap;

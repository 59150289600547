import { Hidden } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { useSidebar } from '../theme/SidebarProvider';
import CmdcListItem from './CmdcListItem';
import { ListLinkProps } from './ListLink';
import ListSideBarMenu from './ListSideBarMenu';
import SidebarToggle from './SidebarToggle';
// TODO: Refactor to use current user from store
import useCurrentUser from 'api/useCurrentUser';

export interface SidebarProps {
  routes: ListLinkProps[];
  MobileHeader?: () => JSX.Element;
  rootUri: string;
}

const Sidebar = ({
  routes,
  MobileHeader,
  rootUri
}: SidebarProps): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useSidebar();
  const handleOpenToggle = () => setOpen(value => !value);
  const { data: currentUser } = useCurrentUser();

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.sidebarDrawer]: true,
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <List disablePadding classes={{ root: classes.sidebarList }}>
            <CmdcListItem to={rootUri} />
            <div>
              {currentUser?.isSuperAdmin && currentUser.isMSOrg
                ? routes.map(
                    ({ label, to, Icon }: ListLinkProps, i: number) => (
                      <ListSideBarMenu
                        key={`${to}${i}`}
                        label={label}
                        to={to}
                        Icon={Icon}
                      />
                    )
                  )
                : routes
                    .filter(({ label }: ListLinkProps) => label !== 'Hardware')
                    .map(({ label, to, Icon }: ListLinkProps, i: number) => (
                      <ListSideBarMenu
                        key={`${to}${i}`}
                        label={label}
                        to={to}
                        Icon={Icon}
                      />
                    ))}
            </div>
          </List>
          <SidebarToggle isOpen={open} handleToggle={handleOpenToggle} />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: clsx(classes.sidebarDrawer, classes.sidebarDrawerWeb)
          }}
        >
          <>
            <div className={classes.drawerHeader}>
              <IconButton
                onClick={handleOpenToggle}
                className={classes.sidebarCloseIcon}
              >
                <Close />
              </IconButton>
            </div>
            {!MobileHeader || <MobileHeader />}
            <List disablePadding>
              <div>
                {currentUser?.isSuperAdmin && currentUser?.isMSOrg
                  ? routes.map(
                      ({ label, to, Icon }: ListLinkProps, i: number) => (
                        <ListSideBarMenu
                          key={`${to}${i}`}
                          label={label}
                          to={to}
                          Icon={Icon}
                        />
                      )
                    )
                  : routes
                      .filter(
                        ({ label }: ListLinkProps) => label !== 'Hardware'
                      )
                      .map(({ label, to, Icon }: ListLinkProps, i: number) => (
                        <ListSideBarMenu
                          key={`${to}${i}`}
                          label={label}
                          to={to}
                          Icon={Icon}
                        />
                      ))}
              </div>
            </List>
          </>
        </Drawer>
      </Hidden>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex' },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  sidebarList: {
    overflow: 'hidden'
  },
  sidebarDrawer: {
    backgroundColor: theme.palette.common.black,
    border: 'none'
  },
  sidebarDrawerWeb: {
    width: theme.mixins.sidebar.width
  },
  sidebarCloseIcon: {
    marginLeft: theme.spacing(-1)
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  drawerOpen: {
    width: theme.mixins.sidebar.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1
  }
}));

export default Sidebar;

import React from 'react';
import { Layer, Source } from 'react-map-gl';

interface Props {
  businessGeoSource: any;
}

export default React.memo(function BusinessSource({
  businessGeoSource
}: Props) {
  return (
    <Source id="zoomBusinesses" type="geojson" data={businessGeoSource}>
      <Layer
        id="unimpacted-store-locations"
        type="circle"
        source="zoomBusinessLocations"
        filter={['<', ['get', 'impactedStores'], 1]}
        paint={{
          'circle-color': '#222222',
          'circle-radius': 3,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
          'circle-opacity': 0.4
        }}
        minzoom={7}
      />

      <Layer
        id="impacted-store-locations"
        type="circle"
        source="zoomBusinessLocations"
        filter={['>=', ['get', 'impactedStores'], 1]}
        paint={{
          'circle-color': '#000',
          'circle-radius': 8,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#fff',
          'circle-opacity': 0.8
        }}
      />
    </Source>
  );
});

import * as React from 'react';
import clsx from 'clsx';
import * as MuiButton from '@material-ui/core/Button';
import LoadingIcon from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface ButtonProps
  extends Omit<MuiButton.ButtonProps, 'size' | 'color'> {
  isLoading?: boolean;
  size?: 'small' | 'large';
  error?: boolean;
}

// Get the Mui Button Component
const MatuiButton = MuiButton.default;

const Button = ({
  classes,
  className,
  children,
  variant = 'contained',
  disabled,
  isLoading = false,
  size = 'large',
  error,
  ...props
}: ButtonProps): JSX.Element => {
  const cc = useStyles();
  const loadingIconSvg = clsx({
    [cc.loadingIconSvg]: true,
    [cc.loadingIconSvgOutlined]: variant === 'outlined'
  });

  return (
    <MatuiButton
      color="primary"
      variant={variant}
      disabled={isLoading || disabled}
      classes={{
        root: error ? cc.errorRoot : cc.buttonRoot,
        outlined: cc.buttonOutlined,
        sizeSmall: cc.buttonSizeSmall
      }}
      className={className}
      size={size}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disableElevation
    >
      {isLoading ? (
        <LoadingIcon
          size={14}
          thickness={7}
          classes={{ svg: loadingIconSvg }}
        />
      ) : (
        children
      )}
    </MatuiButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      width: '85px',
      minWidth: '85px'
    },
    fontWeight: 'bold',
    padding: '12px 20px',
    lineHeight: '1rem',
    minWidth: 124,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  errorRoot: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      width: '85px',
      minWidth: '85px'
    },
    fontWeight: 'bold',
    padding: '12px 20px',
    lineHeight: '1rem',
    minWidth: 124,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonOutlined: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': { borderWidth: 2, backgroundColor: theme.palette.common.white },
    '&[disabled=""]': { color: theme.palette.grey['50'] },
    backgroundColor: theme.palette.common.white
  },
  loadingIconSvg: {
    color: theme.palette.common.white
  },
  loadingIconSvgOutlined: {
    color: theme.palette.primary.main
  },
  buttonSizeSmall: {
    fontSize: theme.typography.body2.fontSize,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
    minWidth: 0,
    backgroundColor: theme.palette.grey['500'],
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.common.black
    }
  }
}));

export default Button;

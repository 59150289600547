import { Theme, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useDashboardSlice } from 'app/layouts';
import {
  StatusToColor,
  pageSizeOptions,
  MIX_DOMAIN_REGEX,
  MIX_MANAGE_DOMAIN_URL,
  NET2PHONE_MANAGE_DOMAIN_URL
} from 'constants/app';
import { ColumnProps, Dot, TablePaginated, Text, Link } from 'design';
import React from 'react';
import { Gateway } from 'types/Gateway';
import EquipmentTableAction from '../EquipmentTableActions';
import { MenuActionItemProps } from '../EquipmentTableActions/EquipmentTableAction';
import { useDispatch } from 'react-redux';
import EquipmentPortalLink from '../EquipmentPortalLink/EquipmentPortalLink';
interface GatewayTableProps {
  gateways?: Gateway[];
  locationId: string;
}

/**
 * Gateway table props
 * @param {GatewayTableProps} props
 * @returns {JSX.Element}
 */
const GatewayTable = ({
  gateways,
  locationId
}: GatewayTableProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const cc = useStyles();
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(1);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const domainLink = (domain: string): string => {
    if (MIX_DOMAIN_REGEX.test(domain)) {
      return `${MIX_MANAGE_DOMAIN_URL}${domain}`;
    } else {
      return `${NET2PHONE_MANAGE_DOMAIN_URL}${domain}`;
    }
  };

  const menuItems: MenuActionItemProps[] = [
    {
      label: 'Manage Lines',
      isVisible: true,
      lazyComponent: '',
      size: 'large'
    },
    {
      label: 'Edit',
      isVisible: true,
      lazyComponent: 'BundleGatewayUpdate',
      lazyComponentProps: {
        locationId
      },
      size: 'xlarge'
    },
    {
      label: 'Export GDMS',
      isVisible: true,
      size: 'large',
      title: 'Download GDMS Template',
      lazyComponent: 'GdmsTemplate'
    }
  ];
  const Columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'operationalStatus.name',
        label: '',
        align: 'center',
        hidden: currentUser?.isOrgAdminOrUser,
        render: ({ operationalStatus }: Gateway) => (
          <Dot
            color={
              operationalStatus?.name
                ? StatusToColor[operationalStatus?.name]
                : null
            }
          />
        ),
        cellWidth: 43,
        padding: 'none'
      },
      {
        accessor: 'expandIcon',
        label: '',
        align: 'center',
        padding: 'none',
        cellWidth: 43,
        resizable: true,
        render: ({ id }: Gateway) => (
          <IconButton
            data-testid="expandButton"
            className={cc.expandButton}
            onClick={() => {
              dispatch(
                actions.showDialog({
                  open: true,
                  title: '',
                  lazyComponent: 'BundleGatewayExpand',
                  size: 'large',
                  lazyComponentProps: {
                    id: id
                  }
                })
              );
            }}
          >
            <LaunchIcon fontSize="small" className={cc.linkIcon} />
          </IconButton>
        )
      },
      {
        accessor: 'gatewayCatalog.model',
        label: 'Name',
        verticalAlign: 'top',
        resizable: true,
        render: ({ gatewayCatalog, adminStatus }: Gateway) => (
          <>
            <Text>{gatewayCatalog?.model}</Text>
            <Text className={cc.subRow}>{adminStatus?.name}</Text>
          </>
        )
      },
      {
        accessor: 'serialNumber',
        label: 'Serial Number',
        render: (gatewayRow: Gateway) => (
          <EquipmentPortalLink
            brand={gatewayRow.gatewayCatalog.brand}
            group="ata" // "ata" is what the Equipment type's CatalogItem.group will be for gateways
            serialNumber={gatewayRow.serialNumber}
          />
        ),
        resizable: true
      },
      {
        accessor: 'ip',
        label: 'Virtual IP',
        resizable: true
      },
      {
        accessor: 'mac',
        label: 'MAC Address',
        resizable: true
      },
      {
        accessor: 'domain',
        label: 'Domain',
        resizable: true,
        render: ({ domain }: Gateway) => (
          <>
            {currentUser?.isSuperAdmin ? (
              <Link to={domainLink(domain)} target="blank">
                {domain}
              </Link>
            ) : (
              <>{domain}</>
            )}
          </>
        )
      },
      {
        accessor: 'lines',
        label: 'Lines',
        render: ({ lines }: any) => (
          <>
            {lines?.length > 0 ? (
              <>
                {lines.map((line: any, i: any) => {
                  return <Text key={i}>{line ? line : '-'}</Text>;
                })}
              </>
            ) : (
              <Text>-</Text>
            )}
          </>
        ),
        resizable: true
      },
      {
        accessor: '',
        label: '',
        hidden: currentUser?.isOrgAdminOrUser,
        render: (gateway: Gateway) => (
          <EquipmentTableAction menuItems={menuItems} equipmentItem={gateway} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );
  return (
    <TablePaginated
      id="Gateway"
      columns={Columns}
      handlePageSize={setPageSize}
      handlePageNav={setPageIndex}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageSizeOptions={pageSizeOptions}
      isLoading={false}
      rows={gateways ? gateways : []}
      total={0}
      hidePagination={true}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  expandButton: {
    padding: 0,
    marginTop: '2px'
  },
  linkIcon: {
    color: '#A9B3B7',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '-8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  subRow: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
export default GatewayTable;

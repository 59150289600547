import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { ActionButton } from 'design';
import React from 'react';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';
import { useDispatch } from 'react-redux';
import { useDashboardSlice } from 'app/layouts';
import { useHistory } from 'react-router-dom';
import { Gateway } from 'types/Gateway';
import { Router } from 'types/Router';
import { Powersource } from 'types/PowerSource';
export interface MenuActionItemProps {
  label: string;
  isVisible: any;
  lazyComponent: string;
  lazyComponentProps?: {
    locationId?: string;
  };
  title?: string;
  size: any;
  disabled?: boolean;
}
interface MenuItemsProps {
  menuItems: any;
  equipmentItem: Gateway | Router | Powersource;
  bundleId?: string;
}
export interface Menus {
  label: string;
  isVisible: any;
  action: () => void;
  disabled?: boolean;
}
const EquipmentTableAction = ({
  menuItems,
  equipmentItem,
  bundleId
}: MenuItemsProps): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  const cc = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuActions, setmenuActions] = React.useState<Menus[]>([]);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentUser?.isSuperAdmin || currentUser?.isOrgAdminOrUser)
      setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const menu: any = [];
    menuItems.map((item: MenuActionItemProps) => {
      return menu.push({
        label: item.label,
        isVisible: item.isVisible,
        disabled: item.disabled,
        action: () => {
          if (item.label === 'Manage Lines') {
            history.push({
              pathname: `/location/gateway/${equipmentItem.id}/managelines`,
              state: {
                goBack: history?.location?.pathname
              }
            });
          } else if (item.label === 'Manage SIMs') {
            history.push({
              pathname: `/location/router/${equipmentItem.id}/manage-sims`,
              state: { goBack: history?.location?.pathname }
            });
          } else {
            dispatch(
              actions.showDialog({
                open: true,
                title: item.title,
                lazyComponent: item.lazyComponent,
                size: item.size,
                lazyComponentProps: {
                  ...equipmentItem,
                  bundleId: bundleId,
                  locationId: item?.lazyComponentProps?.locationId
                }
              })
            );
          }
        }
      });
    });
    setmenuActions(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, equipmentItem, bundleId]);
  return (
    <>
      <div className={cc.iconButton}>
        <ActionButton onClick={handleMenuClick}>
          <MoreVert color="action" />
        </ActionButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: cc.paper, list: cc.list }}
        className={cc.menuBar}
      >
        {menuActions.map(
          ({ label, action, isVisible, disabled }: Menus, i: any) => {
            return (
              <div key={i}>
                {isVisible && (
                  <MenuItem
                    button
                    disabled={disabled}
                    onClick={() => {
                      action();
                      if (handleMenuClose) {
                        handleMenuClose();
                      }
                    }}
                    key={label}
                  >
                    {label}
                  </MenuItem>
                )}
              </div>
            );
          }
        )}
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    width: '100%',
    maxWidth: 150
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.grey['50'],
    borderWidth: 2,
    borderStyle: 'solid'
  },
  menuBar: {
    marginLeft: '20px'
  },
  iconButton: {
    float: 'right'
  },
  disableColor: {
    color: 'grey'
  },
  menuItemColor: {
    color: 'black'
  }
}));
export default EquipmentTableAction;

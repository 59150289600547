import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useDashboardSlice } from 'app/layouts';
import { AxiosError } from 'utils/axiosProvider';
import { createHardwareCatalog } from 'api/useHardwareCatalogs';
import HardwareCreateEditDialog from '../HardwareCreateEditDialog';

import {
  powerSourceDropDownInputs,
  powerSourceRequiredInputs,
  powerSourceTextInputs
} from './EquipmentConstants';
import PowerSourceCatalogSchema from './PowerSourceCatalogSchema';
import { PowerSourceCreateEditData, PowerSourceFormikValues } from 'types';

interface PowerSourceCreateDialogProps {
  refetch: () => void;
  handleClose: () => void;
}

const PowerSourceCreateDialog = ({
  refetch,
  handleClose
}: PowerSourceCreateDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();

  const powerSourceCreateInitialValues: PowerSourceFormikValues = {
    brand: '',
    model: '',
    power: '',
    maxBatteryCount: '0',
    serialNumberRequired: false,
    quantityRequired: false
  };

  const formik = useFormik({
    initialValues: powerSourceCreateInitialValues,
    validationSchema: PowerSourceCatalogSchema,
    onSubmit: () => onSubmit()
  });

  const onSubmit = async () => {
    try {
      const data: PowerSourceCreateEditData = {
        ...formik.values,
        maxBatteryCount: parseInt(formik.values.maxBatteryCount)
      };
      // API call
      await createHardwareCatalog('powersource', data);

      // Update UI after changes
      refetch();

      dispatch(
        actions.showNotify({
          message: 'Power source created successfully',
          severity: 'success'
        })
      );
      handleClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          actions.showNotify({
            message: `Failed to create power source: ${error.response?.data.message}`,
            severity: 'error'
          })
        );
      } else {
        dispatch(
          actions.showNotify({
            message: 'Failed to create power source',
            severity: 'error'
          })
        );
      }
    }
  };

  return (
    <HardwareCreateEditDialog
      title="Power Source"
      type="Create"
      formik={formik}
      handleClose={handleClose}
      textInputs={powerSourceTextInputs}
      dropDownInputs={powerSourceDropDownInputs}
      requiredInputs={powerSourceRequiredInputs}
    />
  );
};

export default PowerSourceCreateDialog;

import { DropdownOption } from 'design';
import { Entity } from './Entity';

export interface BundleTemplate extends Entity {
  name: string;
  createdBy: string;
  createdAt?: string;
  routersCatalog?: RouterCatalog[];
  gatewaysCatalog?: GatewayCatalog[];
  powersourcesCatalog?: PowersourceCatalog[];
  status: HardwareCatalogStatus;
}

export interface RouterCatalog extends Entity {
  brand: string;
  model: string;
  power: string;
  lanPorts?: number;
  simCount?: number;
  wanPorts?: number;
  isCellular: boolean;
  isWired: boolean;
  ipAddressRequired: boolean;
  userNameRequired: boolean;
  passwordRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
  locationCount: number;
  status: HardwareCatalogStatus;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface GatewayCatalog extends Entity {
  brand: string;
  model: string;
  power: string;
  fxoPorts: number;
  ipAddressRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
  locationCount: number;
  status: HardwareCatalogStatus;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface PowersourceCatalog extends Entity {
  model: string;
  brand: string;
  power: string;
  maxBatteryCount: number;
  batteryCount?: number;
  serialNumberRequired: boolean;
  quantityRequired: boolean;
  locationCount: number;
  status: HardwareCatalogStatus;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ManagedBy extends Entity {
  id: string;
  name: string;
}

export type HardwareCatalog =
  | RouterCatalog
  | GatewayCatalog
  | PowersourceCatalog;

export type Catalogs = 'router' | 'gateway' | 'powersource';

type HardwareTextValues = 'brand' | 'model' | 'power';

type HardwareDropdownValues =
  | 'wanPorts'
  | 'lanPorts'
  | 'simCount'
  | 'fxoPorts'
  | 'maxBatteryCount';

type HardwareRequiredValues =
  | 'ipAddressRequired'
  | 'userNameRequired'
  | 'passwordRequired'
  | 'serialNumberRequired'
  | 'macAddressRequired'
  | 'imeiRequired'
  | 'quantityRequired';

export interface RouterFormikValues {
  brand: string;
  model: string;
  power: string;
  wanPorts: string;
  lanPorts: string;
  simCount: string;
  ipAddressRequired: boolean;
  userNameRequired: boolean;
  passwordRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
}

export interface RouterCreateEditData {
  brand: string;
  model: string;
  power: string;
  wanPorts: number;
  lanPorts: number;
  simCount: number;
  ipAddressRequired: boolean;
  userNameRequired: boolean;
  passwordRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
}

export interface GatewayFormikValues {
  brand: string;
  model: string;
  power: string;
  fxoPorts: string;
  ipAddressRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
}

export interface GatewayCreateEditData {
  brand: string;
  model: string;
  power: string;
  fxoPorts: number;
  ipAddressRequired: boolean;
  serialNumberRequired: boolean;
  macAddressRequired: boolean;
  imeiRequired: boolean;
}

export interface HardwareCatalogEditStatus {
  statusId: string;
}

export interface PowerSourceFormikValues {
  model: string;
  brand: string;
  power: string;
  maxBatteryCount: string;
  serialNumberRequired: boolean;
  quantityRequired: boolean;
}

export interface PowerSourceCreateEditData {
  model: string;
  brand: string;
  power: string;
  maxBatteryCount: number;
  serialNumberRequired: boolean;
  quantityRequired: boolean;
}

export type HardwareCreateEditData =
  | RouterCreateEditData
  | GatewayCreateEditData
  | PowerSourceCreateEditData;

export interface CreateEditModalTextInputs {
  value: HardwareTextValues;
  label: string;
}

export interface CreateEditModalDropDownInputs {
  value: HardwareDropdownValues;
  label: string;
  options: DropdownOption[];
}

export interface CreateEditModalRequiredInputs {
  value: HardwareRequiredValues;
  label: string;
}

export interface HardwareCatalogStatus {
  id: string;
  name: string;
}

export enum HardwareCatalogStatusEnum {
  ACTIVE = 'Active',
  DECOMMISSIONED = 'Decommissioned'
}

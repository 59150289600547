import * as Sentry from '@sentry/react';

const { REACT_APP_SENTRY_DSN } = process.env;

const tracePropTargets = ['localhost'];

if (process.env.REACT_APP_API_URL) {
  tracePropTargets.push(process.env.REACT_APP_API_URL);
}

if (REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: tracePropTargets,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import PageHeader from 'app/components/PageHeader';
import {
  Dot,
  TablePaginated,
  ColumnProps,
  SearchInput,
  Link,
  Text
} from 'design';
import { MARKETSPARK, pageSizeOptions, StatusToColor } from 'constants/app';
import { Status, Line, Sort } from 'types';
import useSort from 'hooks/useSort';
import useLines from 'api/useLines';
import useLinesStatusCnt from 'api/useLinesStatusCnt';
import PhoneNumber from '../../components/PhoneNumber';
import StatusBadgeBar from '../../components/StatusBadgeBar';
import LineActions from 'app/components/LocationLines/LineActions';
import { useCallforwardingExists } from 'api/useLocationLines';
import { selectCurrentUser } from '../../../store/selectors';

const LocationLinesPage = (): JSX.Element => {
  const { pathname, search, state } = useLocation();
  const urlSearchParams = React.useMemo(() => new URLSearchParams(search), [
    search
  ]);
  const history = useHistory();

  const urlFilter = urlSearchParams.get('filter');
  const [filter, setFilter] = React.useState(urlFilter ? urlFilter : '');

  const urlPage = urlSearchParams.get('page');
  const [pageIndex, setPageIndex] = React.useState(
    urlPage ? parseInt(urlPage) : 1
  );

  const urlPageSize = urlSearchParams.get('limit');
  const [pageSize, setPageSize] = React.useState(
    urlPageSize ? parseInt(urlPageSize) : 10
  );

  const [sort, setSort] = useSort(
    urlSearchParams.get('sort')?.split('|') as Sort
  );

  // Get the status filter from the URL, or from the URL Location state.
  //  Location state may get set from the DashboardOrgLinesCard.
  const urlStatusFilter = urlSearchParams.get('serviceStatus');
  const locationStateStatus = state as Status;
  const [statusFilter, setStatusFilter] = React.useState<Status | ''>(
    urlStatusFilter ? (urlStatusFilter as Status) : locationStateStatus ?? ''
  );

  const cc = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const prevOrganization = React.useRef(currentUser?.organization);
  const [searchInput, setSearchInput] = React.useState(filter);
  const { isLoading, data, error, refetch } = useLines(
    filter,
    statusFilter,
    sort,
    pageIndex,
    pageSize
  );
  const { data: doesCallforwardingExists } = useCallforwardingExists();

  React.useEffect(() => {
    if (filter === '') {
      urlSearchParams.delete('filter');
    } else {
      urlSearchParams.set('filter', filter);
    }

    if (pageIndex <= 1) {
      urlSearchParams.delete('page');
    } else {
      urlSearchParams.set('page', pageIndex.toString());
    }

    if (pageSize === 10) {
      urlSearchParams.delete('limit');
    } else {
      urlSearchParams.set('limit', pageSize.toString());
    }

    // If sort is the default sort, don't include it in the URL
    if ((sort as Sort) === undefined || sort.join('|') === '|ASC') {
      urlSearchParams.delete('sort');
    } else {
      urlSearchParams.set('sort', sort.join('|'));
    }

    if (statusFilter === '') {
      setStatusFilter('');
      urlSearchParams.delete('serviceStatus');
    } else {
      setStatusFilter(statusFilter);
      urlSearchParams.set('serviceStatus', statusFilter);
    }

    history.push({
      pathname,
      search: urlSearchParams.toString()
    });
  }, [
    filter,
    pageIndex,
    pageSize,
    sort,
    statusFilter,
    history,
    pathname,
    urlSearchParams,
    state
  ]);

  React.useEffect(() => {
    // If the (super) user changes organizations, reset the page index and sort
    if (
      currentUser?.organization !== undefined &&
      prevOrganization.current !== undefined &&
      currentUser?.organization !== prevOrganization.current
    ) {
      setPageIndex(1);
      setSort('');
    }
    prevOrganization.current = currentUser?.organization;
  }, [currentUser, setSort]);

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      { accessor: 'id', label: 'id', hidden: true },
      {
        accessor: 'serviceStatus.name',
        label: '',
        align: 'center',
        padding: 'none',
        render: ({ serviceStatus }: Line) => (
          <Dot color={StatusToColor[serviceStatus.name]} />
        )
      },
      {
        accessor: 'account.organization.name',
        label: 'Organization',
        render: ({ account: { organization } }: Line) => (
          <Link to={`/organization/${organization.id}`}>
            {organization.name}
          </Link>
        ),
        sortable: true,
        hidden: !(
          currentUser.isMSOrg &&
          currentUser.isSuperAdmin &&
          currentUser.organization.name === MARKETSPARK
        ),
        resizable: true
      },
      {
        accessor: 'account.name',
        label: 'Location',
        render: ({ account }: Line) => {
          const { id, name, organization } = account;
          return (
            <Link to={`/${organization?.id}/location/${id}/section/lines`}>
              {name}
            </Link>
          );
        },
        sortable: true,
        resizable: true
      },
      {
        accessor: 'value',
        label: 'Phone Number',
        sortable: true,
        render: ({ value, account }: Line) => {
          const { id } = account;
          const to = `/location/${id}/cdr/${value}`;
          return (
            <Link to={to}>
              <PhoneNumber>{value}</PhoneNumber>
            </Link>
          );
        },
        resizable: true
      },
      {
        accessor: 'description',
        label: 'Description',
        sortable: true,
        resizable: true
      },
      {
        accessor: 'lineType.name',
        label: 'Line Type',
        sortable: true,
        resizable: true,
        hidden: !(currentUser?.isSuperAdmin && currentUser?.isMSOrg)
      },
      { accessor: 'port', label: 'Port', sortable: true, resizable: true },
      {
        accessor: 'forwardValue',
        label: 'Call Forwarding',
        sortable: true,
        render: ({ forwardValue, forwardEnable }: Line) =>
          forwardEnable ? <PhoneNumber>{forwardValue}</PhoneNumber> : <>-</>,
        resizable: true,
        hidden: !doesCallforwardingExists
      },
      {
        accessor: 'tempPortingNumber',
        label: 'Temp DID (Porting)',
        sortable: true,
        render: ({ tempPortingNumber }: Line) =>
          tempPortingNumber ? (
            <PhoneNumber>{tempPortingNumber}</PhoneNumber>
          ) : (
            <>-</>
          ),
        resizable: true,
        hidden: !currentUser?.isMSOrg
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'emergencyAddressRegistered',
        label: 'E911 Address',
        sortable: true,
        render: ({ emergencyAddressRegistered }: Line) =>
          emergencyAddressRegistered ? (
            <Text> Registered </Text>
          ) : (
            <Text> Not Registered</Text>
          ),
        resizable: true
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'equipment.catalogItem.name',
        label: 'Equipment',
        sortable: true,
        resizable: true,
        render: ({ gateway, equipment }: Line) =>
          equipment ? (
            <Text>{equipment?.catalogItem?.name}</Text>
          ) : gateway ? (
            <Text>{gateway?.gatewayCatalog?.model}</Text>
          ) : (
            <>-</>
          )
      },
      {
        hidden: !currentUser?.isSuperAdmin,
        accessor: 'equipment.mac',
        label: 'MAC',
        sortable: true,
        resizable: true,
        render: ({ gateway, equipment }: Line) =>
          equipment ? (
            <Text>{equipment.mac}</Text>
          ) : gateway ? (
            <Text>{gateway?.mac}</Text>
          ) : (
            <>-</>
          )
      },
      {
        accessor: 'networkProvider.name',
        label: 'Provider',
        sortable: true,
        resizable: true,
        hidden: !(currentUser?.isSuperAdmin && currentUser?.isMSOrg)
      },
      {
        accessor: '',
        label: '',
        hidden: !(currentUser?.isSuperAdmin || currentUser?.isOrgAdmin),
        render: ({
          id,
          value,
          forwardValue,
          forwardEnable,
          account,
          networkProvider,
          equipment,
          gateway
        }: Line) => {
          return (
            <div>
              <LineActions
                id={id}
                phoneNumber={value}
                forwardValue={forwardValue}
                forwardEnable={forwardEnable}
                providerName={networkProvider?.name}
                locationName={account?.name}
                refetch={refetch}
                equipmentId={gateway?.id ? gateway?.id : equipment?.id}
                pathName={!!gateway?.id ? `gateway` : `equipment`}
              />
            </div>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, doesCallforwardingExists]
  );

  const { lines, total } = data || { lines: [], total: 0 };

  if (error) {
    throw new Error(`${error.message} Failed to fetch Locations!`);
  }

  return (
    <>
      <Helmet>
        <title> Lines </title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={cc.headWrapper}>
        <PageHeader title="Lines" />
        <SearchInput
          className={cc.searchInput}
          value={searchInput}
          handleClear={() => {
            setFilter('');
            setSearchInput('');
            setPageIndex(1);
          }}
          onChange={setSearchInput}
          handleSearch={() => {
            setFilter(searchInput);
            setPageIndex(1);
          }}
        />
      </div>
      <StatusBadgeBar
        getStatusCount={useLinesStatusCnt()}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        setPageIndex={setPageIndex}
      />
      <div>
        <TablePaginated
          id="Lines"
          rows={lines}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    }
  },
  searchInput: {
    margin: theme.spacing(0, 2, 1),
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  }
}));

export default LocationLinesPage;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper
} from '@material-ui/core';
import cx from 'clsx';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import _ from 'lodash';

export interface DropdownOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface DropdownProps {
  id: string;
  name?: string;
  disabled?: boolean;
  value: any;
  label: string;
  isError: boolean;
  isLoading?: boolean;
  onChange: (event: any) => void;
  onBlur?: (e: any) => void;
  validationMessage?: string;
  validationType?: 'info' | 'success';
  dropdownItems: DropdownOption[];
  className?: string;
  variant?: string;
  style?: Record<string, unknown>;
  placeholder?: string;
  autoComplete?: boolean;
  disableClearable?: boolean;
}

export interface LabelValuesMap {
  label: string;
  value: string;
}

type AutocompleteFilterOptions<T> = (
  options: T[],
  state: FilterOptionsState<T>
) => T[];

const findLabel = (
  items: { label: string; value: string }[],
  value: string
) => {
  const foundItem = items.find(i => i.value === value);
  return foundItem?.label ?? '';
};

function sortBySelectedValue(
  items: { label: string; value: string }[],
  value: string
) {
  return items.sort(a => (a.label === value ? -1 : 1));
}

export const labelValuesFor = (
  data: any,
  nameField = 'name'
): LabelValuesMap[] => {
  if (!data) {
    return [];
  }
  return data.map((rType: any) => ({
    label: rType[nameField],
    value: rType.id
  }));
};

/**
 * OVERRIDE OF BELOW
 * Select wrapper component. Must be passed MenuItems from material-ui/core
 * @param {DropdownProps} props
 * @returns {JSX.Element}
 */
const Dropdown = ({
  id,
  name,
  disabled,
  label,
  isError,
  isLoading,
  onChange,
  validationMessage,
  validationType,
  value,
  dropdownItems,
  className,
  onBlur,
  variant,
  placeholder,
  style,
  autoComplete,
  disableClearable = false
}: DropdownProps): JSX.Element => {
  // eslint-disable-next-line
  const customStyle = style;
  const cc = useStyles();

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(() => {
    return findLabel(dropdownItems, value);
  });
  const [isSearching, setIsSearching] = React.useState(false);

  const validationMessageClass = cx(cc.validationMessage, {
    [cc.validationInfoMessage]: validationType === 'info',
    [cc.validationSuccessMessage]: validationType === 'success',
    [cc.validationErrorMessage]: isError
  });

  React.useEffect(
    () => {
      setInputValue(findLabel(dropdownItems, value));
      setIsSearching(false);
    },
    // eslint-disable-next-line
    [value, dropdownItems]
  );

  /*
   Below function does two things
   Filters the dropdown list when we type in the autocomplete search input.
   When selected, it shows all the items and moves the selected item to the top of the list
  */
  const autoCompleteFilterOptions = React.useCallback<
    AutocompleteFilterOptions<LabelValuesMap>
  >(
    (options, state) => {
      if (isSearching) {
        return options.filter(option =>
          option.label.toLowerCase().includes(state.inputValue.toLowerCase())
        );
      }
      return sortBySelectedValue(options, state.inputValue);
    },
    [isSearching]
  );

  /*
  Decides whether to use autocomplete or dropdown by items length.
  Can be manually overridden by 'autoComplete' prop
  */
  const isAutoComplete = _.isBoolean(autoComplete)
    ? autoComplete
    : dropdownItems.length > 9;

  return (
    <FormControl
      classes={{ root: cc.formControlRoot }}
      disabled={disabled}
      className={className}
      style={customStyle}
      fullWidth
    >
      <InputLabel
        classes={{
          root: cc.inputLabelRoot,
          focused: cc.inputLabelFocused,
          disabled: cc.selectDisabled
        }}
        disableAnimation
        shrink
      >
        {label}
      </InputLabel>
      {/* Dropdown */}
      {!isAutoComplete ? (
        <div className={cc.selectAndButton}>
          <Select
            id={id}
            name={name || id}
            disabled={disabled}
            classes={{
              select: cc.select,
              disabled: cc.selectDisabled
            }}
            placeholder={placeholder}
            IconComponent={() => null}
            onChange={(event: any, val) => {
              onChange({
                label: event.currentTarget.firstChild.data,
                value: event.target.value
              });
            }}
            onBlur={onBlur}
            value={value ?? ''}
            renderValue={selected => {
              return selected ? (
                <span>
                  {dropdownItems.find(e => e.value === selected)?.label}
                </span>
              ) : isLoading ? (
                <span style={{ color: '#A9B3B7' }}>Loading...</span>
              ) : (
                <span style={{ color: '#A9B3B7' }}>{placeholder}</span>
              );
            }}
            // eslint-disable-next-line
            disableUnderline
            displayEmpty
            MenuProps={{
              PopoverClasses: {
                root: cc.menuItemPaperRoot,
                paper: cc.menuItemPaper
              },
              MenuListProps: {
                disablePadding: true,
                classes: {
                  root: variant === 'alt' ? cc.menuItemRootAlt : cc.menuItemRoot
                }
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            }}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            style={{ width: 'calc(100% - 36px)' }}
          >
            {/* {placeholder ? (
              <MenuItem value="" className={cc.menuItem} selected disabled>
                {placeholder}
              </MenuItem>
            ) : null} */}

            {dropdownItems.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                className={cc.menuItem}
                disabled={option.value === '' || option.disabled ? true : false}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <div
            className={`${!disabled ? cc.cursor : ''} ${cc.selectButton}
                ${variant === 'alt' ? cc.selectButtonAlt : ''}
                 ${isOpen ? cc.selectOpen : ''}`}
            aria-hidden="true"
            onClick={() => {
              if (!disabled) {
                setIsOpen(!isOpen);
              }
            }}
          />
        </div>
      ) : (
        <>
          <Autocomplete
            disableClearable={disableClearable}
            autoHighlight
            data-testid="autocomplete"
            id={id}
            className={cc.autoCompleteWrapper}
            disabled={disabled}
            clearOnBlur={false}
            classes={{
              input: `${cc.input} ${disabled ? cc.inputDisabled : ''}`,
              clearIndicatorDirty: cc.clearIndicatorDirty,
              popupIndicator: cc.popupIndicator,
              option: cc.dropDownList,
              popper: cc.autoCompletePopper,
              endAdornment: cc.endAdornment
            }}
            filterOptions={autoCompleteFilterOptions}
            open={isOpen}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setIsOpen(false);
              setIsSearching(false);
              setInputValue(findLabel(dropdownItems, value));
            }}
            options={dropdownItems}
            getOptionDisabled={option => !option.value}
            getOptionSelected={(option, newVal: unknown) =>
              option.value === newVal
            }
            getOptionLabel={options => options.label ?? ''}
            value={value ? value : null}
            inputValue={inputValue}
            onChange={(e, val) => {
              onChange({
                value: val?.value,
                label: val?.label
              });
            }}
            blurOnSelect
            onInputChange={(e, val) => {
              setIsSearching(true);
              setInputValue(val);
            }}
            PaperComponent={({ children }) => (
              <Paper classes={{ root: cc.autoCompletePaper }}>{children}</Paper>
            )}
            ListboxProps={{ className: cc.autoCompleteListbox }}
            renderInput={params => (
              <TextField
                data-testid="autocompleteTextField"
                classes={{
                  root: cc.textFieldRoot
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                size="small"
                id={id}
                name={id}
                placeholder={isLoading ? 'Loading...' : placeholder}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    notchedOutline: cc.notchedOutline
                  }
                }}
              />
            )}
          />
        </>
      )}

      {/* AutoComplete */}
      <div className={validationMessageClass}>
        {isError && validationMessage}&nbsp;
      </div>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formControlRoot: {
    minWidth: '70px',
    marginBottom: '1px'
  },
  selectAndButton: {
    display: 'flex',
    width: '100%'
  },

  select: {
    width: '100%',
    flex: 'auto 1 1',
    marginRight: '-4px',
    backgroundColor: theme.palette.grey[300],
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    borderTop: `2px solid ${theme.palette.grey[50]}`,
    borderBottom: `2px solid ${theme.palette.grey[50]}`,
    borderRadius: 0,
    textIndent: '15px',
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },

  selectDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },

  cursor: {
    cursor: 'pointer'
  },

  selectButton: {
    width: 36,
    display: 'block',
    backgroundColor: theme.palette.grey[300],
    paddingLeft: '2px',
    border: `2px solid ${theme.palette.grey[50]}`,
    position: 'relative',
    zIndex: 2,
    '&::after': {
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: `6px solid transparent`,
      borderRight: `6px solid transparent`,
      borderTop: `7px solid ${theme.palette.grey[500]}`,
      transform: 'translate3d(-50%,-50%, 0)'
    }
  },

  selectButtonAlt: {
    backgroundColor: theme.palette.common.white
  },

  selectOpen: {
    '&::after': {
      transform: ' translate3d(-50%,-50%,0) rotate(180deg)'
    }
  },

  inputLabelRoot: {
    position: 'relative',
    paddingRight: '2px',
    paddingLeft: '15px',
    paddingBottom: '5px',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    transform: 'none',
    fontSize: theme.typography.body2.fontSize
  },

  menuItemPaperRoot: {
    position: 'relative',
    maxHeight: 425
  },

  menuItemPaper: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 0,
    marginTop: '-2px',
    boxShadow: 'none',
    minHeight: '0 !important',
    overflow: 'inherit'
  },

  menuItemRoot: {
    backgroundColor: theme.palette.grey[300],
    textIndent: '-1px',
    position: 'absolute',
    maxHeight: '300px',
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    borderRight: `2px solid ${theme.palette.grey[50]}`,
    borderBottom: `2px solid ${theme.palette.grey[50]}`,
    '&::-webkit-scrollbar': { width: '10px', backgroundColor: 'transparent' },
    '&::-webkit-scrollbar-track': { boxShadow: 'none' },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 50,
      backgroundColor: theme.palette.grey[400],
      minHeight: 70
    },
    padding: '0 !important',
    width: '100% !important',
    overflow: 'auto'
  },

  menuItemRootAlt: {
    backgroundColor: '#fff'
  },

  menuItem: {
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[50]
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.grey[50]} !important`
    },
    whiteSpace: 'nowrap',
    overflowWrap: 'anywhere'
  },

  inputLabelFocused: {
    color: `${theme.palette.grey[500]} !important`
  },
  validationMessage: {
    paddingLeft: theme.spacing(1),
    paddingTop: '6px',
    fontSize: '0.875rem'
  },
  validationErrorMessage: {
    color: theme.palette.error.main
  },
  validationSuccessMessage: {
    color: theme.palette.error.main
  },
  validationInfoMessage: {
    color: theme.palette.error.main
  },

  textFieldRoot: {
    backgroundColor: theme.palette.grey[300],
    paddingRight: 30,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    width: '100%'
  },
  notchedOutline: {
    border: 'none !important',
    borderRadius: 0
  },
  autoCompleteWrapper: {
    border: `2px solid ${theme.palette.grey[50]}`
  },
  input: {
    color: theme.palette.common.black,
    fontSize: 'inherit',
    height: 11,
    marginleft: '50px',
    textIndent: '4px'
  },
  inputDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },
  clearIndicatorDirty: {
    paddingRight: 10,
    color: `${theme.palette.grey[50]} !important`,
    '& span': {
      '& svg': {
        color: `${theme.palette.grey[50]} !important`
      }
    }
  },
  endAdornment: {
    display: 'flex',
    justifyContent: 'end'
  },
  popupIndicator: {
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    top: 0,
    height: '100%',
    width: 36,
    color: `${theme.palette.grey[100]} !important`,
    marginRight: -41,
    marginTop: -4,
    borderRadius: '0px !important',
    '& span': {
      '& svg': {
        color: `${theme.palette.grey[100]} !important`
      }
    },
    '&.MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none!important'
    }
  },
  dropDownList: {
    width: '100%',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'border-box',
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    letterSpacing: '0.00938em',
    '&:hover, &:focus': {
      backgroundColor: `${theme.palette.grey[50]}`
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.grey[100]} !important`
    },
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  autoCompletePopper: {
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    borderRight: `2px solid ${theme.palette.grey[50]}`,
    borderBottom: `2px solid ${theme.palette.grey[50]}`,
    boxShadow: 'none',
    minWidth: '150px !important',
    paddingRight: '2px',
    backgroundColor: theme.palette.grey[300],
    marginLeft: '-3px'
  },
  autoCompletePaper: {
    maxHeight: '40vh',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 0,
    marginTop: '-2px',
    boxShadow: 'none'
  },
  autoCompleteListbox: {
    margin: 0,
    padding: '8px 0',
    overflow: 'auto',
    listStyle: 'none',
    maxHeight: '40vh',
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 50,
      backgroundColor: theme.palette.grey[400],
      minHeight: 70
    }
  }
}));

export default Dropdown;

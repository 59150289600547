import { query } from 'constants/app';
import qs from 'qs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { isEmpty, omit } from 'lodash';
import { Organization, Division, Sort, PostDivision } from 'types';
import { PostOrg } from 'types/Organization';
import axios, { AxiosError, AxiosResponse } from '../utils/axiosProvider';
import { N2POrg } from 'types/N2POrg';

export interface PagedOrgs {
  orgs: Organization[];
  total: number;
}

export interface GetAllOrgDataParams {
  queryKey: [
    __0: string,
    andfilter: string,
    filter: string,
    sort: Sort,
    pageIndex: number,
    pageSize: number,
    userPermission: boolean
  ];
}

interface GetOrgParams {
  queryKey: [__0: string, orgId: string];
}

const getAllOrgMS = async () => {
  const { data } = await axios.get('/organizations/all');

  const orgs: Organization[] = data.data;
  return orgs;
};

const useGetAllOrgMS = (
  userPermission: boolean
): UseQueryResult<Organization[], AxiosError> => {
  return useQuery<Organization[], AxiosError>(
    [query.organizations],
    getAllOrgMS,
    {
      keepPreviousData: true,
      enabled: userPermission
    }
  );
};

/**
 * @param {GetAllOrgDataParams}
 * @returns {id: string, name: string}[]
 */
const getAllOrgData = async ({ queryKey }: GetAllOrgDataParams) => {
  const [, filter, pageIndex, pageSize] = queryKey;

  const { data }: AxiosResponse = await axios.get('/organizations/all', {
    params: {
      filter,
      sort: '|ASC',
      page: pageIndex,
      limit: pageSize
    }
  });
  const orgs: Organization[] = data.data;
  return orgs;
};

const useGetAllOrg = (
  filter: string,
  pageIndex: number,
  pageSize: number,
  userPermission: boolean
): UseQueryResult<Organization[], AxiosError> => {
  return useQuery<Organization[], AxiosError>(
    [query.organizations, filter, pageIndex, pageSize],
    getAllOrgData,
    {
      keepPreviousData: true,
      enabled: userPermission
    }
  );
};

const getOrgs = async ({ queryKey }: GetAllOrgDataParams) => {
  const [, filter, andFilter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [
          `name|cont|${filter}`,
          `status.name|cont|${filter}`,
          `primaryContact.firstName|cont|${filter}`,
          `primaryContact.email|cont|${filter}`
        ]
      }
    : {};

  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andFilter';
  const possibleStatusFilter = andFilter
    ? { [paramFilterKey]: `status.name|cont|${andFilter}` }
    : {};

  const { data } = await axios.get('/organizations', {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort: sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  const orgs: Organization[] = data.data;

  return {
    orgs,
    total: Number(data.total)
  };
};

const useOrgs = (
  andfilter: string,
  filter: string,
  sort: Sort,
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedOrgs, AxiosError> => {
  return useQuery<PagedOrgs, AxiosError>(
    [query.organizations, andfilter, filter, sort, pageIndex, pageSize],
    getOrgs,
    {
      keepPreviousData: true
    }
  );
};

const getAllOrgDataExceptAnOrg = async ({ queryKey }: GetAllOrgDataParams) => {
  const [, andFilter, pageIndex, pageSize] = queryKey;

  const { data }: AxiosResponse = await axios.get('/organizations/all', {
    params: {
      andfilter: [`name|!eq|${andFilter}`],
      sort: '|ASC',
      page: pageIndex,
      limit: pageSize
    }
  });
  const orgs: Organization[] = data.data;
  return orgs;
};

const useGetAllOrgExceptAnOrg = (
  andFilter: string,
  pageIndex: number,
  pageSize: number,
  userPermission: boolean
): UseQueryResult<Organization[], AxiosError> => {
  return useQuery<Organization[], AxiosError>(
    [query.organizations, andFilter, pageIndex, pageSize],
    getAllOrgDataExceptAnOrg,
    {
      keepPreviousData: true,
      enabled: userPermission
    }
  );
};

const getOrg = async ({ queryKey }: GetOrgParams): Promise<Organization> => {
  const [, orgId] = queryKey;
  const { data } = await axios.get(`/organizations/${orgId}`);
  return data as Organization;
};

const useOrg = (orgId: string): UseQueryResult<Organization, Error> => {
  return useQuery<Organization, AxiosError>([query.orgData, orgId], getOrg);
};

interface UpdateLogo {
  orgId: string;
  logo: File | null;
}

const uploadOrgLogo = async (mediaData: UpdateLogo): Promise<void> => {
  const { orgId } = mediaData;
  const formData = new FormData();
  let orgData: any;
  if (mediaData?.logo) {
    formData.append('logo', mediaData?.logo, mediaData?.logo?.name);
    orgData = await axios.patch(`/organizations/${orgId}/logo`, formData);
  }
  return orgData;
};

const useUploadOrgLogo = (): UseMutationResult<
  void,
  AxiosError,
  UpdateLogo
> => {
  const queryClient = useQueryClient();

  const mutatePostOrgLogo = useMutation<void, AxiosError, UpdateLogo>(
    uploadOrgLogo,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.orgData]);
      }
    }
  );
  return mutatePostOrgLogo;
};

interface UploadOrgData {
  orgId: string;
  file: File | null;
}

export interface UploadOrgDataResponse {
  success: boolean;
}

const uploadOrgData = async (
  mediaData: UploadOrgData
): Promise<UploadOrgDataResponse> => {
  const { orgId, file } = mediaData;
  const formData = new FormData();

  if (!file) {
    return { success: false };
  }
  formData.append('file', file);
  formData.append('organizationId', orgId);

  const orgData = await axios.post(`/data-import/master/import`, formData);
  return orgData.data;
};

const useUploadOrgData = (): UseMutationResult<
  UploadOrgDataResponse,
  AxiosError,
  UploadOrgData
> => {
  const queryClient = useQueryClient();

  const mutatePostOrgLogo = useMutation<
    UploadOrgDataResponse,
    AxiosError,
    UploadOrgData
  >(uploadOrgData, {
    onSettled: () => {
      queryClient.invalidateQueries([query.orgData]);
    }
  });
  return mutatePostOrgLogo;
};

export interface FetchUploadedOrgData {
  progress: number;
  totalRows: number;
  errorRows: number;
  successRows: number;
  errorReportUrl: string;
  successReportUrl: string;
  endDate: number;
}

const fetchImportResults = async (): Promise<FetchUploadedOrgData> => {
  const { data } = await axios.get('/data-import/master/latest');

  const orgData = {
    progress: data.progress,
    totalRows: data.totalRows || 0,
    errorRows: data.errorRows || 0,
    successRows: data.successRows || 0,
    errorReportUrl: data.errorReportUrl || '',
    successReportUrl: data.successReportUrl || '',
    endDate: data.finishedOn || 0
  };

  return orgData;
};

const useFetchImportResults = (): UseQueryResult<
  FetchUploadedOrgData,
  AxiosError
> => {
  const queryClient = useQueryClient();

  return useQuery<FetchUploadedOrgData, AxiosError>([], fetchImportResults, {
    onSettled: () => {
      queryClient.invalidateQueries([query.orgData]);
    }
  });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface deleteTempS3FilesData {
  reportUrls: string[];
}

const deleteImportedFiles = async (
  url: deleteTempS3FilesData
): Promise<void> => {
  await axios.delete('/data-import/master/deleteUploadedFiles', {
    data: url.reportUrls
  });
};

const useDeleteImportFiles = (): UseMutationResult<
  void,
  AxiosError,
  deleteTempS3FilesData
> => {
  const queryClient = useQueryClient();
  const mutatePostOrgLogo = useMutation<
    void,
    AxiosError,
    deleteTempS3FilesData
  >(deleteImportedFiles, {
    onSettled: () => {
      queryClient.invalidateQueries([query.orgData]);
    }
  });
  return mutatePostOrgLogo;
};

interface PostData {
  orgId: string;
  inviteUserError: string;
}

// TODO: Clean this up to be more succinct
const postOrg = async (createData: PostOrg): Promise<PostData> => {
  const {
    name,
    address,
    salesOwner,
    status,
    servicesOwner,
    installManager,
    primaryAuthRole,
    secondaryAuthRole,
    primaryContact,
    secondaryContact,
    installation,
    management,
    divisions,
    isNewFlow,
    bpCode,
    authMethod
  } = createData;

  const orgData = {
    name: name,
    address: address,
    salesOwner: {
      id: salesOwner?.id
    },
    servicesOwner: {
      id: servicesOwner?.id
    },
    installManager: {
      id: installManager?.id
    },
    primaryContact: {
      authRoleIds: [primaryAuthRole?.id],
      username: primaryContact?.email
    },
    secondaryContact: {
      authRoleIds: secondaryAuthRole?.id ? [secondaryAuthRole?.id] : null,
      username: secondaryContact?.email
    },
    installation,
    management,
    status: {
      id: status
    },
    divisions,
    isNewFlow,
    bpCode,
    authMethod
  };

  const { data } = await axios.post(`/organizations`, orgData);

  return {
    orgId: data?.organization?.id,
    inviteUserError: data?.inviteUserError
  };
};

const useCreateOrg = (): UseMutationResult<PostData, AxiosError, PostOrg> => {
  const queryClient = useQueryClient();

  const mutatePostOrg = useMutation<PostData, AxiosError, PostOrg>(postOrg, {
    onSettled: () => {
      queryClient.invalidateQueries([query.orgData]);
    }
  });
  return mutatePostOrg;
};

const patchOrg = async (org: PostOrg): Promise<void> => {
  const updatedOrg = omit(org, [
    'id',
    'locationStatusCount',
    'primaryAuthRole',
    'secondaryAuthRole'
  ]);

  await axios.patch(`/organizations/${org.id}`, updatedOrg);
};

const useUpdateOrg = (): UseMutationResult<void, AxiosError, PostOrg> => {
  const queryClient = useQueryClient();

  const mutatePatchOrg = useMutation<void, AxiosError, PostOrg>(patchOrg, {
    onSettled: () => {
      queryClient.invalidateQueries([query.orgData]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([query.organizations]);
    }
  });

  return mutatePatchOrg;
};

export interface GetDivisionParams {
  queryKey: [organizationId: string, id: string, divisionName: string];
}

const getDivision = async ({ queryKey }: GetOrgParams): Promise<Division[]> => {
  const [, orgId] = queryKey;
  const { data } = await axios.get(`/organizations/${orgId}/division`);
  return data?.data;
};

const useDivision = (
  organizationId: string
): UseQueryResult<Division[], AxiosError> => {
  return useQuery<Division[], AxiosError>(
    [query.division, organizationId],
    getDivision
  );
};

interface DivisionParams {
  orgId: string;
  division: Division[];
}
const patchDivision = async (divisions: DivisionParams): Promise<void> => {
  const div: PostDivision[] = [];
  divisions?.division?.map(async division => {
    const canonDivision = {
      divisionName: division?.divisionName
    };
    div.push(canonDivision);
  });
  const canonDivisions = { divisions: div };
  await axios.post(
    `/organizations/${divisions?.orgId}/divisions`,
    canonDivisions
  );
};

const useUpdateDivision = (): UseMutationResult<
  void,
  AxiosError,
  DivisionParams
> => {
  const queryClient = useQueryClient();

  const mutatePatchDivision = useMutation<void, AxiosError, DivisionParams>(
    patchDivision,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.division]);
      }
    }
  );
  return mutatePatchDivision;
};

const deleteOrg = async (id: string): Promise<void> => {
  await axios.delete(`/organizations/${id}`);
};

const useDeleteOrg = (id: string): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteOrg = useMutation<void, AxiosError, any>(deleteOrg, {
    onSettled: () => {
      queryClient.invalidateQueries([query.organizations]);
      queryClient.invalidateQueries([query.orgStatusCount]);
    }
  });

  return mutateDeleteOrg;
};

const deletedivision = async (divisions: Division[]): Promise<void> => {
  await axios.delete(`/division`, { data: divisions });
};

const useDeletedivision = (): UseMutationResult<
  void,
  AxiosError,
  Division[]
> => {
  const queryClient = useQueryClient();

  const mutateDeleteDivision = useMutation<void, AxiosError, Division[]>(
    deletedivision,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.division]);
      }
    }
  );

  return mutateDeleteDivision;
};

export interface OrgDelete {
  isDecommissioned: boolean;
  locations: number;
}

const getOrgDependency = async ({
  queryKey
}: GetOrgParams): Promise<OrgDelete> => {
  const [, orgId] = queryKey;
  const { data } = await axios.get(
    `/organizations/${orgId}/dependencies/count`
  );
  return data;
};

const useOrgDependency = (orgId: string): UseQueryResult<OrgDelete, Error> => {
  return useQuery<OrgDelete, AxiosError>(
    [query.orgDelete, orgId],
    getOrgDependency,
    {
      keepPreviousData: false,
      cacheTime: 1000
    }
  );
};

/* Additional APIS */

const getOrgNetProvider = async ({
  queryKey
}: GetOrgParams): Promise<N2POrg> => {
  const [, orgId] = queryKey;
  const { data } = await axios.get(`/organizations/${orgId}/provider`);

  return data;
};

const useOrgNetProvider = (
  orgId: string,
  enabled = true
): UseQueryResult<N2POrg, AxiosError> => {
  return useQuery<N2POrg, AxiosError>(
    [query.orgNetProvider, orgId],
    getOrgNetProvider,
    {
      keepPreviousData: false,
      cacheTime: 1000,
      enabled
    }
  );
};

const syncOrgNetProvider = async (orgId: string): Promise<void> => {
  await axios.patch(`/organizations/${orgId}/provider/sync`);
};

const useSyncOrgNetProvider = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  const queryClient = useQueryClient();

  const updateOrgNetProvider = useMutation<void, AxiosError, string>(
    syncOrgNetProvider,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.orgNetProvider]);
      }
    }
  );

  return updateOrgNetProvider;
};

interface PatchEnableNotificationOptInParams {
  id: string;
  enableNotificationOptIn: boolean;
}

const patchEnableNotificationOptIn = async (
  patchParams: PatchEnableNotificationOptInParams
): Promise<boolean> => {
  const { data } = await axios.patch(
    `/organizations/${patchParams.id}/enable-notifications`,
    {
      enable: patchParams.enableNotificationOptIn
    }
  );

  return data?.data?.enableNotificationOptIn;
};

const useEnableNotificationOptIn = (): UseMutationResult<
  any,
  AxiosError,
  PatchEnableNotificationOptInParams
> => {
  const queryClient = useQueryClient();

  const updateEnableNotificationOptIn = useMutation<
    any,
    AxiosError,
    PatchEnableNotificationOptInParams
  >(patchEnableNotificationOptIn, {
    onSettled: () => {
      queryClient.invalidateQueries([query.organizations]);
    }
  });

  return updateEnableNotificationOptIn;
};

const getOrgAuthMethods = async (): Promise<string[]> => {
  const { data } = await axios.get('/organizations/auth-methods');
  return data;
};

const useOrgAuthMethods = (): UseQueryResult<string[], Error> => {
  return useQuery<string[], AxiosError>(
    [query.orgAuthMethods],
    getOrgAuthMethods
  );
};

export {
  useGetAllOrg,
  useGetAllOrgMS,
  useOrgs,
  useGetAllOrgExceptAnOrg,
  useOrg,
  useUploadOrgLogo,
  useCreateOrg,
  useUpdateOrg,
  useDivision,
  useUpdateDivision,
  useDeleteOrg,
  useDeletedivision,
  getOrg,
  getDivision,
  getAllOrgData,
  useUploadOrgData,
  useOrgDependency,
  fetchImportResults,
  useFetchImportResults,
  useDeleteImportFiles,
  getOrgNetProvider,
  useOrgNetProvider,
  useSyncOrgNetProvider,
  useEnableNotificationOptIn,
  useOrgAuthMethods
};

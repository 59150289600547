import React from 'react';

import { useLocationNotes } from '../../../api/useLocationNotes';
import { Dialog, DialogCloseIcon, Text } from '../../../design';
import styles from './LocationNotesCard.module.scss';

interface MultiViewNotesModalProps {
  locationId: string;
  handleClose: () => void;
}

const dataConversionOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

const MultiViewNotesModal = ({
  locationId,
  handleClose
}: MultiViewNotesModalProps) => {
  const { data } = useLocationNotes(locationId, 1, 10000);
  const notes = data?.notes || [];

  return (
    <Dialog open handleClose={handleClose} size="xlarge">
      <div className={styles.modalHeaderWrapper}>
        <Text variant="h5">View Notes</Text>
        <DialogCloseIcon onReset={handleClose} />
      </div>
      <div>
        <div className="expand-dialog-wrapper">
          {notes.map((note, index) => {
            // Convert note.createdAt to a date object and format it
            const formattedDate = `Submitted ${new Intl.DateTimeFormat(
              'en-US',
              dataConversionOptions
            ).format(new Date(note.createdAt))}`;

            return (
              <div key={note.id}>
                <Text className={styles.marginBottom}>{formattedDate}</Text>
                <Text>{`${note.userName} - ${note.text}`}</Text>
                {index !== notes.length - 1 && (
                  <div className={styles.borderBreak} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};

export default MultiViewNotesModal;

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useContacts } from 'api/useContacts';
import PageHeader from 'app/components/PageHeader';
import {
  ColumnProps,
  SearchInput,
  TablePaginated,
  Text,
  Dialog as DesignDialog,
  Heading
} from 'design';
import useSort from 'hooks/useSort';
import { pageSizeOptions } from 'constants/app';
import { Contact } from 'types';
import CreateContact from 'app/components/CreateContact';
import TableAction from 'app/components/TableAction';
import { MenuList } from 'app/components/TableAction/TableAction';
import Delete from 'app/components/Delete';
import UpdateContact from 'app/components/UpdateContact';
import useCurrentUser from 'api/useCurrentUser';

/**
 *
 * Contacts Page
 *
 */

const ContactsPage = (): JSX.Element => {
  const { data: currentUser } = useCurrentUser();
  // CRUD Dialog state
  const [showDialog, setShowDialog] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [deleteName, setDeleteName] = React.useState('');
  const [editId, setEditId] = React.useState('');

  //TODO: Rethink how we setup search
  const [sort, setSort] = useSort();
  const [pageIndex, setPageIndex] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [searchInput, setSearchInput] = React.useState('');

  const { isLoading, data, error, refetch } = useContacts(
    filter,
    sort,
    pageIndex,
    pageSize
  );

  const { data: contacts, total } = data || { contacts: [], total: 0 };

  const menuActions = React.useMemo<MenuList<Contact>[]>(
    () => [
      {
        label: 'Edit',
        action: ({ id }: Contact) => {
          setEditId(id);
          setShowEdit(true);
        },
        isVisible: currentUser?.isOrgAdmin || currentUser?.isSuperAdmin
      },
      {
        label: 'Delete',
        action: (contact: Contact) => {
          setDeleteName(contact.name ?? 'contact');
          setDeleteId(contact.id);
          setShowDelete(true);
        },
        isVisible: currentUser?.isOrgAdmin || currentUser?.isSuperAdmin
      }
    ],
    [currentUser]
  );

  const columns = React.useMemo<ColumnProps[]>(
    () => [
      {
        accessor: 'id',
        label: 'id',
        hidden: true
      },
      {
        accessor: 'organization.name',
        label: 'Organization'
      },
      {
        accessor: 'firstName',
        label: 'First Name'
      },
      {
        accessor: 'lastName',
        label: 'Last Name'
      },
      {
        accessor: 'email',
        label: 'Email'
      },
      {
        accessor: 'phoneMobile',
        label: 'Phone Primary'
      },
      {
        accessor: 'phoneWork',
        label: 'Phone Secondary'
      },
      {
        accessor: 'locations',
        label: 'Locations',
        render: ({ locationContacts }: Contact) => (
          <Text>{locationContacts.length}</Text>
        )
      },
      {
        accessor: '',
        label: '',
        render: (contact: Contact) => (
          <>
            {(currentUser?.isOrgAdmin || currentUser?.isSuperAdmin) && (
              <TableAction row={contact} menu={menuActions} />
            )}
          </>
        )
      }
    ],
    [menuActions, currentUser]
  );

  if (error) {
    throw new Error('Error fetching contacts');
  }

  return (
    <>
      <Helmet>
        <title>Contacts</title>
        <meta
          name="description"
          content="Marketspark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className="page-header">
        <PageHeader title="Contacts" />
        <div className="page-header__options">
          <SearchInput
            className="page-header__search-input"
            value={searchInput}
            handleClear={() => {
              setFilter('');
              setSearchInput('');
              setPageIndex(1);
            }}
            onChange={setSearchInput}
            handleSearch={() => {
              setFilter(searchInput);
              setPageIndex(1);
            }}
          />
          <IconButton
            onClick={() => {
              setShowDialog(true);
            }}
            data-testid="addButton"
            className="page-header__options__add-button"
          >
            <AddCircleIcon color="primary" className="button-icon" />
          </IconButton>
        </div>
      </div>
      <div>
        <TablePaginated
          id="Contacts"
          rows={contacts ?? []}
          sort={sort}
          total={total}
          columns={columns}
          handlePageSize={setPageSize}
          handlePageNav={setPageIndex}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          handleSort={setSort}
          isLoading={isLoading}
        />
      </div>
      <DesignDialog
        open={showDialog}
        handleClose={() => {
          setShowDialog(false);
        }}
      >
        <div className="dialog-container">
          <Heading variant="h5">Add Contact</Heading>
          <CreateContact
            handleClose={() => {
              setShowDialog(false);
            }}
          />
        </div>
      </DesignDialog>
      <DesignDialog
        open={showDelete}
        handleClose={() => {
          setShowDelete(false);
        }}
      >
        <div className="dialog-container">
          <Heading variant="h5">Delete Contact</Heading>
          <Delete
            name={deleteName}
            entityType="contact"
            id={deleteId}
            deleteURL={`/contacts/${deleteId}`}
            dependencyURL={`/contacts/${deleteId}/deps`}
            handleClose={() => {
              setShowDelete(false);
            }}
            afterSubmit={() => {
              refetch();
            }}
          />
        </div>
      </DesignDialog>
      <DesignDialog
        open={showEdit}
        handleClose={() => {
          setShowEdit(false);
        }}
      >
        <div className="dialog-container">
          <Heading variant="h5">Edit Contact</Heading>
          <UpdateContact
            id={editId}
            handleClose={() => {
              setShowEdit(false);
            }}
            afterSubmit={() => {
              refetch();
            }}
          />
        </div>
      </DesignDialog>
    </>
  );
};

export default ContactsPage;

import { query } from 'constants/app';
import _ from 'lodash';
import qs from 'qs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { OrganizationUser, Sort } from 'types';
import { UpdateUser, User } from 'types/User';
import axios, { AxiosError } from 'utils/axiosProvider';
import { isEmpty } from 'lodash';
import { removeEmptyProps } from 'utils/helpers';
import { RoleName } from '../types/AuthRoles';

// TODO: we should unify & strongly type the useUsers methods so that its easy to
// invalidate caches, rendering data optimistically, as well as simplify the data
// being transformed in our form components. WAY TOO MUCH data transformation happening
// in different places. This hook talks with the API and canonization/de-canonization
// should be happening here for the UI components.

export interface PagedUsers {
  users: User[];
  total: number;
}

interface GetUsersParams {
  queryKey: [
    __0: string,
    filter: string,
    andFilter: RoleName,
    sort: Sort,
    pageIndex: number,
    pageSize: number
  ];
}

/**
 * Get Users.
 * Method has a default filter option to return only the active users
 * @param {GetUsersParams}
 * @returns {users, total}
 */
const getUsers = async ({ queryKey }: GetUsersParams): Promise<PagedUsers> => {
  const [, filter, andFilter, sort, pageIndex, pageSize] = queryKey;
  const possibleFilter = filter
    ? {
        filter: [
          `firstName|cont|${filter}`,
          `lastName|cont|${filter}`,
          `orgPosition|cont|${filter}`,
          `organization.name|cont|${filter}`,
          `username|cont|${filter}`,
          `phoneMobile|cont|${filter}`,
          `authRoles.name|cont|${filter}`
        ]
      }
    : {};

  const paramFilterKey = isEmpty(possibleFilter) ? 'filter' : 'andfilter';

  const possibleStatusFilter = andFilter
    ? {
        [paramFilterKey]:
          `${andFilter}` === 'Pending'
            ? `isPendingConfirm|eq|false`
            : `authRoles.name|eq|${andFilter}`
      }
    : {};
  /*
   ** TODO: Sort and filter by user role.
   ** It is required as ACs on MS-2375 but
   ** currently it is not possible due to issues on backend
   */
  const { data } = await axios.get(`/users`, {
    params: {
      ...possibleFilter,
      ...possibleStatusFilter,
      sort:
        sort[0] === 'role'
          ? `authRoles.name|${sort[1]}`
          : sort[0] === 'status'
          ? `isPendingConfirm|${sort[1]}`
          : sort.join('|'),
      page: pageIndex,
      limit: pageSize
    },
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });

  return {
    users: data.data,
    total: Number(data.total)
  };
};

/**
 * Wrapper method to get Users
 * @return {{users, total} || AxiosError}
 */
const useUsers = (
  filter: string,
  rolesFilter: string,
  sort: Sort,
  pageIndex: number,
  pageSize: number
): UseQueryResult<PagedUsers, AxiosError> => {
  return useQuery<PagedUsers, AxiosError>(
    [query.users, filter, rolesFilter, sort, pageIndex, pageSize],
    getUsers,
    {
      keepPreviousData: true
    }
  );
};

export interface GetUserParams {
  queryKey: [__0: string, id: string];
}

// More simply getUsers call with org

const getUsersByOrg = async ({
  queryKey
}: {
  queryKey: [__0: string, orgId: string];
}): Promise<User[]> => {
  const [, orgId] = queryKey;
  const { data } = await axios.get(`/users/org/${orgId}`);

  return data;
};

const useGetUsersByOrg = (
  orgId: string
): UseQueryResult<User[], AxiosError> => {
  return useQuery<User[], AxiosError>([query.users, orgId], getUsersByOrg);
};

/**
 * Get User.
 * @param {GetUserParams}
 * @returns {users}
 */
const getUser = async ({ queryKey }: GetUserParams): Promise<User> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/users/${id}`);

  return data;
};

/**
 * Wrapper method to get User
 * @return {User || AxiosError}
 */
const useGetUser = (id: string): UseQueryResult<User, AxiosError> => {
  return useQuery<User, AxiosError>([query.users, id], getUser);
};

export interface InviteUser {
  authRoleIds: string[];
  locationIds: string[];
  email: string;
  organizationId: string | undefined;
}

const useInviteUserEmailValidate = (): ((
  email: string[]
) => Promise<string[]>) => {
  return async (userEmail: string[]) => {
    const { data } = await axios.post(`users/byEmail`, {
      email: userEmail
    });
    return data;
  };
};

const inviteUser = async (createData: InviteUser[]): Promise<void> => {
  await axios.post(`users/invite`, createData);
};

/**
 * Wrapper method to create new User
 * @return AxiosError
 */
const useInviteUser = (): UseMutationResult<void, AxiosError, InviteUser[]> => {
  const queryClient = useQueryClient();

  const mutatePostUser = useMutation<void, AxiosError, InviteUser[]>(
    inviteUser,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.users]);
      }
    }
  );

  return mutatePostUser;
};

/**
 * Update a User through the CMDC api
 * @returns {Promise}
 * @param user
 */
const patchUser = async (user: Partial<UpdateUser>): Promise<void> => {
  const updatedUser = removeEmptyProps(_.omit(user, ['id']));
  await axios.patch(`/users/${user.id}`, updatedUser);
};

/**
 * Wrapper method to update User
 * @return {UseMutationResult}
 */
const useUpdateUser = (): UseMutationResult<
  void,
  AxiosError,
  Partial<UpdateUser>
> => {
  const queryClient = useQueryClient();

  const mutatePatchUser = useMutation<void, AxiosError, Partial<UpdateUser>>(
    patchUser,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.users]);
      }
    }
  );

  return mutatePatchUser;
};

interface ResetPassword {
  email: string;
  redirectUri: string;
}

const patchPasswordUser = async (data: ResetPassword): Promise<void> => {
  const canonUser = {
    email: data?.email,
    redirectUri: data?.redirectUri
  };
  await axios.post(`recovery_password`, canonUser);
};

/**
 * Wrapper method to update User password
 * @return {UseMutationResult}
 */
const useUpdatePasswordUser = (): UseMutationResult<
  void,
  AxiosError,
  ResetPassword
> => {
  const queryClient = useQueryClient();

  const mutatePatchUser = useMutation<void, AxiosError, ResetPassword>(
    patchPasswordUser,
    {
      onSettled: () => {
        queryClient.invalidateQueries([query.users]);
      }
    }
  );

  return mutatePatchUser;
};

// check user whether primary contact or secondary contact
/**
 * Get User.
 * @param {GetUserParams}
 * @returns {UserContactType}
 */

const getUserContactType = async ({
  queryKey
}: GetUserParams): Promise<OrganizationUser> => {
  const [, id] = queryKey;
  const { data } = await axios.get(`/users/${id}/contact/type`);

  return data;
};

const useUserContactType = (
  id: string
): UseQueryResult<OrganizationUser, AxiosError> => {
  return useQuery<OrganizationUser, AxiosError>(
    [query.userContactType, id],
    getUserContactType
  );
};

/**
 * hard delete user.
 * Set 'active' column to false
 * @param {id: string}
 */
const deleteUser = async (id: string): Promise<void> => {
  await axios.delete(`/users/${id}`);
};

const useDeleteUser = (
  id: string
): UseMutationResult<void, AxiosError, any> => {
  const queryClient = useQueryClient();

  const mutateDeleteUser = useMutation<void, AxiosError, any>(deleteUser, {
    onSettled: () => {
      queryClient.invalidateQueries([query.users]);
    }
  });

  return mutateDeleteUser;
};

/**
 * Checks to see if a users exists asyncronously. Built specifically without react-query
 * this method is best used in conjunction with the yup validation & debouncer yup helper (utils/yupHelpers).
 * @param {string} userId
 * @returns {boolean}
 */
const userExists = async (userEmail: string) => {
  const { data } = await axios.post(`users/byEmail`, {
    email: userEmail
  });
  return data.length > 0;
};

const getMarketSparkUsers = async (): Promise<User[]> => {
  const { data } = await axios.get('/users/marketspark');
  return data;
};

const useGetMarketSparkUsers = (): UseQueryResult<User[], AxiosError> => {
  return useQuery<User[], AxiosError>([query.users], getMarketSparkUsers, {
    keepPreviousData: true
  });
};

export {
  useUpdateUser,
  useGetUser,
  useGetUsersByOrg,
  userExists,
  useUpdatePasswordUser,
  useInviteUser,
  useDeleteUser,
  useGetMarketSparkUsers,
  useUserContactType,
  useInviteUserEmailValidate
};

export default useUsers;

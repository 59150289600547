import React from 'react';

import DashboardStatisticCardTemplate, {
  CardType
} from '../DashboardStatisticCardTemplate';
import { ReactComponent as AllCallsLocationIcon } from 'assets/img/IconAllCallsLocations.svg';
import { ReactComponent as InboundCallsLocationIcon } from 'assets/img/IconInboundCallsLocations.svg';
import { ReactComponent as OutboundCallsLocationIcon } from 'assets/img/IconOutboundCallsLocations.svg';

interface TopLocationsCardProps {
  id: string;
}

const TopLocationsCard = ({ id }: TopLocationsCardProps): JSX.Element => {
  return (
    <DashboardStatisticCardTemplate
      id={id}
      type={CardType.Locations}
      title="Top Locations"
      group="locationId"
      IconAll={AllCallsLocationIcon}
      IconInbound={InboundCallsLocationIcon}
      IconOutbound={OutboundCallsLocationIcon}
    />
  );
};

export default TopLocationsCard;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { CardContent, makeStyles, Theme } from '@material-ui/core';
import PencilIcon from '@material-ui/icons/Edit';

import { Card, CardHeader, Button } from 'design';
import { useDashboardSlice } from 'app/layouts';
import { selectCurrentUser } from '../../../store/selectors';
import UserCardContent from 'app/components/UserCardContent';
import PageHeader from 'app/components/PageHeader';
import { USER_CREDENTIALS } from '../../../constants/app';

const EditProfilePage = (): JSX.Element => {
  const user = useSelector(selectCurrentUser);
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const cc = useStyles();

  const handleProfileAction = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        size: 'xlarge',
        title: 'Edit Profile',
        lazyComponent: 'EditProfile',
        lazyComponentProps: {
          userData: user
        }
      })
    );
  }, [user, actions, dispatch]);

  const handlePasswordAction = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        lazyComponent: 'EditPassword',
        lazyComponentProps: {
          email: user?.username,
          updateSelf: true
        }
      })
    );
  }, [actions, dispatch, user?.username]);

  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta
          name="description"
          content="MarketSpark - Command Center"
          data-rh="true"
        />
      </Helmet>
      <div className={cc.headingWrapper}>
        <PageHeader title="Profile" />
      </div>
      <Card>
        <CardHeader
          title="Profile"
          actionIcon={<PencilIcon color="primary" />}
          handleAction={handleProfileAction}
        />
        <CardContent>
          {user ? <UserCardContent userData={user} /> : <>loading...</>}
          {user.organization.authMethod === USER_CREDENTIALS && !user.isMSOrg && (
            <div className={cc.buttonWrapper}>
              <Button
                className={cc.changePasswordButton}
                onClick={handlePasswordAction}
              >
                CHANGE PASSWORD
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headingWrapper: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > h4': {
      flexGrow: 1
    }
  },
  buttonWrapper: {
    marginTop: theme.spacing(5),
    textAlign: 'center'
  },
  logoutButton: {
    height: '30px',
    margin: theme.spacing(3, 0, 6)
  },
  changePasswordButton: {
    width: 'auto !important'
  }
}));

export default EditProfilePage;

import React from 'react';
import { DropdownProps } from 'design';
import { RegisterProps } from 'design/Form/Form';
import Dropdown from './Dropdown';

interface Props
  extends Omit<DropdownProps, 'onChange' | 'value' | 'name' | 'isError'> {
  formState: RegisterProps;
  onChange?: (event: any) => void;
}
export default function FormDropdown(props: Props): React.ReactElement {
  const {
    id,
    placeholder,
    label,
    dropdownItems,
    disabled,
    onChange,
    formState,
    onBlur
  } = props;

  const {
    value,
    name,
    validationMessage,
    isError,
    onChange: formOnChange,
    onBlur: formOnBlur
  } = formState;

  const handleChange = onChange || formOnChange;
  const handOnBlur = onBlur || formOnBlur;

  return (
    <Dropdown
      name={name}
      id={id}
      placeholder={placeholder}
      label={label}
      dropdownItems={dropdownItems}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onBlur={handOnBlur}
    />
  );
}

import * as React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface CardGridProps {
  children: React.ReactNode;
}

const CardGrid = ({ children }: CardGridProps): JSX.Element => {
  const cc = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignContent="stretch"
      wrap="wrap"
      className={cc.cardGrid}
    >
      {children}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardGrid: {
    gap: theme.spacing(3)
  }
}));

export default CardGrid;

import { TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import StopSharpIcon from '@material-ui/icons/StopSharp';
import { Autocomplete } from '@material-ui/lab';
import cx from 'clsx';
import { DropdownOption } from 'design/Dropdown';
import React from 'react';

export interface MultiSelectProps {
  id: string;
  name: string;
  disabled?: boolean;
  value: any;
  label: string;
  isError: boolean;
  onChange: (event: any) => void;
  onBlur?: (e: any) => void;
  validationMessage?: string | string[];
  validationType?: 'info' | 'success';
  dropdownItems: DropdownOption[];
  defaultListHeader?: string;
  className?: string;
  style?: Record<string, unknown>;
  placeholder: string;
  isLoading: boolean;
  showSelectAll?: boolean;
}
const MultiSelect = ({
  id,
  name,
  disabled,
  value,
  label,
  isError,
  onChange,
  onBlur,
  validationMessage,
  validationType,
  dropdownItems,
  className,
  style,
  placeholder,
  isLoading,
  showSelectAll = false
}: MultiSelectProps) => {
  const cc = useStyles();
  const customStyle = style;
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<DropdownOption[]>([]);

  const validationMessageClass = cx(cc.validationMessage, {
    [cc.validationInfoMessage]: validationType === 'info',
    [cc.validationSuccessMessage]: validationType === 'success',
    [cc.validationErrorMessage]: isError
  });

  React.useEffect(() => {
    const selectedData: DropdownOption[] = [];
    if (value.length) {
      value?.forEach((item: any) => {
        dropdownItems.forEach(dropdownItem => {
          if (item === dropdownItem?.value) {
            selectedData.push(dropdownItem);
          }
        });
      });
      setInputValue(selectedData);
    } else {
      setInputValue([]);
    }
  }, [value, dropdownItems]);

  const selectAllOption: DropdownOption = { label: 'Select All', value: 'all' };

  const handleChange = (e: any, val: DropdownOption[]) => {
    if (showSelectAll && val.some(v => v.value === 'all')) {
      if (val.length - 1 === dropdownItems.length) {
        onChange([]);
        return;
      }
      const allItems = dropdownItems.map(item => item.value);
      onChange(allItems);
      return;
    }
    const values = val.map(item => item.value);
    onChange(values);
  };

  return (
    <div>
      <FormControl
        classes={{ root: cc.formControlRoot }}
        disabled={disabled}
        className={className}
        style={customStyle}
        fullWidth
      >
        <InputLabel
          classes={{
            root: cc.inputLabelRoot,
            focused: cc.inputLabelFocused,
            disabled: cc.selectDisabled
          }}
          disableAnimation
          shrink
          aria-label={name}
        >
          {label}
        </InputLabel>
        <Autocomplete
          id={id}
          multiple
          data-testid="autocomplete"
          defaultValue={inputValue}
          disabled={disabled}
          clearOnBlur={false}
          classes={{
            input: disabled ? cc.inputDisabled : cc.input,
            clearIndicatorDirty: cc.clearIndicatorDirty,
            popupIndicator: cc.popupIndicator,
            option: cc.dropDownList,
            popper: cc.autoCompletePopper,
            paper: cc.paper
          }}
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          disableCloseOnSelect
          disableClearable
          options={
            showSelectAll && dropdownItems.length
              ? [selectAllOption, ...dropdownItems]
              : dropdownItems
          }
          value={inputValue}
          onChange={handleChange}
          ListboxProps={{ className: cc.autoCompleteListbox }}
          disableListWrap
          getOptionLabel={options => options.label ?? ''}
          getOptionSelected={(option, newVal) => option.value === newVal.value}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                className={cc.checkBox}
                color="secondary"
                icon={<CheckBoxOutlineBlankSharpIcon />}
                checkedIcon={
                  <>
                    <CheckBoxOutlineBlankSharpIcon
                      className={cc.checkboxOuter}
                      style={{ color: '#AD4EFF' }}
                    />
                    <StopSharpIcon
                      className={cc.checkboxInner}
                      style={{ color: '#AD4EFF' }}
                    />
                  </>
                }
                checked={
                  option.value === 'all'
                    ? inputValue.length === dropdownItems.length
                    : selected
                }
              />
              {option.label}
            </>
          )}
          renderTags={selected => {
            const renderTagsValue = selected
              .map(function (elem) {
                return elem.label;
              })
              .join(', ');
            return (
              <Typography
                noWrap={true}
                color="textPrimary"
                className={cc.fieldText}
              >
                {isLoading ? 'Loading...' : renderTagsValue}
              </Typography>
            );
          }}
          renderInput={params => (
            <TextField
              data-testid="autocompleteTextField"
              classes={{
                root: disabled ? cc.textFieldRootDisabled : cc.textFieldRoot
              }}
              // eslint-disable-next-line
              {...params}
              size="small"
              id={id}
              name={id}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                classes: {
                  notchedOutline: cc.notchedOutline
                }
              }}
              onBlur={onBlur}
              placeholder={!value.length ? placeholder : undefined}
              value={inputValue}
            />
          )}
        />
      </FormControl>
      <div className={validationMessageClass}>
        {isError && validationMessage ? validationMessage : <>&nbsp;</>}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formControlRoot: {
    minWidth: '70px',
    marginBottom: '1px'
  },
  selectDisabled: {
    color: `${theme.palette.grey[500]} !important`
  },
  paper: {
    boxShadow: 'none'
  },
  validationMessage: {
    paddingLeft: theme.spacing(1),
    paddingTop: '6px',
    fontSize: '0.875rem'
  },
  validationErrorMessage: {
    color: theme.palette.error.main
  },
  validationSuccessMessage: {
    color: theme.palette.error.main
  },
  validationInfoMessage: {
    color: theme.palette.error.main
  },
  root: {},
  inputLabelRoot: {
    position: 'relative',
    paddingRight: '2px',
    paddingLeft: '15px',
    paddingBottom: '5px',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    transform: 'none',
    fontSize: theme.typography.body2.fontSize
  },

  inputLabelFocused: {
    color: `${theme.palette.grey[500]} !important`
  },
  textFieldRoot: {
    backgroundColor: theme.palette.grey[300],
    paddingRight: 30,
    border: `2px solid ${theme.palette.grey[50]}`,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap !important'
    }
  },
  textFieldRootDisabled: {
    paddingRight: 22,
    backgroundColor: theme.palette.grey[300],
    border: `2px solid ${theme.palette.grey[50]}`,
    '&:focus, &:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap !important'
    }
  },
  notchedOutline: {
    border: 'none !important'
  },
  input: {
    color: theme.palette.common.black,
    fontSize: 'inherit',
    height: 11,
    marginleft: '50px',
    textIndent: '4px',
    '& .MuiAutocomplete-hasPopupIcon': {
      paddingRight: '50px'
    },
    '& .MuiAutocomplete-hasClearIcon': {
      paddingRight: '50px'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '50px'
    }
  },
  inputDisabled: {
    color: `${theme.palette.grey[500]} !important`,
    fontSize: 'inherit',
    height: 11,
    marginleft: '50px',
    textIndent: '4px',
    '& .MuiAutocomplete-hasPopupIcon': {
      paddingRight: '50px'
    },
    '& .MuiAutocomplete-hasClearIcon': {
      paddingRight: '50px'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '50px'
    }
  },
  clearIndicatorDirty: {
    paddingRight: 10,
    color: `${theme.palette.grey[50]} !important`,
    '& span': {
      '& svg': {
        color: `${theme.palette.grey[50]} !important`
      }
    }
  },
  popupIndicator: {
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    borderRight: `2px solid ${theme.palette.grey[50]}`,
    top: 0,
    height: '100%',
    width: 36,
    color: `${theme.palette.grey[100]} !important`,
    left: 38.5,
    marginTop: -4,
    borderRadius: '0px !important',
    '& span': {
      '& svg': {
        color: `${theme.palette.grey[100]} !important`
      }
    }
  },
  dropDownList: {
    width: '100%',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'border-box',
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    letterSpacing: '0.00938em',
    '&:hover, &:focus': {
      backgroundColor: `${theme.palette.grey[50]}`
    },
    '&.Mui-selected': {
      backgroundColor: `${theme.palette.grey[100]} !important`
    },
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  autoCompletePopper: {
    borderLeft: `2px solid ${theme.palette.grey[50]}`,
    borderRight: `2px solid ${theme.palette.grey[50]}`,
    borderBottom: `2px solid ${theme.palette.grey[50]}`,
    boxShadow: 'none',
    minWidth: '150px !important',
    paddingRight: '2px',
    backgroundColor: theme.palette.grey[300],
    marginLeft: '-3px'
  },
  autoCompletePaper: {
    maxHeight: '40vh',
    backgroundColor: theme.palette.grey[300],
    borderRadius: 0,
    marginTop: '-2px',
    boxShadow: 'none !important'
  },
  autoCompleteListbox: {
    margin: 0,
    padding: '8px 0',
    overflow: 'auto',
    listStyle: 'none',
    maxHeight: '40vh',
    boxShadow: 'none !important',
    backgroundColor: theme.palette.grey[300],
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none !important'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 50,
      backgroundColor: theme.palette.grey[50],
      minHeight: 70
    }
  },
  checkBox: {
    padding: '0 5px 0 0',
    '& .MuiSvgIcon-root': {
      color: '#A9B3B7'
    }
  },
  checkboxOuter: {
    position: 'relative',
    color: theme.palette.grey[50]
  },
  checkboxInner: {
    position: 'absolute',
    left: '0px',
    color: '#AD4EFF'
  },
  fieldText: {
    minWidth: '1%',
    maxWidth: '85%',
    paddingLeft: '10px'
  }
}));

export default MultiSelect;

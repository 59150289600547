import { TextInput } from 'design';
import React from 'react';
import { EquipmentAndHardwareFieldInputProps } from '../types';

const UsernameFieldInput = ({
  name,
  value,
  validationMessage,
  isError,
  onChange,
  onBlur
}: EquipmentAndHardwareFieldInputProps) => {
  return (
    <TextInput
      id="username"
      label="User Name"
      name={name}
      value={value}
      validationMessage={validationMessage}
      isError={isError}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default UsernameFieldInput;

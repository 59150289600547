import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';
import { useOrg } from 'api/useOrgs';
import { useDashboardSlice } from 'app/layouts';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  OrgAvatar
} from 'design';
import { MatuiDialog } from 'design/Dialog/Dialog';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import OrgAddLogo from '../OrgAddLogo/OrgAddLogo';
// TODO: Refactor to use current user from current user store
import useCurrentUser from 'api/useCurrentUser';

const OrgOverviewCard = (): JSX.Element => {
  const cc = useStyles();
  const [open, setOpen] = React.useState(false);
  const [onHover, setOnHover] = React.useState(false);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const { id: orgId } = useParams<{ id: string }>();
  const { data: orgData } = useOrg(orgId);
  const { data: currentUser } = useCurrentUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardHeaderAction = React.useCallback(() => {
    dispatch(
      actions.showDialog({
        open: true,
        title: 'Add Organization Logo',
        lazyComponent: 'OrgAddLogo',
        lazyComponentProps: {
          orgId
        }
      })
    );
  }, [actions, dispatch, orgId]);

  const handleUpdateOrg = React.useCallback(
    (id: string) => {
      dispatch(
        actions.showDialog({
          open: true,
          title: 'Edit Organization',
          lazyComponent: 'OrganizationUpdate',
          size: 'xlarge',
          lazyComponentProps: {
            id,
            currentUser
          }
        })
      );
    },
    [actions, dispatch, currentUser]
  );

  return (
    <>
      <Card>
        <CardHeader title="Overview" />
        <CardContent noOverflow>
          <div className={cc.contentWrapper}>
            {orgData?.logo ? (
              <Box
                className={cc.avatarWrap}
                onMouseLeave={() => setOnHover(false)}
                onMouseOver={() => setOnHover(true)}
              >
                <OrgAvatar
                  src={orgData?.logo?.url}
                  name={orgData?.name}
                  className={cc.orgAvatar}
                  onClick={handleCardHeaderAction}
                />
                <Box
                  onClick={handleCardHeaderAction}
                  className={onHover ? cc.avatarOnHover : 'none'}
                >
                  <AddPhotoAlternate
                    className={onHover ? cc.avatarOnHover : cc.hide}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                onMouseLeave={() => setOnHover(false)}
                onMouseOver={() => setOnHover(true)}
                onClick={handleCardHeaderAction}
                className={cc.avatar}
              >
                <AddPhotoAlternate
                  className={onHover ? cc.avatarIconHover : cc.avatarIcon}
                />
              </Box>
            )}
          </div>
          <MatuiDialog open={open} onClose={handleClose}>
            <OrgAddLogo orgId={orgId} />
          </MatuiDialog>
          <Typography className={cc.orgName}>{orgData?.name}</Typography>
          <Button
            isLoading={false}
            className={cc.ddButton}
            onClick={() => handleUpdateOrg(orgId)}
          >
            Edit
          </Button>
        </CardContent>
        <CardFooter>{}</CardFooter>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    marginTop: 17,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ddButton: {
    marginTop: theme.spacing(2.5),
    display: 'block',
    margin: 'auto'
  },
  orgAvatar: {
    width: 'auto !important',
    height: 'auto !important',
    maxWidth: '70px',
    maxHeight: '70px',
    margin: 'auto'
  },
  avatar: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    margin: 'auto',
    borderColor: '#D9E4EA',
    borderStyle: 'solid',
    border: '2px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: '#AD4EFF'
    },
    '&:hover .avatarIcon': {
      color: '#000 !important'
    }
  },
  avatarIcon: {
    color: '#A9B3B7'
  },
  avatarIconHover: {
    color: '#000'
  },
  orgName: {
    textAlign: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 400,
    fontSize: '13PX'
  },
  orgAddress: {
    textAlign: 'center',
    marginBottom: '15px',
    fontSize: '14PX',
    color: '#B3BBC0'
  },
  avatarWrap: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    borderColor: '#D9E4EA',
    borderStyle: 'solid',
    border: '2px',
    padding: '5px',
    backgroundColor: '#fff',
    position: 'relative'
  },
  avatarOnHover: {
    backgroundColor: '#000',
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    margin: 'auto',
    borderColor: '#AD4EFF',
    borderStyle: 'solid',
    border: '2px',
    position: 'absolute',
    top: '0px',
    right: '5px',
    bottom: '0px',
    left: '-2px',
    opacity: '0.7',
    padding: '20px',
    color: '#fff'
  },
  hide: {
    display: 'none'
  }
}));

export default OrgOverviewCard;

import {
  BarChart as BarChartMark,
  CodeOutlined,
  ContactPhone,
  Edit,
  Room as LocationMark
} from '@material-ui/icons';
import ContactsIcon from '@material-ui/icons/Contacts';

import { Dashboard } from 'app/layouts';
import CdrPage from 'app/pages/CdrPage';
import DashboardPage from 'app/pages/DashboardPage';
import EditProfilePage from 'app/pages/EditProfilePage';
import LocationLinesPage from 'app/pages/LocationLinesPage';
import LocationPage from 'app/pages/LocationPage';
import LocationsPage from 'app/pages/LocationsPage';
import NotFoundPage from 'app/pages/NotFoundPage/Loadable';
import OrganizationPage from 'app/pages/OrganizationPage';
import OrganizationsPage from 'app/pages/OrganizationsPage';
import ToolsPage from 'app/pages/Tools';
import UsersPage from 'app/pages/UsersPage';
import BundlesPage from 'app/pages/BundlesPage/index';
import RoutersPage from 'app/pages/RoutersPage';
import GatewaysPage from 'app/pages/GatewaysPage';
import { selectAuth } from 'app/slice/selectors';
import {
  dashboardUri,
  DECOMMISSION,
  permissions as APP_PERMISSIONS
} from 'constants/app';
import { ListLinkProps } from 'design/SideBar/ListLink';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation
} from 'react-router-dom';
import { ReactComponent as Hub } from '../../assets/img/hub.svg';
import { ReactComponent as Hardware } from '../../assets/img/hardware.svg';
import ManageLines from 'app/components/ManageLines';

import NotificationSubscriptionsPage from 'app/pages/NotificationSubscriptionsPage/NotificationSubscriptionsPage';
import { ErrorBoundary } from 'app/components/ErrorBoundary';

import ManageSims from 'app/components/ManageSims/ManageSims';
import DecommissionDisclosures from 'app/pages/DecommissionDisclosures';
import ProtectedRoute from './ProtectedRoute';
import ContactsPage from 'app/pages/ContactsPage';
import DecommissionMap from 'app/pages/DecommissionPage/DecommissionMap';
import PowerSourcesPage from 'app/pages/PowersourcesPage/PowersourcesPage';
import { selectCurrentUser } from 'store/selectors';
import { RoleName } from '../../types/AuthRoles';

export interface DashRoute extends RouteProps, ListLinkProps {
  userCan: string;
  hidden?: boolean;
  disabledForRoles?: string[];
}

export const HubIcon = (): JSX.Element => {
  return <Hub />;
};

export const HardwareIcon = (): JSX.Element => {
  return <Hardware />;
};

export const dashboardRoutes: DashRoute[] = [
  {
    label: 'Dashboard',
    to: `${dashboardUri}`,
    Icon: BarChartMark,
    userCan: APP_PERMISSIONS.accounts.read,
    component: DashboardPage,
    exact: true
  },
  {
    label: 'Overview',
    to: `${dashboardUri}`,
    Icon: HubIcon,
    userCan: APP_PERMISSIONS.accounts.read,
    exact: true
  },
  {
    label: 'Organizations',
    to: `/organizations`,
    Icon: HubIcon,
    userCan: APP_PERMISSIONS.accounts.read,
    component: OrganizationsPage,
    exact: true,
    hidden: true
  },
  {
    label: 'Organization',
    to: `/organization/:id`,
    Icon: HubIcon,
    userCan: APP_PERMISSIONS.accounts.read,
    component: OrganizationPage,
    exact: true,
    hidden: true,
    disabledForRoles: [RoleName.installPartner]
  },
  {
    label: 'Notifications',
    to: `/notifications/:tab`,
    Icon: HubIcon,
    userCan: APP_PERMISSIONS.accounts.read,
    component: NotificationSubscriptionsPage,
    exact: true,
    hidden: true
  },
  {
    label: 'Locations',
    to: `/locations`,
    Icon: LocationMark,
    userCan: APP_PERMISSIONS.accounts.read,
    component: LocationsPage,
    exact: true,
    hidden: true
  },
  {
    label: 'Location Section',
    to: `/:orgId/location/:locId/section/:tab`,
    Icon: Edit,
    userCan: APP_PERMISSIONS.accounts.read,
    component: LocationPage,
    exact: true,
    hidden: true
  },
  {
    label: 'Lines',
    to: `/lines`,
    Icon: LocationMark, // NOTE: change?
    userCan: APP_PERMISSIONS.accounts.read,
    component: LocationLinesPage, // NOTE: Create a lines page
    exact: true,
    hidden: true
  },
  {
    label: 'Users',
    to: `/users`,
    Icon: ContactsIcon,
    userCan: APP_PERMISSIONS.users.read,
    component: UsersPage,
    exact: true
  },
  {
    label: 'ManageLines',
    to: `/location/:pathName/:id/managelines`,
    Icon: LocationMark,
    userCan: APP_PERMISSIONS.accounts.create,
    component: ManageLines,
    exact: true,
    hidden: true
  },
  {
    label: 'ManageSims',
    Icon: LocationMark,
    to: '/location/:pathName(equipment|router)/:id/manage-sims',
    userCan: APP_PERMISSIONS.accounts.create,
    component: ManageSims,
    exact: true,
    hidden: true
  },
  {
    label: 'Tools',
    to: '/tools',
    Icon: CodeOutlined,
    userCan: APP_PERMISSIONS.accounts.create,
    component: ToolsPage,
    exact: true
  },
  {
    label: 'Hardware',
    to: '/bundles',
    Icon: HardwareIcon,
    userCan: APP_PERMISSIONS.users.scope,
    component: BundlesPage
  },
  {
    label: 'Bundles',
    to: '/bundles',
    Icon: CodeOutlined,
    userCan: APP_PERMISSIONS.users.scope,
    component: BundlesPage,
    hidden: true
  },
  {
    label: 'Routers',
    to: '/routers',
    Icon: CodeOutlined,
    userCan: APP_PERMISSIONS.users.scope,
    component: RoutersPage,
    hidden: true
  },
  {
    label: 'Gateways',
    to: '/gateways',
    Icon: CodeOutlined,
    userCan: APP_PERMISSIONS.users.scope,
    component: GatewaysPage,
    hidden: true
  },
  {
    label: 'Power Sources',
    to: '/power-sources',
    Icon: CodeOutlined,
    userCan: APP_PERMISSIONS.users.scope,
    component: PowerSourcesPage,
    hidden: true
  },
  {
    label: 'Line Logs',
    to: `/location/:id/cdr/:phoneId`,
    Icon: Edit,
    userCan: APP_PERMISSIONS.accounts.read,
    component: CdrPage,
    exact: true,
    hidden: true
  },
  {
    label: DECOMMISSION,
    to: `/decommission`,
    Icon: Edit,
    userCan: APP_PERMISSIONS.decommission.create,
    component: DecommissionMap,
    exact: true
  },
  {
    label: 'Contacts',
    to: '/contacts',
    Icon: ContactPhone,
    userCan: APP_PERMISSIONS.accounts.create,
    component: ContactsPage
  },
  {
    label: 'Upload disclosures',
    to: `/decommission/disclosures`,
    Icon: Edit,
    userCan: APP_PERMISSIONS.decommission.create,
    component: DecommissionDisclosures,
    exact: true,
    hidden: true
  }
];

/**
 * This is the main application router after authentication is successful
 * Routes are rendered based on RBAC as well as the dashboardRoutes constant
 * above. The dashboard layout wraps the router.
 * @returns {JSX.Element}
 */
const DashboardRouter = (): JSX.Element => {
  const location = useLocation();
  const { permissions } = useSelector(selectAuth);
  const user = useSelector(selectCurrentUser);

  const permittedRoutes = dashboardRoutes.filter(
    dashRoute => permissions.indexOf(dashRoute.userCan) > -1
  );
  // Hide routes from being rendered by the Sidebar
  const sidebarRoutes = permittedRoutes.filter(dashRoute => !dashRoute.hidden);
  const decomRedirect =
    user.primaryRole.name === RoleName.salesPartner
      ? '/decommission'
      : '/dashboard';

  return (
    <Dashboard routes={sidebarRoutes}>
      <ErrorBoundary key={location.pathname}>
        <Switch>
          <ProtectedRoute
            key="DashboardPage"
            exact
            path={dashboardUri}
            component={DashboardPage}
            hasPermission={user.primaryRole.name !== RoleName.salesPartner}
            redirectRoute={decomRedirect}
          />
          {dashboardRoutes
            .filter(
              route =>
                !route.disabledForRoles?.includes(
                  user.primaryRole.name as string
                )
            )
            .map(route => (
              <ProtectedRoute
                key={`${route.label}`}
                exact={route.exact}
                path={route.to}
                component={route.component}
                hasPermission={permissions.indexOf(route.userCan) > -1}
                redirectRoute={decomRedirect}
              />
            ))}
          <ProtectedRoute
            key="EditProfilePage"
            exact
            path={`/me`}
            component={EditProfilePage}
            hasPermission={
              permissions.indexOf(APP_PERMISSIONS.users.update) > -1
            }
            redirectRoute={decomRedirect}
          />
          <ProtectedRoute
            key="LocationPage"
            exact
            path={`/location/:id`}
            component={LocationPage}
            hasPermission={
              permissions.indexOf(APP_PERMISSIONS.accounts.read) > -1
            }
            redirectRoute={decomRedirect}
          />
          {/* <Route
            key="decommissionProspectPage"
            exact
            path="/public/decommission/businessName/:businessName"
            component={DecommissionProspectPage}
          /> */}

          <Redirect from="/" to={decomRedirect} />

          <Route key="NotFoundPage" component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </Dashboard>
  );
};

export default DashboardRouter;

import SvgIcon from '@material-ui/core/SvgIcon';
import { DECOMMISSION, Hardware, Overview } from 'constants/app';
import React from 'react';
import ListLink from './ListLink';
import SideBarPopperMenu from './SideBarPopperMenu';

export interface ListLinkProps {
  label: React.ReactNode;
  to: string;
  Icon: typeof SvgIcon;
  classes?: any;
}

const ListSideBarMenu = ({
  label,
  to,
  Icon,
  classes
}: ListLinkProps): JSX.Element => {
  return (
    <>
      {label === Overview || label === Hardware || label === DECOMMISSION ? (
        <SideBarPopperMenu label={label} Icon={Icon} classes={classes} />
      ) : (
        <ListLink label={label} to={to} Icon={Icon} classes={classes} />
      )}
    </>
  );
};

export default ListSideBarMenu;

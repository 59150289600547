import * as React from 'react';
import * as MuiCardActions from '@material-ui/core/CardActions';
import { Theme, makeStyles } from '@material-ui/core/styles';

export interface CardHeaderProps extends MuiCardActions.CardActionsProps {
  children: React.ReactNode;
}

const CardActions = MuiCardActions.default;

const CardFooter = ({ children }: CardHeaderProps): JSX.Element => {
  const cc = useStyles();
  return (
    <CardActions
      classes={{
        root: cc.root
      }}
    >
      {children}
    </CardActions>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `2px solid ${theme.palette.grey['50']}`,
    paddingTop: 8.5,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 1.5, 1.5),
    bottom: 0,
    flexGrow: 0
  }
}));

export default CardFooter;

import { Grid } from '@material-ui/core';
import PageHeader from 'app/components/PageHeader';
import { CardGrid } from 'design';
import React from 'react';
import UpdateDisclosureStaticMap from './components/UpdateDisclosureStaticMap';
import UploadNewDisclosures from './components/UploadNewDisclosures';

import styles from './decommissionDisclosures.module.scss';

export default function DecommissionDisclosures() {
  return (
    <main className={`${styles.root}`}>
      <Grid item xs={12}>
        <PageHeader title="Disclosures Management" />
      </Grid>
      <CardGrid>
        <UploadNewDisclosures />
        <UpdateDisclosureStaticMap />
      </CardGrid>
    </main>
  );
}

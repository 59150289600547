import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiTableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import _ from 'lodash';
import { ColumnProps } from 'design';

export interface TableCellProps {
  row: any;
  col: ColumnProps;
}

const TableCell = ({ row, col }: TableCellProps): JSX.Element => {
  const { cellWidth, divRight, divLeft, verticalAlign } = col;
  const { bodyCellRoot } = useStyles({
    cellWidth,
    divRight,
    divLeft
  });
  const root = clsx(bodyCellRoot);
  return (
    <MuiTableCell
      component="th"
      scope="row"
      classes={{ root }}
      align={col.align}
      padding={col.padding}
      key={`${col.accessor}-body`}
      style={{
        maxHeight: '36px !important',
        width: col.label === '' ? '45px' : 'auto',
        minWidth: col.label === '' ? '45px' : cellWidth ? cellWidth : 'auto',
        maxWidth: col.label === '' ? '45px' : 'auto',
        borderRight: divRight ? `2px solid #fff` : 'none',
        borderLeft: divLeft ? `2px solid #fff` : 'none',
        verticalAlign: verticalAlign ? verticalAlign : 'center',
        paddingTop: verticalAlign && '6px',
        boxSizing: 'border-box'
      }}
    >
      {/*In loadash get method, accessor value which has null doesnt takes the given default value(-), so the type checking for null(object) has done */}
      {col.render ? (
        <col.render {...row} />
      ) : typeof _.get(row, col.accessor) === 'object' ||
        _.get(row, col.accessor) === '' ? (
        '-'
      ) : (
        _.get(row, col.accessor, '-')
      )}
    </MuiTableCell>
  );
};

const useStyles = makeStyles<Theme, Partial<ColumnProps>>((theme: Theme) => ({
  bodyCellRoot: {
    whiteSpace: 'nowrap',
    maxWidth: 192,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.body1.fontSize
  }
}));

export default TableCell;
